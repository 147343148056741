import React from 'react';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import refreshFill from '@iconify/icons-eva/refresh-fill';
// material
import {
    Card,
    Table,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    Grid,
} from '@mui/material';
// components
import Page from '../Page';
import Scrollbar from '../Scrollbar';
import { UserListHead } from '../_dashboard/user';
//
import { LoadingButton } from '@mui/lab';
import { doCreditApi } from '../../http';

import { connect } from 'react-redux';
import { IApplicationState } from 'src/redux';
import { Audio as AudoSpin } from 'react-loader-spinner'
import { formattedDate, generateUUID, getCommaSeparatedUnitUniqueIdsExceptAll } from 'src/utility';
import { IUserDetail } from 'src/interfaces';
import { ISelectedUnitAccess } from 'src/interfaces/selected-unit-access.interface';
import { AllOptionUniqueId } from 'src/app.constant';
import { NoUnitSelectedComponent } from 'src/components/common/no-unit-selected.component';
import { fCurrency } from 'src/utils/formatNumber';
import { useNavigate } from "react-router-dom";
import { IUserRouteDetail } from 'src/interfaces/routes/user-route-detail.interface';
import { IMonthWiseCredit } from 'src/interfaces/month-wise-credit.interface';


// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'place_holder', label: '', alignContent: 'center' },
    { id: 'month_name', label: 'Month', alignContent: 'center' },
    { id: 'responsible_person', label: 'Responsible Person', alignContent: 'center' },
    {id: 'pending_credit', label:'Pending Credit', alignContent:'center'}
];

//#region interfaces
interface IProps {
    loginUser: IUserDetail | null;
    selectedUnits: ISelectedUnitAccess;
    userRoutes:IUserRouteDetail;
}

interface ITotal{
    TotalCreditAmount:number;
    TotalReceivedAmount:number;
    TotalPendingAmount:number;
    UnitUniqueId:string;
}

//#endregion

const MonthWiseCredit = (props: IProps) => {
    //#region Initialization
    const navigate = useNavigate();
    //#endregion

    //#region state variables
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [monthWiseCredits, setMonthWiseCredits] = React.useState<IMonthWiseCredit[]>([]);
    const [overAllTotals, setOverAllTotals] = React.useState<ITotal>({
        TotalCreditAmount:0,
        TotalPendingAmount:0,
        TotalReceivedAmount:0,
        UnitUniqueId:''
    });

    const [unitWiseTotals, setUnitWiseTotals] = React.useState<ITotal[]>([]);
    const [isHistoryLoadedAfterLastChange, setIsHistoryLoadedAfterLastChange] = React.useState<boolean>(false);
    //#endregion

    //#region util methods start

    const setTotals = (crdts:IMonthWiseCredit[]) =>{
        let oTotal:ITotal = {
            TotalCreditAmount:0,
            TotalPendingAmount:0,
            TotalReceivedAmount:0,
            UnitUniqueId:''
        };

        let uWiseTotals:ITotal[] = [];

        if(!!crdts && crdts.length > 0){
            crdts.forEach(c => {
                if(uWiseTotals.findIndex(e => e.UnitUniqueId == c.UnitUniqueId) >= 0){
                    let uWiseTotal = uWiseTotals.find(u => u.UnitUniqueId == c.UnitUniqueId);
                    uWiseTotal.TotalCreditAmount += c.TotalCredit;
                    uWiseTotal.TotalPendingAmount += c.TotalPendingCredit;
                    uWiseTotal.TotalReceivedAmount += c.TotalReceivedAmount;
                }else{
                    uWiseTotals.push({
                        TotalCreditAmount : c.TotalCredit,
                        TotalPendingAmount: c.TotalPendingCredit,
                        TotalReceivedAmount:c.TotalReceivedAmount,
                        UnitUniqueId:c.UnitUniqueId
                    });
                }

                oTotal.TotalCreditAmount += c.TotalCredit;
                oTotal.TotalPendingAmount += c.TotalPendingCredit;
                oTotal.TotalReceivedAmount += c.TotalReceivedAmount;
            });
        }

        setOverAllTotals({...oTotal});
        setUnitWiseTotals([...uWiseTotals]);
    }

    //#endregion util methods end

    //#region handlers start
    const navigateToUrl = (url:string)=>{
        navigate(url);
      }

    const resetEverything = () => {
        setOverAllTotals({
            TotalCreditAmount:0,
            TotalPendingAmount:0,
            TotalReceivedAmount:0,
            UnitUniqueId:''
        });

        setUnitWiseTotals([]);
        getCredits();
    }

    //#endregion handlers end

    //#region apis call start

    const getCredits = () => {
        setIsLoading(true);
        doCreditApi.getMonthWiseCredits(getCommaSeparatedUnitUniqueIdsExceptAll(props.selectedUnits.UnitAccesses), props.loginUser.UserId,null,null)
            .then(results => {
                setIsLoading(false);
                if (!!results) {
                    setMonthWiseCredits([...results]);
                    setTotals([...results]);
                }
                else {
                    resetEverything();
                }
            })
            .catch(e => {
                console.log(e);
                setIsLoading(false);
            });
    }

    //#endregion apis calls end

    //#region hooks start
    React.useEffect(() => {
        getCredits();
    }, [props.selectedUnits.UnitAccesses]);

    React.useEffect(() =>{
        if(!!props.userRoutes && !!props.userRoutes.Routes && props.userRoutes.Routes.length > 0){
            
        }
    },[props.userRoutes]);
    //#endregion hooks end

    const selectedBackgroundColor = 'blue';
    const normalBackgroundColor = 'rgba(34, 154, 22, 0.72)';

    return (
        (!!props.selectedUnits && !!props.selectedUnits.UnitAccesses && props.selectedUnits.UnitAccesses.filter(u => u.UnitUniqueId != AllOptionUniqueId).length > 0) ? <Page title="Credit | Raza Pak Care">
            <Container style={{ maxWidth: 1500, paddingLeft: 0, paddingRight: 0 }}>
                <Grid container style={{ flexDirection: 'row'}}>
                    <Grid xs={12} sm={12} style={{flexDirection:'row',margin:5,display:'flex',justifyContent:'flex-end'}} item>

                    <LoadingButton
                        size="small"
                        type="button"
                        variant="contained"
                        loading={isLoading}
                        style={{alignSelf:'center',marginRight:5, backgroundColor: 'blue', textAlign: 'right', height: 40 }}
                        startIcon={<Icon icon={'eva:arrow-back-outline'} />}
                    >
                        Back
                    </LoadingButton>

                    <LoadingButton
                        size="small"
                        type="button"
                        variant="contained"
                        loading={isLoading}
                        style={{alignSelf:'center', backgroundColor: 'rgba(34, 154, 22, 0.72)', textAlign: 'right', height: 40 }}
                        startIcon={<Icon icon={refreshFill} />}
                        onClick={() => {
                            resetEverything();
                        }}
                    >
                        Refresh
                    </LoadingButton>
                    </Grid>
                </Grid>


                {!!props.selectedUnits && !!props.selectedUnits.UnitAccesses && props.selectedUnits.UnitAccesses.filter(m => m.UnitUniqueId != AllOptionUniqueId).length > 0 && props.selectedUnits.UnitAccesses.filter(m => m.UnitUniqueId != AllOptionUniqueId).map((selectedUnit, unitIndex) => {
                        return <><Card>
                            {<Typography style={{ backgroundColor: selectedBackgroundColor, color: 'white', paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h4'}>Month Wise Credit For {selectedUnit.UnitTitle}</Typography>}
                            {isLoading == true && <AudoSpin
                                height="80"
                                width="80"
                                color='green'
                                ariaLabel='three-dots-loading'
                                wrapperStyle={{ alignSelf: 'center', justifyContent: 'center' }}
                            />}

                            {(!monthWiseCredits || monthWiseCredits.length <= 0 || monthWiseCredits.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length <= 0) && isHistoryLoadedAfterLastChange == true && isLoading == false && <Typography style={{ paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h6'}>No Details For {selectedUnit.UnitTitle}</Typography>}

                            {!!monthWiseCredits && monthWiseCredits.length > 0 && monthWiseCredits.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length > 0 && <Scrollbar sx={{}}>
                                <TableContainer sx={{ minWidth: 900, maxHeight: 700 }}>
                                    <Table stickyHeader>
                                        <UserListHead
                                            order={'asc'}
                                            orderBy={''}
                                            headLabel={TABLE_HEAD}
                                            rowCount={0}
                                            numSelected={0}
                                            onRequestSort={undefined}
                                            onSelectAllClick={undefined}
                                            isAllSelected={false}
                                            showSelectAllCheckbox={false}
                                            customKey={`TableHeadRow_3${generateUUID()}`}
                                        />
                                        <TableBody>
                                            {!!monthWiseCredits && monthWiseCredits.length > 0 && monthWiseCredits.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length > 0 && monthWiseCredits.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).map((monthRow, monthIndex) => {
                                                return (<>{!!monthRow.MonthWiseCreditDetails && monthRow.MonthWiseCreditDetails.length > 0 && monthRow.MonthWiseCreditDetails.map((row, index) => {
                                                    return (
                                                        <TableRow
                                                            hover
                                                            key={`${generateUUID()}`}
                                                            tabIndex={-1}
                                                            role="checkbox"
                                                            selected={false}
                                                            aria-checked={false}
                                                        >
                                                            <TableCell>&nbsp;</TableCell>
                                                            <TableCell align="center" component="th" scope="row" padding="none">
                                                                <strong>{formattedDate(row.MonthStartDate,'MMM-YYYY')}</strong>
                                                            </TableCell>
                                                            <TableCell align="center" component="th" scope="row" padding="none">
                                                                {row.ResponsiblePersonName}
                                                            </TableCell>
                                                            <TableCell style={{color:'red'}} align="center" component="th" scope="row" padding="none">
                                                                {fCurrency(row.TotalPendingCredit)}
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}

                                                    <TableRow
                                                        hover
                                                        key={`${generateUUID()}`}
                                                        tabIndex={-1}
                                                        role="checkbox"
                                                        selected={false}
                                                        aria-checked={false}
                                                    >
                                                        <TableCell style={{ borderBottom: 1, borderBottomColor: 'black', borderBottomStyle: 'solid' }} align="right" colSpan={3}><Typography variant='subtitle2'>Sub Total ({formattedDate(monthRow.MonthStartDate, 'MMM-YYYY')}):</Typography></TableCell>



                                                        <TableCell style={{ borderBottom: 1, borderBottomColor: 'black', borderBottomStyle: 'solid',color:'red' }} align="center" component="th" scope="row" padding="none">
                                                            <Typography variant="subtitle2">{fCurrency(monthRow.TotalPendingCredit)}</Typography>
                                                        </TableCell>
                                                    </TableRow>

                                                </>);
                                            })}
                                            <TableRow>
                                                <TableCell colSpan={3} align='right'>
                                                    <Typography variant='h6' style={{ textAlign: 'right' }}>Total:</Typography>
                                                </TableCell>
                                                <TableCell align='center'><Typography style={{ color: 'red' }} variant='h6'>{fCurrency(unitWiseTotals?.find(m => m.UnitUniqueId == selectedUnit.UnitUniqueId)?.TotalPendingAmount ?? 0)}</Typography></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Scrollbar>}
                        </Card><br /></>
                    })}


            </Container>
        </Page> : <NoUnitSelectedComponent pageTitle='DO | Raza Pak Care' />
    );
}

const mapStateToProps = (state: IApplicationState) => {
    return {
        selectedUnits: state.selectedUnitAccesses,
        loginUser: state.loginUser,
        userRoutes:state.userRoutes
    };
}
const connectedMonthWiseCredit = connect(mapStateToProps)(MonthWiseCredit);
export { connectedMonthWiseCredit as MonthWiseCredit };
