import {http} from './http-base';
import { concatParam } from 'src/utility';
import { IAreaOnMap } from 'src/interfaces/area-on-map.interface';
import { IArea } from 'src/interfaces/area.interface';
import { IAutoComplete } from 'src/interfaces/auto-complete.interface';
import { IAreaPoly } from 'src/interfaces/area-poly.interface';
import { IMapShop } from 'src/interfaces/map-shop.interface';

const getAreasOnMap = async (
): Promise<IAreaOnMap> => {
  let url: string = `api/Area/AreasOnMap`;
  let result = await http
    .get<IAreaOnMap>(url)
    .catch(error => Promise.reject(error));
  return result.data;
};

const saveAreaDetails = async (
    loggedInUserId:number,
    area:IArea
    ): Promise<IArea> => {
      let url: string = `api/Area/SaveAreaDetails?loggedInUserId=${loggedInUserId}`;
      let result = await http
        .post<IArea>(url,area)
        .catch(error => Promise.reject(error));
      return result.data;
    };

    const getAreasAutoComplete = async (
      userId:number
      ): Promise<IAutoComplete[]> => {
        let url: string = `api/Area/AreaAutoComplete?userId=${userId}`;
        let result = await http
          .get<IAutoComplete[]>(url)
          .catch(error => Promise.reject(error));
        return result.data;
      };

      const getAreaPolies = async (
        areaId:number
        ): Promise<IAreaPoly[]> => {
          let url: string = `api/AreaPoly/AreasPolies?areaId=${areaId}`;
          let result = await http
            .get<IAreaPoly[]>(url)
            .catch(error => Promise.reject(error));
          return result.data;
        };

        const getAreaNewShops = async (
          areaId:number
          ): Promise<IMapShop[]> => {
            let url: string = `api/Area/AreaNewShops?areaId=${areaId}`;
            let result = await http
              .get<IMapShop[]>(url)
              .catch(error => Promise.reject(error));
            return result.data;
          };

          const getAreaExistingShops = async (
            areaId:number
            ): Promise<IMapShop[]> => {
              let url: string = `api/Area/AreaExistingShops?areaId=${areaId}`;
              let result = await http
                .get<IMapShop[]>(url)
                .catch(error => Promise.reject(error));
              return result.data;
            };

const areaApi = {
  getAreasOnMap,
  saveAreaDetails,
  getAreasAutoComplete,
  getAreaPolies,
  getAreaNewShops,
  getAreaExistingShops
};

export {areaApi};
