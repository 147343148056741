import React from 'react';
import { useSelector } from 'react-redux';
import { MessageComponent } from 'src/components/messages.component';
import { areaApi } from 'src/http';
import { IAreaOnMap } from 'src/interfaces/area-on-map.interface';
import { IAreaPoly } from 'src/interfaces/area-poly.interface';
import { IArea } from 'src/interfaces/area.interface';
import { IMessage } from 'src/interfaces/message.interface';
import { IApplicationState } from 'src/redux';
import { generateUUID } from 'src/utility';
import { confirmAction } from 'src/utility/confirm.util';
import { centroid } from 'src/utility/geo.util';
import { fCurrency } from 'src/utils/formatNumber';
import { Audio as AudoSpin } from 'react-loader-spinner'
import { IMapShop } from 'src/interfaces/map-shop.interface';

interface IAreaPolyOnMap{
  areaId:string;
  polygon:google.maps.Polygon
}

interface IAreaInfoWindow{
  areaId:string;
  infoWindow:google.maps.InfoWindow;
  marker:google.maps.Marker
}

interface IAreaCenterPointMarker{
  areaId:string;
  marker:google.maps.Marker
}

export const AreaMapComponent = ({
    center,
    zoom,
  }: {
    center: google.maps.LatLngLiteral;
    zoom: number;
  }) => {
    const ref = React.useRef();
    const loginUser = useSelector((state:IApplicationState) => state.loginUser);
    const [areasOnMap,_setAreasOnMap] = React.useState<IAreaOnMap>();
    const areasOnMapRef = React.useRef(areasOnMap);
    const [shopsMarker,_setShopsMarker] = React.useState<google.maps.Marker[]>([]);
    const shopsMarkerRef = React.useRef(shopsMarker);
    const [areaCenterPointMarkers,_setAreaCenterPointMarkers] = React.useState<IAreaCenterPointMarker[]>([]);
    const areaCenterPointMarkersRef = React.useRef(areaCenterPointMarkers);
    const [areaInfoWindows,_setAreasInfoWindows] = React.useState<IAreaInfoWindow[]>([]);
    const areaInfoWindowsRef = React.useRef(areaInfoWindows);
    const [areaPoliesOnMap,_setAreaPoliesOnMap] = React.useState<IAreaPolyOnMap[]>([]);
    const areaPoliesOnMapRef = React.useRef(areaPoliesOnMap);

    const setAreaPoliesOnMap = (data:IAreaPolyOnMap[]) =>{
      areaPoliesOnMapRef.current = data;
      _setAreaPoliesOnMap(data);
    }

    const setAreasInfoWindows = (data:IAreaInfoWindow[]) =>{
      areaInfoWindowsRef.current = data;
      _setAreasInfoWindows(data);
    }
    const setAreaCenterPointMarkers = (data:IAreaCenterPointMarker[]) =>{
      areaCenterPointMarkersRef.current = data;
      _setAreaCenterPointMarkers(data);
    }

    const setShopsMarker = (shopMarkers:google.maps.Marker[]) =>{
      shopsMarkerRef.current = shopMarkers;
      _setShopsMarker(shopMarkers);
    }
    const setAreasOnMap = (aMaps:IAreaOnMap) =>{
      areasOnMapRef.current = aMaps;
      _setAreasOnMap(aMaps);
    }

    const getAreasOnMap = (map:google.maps.Map) =>{
      setIsLoading(true);
      areaApi.getAreasOnMap()
      .then(result => {

        if(!!result && !!result.Areas && result.Areas.length > 0){
          result.Areas.forEach(a => {
            a.AreaUniqueId = generateUUID();
          })
        }

        setAreasOnMap({...result});
        addShopsMarkers(map,result.ExistingShops);
        drawAreaPolygons(map,result.Areas);
        addAreaCenterPointMarker(map,result.Areas);
        clearMessages();
        setIsLoading(false);
      })
      .catch(e => {
        setIsLoading(false);
        showErrorMessage('Unable To Fetch Areas. Please Check Your Internet And Refresh Page Again.');
      });
    }

    const addShopsMarkers = (map:google.maps.Map,shops:IMapShop[]) =>{
      if(!!shops && shops.length > 0){
        let markers:google.maps.Marker[] = [];

        shops.forEach(s => {
          const marker = new google.maps.Marker({
            position: { lat: s.Latitude, lng: s.Longitude },
            map: map,
            icon:'/static/green.png'
          });

          markers.push(marker);
        });

        setShopsMarker([...markers]);
      }
    }

    const addAreaCenterPointMarker = (map:google.maps.Map, areas:IArea[]) =>{
      let centerPointMarkers:IAreaCenterPointMarker[] = [];
      let areaInfoWindowsTemp:IAreaInfoWindow[] = [];
      if(!!areas && areas.length > 0){
        areas.forEach(area => {
          const marker = new google.maps.Marker({
            position: { lat: area.CenterPointLatitude, lng: area.CenterPointLongitude },
            map: map,
          });

          var infowindow = new google.maps.InfoWindow({
            content: `<div style="width:450px;">
                      <div style="width:450px;text-align:center;">
                        <h3>
                          ${area.AreaTitle}
                        </h3>
                      </div>
                      <table width="450px" style="margin-top:5px;">
                        <tr style="padding-bottom:10px;">
                          <td colspan="4" align="center" style="padding-bottom:5px;">
                            <h4>Current Month Analytics</h4>
                          </td>
                        </tr>
                        <tr>
                          <td align="right" style="padding-bottom:7px;">
                            <strong>Existing Shops:</strong>
                          </td>
                          <td style="padding-left:10px;padding-bottom:7px;">
                            <strong>${area?.TotalExistingShops ?? 0}</strong>
                          </td>
                          <td align="right" style="padding-bottom:7px;">
                            <strong>Added Shops:</strong>
                          </td>
                          <td style="padding-left:10px;padding-bottom:7px;">
                            <strong>${area?.TotalNewShops ?? 0}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td align="right" style="padding-bottom:7px;">
                            <strong>Total Orders:</strong>
                          </td>
                          <td style="padding-left:10px;padding-bottom:7px;">
                            <strong>${area?.AreaOrderAnalytics?.TotalOrders ?? 0}</strong>
                          </td>
                          <td align="right" style="padding-bottom:7px;">
                            <strong style="color:green">Repeated Orders:</strong>
                          </td>
                          <td style="color:green;padding-left:10px;padding-bottom:7px;">
                            <strong>${area?.AreaOrderAnalytics?.TotalRepeatedOrders ?? 0}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td align="right" style="padding-bottom:7px;">
                            <strong style="color:red">No Orders:</strong>
                          </td>
                          <td style="color:red;padding-left:10px;padding-bottom:7px;">
                            <strong>${area?.AreaOrderAnalytics?.TotalNoOrders ?? 0}</strong>
                          </td>
                          <td align="right" style="padding-bottom:7px;">
                            <strong style="color:red">Repeated No Orders:</strong>
                          </td>
                          <td style="color:red;padding-left:10px;padding-bottom:7px;">
                            <strong>${area?.AreaOrderAnalytics?.TotalRepeatedNoOrders ?? 0}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td align="right" style="padding-bottom:7px;">
                            <strong style="color:red">Discount:</strong>
                          </td>
                          <td style="color:red;padding-left:10px;padding-bottom:7px;">
                            <strong>${fCurrency(area?.AreaSaleAnalytics?.TotalDiscountCost ?? 0)}</strong>
                          </td>
                          <td align="right" style="padding-bottom:7px;">
                            <strong style="color:red">Scheme Cost:</strong>
                          </td>
                          <td style="color:red;padding-left:10px;padding-bottom:7px;">
                            <strong>${fCurrency(area?.AreaSaleAnalytics?.TotalSchemeCost ?? 0)}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td align="right" style="padding-bottom:7px;">
                            <strong style="color:green">Retail Sale:</strong>
                          </td>
                          <td style="color:green;padding-left:10px;padding-bottom:7px;">
                            <strong>${fCurrency(area?.AreaSaleAnalytics?.TotalRetailSale ?? 0)}</strong>
                          </td>
                          <td align="right" style="padding-bottom:7px;">
                            <strong style="color:red">Retail Sale Returned:</strong>
                          </td>
                          <td style="color:red;padding-left:10px;padding-bottom:7px;">
                            <strong>${fCurrency(area?.AreaSaleAnalytics?.TotalRetailSaleReturned ?? 0)}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td align="right" style="padding-bottom:7px;">
                            <strong style="color:green">Whole Sale:</strong>
                          </td>
                          <td style="color:green;padding-left:10px;padding-bottom:7px;">
                            <strong>${fCurrency(area?.AreaSaleAnalytics?.TotalWholeSale ?? 0)}</strong>
                          </td>
                          <td align="right" style="padding-bottom:7px;">
                            <strong style="color:red">Whole Sale Returned:</strong>
                          </td>
                          <td style="color:red;padding-left:10px;padding-bottom:7px;">
                            <strong>${fCurrency(area?.AreaSaleAnalytics?.TotalWholeSaleReturned ?? 0)}</strong>
                          </td>
                        </tr>

                        <tr>
                          <td align="right" colspan="2" style="padding-bottom:7px;">
                            <h3 style="color:green">Net Sale:</h3>
                          </td>
                          <td colspan="2" style="color:green;padding-left:10px;padding-bottom:7px;">
                            <h3>${fCurrency(area?.AreaSaleAnalytics?.TotalSale ?? 0)}</h3>
                          </td>
                        </tr>

                      </table>
                      </div>`,
          });

          areaInfoWindowsTemp.push({areaId:area.AreaUniqueId,infoWindow:infowindow, marker:marker});

          google.maps.event.addListener(marker, 'click', function () {
            infowindow.open(map, marker);
        });

        infowindow.open(map, marker);
          centerPointMarkers.push({areaId:area.AreaUniqueId,marker:marker});
        });

        setAreaCenterPointMarkers([...centerPointMarkers]);
        setAreasInfoWindows([...areaInfoWindowsTemp]);
      }
    }

    const drawAreaPolygons = (map:google.maps.Map, areas:IArea[]) =>{
      if(!!areas && areas.length > 0){
        let areasPolyOnMap:IAreaPolyOnMap[] = [];
        areas.forEach(a => {
          if(!!a && !!a.AreaPoly && a.AreaPoly.length > 0){
            const poly = a.AreaPoly.sort((a,b) => a.PointOrder - b.PointOrder);
            let polyCoordinates = [];

            poly.forEach(p => {
              polyCoordinates.push({ lat: p.PointLatitude, lng: p.PointLongitude });
            });

            const color = getRandomColor();
            const areaPolyOnMap = new google.maps.Polygon({
              paths: polyCoordinates,
              strokeColor: color,
              strokeOpacity: 0.8,
              strokeWeight: 2,
                fillColor: color,
                editable: false,
              fillOpacity: 0.1,
              });

              google.maps.event.addListener(areaPolyOnMap.getPath(), 'insert_at', function (index, obj) {
                onUpdateAreaPoly(a.Id,a.AreaUniqueId,areaPolyOnMap);
            });
            google.maps.event.addListener(areaPolyOnMap.getPath(), 'set_at', function (index, obj) {
              onUpdateAreaPoly(a.Id,a.AreaUniqueId,areaPolyOnMap);
            });
  
            google.maps.event.addListener(areaPolyOnMap, 'click', function (event) {
  
              if(!areaPolyOnMap.getEditable()){
                let areaPoliesOnMapToSetEdit = [...areaPoliesOnMapRef.current];
                let areaIdsToRemove:string[] = [];
                if(!!areaPoliesOnMapToSetEdit && areaPoliesOnMapToSetEdit.length > 0){
                  areaPoliesOnMapToSetEdit.forEach(ap => {
                    if(!!areasOnMapRef.current.Areas.find(m => m.AreaUniqueId == ap.areaId) && areasOnMapRef.current.Areas.find(m => m.AreaUniqueId == ap.areaId).Id <= 0){
                      ap.polygon.setMap(null);
                      const centerPoint = areaCenterPointMarkersRef.current.find(n => n.areaId == ap.areaId);
                      centerPoint.marker.setMap(null);
                      areaIdsToRemove.push(ap.areaId);
                    }else{
                      ap.polygon.setEditable(ap.areaId == a.AreaUniqueId);
                    }
                  });

                  setAreaPoliesOnMap([...areaPoliesOnMapToSetEdit.filter(m => areaIdsToRemove.findIndex(t => m.areaId == t) < 0)]);
                  setAreaCenterPointMarkers([...areaCenterPointMarkersRef.current.filter(m => areaIdsToRemove.findIndex(t => m.areaId == t) < 0)]);
                  setAreasInfoWindows([...areaInfoWindowsRef.current.filter(m => areaIdsToRemove.findIndex(t => m.areaId == t) < 0)]);
                }

                areaPolyOnMap.setEditable(true);
                let selectedArea = {...areas.find(m => m.AreaUniqueId == a.AreaUniqueId)};
                setCurrentAreaOrignal({...selectedArea,AreaPoly:[...selectedArea.AreaPoly]});
              }
            });

            areasPolyOnMap.push({areaId:a.AreaUniqueId,polygon:areaPolyOnMap});
            areaPolyOnMap.setMap(map);
          }
        });

        setAreaPoliesOnMap([...areasPolyOnMap]);
      }
    }

    const getRandomColor = () => {
      var color = '#';
      for (var i = 0; i < 6; i++) {
        color += Math.floor(Math.random() * 10);
      }
      return color;
    }

    const [currentMap,_setCurrentMap] = React.useState<google.maps.Map>();
    const currentMapRef = React.useRef(currentMap);

    const setCurrentMap = (cMap:google.maps.Map) =>{
      currentMapRef.current = cMap;
      _setCurrentMap(cMap);
    }
    const [currentArea, _setCurrentArea] = React.useState<IArea>();
    const currentAreaRef = React.useRef(currentArea);

    const setCurrentArea = (a:IArea) => {
      currentAreaRef.current = a;
      _setCurrentArea(a);
    }

    const [currentAreaOrignal, _setCurrentAreaOrignal] = React.useState<IArea>();
    const currentAreaOrignalRef = React.useRef(currentAreaOrignal);
    const setCurrentAreaOrignal = (data:IArea) => {
      currentAreaOrignalRef.current = data;
      _setCurrentAreaOrignal(data);
    }

    const onUpdateAreaPoly = (areaId:number,areaUniqueId:string,overlay:google.maps.Polygon) => {
      let areaPolies:IAreaPoly[] = [];
      let arr = overlay.getPath().getArray();
      let pointOrder = 0;
      let points = [];
      arr.forEach(element => {
        pointOrder += 1;
        points.push({x:element.lat(),y:element.lng()})
        areaPolies.push({
          AreaId:areaId,
          CreatedById:loginUser.UserId ?? 0,
          CreatedOn:new Date(),
          Id:0,
          IsActive:true,
          PointLatitude:element.lat(),
          PointLongitude:element.lng(),
          PointOrder:pointOrder,
          AreaUniqueId:areaUniqueId,
        });
      });


      let existingShopsInside = 0;
      let existingShopIds:number[] = [];
      if (!!areasOnMapRef.current && !!areasOnMapRef.current.ExistingShops && areasOnMapRef.current.ExistingShops.length > 0) {
        areasOnMapRef.current.ExistingShops.forEach(sh => {
          if (google.maps.geometry.poly.containsLocation({ lat: sh.Latitude, lng: sh.Longitude }, overlay)) {
            existingShopsInside = existingShopsInside + 1;
            existingShopIds.push(sh.Id);
          }
        });
      }

      let newShopsInside = 0;
      if (!!areasOnMapRef.current && !!areasOnMapRef.current.NewShops && areasOnMapRef.current.NewShops.length > 0) {
        areasOnMapRef.current.NewShops.forEach(sh => {
          if (google.maps.geometry.poly.containsLocation({ lat: sh.Latitude, lng: sh.Longitude }, overlay)) {
            newShopsInside = newShopsInside + 1;
          }
        });
      }

      const centerPoint = centroid(points);
      if(areaUniqueId != '' && !!areasOnMapRef.current && !!areasOnMapRef.current.Areas && areasOnMapRef.current.Areas.length > 0 && areasOnMapRef.current.Areas.findIndex(m => m.AreaUniqueId == areaUniqueId) >= 0){
        let areas = [...areasOnMapRef.current.Areas];
        let selectedArea = {...areas.find(m => m.AreaUniqueId == areaUniqueId)};
        let index = areas.findIndex(n => n.AreaUniqueId == areaUniqueId);
        selectedArea.TotalExistingShops = existingShopsInside;
        selectedArea.TotalNewShops = newShopsInside;
        selectedArea.AreaPoly = [...areaPolies];
        selectedArea.ExistingShopIds = [...existingShopIds];
        areas[index] = {...selectedArea};
        setAreasOnMap({...areasOnMapRef.current,Areas:areas});
        setCurrentArea({...selectedArea,AreaPoly:[...areaPolies]});
        addOrRefreshInfoWindowContent(areaUniqueId,{...selectedArea});
      }else{
        let selectedArea:IArea = {
          AreaDescription:'',
          AreaOrderAnalytics:null,
          AreaPoly:[...areaPolies],
          AreaSaleAnalytics:null,
          AreaTitle:'',
          CenterPointLatitude:centerPoint.lat,
          CenterPointLongitude:centerPoint.lng,
          CreatedById:loginUser.UserId ?? 0,
          CreatedOn:new Date(),
          Id:0,
          IsActive:true,
          PolygonData:'',
          TotalExistingShops:existingShopsInside,
          TotalNewShops:newShopsInside,
          UpdatedById:loginUser.UserId ?? 0,
          UpdatedOn:new Date(),
          AreaUniqueId:areaUniqueId,
          ExistingShopIds:[...existingShopIds],
          AvailableOrderBookers:[]
        };
        
        let areasOnM = [...areasOnMapRef.current.Areas,{...selectedArea}]
        setAreasOnMap({...areasOnMapRef.current,Areas:[...areasOnM]});
        setCurrentArea({...selectedArea,AreaPoly:[...areaPolies]});
        addOrRefreshInfoWindowContent(areaUniqueId,{...selectedArea});
        setAreaPoliesOnMap([...areaPoliesOnMapRef.current,{areaId:areaUniqueId,polygon:overlay}]);
      }
    }

    const addOrRefreshInfoWindowContent = (areaId:string,area:IArea) => {
      let iWindow = areaInfoWindowsRef.current.find(m => m.areaId == areaId);
      if(!!iWindow){
        if(area.Id <= 0){
          let infoContent = `<div style="width:450px;">
          <div style="width:450px;text-align:center;">
            <h3>
            <input style="padding:10px;width:350px;" type='text' name='txtRouteTitle' id='txtRouteTitle' value="${area.AreaTitle}" />
            </h3>
          </div>
          <table width="450px" style="margin-top:5px;">
            <tr>
              <td align="right" style="padding-bottom:7px;">
                <strong>Existing Shops:</strong>
              </td>
              <td style="padding-left:10px;padding-bottom:7px;">
                <strong>${area?.TotalExistingShops ?? 0}</strong>
              </td>
              <td align="right" style="padding-bottom:7px;">
                <strong>Added Shops:</strong>
              </td>
              <td style="padding-left:10px;padding-bottom:7px;">
                <strong>${area?.TotalNewShops ?? 0}</strong>
              </td>
            </tr>
            <tr>
              <td colspan="4" align="center" style="padding-bottom:5px;">
                <button onclick="window.saveAreaDetails()" style="padding:10px;cursor:pointer;">Save Changes</button>
                <button onclick="window.resetEverything()" style="padding:10px;cursor:pointer;">Reset</button>
              </td>
            </tr>
          </table>
          </div>`;
          iWindow.infoWindow.setContent(infoContent);
        }else{
          let infoContent = `<div style="width:450px;">
          <div style="width:450px;text-align:center;">
            <input style="padding:10px;width:350px;" type='text' name='txtRouteTitle' id='txtRouteTitle' value="${area.AreaTitle}" />
          </div>
          <table width="450px" style="margin-top:5px;">
            <tr style="padding-bottom:10px;">
              <td colspan="4" align="center" style="padding-bottom:5px;">
                <h4>Current Month Analytics</h4>
              </td>
            </tr>
            <tr>
              <td align="right" style="padding-bottom:7px;">
                <strong>Existing Shops:</strong>
              </td>
              <td style="padding-left:10px;padding-bottom:7px;">
                <strong>${area?.TotalExistingShops ?? 0}</strong>
              </td>
              <td align="right" style="padding-bottom:7px;">
                <strong>Added Shops:</strong>
              </td>
              <td style="padding-left:10px;padding-bottom:7px;">
                <strong>${area?.TotalNewShops ?? 0}</strong>
              </td>
            </tr>
            <tr>
              <td align="right" style="padding-bottom:7px;">
                <strong>Total Orders:</strong>
              </td>
              <td style="padding-left:10px;padding-bottom:7px;">
                <strong>${area?.AreaOrderAnalytics?.TotalOrders ?? 0}</strong>
              </td>
              <td align="right" style="padding-bottom:7px;">
                <strong style="color:green">Repeated Orders:</strong>
              </td>
              <td style="color:green;padding-left:10px;padding-bottom:7px;">
                <strong>${area?.AreaOrderAnalytics?.TotalRepeatedOrders ?? 0}</strong>
              </td>
            </tr>
            <tr>
              <td align="right" style="padding-bottom:7px;">
                <strong style="color:red">No Orders:</strong>
              </td>
              <td style="color:red;padding-left:10px;padding-bottom:7px;">
                <strong>${area?.AreaOrderAnalytics?.TotalNoOrders ?? 0}</strong>
              </td>
              <td align="right" style="padding-bottom:7px;">
                <strong style="color:red">Repeated No Orders:</strong>
              </td>
              <td style="color:red;padding-left:10px;padding-bottom:7px;">
                <strong>${area?.AreaOrderAnalytics?.TotalRepeatedNoOrders ?? 0}</strong>
              </td>
            </tr>
            <tr>
              <td align="right" style="padding-bottom:7px;">
                <strong style="color:red">Discount:</strong>
              </td>
              <td style="color:red;padding-left:10px;padding-bottom:7px;">
                <strong>${fCurrency(area?.AreaSaleAnalytics?.TotalDiscountCost ?? 0)}</strong>
              </td>
              <td align="right" style="padding-bottom:7px;">
                <strong style="color:red">Scheme Cost:</strong>
              </td>
              <td style="color:red;padding-left:10px;padding-bottom:7px;">
                <strong>${fCurrency(area?.AreaSaleAnalytics?.TotalSchemeCost ?? 0)}</strong>
              </td>
            </tr>
            <tr>
              <td align="right" style="padding-bottom:7px;">
                <strong style="color:green">Retail Sale:</strong>
              </td>
              <td style="color:green;padding-left:10px;padding-bottom:7px;">
                <strong>${fCurrency(area?.AreaSaleAnalytics?.TotalRetailSale ?? 0)}</strong>
              </td>
              <td align="right" style="padding-bottom:7px;">
                <strong style="color:red">Retail Sale Returned:</strong>
              </td>
              <td style="color:red;padding-left:10px;padding-bottom:7px;">
                <strong>${fCurrency(area?.AreaSaleAnalytics?.TotalRetailSaleReturned ?? 0)}</strong>
              </td>
            </tr>
            <tr>
              <td align="right" style="padding-bottom:7px;">
                <strong style="color:green">Whole Sale:</strong>
              </td>
              <td style="color:green;padding-left:10px;padding-bottom:7px;">
                <strong>${fCurrency(area?.AreaSaleAnalytics?.TotalWholeSale ?? 0)}</strong>
              </td>
              <td align="right" style="padding-bottom:7px;">
                <strong style="color:red">Whole Sale Returned:</strong>
              </td>
              <td style="color:red;padding-left:10px;padding-bottom:7px;">
                <strong>${fCurrency(area?.AreaSaleAnalytics?.TotalWholeSaleReturned ?? 0)}</strong>
              </td>
            </tr>
  
            <tr>
              <td align="right" colspan="2" style="padding-bottom:7px;">
                <h3 style="color:green">Net Sale:</h3>
              </td>
              <td colspan="2" style="color:green;padding-left:10px;padding-bottom:7px;">
                <h3>${fCurrency(area?.AreaSaleAnalytics?.TotalSale ?? 0)}</h3>
              </td>
            </tr>
  
            <tr>
              <td colspan="4" align="center" style="padding-bottom:5px;">
                <button onclick="window.saveAreaDetails()" style="padding:10px;cursor:pointer;">Save Changes</button>
                <button onclick="window.resetEverything()" style="padding:10px;cursor:pointer;">Reset</button>
              </td>
            </tr>
  
          </table>
          </div>`;
  
          iWindow.infoWindow.setContent(infoContent);
        }
        
        //iWindow.infoWindow.open(currentMapVar,iWindow.marker);
      }else{
        const marker = new google.maps.Marker({
          position: { lat: area.CenterPointLatitude, lng: area.CenterPointLongitude },
          map: currentMapRef.current,
        });
  
        let infowindow = new google.maps.InfoWindow({
          content: `<div style="width:450px;">
                    <div style="width:450px;text-align:center;">
                      <h3>
                      <input style="padding:10px;width:350px;" type='text' name='txtRouteTitle' id='txtRouteTitle' value="${area.AreaTitle}" />
                      </h3>
                    </div>
                    <table width="450px" style="margin-top:5px;">
                      <tr>
                        <td align="right" style="padding-bottom:7px;">
                          <strong>Existing Shops:</strong>
                        </td>
                        <td style="padding-left:10px;padding-bottom:7px;">
                          <strong>${area?.TotalExistingShops ?? 0}</strong>
                        </td>
                        <td align="right" style="padding-bottom:7px;">
                          <strong>Added Shops:</strong>
                        </td>
                        <td style="padding-left:10px;padding-bottom:7px;">
                          <strong>${area?.TotalNewShops ?? 0}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="4" align="center" style="padding-bottom:5px;">
                          <button onclick="window.saveAreaDetails()" style="padding:10px;cursor:pointer;">Save Changes</button>
                          <button onclick="window.resetEverything()" style="padding:10px;cursor:pointer;">Reset</button>
                        </td>
                      </tr>
                    </table>
                    </div>`,
        });
  
        google.maps.event.addListener(marker, 'click', function () {
          infowindow.open(currentMapRef.current, marker);
      });

        infowindow.open(currentMapRef.current, marker);
  
        setAreaCenterPointMarkers([...areaCenterPointMarkersRef.current,{areaId:area.AreaUniqueId,marker:marker}]);
        setAreasInfoWindows([...areaInfoWindowsRef.current,{areaId:areaId,infoWindow:infowindow,marker:marker}]);
      }
    }

    const resetEverythingExceptShopMarkers = () =>{
      clearMessages();
      if(!!areaCenterPointMarkersRef.current && areaCenterPointMarkersRef.current.length > 0){
        areaCenterPointMarkersRef.current.forEach(m => {
          m.marker.setMap(null);
        });
      }

      setAreaCenterPointMarkers([]);

      if(!!areaPoliesOnMapRef.current && areaPoliesOnMapRef.current.length > 0){
        areaPoliesOnMapRef.current.forEach(p => {
          p.polygon.setMap(null);
        });
      }

      setAreaPoliesOnMap([]);

      if(!!currentAreaOrignalRef.current && !!areasOnMapRef.current && !!areasOnMapRef.current.Areas && areasOnMapRef.current.Areas.findIndex(t => t.AreaUniqueId == currentAreaOrignalRef.current.AreaUniqueId) >= 0){
        let aOnMaps = [...areasOnMapRef.current.Areas];
        let index = aOnMaps.findIndex(m => m.AreaUniqueId == currentAreaOrignalRef.current.AreaUniqueId);
        aOnMaps[index] = {...currentAreaOrignalRef.current};
        aOnMaps = aOnMaps.filter(m => m.Id != 0);
        setAreasOnMap({...areasOnMapRef.current,Areas:[...aOnMaps]});
        drawAreaPolygons(currentMapRef.current,[...aOnMaps]);
        addAreaCenterPointMarker(currentMapRef.current,aOnMaps);
      }else{
        setAreasOnMap({...areasOnMapRef.current,Areas:areasOnMapRef.current.Areas.filter(m => m.Id != 0)});
        drawAreaPolygons(currentMapRef.current,[...areasOnMapRef.current.Areas.filter(m => m.Id != 0)]);
        addAreaCenterPointMarker(currentMapRef.current,[...areasOnMapRef.current.Areas.filter(m => m.Id != 0)]);
      }

      setCurrentArea(null);
      setCurrentAreaOrignal(null);
    }

    (window as any).resetEverything = resetEverythingExceptShopMarkers;

    const clearMap = () =>{
      if(!!areaCenterPointMarkersRef.current && areaCenterPointMarkersRef.current.length > 0){
        areaCenterPointMarkersRef.current.forEach(m => {
          m.marker.setMap(null);
        });
      }

      setAreaCenterPointMarkers([]);

      if(!!areaPoliesOnMapRef.current && areaPoliesOnMapRef.current.length > 0){
        areaPoliesOnMapRef.current.forEach(p => {
          p.polygon.setMap(null);
        });
      }

      setAreaPoliesOnMap([]);
      setCurrentArea(null);
      setCurrentAreaOrignal(null);
    }

    const saveAreaDetails = () =>{
      const areaTitle = (document.getElementById('txtRouteTitle') as HTMLInputElement).value;

      if(!!areaTitle && areaTitle.trim() != ''){
        if(!!currentAreaRef.current){
          let areaToSave = {...currentAreaRef.current};
          areaToSave.AreaTitle = areaTitle.trim();
          setIsLoading(true);
          areaApi.saveAreaDetails(loginUser.UserId,areaToSave)
          .then(res => {
            let areasOnMapTemp = [...areasOnMapRef.current.Areas];
            let index = areasOnMapTemp.findIndex(m => m.AreaUniqueId == res.AreaUniqueId);
            areasOnMapTemp[index] = res;
            setAreasOnMap({...areasOnMapRef.current,Areas:[...areasOnMapTemp]});

            clearMap();

            drawAreaPolygons(currentMapRef.current,[...areasOnMapTemp]);
            addAreaCenterPointMarker(currentMapRef.current,areasOnMapTemp);
            showSuccessMessage(`Area '${areaTitle}' Saved Successfully`);
            setIsLoading(false);
          })
          .catch(e => {
            setIsLoading(false);
            showErrorMessage('Failed To Save Area');
          })
        }else{
          showErrorMessage('No Data To Save');
        }
      }else{
        showErrorMessage('Please Enter Area Name');
      }
    }

    (window as any).saveAreaDetails = saveAreaDetails;

    React.useEffect(() => {
      const map = new window.google.maps.Map(ref.current, {
        center,
        zoom,
        mapTypeId:window.google.maps.MapTypeId.ROADMAP,
        gestureHandling:'greedy'
      });


      let drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: google.maps.drawing.OverlayType.POLYGON,
        drawingControl: true,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [google.maps.drawing.OverlayType.POLYGON]
        },
          polygonOptions: {
              editable: true
        }
      });

      google.maps.event.addListener(drawingManager, "overlaycomplete", function (event) {
        setCurrentAreaOrignal(null);
        const uniqueId = generateUUID();
        onUpdateAreaPoly(0,uniqueId,event.overlay);

        google.maps.event.addListener(event.overlay.getPath(), 'insert_at', function (index, obj) {
          onUpdateAreaPoly(0,uniqueId,event.overlay);
      });
      google.maps.event.addListener(event.overlay.getPath(), 'set_at', function (index, obj) {
        onUpdateAreaPoly(0,uniqueId,event.overlay);
      });
    });

      drawingManager.setMap(map);
      getAreasOnMap(map);
      setCurrentMap(map);
    },[]);
  
    const showErrorMessage = (message: string) => addMessage(message, 'error');
    const showInfoMessage = (message: string) => addMessage(message, 'info');
    const showSuccessMessage = (message: string) => addMessage(message, 'success');
    const showWarningMessage = (message: string) => addMessage(message, 'warning');
    const [isLoading,_setIsLoading] = React.useState<boolean>(false);
    const isLoadingRef = React.useRef(isLoading);
    const setIsLoading = (val:boolean) =>{
      isLoadingRef.current = val;
      _setIsLoading(val);
    }
    const [pageMessages, setPageMessages] = React.useState<IMessage[]>([]);
    const addMessage = (messageText: string, variant: 'success' | 'error' | 'info' | 'warning') => {
        if (!!messageText && messageText.trim() != '') {
            let msgs = [];
            msgs.push({
                messageText,
                variant
            });

            setPageMessages([...msgs]);
        }
    }

    const clearMessages = () =>{
      setPageMessages([]);
    }

    return <React.Fragment>
      <MessageComponent onRemove={(i: number) => {
            let msgs = [...pageMessages];
            msgs.splice(i, 1);
            setPageMessages([...msgs]);
        }} messages={pageMessages} />
        {isLoadingRef.current == true && <AudoSpin
                        height="80"
                        width="80"
                        color='green'
                        ariaLabel='three-dots-loading'
                        wrapperStyle={{ alignSelf: 'center', justifyContent: 'center' }}
                    />}
        <div style={{width:'100%',height:'100%'}} ref={ref} id="map" />
      </React.Fragment>;
  }