import React from 'react';
import PropTypes from 'prop-types';
// material
import { visuallyHidden } from '@mui/utils';
import { Box, Checkbox, TableRow, TableCell, TableHead, TableSortLabel, Typography, TextField, InputAdornment } from '@mui/material';
import { Icon } from '@iconify/react';

// ----------------------------------------------------------------------

UserListHead.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  customKey: PropTypes.string,
  rowCount: PropTypes.number,
  headLabel: PropTypes.array,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  showSelectAllCheckbox: PropTypes.bool,
  isAllSelected:PropTypes.bool,
  typographyVariant:PropTypes.oneOf(["button", "caption", "h1" , "h2" , "h3" , "h4" , "h5" , "h6" , "subtitle1", "subtitle2", "body1" , "body2"]),
  tableHeadTitle:PropTypes.string,
  showHeaderBorder:PropTypes.bool,
  tableHeadAlignment:PropTypes.oneOf(["inherit" , "center" , "left" , "right" , "justify"]),
  tableHeadTypoVariant:PropTypes.oneOf(["button", "caption", "h1" , "h2" , "h3" , "h4" , "h5" , "h6" , "subtitle1", "subtitle2", "body1" , "body2"]),
  backgroundColor:PropTypes.string,
  textColor:PropTypes.string,
  showSearchBox:PropTypes.bool,
  searchText:PropTypes.string,
  onSearchChange:PropTypes.func
};

export default function UserListHead({
  order,
  orderBy,
  rowCount,
  headLabel,
  numSelected,
  onRequestSort,
  onSelectAllClick,
  customKey,
  showSelectAllCheckbox,
  isAllSelected,
  typographyVariant,
  tableHeadTitle,
  showHeaderBorder,
  tableHeadAlignment,
  tableHeadTypoVariant,
  backgroundColor,
  textColor,
  showSearchBox,
  searchText,
  onSearchChange
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const [isSearchBoxExpanded, setIsSerachBoxExpanded] = React.useState<boolean>(false);

  const onClearClick = () =>{
    setIsSerachBoxExpanded(false);
    onSearchChange('');
  }

  return (
    <TableHead>
      {tableHeadTitle !== undefined && tableHeadTitle != null && tableHeadTitle.trim() != '' && <TableRow key={`${customKey}_1`}>
        <TableCell style={showHeaderBorder !== undefined && showHeaderBorder != null ? {borderBottom:'solid', backgroundColor:!!backgroundColor ? backgroundColor : undefined, color:!!textColor ? textColor: undefined} : {backgroundColor:!!backgroundColor ? backgroundColor : undefined, color:!!textColor ? textColor: undefined}} align={tableHeadAlignment !== undefined && tableHeadAlignment != null ? tableHeadAlignment : 'center'} colSpan={headLabel.length + (showSelectAllCheckbox == true ? 1 : 0)}><Typography variant={tableHeadTypoVariant === undefined || tableHeadTypoVariant == null ? 'h6' : tableHeadTypoVariant}>{tableHeadTitle}</Typography></TableCell>
      </TableRow>}
      <TableRow key={`${customKey}_2`}>
        {showSelectAllCheckbox == true && <TableCell style={{backgroundColor:!!backgroundColor ? backgroundColor : undefined, color:!!textColor ? textColor: undefined}} width={10} align="right">
          <Checkbox
            checked={isAllSelected}
            onChange={(event) => {
              if(onSelectAllClick){
                onSelectAllClick();
              }
            }}
          />
        </TableCell>
        }
        {headLabel.map((headCell, ind) => { return <TableCell
            key={headCell.id}
            align={headCell.alignContent}
            style={{backgroundColor:!!backgroundColor ? backgroundColor : undefined, color:!!textColor ? textColor: undefined}}
          >
            {(headCell.enableSort ?? false) == false && <Typography variant={typographyVariant === undefined || typographyVariant == null ? 'h6' : typographyVariant}>{headCell.label}</Typography>}
            {(headCell.enableSort ?? false) == true && <TableSortLabel
            style={{color:!!textColor ? textColor: undefined}}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              sx={{
                '& .MuiTableSortLabel-icon': {
                    color: 'white !important',
                },
            }}
            >
              <Typography variant={typographyVariant === undefined || typographyVariant == null ? 'h6' : typographyVariant}>{headCell.label}</Typography>
              
            </TableSortLabel>}
            {showSearchBox == true && isSearchBoxExpanded == true && headLabel.length - 1 == ind && <TextField
                style={{backgroundColor:'white', borderRadius:7, height:30}}
                        autoComplete="search_text"
                        size='small'
                        variant='outlined'
                        type={'text'}
                        label=""
                        value={searchText}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                            onSearchChange(event.target.value);
                        }}
                        onBlur={(e) => {
                          if(!e.target.value || e.target.value.trim() == ''){
                            setIsSerachBoxExpanded(false);
                          }
                        }}
                        autoFocus
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon icon={'akar-icons:search'} />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                !!searchText ? <InputAdornment position="end">
                                    <Icon onClick={onClearClick} style={{cursor:'pointer'}} icon={'ic:twotone-clear'} />
                                </InputAdornment> : undefined
                            ),
                            style:{height:30}
                        }}
                    />}
                    {showSearchBox == true && isSearchBoxExpanded == false && headLabel.length - 1 == ind && <Icon color='white' width={20} height={20} onClick={() => {setIsSerachBoxExpanded(true)}} style={{cursor:'pointer', marginTop:5}} icon={'material-symbols:search'} />}
          </TableCell>}
        )}
      </TableRow>
    </TableHead>
  );
}
