import {IAction} from './action.interface';
import {UserMenuActionConstants} from './action.constant';
import {IUserMenu} from '../../interfaces/routes/user-menu.interface';

export const setUserMenuAction = (
  payload: IUserMenu[],
): IAction<IUserMenu[]> => {
  return {
    type: UserMenuActionConstants.SET_USER_MENU,
    payload,
  };
};
