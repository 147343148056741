import React from 'react';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import arrowBackFill from '@iconify/icons-eva/arrow-back-fill';
import searchFill from '@iconify/icons-eva/search-fill';
import plusFill from '@iconify/icons-eva/plus-fill';
import refreshFill from '@iconify/icons-eva/refresh-fill';
import saveFill from '@iconify/icons-eva/save-fill';
import downloadFill from '@iconify/icons-eva/download-fill';
import closeFill from '@iconify/icons-eva/close-fill';
// material
import {
    Card,
    Table,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    Grid,
    Stack,
    Avatar,
    TextField,
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { UserListHead } from '../components/_dashboard/user';
//
import { LoadingButton } from '@mui/lab';
import { employeeApi, deliveryOrderApi, productApi, masterDataApi } from '../http';
import { IAutoComplete } from 'src/interfaces/auto-complete.interface';
import { formattedDate, getCommaSeparatedString, getCommaSeparatedUnitUniqueIdsExceptAll, getTotalAppliedSchemeQty, getTotalOrderAndSchemeReturnSeg } from 'src/utility';

import { useSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { IApplicationState } from 'src/redux';
import { IOrderBookerForDO } from 'src/interfaces/delivery-order/order-booker-do.interface';
import { Audio as AudoSpin } from 'react-loader-spinner'
import { IDODetail } from 'src/interfaces/delivery-order/do-detail.interface';
import BarcodeReader from 'react-barcode-reader'
import { IDeliveryOrderSummary } from 'src/interfaces/delivery-order/delivery-order-summary.interface';
import { IProductBarcode } from 'src/interfaces/product-barcode.interface';
import Label from 'src/components/Label';
import { sentenceCase } from 'change-case';
import { IProductWiseOrderDetail } from 'src/interfaces/product-wise-order-detail';
import { IOrderMaster } from 'src/interfaces/order-master.interface';
import { IProductScheme } from 'src/interfaces/product-scheme.interface';
import { confirmAction } from 'src/utility/confirm.util';
import { CustomAutoComplete } from 'src/components/common/CustomAutoComplete';
import { IEnum, IUserDetail } from 'src/interfaces';
import { IExtraQtyReason } from 'src/interfaces/extra-qty-reason';
import { AllOptionUniqueId, DoDetailFor, ExtraQtyReasons } from 'src/app.constant';
import { IReturnReceiveModel } from 'src/interfaces/return-receive-model';
import { IDeliveryBoySale } from 'src/interfaces/delivery-boy-sale';
import { MessageComponent } from 'src/components/messages.component';
import { IMessage } from 'src/interfaces/message.interface';
import { ISelectedUnitAccess } from 'src/interfaces/selected-unit-access.interface';
import { IDOResultWithDetail } from 'src/interfaces/delivery-order/do-result-with-detail.interface';
import { NoUnitSelectedComponent } from 'src/components/common/no-unit-selected.component';


// ----------------------------------------------------------------------

const TABLE_HEAD_OrderDetail = [
    { id: 'order_id', label: 'Order Id', alignContent: 'center' },
    { id: 'route_name', label: 'Route Name', alignContent: 'center' },
    { id: 'shop_name', label: 'Shop Name', alignContent: 'center' },
];

const TABLE_HEAD_ProductWise = [
    { id: 'sr', label: 'Sr.', alignContent: 'center' },
    { id: 'product_name', label: 'Product', alignContent: 'center' },
    { id: 'order_qty', label: 'Order Qty', alignContent: 'center' },
    { id: 'scheme_qty', label: 'Scheme Qty', alignContent: 'center' },
    { id: 'total_return_qty', label: 'Total Return Qty', alignContent: 'center' },
    { id: 'order_return_qty', label: 'Order Return Qty', alignContent: 'center' },
    { id: 'scheme_return_qty', label: 'Scheme Return Qty', alignContent: 'center' },
    { id: 'status', label: 'Validity', alignContent: 'center' },
    { id: 'action', label: '', alignContent: 'center' },
];

const TABLE_HEAD_ProductWiseForSelection = [
    { id: 'sr', label: 'Sr.', alignContent: 'center' },
    { id: 'order_1', label: 'Order Id', alignContent: 'center' },
    { id: 'shop_name', label: 'Shop Name', alignContent: 'center' },
    { id: 'product_name', label: 'Product', alignContent: 'center' },
    { id: 'order_qty', label: 'Order Qty', alignContent: 'center' },
    { id: 'scheme_qty', label: 'Scheme Qty', alignContent: 'center' },
    { id: 'order_return_qty', label: 'Order Return Qty', alignContent: 'center' },
    { id: 'scheme_return_qty', label: 'Scheme Return Qty', alignContent: 'center' },
    { id: 'total_return_qty', label: 'Total Return Qty', alignContent: 'center' },
    { id: 'action', label: '', alignContent: 'center' },
];

const TABLE_HEAD_DO = [
    { id: 'collapse', label: '', alignContent: 'center' },
    { id: 'sr', label: 'Sr.', alignContent: 'center' },
    { id: 'delivery_boy', label: 'Delivery Boy', alignContent: 'center' },
    { id: 'orders_date', label:'Orders Date', alignContent:'center'},
    { id: 'do_status', label: 'DO Status', alignContent: 'center' },
    { id: 'delete_do', label: '', alignContent: 'center' },
];

const TABLE_HEAD_DO_Return_Detail = [
    { id: 'sr', label: 'Sr.', alignContent: 'center' },
    { id: 'product', label: 'Product', alignContent: 'center' },
    { id: 'order_qty', label: 'Order Qty', alignContent: 'center' },
    { id: 'scheme_qty', label: 'Scheme Qty', alignContent: 'center' },
    { id: 'total_issued_qty', label: 'Issued Qty', alignContent: 'center' },
    { id: 'order_return_qty', label: 'Order Return Qty', alignContent: 'center' },
    { id: 'scheme_return_qty', label: 'Scheme Return Qty', alignContent: 'center' },
    { id: 'total_return_qty', label: 'Total Return Qty', alignContent: 'center' },
    { id: 'delivery_boy_sale_qty', label: 'Delivery Boy Sale Qty', alignContent: 'center' },
    { id: 'extra_piece_qty', label: 'Extra Piece Qty', alignContent: 'center' },
    { id: 'actual_return_qty', label: 'Net Return', alignContent: 'center' },
];

const TABLE_HEAD_PRODUCT_WISE_RETURN = [
    { id: 'sr', label: 'Sr.', alignContent: 'center' },
    { id: 'product_name', label: 'Product Name', alignContent: 'center' },
    { id: 'total_return_qty', label: 'Total Return Qty', alignContent: 'center' }
];

const TABLE_HEAD_PRODUCT_WISE_Delivery_Boy_Sale = [
    { id: 'sr', label: 'Sr.', alignContent: 'center' },
    { id: 'product_name', label: 'Product Name', alignContent: 'center' },
    { id: 'sale_qty', label: 'Sale Qty', alignContent: 'center' },
    { id: 'extra_qty', label: 'Extra Qty', alignContent: 'center' },
    { id: 'extra_qty_reason', label: 'Extra Qty Reason', alignContent: 'center' },
    { id: 'status', label: 'Status', alignContent: 'center' },
];

// ----------------------------------------------------------------------

interface IProductWiseReturn {
    ProductId: number;
    ProductName: string;
    TotalReturnQty: number;
    ScannedQty: number;
}

interface IProps{
    loginUser:IUserDetail | null;
    selectedUnits:ISelectedUnitAccess;
  }

const ReceiveReturn = (props:IProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const showErrorMessage = (message: string) => addMessage(message,'error');
    const showInfoMessage = (message: string) => addMessage(message,'info');
    const showSuccessMessage = (message: string) => addMessage(message,'success');
    const showWarningMessage = (message: string) => addMessage(message,'warning');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isReceiving, setIsReceiving] = React.useState(false);
    const [selectedDO, setSelectedDO] = React.useState<IDODetail>(null);
    const [doDetail, setDODetails] = React.useState<IDOResultWithDetail>(null);
    const addMessage = (messageText:string, variant:'success' | 'error' | 'info' | 'warning') =>{
        if(!!messageText && messageText.trim() != ''){
            let msgs = [];
            msgs.push({
                messageText,
                variant
            });

            setPageMessages([...msgs]);
        }
    }

    const resetEverything = () => {
        setDODetails(null);
        setIsReceiving(false);
        setSelectedDO(null);
        getDeliveryOrders();
        setOrderNumber(null);
        setOrderForReturn(null);
        setSelectedReturnList([]);
        setProductWiseReturn([]);
        setIsViewingSummary(false);
        // setPageMessages([]);
    }

    const onRefresh = () => {
        resetEverything();
        setPageMessages([]);
    }

    const backToList = () => {
        scrollToTop();
        confirmAction('DO Return', "You haven't saved the return details. Are you sure you want to go back?", "Yes", "No")
            .then(res => {
                if (res == true) {
                    setPageMessages([]);
                    resetEverything();
                }
            })
    }


    const getDeliveryOrders = () => {
        setIsLoading(true);
        deliveryOrderApi.getDeliveryOrders(props.loginUser.UserId,getCommaSeparatedUnitUniqueIdsExceptAll([...props.selectedUnits.UnitAccesses.map(a => a)]),DoDetailFor.StockReturn,null,null,null,null)
          .then(doResults => {
            setIsLoading(false);
            if (!!doResults) {
              setDODetails({...doResults});
            }
            else {
              setDODetails(null);
            }
          })
          .catch(e => {
            console.log(e);
            setIsLoading(false);
          });
      }

    const getProductBarcodes = () => {
        productApi.getProductBarcodes()
            .then(barcodes => {
                setProductBarcodes([...barcodes]);
            })
            .catch(e => console.log(e));
    }

    const receiveReturn = (doId: number) => {
        setPageMessages([]);
        const filteredDO = doDetail.DeliveryOrders.find(m => m.DeliveryOrderId == doId);

        if (!!filteredDO && !!filteredDO.DOSummary && filteredDO.DOSummary.length > 0) {
            setIsReceiving(true);

            filteredDO.DOSummary.forEach(s => {
                s.ScannedQty = 0;
                s.IsValidDeliveryBoySale = true;
            });

            if(!!allUnitsProductSchemes && allUnitsProductSchemes.length > 0){
                setProductSchemes([...allUnitsProductSchemes.filter(s => s.UnitUniqueId == filteredDO.UnitUniqueId)]);
            }else{
                setProductSchemes([]);
            }

            setSelectedDO({ ...filteredDO });
            setOrderNumber(null);
            setOrderForReturn(null);
        } else {
            showErrorMessage('No Details Found');
        }
    }

    const saveReturnReceiving = () => {
        if ((!selectedReturnList || selectedReturnList.length <= 0)) {
            scrollToTop();
            confirmAction('DO Extra Qty Only', "You haven't added any return detail. And you'll not be able to add return after save. Are you sure you want to save Extra Qty only?", "Yes", "No")
                .then(rest => {
                    if (rest == true) {
                        const isValid = validateDeliveryBoySale([...selectedDO.DOSummary]);

                        if (isValid.isValid == true) {
                            saveReturnReceivingAfterChecks();
                        } else {
                            showErrorMessage(isValid.message);
                        }
                    }
                })
        } else {
            scrollToTop();
            confirmAction('DO Return', "Are you sure you want to save return?", 'Yes', 'No')
                .then(isConfirmed => {
                    if (isConfirmed == true) {
                        const isValid = validateDeliveryBoySale([...selectedDO.DOSummary]);

                        if (isValid.isValid == true) {
                            saveReturnReceivingAfterChecks();
                        } else {
                            showErrorMessage(isValid.message);
                        }
                    }
                });
        }
    }

    const saveReturnReceivingAfterChecks = () => {
        setIsLoading(true);
        let returnReceiving = [];

        if (!!selectedReturnList && selectedReturnList.length > 0) {
            returnReceiving = [...selectedReturnList];
        }

        let deliveryBoySale: IDeliveryBoySale[] = [];

        if (!!selectedDO && !!selectedDO.DOSummary && selectedDO.DOSummary.length > 0) {
            selectedDO.DOSummary.forEach(ds => {
                if (ds.IsValidDeliveryBoySale == true && (ds.ExtraQty > 0 || ds.DeliveryBoySaleQty > 0)) {
                    deliveryBoySale.push({
                        Id: 0,
                        DeliveryBoyId: 0,
                        DOId: 0,
                        ExtraQty: isNaN(ds.ExtraQty) ? 0 : (ds.ExtraQty ?? 0),
                        ExtraQtyReasonId: isNaN(ds.ExtraQtyReasonId) ? 0 : (ds.ExtraQtyReasonId ?? 0),
                        SaleQty: isNaN(ds.DeliveryBoySaleQty) ? 0 : (ds.DeliveryBoySaleQty ?? 0),
                        ProductId: ds.ProductId,
                        RecordedById: 0,
                        RecordedOn: new Date(),
                        RetailPrice: 0,
                        WholeSaleMinQty: 0,
                        WholeSalePrice: 0
                    });
                }
            });
        }

        const returnReceiveModel: IReturnReceiveModel = {
            ProductWiseOrderReturnDetail: [...returnReceiving],
            DeliveryBoySale: [...deliveryBoySale]
        };

        deliveryOrderApi.saveReturnReceiving(props.loginUser.UserId, selectedDO.DeliveryOrderId, returnReceiveModel)
            .then(res => {
                setIsLoading(false);

                if (res.IsOk == true) {
                    const msg = `Return Received Successfully From ${selectedDO.DeliveryBoy} Against DO# ${selectedDO.DeliveryOrderId}.`;
                    showInfoMessage(msg);
                    resetEverything();
                } else {
                    showErrorMessage(res.StatusMessage);
                }
            })
            .catch(e => {
                setIsLoading(false);
                console.log(e);
            });
    }

    const [productBarcodes, setProductBarcodes] = React.useState<IProductBarcode[]>([]);
    const [extraQtyReasons, setExtraQtyReasons] = React.useState<IExtraQtyReason[]>([]);
    const handleBarcodeScan = (data: any) => {
        const barcode = productBarcodes.find(m => m.Barcode == data);

        if (!!barcode && barcode.ProductId > 0) {
            const doSummary = [...productWiseReturn];
            const summary = doSummary.find(m => m.ProductId = barcode.ProductId);
            if (summary.ScannedQty + barcode.Qty > summary.TotalReturnQty) {
                showErrorMessage("Scanned Qty Can't be more than Total Return Qty");
                // let audio = new Audio("../assets/ScannedMoreThanIssue.mp3");
                //     audio.play();
                //play sound here
            } else {
                summary.ScannedQty += barcode.Qty;
                setProductWiseReturn([...doSummary]);

                if (summary.ScannedQty === summary.TotalReturnQty) {
                    // let audio = new Audio("../assets/StopNimalWhite132Gram.mp3");
                    // audio.play();
                }
            }
        }
        else {
            showErrorMessage('Product Not Found');
        }
    }

    const scrollToTop = () =>{
        window.scrollTo(0,0);
      }

    const handleScanError = (err: any) => {
        console.log(err);
    }

    const getProductSchemes = () => {
        productApi.getProductSchemes(getCommaSeparatedUnitUniqueIdsExceptAll(props.selectedUnits.UnitAccesses))
            .then(s => {
                setAllUnitsProductSchemes([...s]);
            })
            .catch(e => console.log(e));
    }

    const getExtraQtyReasons = () => {
        masterDataApi.getExtraQtyReasons()
            .then(qr => {
                setExtraQtyReasons([...qr]);
            })
            .catch(ex => console.log(ex));
    }

    React.useEffect(() => {
        getProductBarcodes();
        getExtraQtyReasons();
    }, []);

    React.useEffect(() => {
        getDeliveryOrders();
        getProductSchemes();
    },[props.selectedUnits.UnitAccesses])

    const addMessageToQueue = (message:string, isError:boolean) =>{
        const msgs = [pageMessages];
    }


    const [productSchemes, setProductSchemes] = React.useState<IProductScheme[]>();
    const [allUnitsProductSchemes, setAllUnitsProductSchemes] = React.useState<IProductScheme[]>();
    const [orderNumber, setOrderNumber] = React.useState<number>(null);
    const [orderForReturn, setOrderForReturn] = React.useState<IOrderMaster>(null);
    const getOrderDetails = () => {
        setPageMessages([]);
        setOrderForReturn(null);
        if (orderNumber > 0) {
            if (!!selectedReturnList && selectedReturnList.length > 0 && !!selectedReturnList.find(m => m.OrderId == orderNumber)) {
                showErrorMessage(`Order Return For Order Number:${orderNumber} Already Added`);
                return;
            }

            setIsLoading(true);
            deliveryOrderApi.getOrderDetailsForStockReturn(selectedDO.DeliveryOrderId, orderNumber)
                .then(detailForReturn => {
                    setIsLoading(false);

                    if (!!detailForReturn) {
                        let productDetails = [...detailForReturn.ProductWiseOrderDetails];
                        productDetails.forEach(pd => {
                            pd.IsReturnOk = false;
                        });

                        setOrderForReturn({ ...detailForReturn, ProductWiseOrderDetails: [...productDetails] });
                    }else{
                        showErrorMessage(`Order Not Found For Order Number:${orderNumber}`)
                    }
                })
                .catch(e => {
                    setIsLoading(false);
                    showErrorMessage('Order Not Found.');
                    console.log(e);
                });
        } else {
            showErrorMessage('Please Enter Order Number');
        }
    }

    const handleReturnedQtyChange = (totalReturnQty: number, productId: number, orderedOn: Date) => {
        setPageMessages([]);
        let productWiseDetail = [...orderForReturn.ProductWiseOrderDetails.map(a => a)];
        let productRecord = productWiseDetail.filter(m => m.ProductId == productId)[0];
        productRecord.TotalReturnQty = totalReturnQty;

        if (isNaN(totalReturnQty)) {
            productRecord.OrderReturnQty = 0;
            productRecord.SchemeReturnQty = 0;
            productRecord.DeliveredQty = productRecord.OrderQty;
        } else {
            const seg = getTotalOrderAndSchemeReturnSeg(productId, (((productRecord.OrderQty ?? 0) + (productRecord.SchemeQty ?? 0)) - totalReturnQty), orderedOn, productSchemes);
            productRecord.OrderReturnQty = (productRecord.OrderQty ?? 0) - (seg.OrderQty ?? 0);
            productRecord.SchemeReturnQty = (productRecord.SchemeQty ?? 0) - (seg.SchemeQty ?? 0);
            productRecord.DeliveredQty = (seg.OrderQty ?? 0);
        }

        setOrderForReturn({ ...orderForReturn, ProductWiseOrderDetails: [...validateReturn(productWiseDetail)] });
    }

    const handleSchemeReturnedQtyChange = (schemeReturnQty: number, productId: number) => {
        let productWiseDetail = [...orderForReturn.ProductWiseOrderDetails];
        let productRecord = productWiseDetail.filter(m => m.ProductId == productId)[0];
        productRecord.SchemeReturnQty = schemeReturnQty;

        if (isNaN(schemeReturnQty)) {
            productRecord.TotalReturnQty = productRecord.OrderReturnQty ?? 0;
        } else {
            productRecord.TotalReturnQty = productRecord.OrderReturnQty + productRecord.SchemeReturnQty;
        }

        setOrderForReturn({ ...orderForReturn, ProductWiseOrderDetails: [...validateReturn(productWiseDetail)] });
    }

    const [selectedReturnList, setSelectedReturnList] = React.useState<IProductWiseOrderDetail[]>([]);

    const validateReturn = (productWiseDetail: IProductWiseOrderDetail[]) => {
        productWiseDetail.forEach(productToAdd => {
            if (productToAdd.OrderReturnQty > 0) {
                if (productToAdd.OrderReturnQty > productToAdd.OrderQty) {
                    productToAdd.IsReturnOk = false;
                } else {
                    if (productToAdd.SchemeReturnQty > productToAdd.SchemeQty) {
                        productToAdd.IsReturnOk = false;
                    } else {
                        if (productToAdd.SchemeQty > 0) {
                            const appliedSchemesTotal = getTotalAppliedSchemeQty(productToAdd.ProductId, productToAdd.DeliveredQty, orderForReturn.OrderedOn, productSchemes);

                            if (appliedSchemesTotal.IsApplied == true) {
                                if ((productToAdd.SchemeQty - appliedSchemesTotal.TotalSchemeQty) != productToAdd.SchemeReturnQty) {
                                    productToAdd.IsReturnOk = false;
                                } else {
                                    //add it
                                    productToAdd.IsReturnOk = true;
                                }
                            } else {
                                if (productToAdd.SchemeQty != productToAdd.SchemeReturnQty) {
                                    productToAdd.IsReturnOk = false;
                                } else {
                                    // add it
                                    productToAdd.IsReturnOk = true;
                                }
                            }
                        } else {
                            if (productToAdd.SchemeReturnQty > 0) {
                                productToAdd.IsReturnOk = false;
                            } else {
                                //add it
                                productToAdd.IsReturnOk = true;
                            }
                        }
                    }
                }
            } else {
                productToAdd.IsReturnOk = false;
            }
        });

        return productWiseDetail;
    }

    const addToReturnList = (orderId: number, productId: number) => {
        const existingOrder = selectedReturnList.find(m => m.OrderId == orderId && m.ProductId == productId);
        setPageMessages([]);
        if (!!existingOrder) {
            showErrorMessage('Product Already Exists in return list');
        } else {
            const productToAdd = orderForReturn.ProductWiseOrderDetails.find(m => m.ProductId == productId && m.OrderId == orderId);

            if (productToAdd.OrderReturnQty > 0) {
                if (productToAdd.OrderReturnQty > productToAdd.OrderQty) {
                    showErrorMessage("Order Return Qty Can't be greater than Order Qty");
                } else {
                    if (productToAdd.SchemeReturnQty > productToAdd.SchemeQty) {
                        showErrorMessage("Scheme Return Qty Can't be greater than Scheme Qty");
                    } else {
                        if (productToAdd.SchemeQty > 0) {
                            const appliedSchemesTotal = getTotalAppliedSchemeQty(productToAdd.ProductId, productToAdd.DeliveredQty, orderForReturn.OrderedOn, productSchemes);

                            if (appliedSchemesTotal.IsApplied == true) {
                                if ((productToAdd.SchemeQty - appliedSchemesTotal.TotalSchemeQty) != productToAdd.SchemeReturnQty) {
                                    showErrorMessage("Invalid Scheme Return Qty");
                                } else {
                                    //add it
                                    addProductRecordToSelectionListAfterChecks({ ...productToAdd }, productId);
                                }
                            } else {
                                if (productToAdd.SchemeQty != productToAdd.SchemeReturnQty) {
                                    showErrorMessage("Invalid Scheme Return Qty");
                                } else {
                                    // add it
                                    addProductRecordToSelectionListAfterChecks({ ...productToAdd }, productId);
                                }
                            }
                        } else {
                            if (productToAdd.SchemeReturnQty > 0) {
                                showErrorMessage("Invalid Scheme Return Qty");
                            } else {
                                //add it
                                addProductRecordToSelectionListAfterChecks({ ...productToAdd }, productId);
                            }
                        }
                    }
                }
            } else {
                showErrorMessage('Please Enter Return Qty');
            }
        }
    }

    const addProductRecordToSelectionListAfterChecks = (toAdd: IProductWiseOrderDetail, productId: number) => {
        const toUpdate = [...selectedReturnList, { ...toAdd, ShopName: orderForReturn.ShopTitle, SchemeReturnQty: (isNaN(toAdd.SchemeReturnQty) ? 0 : (toAdd.SchemeReturnQty ?? 0)), OrderReturnQty: (isNaN(toAdd.OrderReturnQty) ? 0 : (toAdd.OrderReturnQty ?? 0)) }];
        setSelectedReturnList([...toUpdate]);
        calculateAndSetProductWiseReturn([...toUpdate]);

        const orderForReturnProductWise = [...orderForReturn.ProductWiseOrderDetails.filter(m => m.ProductId != productId)];

        if (!!orderForReturnProductWise && orderForReturnProductWise.length > 0) {
            setOrderForReturn({ ...orderForReturn, ProductWiseOrderDetails: [...orderForReturnProductWise] });
        } else {
            setOrderForReturn(null);
            setOrderNumber(null);
        }
    }

    const removeFromReturnList = (productId: number, orderId: number) => {
        let copy = [...selectedReturnList];
        const indexToRemove = copy.findIndex(m => m.OrderId == orderId && m.ProductId == productId);
        copy.splice(indexToRemove, 1);
        setSelectedReturnList([...copy]);
        calculateAndSetProductWiseReturn([...copy]);
    }

    const calculateAndSetProductWiseReturn = (details: IProductWiseOrderDetail[]) => {
        if (!!details && details.length > 0) {
            let returns: IProductWiseReturn[] = [];
            details.forEach(d => {
                const existingProductReturn = returns.filter(m => m.ProductId == d.ProductId);
                if (!!existingProductReturn && existingProductReturn.length > 0) {
                    existingProductReturn[0].TotalReturnQty = (existingProductReturn[0].TotalReturnQty ?? 0) + (d.TotalReturnQty ?? 0);
                } else {
                    returns.push({
                        ProductId: d.ProductId,
                        ProductName: d.ProductName,
                        TotalReturnQty: d.TotalReturnQty,
                        ScannedQty: 0
                    });
                }
            });

            setProductWiseReturn([...returns]);
            let list = [...selectedDO.DOSummary];
            validateDeliveryBoySale(list, [...returns]);
            setSelectedDO({ ...selectedDO, DOSummary: [...list] });
        } else {
            setProductWiseReturn([]);
            let list = [...selectedDO.DOSummary];
            validateDeliveryBoySale(list, []);
            setSelectedDO({ ...selectedDO, DOSummary: [...list] });
        }
    }

    const [productWiseReturn, setProductWiseReturn] = React.useState<IProductWiseReturn[]>([]);
    const handleDeliveryBoySaleChange = (value: number, productId: number) => {
        setPageMessages([]);
        let list = [...selectedDO.DOSummary];
        let item = list.find(m => m.ProductId == productId);
        item.DeliveryBoySaleQty = value;
        validateDeliveryBoySale(list);
        setSelectedDO({ ...selectedDO, DOSummary: [...list] });
    }

    const handleDeliveryBoyExtraQtyChange = (value: number, productId: number) => {
        setPageMessages([]);
        let list = [...selectedDO.DOSummary];
        let item = list.find(m => m.ProductId == productId);
        item.ExtraQty = value;
        validateDeliveryBoySale(list);
        setSelectedDO({ ...selectedDO, DOSummary: [...list] });
    }

    const handleExtraQtyReasonChange = (value: number, productId: number) => {
        setPageMessages([]);
        let list = [...selectedDO.DOSummary];
        let item = list.find(m => m.ProductId == productId);
        item.ExtraQtyReasonId = value;
        validateDeliveryBoySale(list);
        setSelectedDO({ ...selectedDO, DOSummary: [...list] });
    }

    const validateDeliveryBoySale = (summary: IDeliveryOrderSummary[], returnProductWise: IProductWiseReturn[] = null) => {
        let toReturn = {
            isValid: true,
            message: 'Valid Data',
        };

        const pWiseReturn = (!!returnProductWise) ? [...returnProductWise] : [...productWiseReturn];

        if (!!summary && summary.length > 0) {
            summary.forEach(s => {
                s.IsValidDeliveryBoySale = true;
                if (s.ExtraQty > 0 || s.DeliveryBoySaleQty > 0) {
                    if (s.DeliveryBoySaleQty > 0) {
                        if (!!pWiseReturn && pWiseReturn.length > 0) {
                            let selectedProductReturn = pWiseReturn.filter(m => m.ProductId == s.ProductId);

                            if (!!selectedProductReturn && selectedProductReturn.length > 0) {
                                let productTotalReturnQty = selectedProductReturn[0].TotalReturnQty;

                                if (s.DeliveryBoySaleQty > productTotalReturnQty) {
                                    if (toReturn.isValid == true) {
                                        toReturn.isValid = false;
                                        toReturn.message = "Sale Qty Can't be greater than product return qty.";
                                    }

                                    s.IsValidDeliveryBoySale = false;
                                }
                            } else {
                                if (toReturn.isValid == true) {
                                    toReturn.isValid = false;
                                    toReturn.message = "No Return Found For Sale";
                                }

                                s.IsValidDeliveryBoySale = false;
                            }
                        } else {
                            if (toReturn.isValid == true) {
                                toReturn.isValid = false;
                                toReturn.message = "No Return Found For Sale";
                            }

                            s.IsValidDeliveryBoySale = false;
                        }
                    }

                    if (s.ExtraQty > 0) {
                        if (isNaN(s.ExtraQtyReasonId) || s.ExtraQtyReasonId <= 0) {
                            if (toReturn.isValid == true) {
                                toReturn.isValid = false;
                                toReturn.message = "Please Select Extra Qty Reason";
                            }

                            s.IsValidDeliveryBoySale = false;
                        } else {
                            if (s.ExtraQtyReasonId == ExtraQtyReasons.ForgotToGiveScheme) {
                                const productSummary = selectedDO.DOSummary.filter(m => m.ProductId == s.ProductId);

                                if (!!productSummary && productSummary.length > 0) {
                                    let totalSchemeQty = 0;
                                    productSummary.forEach(ps => {
                                        totalSchemeQty += ps.TotalSchemeQty;
                                    });

                                    if (s.ExtraQty > totalSchemeQty) {
                                        if (toReturn.isValid == true) {
                                            toReturn.isValid = false;
                                            toReturn.message = "No Scheme Found For Product In Issuance.";
                                        }

                                        s.IsValidDeliveryBoySale = false;
                                    }
                                } else {
                                    if (toReturn.isValid == true) {
                                        toReturn.isValid = false;
                                        toReturn.message = "Product Details Not Found In Issuance";
                                    }

                                    s.IsValidDeliveryBoySale = false;
                                }

                            } else if (s.ExtraQtyReasonId == ExtraQtyReasons.InvalidDelivery) {
                                const productSummary = selectedDO.DOSummary.filter(m => m.ProductId == s.ProductId);

                                if (!!productSummary && productSummary.length > 0) {
                                    let totalIssuedQty = 0;
                                    productSummary.forEach(ps => {
                                        totalIssuedQty += (ps.TotalSchemeQty + ps.TotalOrderQty);
                                    });

                                    if (s.ExtraQty > totalIssuedQty) {
                                        if (toReturn.isValid == true) {
                                            toReturn.isValid = false;
                                            toReturn.message = "Extra Qty Can't be greater than total Issuance.";
                                        }

                                        s.IsValidDeliveryBoySale = false;
                                    }
                                } else {
                                    if (toReturn.isValid == true) {
                                        toReturn.isValid = false;
                                        toReturn.message = "Product Details Not Found In Issuance";
                                    }

                                    s.IsValidDeliveryBoySale = false;
                                }
                            }
                        }
                    }
                }
            });
        }

        return toReturn;
    }

    const getDoDetailCopy = () =>{
        let detail = {...doDetail,DeliveryOrders:[...doDetail.DeliveryOrders.map(a => a)],CreditWithProductWiseSummary:{...doDetail.CreditWithProductWiseSummary,ProductWiseSummaries:[...doDetail.CreditWithProductWiseSummary.ProductWiseSummaries.map(a => a)]},UnitWiseSummary:[...doDetail.UnitWiseSummary.map(a => a)]};
        return detail;
      }


      const handleCollapsed = (doId: number) => {
        let detail = getDoDetailCopy();
        let dOrder = detail.DeliveryOrders.find(d => d.DeliveryOrderId == doId);
        let existingStatus = dOrder.IsExpanded;
        dOrder.IsExpanded = existingStatus == undefined || existingStatus == null ? true : !existingStatus;
        setDODetails(detail);
        setPageMessages([]);
    }
    
    const onViewSummaryClick = () => {
        setIsViewingSummary(true);
        setPageMessages([]);
    }
    
    const onViewDOClick = () => {
        resetEverything();
        setPageMessages([]);
    }

    const [isViewingSummary, setIsViewingSummary] = React.useState<boolean>(false);
    const [pageMessages,setPageMessages] = React.useState<IMessage[]>([]);

    return (
        (!!props.selectedUnits && !!props.selectedUnits.UnitAccesses && props.selectedUnits.UnitAccesses.filter(u => u.UnitUniqueId != AllOptionUniqueId).length > 0) ? <>
        <MessageComponent onRemove={(i:number) => {
            let msgs = [...pageMessages];
            msgs.splice(i,1);
            setPageMessages([...msgs]);
        }} messages={pageMessages} />

        <Page title="DO | Raza Pak Care">
            {isReceiving == false && isViewingSummary == false && <Container style={{ maxWidth: 1500, paddingLeft: 0, paddingRight: 0 }}>
                <Grid container style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>

                    <LoadingButton
                        size="small"
                        type="button"
                        variant="contained"
                        loading={isLoading}
                        style={{ margin: 3, backgroundColor: 'rgba(34, 154, 22, 0.72)', textAlign: 'right', height: 40 }}
                        startIcon={<Icon icon={refreshFill} />}
                        onClick={onRefresh}
                    >
                        Refresh
                    </LoadingButton>

                    <LoadingButton
                        size="small"
                        type="button"
                        variant="contained"
                        loading={isLoading}
                        style={{ margin: 3, backgroundColor: 'blue', textAlign: 'right', height: 40 }}
                        startIcon={<Icon icon='ooui:text-summary-ltr' />}
                        onClick={onViewSummaryClick}
                    >
                        View Summary
                    </LoadingButton></Grid>
                    {!!props.selectedUnits && !!props.selectedUnits.UnitAccesses && props.selectedUnits.UnitAccesses.filter(m => m.UnitUniqueId != AllOptionUniqueId).length > 0 && props.selectedUnits.UnitAccesses.filter(m => m.UnitUniqueId != AllOptionUniqueId).map((selectedUnit,unitIndex) => {
                        return <><Card>
                    <Typography style={{ backgroundColor: 'rgba(34, 154, 22, 0.72)', color: 'white', paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h4'}>DO (Return Receive) For {selectedUnit.UnitTitle}</Typography>
                    {isLoading == true && <AudoSpin
                        height="80"
                        width="80"
                        color='green'
                        ariaLabel='three-dots-loading'
                        wrapperStyle={{ alignSelf: 'center', justifyContent: 'center' }}
                    />}

                    {(!doDetail || !doDetail.DeliveryOrders || doDetail.DeliveryOrders.length <= 0 || doDetail.DeliveryOrders.filter(u => u.UnitUniqueId == selectedUnit.UnitUniqueId).length <= 0) && isLoading == false && <Typography style={{ paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h6'}>No DO to receive return for "{selectedUnit.UnitTitle}" yet</Typography>}

                    {!!doDetail && !!doDetail.DeliveryOrders && doDetail.DeliveryOrders.length > 0 && doDetail.DeliveryOrders.filter(u => u.UnitUniqueId == selectedUnit.UnitUniqueId).length > 0 && <Scrollbar sx={{}}>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    order={'asc'}
                                    orderBy={''}
                                    headLabel={TABLE_HEAD_DO}
                                    rowCount={0}
                                    numSelected={0}
                                    onRequestSort={undefined}
                                    onSelectAllClick={undefined}
                                    customKey={`TableHeadRow_2`}
                                />
                                <TableBody>
                                    {!!doDetail && !!doDetail.DeliveryOrders && doDetail.DeliveryOrders.length > 0 && doDetail.DeliveryOrders.filter(u => u.UnitUniqueId == selectedUnit.UnitUniqueId).length > 0 && doDetail.DeliveryOrders.filter(u => u.UnitUniqueId == selectedUnit.UnitUniqueId).map((row, index) => {

                                        return (
                                            <><TableRow
                                                hover
                                                key={row.DeliveryOrderId}
                                                tabIndex={-1}
                                                role="checkbox"
                                                selected={false}
                                                aria-checked={false}
                                            >
                                                <TableCell width={10} align="right">
                                                    {(row.IsExpanded == true && row.DOStatusId != 3) && <Icon color='green' onClick={() => { handleCollapsed(row.DeliveryOrderId) }} style={{ cursor: 'pointer' }} width={22} height={22} icon={'akar-icons:arrow-up'} />}
                                                    {(!row.IsExpanded  && row.DOStatusId != 3) && <Icon color='blue' onClick={() => { handleCollapsed(row.DeliveryOrderId) }} style={{ cursor: 'pointer' }} width={22} height={22} icon={'akar-icons:arrow-down'} />}
                                                </TableCell>
                                                <TableCell align="center">{index + 1}</TableCell>
                                                <TableCell align="center" component="th" scope="row" padding="none">
                                                    <Stack direction="row" alignItems="center" justifyContent={'center'} spacing={2}>
                                                        <Avatar alt={row?.DeliveryBoy} src={row?.DeliveryBoyImageUrl} />
                                                        <Typography>{row?.DeliveryBoy ?? ''}</Typography>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell align="center" component="th" scope="row" padding="none">
                                                    {formattedDate(row.OrdersDate,'ddd DD-MM-YYYY')}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Label
                                                        variant="ghost"
                                                        color={'success'}
                                                    >
                                                        {sentenceCase(row?.DOStatus ?? '')}
                                                    </Label>
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.DOStatusId == 3 && <LoadingButton
                                                        size="small"
                                                        type="button"
                                                        variant="contained"
                                                        loading={isLoading}
                                                        style={{ margin: 10, backgroundColor: 'blue' }}
                                                        startIcon={<Icon icon='fontisto:arrow-return-left' />}
                                                        onClick={() => { receiveReturn(row.DeliveryOrderId); }}
                                                    >
                                                        Receive Return
                                                    </LoadingButton>}
                                                    {row.DOStatusId != 3 && <Icon icon="teenyicons:tick-circle-solid" color='green' width={30} height={30} />}
                                                </TableCell>
                                            </TableRow>
                                                {row.IsExpanded == true && <TableRow>
                                                    <TableCell colSpan={6}>
                                                        {<TableContainer sx={{ minWidth: 800 }}>
                                                            <Table>
                                                                <UserListHead
                                                                    order={'asc'}
                                                                    orderBy={''}
                                                                    headLabel={TABLE_HEAD_DO_Return_Detail}
                                                                    rowCount={0}
                                                                    numSelected={0}
                                                                    onRequestSort={undefined}
                                                                    onSelectAllClick={undefined}
                                                                    customKey={`TableHeadRow_2_Unique`}
                                                                />
                                                                <TableBody>
                                                                    {!!row.DOSummary && row.DOSummary.length > 0 && row.DOSummary.map((sRow, sIndex) => {

                                                                        return (
                                                                            <TableRow
                                                                                hover
                                                                                key={row.DeliveryOrderId}
                                                                                tabIndex={-1}
                                                                                role="checkbox"
                                                                                selected={false}
                                                                                aria-checked={false}
                                                                            >
                                                                                <TableCell width={10} align="center">
                                                                                    {sIndex + 1}
                                                                                </TableCell>
                                                                                <TableCell align="center">{sRow.ProductName}</TableCell>
                                                                                <TableCell align="center">{sRow.TotalOrderQty}</TableCell>
                                                                                <TableCell align="center" component="th" scope="row" padding="none">
                                                                                    {sRow.TotalSchemeQty}
                                                                                </TableCell>
                                                                                <TableCell align="center">
                                                                                    {sRow.TotalQtyToIssue}
                                                                                </TableCell>
                                                                                <TableCell align="center">
                                                                                    {sRow.TotalReturnedQty}
                                                                                </TableCell>
                                                                                <TableCell align="center">
                                                                                    {sRow.TotalSchemeReturnedQty}
                                                                                </TableCell>
                                                                                <TableCell align="center">
                                                                                    {(sRow.TotalReturnedQty + sRow.TotalSchemeReturnedQty)}
                                                                                </TableCell>
                                                                                <TableCell align="center">
                                                                                    {sRow.TotalDeliveryBoySaleQty}
                                                                                </TableCell>
                                                                                <TableCell align="center">
                                                                                    {sRow.TotalDeliveryBoyExtraQty}
                                                                                </TableCell>
                                                                                <TableCell align="center" style={{color:'red'}}>
                                                                                    <Typography variant='h6'>{(sRow.TotalReturnedQty + sRow.TotalSchemeReturnedQty + sRow.TotalDeliveryBoyExtraQty) - sRow.TotalDeliveryBoySaleQty}</Typography>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        );
                                                                    })}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>}
                                                    </TableCell>
                                                </TableRow>}</>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Scrollbar>}
                </Card><br /></>})}
            </Container>}


            {isReceiving == true && isViewingSummary == false && <Container style={{ maxWidth: 1500, paddingLeft: 0, paddingRight: 0 }}>
                <Grid container style={{ flexDirection: 'row', justifyContent: 'flex-end' }}><LoadingButton
                    size="small"
                    type="button"
                    variant="contained"
                    loading={isLoading}
                    style={{ margin: 3, backgroundColor: 'rgba(34, 154, 22, 0.72)', textAlign: 'right', height: 40 }}
                    startIcon={<Icon icon={arrowBackFill} />}
                    onClick={backToList}
                >
                    Back To List
                </LoadingButton></Grid>
                <Card>
                    <Typography style={{ backgroundColor: 'rgba(34, 154, 22, 0.72)', color: 'white', paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h4'}>Receive Return ({selectedDO.UnitTitle})</Typography>

                    {isLoading == true && <AudoSpin
                        height="80"
                        width="80"
                        color='green'
                        ariaLabel='three-dots-loading'
                        wrapperStyle={{ alignSelf: 'center', justifyContent: 'center' }}
                    />}
                    <Container style={{ marginTop: 15 }}>
                        <Grid container>
                            <Grid item md={6}>
                                <Typography><strong>DO#:</strong> {selectedDO.DeliveryOrderId}</Typography>
                            </Grid>
                            <Grid item md={6}>
                                <Stack direction="row" alignItems="center" justifyContent={'center'} spacing={2}>
                                    <Typography><strong>Delivery Boy:</strong> </Typography>
                                    <Avatar alt={selectedDO?.DeliveryBoy} src={selectedDO?.DeliveryBoyImageUrl} />
                                    <Typography>{selectedDO?.DeliveryBoy ?? ''}</Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Container>

                    <Container style={{ marginBottom: 10 }}>
                        <Grid container>
                            <Grid item md={4} sm={12}><TextField
                                fullWidth
                                autoComplete="order-number"
                                type={'number'}
                                label="Order Number"
                                value={orderNumber > 0 ? orderNumber : ''}
                                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                    const value = event.target.value;
                                    let numberValue: number | null = null;

                                    if (value !== undefined && value != null) {
                                        numberValue = parseInt(value);
                                    }

                                    setOrderNumber(numberValue);
                                    setPageMessages([]);
                                }}
                            /></Grid>

                            <Grid item md={4} sm={12}>
                                <LoadingButton
                                    size="small"
                                    type="button"
                                    variant="contained"
                                    loading={isLoading}
                                    style={{ margin: 4, marginLeft: 15, width: 150, height: 50 }}
                                    startIcon={<Icon icon={searchFill} />}
                                    onClick={getOrderDetails}
                                >
                                    Get Details
                                </LoadingButton>
                            </Grid>

                        </Grid>
                    </Container>

                    {!!orderForReturn && !!orderForReturn.ProductWiseOrderDetails && orderForReturn.ProductWiseOrderDetails.length > 0 && <Scrollbar sx={{}}>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    order={'asc'}
                                    orderBy={''}
                                    headLabel={TABLE_HEAD_OrderDetail}
                                    rowCount={0}
                                    numSelected={0}
                                    onRequestSort={undefined}
                                    onSelectAllClick={undefined}
                                    customKey={`TableHeadRow_11`}
                                />
                                <TableBody>
                                    <><TableRow
                                        key={orderForReturn.OrderId}
                                        tabIndex={-1}
                                        role="checkbox"
                                        selected={false}
                                        aria-checked={false}
                                    >
                                        <TableCell align="center">{orderForReturn.OrderId}</TableCell>
                                        <TableCell align="center">
                                            {orderForReturn.RouteTitle}
                                        </TableCell>
                                        <TableCell align="center">
                                            {orderForReturn.ShopTitle}
                                        </TableCell>
                                    </TableRow>

                                        {<TableRow key={`Row_Detail_${orderForReturn.OrderId}`}><TableCell colSpan={3}>
                                            <TableContainer sx={{ minWidth: 800, paddingLeft: 5, paddingRight: 5 }}>
                                                <Table sx={{ backgroundColor: '#F5F5F5' }}>
                                                    <UserListHead
                                                        order={'asc'}
                                                        key={`Table_${orderForReturn.OrderId}`}
                                                        customKey={`TableDetailHeadRow_${orderForReturn.OrderId}`}
                                                        orderBy={'asc'}
                                                        headLabel={TABLE_HEAD_ProductWise}
                                                        rowCount={0}
                                                        numSelected={0}
                                                    />
                                                    <TableBody key={`Table_Body_${orderForReturn.OrderId}`}>
                                                        {orderForReturn.ProductWiseOrderDetails.map((dt, dtIndex) => {
                                                            return <TableRow
                                                                key={`TableDetailRow_${orderForReturn.OrderId}_${dt.ProductId}`}
                                                                tabIndex={-1}
                                                                role="checkbox"
                                                                selected={false}
                                                                aria-checked={false}
                                                            >
                                                                <TableCell align="center">{dtIndex + 1}</TableCell>
                                                                <TableCell align="center">
                                                                    {dt.ProductName ?? ''}
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    {dt.OrderQty ?? ''}
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    {dt.SchemeQty ?? 0}
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <TextField
                                                                        fullWidth
                                                                        autoComplete="order-number"
                                                                        type={'number'}
                                                                        label="Total Return Qty"
                                                                        value={dt.TotalReturnQty}
                                                                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                                            const value = event.target.value;
                                                                            let numberValue: number | null = null;

                                                                            if (value !== undefined && value != null) {
                                                                                numberValue = parseInt(value);
                                                                            }

                                                                            handleReturnedQtyChange(numberValue, dt.ProductId, orderForReturn.OrderedOn);
                                                                        }}
                                                                    />
                                                                </TableCell>
                                                                <TableCell width={150} align="center">
                                                                    {isNaN(dt.OrderReturnQty) ? 0 : (dt.OrderReturnQty ?? 0)}
                                                                </TableCell>
                                                                <TableCell width={150} align="center">
                                                                    {isNaN(dt.SchemeReturnQty) ? 0 : (dt.SchemeReturnQty ?? 0)}
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    {dt.IsReturnOk == false && <Icon color="red" height={30} width={30} icon={"ep:circle-close-filled"} />}
                                                                    {dt.IsReturnOk == true && <Icon color="green" height={30} width={30} icon={"teenyicons:tick-circle-solid"} />}
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <LoadingButton
                                                                        size="small"
                                                                        type="button"
                                                                        variant="contained"
                                                                        loading={isLoading}
                                                                        style={{ margin: 3, height: 30 }}
                                                                        startIcon={<Icon icon={plusFill} />}
                                                                        onClick={() => {
                                                                            addToReturnList(orderForReturn.OrderId, dt.ProductId);
                                                                        }}
                                                                    >
                                                                        Add
                                                                    </LoadingButton>
                                                                </TableCell>
                                                            </TableRow>
                                                        })
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer></TableCell></TableRow>}
                                    </>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Scrollbar>}
                    {(!orderForReturn || !orderForReturn.ProductWiseOrderDetails || orderForReturn.ProductWiseOrderDetails.length <= 0) && <><br /><br /><br /><br /><br /><br /><br /><br /><br /></>}
                    {<Scrollbar sx={{}}>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <hr style={{ height: 3, backgroundColor: 'green' }} />
                            <Typography style={{ padding: 10, textAlign: 'center' }} variant='h4'>Return Details</Typography>
                            {(!selectedReturnList || selectedReturnList.length <= 0) && <Typography style={{ padding: 10, textAlign: 'center' }}><strong>No Item Added Yet</strong></Typography>}
                            {!!selectedReturnList && selectedReturnList.length > 0 && <Table sx={{ backgroundColor: '#F5F5F5' }}>
                                <UserListHead
                                    order={'asc'}
                                    key={`Table_Details_Selection`}
                                    customKey={`TableDetailHeadRow_Details`}
                                    orderBy={'asc'}
                                    headLabel={TABLE_HEAD_ProductWiseForSelection}
                                    rowCount={0}
                                    numSelected={0}
                                />
                                <TableBody key={`Table_Body_Selection_Details`}>
                                    {!!selectedReturnList && selectedReturnList.length > 0 && selectedReturnList.map((dt, dtIndex) => {
                                        return <TableRow
                                            key={`TableDetailRow_${dt.OrderId}_${dt.ProductId}`}
                                            tabIndex={-1}
                                            role="checkbox"
                                            selected={false}
                                            aria-checked={false}
                                        >
                                            <TableCell align="center">{dtIndex + 1}</TableCell>
                                            <TableCell align="center">
                                                {dt.OrderId ?? ''}
                                            </TableCell>
                                            <TableCell align="center">
                                                {dt.ShopName ?? ''}
                                            </TableCell>
                                            <TableCell align="center">
                                                {dt.ProductName ?? ''}
                                            </TableCell>
                                            <TableCell align="center">
                                                {dt.OrderQty ?? ''}
                                            </TableCell>
                                            <TableCell align="center">
                                                {dt.SchemeQty ?? 0}
                                            </TableCell>
                                            <TableCell align="center">
                                                {isNaN(dt.OrderReturnQty) ? 0 : dt.OrderReturnQty}
                                            </TableCell>
                                            <TableCell align="center">
                                                {isNaN(dt.SchemeReturnQty) ? 0 : (dt.SchemeReturnQty ?? 0)}
                                            </TableCell>
                                            <TableCell align="center">
                                                {((isNaN(dt.OrderReturnQty) ? 0 : dt.OrderReturnQty) + (isNaN(dt.SchemeReturnQty) ? 0 : dt.SchemeReturnQty))}
                                            </TableCell>
                                            <TableCell align="center">
                                                <LoadingButton
                                                    size="small"
                                                    type="button"
                                                    variant="contained"
                                                    loading={isLoading}
                                                    style={{ margin: 3, width: 100, backgroundColor: 'red' }}
                                                    startIcon={<Icon icon={closeFill} />}
                                                    onClick={() => {
                                                        removeFromReturnList(dt.ProductId, dt.OrderId);
                                                    }}
                                                >
                                                    Remove
                                                </LoadingButton>
                                            </TableCell>
                                        </TableRow>
                                    })
                                    }
                                </TableBody>
                            </Table>}
                        </TableContainer>
                    </Scrollbar>}




                    {!!productWiseReturn && productWiseReturn.length > 0 && <Scrollbar sx={{}}>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Typography style={{ padding: 10, textAlign: 'center' }} variant='h4'>Product Wise Return Summary</Typography>
                            {(!productWiseReturn || productWiseReturn.length <= 0) && <Typography style={{ padding: 10, textAlign: 'center' }}><strong>No Item Added Yet</strong></Typography>}
                            {!!productWiseReturn && productWiseReturn.length > 0 && <Table sx={{ backgroundColor: '#F5F5F5' }}>
                                <UserListHead
                                    order={'asc'}
                                    key={`Table_Details_Selection_Summary`}
                                    customKey={`TableDetailHeadRow_Details_Summary`}
                                    orderBy={'asc'}
                                    headLabel={TABLE_HEAD_PRODUCT_WISE_RETURN}
                                    rowCount={0}
                                    numSelected={0}
                                />
                                <TableBody key={`Table_Body_Selection_Details_Summary`}>
                                    {!!productWiseReturn && productWiseReturn.length > 0 && productWiseReturn.map((dt, dtIndex) => {
                                        return <TableRow
                                            key={`TableDetailRow_SUMMARY_${dt.ProductId}`}
                                            tabIndex={-1}
                                            role="checkbox"
                                            selected={false}
                                            aria-checked={false}
                                        >
                                            <TableCell align="center">{dtIndex + 1}</TableCell>
                                            <TableCell align="center">
                                                {dt.ProductName ?? ''}
                                            </TableCell>
                                            <TableCell align="center">
                                                {dt.TotalReturnQty ?? ''}
                                            </TableCell>
                                        </TableRow>
                                    })
                                    }
                                </TableBody>
                            </Table>}
                        </TableContainer>
                    </Scrollbar>}


                    {!!selectedDO && !!selectedDO.DOSummary && selectedDO.DOSummary.length > 0 && <Scrollbar sx={{}}>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Typography style={{ padding: 10, textAlign: 'center' }} variant='h4'>Delivery Boy Sale</Typography>
                            {<Table sx={{ backgroundColor: '#F5F5F5' }}>
                                <UserListHead
                                    order={'asc'}
                                    key={`Table_Details_Delivery_Boy_Sale`}
                                    customKey={`TableDetailHeadRow_Details_Delivery_Boy_Sale`}
                                    orderBy={'asc'}
                                    headLabel={TABLE_HEAD_PRODUCT_WISE_Delivery_Boy_Sale}
                                    rowCount={0}
                                    numSelected={0}
                                />
                                <TableBody key={`Table_Body_Selection_Details_Delivery_Boy_Sale`}>
                                    {!!selectedDO.DOSummary && selectedDO.DOSummary.length > 0 && selectedDO.DOSummary.map((dt, dtIndex) => {
                                        return <TableRow
                                            key={`TableDetailRow_SUMMARY_${dt.ProductId}`}
                                            tabIndex={-1}
                                            role="checkbox"
                                            selected={false}
                                            aria-checked={false}
                                        >
                                            <TableCell align="center">{dtIndex + 1}</TableCell>
                                            <TableCell align="center">
                                                {dt.ProductName ?? ''}
                                            </TableCell>
                                            <TableCell align="center">
                                                <TextField
                                                    fullWidth
                                                    autoComplete="order-number"
                                                    type={'number'}
                                                    label="Sale Qty"
                                                    value={dt.DeliveryBoySaleQty > 0 ? dt.DeliveryBoySaleQty : ''}
                                                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                        const value = event.target.value;
                                                        let numberValue: number | null = null;

                                                        if (value !== undefined && value != null) {
                                                            numberValue = parseInt(value);
                                                        }

                                                        handleDeliveryBoySaleChange(numberValue, dt.ProductId);
                                                    }}
                                                    disabled={!productWiseReturn || productWiseReturn.length <= 0}
                                                />
                                            </TableCell>
                                            <TableCell align="center">
                                                <TextField
                                                    fullWidth
                                                    autoComplete="order-number"
                                                    type={'number'}
                                                    label="Extra Qty"
                                                    value={dt.ExtraQty > 0 ? dt.ExtraQty : ''}
                                                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                        const value = event.target.value;
                                                        let numberValue: number | null = null;

                                                        if (value !== undefined && value != null) {
                                                            numberValue = parseInt(value);
                                                        }

                                                        handleDeliveryBoyExtraQtyChange(numberValue, dt.ProductId);
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell align='center'>
                                                <CustomAutoComplete<IExtraQtyReason>
                                                    Id={'extra_qty_reason'}
                                                    Value={!!extraQtyReasons && dt.ExtraQtyReasonId > 0 ? extraQtyReasons.filter(m => m.Id == dt.ExtraQtyReasonId)[0] : null}
                                                    Options={extraQtyReasons}
                                                    displayField={'EnumDescription'}
                                                    valueField={'Id'}
                                                    Label={'Extra Qty Reason'}
                                                    onChange={(option: IExtraQtyReason) => {
                                                        if (!!option) {
                                                            handleExtraQtyReasonChange(option.Id, dt.ProductId);
                                                        } else {
                                                            handleExtraQtyReasonChange(0, dt.ProductId);
                                                        }
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell align='center'>
                                                {dt.IsValidDeliveryBoySale == false && <Icon color="red" height={30} width={30} icon={"ep:circle-close-filled"} />}
                                                {dt.IsValidDeliveryBoySale == true && <Icon color="green" height={30} width={30} icon={"teenyicons:tick-circle-solid"} />}
                                            </TableCell>
                                        </TableRow>
                                    })
                                    }
                                </TableBody>
                            </Table>}
                        </TableContainer>
                    </Scrollbar>}



                </Card>

                {<Container style={{ paddingTop: 30, paddingBottom: 10 }}>
                    <Grid container >
                        <Grid item md={12} style={{ textAlign: 'center' }}>
                            <LoadingButton
                                size="small"
                                type="button"
                                variant="contained"
                                loading={isLoading}
                                style={{ margin: 3, backgroundColor: 'blue', width: 180, height: 50 }}
                                startIcon={<Icon icon={saveFill} />}
                                onClick={saveReturnReceiving}
                                disabled={!((!!selectedReturnList && selectedReturnList.length > 0) || (!!selectedDO && !!selectedDO.DOSummary && !!selectedDO.DOSummary.find(m => m.ExtraQty > 0 && m.IsValidDeliveryBoySale == true)))}
                            >
                                Save
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Container>}

            </Container>}

            {isViewingSummary == true && <Container style={{ maxWidth: 1500, paddingLeft: 0, paddingRight: 0 }}>
                <Grid container style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>

                    <LoadingButton
                        size="small"
                        type="button"
                        variant="contained"
                        loading={isLoading}
                        style={{ margin: 3, backgroundColor: 'rgba(34, 154, 22, 0.72)', textAlign: 'right', height: 40 }}
                        startIcon={<Icon icon={refreshFill} />}
                        onClick={getDeliveryOrders}
                    >
                        Refresh
                    </LoadingButton>

                    <LoadingButton
                        size="small"
                        type="button"
                        variant="contained"
                        loading={isLoading}
                        style={{ margin: 3, backgroundColor: 'blue', textAlign: 'right', height: 40 }}
                        startIcon={<Icon icon='ep:van' />}
                        onClick={onViewDOClick}
                    >
                        View DO
                    </LoadingButton></Grid>

                {props.selectedUnits.UnitAccesses.filter(u => u.UnitUniqueId != AllOptionUniqueId).length > 0 && props.selectedUnits.UnitAccesses.filter(u => u.UnitUniqueId != AllOptionUniqueId).map((uForS,uForSIndex) => {
                    return <><Card>
                    <Typography style={{ backgroundColor: 'rgba(34, 154, 22, 0.72)', color: 'white', paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h4'}>Return Summary For {uForS.UnitTitle}</Typography>
                    {isLoading == true && <AudoSpin
                        height="80"
                        width="80"
                        color='green'
                        ariaLabel='three-dots-loading'
                        wrapperStyle={{ alignSelf: 'center', justifyContent: 'center' }}
                    />}

                    {(!doDetail || !doDetail.UnitWiseSummary || doDetail.UnitWiseSummary.length <= 0 || doDetail.UnitWiseSummary.filter(u => u.UnitUniqueId == uForS.UnitUniqueId).length <= 0) && isLoading == false && <Typography style={{ paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h6'}>No Summary For {uForS.UnitTitle} Today</Typography>}

                    {!!doDetail && !!doDetail.UnitWiseSummary && doDetail.UnitWiseSummary.length > 0 && doDetail.UnitWiseSummary.filter(u => u.UnitUniqueId == uForS.UnitUniqueId).length > 0 && <Scrollbar sx={{}}>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    order={'asc'}
                                    orderBy={''}
                                    headLabel={TABLE_HEAD_DO_Return_Detail}
                                    rowCount={0}
                                    numSelected={0}
                                    onRequestSort={undefined}
                                    onSelectAllClick={undefined}
                                    customKey={`TableHeadRow_2_Unique`}
                                />
                                <TableBody>
                                    {!!doDetail && !!doDetail.UnitWiseSummary && doDetail.UnitWiseSummary.length > 0 && doDetail.UnitWiseSummary.filter(u => u.UnitUniqueId == uForS.UnitUniqueId).length > 0 && doDetail.UnitWiseSummary.filter(u => u.UnitUniqueId == uForS.UnitUniqueId).map((sRow, sIndex) => {

                                        return (
                                            <TableRow
                                                hover
                                                key={`SUMMARY_ROW_KEY_${sIndex}`}
                                                tabIndex={-1}
                                                role="checkbox"
                                                selected={false}
                                                aria-checked={false}
                                            >
                                                <TableCell width={10} align="center">
                                                    {sIndex + 1}
                                                </TableCell>
                                                <TableCell align="center">{sRow.ProductName}</TableCell>
                                                <TableCell align="center">{sRow.TotalOrderQty}</TableCell>
                                                <TableCell align="center" component="th" scope="row" padding="none">
                                                    {sRow.TotalSchemeQty}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {sRow.TotalQtyToIssue}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {sRow.TotalReturnedQty}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {sRow.TotalSchemeReturnedQty}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {sRow.TotalReturnedQty + sRow.TotalSchemeReturnedQty}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {sRow.TotalDeliveryBoySaleQty}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {sRow.TotalDeliveryBoyExtraQty}
                                                </TableCell>
                                                <TableCell align="center" style={{color:'red'}}>
                                                    <Typography variant='h6'>{(sRow.TotalReturnedQty + sRow.TotalSchemeReturnedQty + sRow.TotalDeliveryBoyExtraQty) - sRow.TotalDeliveryBoySaleQty}</Typography>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Scrollbar>}
                </Card><br /></>})}



                {props.selectedUnits.UnitAccesses.filter(u => u.UnitUniqueId != AllOptionUniqueId).length > 1 && <Card>
                    <Typography style={{ backgroundColor: 'rgba(34, 154, 22, 0.72)', color: 'white', paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h4'}>Return Summary</Typography>
                    {isLoading == true && <AudoSpin
                        height="80"
                        width="80"
                        color='green'
                        ariaLabel='three-dots-loading'
                        wrapperStyle={{ alignSelf: 'center', justifyContent: 'center' }}
                    />}

                    {(!doDetail || !doDetail.CreditWithProductWiseSummary || !doDetail.CreditWithProductWiseSummary.ProductWiseSummaries || doDetail.CreditWithProductWiseSummary.ProductWiseSummaries.length <= 0) && isLoading == false && <Typography style={{ paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h6'}>No Summary For Today</Typography>}

                    {!!doDetail && !!doDetail.CreditWithProductWiseSummary && !!doDetail.CreditWithProductWiseSummary.ProductWiseSummaries && doDetail.CreditWithProductWiseSummary.ProductWiseSummaries.length > 0 && <Scrollbar sx={{}}>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    order={'asc'}
                                    orderBy={''}
                                    headLabel={TABLE_HEAD_DO_Return_Detail}
                                    rowCount={0}
                                    numSelected={0}
                                    onRequestSort={undefined}
                                    onSelectAllClick={undefined}
                                    customKey={`TableHeadRow_2_Unique`}
                                />
                                <TableBody>
                                    {!!doDetail && !!doDetail.CreditWithProductWiseSummary && !!doDetail.CreditWithProductWiseSummary.ProductWiseSummaries && doDetail.CreditWithProductWiseSummary.ProductWiseSummaries.length > 0 && doDetail.CreditWithProductWiseSummary.ProductWiseSummaries.map((sRow, sIndex) => {

                                        return (
                                            <TableRow
                                                hover
                                                key={`SUMMARY_ROW_KEY_${sIndex}`}
                                                tabIndex={-1}
                                                role="checkbox"
                                                selected={false}
                                                aria-checked={false}
                                            >
                                                <TableCell width={10} align="center">
                                                    {sIndex + 1}
                                                </TableCell>
                                                <TableCell align="center">{sRow.ProductName}</TableCell>
                                                <TableCell align="center">{sRow.TotalOrderQty}</TableCell>
                                                <TableCell align="center" component="th" scope="row" padding="none">
                                                    {sRow.TotalSchemeQty}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {sRow.TotalQtyToIssue}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {sRow.TotalReturnedQty}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {sRow.TotalSchemeReturnedQty}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {sRow.TotalReturnedQty + sRow.TotalSchemeReturnedQty}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {sRow.TotalDeliveryBoySaleQty}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {sRow.TotalDeliveryBoyExtraQty}
                                                </TableCell>
                                                <TableCell align="center" style={{color:'red'}}>
                                                    <Typography variant='h6'>{(sRow.TotalReturnedQty + sRow.TotalSchemeReturnedQty + sRow.TotalDeliveryBoyExtraQty) - sRow.TotalDeliveryBoySaleQty}</Typography>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Scrollbar>}
                </Card>}
            </Container>}
        </Page></>  : <NoUnitSelectedComponent pageTitle='DO | Raza Pak Care' />
    );
}

const mapStateToProps = (state:IApplicationState) =>{
    return {
        selectedUnits:state.selectedUnitAccesses,
        loginUser:state.loginUser
      };
}
const connectedReceiveReturn = connect(mapStateToProps)(ReceiveReturn);
export {connectedReceiveReturn as ReceiveReturn};
