import { IAutoComplete } from '../interfaces/auto-complete.interface';
import { http } from './http-base';
import { IEnum } from 'src/interfaces';
import { IRouteOnMap } from 'src/interfaces/route-on-map.interface';
import { IRoute } from 'src/interfaces/route.interface';
import { concatParam } from 'src/utility';
import { IRouteAnalyticsHistory } from 'src/interfaces/route-analytics-history.interface';
import { IRouteInfoResult } from 'src/interfaces/route-info-result.interface';
import { IRouteShopDetail } from 'src/interfaces/route-shop-detail.interface';
import { IOrderBookerRouteInfoNew } from 'src/interfaces/order-booker-route-info-new.interface';
import { IRouteInfoNew } from 'src/interfaces/route-info-new.interface';
import { IRoutePoly } from 'src/interfaces/route-poly.interface';

const getRouteShops = async (routeId: number
): Promise<IAutoComplete[]> => {

  let url: string = `api/RouteDetail/routeShops?routeId=${routeId}`;

  let result = await http
    .get<IAutoComplete[]>(url)
    .catch(error => Promise.reject(error));
  return result.data;
};

const getRoutesOnMap = async (
  isNew:number, areaId: number = null, orderBookerId: number = null, routeAssignment: number = null
): Promise<IRouteOnMap> => {
  let prams = '';

  if (isNew !== undefined && isNew != null) {
    prams = concatParam(prams, isNew, 'isNew');
  }
  
  if (!!areaId && areaId > 0) {
    prams = concatParam(prams, areaId, 'areaId');
  }

  if (!!orderBookerId && orderBookerId > 0) {
    prams = concatParam(prams, orderBookerId, 'orderBookerId');
  }

  if (!!routeAssignment && routeAssignment > 0) {
    prams = concatParam(prams, routeAssignment, 'routeAssignment');
  }

  let url: string = `api/Route/RoutesOnMap${prams}`;

  let result = await http
    .get<IRouteOnMap>(url)
    .catch(error => Promise.reject(error));
  return result.data;
};

const getRouteAnalyticHistory = async (
  routeId:number,
  currentWeekStartDate:Date
): Promise<IRouteAnalyticsHistory> => {
  let prams = '';
  prams = concatParam(prams, routeId, 'routeId');
  prams = concatParam(prams, currentWeekStartDate, 'currentWeekStartDate');

  let url: string = `api/Route/RouteHistory${prams}`;

  let result = await http
    .get<IRouteAnalyticsHistory>(url)
    .catch(error => Promise.reject(error));
  return result.data;
};

const saveRouteDetails = async (
  loggedInUserId: number,
  route: IRoute
): Promise<IRoute> => {
  let url: string = `api/Route/SaveRouteDetails?loginUserId=${loggedInUserId}`;
  let result = await http
    .post<IRoute>(url, route)
    .catch(error => Promise.reject(error));
  return result.data;
};

const deleteRoute = async (
  routeId: number,
  loggedInUserId:number
): Promise<boolean> => {
  let prams = '';
  prams = concatParam(prams, routeId, 'routeId');
  prams = concatParam(prams, loggedInUserId, 'loggedInUserId');

  let url: string = `api/Route/DeleteRouteDetailsAndGetConfirmation${prams}`;

  let result = await http
    .get<boolean>(url)
    .catch(error => Promise.reject(error));
  return result.data;
};

const deAssignRoute = async (
  routeId: number,
  loggedInUserId:number
): Promise<boolean> => {
  let prams = '';
  prams = concatParam(prams, routeId, 'routeId');
  prams = concatParam(prams, loggedInUserId, 'loggedInUserId');

  let url: string = `api/Route/DeAssignRoute${prams}`;

  let result = await http
    .get<boolean>(url)
    .catch(error => Promise.reject(error));
  return result.data;
};

const getOrderBookerRouteInfo = async (
  loginUserId: number,
  commaSeparatedUnitUniqueIds:string,
  orderBookerId:number
): Promise<IRouteInfoResult> => {
  let prams = '';
  prams = concatParam(prams, loginUserId, 'loginUserId');
  prams = concatParam(prams, commaSeparatedUnitUniqueIds, 'commaSeparatedUnitUniqueIds');
  prams = concatParam(prams, orderBookerId, 'orderBookerId');

  let url: string = `api/Route/OrderBookerRouteInfo${prams}`;

  let result = await http
    .get<IRouteInfoResult>(url)
    .catch(error => Promise.reject(error));
  return result.data;
};

const getRouteAutoComplete = async (
  userId:number,
  areaId:number,
  orderBookerId:number | null = null
  ): Promise<IAutoComplete[]> => {
    let prams = '';
    prams = concatParam(prams, userId, 'userId');
    prams = concatParam(prams, areaId, 'areaId');

    if(!!orderBookerId){
      prams = concatParam(prams, orderBookerId, 'orderBookerId');
    }

    let url: string = `api/Route/RoutAutoComplete${prams}`;
    let result = await http
      .get<IAutoComplete[]>(url)
      .catch(error => Promise.reject(error));
    return result.data;
  };

  const getRouteDay = async (
    routeId:number
    ): Promise<IAutoComplete[]> => {
      let prams = '';
      prams = concatParam(prams, routeId, 'routeId');
  
      let url: string = `api/Route/RouteDay${prams}`;
      let result = await http
        .get<IAutoComplete[]>(url)
        .catch(error => Promise.reject(error));
      return result.data;
    };

  const getRouteShopsAsync = async (
    routeId:number
    ): Promise<IRouteShopDetail[]> => {
      let prams = '';
      prams = concatParam(prams, routeId, 'routeId');
  
      let url: string = `api/Route/Shops${prams}`;
      let result = await http
        .get<IRouteShopDetail[]>(url)
        .catch(error => Promise.reject(error));
      return result.data;
    };

    const getOrderBookerWithRoutesAsync = async (
      areaId:number
      ): Promise<IOrderBookerRouteInfoNew[]> => {
        let prams = '';
        prams = concatParam(prams, areaId, 'areaId');
    
        let url: string = `api/Route/OrderBookerWithRoutes${prams}`;
        let result = await http
          .get<IOrderBookerRouteInfoNew[]>(url)
          .catch(error => Promise.reject(error));
        return result.data;
      };

      const getRouteInfoByIdAsync = async (
        routeId:number
        ): Promise<IRouteInfoNew> => {
          let prams = '';
          prams = concatParam(prams, routeId, 'routeId');
      
          let url: string = `api/Route/RouteInfo${prams}`;
          let result = await http
            .get<IRouteInfoNew>(url)
            .catch(error => Promise.reject(error));
          return result.data;
        };

        const unAssignRoute = async (
          orderBookerId: number,
          routeDay:number
        ): Promise<boolean> => {
          let prams = '';
          prams = concatParam(prams, orderBookerId, 'orderBookerId');
          prams = concatParam(prams, routeDay, 'routeDay');
        
          let url: string = `api/Route/UnAssignRoute${prams}`;
        
          let result = await http
            .post<boolean>(url)
            .catch(error => Promise.reject(error));
          return result.data;
        };

        const updateRouteAssigment = async (
          orderBookerId: number,
          routeDay:number,
          routeId:number,
          loginUserId:number
        ): Promise<boolean> => {
          let prams = '';
          prams = concatParam(prams, orderBookerId, 'orderBookerId');
          prams = concatParam(prams, routeDay, 'routeDay');
          prams = concatParam(prams, routeId, 'routeId');
          prams = concatParam(prams, loginUserId, 'loginUserId');
        
          let url: string = `api/Route/UpdateAssignment${prams}`;
        
          let result = await http
            .post<boolean>(url)
            .catch(error => Promise.reject(error));
          return result.data;
        };

        const getRoutePolies = async (
          areaId:number
          ): Promise<IRoutePoly[]> => {
            let url: string = `api/RoutePoly/RoutePolies?areaId=${areaId}`;
            let result = await http
              .get<IRoutePoly[]>(url)
              .catch(error => Promise.reject(error));
            return result.data;
          };

          const deactivateRoute = async (
            routeId:number
          ): Promise<boolean> => {
            let prams = '';
            prams = concatParam(prams, routeId, 'routeId');
          
            let url: string = `api/Route/DeActivateRoute${prams}`;
          
            let result = await http
              .post<boolean>(url)
              .catch(error => Promise.reject(error));
            return result.data;
          };

          const updateRouteName = async (
            routeId:number,
            routeName:string
          ): Promise<boolean> => {
            let prams = '';
            prams = concatParam(prams, routeId, 'routeId');
            prams = concatParam(prams, routeName, 'routeName');
          
            let url: string = `api/Route/UpdateRouteName${prams}`;
          
            let result = await http
              .post<boolean>(url)
              .catch(error => Promise.reject(error));
            return result.data;
          };

          const saveNewRoute = async (
            loggedInUserId: number,
            route: IRoute
          ): Promise<IRouteInfoNew> => {
            let url: string = `api/Route/SaveNewRouteAndAssignment?loginUserId=${loggedInUserId}`;
            let result = await http
              .post<IRouteInfoNew>(url, route)
              .catch(error => Promise.reject(error));
            return result.data;
          };

const routeApi = {
  getRouteShops,
  getRoutesOnMap,
  saveRouteDetails,
  deleteRoute,
  deAssignRoute,
  getRouteAnalyticHistory,
  getOrderBookerRouteInfo,
  getRouteAutoComplete,
  getRouteShopsAsync,
  getRouteDay,
  getOrderBookerWithRoutesAsync,
  getRouteInfoByIdAsync,
  unAssignRoute,
  updateRouteAssigment,
  getRoutePolies,
  deactivateRoute,
  updateRouteName,
  saveNewRoute
};

export { routeApi };
