import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
// layouts
import EmptyLayout from './layouts/EmptyLayout';
//
import Login from './pages/Login';
import NotFound from './pages/Page404';
import { connect } from 'react-redux';
import { IApplicationState } from './redux';
import { ITokenResult } from './interfaces';
import { IUserRouteDetail } from './interfaces/routes/user-route-detail.interface';
import { ComponentKeys } from './component.keys';
import { stableSort } from './utility';

// ----------------------------------------------------------------------

interface IProps {
  token: ITokenResult,
  userRoutes: IUserRouteDetail
}
const Router = (props: IProps) => {
  return <Routes>
    <Route key={`Route_Key_Default_Route`} path='/' element={<Navigate to={props?.userRoutes?.DefaultRoute ?? '/404'} />} />
    {(!!props.token &&
      !!props.token.access_token &&
      !!props.token.expire_on &&
      new Date(props.token.expire_on) > new Date()) && (!!props.userRoutes && !!props.userRoutes.DefaultRoute && !!props.userRoutes.Routes && props.userRoutes.Routes.length > 0) && <React.Fragment>{props.userRoutes.Routes.map((tcroute, index) => {
        const route = Object.assign({}, tcroute);
        const children = route.Children.map(c => Object.assign({}, c));
        return <React.Fragment key={`Main_Item_${index}`}>
          <Route key={`Route_Key_${index}`} path={route.RoutePath} element={ComponentKeys.find(k => k.KeyName == route.ComponentKey).Component} children={<React.Fragment key={`Main_Child_${index}`}>
            {!!children && children.length > 0 && stableSort(children, 'desc', 'IsDefault').map((cRoute, cIndex) => {
              return <React.Fragment key={`Child_Main_List_${index}_${cIndex}`}>
                {cIndex == 0 && <Route key={`Route_Default_Key_${index}_${cIndex}`} path={''} element={<Navigate to={`${route.RoutePath}/${cRoute.RoutePath}`} />} />}
                {<Route key={`Route_Key_${index}_${cIndex}`} path={cRoute.RoutePath} element={ComponentKeys.find(cc => cc.KeyName == cRoute.ComponentKey).Component} />}
              </React.Fragment>
            })}
            <Route key={`Route_Child_Default_All_${index}`} path='*' element={<Navigate to={props?.userRoutes?.DefaultRoute ?? '/404'} />} />
          </React.Fragment>} />
        </React.Fragment>;
      })}

        <Route key={`Route_Key_404`} path='404' element={<NotFound />} />
        <Route key={`Route_Key_Default_All_Route`} path='*' element={<Navigate to={props?.userRoutes?.DefaultRoute ?? '/404'} />} />
      </React.Fragment>}

    {(!props.token ||
      !props.token.access_token ||
      !props.token.expire_on ||
      new Date(props.token.expire_on) <= new Date()
      || !props.userRoutes || !props.userRoutes.Routes || props.userRoutes.Routes.length <= 0 || !props.userRoutes.DefaultRoute) && <>
        <Route path='/' element={<EmptyLayout />} children={
          <>
            <Route key={'Login_Route'} path='login' element={<Login />} />
            <Route key={'UnAuth_404_Route'} path='404' element={<NotFound />} />
            <Route key={'UnAuth_NavigationAbsolute'} path='/' element={<Navigate to='/login' />} />
            <Route key={'UnAuth_NavigationAll'} path='*' element={<Navigate to='/login' />} />
          </>
        } />
      </>}
  </Routes>
}

const mapStateToProps = (state: IApplicationState) => {
  return {
    token: state.token,
    userRoutes: state.userRoutes
  }
}

const connectedRouter = connect(mapStateToProps)(Router);

export { connectedRouter as Router };
