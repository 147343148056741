import { IRoute } from 'src/interfaces/route.interface';
import {NewRouteSetupConstant} from '../../actions/action.constant';
import {IAction} from '../../actions/action.interface';
import {initialState} from '../../initial-state';
import { getCopy } from 'src/utility';
import { IMapPopUpConfirmation } from 'src/interfaces/map-pop-confirmation.interface';

export const mapConfirmationPopupReducer = (
  state: IMapPopUpConfirmation | null = initialState.mapConfirmationPopup,
  action: IAction<IMapPopUpConfirmation | null>,
) => {
  switch (action.type) {
    case NewRouteSetupConstant.SET_CONFIRMATION_MESSAGE_POP_UP: {;
      return !!action.payload ? getCopy(action.payload) : null;
    }
    default:
      return state;
  }
};
