
interface IPoint{
    x:number,
    y:number
}
export const centroid = (points:IPoint[]) => {
    let x = 0,
        y = 0,
        i,
        j,
        f,
        point1,
        point2;

    for (i = 0, j = points.length - 1; i < points.length; j=i,i++) {
        point1 = points[i];
        point2 = points[j];
        f = point1.x * point2.y - point2.x * point1.y;
        x += (point1.x + point2.x) * f;
        y += (point1.y + point2.y) * f;
    }

    f = area(points) * 6;

    return {lat:x / f, lng:y / f};
};

export const area = (points:IPoint[]) => {
    let area = 0,
        i,
        j,
        point1,
        point2;

    for (i = 0, j = points.length - 1; i < points.length; j=i,i++) {
        point1 = points[i];
        point2 = points[j];
        area += point1.x * point2.y;
        area -= point1.y * point2.x;
    }
    area /= 2;

    return area;
};