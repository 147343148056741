import { NavigateFunction } from 'react-router-dom';
import { setUserMenuAction } from 'src/redux/actions/user-menu.action';
import { setUserRouteDetailAction } from 'src/redux/actions/user-routes.action';
import {store} from '../redux';
import {setLoginUserDetailAction, setTokenAction} from '../redux/actions';
import { resetIsLoadingOrderBookerInfoAction, resetIsSavingAssignmentAction, resetOrderBookerInfoAction } from 'src/redux/actions/area-wise-order-booker-info.action';
export const isAuthenticated = (): boolean => {
  let token = store?.getState()?.token;
  if (
    token &&
    token.access_token &&
    token.expire_on &&
    new Date(token.expire_on) > new Date()
  ) {
    return true;
  } else {
    return false;
  }
};

export const logout = (navigate:NavigateFunction) => {
  resetStore();
};

export const resetStore = () =>{
  store.dispatch(resetOrderBookerInfoAction());
  store.dispatch(setTokenAction(null));
  store.dispatch(setLoginUserDetailAction(null));
  store.dispatch(setUserMenuAction([]));
  store.dispatch(setUserRouteDetailAction(null));
  store.dispatch(resetIsLoadingOrderBookerInfoAction());
  store.dispatch(resetIsSavingAssignmentAction());
}
