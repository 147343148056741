import React from 'react';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import arrowBackFill from '@iconify/icons-eva/arrow-back-fill';
import refreshFill from '@iconify/icons-eva/refresh-fill';
import saveFill from '@iconify/icons-eva/save-fill';
import moneyCollectFill from '@iconify/icons-ant-design/money-collect-fill';
// material
import {
    Card,
    Table,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    Grid,
    TextField,
    InputAdornment,
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { UserListHead } from '../components/_dashboard/user';
//
import { LoadingButton } from '@mui/lab';
import { doCreditApi } from '../http';

import { useSnackbar } from 'notistack';
import { connect, useSelector } from 'react-redux';
import { IApplicationState } from 'src/redux';
import { Audio as AudoSpin } from 'react-loader-spinner'
import { IDODetail } from 'src/interfaces/delivery-order/do-detail.interface';
import Label from 'src/components/Label';
import { sentenceCase } from 'change-case';
import { IDOCredit } from 'src/interfaces/do-credit.interface';
import { ICreditReceiveDetail } from 'src/interfaces/credit-receive-detail.interface';
import { confirmAction } from 'src/utility/confirm.util';
import { formattedDate, generateUUID, getCommaSeparatedUnitUniqueIdsExceptAll, getDateOnly, getDaysDiff } from 'src/utility';
import { IUserDetail } from 'src/interfaces';
import { ISelectedUnitAccess } from 'src/interfaces/selected-unit-access.interface';
import { ICreditDetail } from 'src/interfaces/credit-detail.interface';
import { AllOptionUniqueId, AllowedNumberOfDaysForRed } from 'src/app.constant';
import { NoUnitSelectedComponent } from 'src/components/common/no-unit-selected.component';
import { Highlighted } from 'src/components/common/highlighted.component';
import { fCurrency } from 'src/utils/formatNumber';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { IMessage } from 'src/interfaces/message.interface';
import { MessageComponent } from 'src/components/messages.component';
import CustomDatePicker from 'src/components/common/CustomDatePicker';
import { useNavigate } from "react-router-dom";
import { ICreditHistoryParam } from 'src/interfaces/credit-history-param.interface';
import { CreditHistoryParam } from 'src/components/CreditHistoryParam';

// ---------------------------------------------------------------------

const TABLE_HEAD_DO_Credit_Received = [
    { id: 'sr', label: 'Sr.', alignContent: 'center' },
    { id: 'order_id', label: 'Slip#', alignContent: 'center' },
    { id: 'shop_name', label: 'Shop Name', alignContent: 'center' },
    { id: 'shop_address', label: 'Shop Address', alignContent: 'center' },
    { id: 'owner_cell', label: 'Owner Cell', alignContent: 'center' },
    { id: 'responsible_person', label: 'Responsible Person', alignContent: 'center' },
    { id: 'credited_on', label: 'Credited On', alignContent: 'center' },
    { id: 'total_amount', label: 'Total Credit', alignContent: 'center' },
    { id: 'received_amount', label: 'Received', alignContent: 'center' },
    { id: 'pending_amount', label: 'Pending', alignContent: 'center' },
    { id: 'status', label: 'Status', alignContent: 'center' },
];


//#region interfaces
interface IProps {
    loginUser: IUserDetail | null;
    selectedUnits: ISelectedUnitAccess;
    showBackButton?:boolean;
}

interface ITotal{
    TotalCredit:number;
    TotalPending:number;
    TotalReceived:number;
    UnitUniqueId:string;
}

//#endregion

const CreditHistory = (props: IProps) => {
    const navigate = useNavigate();
    //#region state variables
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [credits, setCredits] = React.useState<IDOCredit[]>([]);
    const [orignalCredits,setOrignalCredits] = React.useState<IDOCredit[]>(null);
    const [searchText, setSearchText] = React.useState<string>('');
    const [isLatedOnly,setIsLatedOnly] = React.useState<boolean>(false);
    const [pageMessages, setPageMessages] = React.useState<IMessage[]>([]);
    const [isHistoryLoadedAfterLastChange,setIsHistoryLoadedAfterLastChange] = React.useState<boolean>(false);
    const [unitWiseTotals, setUnitWiseTotals] = React.useState<ITotal[]>([]);
    const [params, setParams] = React.useState<ICreditHistoryParam>({
        SlipNumber:null,
        FromDate:new Date(),
        ToDate:new Date(),
        OwnerCellNumber:null,
        OwnerName:null,
        ResponsiblePersonName:null,
        ShopAddress:null,
        ShopName:null,
        SearchText:null
    });

    const [overAllTotal, setOverAllTotal] = React.useState<ITotal>({
        TotalCredit:0,
        TotalPending:0,
        TotalReceived:0,
        UnitUniqueId:''
    });
    
    const showErrorMessage = (message: string) => addMessage(message, 'error');
    const showInfoMessage = (message: string) => addMessage(message, 'info');
    const showSuccessMessage = (message: string) => addMessage(message, 'success');
    const showWarningMessage = (message: string) => addMessage(message, 'warning');

    const addMessage = (messageText: string, variant: 'success' | 'error' | 'info' | 'warning') => {
        if (!!messageText && messageText.trim() != '') {
            let msgs = [];
            msgs.push({
                messageText,
                variant
            });

            setPageMessages([...msgs]);
        }
    }

    //#endregion

    //#region util methods start

    const getCopy = (crdts:IDOCredit[]) => {
        const copy = [...crdts.map(a => a)];
        return copy;
    }

    const getColor = (creditedOn:Date, pendingAmount:number) =>{
        let color = 'black';

        if(!!creditedOn){
            const numberOfDays = getDaysDiff(new Date(), creditedOn);
            if(numberOfDays >= AllowedNumberOfDaysForRed){
                if(pendingAmount > 0){
                    color = 'red'
                }else{
                    color = 'green'
                }
            }
        }

        return color;
    }

    const isCreditLated = (creditedOn:Date) =>{
        let isYes = false;

        if(!!creditedOn){
            const numberOfDays = getDaysDiff(new Date(), creditedOn);
            if(numberOfDays >= AllowedNumberOfDaysForRed){
                isYes = true
            }
        }

        return isYes;
    }


    //#endregion util methods end

    //#region handlers start

    const resetEverythingAndReloadData = () => {
        resetEverything();
        getCreditHistory(true);
    }

    const resetEverything = () => {
        setPageMessages([]);
        setTotals([]);
        setOverAllTotal({
            TotalCredit:0,
            TotalPending:0,
            TotalReceived:0,
            UnitUniqueId:''
        })
        
        setIsLatedOnly(false);
        setCredits([]);
        setOrignalCredits([]);
    }

    const getFilteredResults = (text:string, isLated, crdts:IDOCredit[]) =>{
        if(!!text && text.trim() != ''){
            if(!!crdts && crdts.length > 0){
                let copy = getCopy(crdts);
                copy = copy.filter(m => isLated == false || (isCreditLated(m.CreditedOn) == true && m.PendingAmount > 0));
                copy = copy.filter(c => 
                    (c.OrderId !== undefined && c.OrderId != null && c.OrderId.toString().trim().indexOf(text) >= 0)
                    ||
                    (!!c.ShopName && c.ShopName.toLowerCase().indexOf(text.toLowerCase()) >= 0)
                    ||
                    (!!c.ShopAddress && c.ShopAddress.toLowerCase().indexOf(text.toLowerCase()) >= 0 )
                    ||
                    (!!c.ResponsibleEmployeeName && c.ResponsibleEmployeeName.toLowerCase().indexOf(text.toLowerCase()) >= 0)
                    ||
                    (!!c.OwnerCellNumber && c.OwnerCellNumber.toLowerCase().indexOf(text.toLowerCase()) >= 0)
                    ||
                    (c.TotalAmount !== undefined && c.TotalAmount != null && c.TotalAmount.toString().indexOf(text) >= 0)
                    ||
                    (c.ReceivedAmount !== undefined && c.ReceivedAmount != null && c.ReceivedAmount.toString().indexOf(text) >= 0)
                    ||
                    (c.PendingAmount !== undefined && c.PendingAmount != null && c.PendingAmount.toString().indexOf(text) >= 0)
                    ||
                    (!!c.CreditStatus && c.CreditStatus.toLowerCase().indexOf(text.toLowerCase()) >= 0)
                    );

                    return [...copy];
            }else{
                return crdts;
            }
            
        }else{
            if(!!crdts && crdts.length > 0){
                let copy = getCopy(crdts);
                copy = copy.filter(m => isLated == false || (isCreditLated(m.CreditedOn) == true && m.PendingAmount > 0));
                return [...copy];
            }else{
                return crdts;
            }
        }
    }

    const onSearchChange = (text:string, isLated:boolean) =>{
        setPageMessages([]);
        if(!!text){
            setSearchText(text);
            if(text.trim() != ''){
                const filtered = getFilteredResults(text,isLated,orignalCredits);
                setTotals([...filtered]);
                setCredits(filtered);
            }else{
                const orignalCopy = getCopy(orignalCredits);
                setTotals([...orignalCopy]);
                setCredits([...orignalCopy]);
            }
        }else{
            setSearchText('');
            const filtered = getFilteredResults(text,isLated,orignalCredits);
                setTotals([...filtered]);
                setCredits(filtered);
        }
    }

    const onChangeLated = (isLated:boolean) =>{
        onSearchChange(searchText,isLated);
        setIsLatedOnly(isLated);
    }
    const goBack = () =>{
        navigate(-1);
      }

    const onParamChange = (prms:ICreditHistoryParam) => {
        setParams({...prms});
    }

    const onResetFilters = () => {
        let changed = {
            SlipNumber:null,
            FromDate:null,
            ToDate:null,
            OwnerCellNumber:null,
            OwnerName:null,
            ResponsiblePersonName:null,
            ShopAddress:null,
            ShopName:null,
            SearchText:null,
        };

        setParams(changed);
        resetEverything();
    }

    //#endregion handlers end

    //#region apis call start

    const validateFilters = () =>{
        if(!!params){
            let anyFilterFound = (params.FromDate !== undefined && params.FromDate != null)
            || (params.ToDate !== undefined && params.ToDate != null)
            || (params.SlipNumber !== undefined && params.SlipNumber != null && params.SlipNumber > 0)
            || (params.ShopName !== undefined && params.ShopName != null && params.ShopName.trim() != '')
            || (params.ShopAddress !== undefined && params.ShopAddress != null && params.ShopAddress.trim() != '')
            || (params.OwnerName !== undefined && params.OwnerName != null && params.OwnerName.trim() != '')
            || (params.OwnerCellNumber !== undefined && params.OwnerCellNumber != null && params.OwnerCellNumber.trim() != '')
            || (params.ResponsiblePersonName !== undefined && params.ResponsiblePersonName != null && params.ResponsiblePersonName.trim() != '');

            if(anyFilterFound == true){

                if(params.FromDate !== undefined && params.FromDate != null && params.ToDate !== undefined && params.ToDate != null && getDateOnly(params.FromDate) > getDateOnly(params.ToDate)){
                    showErrorMessage(`From Date Can't be Greater Than To Date`);
                    return false;
                }else{
                    return true;
                }

            }else{
                showErrorMessage('Please Specify Filters');
                return false;
            }
        }else{
            showErrorMessage('Please Specify Filters');
            return false;
        }
    }

    const getCreditHistory = (isOnRefresh:boolean) => {

        if(validateFilters() == false){
            return;
        }
        
        resetEverything();
        setIsLoading(true);
        setIsHistoryLoadedAfterLastChange(true);

        let text:string = searchText;
        let isLated:boolean = isLatedOnly;

        if(isOnRefresh == false){
            setSearchText('');
            setIsLatedOnly(false);
            text = '';
            isLated = false;
        }
        
        doCreditApi.getCreditHistory(getCommaSeparatedUnitUniqueIdsExceptAll(props.selectedUnits.UnitAccesses), props.loginUser.UserId, params.SlipNumber, params.ShopName, params.ShopAddress, params.OwnerName, params.OwnerCellNumber,params.ResponsiblePersonName, params.FromDate, params.ToDate)
            .then(results => {
                setIsLoading(false);
                if (!!results) {
                    const filtered = getFilteredResults(text,isLated,results);
                    setCredits([...filtered]);
                    setOrignalCredits([...results]);
                    setTotals([...filtered]);
                }
                else {
                    setOrignalCredits([]);
                    setCredits([]);
                    setTotals([]);
                }
            })
            .catch(e => {
                console.log(e);
                setIsLoading(false);
                setOrignalCredits([]);
                setCredits([]);
                setTotals([]);
            });
    }

    const setTotals = (credts:IDOCredit[]) =>{
        let uWiseTotals:ITotal[] = [];
        let oAllTotal:ITotal = {
            TotalCredit:0,
            TotalPending:0,
            TotalReceived:0,
            UnitUniqueId:''
        };

        if(!!credts && credts.length > 0){
            credts.forEach(c => {
                if(uWiseTotals.findIndex(i => i.UnitUniqueId == c.UnitUniqueId) >= 0){
                    let thatTotal:ITotal = uWiseTotals.find(uw => uw.UnitUniqueId == c.UnitUniqueId);
                    thatTotal.TotalCredit += c.TotalAmount;
                    thatTotal.TotalPending += c.PendingAmount;
                    thatTotal.TotalReceived += c.ReceivedAmount;
                }else{
                    let thisTotal:ITotal = {
                        TotalCredit:c.TotalAmount,
                        TotalPending:c.PendingAmount,
                        TotalReceived:c.ReceivedAmount,
                        UnitUniqueId:c.UnitUniqueId
                    };

                    uWiseTotals.push(thisTotal);
                }

                oAllTotal.TotalCredit += c.TotalAmount;
                oAllTotal.TotalPending += c.PendingAmount;
                oAllTotal.TotalReceived += c.ReceivedAmount;
            });
        }

        setUnitWiseTotals([...uWiseTotals.map(a => a)]);
        setOverAllTotal({...oAllTotal});
    }

    //#endregion apis calls end

    //#region hooks start
    React.useEffect(() => {
        getCreditHistory(true);
    }, [props.selectedUnits.UnitAccesses]);

    //#endregion hooks end

    const creditHistoryKey = 'CreditOverAllHistoryKey';
    return (
        (!!props.selectedUnits && !!props.selectedUnits.UnitAccesses && props.selectedUnits.UnitAccesses.filter(u => u.UnitUniqueId != AllOptionUniqueId).length > 0) ? <Page key={`${creditHistoryKey}_1`} title="Credit History | Raza Pak Care">
<MessageComponent key={`${creditHistoryKey}_2`} onRemove={(i: number) => {
                let msgs = [...pageMessages];
                msgs.splice(i, 1);
                setPageMessages([...msgs]);
            }} messages={pageMessages} />
            <CreditHistoryParam key={`${creditHistoryKey}_3`} params={params} isLoading={isLoading} onChange={onParamChange} onLoad={getCreditHistory} onReset={onResetFilters} />
<Container key={`${creditHistoryKey}_4`} style={{ maxWidth: 1500, paddingLeft: 0, paddingRight: 0 }}>
    {isHistoryLoadedAfterLastChange == true && <Grid key={`${creditHistoryKey}_5`} item md={12}>
    <Grid key={`${creditHistoryKey}_6`} container style={{ flexDirection: 'row'}}>
                    <Grid key={`${creditHistoryKey}_7`} container spacing={2}>
                    <Grid key={`${creditHistoryKey}_8`} xs={3} sm={3} item style={{flexDirection:'row',display:'flex',justifyContent:'flex-start'}}>
                <TextField
                style={{margin:3, marginRight:15}}
                        autoComplete="search_text"
                        fullWidth
                        variant='outlined'
                        type={'text'}
                        label=""
                        value={searchText}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                            onSearchChange(event.target.value, isLatedOnly);
                        }}

                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon icon={'akar-icons:search'} />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                !!searchText ? <InputAdornment position="end">
                                    <Icon onClick={() => {onSearchChange('',isLatedOnly)}} style={{cursor:'pointer'}} icon={'ic:twotone-clear'} />
                                </InputAdornment> : undefined
                            )
                        }}
                        key={`${creditHistoryKey}_9`}
                    />
                    </Grid>
                    <Grid key={`${creditHistoryKey}_10`} item xs={2} sm={2} style={{display:'flex',flexDirection:'row', justifyContent:'center'}}>
                    <FormGroup key={`${creditHistoryKey}_11`} style={{alignSelf:'flex-end', display:'flex'}}>
                        <FormControlLabel key={`${creditHistoryKey}_12`} style={{alignSelf:'flex-end'}} control={<Switch checked={isLatedOnly} onChange={() => {onChangeLated(!isLatedOnly)}} />} label="Lated Only" />
                    </FormGroup>
                    </Grid>
                    <Grid key={`${creditHistoryKey}_13`} item xs={3} sm={3} style={{display:'flex', flexDirection:'row', justifyContent:'flex-end'}}>
                        <Typography key={`${creditHistoryKey}_14`} variant='subtitle1' style={{alignSelf:'flex-end', textDecoration:'underline', color:'black'}}>Total: {fCurrency(overAllTotal.TotalCredit)} </Typography>
                        <Typography key={`${creditHistoryKey}_15`} variant='subtitle1' style={{alignSelf:'flex-end', textDecoration:'underline', color:'green'}}>Received: {fCurrency(overAllTotal.TotalReceived)} </Typography>
                        <Typography key={`${creditHistoryKey}_16`} variant='subtitle1' style={{alignSelf:'flex-end', textDecoration:'underline', color:'red'}}>Pending: {fCurrency(overAllTotal.TotalPending)} </Typography>
                    </Grid>
                    <Grid key={`${creditHistoryKey}_17`} xs={4} sm={4} style={{flexDirection:'row',display:'flex',justifyContent:'flex-end'}} item>
                    <LoadingButton
                        size="small"
                        type="button"
                        variant="contained"
                        loading={isLoading}
                        style={{alignSelf:'flex-end',margin:5, backgroundColor: 'rgba(34, 154, 22, 0.72)', height: 40 }}
                        startIcon={<Icon icon={refreshFill} />}
                        onClick={() => {
                            resetEverythingAndReloadData();
                        }}
                        key={`${creditHistoryKey}_18`}
                    >
                        Refresh
                    </LoadingButton>
                    {(props.showBackButton === undefined || props.showBackButton == true) && <LoadingButton
                size="small"
                type="button"
                variant="contained"
                loading={isLoading}
                style={{ alignSelf: 'flex-end', margin: 5, backgroundColor: 'black', textAlign: 'right', height: 40 }}
                startIcon={<Icon icon={'akar-icons:arrow-back'} />}
                onClick={goBack}
                key={`${creditHistoryKey}_19`}
            >
                Back
            </LoadingButton>}
                    </Grid>
                    </Grid>
                </Grid>
    </Grid>}
                


                {isHistoryLoadedAfterLastChange == true && !!props.selectedUnits && !!props.selectedUnits.UnitAccesses && props.selectedUnits.UnitAccesses.filter(m => m.UnitUniqueId != AllOptionUniqueId).length > 0 && props.selectedUnits.UnitAccesses.filter(m => m.UnitUniqueId != AllOptionUniqueId).map((selectedUnit, unitIndex) => {
                    return <React.Fragment key={`${creditHistoryKey}_${selectedUnit.UnitUniqueId}_20`}><Card key={`${creditHistoryKey}_${selectedUnit.UnitUniqueId}_21`}>
                        <Typography key={`${creditHistoryKey}_${selectedUnit.UnitUniqueId}_22`} style={{ backgroundColor: 'rgba(34, 154, 22, 0.72)', color: 'white', paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h4'}>History For {selectedUnit.UnitTitle}</Typography>
                        {isLoading == true && <AudoSpin
                            height="80"
                            width="80"
                            color='green'
                            ariaLabel='three-dots-loading'
                            wrapperStyle={{ alignSelf: 'center', justifyContent: 'center' }}
                            key={`${creditHistoryKey}_${selectedUnit.UnitUniqueId}_23`}
                        />}

                        {(!credits || credits.length <= 0 || credits.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length <= 0) && isLoading == false && <Typography key={`${creditHistoryKey}_${selectedUnit.UnitUniqueId}_24`} style={{ paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h6'}>{`No Credit History For Selected Filters.`}</Typography>}

                        {!!credits && credits.length > 0 && credits.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length > 0 && <Scrollbar key={`${creditHistoryKey}_${selectedUnit.UnitUniqueId}_25`} sx={{}}>
                            <TableContainer key={`${creditHistoryKey}_${selectedUnit.UnitUniqueId}_26`} sx={{ minWidth: 800 }}>
                                <Table key={`${creditHistoryKey}_${selectedUnit.UnitUniqueId}_27`} size='small'>
                                    <UserListHead
                                        order={'asc'}
                                        orderBy={''}
                                        headLabel={TABLE_HEAD_DO_Credit_Received}
                                        rowCount={0}
                                        numSelected={0}
                                        onRequestSort={undefined}
                                        onSelectAllClick={undefined}
                                        key={`${creditHistoryKey}_${selectedUnit.UnitUniqueId}_28`}
                                        customKey={`${creditHistoryKey}_${selectedUnit.UnitUniqueId}_29`}
                                    />
                                    <TableBody key={`${creditHistoryKey}_${selectedUnit.UnitUniqueId}_30`}>
                                        {!!credits && credits.length > 0 && credits.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length > 0 && credits.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).map((row, index) => {

                                            return (
                                                <TableRow
                                                    hover
                                                    key={`${creditHistoryKey}_${selectedUnit.UnitUniqueId}_${row.OrderId}_31`}
                                                    tabIndex={-1}
                                                    role="checkbox"
                                                    selected={false}
                                                    aria-checked={false}
                                                >
                                                    <TableCell key={`${creditHistoryKey}_${selectedUnit.UnitUniqueId}_${row.OrderId}_32`} style={{color:getColor(row.CreditedOn, row.PendingAmount)}} align="center">{index + 1}</TableCell>
                                                    <TableCell key={`${creditHistoryKey}_${selectedUnit.UnitUniqueId}_${row.OrderId}_33`} style={{color:getColor(row.CreditedOn, row.PendingAmount)}} align="center"><Highlighted key={`${creditHistoryKey}_${selectedUnit.UnitUniqueId}_${row.OrderId}_38`} text={row.OrderId.toString()} highlight={searchText}  /></TableCell>
                                                    <TableCell key={`${creditHistoryKey}_${selectedUnit.UnitUniqueId}_${row.OrderId}_34`} style={{color:getColor(row.CreditedOn, row.PendingAmount)}} align="center"><Highlighted key={`${creditHistoryKey}_${selectedUnit.UnitUniqueId}_${row.OrderId}_39`} text={row.ShopName ?? ''} highlight={searchText}  /></TableCell>
                                                    <TableCell key={`${creditHistoryKey}_${selectedUnit.UnitUniqueId}_${row.OrderId}_35`} style={{color:getColor(row.CreditedOn, row.PendingAmount)}} align="center"><Highlighted key={`${creditHistoryKey}_${selectedUnit.UnitUniqueId}_${row.OrderId}_40`} text={row.ShopAddress ?? ''} highlight={searchText}  /></TableCell>
                                                    <TableCell key={`${creditHistoryKey}_${selectedUnit.UnitUniqueId}_${row.OrderId}_36`} style={{color:getColor(row.CreditedOn, row.PendingAmount)}} align="center" component="th" scope="row" padding="none">
                                                        <Highlighted key={`${creditHistoryKey}_${selectedUnit.UnitUniqueId}_${row.OrderId}_37`} text={`${!!row.OwnerCellNumber && row.OwnerCellNumber.trim() != '' ? `${row.OwnerCellNumber}` : 'N/A'}`} highlight={searchText}  />
                                                    </TableCell>
                                                    <TableCell key={`${creditHistoryKey}_${selectedUnit.UnitUniqueId}_${row.OrderId}_41`} style={{color:getColor(row.CreditedOn, row.PendingAmount)}} align="center"><Highlighted key={`${creditHistoryKey}_${selectedUnit.UnitUniqueId}_${row.OrderId}_42`} text={row.ResponsibleEmployeeName} highlight={searchText}  /></TableCell>

                                                    <TableCell key={`${creditHistoryKey}_${selectedUnit.UnitUniqueId}_${row.OrderId}_43`} style={{color:getColor(row.CreditedOn, row.PendingAmount)}} align="center" component="th" scope="row" padding="none">
                                                        {formattedDate(row.CreditedOn, 'ddd DD-MM-YYYY')}
                                                    </TableCell>

                                                    <TableCell key={`${creditHistoryKey}_${selectedUnit.UnitUniqueId}_${row.OrderId}_44`} align="center" component="th" scope="row" padding="none">
                                                        <strong><Highlighted key={`${creditHistoryKey}_${selectedUnit.UnitUniqueId}_${row.OrderId}_45`} text={`Rs.${row.TotalAmount}/-`} highlight={searchText}  /></strong>
                                                    </TableCell>

                                                    <TableCell key={`${creditHistoryKey}_${selectedUnit.UnitUniqueId}_${row.OrderId}_46`} align="center" component="th" scope="row" padding="none" style={{ color: 'green' }}>
                                                        <strong><Highlighted key={`${creditHistoryKey}_${selectedUnit.UnitUniqueId}_${row.OrderId}_47`} text={`Rs.${row.ReceivedAmount}/-`} highlight={searchText}  /></strong>
                                                    </TableCell>

                                                    <TableCell key={`${creditHistoryKey}_${selectedUnit.UnitUniqueId}_${row.OrderId}_48`} align="center" component="th" scope="row" padding="none" style={{ color: 'red' }}>
                                                        <Typography variant='h6'><Highlighted key={`${creditHistoryKey}_${selectedUnit.UnitUniqueId}_${row.OrderId}_49`} text={`Rs.${row.PendingAmount}/-`} highlight={searchText}  /></Typography>
                                                    </TableCell>

                                                    <TableCell key={`${creditHistoryKey}_${selectedUnit.UnitUniqueId}_${row.OrderId}_50`} align="center">
                                                        <Label
                                                        key={`${creditHistoryKey}_${selectedUnit.UnitUniqueId}_${row.OrderId}_51`}
                                                            variant="ghost"
                                                            color={row.CreditStatusId == 1 ? 'error' : (row.CreditStatusId == 2 ? 'info' : 'success')}
                                                        >
                                                            <Highlighted key={`${creditHistoryKey}_${selectedUnit.UnitUniqueId}_${row.OrderId}_52`} text={sentenceCase(row.CreditStatus ?? '')} highlight={searchText}  />
                                                        </Label>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                        <TableRow key={`${creditHistoryKey}_${selectedUnit.UnitUniqueId}_53`}>
                                            <TableCell key={`${creditHistoryKey}_${selectedUnit.UnitUniqueId}_54`} colSpan={7} align='right'><h3>Total:</h3></TableCell>
                                            <TableCell key={`${creditHistoryKey}_${selectedUnit.UnitUniqueId}_55`} align='center' style={{color:'green'}}><h3>{fCurrency(unitWiseTotals.find(un => un.UnitUniqueId == selectedUnit.UnitUniqueId)?.TotalCredit ?? 0)}</h3></TableCell>
                                            <TableCell key={`${creditHistoryKey}_${selectedUnit.UnitUniqueId}_56`} align='center' style={{color:'green'}}><h3>{fCurrency(unitWiseTotals.find(un => un.UnitUniqueId == selectedUnit.UnitUniqueId)?.TotalReceived ?? 0)}</h3></TableCell>
                                            <TableCell key={`${creditHistoryKey}_${selectedUnit.UnitUniqueId}_57`} align='center' style={{color:'green'}}><h3>{fCurrency(unitWiseTotals.find(un => un.UnitUniqueId == selectedUnit.UnitUniqueId)?.TotalPending ?? 0)}</h3></TableCell>
                                            <TableCell key={`${creditHistoryKey}_${selectedUnit.UnitUniqueId}_58`}>&nbsp;</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Scrollbar>}
                    </Card><br /></React.Fragment>
                })}


            </Container>
        </Page> : <NoUnitSelectedComponent key={`${creditHistoryKey}_59`} pageTitle='Credit History | Raza Pak Care' />
    );
}

const mapStateToProps = (state: IApplicationState) => {
    return {
        selectedUnits: state.selectedUnitAccesses,
        loginUser: state.loginUser
    };
}
const connectedCreditHistory = connect(mapStateToProps)(CreditHistory);
export { connectedCreditHistory as CreditHistory };
