import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IApplicationState } from 'src/redux';
import { connect } from 'react-redux';
import { Container, Grid } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { IAppConfirmation } from 'src/interfaces/app-confirmation.interface';

interface IProps {
    confirmation:IAppConfirmation | null;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width:500,
    bgcolor: 'background.paper',
    border: '3px solid #660000',
    boxShadow: 24,
    paddingTop: 2,
    paddingBottom: 2
};

const ConfirmationPopup = (props: IProps) => {
    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick") {
            return;
        }

        if(props.confirmation.onOk){
            props.confirmation.onOk(false);
        }
    }

    return <Modal
        open={!!props.confirmation}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disablePortal
        sx={{zIndex:1500}}
        slotProps={{backdrop:{color:!!props.confirmation && !!props.confirmation.backdropColor ? props.confirmation.backdropColor : undefined}}}
    >
        <Box sx={style}>
            <Grid item md={12} style={{ justifyContent: 'center', display: 'flex' }}>
                <Typography style={{ alignSelf: 'center', color:!!props.confirmation?.titleColor ? props.confirmation.titleColor : undefined }} id="modal-modal-title" variant="h6" component="h2">
                    {props.confirmation?.title ?? ''}
                </Typography>
            </Grid>

            <Container>
                <Grid container spacing={2} style={{ marginTop: 5 }}>
                    <Grid item sm={12}>
                        <Typography style={{color:!!props.confirmation?.messageColor ? props.confirmation.messageColor : undefined }}>{props.confirmation?.message ?? ''}</Typography>
                    </Grid>
                </Grid>
                <Grid item md={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    {(!!props.confirmation && !props.confirmation.hideYesButton) && <LoadingButton
                        size="small"
                        type="button"
                        variant="outlined"
                        loading={false}
                        disabled={false}
                        style={{ marginTop: 15, alignSelf: 'center', color: props.confirmation?.yesButtonColor  ?? 'green', borderColor:props.confirmation?.yesButtonColor  ?? 'green', borderRadius: 0, marginRight:10 }}
                        onClick={() => {
                            if(props.confirmation.onOk){
                                props.confirmation.onOk(true);
                            }
                        }}
                    >
                        {props.confirmation?.yesButtonText ?? 'Yes'}
                    </LoadingButton>}
                    <LoadingButton
                        size="small"
                        type="button"
                        variant="outlined"
                        loading={false}
                        style={{ marginTop: 15, color: props.confirmation?.noButtonColor ?? 'gray', alignSelf: 'center', borderColor: props.confirmation?.noButtonColor ?? 'gray', borderRadius: 0 }}
                        onClick={() =>{
                            if(props.confirmation.onOk){
                                props.confirmation.onOk(false);
                            }
                        }}
                    >
                        {props.confirmation?.noButtonText ?? 'No'}
                    </LoadingButton>
                </Grid>
            </Container>
        </Box>
    </Modal>;
}

const mapStateToProps = (state:IApplicationState) =>{
    return {
        confirmation:state.confirmation
    };
}

const connnectedComponent = connect(mapStateToProps)(ConfirmationPopup);

export {connnectedComponent as ConfirmationPopup};