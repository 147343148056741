import {IAction} from './action.interface';
import {AppConfirmation} from './action.constant';
import { IAppConfirmation } from 'src/interfaces/app-confirmation.interface';

export const setAppConfirmationModalAction = (
  payload: IAppConfirmation | null,
): IAction<IAppConfirmation | null> => {
  return {
    type: AppConfirmation.SET_APP_CONFIRMATION_MODAL,
    payload,
  };
};
