import React from 'react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
//
import account from '../../_mocks_/account';
import { connect, useSelector } from 'react-redux';
import { IApplicationState } from 'src/redux';
import { IUserMenu } from 'src/interfaces/routes/user-menu.interface';
import { ISidebarConfig } from 'src/interfaces/sidebar-config.interface';
import { Icon } from '@iconify/react';
import { UnitAccessDropDown } from 'src/components/common/UnitAccessDropDown.component';
import { IUserDetail } from 'src/interfaces';
import { UnitAccessMultiSelect } from 'src/components/common/UnitAccessMultiSelect';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));


const LogoRootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: 45,
  flexDirection:'row',
  backgroundColor:alpha(theme.palette.success.dark, 0.72),
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  marginTop:10,
  borderRadius: (theme.shape as any).borderRadiusSm,
  backgroundColor: theme.palette.grey[200]
}));

// ----------------------------------------------------------------------

interface IProps{
  isOpenSidebar:boolean;
  onCloseSidebar:() => void;
  userMenu:IUserMenu[];
  loginUser:IUserDetail | null;
}

const DashboardSidebar = (props:IProps) => {
  const { pathname } = useLocation();
  const [sidebarConfig,setSideBarConfig] = React.useState<ISidebarConfig[]>([]);
  const userDetail = useSelector((state:IApplicationState) => { return state.loginUser;});
  useEffect(() => {
    if (props.isOpenSidebar == true) {
      props.onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const getIcon = (name:string) => <Icon icon={name} width={22} height={22} />;
  
  React.useEffect(() => {
    if(!!props.userMenu && props.userMenu.length > 0){
      let tempConfig:ISidebarConfig[] = [];
      const umenu = props.userMenu.map(um => Object.assign({},um));
      umenu.sort((a,b) => {return a.DisplayOrder - b.DisplayOrder;}).forEach(um => {
        tempConfig.push({
          title:um.DisplayName,
          path:um.Path,
          icon:getIcon(um.IconName)
        });
      });

      setSideBarConfig([...tempConfig]);

    }else{
      setSideBarConfig([]);
    }
  },[props.userMenu]);

  const renderContent = (
    <><LogoRootStyle>
          <Logo sx={{marginLeft:1, alignSelf:'center'}} />
          <Box component={'span'} color={'white'} sx={{marginLeft:1, alignSelf:'center'}}>Raza PakCare Pvt Ltd.</Box>
      </LogoRootStyle>
      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="#">
          <AccountStyle>
            <Avatar src={account.photoURL} alt="photoURL" />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
              {`${userDetail.FirstName} ${userDetail.LastName}`}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {account.role}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>
      {!!props.loginUser && !!props.loginUser.UnitAccesses && props.loginUser.UnitAccesses.length > 1 &&  <Box sx={{ mb: 5, mx: 2.5 }}>
        <UnitAccessMultiSelect />
      </Box>}
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': {display: 'flex', marginBottom:3, flexDirection: 'column' }
      }}
    >
      <NavSection navConfig={sidebarConfig} />
    </Scrollbar></>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={props.isOpenSidebar}
          onClose={props.onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default'
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}

const mapStateToProps = (state:IApplicationState) => {
  return {
    userMenu:state.userMenu,
    loginUser:state.loginUser
  };
}
const connectedSideBar = connect(mapStateToProps)(DashboardSidebar);

export {connectedSideBar as DashboardSidebar};
