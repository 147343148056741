import React from 'react';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import arrowBackFill from '@iconify/icons-eva/arrow-back-fill';
import refreshFill from '@iconify/icons-eva/refresh-fill';
import saveFill from '@iconify/icons-eva/save-fill';
import moneyCollectFill from '@iconify/icons-ant-design/money-collect-fill';
// material
import {
    Card,
    Table,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    Grid,
    TextField,
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { UserListHead } from '../components/_dashboard/user';
//
import { LoadingButton } from '@mui/lab';
import { doCashApi, doCreditApi, productApi } from '../http';

import { useSnackbar } from 'notistack';
import { connect, useSelector } from 'react-redux';
import { IApplicationState } from 'src/redux';
import { Audio as AudoSpin } from 'react-loader-spinner'
import { IDODetail } from 'src/interfaces/delivery-order/do-detail.interface';
import Label from 'src/components/Label';
import { sentenceCase } from 'change-case';
import { IDOCredit } from 'src/interfaces/do-credit.interface';
import { confirmAction } from 'src/utility/confirm.util';
import { IDOCashDetail } from 'src/interfaces/do-cash-detail.interface';
import { IProductStock } from 'src/interfaces/product-stock.interface';
import { IUserDetail } from 'src/interfaces';
import { ISelectedUnitAccess } from 'src/interfaces/selected-unit-access.interface';
import { AllOptionUniqueId } from 'src/app.constant';
import { getCommaSeparatedUnitUniqueIdsExceptAll } from 'src/utility';
import { MessageComponent } from 'src/components/messages.component';
import { NoUnitSelectedComponent } from 'src/components/common/no-unit-selected.component';
import { Keys } from 'src/component.keys';
import { getUrlFromComponentKey } from 'src/utility/navigation.util';
import { IUserRouteDetail } from 'src/interfaces/routes/user-route-detail.interface';
import { useNavigate } from "react-router-dom";


// ----------------------------------------------------------------------

const TABLE_HEAD_CASH_SUMMARY = [
    { id: 'sr', label: 'Sr.', alignContent: 'center' },
    { id: 'product_name', label: 'Product Name', alignContent: 'center' },
    { id: 'available_stock', label: 'Available Stock(CTN)', alignContent: 'center' },
    { id: 'available_stock', label: 'Available Stock(Pieces)', alignContent: 'center' },
];

// ----------------------------------------------------------------------

interface IProps {
    loginUser: IUserDetail | null;
    selectedUnits: ISelectedUnitAccess;
    userRoutes:IUserRouteDetail;
}

const Stock = (props:IProps) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [stockDetail, setStockDetail] = React.useState<IProductStock[]>([]);
    const [viewStockLedgerUrl, setViewStockLedgerUrl] = React.useState<string>('');
    const [addStockUrl, setAddStockUrl] = React.useState<string>('');

    const onViewStockLedger = () => {
        navigate(viewStockLedgerUrl);
    }

    const onAddStock = () => {
        navigate(addStockUrl);
    }

    const getProductStock = () => {
        setIsLoading(true);
        setStockDetail([]);
        //revist
        productApi.getUnitWiseProductStock(props.loginUser.UserId, getCommaSeparatedUnitUniqueIdsExceptAll([...props.selectedUnits.UnitAccesses.map(a => a)]))
            .then(cd => {
                setIsLoading(false);
                setStockDetail([...cd]);
            })
            .catch(e => {
                setIsLoading(false);
                console.log(e);
            })
    }

    React.useEffect(() => {
        if(!!props.selectedUnits && !!props.selectedUnits.UnitAccesses && props.selectedUnits.UnitAccesses.filter(u => u.UnitUniqueId != AllOptionUniqueId).length > 0){
            getProductStock();
        }
    }, [props.selectedUnits.UnitAccesses]);

    React.useEffect(() =>{
        if(!!props.userRoutes && !!props.userRoutes.Routes && props.userRoutes.Routes.length > 0){
            setViewStockLedgerUrl(getUrlFromComponentKey(props.userRoutes,Keys.StockLedger));
            setAddStockUrl(getUrlFromComponentKey(props.userRoutes,Keys.AddStock));
        }
    },[props.userRoutes]);

    return (
        (!!props.selectedUnits && !!props.selectedUnits.UnitAccesses && props.selectedUnits.UnitAccesses.filter(u => u.UnitUniqueId != AllOptionUniqueId).length > 0) ? <>
        
        <Page title="Stock | Raza Pak Care">
            <Container style={{ maxWidth: 1500, paddingLeft: 0, paddingRight: 0 }}>
                <Grid container style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>

                {(!!viewStockLedgerUrl && viewStockLedgerUrl.trim() != '') && <LoadingButton
                        size="small"
                        type="button"
                        variant="contained"
                        loading={isLoading}
                        style={{ margin: 3, backgroundColor: 'blue', textAlign: 'right', height: 40 }}
                        startIcon={<Icon icon={'emojione:ledger'} />}
                        onClick={onViewStockLedger}
                    >
                        View Stock Ledger
                    </LoadingButton>}

                    {(!!addStockUrl && addStockUrl.trim() != '') && <LoadingButton
                        size="small"
                        type="button"
                        variant="contained"
                        loading={isLoading}
                        style={{ margin: 3, backgroundColor: 'black', textAlign: 'right', height: 40 }}
                        startIcon={<Icon icon={'carbon:carbon-for-ibm-product'} />}
                        onClick={onAddStock}
                    >
                        Manage Stock
                    </LoadingButton>}

                    <LoadingButton
                        size="small"
                        type="button"
                        variant="contained"
                        loading={isLoading}
                        style={{ margin: 3, backgroundColor: 'rgba(34, 154, 22, 0.72)', textAlign: 'right', height: 40 }}
                        startIcon={<Icon icon={refreshFill} />}
                        onClick={getProductStock}
                    >
                        Refresh
                    </LoadingButton>
                </Grid>
                {!!props.selectedUnits && !!props.selectedUnits.UnitAccesses && props.selectedUnits.UnitAccesses.filter(m => m.UnitUniqueId != AllOptionUniqueId).length > 0 && props.selectedUnits.UnitAccesses.filter(m => m.UnitUniqueId != AllOptionUniqueId).map((selectedUnit, unitIndex) => {
                return <><Card>
                    <Typography style={{ backgroundColor: 'rgba(34, 154, 22, 0.72)', color: 'white', paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h4'}>{selectedUnit.UnitTitle} Stock Details</Typography>
                    {isLoading == true && <AudoSpin
                        height="80"
                        width="80"
                        color='green'
                        ariaLabel='three-dots-loading'
                        wrapperStyle={{ alignSelf: 'center', justifyContent: 'center' }}
                    />}

                    {(!stockDetail || stockDetail.length <= 0 || stockDetail.filter(s => s.UnitUniqueId == selectedUnit.UnitUniqueId).length <= 0) && isLoading == false && <Typography style={{ paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h6'}>No Details Available For {selectedUnit.UnitTitle} Store.</Typography>}
                    {!!stockDetail && stockDetail.length > 0 && stockDetail.filter(s => s.UnitUniqueId == selectedUnit.UnitUniqueId).length > 0 && <Scrollbar sx={{}}>
                        <TableContainer sx={{ minWidth: 800 }}>
                            {<Table>
                                <UserListHead
                                    order={'asc'}
                                    orderBy={''}
                                    headLabel={TABLE_HEAD_CASH_SUMMARY}
                                    rowCount={0}
                                    numSelected={0}
                                    onRequestSort={undefined}
                                    onSelectAllClick={undefined}
                                    customKey={`TableHeadRow_2`}
                                />

                                <TableBody key={`Table_Body_Selection_Details_Summary`}>
                                    {stockDetail.filter(s => s.UnitUniqueId == selectedUnit.UnitUniqueId).map((row,index) => <TableRow
                                        hover
                                        key={`TableDetailRow_SUMMARY_${row.Id}`}
                                        tabIndex={-1}
                                        role="checkbox"
                                        selected={false}
                                        aria-checked={false}
                                    >
                                        <TableCell align="center">{index + 1}</TableCell>
                                        <TableCell align="center">
                                            {row.ProductName}
                                        </TableCell>
                                        <TableCell align="center"><Typography variant='subtitle2'>{`${Math.floor(row.AvailableQty / ((row.ProductId === 2 || row.ProductId === 3 || row.ProductId === 4) ? 48 : 72))} CTN ${row.AvailableQty - (Math.floor(row.AvailableQty / ((row.ProductId === 2 || row.ProductId === 3 || row.ProductId === 4) ? 48 : 72)) * ((row.ProductId === 2 || row.ProductId === 3 || row.ProductId === 4) ? 48 : 72))} Pieces`}</Typography></TableCell>
                                        <TableCell align="center"><Typography variant='subtitle2'>{row.AvailableQty} Pieces</Typography></TableCell>
                                    </TableRow>)}
                                </TableBody>
                            </Table>}
                        </TableContainer>
                    </Scrollbar>
                    }
                </Card><br /></>})}
            </Container>
        </Page></> : <NoUnitSelectedComponent pageTitle='Stock | Raza Pak Care' />
    );
}


const mapStateToProps = (state: IApplicationState) => {
    return {
        selectedUnits: state.selectedUnitAccesses,
        loginUser: state.loginUser,
        userRoutes:state.userRoutes,
    };
}
const connectedStock = connect(mapStateToProps)(Stock);
export { connectedStock as Stock };