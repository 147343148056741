import { replace } from 'lodash';
import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fCurrency(number) {
  let val =  numeral(number).format(Number.isInteger(number) ? '0,0' : '0,0.00');
  return `Rs.${val}/-`;
}

export function fCommaSeparated(number) {
  let val =  numeral(number).format(Number.isInteger(number) ? '0,0' : '0,0');
  return `${val}`;
}

export function fCommaSeparatedWithDecial(number) {
  let val =  numeral(number).format(Number.isInteger(number) ? '0,0' : '0,0.00');
  return `${val}`;
}

export function fUptoTwoDecimal(number) {
  let val =  numeral(number).format(Number.isInteger(number) ? '0' : '0.00');
  return `${val}`;
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return replace(numeral(number).format('0.00a'), '.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}
