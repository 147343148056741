import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Typography } from '@mui/material';
// components
import { MHidden } from '../../components/@material-extend';
import AccountPopover from './AccountPopover';
import NotificationsPopover from './NotificationsPopover';
import Logo from 'src/components/Logo';
import { connect, useSelector } from 'react-redux';
import { IApplicationState } from 'src/redux';
import { AllOptionUniqueId } from 'src/app.constant';
import { IUserDetail } from 'src/interfaces';
import { ISelectedUnitAccess } from 'src/interfaces/selected-unit-access.interface';
import { getCommaSeparatedSelectedUnitsString } from 'src/utility';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 45;
const APPBAR_DESKTOP = 45

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.success.dark, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

interface IProps{
  onOpenSidebar:() => void;
  UserDetail:IUserDetail;
  SelectedUnitAccess:ISelectedUnitAccess;
}
const DashboardNavbar = (props:IProps) => {
  return (
    <RootStyle>
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton onClick={props.onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>
        {!!props.UserDetail && !!props.UserDetail.FirstName && <Box component={'span'} sx={{ flexGrow: 1,display:'flex', flexDirection:'row' }}>
          Salam {`${props.UserDetail.FirstName} ${props.UserDetail.LastName}`},{!!props.SelectedUnitAccess && props.SelectedUnitAccess.UnitAccesses.length > 0 && <h2 style={{width:'90%',textAlign:'center'}}>{getCommaSeparatedSelectedUnitsString([...props.SelectedUnitAccess.UnitAccesses.filter(u => u.UnitUniqueId != AllOptionUniqueId)])}</h2>}
        </Box>}
        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <NotificationsPopover />
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}

const mapStateToProps = (state:IApplicationState) =>{
  return {
    UserDetail:state.loginUser,
    SelectedUnitAccess:state.selectedUnitAccesses
  }
}
const connectedDashboardNavbar = connect(mapStateToProps)(DashboardNavbar);

export {connectedDashboardNavbar as DashboardNavbar};
