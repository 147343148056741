import { IAction } from './action.interface';
import { OrderBookerInfoConstants } from './action.constant';
import { IOrderBookerRouteInfoNew } from 'src/interfaces/order-booker-route-info-new.interface';
import { IAreaWiseOrderBookerInfo } from 'src/interfaces/area-wise-order-booker-info.interface';
import { IApplicationState } from '../application-state.interface';
import { getCopy, getDayName } from 'src/utility';
import { routeApi } from 'src/http';
import { UnAssignedOrderBookerId } from 'src/app.constant';
import { IRouteInfoNew } from 'src/interfaces/route-info-new.interface';
import { IRoute } from 'src/interfaces/route.interface';
import { setIsDeletingRouteAction, setIsSavingNewRouteAction, setNewRouteSetupPageErrorMessageAction, setNewRouteSetupPageSuccessMessageAction, setRoutePoliesAction, setSelectedRouteIdOnMapAction } from './new-route-setup-action';
import { IRoutePoly } from 'src/interfaces/route-poly.interface';

export const setAreaWiseOrderBookerInfoAction = (
    payload: IAreaWiseOrderBookerInfo,
): IAction<IAreaWiseOrderBookerInfo> => {
    return {
        type: OrderBookerInfoConstants.SET_ORDER_BOOKER_INFO,
        payload,
    };
};

export const resetOrderBookerInfoAction = () => {
    return {
        type: OrderBookerInfoConstants.RESET_ORDER_BOOKER_INFO,
    };
}

export const resetIsLoadingOrderBookerInfoAction = () => {
    return {
        type: OrderBookerInfoConstants.SET_ORDER_BOOKER_INFO_LOADING,
        payload: false,
    };
}

export const setSelectedAreaIdAction = (payload: number): IAction<number> => {
    return {
        type: OrderBookerInfoConstants.SET_SELECTED_AREA_ID,
        payload,
    };
}

export const resetIsSavingAssignmentAction = () => {
    return {
        type: OrderBookerInfoConstants.SET_ROUTE_ASSIGNMENT_SAVING,
        payload: false,
    };
}

const setIsLoadingOrderBookerInfo = (payload: boolean): IAction<boolean> => {
    return {
        type: OrderBookerInfoConstants.SET_ORDER_BOOKER_INFO_LOADING,
        payload,
    };
}

const setIsRouteAssignmentSavingAction = (payload: boolean): IAction<boolean> => {
    return {
        type: OrderBookerInfoConstants.SET_ROUTE_ASSIGNMENT_SAVING,
        payload,
    };
}

export const softReloadAreaOrderBookerInfoAction = (areaId: number) => {
    return (dispatch, getState) => {
        if (areaId > 0) {
            dispatch(setIsLoadingOrderBookerInfo(true));
            const state: IApplicationState = getState();

            if (!!state.orderBookerRouteInfo) {
                const existingInfo = getCopy(state.orderBookerRouteInfo);
                if (existingInfo.findIndex(m => m.areaId === areaId) >= 0) {
                    dispatch(setIsLoadingOrderBookerInfo(false));
                    dispatch(setAreaWiseOrderBookerInfoAction(existingInfo.find(t => t.areaId === areaId)));
                } else {
                    loadData(areaId, dispatch);
                }
            } else {
                loadData(areaId, dispatch);
            }
        }
    }
}

export const hardReloadAreaOrderBookerInfoAction = (areaId: number) => {
    return (dispatch, getState) => {
        if (areaId > 0) {
            dispatch(setIsLoadingOrderBookerInfo(true));
            loadData(areaId, dispatch);
        }
    }
}

export const toggleOrderBookerRowCollapseAction = (areaId: number, employeeId: number) => {
    return (dispatch, getState) => {
        const state: IApplicationState = getState();

        if (!!state.orderBookerRouteInfo) {
            const existingInfo = getCopy(state.orderBookerRouteInfo);
            if (existingInfo.findIndex(m => m.areaId === areaId) >= 0) {
                const toModify = existingInfo.find(t => t.areaId === areaId);
                if (!!toModify && !!toModify.orderBookerInfo) {
                    const newState = toModify.orderBookerInfo.map(ob => {
                        if (ob.EmployeeId == employeeId) {
                            ob.IsOrderBookerCollapsed = !ob.IsOrderBookerCollapsed;
                        }

                        ob.Routes = !!ob.Routes && ob.Routes.length > 0 ? ob.Routes.map(b => {
                            if (ob.EmployeeId == employeeId) {
                                b.EditMode = false;
                                b.IsEditingAny = false;
                            }

                            return b;
                        }) : [];

                        return ob;
                    });

                    dispatch(setAreaWiseOrderBookerInfoAction({ areaId: areaId, orderBookerInfo: newState }));
                }
            }
        }
    }
}

export const editOrderBookerRouteInfoAction = (areaId: number, employeeId: number, routeDay: number, routeId: number) => {
    return (dispatch, getState) => {
        const state: IApplicationState = getState();

        if (!!state.orderBookerRouteInfo) {
            const existingInfo = getCopy(state.orderBookerRouteInfo);
            if (existingInfo.findIndex(m => m.areaId === areaId) >= 0) {
                const toModify = existingInfo.find(t => t.areaId === areaId);
                if (!!toModify && !!toModify.orderBookerInfo) {
                    const newState = toModify.orderBookerInfo.map(ob => {
                        if (ob.EmployeeId == employeeId) {
                            ob.Routes = !!ob.Routes && ob.Routes.length > 0 ? ob.Routes.map(b => {
                                b.EditMode = employeeId == UnAssignedOrderBookerId ? b.RouteId == routeId : b.RouteDay == routeDay;
                                b.IsEditingAny = true;
                                return b;
                            }) : [];
                        } else {
                            ob.Routes = !!ob.Routes && ob.Routes.length > 0 ? ob.Routes.map(b => {
                                b.EditMode = false;
                                b.IsEditingAny = false;
                                return b;
                            }) : [];
                        }

                        return ob;
                    });

                    dispatch(setAreaWiseOrderBookerInfoAction({ areaId: areaId, orderBookerInfo: newState }));
                }
            }
        }
    }
}

export const cancelEditOrderBookerRouteInfoAction = (areaId: number) => {
    return (dispatch, getState) => {
        const state: IApplicationState = getState();

        if (!!state.orderBookerRouteInfo) {
            const existingInfo = getCopy(state.orderBookerRouteInfo);
            if (existingInfo.findIndex(m => m.areaId === areaId) >= 0) {
                const toModify = existingInfo.find(t => t.areaId === areaId);
                if (!!toModify && !!toModify.orderBookerInfo) {
                    const newState = toModify.orderBookerInfo.map(ob => {
                        ob.Routes = !!ob.Routes && ob.Routes.length > 0 ? ob.Routes.map(b => {
                            b.EditMode = false;
                            b.IsEditingAny = false;
                            return b;
                        }) : [];
                        return ob;
                    });

                    dispatch(setAreaWiseOrderBookerInfoAction({ areaId: areaId, orderBookerInfo: newState }));
                }
            }
        }
    }
}

// export const updateAssignmentDetailLocallyAction = (areaId: number, fromOrderBookerId: number, toOrderBookerId: number, toRouteDay: number, routeForProcess: IRouteInfoNew) => {
//     return (dispatch, getState) => {
//         const state: IApplicationState = getState();

//         if (!!state.orderBookerRouteInfo) {
//             const existingInfo = getCopy(state.orderBookerRouteInfo);
//             if (existingInfo.findIndex(m => m.areaId === areaId) >= 0) {
//                 const toModify = existingInfo.find(t => t.areaId === areaId);
//                 if (!!toModify && !!toModify.orderBookerInfo) {
//                     let newState = setRouteAssignment(toModify.orderBookerInfo, fromOrderBookerId, toOrderBookerId, toRouteDay, routeForProcess);
//                     dispatch(setAreaWiseOrderBookerInfoAction({ areaId: areaId, orderBookerInfo: newState }));
//                 }
//             }
//         }
//     }
// }

export const unAssignRouteAction = (areaId: number, orderBookerId: number, routeDay: number, routeForProcess: IRouteInfoNew) => {
    return (dispatch, getState) => {
        dispatch(setIsRouteAssignmentSavingAction(true));
        routeApi.unAssignRoute(orderBookerId, routeDay)
            .then(res => {
                updateOrderBookerRouteInfoState(dispatch, getState,areaId, routeForProcess.RouteId, UnAssignedOrderBookerId,-1,routeForProcess);
                dispatch(setIsRouteAssignmentSavingAction(false));
            })
            .catch(e => {
                dispatch(setIsRouteAssignmentSavingAction(false));
            });
    }
}

export const updateRouteAssignmentAction = (areaId: number, loginUserId: number, fromOrderBookerId: number, toOrderBookerId: number, toRouteDay: number, routeForProcess: IRouteInfoNew) => {
    return (dispatch, getState) => {
        dispatch(setIsRouteAssignmentSavingAction(true));
        routeApi.updateRouteAssigment(toOrderBookerId, toRouteDay, routeForProcess.RouteId, loginUserId)
            .then(res => {
                updateOrderBookerRouteInfoState(dispatch, getState,areaId, routeForProcess.RouteId,toOrderBookerId,toRouteDay,routeForProcess);
                dispatch(setIsRouteAssignmentSavingAction(false));
            })
            .catch(e => {
                dispatch(setIsRouteAssignmentSavingAction(false));
            });
    }
}

export const addOrUpdateRouteAction = (loginUserId: number, routeToAddOrUpdate: IRoute) => {
    return (dispatch, getState) => {
        dispatch(setIsSavingNewRouteAction(true));
        routeToAddOrUpdate.IsNew = 1;
        routeApi.saveNewRoute(loginUserId, routeToAddOrUpdate)
            .then(res => {
                updateOrderBookerRouteInfoState(dispatch, getState, routeToAddOrUpdate.AreaId, routeToAddOrUpdate.Id,
                    routeToAddOrUpdate.OrderBookerToAssignRoute ?? 0, routeToAddOrUpdate.RouteDayToAssign ?? 0,res);
                updateRoutePoliesState(dispatch,getState, routeToAddOrUpdate.AreaId, res.RouteId, routeToAddOrUpdate.RoutePoly);
                dispatch(setNewRouteSetupPageSuccessMessageAction('Saved Successfully'));
                dispatch(setIsSavingNewRouteAction(false));
                dispatch(setSelectedRouteIdOnMapAction({selectedRouteId:0}))
            })
            .catch(e => {
                dispatch(setNewRouteSetupPageErrorMessageAction('Failed To Save Route'));
                dispatch(setIsSavingNewRouteAction(false));
            })
    }
}

const updateRoutePoliesState = (dispatch:any, getState:any, areaId:number, routeId:number, updatedRoutePolies:IRoutePoly[]) =>{
    const state:IApplicationState = getState();
    let areaRoutePoliesCopy = getCopy(state.routePolies ?? []).find(m => m.areaId === areaId);

    if (!!areaRoutePoliesCopy && !!areaRoutePoliesCopy.routePolies) {
        const newState = [...areaRoutePoliesCopy.routePolies.filter(m => m.RouteId !== routeId), ...getCopy(updatedRoutePolies.map(a => {
            a.RouteId = routeId;
            return a;
        }))];
        dispatch(setRoutePoliesAction({ areaId: areaId, routePolies: getCopy(newState) }));
    }else{
        dispatch(setRoutePoliesAction({areaId:areaId, routePolies:[...getCopy(updatedRoutePolies.map(a => {
            a.RouteId = routeId;
            return a;
        }))]}))
    }
}

//pass order booker assign to id = 0 if you want to update existing assignment and route day = 0
const updateOrderBookerRouteInfoState = (dispatch: any, getState: any, areaId: number, routeId:number, assignToOrderBookerId:number, routeDayToAssign:number , routeToProcess: IRouteInfoNew) => {
    const state: IApplicationState = getState();
    const routeForProcess = !!routeDayToAssign && routeDayToAssign > 0 ? {...routeToProcess, RouteDay:routeDayToAssign, RouteDayName:getDayName(routeDayToAssign), EditMode:false, IsEditingAny:false} : {...routeToProcess, EditMode:false, IsEditingAny:false};
    if (!!state.orderBookerRouteInfo) {
        let areaOrderBookerRouteInfo = getCopy(state.orderBookerRouteInfo).find(m => m.areaId === areaId);

        if (!!areaOrderBookerRouteInfo && !!areaOrderBookerRouteInfo.orderBookerInfo && areaOrderBookerRouteInfo.orderBookerInfo.length > 0) {
            //each area must have all order bookers

            if (routeId <= 0) {
                ///its a new route created
                if ((assignToOrderBookerId > 0)
                    && (routeDayToAssign > 0)
                ) {
                    let orderBookerToAssignNewRoute = areaOrderBookerRouteInfo.orderBookerInfo.find(m => m.EmployeeId === assignToOrderBookerId);

                    if (!!orderBookerToAssignNewRoute.Routes && orderBookerToAssignNewRoute.Routes.length > 0) {
                        let orderBookerRouteDay = orderBookerToAssignNewRoute.Routes.find(m => m.RouteDay === routeDayToAssign);

                        if (!!orderBookerRouteDay) {
                            if (orderBookerRouteDay.RouteId > 0) {
                                //move existing route to un assign array and add new one
                                //do not update route count
                                let unAssignOrderBookerToMoveExistingRoute = areaOrderBookerRouteInfo.orderBookerInfo.find(o => o.EmployeeId === UnAssignedOrderBookerId);

                                if (!!unAssignOrderBookerToMoveExistingRoute) {
                                    unAssignOrderBookerToMoveExistingRoute.AttachedShops += orderBookerRouteDay.AttachedShops;
                                    unAssignOrderBookerToMoveExistingRoute.NeverAttachedShops += orderBookerRouteDay.NeverAttachedShops;
                                    unAssignOrderBookerToMoveExistingRoute.NumberOfRoutes += 1;
                                    unAssignOrderBookerToMoveExistingRoute.TotalShops = orderBookerRouteDay.TotalShops;
                                    unAssignOrderBookerToMoveExistingRoute.Routes = [...getCopy(unAssignOrderBookerToMoveExistingRoute.Routes), {...getCopy(orderBookerRouteDay), RouteDay:-1, RouteDayName:'Unassign'}];
                                } else {
                                    areaOrderBookerRouteInfo.orderBookerInfo.push(
                                        {
                                            AttachedShops: orderBookerRouteDay.AttachedShops,
                                            EmployeeId: UnAssignedOrderBookerId,
                                            EmployeeName: 'Unassigned',
                                            IsOrderBookerCollapsed: true,
                                            NeverAttachedShops: orderBookerRouteDay.NeverAttachedShops,
                                            NumberOfRoutes: 1,
                                            TotalShops: orderBookerRouteDay.TotalShops,
                                            Routes: [{...getCopy(orderBookerRouteDay), RouteDay:-1, RouteDayName:'Unassign'}]
                                        })
                                }

                                orderBookerToAssignNewRoute.AttachedShops -= orderBookerRouteDay.AttachedShops;
                                orderBookerToAssignNewRoute.NeverAttachedShops -= orderBookerRouteDay.NeverAttachedShops;
                                orderBookerToAssignNewRoute.TotalShops -= orderBookerRouteDay.TotalShops;

                                orderBookerToAssignNewRoute.Routes = [...getCopy(orderBookerToAssignNewRoute.Routes).filter(m => m.RouteId !== orderBookerRouteDay.RouteId), getCopy(routeForProcess)]

                            } else {
                                orderBookerToAssignNewRoute.AttachedShops += routeForProcess.AttachedShops;
                                orderBookerToAssignNewRoute.NeverAttachedShops += routeForProcess.NeverAttachedShops;
                                orderBookerToAssignNewRoute.NumberOfRoutes += 1;
                                orderBookerToAssignNewRoute.TotalShops += routeForProcess.TotalShops;
                                let routeDayToUpdate = orderBookerToAssignNewRoute.Routes.find(m => m.RouteDay === routeDayToAssign);
                                orderBookerToAssignNewRoute.Routes = [...getCopy(orderBookerToAssignNewRoute.Routes).filter(rd => rd.RouteDay !== routeDayToUpdate.RouteDay), getCopy(routeForProcess)];
                            }
                        } else {
                            orderBookerToAssignNewRoute.AttachedShops += routeForProcess.AttachedShops;
                            orderBookerToAssignNewRoute.NeverAttachedShops += routeForProcess.NeverAttachedShops;
                            orderBookerToAssignNewRoute.NumberOfRoutes += 1;
                            orderBookerToAssignNewRoute.TotalShops += routeForProcess.TotalShops;
                            orderBookerToAssignNewRoute.Routes = [...orderBookerToAssignNewRoute.Routes, getCopy(routeForProcess)];
                        }
                    } else {
                        orderBookerToAssignNewRoute.AttachedShops = routeForProcess.AttachedShops;
                        orderBookerToAssignNewRoute.NeverAttachedShops = routeForProcess.NeverAttachedShops;
                        orderBookerToAssignNewRoute.NumberOfRoutes = 1;
                        orderBookerToAssignNewRoute.TotalShops = routeForProcess.TotalShops;
                        orderBookerToAssignNewRoute.Routes = [getCopy(routeForProcess)];
                    }
                } else {
                    //add it to unassign array or add a new item

                    let unAssignOrderBookerNewRoute = areaOrderBookerRouteInfo.orderBookerInfo.find(m => m.EmployeeId === UnAssignedOrderBookerId);

                    if (!!unAssignOrderBookerNewRoute) {
                        unAssignOrderBookerNewRoute.AttachedShops += routeForProcess.AttachedShops;
                        unAssignOrderBookerNewRoute.NeverAttachedShops += routeForProcess.NeverAttachedShops;
                        unAssignOrderBookerNewRoute.NumberOfRoutes += 1;
                        unAssignOrderBookerNewRoute.TotalShops = routeForProcess.TotalShops;
                        unAssignOrderBookerNewRoute.Routes = [...getCopy(unAssignOrderBookerNewRoute.Routes), {...getCopy(routeForProcess),RouteDay:-1, RouteDayName:'Unassign'}];
                    } else {
                        areaOrderBookerRouteInfo.orderBookerInfo.push(
                            {
                                AttachedShops: routeForProcess.AttachedShops,
                                EmployeeId: UnAssignedOrderBookerId,
                                EmployeeName: 'Unassigned',
                                IsOrderBookerCollapsed: true,
                                NeverAttachedShops: routeForProcess.NeverAttachedShops,
                                NumberOfRoutes: 1,
                                TotalShops: routeForProcess.TotalShops,
                                Routes: [{...getCopy(routeForProcess), RouteDay:-1, RouteDayName:'Unassign'}]
                            })
                    }
                }
            } else {
                //route already exists check if assignment exists otherwide update the same man with the route numbersss

                if ((assignToOrderBookerId > 0)
                    && (routeDayToAssign > 0)) {
                    let orderBookerWithExistingAssignment = areaOrderBookerRouteInfo.orderBookerInfo.find(m => !!m.Routes && m.Routes.findIndex(t => t.RouteId === routeId) >= 0);
                    let routeExistingAssignment = orderBookerWithExistingAssignment.Routes.find(m => m.RouteId === routeId);
                    orderBookerWithExistingAssignment.AttachedShops -= routeExistingAssignment.AttachedShops;
                    orderBookerWithExistingAssignment.NeverAttachedShops -= routeExistingAssignment.NeverAttachedShops;
                    orderBookerWithExistingAssignment.NumberOfRoutes -= 1;
                    orderBookerWithExistingAssignment.TotalShops -= routeExistingAssignment.TotalShops;
                    orderBookerWithExistingAssignment.Routes =
                        orderBookerWithExistingAssignment.EmployeeId === UnAssignedOrderBookerId ? getCopy(orderBookerWithExistingAssignment.Routes).filter(r => r.RouteId !== routeExistingAssignment.RouteId)
                            : orderBookerWithExistingAssignment.Routes.map(a => {
                                if (a.RouteId === routeExistingAssignment.RouteId) {
                                    a.RouteId = 0;
                                    a.AttachedShops = 0;
                                    a.NeverAttachedShops = 0;
                                    a.PendingCredit = 0;
                                    a.RouteName = '';
                                    a.TotalShops = 0;
                                }

                                return a;
                            });

                    let orderBookerToAssignRoute = areaOrderBookerRouteInfo.orderBookerInfo.find(m => m.EmployeeId === assignToOrderBookerId);

                    if(orderBookerToAssignRoute.EmployeeId === UnAssignedOrderBookerId){
                        //add the new route to list
                        let unAssignOrderBooker = areaOrderBookerRouteInfo.orderBookerInfo.find(m => m.EmployeeId === UnAssignedOrderBookerId);

                            if(!!unAssignOrderBooker){
                                unAssignOrderBooker.AttachedShops += routeForProcess.AttachedShops;
                                unAssignOrderBooker.NeverAttachedShops += routeForProcess.NeverAttachedShops;
                                unAssignOrderBooker.NumberOfRoutes += 1;
                                unAssignOrderBooker.TotalShops += routeForProcess.TotalShops;
                                unAssignOrderBooker.Routes = [...getCopy(unAssignOrderBooker.Routes), {...getCopy(routeForProcess), RouteDay:-1, RouteDayName:'Unassign'}]
                            }else{
                                areaOrderBookerRouteInfo.orderBookerInfo.push(
                                    {
                                        AttachedShops: routeForProcess.AttachedShops,
                                        EmployeeId: UnAssignedOrderBookerId,
                                        EmployeeName: 'Unassigned',
                                        IsOrderBookerCollapsed: true,
                                        NeverAttachedShops: routeForProcess.NeverAttachedShops,
                                        NumberOfRoutes: 1,
                                        TotalShops: routeForProcess.TotalShops,
                                        Routes: [{...getCopy(routeForProcess), RouteDay:-1, RouteDayName:'Unassigned'}]
                                    })
                            }
                    }else{
                        let orderBookerRouteDay = orderBookerToAssignRoute.Routes.find(m => m.RouteDay === routeDayToAssign);

                        if(!!orderBookerRouteDay && orderBookerRouteDay.RouteId > 0){
                            let unAssignOrderBooker = areaOrderBookerRouteInfo.orderBookerInfo.find(m => m.EmployeeId === UnAssignedOrderBookerId);

                            if(!!unAssignOrderBooker){
                                unAssignOrderBooker.AttachedShops += orderBookerRouteDay.AttachedShops;
                                unAssignOrderBooker.NeverAttachedShops += orderBookerRouteDay.NeverAttachedShops;
                                unAssignOrderBooker.NumberOfRoutes += 1;
                                unAssignOrderBooker.TotalShops += orderBookerRouteDay.TotalShops;
                                unAssignOrderBooker.Routes = [...getCopy(unAssignOrderBooker.Routes), {...getCopy(orderBookerRouteDay), RouteDay:-1, RouteDayName:'Unassign'}]
                            }else{
                                areaOrderBookerRouteInfo.orderBookerInfo.push(
                                    {
                                        AttachedShops: orderBookerRouteDay.AttachedShops,
                                        EmployeeId: UnAssignedOrderBookerId,
                                        EmployeeName: 'Unassigned',
                                        IsOrderBookerCollapsed: true,
                                        NeverAttachedShops: orderBookerRouteDay.NeverAttachedShops,
                                        NumberOfRoutes: 1,
                                        TotalShops: orderBookerRouteDay.TotalShops,
                                        Routes: [{...getCopy(orderBookerRouteDay), RouteDay:-1, RouteDayName:'Unassigned'}]
                                    })
                            }

                            orderBookerToAssignRoute.AttachedShops -= orderBookerRouteDay.AttachedShops;
                            orderBookerToAssignRoute.NeverAttachedShops -= orderBookerRouteDay.NeverAttachedShops;
                            orderBookerToAssignRoute.TotalShops -= orderBookerRouteDay.TotalShops;

                            orderBookerToAssignRoute.AttachedShops += routeForProcess.AttachedShops;
                            orderBookerToAssignRoute.NeverAttachedShops += routeForProcess.NeverAttachedShops;
                            orderBookerToAssignRoute.TotalShops += routeForProcess.TotalShops;

                            orderBookerToAssignRoute.Routes = [...getCopy(orderBookerToAssignRoute.Routes).filter(m => m.RouteDay !== routeDayToAssign), getCopy(routeForProcess)];
                        }else{
                            orderBookerToAssignRoute.AttachedShops += routeForProcess.AttachedShops;
                            orderBookerToAssignRoute.NeverAttachedShops += routeForProcess.NeverAttachedShops;
                            orderBookerToAssignRoute.NumberOfRoutes += 1;
                            orderBookerToAssignRoute.TotalShops += routeForProcess.TotalShops;
                            orderBookerToAssignRoute.Routes = [...getCopy(orderBookerToAssignRoute.Routes).filter(m => m.RouteDay !== routeDayToAssign), getCopy(routeForProcess)];
                        }
                    }

                } else {
                    if(assignToOrderBookerId === UnAssignedOrderBookerId){
                        let orderBookerWithExistingAssignment = areaOrderBookerRouteInfo.orderBookerInfo.find(m => !!m.Routes && m.Routes.findIndex(t => t.RouteId === routeId) >= 0);
                        let routeExistingAssignment = orderBookerWithExistingAssignment.Routes.find(m => m.RouteId === routeId);
                        orderBookerWithExistingAssignment.AttachedShops -= routeExistingAssignment.AttachedShops;
                        orderBookerWithExistingAssignment.NeverAttachedShops -= routeExistingAssignment.NeverAttachedShops;
                        orderBookerWithExistingAssignment.TotalShops -= routeExistingAssignment.TotalShops;
                        orderBookerWithExistingAssignment.NumberOfRoutes -= 1;
                        orderBookerWithExistingAssignment.Routes = orderBookerWithExistingAssignment.EmployeeId === UnAssignedOrderBookerId ?
                        [...getCopy(orderBookerWithExistingAssignment.Routes).filter(m => m.RouteId !== routeId)]
                        : orderBookerWithExistingAssignment.Routes.map(a => {
                            if (a.RouteId === routeExistingAssignment.RouteId) {
                                a.AttachedShops = 0;
                                a.NeverAttachedShops = 0;
                                a.PendingCredit = 0;
                                a.RouteName = '';
                                a.TotalShops = 0;
                                a.RouteId = 0;
                            }
    
                            return a;
                        });
    
                        let unAssignOrderBooker = areaOrderBookerRouteInfo.orderBookerInfo.find(m => m.EmployeeId === UnAssignedOrderBookerId);

                        if(!!unAssignOrderBooker){
                            unAssignOrderBooker.AttachedShops += routeForProcess.AttachedShops;
                            unAssignOrderBooker.NeverAttachedShops += routeForProcess.NeverAttachedShops;
                            unAssignOrderBooker.NumberOfRoutes += 1;
                            unAssignOrderBooker.TotalShops += routeForProcess.TotalShops;
                            unAssignOrderBooker.Routes = [...getCopy(unAssignOrderBooker.Routes), {...getCopy(routeForProcess), RouteDay:-1, RouteDayName:'Unassign'}];
                        }else{
                            areaOrderBookerRouteInfo.orderBookerInfo.push({
                                AttachedShops:routeForProcess.AttachedShops,
                                EmployeeId:UnAssignedOrderBookerId,
                                EmployeeName:'Unassign',
                                IsOrderBookerCollapsed:true,
                                NeverAttachedShops:routeForProcess.NeverAttachedShops,
                                NumberOfRoutes:1,
                                TotalShops:routeForProcess.TotalShops,
                                Routes:[{...getCopy(routeForProcess), RouteDay:-1, RouteDayName:'Unassign'}]
                            });
                        }

                    }else{
                        //refresh existing assignment
                        let orderBookerWithExistingAssignment = areaOrderBookerRouteInfo.orderBookerInfo.find(m => !!m.Routes && m.Routes.findIndex(t => t.RouteId === routeId) >= 0);
                        let routeExistingAssignment = orderBookerWithExistingAssignment.Routes.find(m => m.RouteId === routeId);
                        orderBookerWithExistingAssignment.AttachedShops -= routeExistingAssignment.AttachedShops;
                        orderBookerWithExistingAssignment.NeverAttachedShops -= routeExistingAssignment.NeverAttachedShops;
                        orderBookerWithExistingAssignment.TotalShops -= routeExistingAssignment.TotalShops;
                        orderBookerWithExistingAssignment.Routes = orderBookerWithExistingAssignment.Routes.map(a => {
                            if (a.RouteId === routeExistingAssignment.RouteId) {
                                a.AttachedShops = routeForProcess.AttachedShops;
                                a.NeverAttachedShops = routeForProcess.NeverAttachedShops;
                                a.PendingCredit = routeForProcess.PendingCredit;
                                a.RouteName = routeForProcess.RouteName;
                                a.TotalShops = routeForProcess.TotalShops;
                            }
    
                            return a;
                        });
    
                        orderBookerWithExistingAssignment.AttachedShops += routeForProcess.AttachedShops;
                        orderBookerWithExistingAssignment.NeverAttachedShops += routeForProcess.NeverAttachedShops;
                        orderBookerWithExistingAssignment.TotalShops += routeForProcess.TotalShops;
                    }
                }


            }
        } else {
            //add unassign order booker with the route
            areaOrderBookerRouteInfo = {
                areaId:areaId,
                orderBookerInfo:[{
                    AttachedShops: routeForProcess.AttachedShops,
                    EmployeeId: UnAssignedOrderBookerId,
                    EmployeeName: 'Unassigned',
                    IsOrderBookerCollapsed: true,
                    NeverAttachedShops: routeForProcess.NeverAttachedShops,
                    NumberOfRoutes: 1,
                    TotalShops: routeForProcess.TotalShops,
                    Routes: [{...getCopy(routeForProcess), RouteDay:-1, RouteDayName:'Unassigned'}]
                }]
            };
        }

        let copyToSort = getCopy(areaOrderBookerRouteInfo);

        if(!!copyToSort && !!copyToSort.orderBookerInfo){
            copyToSort.orderBookerInfo = copyToSort.orderBookerInfo.map(ob => {
                if(ob.EmployeeId !== UnAssignedOrderBookerId){
                    let routesCopy = getCopy(ob.Routes);
                    let saturdayRoute = routesCopy.find(r => r.RouteDay === 7);
                    let otherRoutes = routesCopy.filter(m => m.RouteDay !== 7);
                    let sortedRoutes = otherRoutes.sort((a,b) => a.RouteDay - b.RouteDay);
                    ob.Routes = [{...saturdayRoute, IsEditingAny:false, EditMode:false}, ...sortedRoutes.map(a => {
                        a.IsEditingAny = false;
                        a.EditMode = false;
                        return a;
                    })];
                }else{
                    ob.Routes = ob.Routes.map(a => {
                        a.IsEditingAny = false;
                        a.EditMode = false;
                        return a;
                    });
                }
                
                return ob;
            });
        }

        dispatch(setAreaWiseOrderBookerInfoAction(getCopy(copyToSort)));
    }
}

export const deleteRouteAction = (areaId: number, routeId: number) => {
    return (dispatch, getState) => {
        dispatch(setIsDeletingRouteAction(true));
        routeApi.deactivateRoute(routeId)
            .then(m => {
                dispatch(setNewRouteSetupPageSuccessMessageAction('Deleted Successfully'));
                updateLocalStateOnRouteDelete(dispatch, getState, areaId, routeId);
                dispatch(setIsDeletingRouteAction(false));
            })
            .catch(e => {
                dispatch(setIsDeletingRouteAction(false));
                dispatch(setNewRouteSetupPageErrorMessageAction('Unable to delete route. Please check with system admin'));
            });
    }
}

const updateLocalStateOnRouteDelete = (dispatch: any, getState: any, areaId: number, routeId: number) => {
    const state: IApplicationState = getState();

    if (!!state.orderBookerRouteInfo) {
        const existingInfo = getCopy(state.orderBookerRouteInfo);
        if (existingInfo.findIndex(m => m.areaId === areaId) >= 0) {
            const toModify = existingInfo.find(t => t.areaId === areaId);
            if (!!toModify && !!toModify.orderBookerInfo) {
                const newState = toModify.orderBookerInfo.map(a => {
                    if (!!a && !!a.Routes) {
                        a.Routes = getCopy(a.Routes).filter(t => t.RouteId !== routeId);
                    }

                    return a;
                })

                dispatch(setAreaWiseOrderBookerInfoAction({ areaId: areaId, orderBookerInfo: newState }));
            }
        }
    }

    if (!!state.routePolies) {
        const existingPolies = getCopy(state.routePolies);
        if (existingPolies.findIndex(m => m.areaId === areaId) >= 0) {
            const toModify = existingPolies.find(m => m.areaId === areaId);

            if (!!toModify && !!toModify.routePolies) {
                const newPolies = getCopy(toModify).routePolies.filter(t => t.RouteId !== routeId);

                dispatch(setRoutePoliesAction({ areaId: areaId, routePolies: newPolies }));
            }
        }
    }

    dispatch(setSelectedRouteIdOnMapAction({ selectedRouteId: 0 }));
}

// const updateStateOnAddOrUpdateRoute = (dispatch: any, getState: any, savedRoute: IRoute, saveResponse: IRouteInfoNew) => {
//     const state: IApplicationState = getState();

//     if (!!state.orderBookerRouteInfo) {
//         const existingInfo = getCopy(state.orderBookerRouteInfo);
//         if (existingInfo.findIndex(m => m.areaId === savedRoute.AreaId) >= 0) {
//             const toModify = existingInfo.find(t => t.areaId === savedRoute.AreaId);
//             if (!!toModify && !!toModify.orderBookerInfo) {
//                 if (savedRoute.Id <= 0) {
//                     let newState = setRouteAssignment(toModify.orderBookerInfo, 0,
//                         ((savedRoute.OrderBookerToAssignRoute ?? UnAssignedOrderBookerId) === UnAssignedOrderBookerId || (savedRoute.OrderBookerToAssignRoute ?? 0) > 0) ? (savedRoute.OrderBookerToAssignRoute ?? 0) : 0
//                         , savedRoute.RouteDayToAssign, saveResponse);
//                     dispatch(setAreaWiseOrderBookerInfoAction({ areaId: savedRoute.AreaId, orderBookerInfo: newState }));
//                 } else {
//                     let newState = setRouteAssignment(toModify.orderBookerInfo, savedRoute.EmployeeId,
//                         ((savedRoute.OrderBookerToAssignRoute ?? 0) === UnAssignedOrderBookerId || (savedRoute.OrderBookerToAssignRoute ?? 0) > 0) ? (savedRoute.OrderBookerToAssignRoute ?? 0) : savedRoute.EmployeeId
//                         , savedRoute.RouteDayToAssign, saveResponse);
//                     dispatch(setAreaWiseOrderBookerInfoAction({ areaId: savedRoute.AreaId, orderBookerInfo: newState }));
//                 }
//             } else {
//                 /// dispatch new state
//                 dispatch(setAreaWiseOrderBookerInfoAction({
//                     areaId: savedRoute.AreaId, orderBookerInfo: [{
//                         AttachedShops: saveResponse.AttachedShops,
//                         EmployeeId: UnAssignedOrderBookerId,
//                         EmployeeName: 'Unassigned',
//                         IsOrderBookerCollapsed: true,
//                         NeverAttachedShops: saveResponse.NeverAttachedShops,
//                         NumberOfRoutes: 1,
//                         Routes: [getCopy(saveResponse)],
//                         TotalShops: saveResponse.TotalShops
//                     }]
//                 }));
//             }
//         } else {
//             /// dispatch new state
//             dispatch(setAreaWiseOrderBookerInfoAction({
//                 areaId: savedRoute.AreaId, orderBookerInfo: [{
//                     AttachedShops: saveResponse.AttachedShops,
//                     EmployeeId: UnAssignedOrderBookerId,
//                     EmployeeName: 'Unassigned',
//                     IsOrderBookerCollapsed: true,
//                     NeverAttachedShops: saveResponse.NeverAttachedShops,
//                     NumberOfRoutes: 1,
//                     Routes: [getCopy(saveResponse)],
//                     TotalShops: saveResponse.TotalShops
//                 }]
//             }));
//         }
//     } else {
//         /// dispatch new state
//         dispatch(setAreaWiseOrderBookerInfoAction({
//             areaId: savedRoute.AreaId, orderBookerInfo: [{
//                 AttachedShops: saveResponse.AttachedShops,
//                 EmployeeId: UnAssignedOrderBookerId,
//                 EmployeeName: 'Unassigned',
//                 IsOrderBookerCollapsed: true,
//                 NeverAttachedShops: saveResponse.NeverAttachedShops,
//                 NumberOfRoutes: 1,
//                 Routes: [getCopy(saveResponse)],
//                 TotalShops: saveResponse.TotalShops
//             }]
//         }));
//     }

//     let areaRoutePoliesCopy = getCopy(state.routePolies ?? []).find(m => m.areaId === savedRoute.AreaId);

//     if (!!areaRoutePoliesCopy && !!areaRoutePoliesCopy.routePolies) {
//         const filtered = [...areaRoutePoliesCopy.routePolies.filter(m => m.RouteId !== savedRoute.Id), ...getCopy(savedRoute.RoutePoly)];
//         dispatch(setRoutePoliesAction({ areaId: savedRoute.AreaId, routePolies: getCopy(filtered) }));
//     }

//     dispatch(setSelectedRouteIdOnMapAction({ selectedRouteId: 0 }));
// }


// const setRouteAssignment = (toModify: IOrderBookerRouteInfoNew[], fromOrderBookerId: number, toOrderBookerId: number, toRouteDay: number, routeForProcess: IRouteInfoNew) => {
//     if (!!toModify && toModify.length > 0) {
//         let copy = getCopy(toModify);
//         let fromOrderBookerRecord = copy.find(m => m.EmployeeId == fromOrderBookerId);
//         let toOrderBookerRecord = copy.find(m => m.EmployeeId == toOrderBookerId);
//         let unAssignedOrderBookerRecord = copy.find(m => m.EmployeeId == UnAssignedOrderBookerId);

//         if (fromOrderBookerId == UnAssignedOrderBookerId) {
//             if (!!fromOrderBookerRecord && !!fromOrderBookerRecord.Routes) {
//                 let fromOrderBookerRoute = fromOrderBookerRecord.Routes.find(m => m.RouteId == routeForProcess.RouteId);
//                 fromOrderBookerRecord.AttachedShops -= (fromOrderBookerRoute?.AttachedShops ?? 0);
//                 fromOrderBookerRecord.NeverAttachedShops -= (fromOrderBookerRoute?.NeverAttachedShops ?? 0);
//                 fromOrderBookerRecord.NumberOfRoutes -= 1;
//                 fromOrderBookerRecord.TotalShops -= (fromOrderBookerRoute?.TotalShops ?? 0);

//                 fromOrderBookerRecord.Routes = fromOrderBookerRecord.Routes.filter(m => m.RouteId != routeForProcess.RouteId);
//             }
//         } else {
//             if (!!fromOrderBookerRecord && !!fromOrderBookerRecord.Routes) {
//                 let fromOrderBookerRoute = fromOrderBookerRecord.Routes.find(ms => ms.RouteId === routeForProcess.RouteId);
//                 console.log('FromOrderBookerRoute:', fromOrderBookerRoute)
//                 fromOrderBookerRecord.AttachedShops -= (fromOrderBookerRoute?.AttachedShops ?? 0);
//                 fromOrderBookerRecord.NeverAttachedShops -= (fromOrderBookerRoute?.NeverAttachedShops ?? 0);
//                 fromOrderBookerRecord.NumberOfRoutes -= 1;
//                 fromOrderBookerRecord.TotalShops -= (fromOrderBookerRoute?.TotalShops ?? 0);
//                 fromOrderBookerRoute.AttachedShops = 0;
//                 fromOrderBookerRoute.NeverAttachedShops = 0;
//                 fromOrderBookerRoute.PendingCredit = 0;
//                 fromOrderBookerRoute.RouteId = 0;
//                 fromOrderBookerRoute.RouteName = '';
//                 fromOrderBookerRoute.TotalShops = 0;
//             }
//         }

//         if (toOrderBookerId == UnAssignedOrderBookerId) {
//             if (!!toOrderBookerRecord) {
//                 toOrderBookerRecord.NumberOfRoutes += 1;
//                 toOrderBookerRecord.AttachedShops += (routeForProcess?.AttachedShops ?? 0);
//                 toOrderBookerRecord.NeverAttachedShops += (routeForProcess?.NeverAttachedShops ?? 0);
//                 toOrderBookerRecord.TotalShops += (routeForProcess?.TotalShops ?? 0);
//                 toOrderBookerRecord.Routes = [...toOrderBookerRecord.Routes, { ...routeForProcess }];
//             }
//         } else {
//             if (!!toOrderBookerRecord && !!toOrderBookerRecord.Routes) {
//                 let toOrderBookerRoute = toOrderBookerRecord.Routes.find(m => m.RouteDay == toRouteDay);
//                 toOrderBookerRecord.NumberOfRoutes += ((toOrderBookerRoute?.RouteId ?? 0) > 0 ? 0 : 1);
//                 toOrderBookerRecord.AttachedShops -= (toOrderBookerRoute?.AttachedShops ?? 0);
//                 toOrderBookerRecord.NeverAttachedShops -= (toOrderBookerRoute?.NeverAttachedShops ?? 0);
//                 toOrderBookerRecord.TotalShops -= (toOrderBookerRoute?.TotalShops ?? 0);

//                 if (!!toOrderBookerRoute && toOrderBookerRoute.RouteId > 0) {
//                     unAssignedOrderBookerRecord.NumberOfRoutes += 1;
//                     unAssignedOrderBookerRecord.AttachedShops += (toOrderBookerRoute?.AttachedShops ?? 0);
//                     unAssignedOrderBookerRecord.NeverAttachedShops += (toOrderBookerRoute?.NeverAttachedShops ?? 0);
//                     unAssignedOrderBookerRecord.TotalShops += (toOrderBookerRoute?.TotalShops ?? 0);
//                     unAssignedOrderBookerRecord.Routes = [...unAssignedOrderBookerRecord.Routes, { ...getCopy(toOrderBookerRoute) }]
//                 }

//                 toOrderBookerRoute.AttachedShops = (routeForProcess?.AttachedShops ?? 0);
//                 toOrderBookerRoute.NeverAttachedShops = (routeForProcess?.NeverAttachedShops ?? 0);
//                 toOrderBookerRoute.PendingCredit = (routeForProcess?.PendingCredit ?? 0);
//                 toOrderBookerRoute.RouteId = routeForProcess.RouteId;
//                 toOrderBookerRoute.RouteName = routeForProcess.RouteName;
//                 toOrderBookerRoute.TotalShops = (routeForProcess?.TotalShops ?? 0);

//                 toOrderBookerRecord.AttachedShops += (toOrderBookerRoute?.AttachedShops ?? 0);
//                 toOrderBookerRecord.NeverAttachedShops += (toOrderBookerRoute?.NeverAttachedShops ?? 0);
//                 toOrderBookerRecord.TotalShops += (toOrderBookerRoute?.TotalShops ?? 0);
//             }
//         }

//         const newState = copy.map(ob => {
//             ob.Routes = !!ob.Routes && ob.Routes.length > 0 ? ob.Routes.map(b => {
//                 b.EditMode = false;
//                 b.IsEditingAny = false;
//                 return b;
//             }) : [];
//             return ob;
//         });

//         console.log('FromOrderBookerRoutes:', getCopy(newState).filter(m => m.EmployeeId === fromOrderBookerId));
//         console.log('ToOrderBookerRoutes:', getCopy(newState).filter(m => m.EmployeeId === toOrderBookerId));

//         return newState;
//     }

//     return toModify;
// }

const loadData = (areaId: number, dispatch: any) => {
    routeApi.getOrderBookerWithRoutesAsync(areaId)
        .then((data: IOrderBookerRouteInfoNew[]) => {
            dispatch(setAreaWiseOrderBookerInfoAction({ areaId: areaId, orderBookerInfo: (data ?? []) }));
            dispatch(setIsLoadingOrderBookerInfo(false));
        })
        .catch(e => {
            dispatch(setIsLoadingOrderBookerInfo(false));
            dispatch(setAreaWiseOrderBookerInfoAction({ areaId: areaId, orderBookerInfo: [] }));
        });
}
