import React from 'react';
import { Container, Grid } from '@mui/material';
import CustomDatePicker from '../common/CustomDatePicker';
import { IApplicationState } from 'src/redux';
import { useSelector } from 'react-redux';
import { isRestrictedLogin, NumberOfPreviousMonth } from 'src/app.constant';

interface IProps {
    fromDate: Date;
    toDate: Date;
    setFromDate: (dated: Date) => void;
    setToDate: (dated: Date) => void;
    isLoading: boolean;
    onLoad: (showMessages: boolean) => void;
}

export const OrdersParamComponent = (props: IProps) => {

    const pageKey = 'OrdersParamCompnentKey';

    const userId = useSelector((st: IApplicationState) => st.loginUser.UserId);
    const tDated = new Date();

    return <Container key={`${pageKey}_1`} style={{ paddingTop: 20, paddingBottom: 10, paddingLeft: 0, paddingRight: 0 }}>
        <Grid key={`${pageKey}_2`} container spacing={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Grid key={`${pageKey}_7`} item md={6} sm={12}>
                <CustomDatePicker
                    label='From Date'
                    value={props.fromDate}
                    readonly={props.isLoading}
                    onChange={(dated: Date) => {
                        props.setFromDate(dated);
                    }}
                    key={`${pageKey}_9`}
                    minDate={isRestrictedLogin(userId) ? (new Date(tDated.getFullYear(), tDated.getMonth() - NumberOfPreviousMonth, 1)) : undefined}
                    inputDisable={true}
                />
            </Grid>

            <Grid key={`${pageKey}_8`} item md={6} sm={12}>
                <CustomDatePicker
                    label='To Date'
                    value={props.toDate}
                    readonly={props.isLoading}
                    onChange={(dated: Date) => {
                        props.setToDate(dated);
                    }}
                    key={`${pageKey}_10`}

                    minDate={isRestrictedLogin(userId) ? (new Date(tDated.getFullYear(), tDated.getMonth() - NumberOfPreviousMonth, 1)) : undefined}
                    inputDisable={true}
                />
            </Grid>
        </Grid>
    </Container>;
}