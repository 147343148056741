import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IApplicationState } from 'src/redux';
import { useSelector } from 'react-redux';
import { Checkbox, CircularProgress, Container, FormControlLabel, Grid, Table, TableBody, TableCell, TableContainer, TableRow, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { IAutoComplete } from 'src/interfaces/auto-complete.interface';
import { CustomAutoComplete } from './common/CustomAutoComplete';
import { employeeApi, masterDataApi, routeApi } from 'src/http';
import { shopApi } from 'src/http/shop.http';
import { IRouteShopDetail } from 'src/interfaces/route-shop-detail.interface';
import { IShopUpdateRequest } from 'src/interfaces/shop-update-request.interface';
import { formatedDateTime, getCopy, isValidCellNumber, timeAgo } from 'src/utility';
import { IMessage } from 'src/interfaces/message.interface';
import { MessageComponent } from './messages.component';
import { IShopRequest } from 'src/interfaces/shop-request.interface';
import { UserListHead } from './_dashboard/user';
import { Icon } from '@iconify/react';
import { Highlighted } from './common/highlighted.component';
import { fCommaSeparated } from 'src/utils/formatNumber';
import { confirmAction } from 'src/utility/confirm.util';
import Highlighter from "react-highlight-words";
import { isRestrictedLogin } from 'src/app.constant';

const SHOP_TABLE_HEAD = [
    { id: '', label: '', alignContent: 'center', enableSort: false },
    { id: 'ShopName', label: 'Name', alignContent: 'left', enableSort: true },
    { id: 'ShopType', label: 'Shop Type', alignContent: 'left', enableSort: true },
    { id: 'ContactName', label: 'Contact Name', alignContent: 'left', enableSort: true },
    { id: 'CellNumber', label: 'Cell#', alignContent: 'left', enableSort: true },
    { id: 'StreetAddress', label: 'Address', alignContent: 'left', enableSort: true },
    { id: 'Distance', label: 'Distance', alignContent: 'left', enableSort: true },
];

interface IProps {
    passedRequest: IShopRequest;
    loginUserId: number;
    onClose: () => void;
    onSuccess: (requestUniqueId: string) => void;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    height: '98%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    paddingBottom: 2
};

// const shopInfoDummy: IRouteShopDetail[] = [
//     { ShopId: 1010, ShopName: 'Ali K/S', IsCovered: true, LastOrderedOn: new Date(2023, 1, 1, 1, 1, 1, 1), LastVisitedOn: new Date(), Route: 'Dera Bakha', ShopAddress: 'Main Ada Dera Bakha Street Number 1', IsCollapsed: true, PendingCredit: 0, TotalSale: 10000, RouteId: 1, OrderBookerId: 2, OrderBookerName: 'Muhammad Zubair', RouteDayNumber: 1, RouteDay: 'Sunday', UnitId: 1, UnitUniqueId: '889CB2B2-9F99-4013-AA65-FADE87A0B851', UnitTitle: 'Bahawalpur', CellOne: '03008833038', CellTwo: '03312233038', CreditStatusId: 1, CreditStatus: 'Active', CreditabilityId: 1, Creditability: 'Excellent', ShopTypeId: 1, ShopType: 'K/S', AddedOn: new Date(2023, 1, 1), OrderOnCashAmount: 10000, OrderOnCreditAmount: 20000, TotalRecovery: 40000, Distance: 1600, ImageUrl: 'https://www.blogtyrant.com/wp-content/uploads/2019/07/how-to-start-an-online-store-min.png', IsRouteShop: true, IsOtherShop: false, IsWholesale: true, IsInOtherRoute: false, RecordId: '667b65ac-1960-452e-8fee-f60adcc35d27', ContactName: 'Haji Rasheed' },
//     { ShopId: 2020, ShopName: 'Ahmad K/S', IsCovered: false, LastOrderedOn: new Date(2023, 1, 1, 1, 1, 10, 1), LastVisitedOn: new Date(), Route: 'Islami Colony', ShopAddress: 'Kharak Chowk', IsCollapsed: true, PendingCredit: 3000, TotalSale: 0, RouteId: 1, OrderBookerId: 2, OrderBookerName: 'Muhammad Zubair', RouteDayNumber: 1, RouteDay: 'Sunday', UnitId: 1, UnitUniqueId: '889CB2B2-9F99-4013-AA65-FADE87A0B851', UnitTitle: 'Bahawalpur', CellOne: '03008833038', CellTwo: '03312233038', CreditStatusId: 1, CreditStatus: 'Active', CreditabilityId: 1, Creditability: 'Excellent', ShopTypeId: 1, ShopType: 'K/S', AddedOn: new Date(2023, 1, 1), OrderOnCashAmount: 10000, OrderOnCreditAmount: 20000, TotalRecovery: 40000, Distance: 15, ImageUrl: 'https://www.blogtyrant.com/wp-content/uploads/2019/07/how-to-start-an-online-store-min.png', IsRouteShop: false, IsOtherShop: true, IsWholesale: true, IsInOtherRoute: false, RecordId: 'd289174e-701d-4431-ab94-e129a6fd993c', ContactName: 'Haji Rasheed' },
//     { ShopId: 3030, ShopName: 'Raza K/S', IsCovered: false, LastOrderedOn: new Date(2023, 1, 1, 1, 10, 1, 1), LastVisitedOn: new Date(2023, 1, 1, 1, 10, 1, 1), Route: '13 Solang', ShopAddress: 'Chandni Chowk', IsCollapsed: true, PendingCredit: 0, TotalSale: 30000, RouteId: 1, OrderBookerId: 2, OrderBookerName: 'Muhammad Zubair', RouteDayNumber: 1, RouteDay: 'Sunday', UnitId: 1, UnitUniqueId: '889CB2B2-9F99-4013-AA65-FADE87A0B851', UnitTitle: 'Bahawalpur', CellOne: '03008833038', CellTwo: '03312233038', CreditStatusId: 1, CreditStatus: 'Active', CreditabilityId: 1, Creditability: 'Excellent', ShopTypeId: 1, ShopType: 'K/S', AddedOn: new Date(2023, 1, 1), OrderOnCashAmount: 10000, OrderOnCreditAmount: 20000, TotalRecovery: 40000, Distance: 14, ImageUrl: 'https://www.blogtyrant.com/wp-content/uploads/2019/07/how-to-start-an-online-store-min.png', IsRouteShop: false, IsOtherShop: false, IsWholesale: true, IsInOtherRoute: true, RecordId: '3a95e67f-aa37-4bf5-9a9a-576f66995bb8', ContactName: 'Haji Rasheed' },
//     { ShopId: 4050, ShopName: 'Dilshad K/S', IsCovered: true, LastOrderedOn: new Date(2023, 1, 1, 10, 1, 1, 1), LastVisitedOn: new Date(2023, 1, 1, 10, 1, 1, 1), Route: 'Yazman', ShopAddress: 'Yazman main chowk', IsCollapsed: true, PendingCredit: 5000, TotalSale: 40000, RouteId: 1, OrderBookerId: 2, OrderBookerName: 'Muhammad Zubair', RouteDayNumber: 1, RouteDay: 'Sunday', UnitId: 1, UnitUniqueId: '889CB2B2-9F99-4013-AA65-FADE87A0B851', UnitTitle: 'Bahawalpur', CellOne: '03008833038', CellTwo: '03312233038', CreditStatusId: 1, CreditStatus: 'Active', CreditabilityId: 1, Creditability: 'Excellent', ShopTypeId: 1, ShopType: 'K/S', AddedOn: new Date(2023, 1, 1), OrderOnCashAmount: 10000, OrderOnCreditAmount: 20000, TotalRecovery: 40000, Distance: 13, ImageUrl: 'https://www.blogtyrant.com/wp-content/uploads/2019/07/how-to-start-an-online-store-min.png', IsRouteShop: false, IsOtherShop: false, IsWholesale: true, IsInOtherRoute: true, RecordId: 'efda121f-b106-4b4d-b1cb-366926621200', ContactName: 'Haji Rasheed' },
//     { ShopId: 5040, ShopName: 'Zainab K/S', IsCovered: true, LastOrderedOn: new Date(2023, 1, 2, 1, 1, 1, 1), LastVisitedOn: new Date(2023, 1, 2, 1, 1, 1, 1), Route: 'Lodhran', ShopAddress: 'Main City Lodhran', IsCollapsed: true, PendingCredit: 6000, TotalSale: 50000, RouteId: 1, OrderBookerId: 2, OrderBookerName: 'Muhammad Zubair', RouteDayNumber: 1, RouteDay: 'Sunday', UnitId: 1, UnitUniqueId: '889CB2B2-9F99-4013-AA65-FADE87A0B851', UnitTitle: 'Bahawalpur', CellOne: '03008833038', CellTwo: '03312233038', CreditStatusId: 1, CreditStatus: 'Active', CreditabilityId: 1, Creditability: 'Excellent', ShopTypeId: 1, ShopType: 'K/S', AddedOn: new Date(2023, 1, 1), OrderOnCashAmount: 10000, OrderOnCreditAmount: 20000, TotalRecovery: 40000, Distance: 12, ImageUrl: 'https://www.blogtyrant.com/wp-content/uploads/2019/07/how-to-start-an-online-store-min.png', IsRouteShop: false, IsOtherShop: true, IsWholesale: true, IsInOtherRoute: false, RecordId: '34579441-6949-4a9f-b1d8-484ff34b9cc9', ContactName: 'Haji Rasheed' },
//     { ShopId: 66070, ShopName: 'Rabia K/S', IsCovered: false, LastOrderedOn: new Date(2023, 2, 2, 1, 1, 1, 1), LastVisitedOn: new Date(), Route: 'Khanqah Sharif', ShopAddress: 'City Khanqah Sharif', IsCollapsed: true, PendingCredit: 7000, TotalSale: 60000, RouteId: 1, OrderBookerId: 2, OrderBookerName: 'Muhammad Zubair', RouteDayNumber: 1, RouteDay: 'Sunday', UnitId: 1, UnitUniqueId: '889CB2B2-9F99-4013-AA65-FADE87A0B851', UnitTitle: 'Bahawalpur', CellOne: '03008833038', CellTwo: '03312233038', CreditStatusId: 1, CreditStatus: 'Active', CreditabilityId: 1, Creditability: 'Excellent', ShopTypeId: 1, ShopType: 'K/S', AddedOn: new Date(2023, 1, 1), OrderOnCashAmount: 10000, OrderOnCreditAmount: 20000, TotalRecovery: 40000, Distance: 10, ImageUrl: 'https://www.blogtyrant.com/wp-content/uploads/2019/07/how-to-start-an-online-store-min.png', IsRouteShop: true, IsOtherShop: false, IsWholesale: true, IsInOtherRoute: false, RecordId: '75b41033-07a9-42c0-81ed-35d3bf693971', ContactName: 'Haji Rasheed' },
//     { ShopId: 79850, ShopName: 'Labo K/S', IsCovered: true, LastOrderedOn: new Date(2022, 1, 2, 1, 1, 1, 1), LastVisitedOn: new Date(), Route: 'Ahmadpur', ShopAddress: 'Ahmad pur road', IsCollapsed: true, PendingCredit: 8000, TotalSale: 70000, RouteId: 1, OrderBookerId: 2, OrderBookerName: 'Muhammad Zubair', RouteDayNumber: 1, RouteDay: 'Sunday', UnitId: 1, UnitUniqueId: '889CB2B2-9F99-4013-AA65-FADE87A0B851', UnitTitle: 'Bahawalpur', CellOne: '03008833038', CellTwo: '03312233038', CreditStatusId: 1, CreditStatus: 'Active', CreditabilityId: 1, Creditability: 'Excellent', ShopTypeId: 1, ShopType: 'K/S', AddedOn: new Date(2023, 1, 1), OrderOnCashAmount: 10000, OrderOnCreditAmount: 20000, TotalRecovery: 40000, Distance: 20, ImageUrl: 'https://www.blogtyrant.com/wp-content/uploads/2019/07/how-to-start-an-online-store-min.png', IsRouteShop: true, IsOtherShop: false, IsWholesale: false, IsInOtherRoute: false, RecordId: '128db91f-92b3-4df2-bc1d-721bb8567d65', ContactName: 'Haji Rasheed' },
//     { ShopId: 8891, ShopName: 'Billa K/S', IsCovered: true, LastOrderedOn: new Date(2023, 1, 3, 1, 1, 1, 1), LastVisitedOn: new Date(), Route: 'Dhanot', ShopAddress: 'Street Number 1 Dhanot', IsCollapsed: true, PendingCredit: 9000, TotalSale: 0, RouteId: 1, OrderBookerId: 2, OrderBookerName: 'Muhammad Zubair', RouteDayNumber: 1, RouteDay: 'Sunday', UnitId: 1, UnitUniqueId: '889CB2B2-9F99-4013-AA65-FADE87A0B851', UnitTitle: 'Bahawalpur', CellOne: '03008833038', CellTwo: '03312233038', CreditStatusId: 1, CreditStatus: 'Active', CreditabilityId: 1, Creditability: 'Excellent', ShopTypeId: 1, ShopType: 'K/S', AddedOn: new Date(2023, 1, 1), OrderOnCashAmount: 10000, OrderOnCreditAmount: 20000, TotalRecovery: 40000, Distance: 10, ImageUrl: 'https://www.blogtyrant.com/wp-content/uploads/2019/07/how-to-start-an-online-store-min.png', IsRouteShop: false, IsOtherShop: true, IsWholesale: false, IsInOtherRoute: false, RecordId: 'cf936de7-a6ce-474e-8dc6-d9f19b41eff6', ContactName: 'Haji Rasheed' },
//     { ShopId: 42579, ShopName: 'Dabbu K/S', IsCovered: false, LastOrderedOn: new Date(2023, 1, 4, 1, 1, 1, 1), LastVisitedOn: new Date(), Route: 'Dhanot', ShopAddress: 'Sri Nagar Highway', IsCollapsed: true, PendingCredit: 10000, TotalSale: 0, RouteId: 1, OrderBookerId: 2, OrderBookerName: 'Muhammad Zubair', RouteDayNumber: 1, RouteDay: 'Sunday', UnitId: 1, UnitUniqueId: '889CB2B2-9F99-4013-AA65-FADE87A0B851', UnitTitle: 'Bahawalpur', CellOne: '03008833038', CellTwo: '03312233038', CreditStatusId: 1, CreditStatus: 'Active', CreditabilityId: 1, Creditability: 'Excellent', ShopTypeId: 1, ShopType: 'K/S', AddedOn: new Date(2023, 1, 1), OrderOnCashAmount: 10000, OrderOnCreditAmount: 20000, TotalRecovery: 40000, Distance: 10, ImageUrl: 'https://www.blogtyrant.com/wp-content/uploads/2019/07/how-to-start-an-online-store-min.png', IsRouteShop: true, IsOtherShop: false, IsWholesale: false, IsInOtherRoute: false, RecordId: 'b431f297-74df-49c9-add9-3cc76ad324a5', ContactName: 'Haji Rasheed' },

// ];

export const ApproveShopRequestPopup = (props: IProps) => {
    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick") {
            return;
        }

        props.onClose();
    }

    const loginUser = useSelector((state: IApplicationState) => state.loginUser);
    const [shopRequest, setShopRequest] = React.useState<IShopRequest>(props.passedRequest);
    const [searchText, setSearchText] = React.useState<string>('');
    const [searchTextArray, setSearchTextArray] = React.useState<string[]>([])
    const [pageMessages, setPageMessages] = React.useState<IMessage[]>([]);
    const [matchingShops, setMatchingShops] = React.useState<IRouteShopDetail[]>([]);
    const [isMatchingLoading, setIsMatchingLoading] = React.useState<boolean>(false);
    const [selectedShopId, setSelectedShopId] = React.useState<number>(0);
    const [isLocationTypeEnabled, setIsLocationTypeEnabled] = React.useState<boolean>(props.passedRequest.LocationAccuracy !== 0)

    const [isOrignalLocation, setIsOrignalLocation] = React.useState<boolean>(false);
    const [isFakeLocation, setIsFakeLocation] = React.useState<boolean>(props.passedRequest.LocationAccuracy === 0);
    const isRestrictedUser = React.useMemo(() => {
        let isRestricted = true;
        if(!!loginUser && !!loginUser.UserId && loginUser.UserId > 0){
            isRestricted = isRestrictedLogin(loginUser?.UserId ?? 0)
        }

        return isRestricted;
    },[loginUser]);
    const addMessage = (messageText: string, variant: 'success' | 'error' | 'info' | 'warning') => {
        if (!!messageText && messageText.trim() != '') {
            let msgs = [];
            msgs.push({
                messageText,
                variant
            });

            setPageMessages([...msgs]);
        }
    }

    const showErrorMessage = (message: string) => addMessage(message, 'error');
    const showInfoMessage = (message: string) => addMessage(message, 'info');
    const showSuccessMessage = (message: string) => addMessage(message, 'success');
    const showWarningMessage = (message: string) => addMessage(message, 'warning');

    const validate = () => {
        if(isFakeLocation === false && isOrignalLocation === false){
            showErrorMessage('Please Select Location Type at the bottom.');
            return false;
        }

        return true;
    }


    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const onSave = () => {
        if (validate()) {
            confirmAction('Shop Request Approval', `${!!selectedShopId && selectedShopId > 0 ? 'Are you sure you want to replace shop details?' : 'Are you sure you want to approve request?'}`,'Yes','No','green','red','green','green')
            .then(isOk => {
                if(isOk === true){
                    setIsLoading(true);
                    shopApi.processShopRequest(shopRequest.UniqueId, isFakeLocation, loginUser?.UserId ?? 0, selectedShopId)
                        .then(() => {
                            setIsLoading(false);
                            props.onSuccess(shopRequest.UniqueId);
                        })
                        .catch(() => {
                            showErrorMessage('Unable to process request. Please contact system admin.')
                            setIsLoading(false);
                        });
                }
            })
        }
    }

    const onShopRequestCollapse = (uniqueId: string) => {
        setPageMessages([]);
        let copy = getCopy(shopRequest)
        copy.IsCollapsed = !copy.IsCollapsed;
        setShopRequest(copy);
    }

    const onShopCollapseChange = (recordId: string) => {
        setPageMessages([]);
        let copy = getCopy(matchingShops);
        let shopinfoToModify = copy.find(m => m.RecordId === recordId);
        shopinfoToModify.IsCollapsed = !shopinfoToModify.IsCollapsed;
        setMatchingShops(copy);
    }

    const loadMatchings = () => {
        setIsMatchingLoading(true);
        const request = getCopy(props.passedRequest);
        shopApi.getMatchingShops(request.AreaId, request.Latitude, request.Longitude, request.ShopName, request.StreetAddress, request.ContactName, request.ContactNumberOne, request.ContactNumberTwo)
        .then(results => {
            setIsMatchingLoading(false);

            if(!!results && results.length > 0){
                setMatchingShops(getCopy(results));
            }else{
                setMatchingShops([]);
            }
        })
        .catch(e => {
            showErrorMessage('Something went wrong while loading matchings. Please try again or contact system admin.');
            setIsMatchingLoading(false);
            console.log(e);
        })
    }

    const onDeleteShopRequest = (requestUniqueId: string) => {
        confirmAction('Reject Shop Request', 'Are you sure, you want to reject shop request?', 'Yes', 'No','red', 'green','red','red')
        .then(isOk => {
            if(isOk === true){
                shopApi.deActivateShopRequest(props.loginUserId, requestUniqueId)
                .then(() => {
                    props.onSuccess(requestUniqueId);
                })
            }
        })
    }

    React.useEffect(() => {
        if (!!props.passedRequest) {
            loadMatchings();

            setSearchTextArray([
                ...getCopy(!!props.passedRequest.ShopName ? props.passedRequest.ShopName.trim().split(' ') : []),
                ...getCopy(!!props.passedRequest.StreetAddress ? props.passedRequest.StreetAddress.trim().split(' ') : []),
                ...getCopy(!!props.passedRequest.ContactName ? props.passedRequest.ContactName.trim().split(' ') : []),
                ...(!!props.passedRequest.ContactNumberOne ? [props.passedRequest.ContactNumberOne.trim()] : []),
                ...(!!props.passedRequest.ContactNumberTwo ? [props.passedRequest.ContactNumberTwo.trim()] : []),
                ...(!!props.passedRequest.ShopType ? [props.passedRequest.ShopType.trim()] : []),
            ])

        }
    }, [props.passedRequest]);

    return <Modal
        open={!!shopRequest && shopRequest.Id > 0}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        id="ApproveShopRequestModal"
    >
        <Box sx={style}>
            <Grid item md={12} style={{ justifyContent: 'center', marginTop: 2, marginBottom: 2, display: 'flex' }}>
                <Typography style={{ alignSelf: 'center', color:'darkgreen' }} id="modal-modal-title" variant="h6" component="h2">
                    Approve Shop Request
                </Typography>
            </Grid>



            <Container style={{ maxWidth: '100%' }}>
                {!!pageMessages && pageMessages.length > 0 && <Grid container spacing={2} style={{ marginTop: 5, maxWidth: '100%', width: '100%' }}>
                    <Grid item md={12} xs={12}>
                        <MessageComponent onRemove={(i: number) => {
                            let msgs = [...pageMessages];
                            msgs.splice(i, 1);
                            setPageMessages([...msgs]);
                        }} messages={pageMessages} /></Grid>
                </Grid>}
                <Grid container spacing={2} >
                    <Grid item md={12}>
                        <TableContainer sx={{ minWidth: 800, maxWidth: '100%' }}>
                            <Table sx={{ borderSpacing: '0px 3px' }} size="small" stickyHeader>
                                <UserListHead
                                    headLabel={SHOP_TABLE_HEAD}
                                    rowCount={0}
                                    numSelected={0}
                                    onSelectAllClick={undefined}
                                    customKey={`TableHeadRow_6_Shops_Request_Table`}
                                    typographyVariant='subtitle2'
                                    backgroundColor={'#4279a3'}
                                    textColor='white'
                                />
                                <TableBody>


                                    <React.Fragment key={`Shop_Item_${shopRequest.UniqueId}`}><TableRow
                                        hover
                                        key={shopRequest.UniqueId}
                                        tabIndex={-1}
                                        role="checkbox"
                                        selected={false}
                                        aria-checked={false}
                                        className={shopRequest.IsCollapsed === true ? 'shop' : 'shop-selected'}
                                        onClick={() => {
                                            if(isLoading === false){
                                                onShopRequestCollapse(shopRequest.UniqueId)
                                            }
                                        }}
                                    >
                                        <TableCell padding="none" width="1%" align="left" style={{ paddingLeft: 10 }}>
                                            {shopRequest.IsCollapsed === false && <Icon color='black' style={{ cursor: 'pointer' }} width={25} height={25} icon={'mdi:chevron-up'} />}
                                            {shopRequest.IsCollapsed === true && <Icon color='black' style={{ cursor: 'pointer' }} width={25} height={25} icon={'mdi:chevron-down'} />}
                                        </TableCell>
                                        <TableCell width={'15%'} align="left" style={shopRequest.IsCollapsed === false ? { color: 'blue', fontWeight: 'bold' } : {}} className='cell-text'>
                                            {shopRequest.ShopName}
                                        </TableCell>
                                        <TableCell width={'10%'} align="left" style={shopRequest.IsCollapsed === false ? { color: 'blue', fontWeight: 'bold' } : {}} className='cell-text'>
                                            {shopRequest.ShopType}
                                        </TableCell>
                                        <TableCell width={'10%'} align="left" className='cell-text'>
                                            {`${shopRequest.IsCollapsed === true ? `${!!shopRequest.ContactName && shopRequest.ContactName.trim() !== '' ? shopRequest.ContactName : 'N/A'}` : ''}`}
                                        </TableCell>
                                        <TableCell width={'15%'} align="left" className='cell-text'>
                                            {`${shopRequest.IsCollapsed === true ? `${!!shopRequest.CellNumber && shopRequest.CellNumber.trim() !== '' ? shopRequest.CellNumber : 'N/A'}` : ''}`}
                                        </TableCell>
                                        <TableCell width={'40%'} align="left" className='cell-text'>
                                            {`${shopRequest.IsCollapsed === true ? `${!!shopRequest.StreetAddress && shopRequest.StreetAddress.trim() !== '' ? shopRequest.StreetAddress : 'N/A'}` : ''}`}
                                        </TableCell>
                                        <TableCell width={'8%'} align="left" className='cell-text'>
                                            {`${shopRequest.IsCollapsed === true ? `${((shopRequest.Distance ?? 0) / 1000).toFixed(3)} KM` : ''}`}
                                        </TableCell>
                                    </TableRow>
                                        {shopRequest.IsCollapsed === false && <TableRow key={`Shop_Detail_Card_${shopRequest.UniqueId}`}>
                                            <TableCell colSpan={SHOP_TABLE_HEAD.length}>
                                                <Table sx={{ borderCollapse: 'separate', borderSpacing: '0px 4px' }} size={'small'}>
                                                    <TableBody>
                                                        <TableRow
                                                            hover
                                                            key={`ShopDetail_${shopRequest.UniqueId}`}
                                                            tabIndex={-1}
                                                            role="checkbox"
                                                            selected={false}
                                                            aria-checked={false}
                                                        >
                                                            <TableCell className='' width={"15%"}>
                                                                <strong>Request Id:</strong>
                                                            </TableCell>
                                                            <TableCell className='' width="20%">
                                                                {shopRequest.Id}
                                                            </TableCell>
                                                            <TableCell colSpan={2} rowSpan={3} align='right'>
                                                                <div style={{ display: 'flex' }}><img style={{ alignSelf: 'center', display: 'block' }} src={shopRequest.ShopImageUrl} alt="Shop" width="150" height="100" /></div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            hover
                                                            key={`ShopDetail1_${shopRequest.UniqueId}`}
                                                            tabIndex={-1}
                                                            role="checkbox"
                                                            selected={false}
                                                            aria-checked={false}
                                                        >
                                                            <TableCell className=''>
                                                                <strong>Name:</strong>
                                                            </TableCell>
                                                            <TableCell className=''>
                                                                {shopRequest.ShopName}
                                                                {!!shopRequest && shopRequest.CellNumber.trim() !== '' && <span style={{ paddingLeft: 5 }}>
                                                                    {shopRequest.CellNumber}
                                                                </span>}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            hover
                                                            key={`ShopDetail2_${shopRequest.UniqueId}`}
                                                            tabIndex={-1}
                                                            role="checkbox"
                                                            selected={false}
                                                            aria-checked={false}
                                                        >
                                                            <TableCell className=''>
                                                                <strong>Shop Type:</strong>
                                                            </TableCell>
                                                            <TableCell className=''>
                                                                {shopRequest.ShopType}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            hover
                                                            key={`ShopDetail3_${shopRequest.UniqueId}`}
                                                            tabIndex={-1}
                                                            role="checkbox"
                                                            selected={false}
                                                            aria-checked={false}
                                                        >
                                                            <TableCell className=''>
                                                                <strong>Requested By:</strong>
                                                            </TableCell>
                                                            <TableCell className=''>
                                                                {!!shopRequest.AddedBy && shopRequest.AddedBy.trim() !== '' ? shopRequest.AddedBy : 'N/A'}
                                                            </TableCell>
                                                            <TableCell className='' width="15%">
                                                                <strong>Route:</strong>
                                                            </TableCell>
                                                            <TableCell className=''>
                                                                {!!shopRequest.AddedOnRouteName && shopRequest.AddedOnRouteName.trim() !== '' ? shopRequest.AddedOnRouteName : 'N/A'}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            hover
                                                            key={`ShopDetail4_${shopRequest.UniqueId}`}
                                                            tabIndex={-1}
                                                            role="checkbox"
                                                            selected={false}
                                                            aria-checked={false}
                                                        >
                                                            <TableCell className=''>
                                                                <strong>Requested On:</strong>
                                                            </TableCell>
                                                            <TableCell className=''>
                                                                {formatedDateTime(shopRequest.AddedOn, 'DD-MMM-YYYY hh:mm A')}
                                                            </TableCell>
                                                            <TableCell className=''>
                                                                <strong>Status:</strong>
                                                            </TableCell>
                                                            <TableCell className=''>
                                                                {shopRequest.RequestStatus}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            hover
                                                            key={`ShopDetail5_${shopRequest.UniqueId}`}
                                                            tabIndex={-1}
                                                            role="checkbox"
                                                            selected={false}
                                                            aria-checked={false}
                                                        >
                                                            <TableCell className=''>
                                                                <strong>Area:</strong>
                                                            </TableCell>
                                                            <TableCell className=''>
                                                                {shopRequest.AreaName}
                                                            </TableCell>
                                                            <TableCell className=''>
                                                                <strong>Address:</strong>
                                                            </TableCell>
                                                            <TableCell className=''>
                                                                {`${(!!shopRequest.StreetAddress && shopRequest.StreetAddress.trim() !== '' ? shopRequest.StreetAddress : 'N/A')}`}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            hover
                                                            key={`ShopDetail6_${shopRequest.UniqueId}`}
                                                            tabIndex={-1}
                                                            role="checkbox"
                                                            selected={false}
                                                            aria-checked={false}
                                                        >
                                                            <TableCell className=''>
                                                                <strong>Shop In:</strong>
                                                            </TableCell>
                                                            <TableCell className=''>
                                                                {shopRequest.ShopIn}
                                                            </TableCell>
                                                            <TableCell className=''>
                                                                <strong>Shop Size:</strong>
                                                            </TableCell>
                                                            <TableCell className=''>
                                                                {shopRequest.ShopSize}
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableCell>
                                        </TableRow>}
                                        <TableRow
                                            key={'LoadingRow'}
                                            tabIndex={-1}
                                            role="checkbox"
                                            selected={false}
                                            aria-checked={false}
                                            style={{ backgroundColor: 'rgb(66, 121, 163)' }}
                                        >
                                            <TableCell style={{ color: 'white' }} align='center' colSpan={SHOP_TABLE_HEAD.length}>
                                                <strong>Matching Shops Below</strong>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                    <TableRow key={`Matching_Shops_${shopRequest.UniqueId}`}>
                                        <TableCell colSpan={SHOP_TABLE_HEAD.length}>
                                            <TableContainer sx={{ height: window.innerHeight - (shopRequest.IsCollapsed === true ? 225 : 550), overflowY: 'auto' }}>
                                                <Table stickyHeader sx={{ borderCollapse: 'separate', borderSpacing: '0px 4px', overflow: 'scroll' }} size={'small'}>
                                                    <TableBody>
                                                        {isMatchingLoading === true && <TableRow
                                                            key={'LoadingRow'}
                                                            tabIndex={-1}
                                                            role="checkbox"
                                                            selected={false}
                                                            aria-checked={false}
                                                        >
                                                            <TableCell align='center' colSpan={8}>
                                                                <CircularProgress />
                                                            </TableCell>
                                                        </TableRow>}
                                                        {isMatchingLoading === false && (!matchingShops || matchingShops.length <= 0) && <TableRow
                                                            key={'LoadingRow'}
                                                            tabIndex={-1}
                                                            role="checkbox"
                                                            selected={false}
                                                            aria-checked={false}
                                                        >
                                                            <TableCell align='center' colSpan={8}>
                                                                {`No Matching Shops`}
                                                            </TableCell>
                                                        </TableRow>}
                                                        {!!matchingShops && isMatchingLoading === false && matchingShops.length > 0 && matchingShops.map((row, index) => {
                                                            return (
                                                                <React.Fragment key={`Shop_Item_${row.RecordId}`}><TableRow
                                                                    hover
                                                                    key={row.ShopId as number}
                                                                    tabIndex={-1}
                                                                    role="checkbox"
                                                                    selected={false}
                                                                    aria-checked={false}
                                                                    className={row.IsCollapsed === true ? 'shop' : 'shop-selected'}
                                                                    onClick={() => {
                                                                        if(isLoading === false){
                                                                            onShopCollapseChange(row.RecordId)   
                                                                        }
                                                                    }}
                                                                >
                                                                    {/* <TableCell padding="none" width="1%" align="left" style={{ paddingLeft: 10 }}>
                                                                        <Checkbox checked={selectedShopId === row.ShopId} onClick={(e: any) => {
                                                                            e.stopPropagation();
                                                                            setPageMessages([]);
                                                                            if(isLoading === false){
                                                                                if (row.ShopId === selectedShopId) {
                                                                                    setSelectedShopId(0)
                                                                                } else {
                                                                                    setSelectedShopId(row.ShopId)
                                                                                }
                                                                            }
                                                                        }} />
                                                                    </TableCell> */}
                                                                    <TableCell width="5%" align="left">
                                                                        <Highlighted text={`${row.ShopId}`} highlight={searchText} />
                                                                    </TableCell>
                                                                    <TableCell width={'10%'} align="left">
                                                                        <Highlighter
                                                                            searchWords={searchTextArray}
                                                                            autoEscape={false}
                                                                            textToHighlight={row.ShopName}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell width={'10%'} align="left">
                                                                    <Highlighter
                                                                            searchWords={searchTextArray}
                                                                            autoEscape={false}
                                                                            textToHighlight={row.ShopType}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell width={'10%'} align="left">
                                                                    <Highlighter
                                                                            searchWords={searchTextArray}
                                                                            autoEscape={false}
                                                                            textToHighlight={row.ContactName}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell width={'15%'} align="left">
                                                                    <Highlighter
                                                                            searchWords={searchTextArray}
                                                                            autoEscape={false}
                                                                            textToHighlight={`${!!row.CellOne && row.CellOne.trim() !== '' && !!row.CellTwo && row.CellTwo.trim() !== '' ? `${row.CellOne}, ${row.CellTwo}` : (!!row.CellOne && row.CellOne.trim() !== '' ? `${row.CellOne}` : (!!row.CellTwo && row.CellTwo.trim() !== '' ? `${row.CellTwo}` : 'N/A'))}`}
                                                                        />
                                                                        
                                                                    </TableCell>
                                                                    <TableCell width={'41%'} align="left">
                                                                    <Highlighter
                                                                            searchWords={searchTextArray}
                                                                            autoEscape={false}
                                                                            textToHighlight={row.ShopAddress}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell width={'8%'} align="left">
                                                                        <Highlighted text={`${((row.Distance ?? 0)).toFixed(0)} M`} highlight={searchText} />
                                                                    </TableCell>
                                                                </TableRow>
                                                                    {row.IsCollapsed === false && <TableRow key={`Shop_Detail1_Card_${row.ShopId}`}>
                                                                        <TableCell colSpan={8}>
                                                                            <Table sx={{ borderCollapse: 'separate', borderSpacing: '0px 4px' }} size={'small'}>
                                                                                <TableBody>
                                                                                    <TableRow
                                                                                        hover
                                                                                        key={`ShopDetail_${row.ShopId}`}
                                                                                        tabIndex={-1}
                                                                                        role="checkbox"
                                                                                        selected={false}
                                                                                        aria-checked={false}
                                                                                    >
                                                                                        <TableCell className='' width={"15%"}>
                                                                                            <strong>Shop Id:</strong>
                                                                                        </TableCell>
                                                                                        <TableCell className='' width="20%">
                                                                                            <Highlighted text={row.ShopId.toString()} highlight={searchText} />
                                                                                        </TableCell>
                                                                                        <TableCell colSpan={2} rowSpan={3} align='right'>
                                                                                            <div style={{ display: 'flex' }}><img style={{ alignSelf: 'center', display: 'block' }} src={row.ImageUrl} alt="Shop" width="150" height="100" /></div>
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                    <TableRow
                                                                                        hover
                                                                                        key={`ShopDetail1_${row.ShopId}`}
                                                                                        tabIndex={-1}
                                                                                        role="checkbox"
                                                                                        selected={false}
                                                                                        aria-checked={false}
                                                                                    >
                                                                                        <TableCell className=''>
                                                                                            <strong>Name:</strong>
                                                                                        </TableCell>
                                                                                        <TableCell className=''>
                                                                                            <Highlighted text={row.ShopName} highlight={searchText} />
                                                                                            {!!row.CellOne && row.CellOne.trim() !== '' && <span style={{ paddingLeft: 5 }}>
                                                                                                <Highlighted text={row.CellOne} highlight={searchText} />
                                                                                            </span>}
                                                                                            {!!row.CellTwo && row.CellTwo.trim() !== '' && <span style={{ paddingLeft: 5 }}>
                                                                                                <Highlighted text={row.CellTwo} highlight={searchText} />
                                                                                            </span>}
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                    <TableRow
                                                                                        hover
                                                                                        key={`ShopDetail2_${row.ShopId}`}
                                                                                        tabIndex={-1}
                                                                                        role="checkbox"
                                                                                        selected={false}
                                                                                        aria-checked={false}
                                                                                    >
                                                                                        <TableCell className=''>
                                                                                            <strong>Crdt.Status:</strong>
                                                                                        </TableCell>
                                                                                        <TableCell className='' style={{ color: row.CreditStatusId === 1 ? 'green' : 'red' }}>
                                                                                            {row.CreditStatus}
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                    <TableRow
                                                                                        hover
                                                                                        key={`ShopDetail3_${row.ShopId}`}
                                                                                        tabIndex={-1}
                                                                                        role="checkbox"
                                                                                        selected={false}
                                                                                        aria-checked={false}
                                                                                    >
                                                                                        <TableCell className=''>
                                                                                            <strong>Assigned To:</strong>
                                                                                        </TableCell>
                                                                                        <TableCell className=''>
                                                                                            {!!row.OrderBookerName && row.OrderBookerName.trim() !== '' ? row.OrderBookerName : 'N/A'}
                                                                                        </TableCell>
                                                                                        <TableCell className='' width="15%">
                                                                                            <strong>Route:</strong>
                                                                                        </TableCell>
                                                                                        <TableCell className=''>
                                                                                            <Highlighted text={!!row.Route && row.Route.trim() !== '' ? row.Route : 'Unassigned'} highlight={searchText} />
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                    <TableRow
                                                                                        hover
                                                                                        key={`ShopDetail4_${row.ShopId}`}
                                                                                        tabIndex={-1}
                                                                                        role="checkbox"
                                                                                        selected={false}
                                                                                        aria-checked={false}
                                                                                    >
                                                                                        <TableCell className=''>
                                                                                            <strong>Added Date:</strong>
                                                                                        </TableCell>
                                                                                        <TableCell className=''>
                                                                                            {formatedDateTime(row.AddedOn, 'DD-MMM-YYYY hh:mm A')}
                                                                                        </TableCell>
                                                                                        <TableCell className=''>
                                                                                            <strong>Assigned Day:</strong>
                                                                                        </TableCell>
                                                                                        <TableCell className=''>
                                                                                            {!!row.RouteDay && row.RouteDay.trim() !== '' ? row.RouteDay : 'Unassigned'}
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                    <TableRow
                                                                                        hover
                                                                                        key={`ShopDetail5_${row.ShopId}`}
                                                                                        tabIndex={-1}
                                                                                        role="checkbox"
                                                                                        selected={false}
                                                                                        aria-checked={false}
                                                                                    >
                                                                                        <TableCell className=''>
                                                                                            <strong>Unit:</strong>
                                                                                        </TableCell>
                                                                                        <TableCell className=''>
                                                                                            {row.UnitTitle}
                                                                                        </TableCell>
                                                                                        <TableCell className=''>
                                                                                            <strong>Address:</strong>
                                                                                        </TableCell>
                                                                                        <TableCell className=''>
                                                                                            <Highlighted text={`${(!!row.ShopAddress && row.ShopAddress.trim() !== '' ? row.ShopAddress : 'N/A')}`} highlight={searchText} />
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                    <TableRow
                                                                                        hover
                                                                                        key={`ShopDetail6_${row.ShopId}`}
                                                                                        tabIndex={-1}
                                                                                        role="checkbox"
                                                                                        selected={false}
                                                                                        aria-checked={false}
                                                                                    >
                                                                                        <TableCell className='' colSpan={4}>
                                                                                            <Table sx={{ borderCollapse: 'separate', borderSpacing: '0px 4px' }} size={'small'}>
                                                                                                <TableBody>
                                                                                                    <TableRow
                                                                                                        hover
                                                                                                        key={`ShopDetail7_${row.ShopId}`}
                                                                                                        tabIndex={-1}
                                                                                                        role="checkbox"
                                                                                                        selected={false}
                                                                                                        aria-checked={false}
                                                                                                    >
                                                                                                        <TableCell className='' align='left' padding='none' width={"10%"}>
                                                                                                            <strong>Shop Type:</strong>
                                                                                                        </TableCell>
                                                                                                        <TableCell className='' padding='none' width="12%" style={{ color: 'blue' }}>
                                                                                                            {!!row.ShopType && row.ShopType.trim() !== '' ? row.ShopType : 'N/A'}
                                                                                                        </TableCell>
                                                                                                        <TableCell className='' align='left' padding='none' width={"12%"}>
                                                                                                            <strong>Last Visited:</strong>
                                                                                                        </TableCell>
                                                                                                        <TableCell className='' padding='none' width={"15%"} style={{ color: 'blue' }}>
                                                                                                            <Highlighted text={!!row.LastVisitedOn ? timeAgo(row.LastVisitedOn) : 'N/A'} highlight={searchText} />
                                                                                                        </TableCell>
                                                                                                        <TableCell className='' padding='none' width={"10%"}>
                                                                                                            <strong>Last Order:</strong>
                                                                                                        </TableCell>
                                                                                                        <TableCell className='' padding='none' style={{ color: 'blue' }} width={"15%"}>
                                                                                                            <Highlighted text={!!row.LastOrderedOn ? timeAgo(row.LastOrderedOn) : 'N/A'} highlight={searchText} />
                                                                                                        </TableCell>
                                                                                                        <TableCell className='' padding='none' width={"10%"}>
                                                                                                            <strong>Cvrd.Status:</strong>
                                                                                                        </TableCell>
                                                                                                        <TableCell align="left" style={{ color: row.IsCovered ? 'green' : 'red' }}>
                                                                                                            <Highlighted text={`${row.IsCovered ? 'Covrd.' : 'Nvr. Covrd.'}`} highlight={searchText} />
                                                                                                        </TableCell>
                                                                                                    </TableRow>
                                                                                                </TableBody>
                                                                                            </Table>
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                    {!isRestrictedUser && <TableRow
                                                                                        hover
                                                                                        key={`ShopDetail8_${row.ShopId}`}
                                                                                        tabIndex={-1}
                                                                                        role="checkbox"
                                                                                        selected={false}
                                                                                        aria-checked={false}
                                                                                        style={{ backgroundColor: 'white' }}
                                                                                    >
                                                                                        <TableCell colSpan={4}>
                                                                                            <table border={1} style={{ borderWidth: 1 }} cellSpacing={0} cellPadding={10} width="75%">
                                                                                                <tbody>
                                                                                                    <tr key={`ShopDetail9_${row.ShopId}`}>
                                                                                                        <th colSpan={8} align='center'>Progress</th>
                                                                                                    </tr>
                                                                                                    <tr key={`ShopDetail10_${row.ShopId}`}>
                                                                                                        <td align='center'>T-Sale</td>
                                                                                                        <td align='center'>Cash Sale</td>
                                                                                                        <td align='center'>Crdt.Sale</td>
                                                                                                        <td align='center'>Recvrd. Crdt</td>
                                                                                                        <td align='center'>Pend.Crdt</td>
                                                                                                        <td align='center'>Creditability</td>
                                                                                                        <td align='center'>Distance</td>
                                                                                                    </tr>
                                                                                                    <tr key={`ShopDetail11_${row.ShopId}`}>
                                                                                                        <td align='right' style={{ paddingRight: 5 }}>
                                                                                                            <Highlighted text={`${`${!!searchText && !!row.TotalSale && row.TotalSale.toString().indexOf(searchText) >= 0 ? row.TotalSale : fCommaSeparated(row.TotalSale ?? 0)}`}`} highlight={searchText} />
                                                                                                        </td>
                                                                                                        <td align='right' style={{ paddingRight: 5 }}>{fCommaSeparated(row.OrderOnCashAmount ?? 0)}</td>
                                                                                                        <td align='right' style={{ paddingRight: 5 }}>{fCommaSeparated(row.OrderOnCreditAmount ?? 0)}</td>
                                                                                                        <td align='right' style={{ paddingRight: 5, color: 'green' }}>{fCommaSeparated(row.TotalRecovery ?? 0)}</td>
                                                                                                        <td align='right' style={{ paddingRight: 5, color: 'red' }}>
                                                                                                            <Highlighted text={`${`${!!searchText && !!row.PendingCredit && row.PendingCredit.toString().indexOf(searchText) >= 0 ? (row.PendingCredit ?? 0) : fCommaSeparated(row.PendingCredit ?? 0)}`}`} highlight={searchText} />
                                                                                                        </td>
                                                                                                        <td align='center' style={{ paddingRight: 5, color: row.CreditabilityId === 1 ? 'green' : (row.CreditabilityId === 2 ? 'orange' : 'red') }}>{!!row.Creditability && row.Creditability.trim() !== '' ? row.Creditability : 'N/A'}</td>
                                                                                                        <td align='center' style={{ paddingRight: 5 }}>{!!row.Distance && row.Distance > 0 ? (row.Distance > 1000 ? `${(row.Distance / 1000).toFixed(1)} KM` : `${row.Distance} M`) : 'N/A'}</td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </TableCell>
                                                                                    </TableRow>}
                                                                                </TableBody>
                                                                            </Table>
                                                                        </TableCell>
                                                                    </TableRow>}
                                                                </React.Fragment>
                                                            );
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>

                <Grid item md={12} style={{ justifyContent: 'flex-end', display: 'flex', position: 'absolute', right: 10, bottom: 5 }}>
                    <div style={{ marginRight: 10, paddingTop: 10 }}><FormControlLabel
                        control={
                            <Checkbox
                                checked={isFakeLocation === true}
                                disabled={!isLocationTypeEnabled}
                                onClick={() => {
                                    setPageMessages([]);
                                    setIsFakeLocation(true);
                                    setIsOrignalLocation(false);
                                }}
                            />
                        }
                        label="Office Location" />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isOrignalLocation === true}
                                    disabled={!isLocationTypeEnabled}
                                    onClick={() => {
                                        setPageMessages([]);
                                        setIsFakeLocation(false);
                                        setIsOrignalLocation(true);
                                    }}
                                />
                            }
                            label="Orignal Location" /></div>
                    <LoadingButton
                        size="small"
                        type="button"
                        variant="outlined"
                        loading={isLoading}
                        disabled={isLoading}
                        style={{ marginTop: 15, alignSelf: 'center', marginRight: 10, borderRadius: 0 }}
                        onClick={() => {
                            onSave();
                        }}
                    >
                        {selectedShopId > 0 ? 'Replace' : 'Approve'}
                    </LoadingButton>

                    <LoadingButton
                        size="small"
                        type="button"
                        variant="outlined"
                        loading={isLoading}
                        disabled={isLoading}
                        style={{ marginTop: 15, alignSelf: 'center', color:'red', borderColor:'red', marginRight: 10, borderRadius: 0 }}
                        onClick={() => {
                            onDeleteShopRequest(shopRequest.UniqueId);
                        }}
                    >
                        {'Reject'}
                    </LoadingButton>

                    <LoadingButton
                        size="small"
                        type="button"
                        variant="outlined"
                        loading={false}
                        disabled={isLoading}
                        style={{ marginTop: 15, alignSelf: 'center', color: 'gray', borderRadius: 0, borderColor: 'gray' }}
                        onClick={props.onClose}
                    >
                        Cancel
                    </LoadingButton>
                </Grid>
            </Container>
        </Box>
    </Modal>;
}