import { Icon } from '@iconify/react';
import { PropaneSharp } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Container, Grid, InputAdornment, TextField } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { isRestrictedLogin, NumberOfPreviousMonth } from 'src/app.constant';
import { ICreditHistoryParam } from 'src/interfaces/credit-history-param.interface';
import { IApplicationState } from 'src/redux';
import CustomDatePicker from './common/CustomDatePicker';

interface IProps {
    params: ICreditHistoryParam;
    onChange: (prms:ICreditHistoryParam) => void;
    isLoading: boolean;
    onLoad: (isOnRefresh) => void;
    onReset:() => void;
}

export const CreditReceiveHistoryParam = (props: IProps) => {

    const [params, setParams] = React.useState<ICreditHistoryParam>(props.params);
    const creditReceiveHisotyParamKey = 'CreditReceiveHistoryParamComponent';
    const onParamChange = (value:any, name:keyof ICreditHistoryParam) => {
        let prms:any = {...params};
        prms[name] = value;
        setParams(prms);
        props.onChange({...prms});
    }

    const userId = useSelector((st:IApplicationState) => st.loginUser.UserId);
    const tDated = new Date();
    
    const onReset = () =>{
        let changed = {
            SlipNumber:null,
            FromDate:null,
            ToDate:null,
            OwnerCellNumber:null,
            OwnerName:null,
            ResponsiblePersonName:null,
            ShopAddress:null,
            ShopName:null,
            SearchText:'',
        };

        setParams(changed);
        props.onReset();
    }

    return <Container key={`${creditReceiveHisotyParamKey}_1`} style={{ paddingTop: 20, paddingBottom: 10, paddingLeft:0, paddingRight:0 }}>
        <Grid key={`${creditReceiveHisotyParamKey}_2`} container spacing={2} style={{paddingLeft:0, paddingRight:0}}>
            <Grid key={`${creditReceiveHisotyParamKey}_3`} item md={3} sm={12}>
                <TextField
                    fullWidth
                    autoComplete="slip-number"
                    type={'number'}
                    label="Slip Number"
                    value={!!params && !!params.SlipNumber && params.SlipNumber > 0 ? params.SlipNumber : ''}
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                        const value = event.target.value;
                        onParamChange(value, 'SlipNumber');
                    }}
                    onKeyDown={(e:any) => {
                        if (e.key === 'Enter') {
                            props.onLoad(false);
                          }
                    }}
                    key={`${creditReceiveHisotyParamKey}_4`}
                />
            </Grid>
            <Grid key={`${creditReceiveHisotyParamKey}_5`} item md={3} sm={12}>
            <TextField
                        autoComplete="search_text"
                        fullWidth
                        variant='outlined'
                        type={'text'}
                        label=""
                        value={params.SearchText}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                            onParamChange(event.target.value,'SearchText');
                        }}

                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon icon={'akar-icons:search'} />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                !!params.SearchText ? <InputAdornment position="end">
                                    <Icon onClick={() => {onParamChange('','SearchText')}} style={{cursor:'pointer'}} icon={'ic:twotone-clear'} />
                                </InputAdornment> : undefined
                            )
                        }}

                        onKeyDown={(e:any) => {
                            if (e.key === 'Enter') {
                                props.onLoad(false);
                              }
                        }}
                        key={`${creditReceiveHisotyParamKey}_6`}
                    />
            </Grid>

            <Grid key={`${creditReceiveHisotyParamKey}_7`} item md={3} sm={12}>
                <CustomDatePicker
                    label='From Date'
                    value={params.FromDate}
                    readonly={props.isLoading}
                    onChange={(dated: Date) => {
                        onParamChange(dated, 'FromDate');
                    }}
                    key={`${creditReceiveHisotyParamKey}_9`}
                    minDate={isRestrictedLogin(userId) ? (new Date(tDated.getFullYear(),tDated.getMonth() - NumberOfPreviousMonth,1)) : undefined}
                    inputDisable={true}
                />
            </Grid>

            <Grid key={`${creditReceiveHisotyParamKey}_8`} item md={3} sm={12}>
                <CustomDatePicker
                    label='To Date'
                    value={params.ToDate}
                    readonly={props.isLoading}
                    onChange={(dated: Date) => {
                        onParamChange(dated, 'ToDate');
                    }}
                    key={`${creditReceiveHisotyParamKey}_10`}
                    minDate={isRestrictedLogin(userId) ? (new Date(tDated.getFullYear(),tDated.getMonth() - NumberOfPreviousMonth,1)) : undefined}
                    inputDisable={true}
                />
            </Grid>

            <Grid key={`${creditReceiveHisotyParamKey}_11`} item xs={12} style={{ justifyContent: 'center', display: 'flex' }}>
                <LoadingButton
                    size="small"
                    type="button"
                    variant="contained"
                    loading={props.isLoading}
                    style={{ alignSelf: 'center', margin: 3, backgroundColor: 'blue', textAlign: 'right', height: 40 }}
                    startIcon={<Icon icon={'bi:clock-history'} />}
                    onClick={() => {
                        props.onLoad(false);
                    }}
                    key={`${creditReceiveHisotyParamKey}_12`}
                >
                    Load History
                </LoadingButton>

                <LoadingButton
                    size="small"
                    type="button"
                    variant="contained"
                    loading={props.isLoading}
                    style={{ alignSelf: 'center', margin: 3, backgroundColor: 'orange', textAlign: 'right', height: 40 }}
                    startIcon={<Icon icon={'carbon:reset'} />}
                    onClick={onReset}
                    key={`${creditReceiveHisotyParamKey}_13`}
                >
                    Reset Filters
                </LoadingButton>

            </Grid>

        </Grid>
    </Container>;
}