import React from 'react';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import refreshFill from '@iconify/icons-eva/refresh-fill';
// material
import {
    Card,
    Table,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    Grid,
} from '@mui/material';
import Page from '../Page';
import Scrollbar from '../Scrollbar';
import { UserListHead } from '../_dashboard/user';
import { LoadingButton } from '@mui/lab';
import { doCreditApi } from '../../http';
import { connect } from 'react-redux';
import { IApplicationState } from 'src/redux';
import { Audio as AudoSpin } from 'react-loader-spinner'
import { formattedDate, generateUUID } from 'src/utility';
import { IUserDetail } from 'src/interfaces';
import { fCurrency } from 'src/utils/formatNumber';
import { useNavigate } from "react-router-dom";
import { IMonthWiseCredit } from 'src/interfaces/month-wise-credit.interface';
import { AliUserId } from 'src/app.constant';


// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'place_holder', label: 'Unit', alignContent: 'center' },
    { id: 'responsible_person', label: 'Responsible Person', alignContent: 'center' },
    { id: 'month_name', label: 'Month', alignContent: 'center' },
    {id: 'pending_credit', label:'Pending Credit', alignContent:'center'},
    {id: 'action', label:'', alignContent:'center'}
];

interface IMonthWiseDetailParam{
    UnitUniqueId:string;
    UnitTitle:string;
    ResponsiblePersonEmployeeId:number;
    ResponsiblePersonName:string;
}

//#region interfaces
interface IProps {
    loginUser: IUserDetail | null;
    params:IMonthWiseDetailParam;
    onClick:(monthStartDate:Date, employeeId:number, employeeName:string, unitUniqueId:string, unitTitle:string) => void;
    onBackClick:() => void;
    onWeekWiseClick:(monthStartDate:Date, employeeId:number, employeeName:string, unitUniqueId:string, unitTitle:string) => void;
}

interface ITotal{
    TotalCreditAmount:number;
    TotalReceivedAmount:number;
    TotalPendingAmount:number;
    UnitUniqueId:string;
}

//#endregion

const MonthWiseCreditDetail = (props: IProps) => {
    //#region Initialization
    const navigate = useNavigate();
    //#endregion

    //#region state variables
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [monthWiseCredits, setMonthWiseCredits] = React.useState<IMonthWiseCredit[]>([]);
    const [overAllTotals, setOverAllTotals] = React.useState<ITotal>({
        TotalCreditAmount:0,
        TotalPendingAmount:0,
        TotalReceivedAmount:0,
        UnitUniqueId:''
    });

    const [unitWiseTotals, setUnitWiseTotals] = React.useState<ITotal[]>([]);
    //#endregion

    //#region util methods start

    const setTotals = (crdts:IMonthWiseCredit[]) =>{
        let oTotal:ITotal = {
            TotalCreditAmount:0,
            TotalPendingAmount:0,
            TotalReceivedAmount:0,
            UnitUniqueId:''
        };

        let uWiseTotals:ITotal[] = [];

        if(!!crdts && crdts.length > 0){
            crdts.forEach(c => {
                if(uWiseTotals.findIndex(e => e.UnitUniqueId == c.UnitUniqueId) >= 0){
                    let uWiseTotal = uWiseTotals.find(u => u.UnitUniqueId == c.UnitUniqueId);
                    uWiseTotal.TotalCreditAmount += c.TotalCredit;
                    uWiseTotal.TotalPendingAmount += c.TotalPendingCredit;
                    uWiseTotal.TotalReceivedAmount += c.TotalReceivedAmount;
                }else{
                    uWiseTotals.push({
                        TotalCreditAmount : c.TotalCredit,
                        TotalPendingAmount: c.TotalPendingCredit,
                        TotalReceivedAmount:c.TotalReceivedAmount,
                        UnitUniqueId:c.UnitUniqueId
                    });
                }

                oTotal.TotalCreditAmount += c.TotalCredit;
                oTotal.TotalPendingAmount += c.TotalPendingCredit;
                oTotal.TotalReceivedAmount += c.TotalReceivedAmount;
            });
        }

        setOverAllTotals({...oTotal});
        setUnitWiseTotals([...uWiseTotals]);
    }

    //#endregion util methods end

    //#region handlers start
    const navigateToUrl = (url:string)=>{
        navigate(url);
      }

    const resetEverything = () => {
        setOverAllTotals({
            TotalCreditAmount:0,
            TotalPendingAmount:0,
            TotalReceivedAmount:0,
            UnitUniqueId:''
        });

        setUnitWiseTotals([]);
        setMonthWiseCredits([]);
    }

    const onRefresh = () =>{
        resetEverything();
        getCredits(props.params.ResponsiblePersonEmployeeId, props.params.UnitUniqueId);
    }

    //#endregion handlers end

    //#region apis call start

    const getCredits = (employeeId:number,unitUniqueId:string) => {
        setIsLoading(true);
        doCreditApi.getMonthWiseCredits(unitUniqueId, props.loginUser.UserId,employeeId,null)
            .then(results => {
                setIsLoading(false);
                if (!!results) {
                    setMonthWiseCredits([...results]);
                    setTotals([...results]);
                }
                else {
                    resetEverything();
                }
            })
            .catch(e => {
                console.log(e);
                setIsLoading(false);
            });
    }

    //#endregion apis calls end

    //#region hooks start
    React.useEffect(() => {
        if(!!props.params && !!props.params.ResponsiblePersonEmployeeId){
            getCredits(props.params.ResponsiblePersonEmployeeId, props.params.UnitUniqueId);
        }else{
            resetEverything();
        }
    }, [props.params]);

    //#endregion hooks end

    const blueBackground = 'blue';
    const greenBackground = 'rgba(34, 154, 22, 0.72)';
    const monthWiseCreditDetailKey = 'MonthWiseCreditDetailKey';

    return (
        (!!props.params.ResponsiblePersonEmployeeId && !!props.params.UnitUniqueId && props.params.UnitUniqueId != '') ? <Page key={`${monthWiseCreditDetailKey}_1`} title="Month Wise Credit Detail | Raza Pak Care">
            <Container key={`${monthWiseCreditDetailKey}_2`} style={{ maxWidth: 1500, paddingLeft: 0, paddingRight: 0 }}>
                <Grid key={`${monthWiseCreditDetailKey}_3`} container style={{ flexDirection: 'row'}}>
                    <Grid key={`${monthWiseCreditDetailKey}_4`} xs={12} sm={12} style={{flexDirection:'row',margin:5,display:'flex',justifyContent:'flex-end'}} item>

                    <LoadingButton
                        size="small"
                        type="button"
                        variant="contained"
                        loading={isLoading}
                        style={{alignSelf:'center',marginRight:5, backgroundColor: 'blue', textAlign: 'right', height: 40 }}
                        startIcon={<Icon icon={'eva:arrow-back-outline'} />}
                        onClick={props.onBackClick}
                        key={`${monthWiseCreditDetailKey}_5`}
                    >
                        Back
                    </LoadingButton>

                    <LoadingButton
                        size="small"
                        type="button"
                        variant="contained"
                        loading={isLoading}
                        style={{alignSelf:'center', backgroundColor: 'rgba(34, 154, 22, 0.72)', textAlign: 'right', height: 40 }}
                        startIcon={<Icon icon={refreshFill} />}
                        onClick={() => {
                            onRefresh();
                        }}
                        key={`${monthWiseCreditDetailKey}_6`}
                    >
                        Refresh
                    </LoadingButton>
                    </Grid>
                </Grid>


                <Card key={`${monthWiseCreditDetailKey}_7`}>
                            {<Typography key={`${monthWiseCreditDetailKey}_8`} style={{ backgroundColor: props.loginUser?.UserId == AliUserId ? blueBackground : greenBackground, color: 'white', paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h4'}>{`${!!props.params.UnitTitle && props.params.UnitTitle.trim() != '' ? `${props.params.UnitTitle} ` : ''}`}Month Wise Credit Detail{`${!!props.params.ResponsiblePersonName && props.params.ResponsiblePersonName.trim() != '' ? ` For ${props.params.ResponsiblePersonName}` : ''}`}</Typography>}
                            {isLoading == true && <AudoSpin
                                height="80"
                                width="80"
                                color='green'
                                ariaLabel='three-dots-loading'
                                wrapperStyle={{ alignSelf: 'center', justifyContent: 'center' }}
                                key={`${monthWiseCreditDetailKey}_9`}
                            />}

                            {(!monthWiseCredits || monthWiseCredits.length <= 0) && isLoading == false && <Typography key={`${monthWiseCreditDetailKey}_10`} style={{ paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h6'}>No Month Wise Credit Details For {props.params.ResponsiblePersonName}</Typography>}

                            {!!monthWiseCredits && monthWiseCredits.length > 0 && <Scrollbar key={`${monthWiseCreditDetailKey}_11`} sx={{}}>
                                <TableContainer key={`${monthWiseCreditDetailKey}_12`} sx={{ minWidth: 900 }}>
                                    <Table key={`${monthWiseCreditDetailKey}_13`} stickyHeader>
                                        <UserListHead
                                            order={'asc'}
                                            orderBy={''}
                                            headLabel={TABLE_HEAD}
                                            rowCount={0}
                                            numSelected={0}
                                            onRequestSort={undefined}
                                            onSelectAllClick={undefined}
                                            isAllSelected={false}
                                            showSelectAllCheckbox={false}
                                            customKey={`${monthWiseCreditDetailKey}_14`}
                                            key={`${monthWiseCreditDetailKey}_15`}
                                        />
                                        <TableBody key={`${monthWiseCreditDetailKey}_16`}>
                                            {!!monthWiseCredits && monthWiseCredits.length > 0 && monthWiseCredits.map((monthRow, monthIndex) => {
                                                return (<React.Fragment key={`${monthWiseCreditDetailKey}_${formattedDate(monthRow.MonthStartDate, 'MMM-YYYY')}_1`}>{!!monthRow.MonthWiseCreditDetails && monthRow.MonthWiseCreditDetails.length > 0 && monthRow.MonthWiseCreditDetails.map((row, index) => {
                                                    return (
                                                        <TableRow
                                                            hover
                                                            key={`${monthWiseCreditDetailKey}_${formattedDate(monthRow.MonthStartDate, 'MMM-YYYY')}_2`}
                                                            tabIndex={-1}
                                                            role="checkbox"
                                                            selected={false}
                                                            aria-checked={false}
                                                            style={{cursor:'pointer'}}
                                                        >
                                                            <TableCell key={`${monthWiseCreditDetailKey}_${formattedDate(monthRow.MonthStartDate, 'MMM-YYYY')}_3`} align="center">{row.UnitTitle}</TableCell>
                                                            <TableCell key={`${monthWiseCreditDetailKey}_${formattedDate(monthRow.MonthStartDate, 'MMM-YYYY')}_4`} align="center" component="th" scope="row" padding="none">
                                                                {row.ResponsiblePersonName}
                                                            </TableCell>
                                                            <TableCell onClick={() => {
                                                                props.onClick(monthRow.MonthStartDate,row.ResponsiblePersonEmployeeId,row.ResponsiblePersonName,monthRow.UnitUniqueId,monthRow.UnitTitle);
                                                            }} key={`${monthWiseCreditDetailKey}_${formattedDate(monthRow.MonthStartDate, 'MMM-YYYY')}_5`} style={{textDecoration:'underline',color:'blue'}} align="center" component="th" scope="row" padding="none">
                                                                <strong>{formattedDate(monthRow.MonthStartDate, 'MMM-YYYY')}</strong>
                                                            </TableCell>
                                                            <TableCell key={`${monthWiseCreditDetailKey}_${formattedDate(monthRow.MonthStartDate, 'MMM-YYYY')}_6`} style={{color:'red'}} align="center" component="th" scope="row" padding="none">
                                                                {fCurrency(row.TotalPendingCredit)}
                                                            </TableCell>
                                                            <TableCell align='center' style={{width:170}}>
                                                            <LoadingButton
                                                        size="small"
                                                        type="button"
                                                        variant="contained"
                                                        loading={isLoading}
                                                        style={{ margin: 5,backgroundColor:'blue' }}
                                                        startIcon={<Icon icon='cil:credit-card' />}
                                                        onClick={() => {
                                                            props.onWeekWiseClick(monthRow.MonthStartDate,row.ResponsiblePersonEmployeeId,row.ResponsiblePersonName,monthRow.UnitUniqueId,monthRow.UnitTitle);
                                                         }}
                                                    >
                                                        Week Credit
                                                    </LoadingButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                                </React.Fragment>);
                                            })}
                                            <TableRow key={`${monthWiseCreditDetailKey}_30`}>
                                                <TableCell key={`${monthWiseCreditDetailKey}_31`} colSpan={3} align='right'>
                                                    <Typography key={`${monthWiseCreditDetailKey}_32`} variant='h6' style={{ textAlign: 'right' }}>Total:</Typography>
                                                </TableCell>
                                                <TableCell key={`${monthWiseCreditDetailKey}_33`} align='center'><Typography key={`${monthWiseCreditDetailKey}_34`} style={{ color: 'red' }} variant='h6'>{fCurrency(overAllTotals?.TotalPendingAmount ?? 0)}</Typography></TableCell>
                                                <TableCell>&nbsp;</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Scrollbar>}
                        </Card>
            </Container>
        </Page> : <Page key={`${monthWiseCreditDetailKey}_35`} title='Month Wise Credit Detail | Raza Pakcare'>
    <Container key={`${monthWiseCreditDetailKey}_36`} style={{height:'100%', display:'flex',flex:1,flexDirection:'row',justifyContent:'center'}}>
            <Typography key={`${monthWiseCreditDetailKey}_37`} style={{alignSelf:'center',backgroundColor:'orange',opacity:0.5,color:'white',padding:20,marginTop:20,borderRadius:10}} variant='h2'>Please Go Back And Click On Responsible Person To See Details</Typography>
    </Container>
  </Page>
    );
}

const mapStateToProps = (state: IApplicationState) => {
    return {
        loginUser: state.loginUser
    };
}
const connectedMonthWiseCreditDetail = connect(mapStateToProps)(MonthWiseCreditDetail);
export { connectedMonthWiseCreditDetail as MonthWiseCreditDetail };
