import { IComponentKey } from "./interfaces/component-key.interface";
import DashboardLayout from "./layouts/dashboard";
import EmptyLayout from "./layouts/EmptyLayout";
import Blog from "./pages/Blog";
import { CashSubmission } from "./pages/CashSubmission";
import { CollectedCashDetail } from "./pages/CollectedCashDetail";
import { CreateDO } from "./pages/CreateDO";
import { Credit } from "./pages/Credit";
import DashboardApp from "./pages/DashboardApp";
import { IssueStock } from "./pages/IssueStock";
import Login from "./pages/Login";
import { Orders } from "./pages/Orders";
import Products from "./pages/Products";
import { ReceiveCash } from "./pages/ReceiveCash";
import { ReceiveReturn } from "./pages/ReceiveReturn";
import Register from "./pages/Register";
import { Stock } from "./pages/Stock";
import User from "./pages/User";
import NotFound from './pages/Page404';
import { AreaMapContainer } from "./google-map/area-map.container";
import {RouteMapContainer} from './google-map/route-map.container';
import { EmployeeCredit } from "./pages/EmployeeCredit";
import { CollectedCashDetailNew } from "./pages/CollectedCashDetailNew";
import { StockLedger } from "./pages/StockLedger";
import { AddStock } from "./pages/AddStock";
import { CashDetailNew } from "./pages/CashDetailNew";
import {Report} from './pages/Report';
import { DateWiseCredit } from "./pages/DateWiseCredit";
import { CreditReceiveHistory } from "./pages/CreditReceiveHistory";
import { MonthWiseCredit } from "./components/credit/MonthWiseCredit";
import { CreditNew } from "./pages/CreditNew";
import { CreditHistory } from "./pages/CreditHistory";
import { UnitOrdersMain } from "./components/orders/unit-orders-main.component";
import { OrdersOldComponent } from "./components/orders/orders-old.component";
import { NewRouteMapContainer } from "./google-map/new-route-map.container";
import { OrdersNewReport } from "./pages/OrdersNewReport";
import { TrackingMapContainer } from "./google-map/TrackingMapContainer";
import { RouteSetup } from "./pages/RouteSetup";
import { RouteDetail } from "./pages/RouteDetail";
import { NewRouteSetupOnMapContainer } from "./pages/NewRouteSetupOnMap";
import { ShopsContainer } from "./pages/ShopsContainer";
import { ShopRequestsContainer } from "./pages/ShopRequestsContainer";
import { ManageShopsContainer } from "./pages/ManageShopsContainer";
import { ManageTabletContainer } from "./pages/ManageTabletContainer";
import { CreditLimits } from "./pages/CreditLimits";
import { RecoveryTargetNewContainer } from "./pages/RecoveryTargetNewContainer";

export const Keys = {
    DashboardLayout:'DashboardLayout',
    DashboardApp:'DashboardApp',
    User:'User',
    Products:'Products',
    Blog:'Blog',
    Orders:'Orders',
    CreateDO:'CreateDO',
    IssueStock:'IssueStock',
    ReceiveReturn:'ReceiveReturn',
    ReceiveCash:'ReceiveCash',
    Credit:'Credit',
    CashSubmission:'CashSubmission',
    CollectedCashDetail:'CollectedCashDetail',
    Stock:'Stock',
    EmptyLayout:'EmptyLayout',
    Login:'Login',
    Register:'Register',
    NotFound:'NotFound',
    AreaMapContainer:'AreaMapContainer',
    RouteMapContainer:'RouteMapContainer',
    EmployeeCredit:'EmployeeCredit',
    StockLedger:'StockLedger',
    AddStock:'AddStock',
    Report:'Report',
    NewCreditVsRecovered:'NewCreditVsRecovered',
    CreditReceiveHistory:'CreditReceiveHistory',
    MonthWiseCredit:'MonthWiseCredit',
    CreditHistory:'CreditHistory',
    UnitOrdersMain:'UnitOrdersMain',
    OrdersOldComponent:'OrdersOldComponent',
    NewRouteMapContainer:'NewRouteMapContainer',
    NewOrderReport:'NewOrdersReport',
    NewTrackingComponent:'NewTrackingComponent',
    RouteSetup:'RouteSetup',
    RouteDetail:'RouteDetail',
    ShopsContainer:'ShopsContainer',
    ShopRequests:'ShopRequests',
    ManageShops:'ManageShops',
    ManageTablet:'ManageTablet',
    RecoveryTarget:'RecoveryTargetContainer',
    CreditLimitsPage:'CreditLimits'
};

export const ComponentKeys:IComponentKey[] = [
    {
        KeyName:Keys.DashboardLayout,
        Component:<DashboardLayout />
    },
    {
        KeyName:Keys.DashboardApp,
        Component:<DashboardApp />
    },
    {
        KeyName:Keys.User,
        Component:<User />
    },
    {
        KeyName:Keys.Products,
        Component:<Products />
    },
    {
        KeyName:Keys.Blog,
        Component:<Blog />
    },
    {
        KeyName:Keys.Orders,
        Component:<Orders />
    },
    {
        KeyName:Keys.CreateDO,
        Component:<CreateDO />
    },
    {
        KeyName:Keys.IssueStock,
        Component:<IssueStock />
    },
    {
        KeyName:Keys.ReceiveReturn,
        Component:<ReceiveReturn />
    },
    {
        KeyName:Keys.ReceiveCash,
        Component:<ReceiveCash />
    },
    {
        KeyName:Keys.Credit,
        Component:<CreditNew />
    },
    {
        KeyName:Keys.CashSubmission,
        Component:<CollectedCashDetailNew />
    },
    {
        KeyName:Keys.CollectedCashDetail,
        Component:<CashDetailNew />
    },
    {
        KeyName:Keys.Stock,
        Component:<Stock />
    },
    {
        KeyName:Keys.EmptyLayout,
        Component:<EmptyLayout />
    },
    {
        KeyName:Keys.Login,
        Component:<Login />
    },
    {
        KeyName:Keys.Register,
        Component:<Register />
    },
    {
        KeyName:Keys.NotFound,
        Component:<NotFound />
    },
    {
        KeyName:Keys.AreaMapContainer,
        Component:<AreaMapContainer />
    },
    {
        KeyName:Keys.RouteMapContainer,
        Component:<RouteMapContainer />
    },
    {
        KeyName:Keys.EmployeeCredit,
        Component:<EmployeeCredit />
    },
    {
        KeyName:Keys.StockLedger,
        Component:<StockLedger />
    },
    {
        KeyName:Keys.AddStock,
        Component:<AddStock />
    },
    {
        KeyName:Keys.Report,
        Component:<Report />
    },
    {
        KeyName:Keys.NewCreditVsRecovered,
        Component:<DateWiseCredit />
    },
    {
        KeyName:Keys.CreditReceiveHistory,
        Component:<CreditReceiveHistory />
    },
    {
        KeyName:Keys.MonthWiseCredit,
        Component:<MonthWiseCredit />
    },
    {
        KeyName:Keys.CreditHistory,
        Component:<CreditHistory />
    },
    {
        KeyName:Keys.UnitOrdersMain,
        Component:<UnitOrdersMain />
    },
    {
        KeyName:Keys.OrdersOldComponent,
        Component:<OrdersOldComponent />
    },
    {
        KeyName:Keys.NewRouteMapContainer,
        Component:<NewRouteSetupOnMapContainer />
    },
    {
        KeyName:Keys.NewOrderReport,
        Component:<OrdersNewReport />
    },
    {
        KeyName:Keys.NewTrackingComponent,
        Component:<TrackingMapContainer />
    },
    {
        KeyName:Keys.RouteSetup,
        Component:<RouteSetup />
    },
    {
        KeyName:Keys.RouteDetail,
        Component:<RouteDetail />
    },
    {
        KeyName:Keys.ShopsContainer,
        Component:<ShopsContainer />
    },
    {
        KeyName:Keys.ShopRequests,
        Component:<ShopRequestsContainer scrollToTop={() => {}} />
    },
    {
        KeyName:Keys.ManageShops,
        Component:<ManageShopsContainer />
    },
    {
        KeyName:Keys.ManageTablet,
        Component:<ManageTabletContainer />
    },
    {
        KeyName:Keys.RecoveryTarget,
        Component:<RecoveryTargetNewContainer />
    },
    {
        KeyName:Keys.CreditLimitsPage,
        Component:<CreditLimits />
    }
];