import {IProductOrder} from '../interfaces/product-order.interface';
import {http} from './http-base';
import {concatParam, formattedDate} from '../utility'
import { IEnum } from 'src/interfaces';
import { IDeliveryOrderResult } from 'src/interfaces/delivery-order/delivery-order-result.interface';
import { IDeliveryOrderModelPost } from 'src/interfaces/delivery-order-post/delivery-order-post-model.interface';
import { IDeliveryOrderPostResponse } from 'src/interfaces/delivery-order-post/delivery-order-post-response.interface';
import { IOrderBookerForDO } from 'src/interfaces/delivery-order/order-booker-do.interface';
import { IDODetail } from 'src/interfaces/delivery-order/do-detail.interface';
import { IResponseModal } from 'src/interfaces/response-modal.interface';
import { IOrderMaster } from 'src/interfaces/order-master.interface';
import { IProductWiseOrderDetail } from 'src/interfaces/product-wise-order-detail';
import { IReturnReceiveModel } from 'src/interfaces/return-receive-model';
import { IDOResultWithDetail } from 'src/interfaces/delivery-order/do-result-with-detail.interface';

const getOrdersForDO = async (
    orderBookerId:number,
    dated:Date
  ): Promise<IDeliveryOrderResult> => {

    let params:string = '';
    
    params = concatParam(params,orderBookerId,'orderBookerId');

    if(dated !== undefined && dated != null){
      const formattedToDate = formattedDate(dated);
      params = concatParam(params,formattedToDate,'dated');
    }

    let url: string = `api/ProductOrder/OrdersForDO${params}`;

    let result = await http
      .get<IDeliveryOrderResult>(url)
      .catch(error => Promise.reject(error));
    return result.data;
  };

  const getDeliveryOrders = async (
    loginUserId:number,
    commaSeparatedUnitUniqueIds:string,
    detailFor:number,
    doId:number = null,
    doFromDate:Date = null,
    doToDate:Date = null,
    commaSeparatedDOStatusIds:string,
  ): Promise<IDOResultWithDetail> => {

    let params:string = '';
    params = concatParam(params,loginUserId,'loginUserId');
    params = concatParam(params,commaSeparatedUnitUniqueIds,'commaSeparatedUnitUniqueIds');
    params = concatParam(params,detailFor,'detailFor');

    if(doId !== undefined && doId != null){
      params = concatParam(params,doId,'doId');
    }

    if(doFromDate !== undefined && doFromDate != null){
      const formattedDoFromDate = formattedDate(doFromDate);
      params = concatParam(params,formattedDoFromDate,'doFromDate');
    }

    if(doToDate !== undefined && doToDate != null){
      const formattedDoToDate = formattedDate(doToDate);
      params = concatParam(params,formattedDoToDate,'doToDate');
    }

    params = concatParam(params,commaSeparatedDOStatusIds,'commaSeparatedDOStatusIds');

    let url: string = `api/DelieveryOrder/DODetail${params}`;

    let result = await http
      .get<IDOResultWithDetail>(url)
      .catch(error => Promise.reject(error));
    return result.data;
  };


  const saveDeliveryOrder = async (
    loggedInUserId:number,
    dated:Date,
    deliveryBoyId:number,
    cost:number,
    commaSeparatedOrderBookerIds:string,
    commaSeparatedRouteIds:string,
    unitUniqueId:string
  ): Promise<IDeliveryOrderPostResponse> => {

    let params:string = '';
    let costed = cost;
    params = concatParam(params,loggedInUserId,'loggedInUserId');

    if(dated !== undefined && dated != null){
      const formattedToDate = formattedDate(dated);
      params = concatParam(params,formattedToDate,'dated');
    }

    if(cost === undefined || cost == null){
      costed = 0;
    }

    params = concatParam(params,deliveryBoyId,'deliveryBoyId');
    params = concatParam(params,costed,'cost');

    
    if(!!commaSeparatedOrderBookerIds && commaSeparatedOrderBookerIds.trim() != ''){
      params = concatParam(params,commaSeparatedOrderBookerIds,'commaSeparatedOrderBookerIds');
    }

    if(!!commaSeparatedRouteIds && commaSeparatedRouteIds.trim() != ''){
      params = concatParam(params,commaSeparatedRouteIds,'commaSeparatedRouteIds');
    }

    params = concatParam(params,unitUniqueId,'unitUniqueId');

    let url: string = `api/DelieveryOrder${params}`;

    let result = await http
      .post<IDeliveryOrderPostResponse>(url)
      .catch(error => Promise.reject(error));
    return result.data;
  };

  const getOrderBookersForDO = async (
    dated:Date,
    unitUniqueId:string
  ): Promise<IOrderBookerForDO[]> => {

    let params:string = '';

    if(dated !== undefined && dated != null){
      const formattedToDate = formattedDate(dated);
      params = concatParam(params,formattedToDate,'dated');
    }

    params = concatParam(params,unitUniqueId,'unitUniqueId');

    let url: string = `api/DelieveryOrder/OrderBookerForDO${params}`;

    let result = await http
      .get<IOrderBookerForDO[]>(url)
      .catch(error => Promise.reject(error));
    return result.data;
  };

  const deleteDOAndItsDetails = async (
    doId:number
  ): Promise<number> => {

    let params:string = '';
    params = concatParam(params,doId,'doId');

    let url: string = `api/DelieveryOrder/DeleteDoAndItsDetails${params}`;

    let result = await http
      .post<number>(url)
      .catch(error => Promise.reject(error));
    return result.data;
  };

  const saveIssuedStock = async (
    loggedInUserId:number,
    doId:number
  ): Promise<IResponseModal> => {

    let params:string = '';
    params = concatParam(params,doId,'doId');
    params = concatParam(params,loggedInUserId,'loggedInUserId');

    let url: string = `api/DelieveryOrder/IssueStock${params}`;

    let result = await http
      .post<IResponseModal>(url)
      .catch(error => Promise.reject(error));
    return result.data;
  };

  const saveReturnReceiving = async (
    loggedInUserId:number,
    doId:number,
    returnReceiveModel:IReturnReceiveModel
  ): Promise<IResponseModal> => {

    let params:string = '';
    params = concatParam(params,doId,'doId');
    params = concatParam(params,loggedInUserId,'loggedInUserId');

    let url: string = `api/DelieveryOrder/PostReturn${params}`;

    let result = await http
      .post<IResponseModal>(url,returnReceiveModel)
      .catch(error => Promise.reject(error));
    return result.data;
  };

  const getOrderDetailsForStockReturn = async (
    doId:number,
    orderId:number
  ): Promise<IOrderMaster> => {

    let params:string = '';

    params = concatParam(params,doId,'doId');
    params = concatParam(params,orderId,'orderId');

    let url: string = `api/DelieveryOrder/OrderDetailForReturn${params}`;

    let result = await http
      .get<IOrderMaster>(url)
      .catch(error => Promise.reject(error));
    return result.data;
  };

  const getDeliveryOrdersForReturnReceive = async (
    loggedInUserId:number,
    commaSeparatedUnitUniqueIds:string
  ): Promise<IDODetail[]> => {

    
    let params = '';
    params = concatParam(params,loggedInUserId,'loggedInUserId');
    params = concatParam(params,commaSeparatedUnitUniqueIds,'commaSeparatedUnitUniqueIds');
    let url: string = `api/DelieveryOrder/DODetailReturnReceive${params}`;

    let result = await http
      .get<IDODetail[]>(url)
      .catch(error => Promise.reject(error));
    return result.data;
  };

  const getDeliveryOrdersForStockIssuance = async (
    loggedInUserId:number,
    commaSeparatedUnitUniqueIds:string
    ): Promise<IDODetail[]> => {
  
      
      let param = '';
      param = concatParam(param,loggedInUserId,'loggedInUserId');
      param = concatParam(param,commaSeparatedUnitUniqueIds,'commaSeparatedUnitUniqueIds');
      let url: string = `api/DelieveryOrder/DODetailStockIssuance${param}`;
  
      let result = await http
        .get<IDODetail[]>(url)
        .catch(error => Promise.reject(error));
      return result.data;
    };

    const getDeliveryOrdersForCashReceive = async (
      loggedInUserId:number,
      commaSeparatedUnitUniqueIds:string
      ): Promise<IDODetail[]> => {
    
        let params = '';
        params = concatParam(params,loggedInUserId,'loggedInUserId');
        params = concatParam(params,commaSeparatedUnitUniqueIds,'commaSeparatedUnitUniqueIds');
        let url: string = `api/DelieveryOrder/DODetailCashReceive${params}`;
    
        let result = await http
          .get<IDODetail[]>(url)
          .catch(error => Promise.reject(error));
        return result.data;
      };
  
  const deliveryOrderApi = {
    getOrdersForDO,
    saveDeliveryOrder,
    getOrderBookersForDO,
    getDeliveryOrders,
    deleteDOAndItsDetails,
    saveIssuedStock,
    getOrderDetailsForStockReturn,
    saveReturnReceiving
  };
  
  export {deliveryOrderApi};
