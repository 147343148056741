import {UserMenuActionConstants} from '../actions/action.constant';
import {IAction} from '../actions/action.interface';
import {IUserMenu} from '../../interfaces/routes/user-menu.interface';
import {initialState} from '../initial-state';

export const userMenuReducer = (
  state = initialState.userMenu,
  action: IAction<IUserMenu[]>,
) => {
  switch (action.type) {
    case UserMenuActionConstants.SET_USER_MENU:
      let newState: IUserMenu[] = [...action.payload];
      return newState;
    default:
      return state;
  }
};
