import { IAction } from './action.interface';
import { NewRouteSetupConstant } from './action.constant';
import { IApplicationState } from '../application-state.interface';
import { getCopy } from 'src/utility';
import { areaApi, routeApi } from 'src/http';
import { UnAssignedOrderBookerId } from 'src/app.constant';
import { IRoute } from 'src/interfaces/route.interface';
import { IRoutePoly } from 'src/interfaces/route-poly.interface';
import { IMapPopUpConfirmation } from 'src/interfaces/map-pop-confirmation.interface';
import { ISelectedRouteIdOnMap } from 'src/interfaces/selected-route-id-on-map.interface';
import { IMessage } from 'src/interfaces/message.interface';
import { IAreaRoutePoly } from 'src/interfaces/area-route-poly.interface';
import { IAreaWiseAreaPoly } from 'src/interfaces/area-wise-area-poly.interface';
import { IAreaExistingShops } from 'src/interfaces/area-existing-shops.interface';
import { IAreaNewShops } from 'src/interfaces/area-new-shops.interface';
import { IAreaPoly } from 'src/interfaces/area-poly.interface';
import { IMapShop } from 'src/interfaces/map-shop.interface';

export const setSelectedRouteToModifyAction = (
    payload: IRoute,
): IAction<IRoute> => {
    return {
        type: NewRouteSetupConstant.SET_SELECTED_ROUTE_TO_MODIFY,
        payload,
    };
};

export const setRoutePoliesAction = (
    payload: IAreaRoutePoly,
): IAction<IAreaRoutePoly> => {
    return {
        type: NewRouteSetupConstant.SET_ROUTE_POLIES,
        payload,
    };
};

export const setAreaPoliesAction = (
    payload: IAreaWiseAreaPoly,
): IAction<IAreaWiseAreaPoly> => {
    return {
        type: NewRouteSetupConstant.SET_AREA_POLIES,
        payload,
    };
};

export const setExistingShopsAction = (
    payload: IAreaExistingShops,
): IAction<IAreaExistingShops> => {
    return {
        type: NewRouteSetupConstant.SET_AREA_EXISTING_SHOPS,
        payload,
    };
};

export const setNewShopsAction = (
    payload: IAreaNewShops,
): IAction<IAreaNewShops> => {
    return {
        type: NewRouteSetupConstant.SET_AREA_NEW_SHOPS,
        payload,
    };
};

export const setIsDeletingRouteAction = (
    payload: boolean,
): IAction<boolean> => {
    return {
        type: NewRouteSetupConstant.SET_IS_DELETING_ROUTE,
        payload,
    };
};

export const setIsLoadingAreaPoliesAction = (
    payload: boolean,
): IAction<boolean> => {
    return {
        type: NewRouteSetupConstant.SET_IS_LOADIN_AREA_POLIES,
        payload,
    };
};

export const setIsLoadingRoutePoliesAction = (
    payload: boolean,
): IAction<boolean> => {
    return {
        type: NewRouteSetupConstant.SET_IS_LOADING_ROUTE_POLIES,
        payload,
    };
};

export const setIsLoadingExistingShopsAction = (
    payload: boolean,
): IAction<boolean> => {
    return {
        type: NewRouteSetupConstant.SET_IS_LOADING_EXISTING_SHOPS,
        payload,
    };
};

export const setIsLoadingNewShopsAction = (
    payload: boolean,
): IAction<boolean> => {
    return {
        type: NewRouteSetupConstant.SET_IS_LOADING_NEW_SHOPS,
        payload,
    };
};

export const resetNewRouteSetupPageMessageAction = (): IAction<IMessage | null> => {
    return {
        type: NewRouteSetupConstant.SET_NEW_ROUTE_SETUP_PAGE_MESSAGE,
        payload:null,
    };
};

export const setNewRouteSetupPageSuccessMessageAction = (
    payload: string | null,
): IAction<IMessage | null> => {
    return {
        type: NewRouteSetupConstant.SET_NEW_ROUTE_SETUP_PAGE_MESSAGE,
        payload: !!payload && payload.trim() !== '' ? {
            messageText:payload,
            variant:'success'
        } : null,
    };
};

export const softReloadRoutePoliesAction = (areaId: number) => {
    return (dispatch, getState) => {
        if (areaId > 0) {
            const state: IApplicationState = getState();

            if (!!state.routePolies) {
                const existingInfo = getCopy(state.routePolies);
                if (existingInfo.findIndex(m => m.areaId === areaId) >= 0) {
                    dispatch(setRoutePoliesAction(existingInfo.find(t => t.areaId === areaId)));
                } else {
                    loadRoutePoliesFromDatabase(areaId, dispatch);
                }
            } else {
                loadRoutePoliesFromDatabase(areaId, dispatch);
            }
        }
    }
}



export const hardReloadRoutePoliesAction = (areaId: number) => {
    return (dispatch, getState) => {
        if (areaId > 0) {
            loadRoutePoliesFromDatabase(areaId, dispatch);
        }
    }
}

const loadRoutePoliesFromDatabase = (areaId: number, dispatch: any) => {
    dispatch(setIsLoadingRoutePoliesAction(true))
    routeApi.getRoutePolies(areaId)
        .then((data: IRoutePoly[]) => {
            dispatch(setRoutePoliesAction({ areaId: areaId, routePolies: (data ?? []) }));
            dispatch(setIsLoadingRoutePoliesAction(false))
        })
        .catch(e => {
            dispatch(setRoutePoliesAction({ areaId: areaId, routePolies: [] }));
            dispatch(setIsLoadingRoutePoliesAction(false))
        });
}

export const softReloadAreaPoliesAction = (areaId: number) => {
    return (dispatch, getState) => {
        if (areaId > 0) {
            const state: IApplicationState = getState();

            if (!!state.areaPolies) {
                const existingInfo = getCopy(state.areaPolies);
                if (existingInfo.findIndex(m => m.areaId === areaId) >= 0) {
                    dispatch(setAreaPoliesAction(existingInfo.find(t => t.areaId === areaId)));
                } else {
                    loadAreaPoliesFromDatabase(areaId, dispatch);
                }
            } else {
                loadAreaPoliesFromDatabase(areaId, dispatch);
            }
        }
    }
}



export const hardReloadAreaPoliesAction = (areaId: number) => {
    return (dispatch, getState) => {
        if (areaId > 0) {
            loadAreaPoliesFromDatabase(areaId, dispatch);
        }
    }
}

const loadAreaPoliesFromDatabase = (areaId: number, dispatch: any) => {
    dispatch(setIsLoadingAreaPoliesAction(true))
    areaApi.getAreaPolies(areaId)
        .then((data: IAreaPoly[]) => {
            dispatch(setAreaPoliesAction({ areaId: areaId, areaPolies: (data ?? []) }));
            dispatch(setIsLoadingAreaPoliesAction(false))
        })
        .catch(e => {
            dispatch(setAreaPoliesAction({ areaId: areaId, areaPolies: [] }));
            dispatch(setIsLoadingAreaPoliesAction(false))
        });
}

export const softReloadExistingShopsAction = (areaId: number) => {
    return (dispatch, getState) => {
        if (areaId > 0) {
            const state: IApplicationState = getState();

            if (!!state.existingShops) {
                const existingInfo = getCopy(state.existingShops);
                if (existingInfo.findIndex(m => m.areaId === areaId) >= 0) {
                    dispatch(setExistingShopsAction(existingInfo.find(t => t.areaId === areaId)));
                } else {
                    loadExistingShopsFromDatabase(areaId, dispatch);
                }
            } else {
                loadExistingShopsFromDatabase(areaId, dispatch);
            }
        }
    }
}



export const hardReloadExistingShopsAction = (areaId: number) => {
    return (dispatch, getState) => {
        if (areaId > 0) {
            loadExistingShopsFromDatabase(areaId, dispatch);
        }
    }
}

const loadExistingShopsFromDatabase = (areaId: number, dispatch: any) => {
    dispatch(setIsLoadingExistingShopsAction(true))
    areaApi.getAreaExistingShops(areaId)
        .then((data: IMapShop[]) => {
            dispatch(setExistingShopsAction({ areaId: areaId, existingShops: (data ?? []) }));
            dispatch(setIsLoadingExistingShopsAction(false))
        })
        .catch(e => {
            dispatch(setExistingShopsAction({ areaId: areaId, existingShops: [] }));
            dispatch(setIsLoadingExistingShopsAction(false))
        });
}

export const softReloadNewShopsAction = (areaId: number) => {
    return (dispatch, getState) => {
        if (areaId > 0) {
            const state: IApplicationState = getState();

            if (!!state.newShops) {
                const existingInfo = getCopy(state.newShops);
                if (existingInfo.findIndex(m => m.areaId === areaId) >= 0) {
                    dispatch(setNewShopsAction(existingInfo.find(t => t.areaId === areaId)));
                } else {
                    loadNewShopsFromDatabase(areaId, dispatch);
                }
            } else {
                loadNewShopsFromDatabase(areaId, dispatch);
            }
        }
    }
}



export const hardReloadNewShopsAction = (areaId: number) => {
    return (dispatch, getState) => {
        if (areaId > 0) {
            loadNewShopsFromDatabase(areaId, dispatch);
        }
    }
}

const loadNewShopsFromDatabase = (areaId: number, dispatch: any) => {
    dispatch(setIsLoadingNewShopsAction(true))
    areaApi.getAreaNewShops(areaId)
        .then((data: IMapShop[]) => {
            dispatch(setNewShopsAction({ areaId: areaId, newShops: (data ?? []) }));
            dispatch(setIsLoadingNewShopsAction(false))
        })
        .catch(e => {
            dispatch(setNewShopsAction({ areaId: areaId, newShops: [] }));
            dispatch(setIsLoadingNewShopsAction(false))
        });
}

export const setNewRouteSetupPageErrorMessageAction = (
    payload: string | null,
): IAction<IMessage | null> => {
    return {
        type: NewRouteSetupConstant.SET_NEW_ROUTE_SETUP_PAGE_MESSAGE,
        payload: !!payload && payload.trim() !== '' ? {
            messageText:payload,
            variant:'error'
        } : null,
    };
};

export const setConfirmationMessageOnMapAction = (
    payload: IMapPopUpConfirmation | null,
): IAction<IMapPopUpConfirmation | null> => {
    return {
        type: NewRouteSetupConstant.SET_CONFIRMATION_MESSAGE_POP_UP,
        payload,
    };
};

export const setIsSavingNewRouteAction = (
    payload: boolean,
): IAction<boolean> => {
    return {
        type: NewRouteSetupConstant.SET_CONFIRMATION_MESSAGE_POP_UP,
        payload,
    };
};

export const setSelectedRouteIdOnMapAction = (
    payload: ISelectedRouteIdOnMap,
): IAction<ISelectedRouteIdOnMap> => {
    return {
        type: NewRouteSetupConstant.SET_SELECTED_ROUTE_ID_ON_MAP,
        payload,
    };
};