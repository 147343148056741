import {IProductOrder} from '../interfaces/product-order.interface';
import {http} from './http-base';
import {concatParam, formattedDate} from '../utility'
import { IEnum } from 'src/interfaces';
import { IResult } from 'src/interfaces/result.interface';
import { IUnitSaleReportResult } from 'src/interfaces/unit-sale-report-result.interface';
import { IOrderBookerSaleReportResult } from 'src/interfaces/order-booker-sale-report-result.interface';
import { IProductSaleAnalytics } from 'src/interfaces/product-sale-analytics.interface';
import { ITrackingResult } from 'src/interfaces/tracking/tracking-result.interface';
import { IOrderNew } from 'src/interfaces/reports/order-new.interface';

const getOrders = async (
    orderNumber: number | undefined,
    orderBookerId:number | undefined,
    routeId:number | undefined,
    shopId:number | undefined,
    fromDate:Date | undefined,
    toDate:Date | undefined,
    orderStatusId:number | undefined,
    pageNumber:number,
    pageSize:number
  ): Promise<IResult<IProductOrder>> => {

    let params:string = '';
    if(!isNaN(orderNumber) && orderNumber > 0){
      params = concatParam(params,orderNumber,'orderNumber');
    }
    
    params = concatParam(params,orderBookerId,'orderBookerId');
    params = concatParam(params,routeId,'routeId');
    params = concatParam(params,shopId,'shopId');

    if(fromDate !== undefined && fromDate != null){
      const formattedFromDate = formattedDate(fromDate);
      params = concatParam(params,formattedFromDate,'fromDate');
    }

    if(toDate !== undefined && toDate != null){
      const formattedToDate = formattedDate(toDate);
      params = concatParam(params,formattedToDate,'toDate');
    }
    

    params = concatParam(params,orderStatusId,'orderStatusId');
    params = concatParam(params,pageNumber,'pageNumber');
    params = concatParam(params,pageSize,'pageSize');

    let url: string = `api/ProductOrder/ordersview${params}`;

    let result = await http
      .get<IResult<IProductOrder>>(url)
      .catch(error => Promise.reject(error));
    return result.data;
  };

  const downloadInvoicesZip = async (
    orderNumber: number | undefined,
    orderBookerId:number | undefined,
    routeId:number | undefined,
    shopId:number | undefined,
    fromDate:Date | undefined,
    toDate:Date | undefined,
    orderStatusId:number | undefined
  ): Promise<string> => {

    let params:string = '';
    if(!isNaN(orderNumber) && orderNumber > 0){
      params = concatParam(params,orderNumber,'orderNumber');
    }
    
    params = concatParam(params,orderBookerId,'orderBookerId');
    params = concatParam(params,routeId,'routeId');
    params = concatParam(params,shopId,'shopId');

    if(fromDate !== undefined && fromDate != null){
      const formattedFromDate = formattedDate(fromDate);
      params = concatParam(params,formattedFromDate,'fromDate');
    }

    if(toDate !== undefined && toDate != null){
      const formattedToDate = formattedDate(toDate);
      params = concatParam(params,formattedToDate,'toDate');
    }
    

    params = concatParam(params,orderStatusId,'orderStatusId');

    let url: string = `api/ProductOrder/invoices${params}`;

    let result = await http
      .get<string>(url)
      .catch(error => Promise.reject(error));
    return result.data;
  };

  const downloadInvoicesDocument = async (
    doId:number
  ): Promise<string> => {

    let params:string = '';
    
    params = concatParam(params,doId,'doId');

    let url: string = `api/ProductOrder/invoicesfordo${params}`;

    let result = await http
      .get<string>(url)
      .catch(error => Promise.reject(error));
    return result.data;
  };

  const getOrderStatuses = async (): Promise<IEnum[]> => {

    let url: string = `api/ProductOrderStatus/search`;

    let result = await http
      .get<IEnum[]>(url)
      .catch(error => Promise.reject(error));
    return result.data;
  };

  const getUnitSaleReport = async (
    loginUserId: number,
    commaSeparatedUnitUniqueIds:string,
    fromDate:Date,
    toDate:Date
  ): Promise<IUnitSaleReportResult> => {

    let params:string = '';
    
    params = concatParam(params,loginUserId,'loginUserId');
    params = concatParam(params,commaSeparatedUnitUniqueIds,'commaSeparatedUnitUniqueIds');

    if(fromDate !== undefined && fromDate != null){
      const formattedFromDate = formattedDate(fromDate);
      params = concatParam(params,formattedFromDate,'fromDate');
    }

    if(toDate !== undefined && toDate != null){
      const formattedToDate = formattedDate(toDate);
      params = concatParam(params,formattedToDate,'toDate');
    }

    let url: string = `api/ProductOrder/OrdersDeliveredReport${params}`;

    let result = await http
      .get<IUnitSaleReportResult>(url)
      .catch(error => Promise.reject(error));
    return result.data;
  };

  const getOrderBookerSaleReport = async (
    loginUserId: number,
    unitUniqueId:string,
    fromDate:Date,
    toDate:Date
  ): Promise<IOrderBookerSaleReportResult> => {

    let params:string = '';
    
    params = concatParam(params,loginUserId,'loginUserId');
    params = concatParam(params,unitUniqueId,'unitUniqueId');

    if(fromDate !== undefined && fromDate != null){
      const formattedFromDate = formattedDate(fromDate);
      params = concatParam(params,formattedFromDate,'fromDate');
    }

    if(toDate !== undefined && toDate != null){
      const formattedToDate = formattedDate(toDate);
      params = concatParam(params,formattedToDate,'toDate');
    }

    let url: string = `api/ProductOrder/OrderBookerOrdersDeliveredReport${params}`;

    let result = await http
      .get<IOrderBookerSaleReportResult>(url)
      .catch(error => Promise.reject(error));
    return result.data;
  };

  const getOrderBookerProductSaleReport = async (
    loginUserId: number,
    unitUniqueId:string,
    orderBookerEmployeeId:number,
    fromDate:Date,
    toDate:Date
  ): Promise<IProductSaleAnalytics[]> => {

    let params:string = '';
    
    params = concatParam(params,loginUserId,'loginUserId');
    params = concatParam(params,unitUniqueId,'unitUniqueId');
    params = concatParam(params,orderBookerEmployeeId,'orderBookerEmployeeId');

    if(fromDate !== undefined && fromDate != null){
      const formattedFromDate = formattedDate(fromDate);
      params = concatParam(params,formattedFromDate,'fromDate');
    }

    if(toDate !== undefined && toDate != null){
      const formattedToDate = formattedDate(toDate);
      params = concatParam(params,formattedToDate,'toDate');
    }

    let url: string = `api/ProductOrder/OrderBookerProductsSaleReport${params}`;

    let result = await http
      .get<IProductSaleAnalytics[]>(url)
      .catch(error => Promise.reject(error));
    return result.data;
  };

  const getTrackingResult = async (
    loginUserId: number,
    orderBookerId:number,
    dated:Date
  ): Promise<ITrackingResult> => {

    let params:string = '';
    
    params = concatParam(params,orderBookerId,'orderBookerId');
    params = concatParam(params,loginUserId,'loggedInUserId');

    if(dated !== undefined && dated != null){
      const formattedFromDate = formattedDate(dated);
      params = concatParam(params,formattedFromDate,'dated');
    }

    let url: string = `api/ProductOrder/TrackingDetails${params}`;

    let result = await http
      .get<ITrackingResult>(url)
      .catch(error => Promise.reject(error));
    return result.data;
  };

  const getNewOrdersReport = async (
    loginUserId: number,
    commaSeparatedUnitUniqueIds:string,
    fromDate:Date,
    toDate:Date,
    orderBookerId:number | null
  ): Promise<IOrderNew[]> => {

    let params:string = '';
    
    params = concatParam(params,loginUserId,'loginUserId');
    params = concatParam(params,commaSeparatedUnitUniqueIds,'commaSeparatedUnitUniqueIds');

    if(fromDate !== undefined && fromDate != null){
      const formattedFromDate = formattedDate(fromDate);
      params = concatParam(params,formattedFromDate,'fromDate');
    }

    if(toDate !== undefined && toDate != null){
      const formattedToDate = formattedDate(toDate);
      params = concatParam(params,formattedToDate,'toDate');
    }

    if(!!orderBookerId){
      params = concatParam(params,orderBookerId,'orderBookerId');
    }

    let url: string = `api/ProductOrder/OrdersNew${params}`;

    let result = await http
      .get<IOrderNew[]>(url)
      .catch(error => Promise.reject(error));
    return result.data;
  };
  
  const orderApi = {
    getOrders,
    getOrderStatuses,
    downloadInvoicesZip,
    downloadInvoicesDocument,
    getUnitSaleReport,
    getOrderBookerSaleReport,
    getOrderBookerProductSaleReport,
    getTrackingResult,
    getNewOrdersReport
  };
  
  export {orderApi};
