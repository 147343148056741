import React from 'react';
import { masterDataApi } from 'src/http';
import { formatedDateTime, getCopy, stableSort, timeAgo } from 'src/utility';
import { confirmAction } from 'src/utility/confirm.util';
import { fCommaSeparated } from 'src/utils/formatNumber';
import { Card, Container, Table, TableBody, TableCell, TableContainer, TableRow, CircularProgress } from '@mui/material';
import { CustomAutoComplete } from 'src/components/common/CustomAutoComplete';
import { IAutoComplete } from 'src/interfaces/auto-complete.interface';
import { Icon } from '@iconify/react';
import { UserListHead } from 'src/components/_dashboard/user';
import { IRouteShopDetail } from 'src/interfaces/route-shop-detail.interface';
import { ShopRouteChangePopup } from 'src/components/ShopRouteChangePopup';
import './component-styles/shops-component.css';
import { Highlighted } from './common/highlighted.component';
import { IShopRouteChangeParam } from 'src/interfaces/shop-route-change-param.interface';
import { shopApi } from 'src/http/shop.http';
import { EditShopPopup } from './EditShopPopup.component';
import { IShopUpdateRequest } from 'src/interfaces/shop-update-request.interface';
import { isRestrictedLogin } from 'src/app.constant';

type Order = 'asc' | 'desc';



type Filters = 'RouteShops' | 'WithSale' | 'OtherShops' | 'TotalShops' | 'WholeSaleShops' | 'RetailShops' | 'CoveredShops' | 'NeverCoveredShops' | 'CreditShops' | 'RouteShopsInOtherRoutes';

interface IProps {
    areaId: number;
    loginUserId: number;
    shops: IRouteShopDetail[];
    filter: Filters | null;
    isLoading: boolean;
    searchText?: string;
    isRouteDetailPage?: boolean;
    selectedRouteId?: number;
    setFilter?: (f: Filters | null) => void;
    showErrorMessage?: (message: string) => void;
    showInfoMessage?: (message: string) => void;
    showSuccessMessage?: (message: string) => void;
    showWarningMessage?: (message: string) => void;
    setShopsOnAction: (shops: IRouteShopDetail[]) => void;
}

export const ShopsComponent = (props: IProps) => {

    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof IRouteShopDetail>(null);
    const [searchText, setSearchText] = React.useState<string>('');
    const [changeRouteParam, setChangeRouteParam] = React.useState<IShopRouteChangeParam>();
    const [selectedShopIdToUpdateCreditStatus, setSelectedShopIdToUpdateCreditStatus] = React.useState<number>(0);
    const [selectedStatusId, setSelectedStatusId] = React.useState<number>(0);
    const [selectedStatusText, setSelectedStatusText] = React.useState<string>('');
    const [creditStatuses, setCreditStatuses] = React.useState<IAutoComplete[]>([]);
    const [isCreditStatusesLoading, setIsCreditStatusesLoading] = React.useState<boolean>(false);
    const [shopForEdit, setShopForEdit] = React.useState<IRouteShopDetail>(null);
    const coveredShort = 'Covrd.';
    const neverCoveredShort = 'Nvr.Cvrd.';
    const isRestrictedUser = isRestrictedLogin(props.loginUserId);

    const UN_RESTRICTED_SHOP_TABLE_HEAD = [
        { id: 'action_button_chevron', label: '', alignContent: 'left', enableSort: false },
        { id: 'ShopId', label: 'Shop Id', alignContent: 'left', enableSort: true },
        { id: 'ShopName', label: 'Name', alignContent: 'left', enableSort: true },
        { id: 'Route', label: 'Route', alignContent: 'left', enableSort: true },
        { id: 'IsCovered', label: 'Status', alignContent: 'left', enableSort: true },
        { id: 'LastOrderedOn', label: 'Last Order', alignContent: 'left', enableSort: true },
        { id: 'LastVisitedOn', label: 'Last Visited', alignContent: 'left', enableSort: true },
        { id: 'TotalSale', label: 'T-Sale', alignContent: 'left', enableSort: true },
        { id: 'PendingCredit', label: 'Pend. Crdt.', alignContent: 'left', enableSort: true },
        { id: 'action_button', label: '', alignContent: 'right', enableSort: false },
    ];

    const RESTRICTED_SHOP_TABLE_HEAD = [
        { id: 'action_button_chevron', label: '', alignContent: 'left', enableSort: false },
        { id: 'ShopId', label: 'Shop Id', alignContent: 'left', enableSort: true },
        { id: 'ShopName', label: 'Name', alignContent: 'left', enableSort: true },
        { id: 'Route', label: 'Route', alignContent: 'left', enableSort: true },
        { id: 'IsCovered', label: 'Status', alignContent: 'left', enableSort: true },
        { id: 'LastOrderedOn', label: 'Last Order', alignContent: 'left', enableSort: true },
        { id: 'LastVisitedOn', label: 'Last Visited', alignContent: 'left', enableSort: true },
        { id: 'PendingCredit', label: 'Pend. Crdt.', alignContent: 'left', enableSort: true },
        { id: 'action_button', label: '', alignContent: 'right', enableSort: false },
    ];

    const SHOP_TABLE_HEAD = isRestrictedUser === true ? RESTRICTED_SHOP_TABLE_HEAD : UN_RESTRICTED_SHOP_TABLE_HEAD;


    const onShopCollapseChange = (recordId: string) => {
        let copy = getCopy(props.shops);
        let shopinfoToModify = copy.find(m => m.RecordId === recordId);
        shopinfoToModify.IsCollapsed = !shopinfoToModify.IsCollapsed;
        props.setShopsOnAction(copy);
    }

    const getCreditStatuses = () => {
        setIsCreditStatusesLoading(true);
        masterDataApi.getShopCreditStatuses()
            .then((data: IAutoComplete[]) => {
                setIsCreditStatusesLoading(false);
                setCreditStatuses([...data]);
            })
            .catch(() => {
                setIsCreditStatusesLoading(false);
                setCreditStatuses([]);
            })
    }

    const onCreditStatusChange = (option: IAutoComplete) => {
        setSelectedStatusId(option?.Value ?? 0);
        setSelectedStatusText(option?.DisplayText ?? '');
    }

    const onSaveCreditStatus = (shopId: number, creditStatusId: number, creditStatusText: string) => {
        shopApi.updateShopCreditStatus(shopId, creditStatusId)
            .then(() => {
                let shopsToModify = getCopy(props.shops);
                let shopToModify = shopsToModify.filter(m => m.ShopId === shopId);
                shopToModify.forEach(sh => {
                    sh.CreditStatus = creditStatusText;
                    sh.CreditStatusId = creditStatusId;
                });

                props.setShopsOnAction(shopsToModify);
                setSelectedStatusId(0);
                setSelectedShopIdToUpdateCreditStatus(0);
                setSelectedStatusText('');
            })
    }

    const onCancelCreditStatusUpdate = () => {
        setSelectedStatusId(0);
        setSelectedStatusText('');
        setSelectedShopIdToUpdateCreditStatus(0);
    }



    const onSelectShopToUpdateCreditStatus = (shopId: number, creditStatusId: number, creditStatusText: string) => {
        setSelectedStatusId(creditStatusId);
        setSelectedStatusText(creditStatusText);
        setSelectedShopIdToUpdateCreditStatus(shopId);
    }

    const onDeleteShop = (shopId: number) => {
        confirmAction('Shop Delete', 'Are you sure, you want to delete shop?', 'Yes', 'No','red','green','red','red')
            .then((isConfirmed: boolean) => {
                if (isConfirmed === true) {
                    shopApi.deActivateShop(props.loginUserId, shopId)
                        .then(() => {
                            let shopsWithoutDeletedShop = getCopy(props.shops).filter(m => m.ShopId !== shopId);
                            props.setShopsOnAction(shopsWithoutDeletedShop);
                        })
                }
            })
    }

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof IRouteShopDetail,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const orderedRows = React.useMemo(
        () => {
            let copy = getCopy(props.shops);

            copy = copy.filter(m =>
                (props.filter == null || props.filter !== 'RouteShops' || (m.IsRouteShop === true && m.IsInOtherRoute === false))
                &&
                (props.filter == null || props.filter !== 'OtherShops' || (m.IsOtherShop === true && m.IsInOtherRoute === false))
                &&
                (props.filter == null || props.filter !== 'TotalShops' || ((m.IsOtherShop === true || m.IsRouteShop === true) && m.IsInOtherRoute === false))
                &&
                (props.filter == null || props.filter !== 'WholeSaleShops' || (m.IsWholesale === true && m.IsInOtherRoute === false))
                &&
                (props.filter == null || props.filter !== 'RetailShops' || (m.IsWholesale === false && m.IsInOtherRoute === false))
                &&
                (props.filter == null || props.filter !== 'CoveredShops' || (m.IsCovered === true && m.IsInOtherRoute === false))
                &&
                (props.filter == null || props.filter !== 'NeverCoveredShops' || (m.IsCovered === false && m.IsInOtherRoute === false))
                &&
                (props.filter == null || props.filter !== 'CreditShops' || ((m.PendingCredit ?? 0) > 0 && m.IsInOtherRoute === false))
                &&
                (props.filter == null || props.filter !== 'WithSale' || ((m.TotalSale ?? 0) > 0 && m.IsInOtherRoute === false))
                &&
                (props.filter == null || props.filter !== 'RouteShopsInOtherRoutes' || m.IsInOtherRoute === true)
            );

            if (!!searchText && !!copy && copy.length > 0) {
                if (searchText.trim() !== '') {
                    copy = copy.filter(c =>
                        (c.ShopId !== undefined && c.ShopId != null && c.ShopId.toString().trim().indexOf(searchText) >= 0)
                        ||
                        (!!c.ShopName && c.ShopName.toLowerCase().indexOf(searchText.toLowerCase()) >= 0)
                        ||
                        (!!c.Route && c.Route.toLowerCase().indexOf(searchText.toLowerCase()) >= 0)
                        ||
                        (c.TotalSale !== undefined && c.TotalSale != null && c.TotalSale.toString().indexOf(searchText) >= 0)
                        ||
                        (c.PendingCredit !== undefined && c.PendingCredit != null && c.PendingCredit.toString().indexOf(searchText) >= 0)
                        ||
                        ((c.IsCovered ? coveredShort : neverCoveredShort).toLowerCase().indexOf(searchText.toLowerCase()) >= 0)
                        ||
                        (!!c.LastOrderedOn && (formatedDateTime(c.LastOrderedOn, 'DD-MMM-YYYY')).toLowerCase().indexOf(searchText.toLowerCase()) >= 0)
                        ||
                        (!!c.LastVisitedOn && (formatedDateTime(c.LastVisitedOn, 'DD-MMM-YYYY')).toLowerCase().indexOf(searchText.toLowerCase()) >= 0)
                        ||
                        (!!c.LastOrderedOn && (timeAgo(c.LastOrderedOn)).toLowerCase().indexOf(searchText.toLowerCase()) >= 0)
                        ||
                        (!!c.LastVisitedOn && (timeAgo(c.LastVisitedOn)).toLowerCase().indexOf(searchText.toLowerCase()) >= 0)
                    );
                }
            }

            if (!!orderBy) {
                return stableSort(copy, order, orderBy)
            } else {
                return copy;
            }
        },
        [order, orderBy, searchText, props.shops, props.filter],
    );

    const handleShopRouteChange = (shopId: number, toOrderBookerId: number, toOrderBookerName: string, toRouteId: number, toRouteName: string, toRouteDayNumber: number, toRouteDay: string) => {
        setChangeRouteParam(null);
        let shopsToModify = getCopy(props.shops);
        let shopsToUpdate = shopsToModify.filter(m => m.ShopId === shopId);

        if(!!shopsToUpdate && shopsToUpdate.length > 0){
            shopsToUpdate.forEach(shopToUpdate => {
                if (props.isRouteDetailPage === true) {
                    if(shopToUpdate.IsOtherShop === true){
                        shopsToModify = shopsToModify.filter(m => m.ShopId !== shopId);
                    }else if(shopToUpdate.IsRouteShop === true){
                        shopToUpdate.OrderBookerId = toOrderBookerId;
                            shopToUpdate.OrderBookerName = toOrderBookerName;
                            shopToUpdate.RouteId = toRouteId;
                            shopToUpdate.RouteDayNumber = toRouteDayNumber;
                            shopToUpdate.RouteDay = toRouteDay;
                            shopToUpdate.Route = toRouteName;
                            shopToUpdate.IsRouteShop = false;
                            shopToUpdate.IsInOtherRoute = true;
                    }else{
                        if(!!props.selectedRouteId && props.selectedRouteId > 0 && toRouteId === props.selectedRouteId){
                            shopToUpdate.OrderBookerName = toOrderBookerName;
                            shopToUpdate.RouteId = toRouteId;
                            shopToUpdate.RouteDayNumber = toRouteDayNumber;
                            shopToUpdate.RouteDay = toRouteDay;
                            shopToUpdate.Route = toRouteName;
                            shopToUpdate.IsRouteShop = true;
                            shopToUpdate.IsInOtherRoute = false;
                        }else{
                            shopToUpdate.OrderBookerId = toOrderBookerId;
                            shopToUpdate.OrderBookerName = toOrderBookerName;
                            shopToUpdate.RouteId = toRouteId;
                            shopToUpdate.RouteDayNumber = toRouteDayNumber;
                            shopToUpdate.RouteDay = toRouteDay;
                            shopToUpdate.Route = toRouteName;
                        }
                    }
                } else {
                    if (!!props.selectedRouteId && props.selectedRouteId > 0) {
                        if (toRouteId === props.selectedRouteId) {
                            shopToUpdate.OrderBookerId = toOrderBookerId;
                            shopToUpdate.OrderBookerName = toOrderBookerName;
                            shopToUpdate.RouteId = toRouteId;
                            shopToUpdate.RouteDayNumber = toRouteDayNumber;
                            shopToUpdate.RouteDay = toRouteDay;
                            shopToUpdate.Route = toRouteName;
                        } else {
                            shopsToModify = shopsToModify.filter(m => m.ShopId !== shopId);
                        }
                    } else {
                        shopToUpdate.OrderBookerId = toOrderBookerId;
                        shopToUpdate.OrderBookerName = toOrderBookerName;
                        shopToUpdate.RouteId = toRouteId;
                        shopToUpdate.RouteDayNumber = toRouteDayNumber;
                        shopToUpdate.RouteDay = toRouteDay;
                        shopToUpdate.Route = toRouteName;
                    }
                }
            })
        }

        props.setShopsOnAction(shopsToModify);
    }

    const onShopUpdateSuccess = (updatedValues: IShopUpdateRequest) => {
        setShopForEdit(null);
        let shopsToModify = getCopy(props.shops);
        let shopToUpdateLocally = shopsToModify.filter(m => m.ShopId === updatedValues.ShopId);
        shopToUpdateLocally.forEach(stuc => {
            stuc.ShopName = updatedValues.ShopName;
            stuc.CellOne = updatedValues.CellOne;
            stuc.CellTwo = updatedValues.CellTwo;
            stuc.ShopAddress = updatedValues.ShopAddress;
            stuc.ShopTypeId = updatedValues.ShopTypeId;
            stuc.ShopType = updatedValues.ShopType;
        });

        props.setShopsOnAction(shopsToModify);
    }

    const onSearchChange = (text: string) => {
        setSearchText(text ?? '');

        if (!!text && text.trim() !== '' && props.setFilter) {
            props.setFilter(null);
        }
    }

    React.useEffect(() => {
        if (!props.shops || props.shops.length === 0) {
            setSearchText('');
            setOrderBy(null);
        }

        setSelectedShopIdToUpdateCreditStatus(0);
        setSelectedStatusId(0);
        setSelectedStatusText('');
    }, [props.shops, props.filter]);

    React.useEffect(() => {
        getCreditStatuses();
    }, []);

    return <React.Fragment>
        {<Container style={{ paddingBottom: 10, maxWidth: '100%' }}>
            <Card>
                {<TableContainer sx={{ minWidth: 800, maxWidth: '100%' }}>
                    <Table sx={{ borderSpacing: '0px 3px' }} size="small" stickyHeader>
                        <UserListHead
                            order={order}
                            orderBy={orderBy}
                            headLabel={SHOP_TABLE_HEAD}
                            rowCount={0}
                            numSelected={0}
                            onRequestSort={handleRequestSort}
                            onSelectAllClick={undefined}
                            customKey={`TableHeadRow_6_Shops_Table`}
                            typographyVariant='subtitle2'
                            backgroundColor={'#4279a3'}
                            textColor='white'
                            showSearchBox={true}
                            searchText={searchText}
                            onSearchChange={onSearchChange}
                        />
                        <TableBody>
                            {props.isLoading === true && <TableRow
                                key={'LoadingRow'}
                                tabIndex={-1}
                                role="checkbox"
                                selected={false}
                                aria-checked={false}
                            >
                                <TableCell align='center' colSpan={SHOP_TABLE_HEAD.length}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>}
                            {props.isLoading === false && (!orderedRows || orderedRows.length <= 0) && <TableRow
                                key={'LoadingRow'}
                                tabIndex={-1}
                                role="checkbox"
                                selected={false}
                                aria-checked={false}
                            >
                                <TableCell align='center' colSpan={SHOP_TABLE_HEAD.length}>
                                    {`No Data${!!searchText && searchText.trim() !== '' && !!orderedRows && orderedRows.length > 0 ? ` For "${searchText}"` : ''}`}
                                </TableCell>
                            </TableRow>}
                            {!!orderedRows && props.isLoading === false && orderedRows.length > 0 && orderedRows.map((row, index) => {
                                return (
                                    <React.Fragment key={`Shop_Item_${row.RecordId}`}><TableRow
                                        hover
                                        key={row.ShopId as number}
                                        tabIndex={-1}
                                        role="checkbox"
                                        selected={false}
                                        aria-checked={false}
                                        className={row.IsCollapsed === true ? 'shop' : 'shop-selected'}
                                        onClick={() => onShopCollapseChange(row.RecordId)}
                                    >
                                        <TableCell padding="none" width="1%" align="left" style={{ paddingLeft: 10 }}>
                                            {row.IsCollapsed === false && <Icon color='black' style={{ cursor: 'pointer' }} width={25} height={25} icon={'mdi:chevron-up'} />}
                                            {row.IsCollapsed === true && <Icon color='black' style={{ cursor: 'pointer' }} width={25} height={25} icon={'mdi:chevron-down'} />}
                                        </TableCell>
                                        <TableCell width="8%" align="left">
                                            <Highlighted text={`${row.ShopId}`} highlight={!!searchText && searchText.trim() !== '' ? searchText : (!!props.searchText && props.searchText.trim() !== '' ? props.searchText.trim() : '')} />
                                        </TableCell>
                                        <TableCell width={'10%'} align="left" style={row.IsCollapsed === false ? { color: 'blue', fontWeight: 'bold' } : {}}>
                                            <Highlighted text={`${row.ShopName}`} highlight={!!searchText && searchText.trim() !== '' ? searchText : (!!props.searchText && props.searchText.trim() !== '' ? props.searchText.trim() : '')} />
                                        </TableCell>
                                        <TableCell width={'20%'} align="left">

                                            <Highlighted text={`${row.IsCollapsed === true ? `${!!row.Route && row.Route.trim() !== '' ? row.Route : 'N/A'}` : ''}`} highlight={searchText} />
                                        </TableCell>
                                        <TableCell width="8%" align="left" style={{ color: row.IsCovered ? 'green' : 'red' }}>
                                            <Highlighted text={`${row.IsCollapsed === true ? `${row.IsCovered ? coveredShort : neverCoveredShort}` : ''}`} highlight={searchText} />
                                        </TableCell>
                                        <TableCell width="10%" align="left" >
                                            <Highlighted text={`${row.IsCollapsed === true ? `${!!row.LastOrderedOn ? timeAgo(row.LastOrderedOn) : 'N/A'}` : ''}`} highlight={searchText} />
                                        </TableCell>
                                        <TableCell width="10%" align="left">
                                            <Highlighted text={`${row.IsCollapsed === true ? `${!!row.LastVisitedOn ? timeAgo(row.LastVisitedOn) : 'N/A'}` : ''}`} highlight={searchText} />
                                        </TableCell>
                                        {!isRestrictedUser && <TableCell align="left" style={{ color: 'blue' }} width="7%">
                                            <Highlighted text={`${row.IsCollapsed === true ? `${!!searchText && !!row.TotalSale && row.TotalSale.toString().indexOf(searchText) >= 0 ? row.TotalSale : fCommaSeparated(row.TotalSale ?? 0)}` : ''}`} highlight={searchText} />
                                        </TableCell>}
                                        <TableCell align="left" style={{ color: 'red' }}>
                                            <Highlighted text={`${row.IsCollapsed === true ? `${!!searchText && !!row.PendingCredit && row.PendingCredit.toString().indexOf(searchText) >= 0 ? row.PendingCredit : fCommaSeparated(row.PendingCredit ?? 0)}` : ''}`} highlight={searchText} />
                                        </TableCell>
                                        <TableCell align='right'>
                                            {row.IsCollapsed === false && <span onClick={(e) => {
                                                e.stopPropagation();
                                                onDeleteShop(row.ShopId);
                                            }} className='btn-text' style={{ color: 'red' }}>Delete</span>}
                                            <span onClick={(e) => {
                                                e.stopPropagation();
                                                setChangeRouteParam({
                                                    ShopId: row.ShopId,
                                                    FromOrderBookerName: row.OrderBookerName,
                                                    FromRouteDay: row.RouteDay,
                                                    FromRouteId: row.RouteId,
                                                    FromRouteName: row.Route,
                                                    AreaId: props.areaId,
                                                    LoginUserId: props.loginUserId
                                                });
                                            }} className='btn-text'>Shift</span>
                                            <span onClick={(e) => {
                                                e.stopPropagation();
                                                setShopForEdit(getCopy(row));
                                            }} className='btn-text'>Edit</span>
                                        </TableCell>
                                    </TableRow>
                                        {row.IsCollapsed === false && <TableRow key={`Shop_Detail_Card_${row.ShopId}`}>
                                            <TableCell colSpan={9}>
                                                <Table sx={{ borderCollapse: 'separate', borderSpacing: '0px 4px' }} size={'small'}>
                                                    <TableBody>
                                                        <TableRow
                                                            hover
                                                            key={`ShopDetail_${row.ShopId}`}
                                                            tabIndex={-1}
                                                            role="checkbox"
                                                            selected={false}
                                                            aria-checked={false}
                                                        >
                                                            <TableCell className='' width={"15%"}>
                                                                <strong>Shop Id:</strong>
                                                            </TableCell>
                                                            <TableCell className='' width="20%">
                                                                <Highlighted text={row.ShopId.toString()} highlight={!!searchText && searchText.trim() !== '' ? searchText : (!!props.searchText && props.searchText.trim() !== '' ? props.searchText.trim() : '')} />
                                                            </TableCell>
                                                            <TableCell colSpan={2} rowSpan={3} align='right'>
                                                                <div style={{ display: 'flex' }}><img style={{ alignSelf: 'center', display: 'block' }} src={row.ImageUrl} alt="Shop" width="150" height="100" /></div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            hover
                                                            key={`ShopDetail1_${row.ShopId}`}
                                                            tabIndex={-1}
                                                            role="checkbox"
                                                            selected={false}
                                                            aria-checked={false}
                                                        >
                                                            <TableCell className=''>
                                                                <strong>Name:</strong>
                                                            </TableCell>
                                                            <TableCell className=''>
                                                                <Highlighted text={row.ShopName} highlight={!!searchText && searchText.trim() !== '' ? searchText : (!!props.searchText && props.searchText.trim() !== '' ? props.searchText.trim() : '')} />
                                                                {!!row.CellOne && row.CellOne.trim() !== '' && <span style={{ paddingLeft: 5 }}>
                                                                    <Highlighted text={row.CellOne} highlight={!!searchText && searchText.trim() !== '' ? searchText : (!!props.searchText && props.searchText.trim() !== '' ? props.searchText.trim() : '')} />
                                                                </span>}
                                                                {!!row.CellTwo && row.CellTwo.trim() !== '' && <span style={{ paddingLeft: 5 }}>
                                                                    <Highlighted text={row.CellTwo} highlight={!!searchText && searchText.trim() !== '' ? searchText : (!!props.searchText && props.searchText.trim() !== '' ? props.searchText.trim() : '')} />
                                                                </span>}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            hover
                                                            key={`ShopDetail2_${row.ShopId}`}
                                                            tabIndex={-1}
                                                            role="checkbox"
                                                            selected={false}
                                                            aria-checked={false}
                                                        >
                                                            <TableCell className=''>
                                                                <strong>Crdt.Status:</strong>
                                                            </TableCell>
                                                            <TableCell className='' style={{ color: row.CreditStatusId === 1 ? 'green' : 'red' }}>
                                                                {selectedShopIdToUpdateCreditStatus !== row.ShopId ? (!!row.CreditStatus && row.CreditStatus.trim() !== '' ? row.CreditStatus : 'N/A') : ''} {selectedShopIdToUpdateCreditStatus !== row.ShopId && <span onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    onSelectShopToUpdateCreditStatus(row.ShopId, row.CreditStatusId, row.CreditStatus);
                                                                }} className='btn-text' style={{ color: 'blue', textDecoration: 'underline' }}>Change</span>}
                                                                {selectedShopIdToUpdateCreditStatus === row.ShopId && <React.Fragment><CustomAutoComplete<IAutoComplete>
                                                                    Id={`Credit_Statuses_AutoComplete_${row.RecordId}`}
                                                                    Value={!!creditStatuses && creditStatuses.length > 0 && selectedStatusId > 0 ? creditStatuses.find(m => m.Value === selectedStatusId) : null}
                                                                    Options={creditStatuses ?? []}
                                                                    displayField={'DisplayText'}
                                                                    valueField={'Value'}
                                                                    Label={'Credit Status'}
                                                                    onChange={onCreditStatusChange}
                                                                    size='small'
                                                                    isLoading={isCreditStatusesLoading}
                                                                />
                                                                    <span onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        if (selectedStatusId > 0 && !!selectedStatusText && selectedStatusText.trim() !== '') {
                                                                            onSaveCreditStatus(row.ShopId, selectedStatusId, selectedStatusText);
                                                                        }
                                                                    }} className='btn-text'>Save</span>
                                                                    <span onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        onCancelCreditStatusUpdate();
                                                                    }} className='btn-text'>Cancel</span>
                                                                </React.Fragment>}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            hover
                                                            key={`ShopDetail3_${row.ShopId}`}
                                                            tabIndex={-1}
                                                            role="checkbox"
                                                            selected={false}
                                                            aria-checked={false}
                                                        >
                                                            <TableCell className=''>
                                                                <strong>Assigned To:</strong>
                                                            </TableCell>
                                                            <TableCell className=''>
                                                                {!!row.OrderBookerName && row.OrderBookerName.trim() !== '' ? row.OrderBookerName : 'N/A'}
                                                            </TableCell>
                                                            <TableCell className='' width="15%">
                                                                <strong>Route:</strong>
                                                            </TableCell>
                                                            <TableCell className=''>
                                                                <Highlighted text={!!row.Route && row.Route.trim() !== '' ? row.Route : 'Unassigned'} highlight={searchText} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            hover
                                                            key={`ShopDetail4_${row.ShopId}`}
                                                            tabIndex={-1}
                                                            role="checkbox"
                                                            selected={false}
                                                            aria-checked={false}
                                                        >
                                                            <TableCell className=''>
                                                                <strong>Added Date:</strong>
                                                            </TableCell>
                                                            <TableCell className=''>
                                                                {formatedDateTime(row.AddedOn, 'DD-MMM-YYYY hh:mm A')}
                                                            </TableCell>
                                                            <TableCell className=''>
                                                                <strong>Assigned Day:</strong>
                                                            </TableCell>
                                                            <TableCell className=''>
                                                                {!!row.RouteDay && row.RouteDay.trim() !== '' ? row.RouteDay : 'Unassigned'}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            hover
                                                            key={`ShopDetail5_${row.ShopId}`}
                                                            tabIndex={-1}
                                                            role="checkbox"
                                                            selected={false}
                                                            aria-checked={false}
                                                        >
                                                            <TableCell className=''>
                                                                <strong>Unit:</strong>
                                                            </TableCell>
                                                            <TableCell className=''>
                                                                {row.UnitTitle}
                                                            </TableCell>
                                                            <TableCell className=''>
                                                                <strong>Address:</strong>
                                                            </TableCell>
                                                            <TableCell className=''>
                                                                <Highlighted text={`${(!!row.ShopAddress && row.ShopAddress.trim() !== '' ? row.ShopAddress : 'N/A')}`} highlight={!!searchText && searchText.trim() !== '' ? searchText : (!!props.searchText && props.searchText.trim() !== '' ? props.searchText.trim() : '')} />
                                                            </TableCell>
                                                        </TableRow>
                                                        {isRestrictedUser === true && <TableRow
                                                            hover
                                                            key={`ShopDetail86659_${row.ShopId}`}
                                                            tabIndex={-1}
                                                            role="checkbox"
                                                            selected={false}
                                                            aria-checked={false}
                                                        >
                                                            <TableCell className=''>
                                                                <strong>Pending Credit:</strong>
                                                            </TableCell>
                                                            <TableCell className='' style={{color:'red'}}>
                                                                <Highlighted text={`${`${!!searchText && !!row.PendingCredit && row.PendingCredit.toString().indexOf(searchText) >= 0 ? (row.PendingCredit ?? 0) : fCommaSeparated(row.PendingCredit ?? 0)}`}`} highlight={searchText} />
                                                            </TableCell>
                                                            <TableCell className=''>
                                                                &nbsp;
                                                            </TableCell>
                                                            <TableCell className=''>
                                                                &nbsp;
                                                            </TableCell>
                                                        </TableRow>}
                                                        <TableRow
                                                            hover
                                                            key={`ShopDetail6_${row.ShopId}`}
                                                            tabIndex={-1}
                                                            role="checkbox"
                                                            selected={false}
                                                            aria-checked={false}
                                                        >
                                                            <TableCell className='' colSpan={4}>
                                                                <Table sx={{ borderCollapse: 'separate', borderSpacing: '0px 4px' }} size={'small'}>
                                                                    <TableBody>
                                                                        <TableRow
                                                                            hover
                                                                            key={`ShopDetail7_${row.ShopId}`}
                                                                            tabIndex={-1}
                                                                            role="checkbox"
                                                                            selected={false}
                                                                            aria-checked={false}
                                                                        >
                                                                            <TableCell className='' align='left' padding='none' width={"10%"}>
                                                                                <strong>Shop Type:</strong>
                                                                            </TableCell>
                                                                            <TableCell className='' padding='none' width="12%" style={{ color: 'blue' }}>
                                                                                {!!row.ShopType && row.ShopType.trim() !== '' ? row.ShopType : 'N/A'}
                                                                            </TableCell>
                                                                            <TableCell className='' align='left' padding='none' width={"12%"}>
                                                                                <strong>Last Visited:</strong>
                                                                            </TableCell>
                                                                            <TableCell className='' padding='none' width={"15%"} style={{ color: 'blue' }}>
                                                                                <Highlighted text={!!row.LastVisitedOn ? timeAgo(row.LastVisitedOn) : 'N/A'} highlight={searchText} />
                                                                            </TableCell>
                                                                            <TableCell className='' padding='none' width={"10%"}>
                                                                                <strong>Last Order:</strong>
                                                                            </TableCell>
                                                                            <TableCell className='' padding='none' style={{ color: 'blue' }} width={"15%"}>
                                                                                <Highlighted text={!!row.LastOrderedOn ? timeAgo(row.LastOrderedOn) : 'N/A'} highlight={searchText} />
                                                                            </TableCell>
                                                                            <TableCell className='' padding='none' width={"10%"}>
                                                                                <strong>Cvrd.Status:</strong>
                                                                            </TableCell>
                                                                            <TableCell align="left" style={{ color: row.IsCovered ? 'green' : 'red' }}>
                                                                                <Highlighted text={`${row.IsCovered ? coveredShort : neverCoveredShort}`} highlight={searchText} />
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </TableBody>
                                                                </Table>
                                                            </TableCell>
                                                        </TableRow>
                                                        
                                                        {!isRestrictedUser && <TableRow
                                                            hover
                                                            key={`ShopDetail8_${row.ShopId}`}
                                                            tabIndex={-1}
                                                            role="checkbox"
                                                            selected={false}
                                                            aria-checked={false}
                                                            style={{ backgroundColor: 'white' }}
                                                        >
                                                            <TableCell colSpan={4}>
                                                                <table border={1} style={{ borderWidth: 1 }} cellSpacing={0} cellPadding={10} width="75%">
                                                                    <tbody>
                                                                        <tr key={`ShopDetail9_${row.ShopId}`}>
                                                                            <th colSpan={7} align='center'>Progress</th>
                                                                        </tr>
                                                                        <tr key={`ShopDetail10_${row.ShopId}`}>
                                                                            <td align='center'>T-Sale</td>
                                                                            <td align='center'>Cash Sale</td>
                                                                            <td align='center'>Crdt.Sale</td>
                                                                            <td align='center'>Recvrd. Crdt</td>
                                                                            <td align='center'>Pend.Crdt</td>
                                                                            <td align='center'>Creditability</td>
                                                                            <td align='center'>Distance</td>
                                                                        </tr>
                                                                        <tr key={`ShopDetail11_${row.ShopId}`}>
                                                                            <td align='right' style={{ paddingRight: 5 }}>
                                                                                <Highlighted text={`${`${!!searchText && !!row.TotalSale && row.TotalSale.toString().indexOf(searchText) >= 0 ? row.TotalSale : fCommaSeparated(row.TotalSale ?? 0)}`}`} highlight={searchText} />
                                                                            </td>
                                                                            <td align='right' style={{ paddingRight: 5 }}>{fCommaSeparated(row.OrderOnCashAmount ?? 0)}</td>
                                                                            <td align='right' style={{ paddingRight: 5 }}>{fCommaSeparated(row.OrderOnCreditAmount ?? 0)}</td>
                                                                            <td align='right' style={{ paddingRight: 5, color: 'green' }}>{fCommaSeparated(row.TotalRecovery ?? 0)}</td>
                                                                            <td align='right' style={{ paddingRight: 5, color: 'red' }}>
                                                                                <Highlighted text={`${`${!!searchText && !!row.PendingCredit && row.PendingCredit.toString().indexOf(searchText) >= 0 ? (row.PendingCredit ?? 0) : fCommaSeparated(row.PendingCredit ?? 0)}`}`} highlight={searchText} />
                                                                            </td>
                                                                            <td align='center' style={{ paddingRight: 5, color: row.CreditabilityId === 1 ? 'green' : (row.CreditabilityId === 2 ? 'orange' : 'red') }}>{!!row.Creditability && row.Creditability.trim() !== '' ? row.Creditability : 'N/A'}</td>
                                                                            <td align='center' style={{ paddingRight: 5 }}>{!!row.Distance && row.Distance > 0 ? (row.Distance > 1000 ? `${(row.Distance / 1000).toFixed(1)} KM` : `${row.Distance} M`) : 'N/A'}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </TableCell>
                                                        </TableRow>}
                                                    </TableBody>
                                                </Table>
                                            </TableCell>
                                        </TableRow>}
                                    </React.Fragment>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>}
            </Card>
        </Container>}
        {!!shopForEdit && <EditShopPopup onClose={() => setShopForEdit(null)} shop={shopForEdit} onSuccess={onShopUpdateSuccess} />}
        {!!changeRouteParam && <ShopRouteChangePopup
            changeRouteParam={changeRouteParam}
            open={!!changeRouteParam}
            onClose={() => setChangeRouteParam(null)}
            onSuccess={handleShopRouteChange}
        />}
    </React.Fragment>
}