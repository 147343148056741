import React from 'react';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import refreshFill from '@iconify/icons-eva/refresh-fill';
// material
import {
    Card,
    Table,
    TableRow,
    TableBody,
    TableCell,
    Container,
    TableContainer,
    Grid,
    FormGroup,
    FormControlLabel,
    Switch,
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
//
import { LoadingButton, MonthPicker } from '@mui/lab';
import { orderApi } from '../http';
import { Audio as AudoSpin } from 'react-loader-spinner'
import { encodeBase64String, formattedDate, getCommaSeparatedSelectedUnitsString, getCommaSeparatedUnitUniqueIdsExceptAll, getDateOnly } from 'src/utility';
import { IUserDetail } from 'src/interfaces';
import { fCommaSeparated, fCommaSeparatedWithDecial, fCurrency, fUptoTwoDecimal } from 'src/utils/formatNumber';
import { IMessage } from 'src/interfaces/message.interface';
import { MessageComponent } from 'src/components/messages.component';
import { useNavigate } from "react-router-dom";
import { IOrderBookerSaleReportResult } from 'src/interfaces/order-booker-sale-report-result.interface';
import { IApplicationState } from 'src/redux';
import { connect, useSelector } from 'react-redux';
import { ISelectedUnitAccess } from 'src/interfaces/selected-unit-access.interface';
import { IOrderNew } from 'src/interfaces/reports/order-new.interface';
import { IOrderData } from 'src/interfaces/reports/order-data.interface';
import { setOrderNewDataAction } from 'src/redux/actions/order-new.action';
import { IAction } from 'src/redux/actions/action.interface';
import { AllOptionUniqueId, isRestrictedLogin, NewOrderReportPrintSetting, NewOrderReportPrintSettings, NumberOfPreviousMonth } from 'src/app.constant';
import CustomDatePicker from 'src/components/common/CustomDatePicker';
import { getUrlFromComponentKey } from 'src/utility/navigation.util';
import { Keys } from 'src/component.keys';
import { IUserRouteDetail } from 'src/interfaces/routes/user-route-detail.interface';
import CustomMonthPicker from 'src/components/common/MonthPicker';
import useWindowDimensions from 'src/components/common/useWindowDimentions';
import { useReactToPrint } from 'react-to-print';
import { OrderNewReportPrintSettingModal } from 'src/components/OrderNewReportPrintSettingModal';
import { INewOrderReportSetting } from 'src/interfaces/new-order-report-setting.interface';
import '../page-styles/order-new-report.css';
// --------------------------------------------------------------------


//#region interfaces

interface IOrderBookerTotal {
    Visited: number;
    Orders: number;
    NoOrders: number;
    OrderQty: number;
    ReturnQty: number;
    DeliveredQty: number;
    SchemeQty: number;
    ReturnAmount: number;
    DiscountAmount: number;
    SchemeCost: number;
    NetSale: number;
    Sale: number;
}

interface IProductTotal {
    OrderQty: number;
    ReturnQty: number;
    SchemeQty: number;
    DeliveredQty: number;
    ReturnAmount: number;
    DiscountAmount: number;
    SchemeCost: number;
    NetSale: number;
    Sale: number;
}

interface ISavedDimensions {
    width: number;
    height: number;
}

//#endregion
interface IProps {
    loginUser: IUserDetail | null;
    selectedUnitAccesses: ISelectedUnitAccess | null;
    orderNewData: IOrderData;
    setNewOrderData: (payload: IOrderData) => IAction<IOrderData>;
    userRoutes: IUserRouteDetail;
    newOrderReportPrintSetting: INewOrderReportSetting;
}

const OrdersNewReport = (props: IProps) => {
    const navigate = useNavigate();
    //#region state variable



    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [pageMessages, setPageMessages] = React.useState<IMessage[]>([]);
    const showErrorMessage = (message: string) => addMessage(message, 'error');
    const showInfoMessage = (message: string) => addMessage(message, 'info');
    const showSuccessMessage = (message: string) => addMessage(message, 'success');
    const showWarningMessage = (message: string) => addMessage(message, 'warning');

    const [printSettingOpen, setPrintSettingOpen] = React.useState<boolean>(false);
    const onClosePrintSettings = () => {
        setPrintSettingOpen(false);
        setIsPrinting(false);
    };

    const [isPrinting, setIsPrinting] = React.useState<boolean>(false);
    const totalColumns = NewOrderReportPrintSettings.length;


    const addMessage = (messageText: string, variant: 'success' | 'error' | 'info' | 'warning') => {
        if (!!messageText && messageText.trim() != '') {
            let msgs = [];
            msgs.push({
                messageText,
                variant
            });

            setPageMessages([...msgs]);
        }
    }

    //#endregion

    //#region handlers start

    const resetEverything = () => {
        setPageMessages([]);
        props.setNewOrderData(null);
    }

    const goBack = () => {
        navigate(-1);
    }

    const navigateToTrackingPage = (obId: number) => {
        let url = getUrlFromComponentKey(props.userRoutes, Keys.NewTrackingComponent);
        url = `${url}?orderBooker=${encodeBase64String(obId.toString())}`;
        navigate(url);
    }

    //#endregion handlers end

    //#region apis call start

    const [fromDated, _setFromDated] = React.useState<Date>(new Date());
    const [toDated, _setToDated] = React.useState<Date>(new Date());

    const setFromDated = (dated: Date) => {
        resetEverything();
        _setFromDated(dated);
    }

    const setToDated = (dated: Date) => {
        resetEverything();
        _setToDated(dated);
    }

    const validateFilters = (showMessages: boolean) => {

        if (selectedButtonOption == 'D') {
            if (fromDated === undefined || fromDated == null) {
                if (showMessages == true) {
                    showErrorMessage('Please Select Date');
                }

                return false;
            }
        } else {
            if (fromDated === undefined || fromDated == null || toDated === undefined || toDated == null) {

                if (showMessages == true) {
                    showErrorMessage('Please Select Dates');
                }

                return false;
            }

            if (getDateOnly(fromDated) > getDateOnly(toDated)) {

                if (showMessages == true) {
                    showErrorMessage(`From Date Can't be Greater Than To Date`);
                }

                return false;
            }
        }

        return true;
    }

    const loadDataForDate = () => {
        if (validateFilters(true)) {
            let toDate = new Date(toDated.getFullYear(), toDated.getMonth(), toDated.getDate(), 0, 0, 0, 0);
            getReport(true, toDate, toDate);
        }
    }

    const loadDataForMonth = () => {
        if (validateFilters(true)) {
            let fromDate = new Date(fromDated.getFullYear(), fromDated.getMonth(), fromDated.getDate(), 0, 0, 0, 0);
            let toDate = new Date(toDated.getFullYear(), toDated.getMonth(), toDated.getDate(), 0, 0, 0, 0);

            if (fromDate.getTime() == toDate.getTime()) {
                setSelectedButtonOption('D');
            }

            getReport(true, fromDate, toDate);
        }
    }

    const getReport = (showMessages: boolean, fromDate: Date, toDate: Date) => {
        resetEverything();
        setIsLoading(true);


        orderApi.getNewOrdersReport(props.loginUser.UserId, getCommaSeparatedUnitUniqueIdsExceptAll(props.selectedUnitAccesses.UnitAccesses), fromDate, toDate, null)
            .then(results => {
                setIsLoading(false);
                if (!!results) {
                    props.setNewOrderData({ FromDate: fromDate, ToDate: toDate, OrderNew: [...results] });
                }
                else {
                    props.setNewOrderData({ FromDate: fromDate, ToDate: toDate, OrderNew: [] });
                }
            })
            .catch(e => {
                console.log(e);
                setIsLoading(false);
                props.setNewOrderData({ FromDate: fromDate, ToDate: toDate, OrderNew: [] });
            });
    }

    const handleDClick = () => {
        setSelectedButtonOption('D');

        if (!!props.selectedUnitAccesses && !!props.selectedUnitAccesses.UnitAccesses && props.selectedUnitAccesses.UnitAccesses.length > 0) {
            const todayDated: Date = new Date();

            setFromDated(todayDated);
            setToDated(todayDated);

            getReport(true, todayDated, todayDated);
        } else {
            props.setNewOrderData({ ...props.orderNewData, OrderNew: [] });
        }
    }

    const handleMClick = () => {
        setSelectedButtonOption('M');

        if (!!props.selectedUnitAccesses && !!props.selectedUnitAccesses.UnitAccesses && props.selectedUnitAccesses.UnitAccesses.length > 0) {

            const todayDated: Date = new Date();
            let fromDate = todayDated;
            let toDate = todayDated;

            const monthNumber = todayDated.getMonth();
            const yearNumber = todayDated.getFullYear();
            fromDate = new Date(yearNumber, monthNumber, 1, 0, 0, 0, 0);

            setFromDated(fromDate);
            setToDated(toDate);

            getReport(true, fromDate, toDate);
        } else {
            props.setNewOrderData({ ...props.orderNewData, OrderNew: [] });
        }
    }

    const currentYear = (new Date()).getFullYear();
    const currentMonthIndex = (new Date()).getMonth();
    const [selectedMonthIndex, setSelectedMonthIndex] = React.useState<number>(currentMonthIndex);
    const [selectedYear, setSelectedYear] = React.useState<number>(currentYear);
    const onChangeYearMonth = (year: number, monthIndex) => {
        setSelectedMonthIndex(monthIndex);
        setSelectedYear(year);
    }

    const componentRef = React.useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        copyStyles: true,
        onBeforeGetContent: () => setIsPrinting(true),
        onAfterPrint: () => setIsPrinting(false),
        documentTitle: 'Performance Report',
    });

    const onPrintReport = () => {
        onClosePrintSettings();
        handlePrint();
    }

    //#endregion apis calls end

    //#region hooks start

    React.useEffect(() => {
        if (!!props.selectedUnitAccesses && !!props.selectedUnitAccesses.UnitAccesses && props.selectedUnitAccesses.UnitAccesses.length > 0) {
            const todayDated: Date = new Date();
            let fromDate = todayDated;
            let toDate = todayDated;

            if (selectedButtonOption == 'M') {
                const monthNumber = todayDated.getMonth();
                const yearNumber = todayDated.getFullYear();
                fromDate = new Date(yearNumber, monthNumber, 1, 0, 0, 0, 0);
            }

            setFromDated(fromDate);
            setToDated(toDate);
            getReport(true, fromDate, toDate);
        } else {
            props.setNewOrderData({ ...props.orderNewData, OrderNew: [] });
        }
    }, [props.selectedUnitAccesses]);

    //#endregion hooks end

    const selectedButtonColor: string = 'rgba(51, 170, 51, .4)';
    const defaultButtonColor: string = 'rgba(255,255,255,.1)';
    const selectedButtonTextColor: string = 'green';
    const defaultButtonTextColor: string = 'black';
    const tableCellPadding = 2;
    const [selectedButtonOption, setSelectedButtonOption] = React.useState<'D' | 'M'>('D');
    const dimensions = useWindowDimensions();

    const isRestrictedUser = isRestrictedLogin(props.loginUser?.UserId);
    const [isCashView, setIsCashView] = React.useState<boolean>(false);
    const pageKey = 'NewOrdersComponentPageKey';
    return (
        <Page key={pageKey} title="Order Booker Orders | Raza Pak Care">
            <MessageComponent key={`${pageKey}_1`} onRemove={(i: number) => {
                let msgs = [...pageMessages];
                msgs.splice(i, 1);
                setPageMessages([...msgs]);
            }} messages={pageMessages} />

            <OrderNewReportPrintSettingModal open={printSettingOpen} onPrint={onPrintReport} onClose={onClosePrintSettings} />

            <Container key={`${pageKey}_3`} style={{ maxWidth: dimensions.width, paddingLeft: 0, paddingRight: 0, marginBottom: 10 }}>
                {/* {<Grid key={`${pageKey}_4`} item md={12}>
                    <Grid key={`${pageKey}_5`} container style={{ flexDirection: 'row' }}>
                        <Grid key={`${pageKey}_6`} container spacing={2}>
                            <Grid key={`${pageKey}_7`} xs={12} sm={12} md={12} style={{ flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }} item>
                                <LoadingButton
                                    size="small"
                                    type="button"
                                    variant="contained"
                                    loading={isLoading}
                                    style={{ alignSelf: 'flex-end', margin: 5, backgroundColor: 'rgba(34, 154, 22, 0.72)' }}
                                    startIcon={<Icon icon={refreshFill} />}
                                    onClick={() => {
                                        resetEverythingAndReloadData();
                                    }}
                                    key={`${pageKey}_8`}
                                >
                                    Refresh
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>} */}

                <Scrollbar key={'Top_Scroll_key'} sx={{}}>
                    <Container key={`${pageKey}_1221`}>
                        <Grid key={`${pageKey}_2`} container spacing={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
                            <Grid key={`${pageKey}_7`} item md={12} sm={12} xs={12}>
                                <LoadingButton
                                    size="small"
                                    type="button"
                                    variant="contained"
                                    loading={false}
                                    disabled={isLoading}
                                    style={{ margin: 5, width: 30, height: 30, borderRadius: 60, background: selectedButtonOption == 'D' ? selectedButtonColor : defaultButtonColor, color: 'black', borderColor: 'black', border: '2px solid' }}
                                    onClick={handleDClick}
                                    key={`${pageKey}_81`}
                                >
                                    D
                                </LoadingButton>

                                <LoadingButton
                                    size="small"
                                    type="button"
                                    variant="outlined"
                                    loading={false}
                                    disabled={isLoading}
                                    style={{ margin: 5, width: 30, height: 30, borderRadius: 60, background: selectedButtonOption == 'M' ? selectedButtonColor : defaultButtonColor, color: 'black', borderColor: 'black', border: '2px solid' }}
                                    onClick={handleMClick}
                                    key={`${pageKey}_1123565`}
                                >
                                    M
                                </LoadingButton>

                            </Grid>

                            {selectedButtonOption == 'D' && <Grid item md={3} sm={4} xs={6}>
                                <div><CustomDatePicker
                                    label={'Date'}
                                    value={toDated}
                                    onChange={(v: Date) => {
                                        setToDated(v);
                                    }}
                                    inputDisable={true}
                                /></div>
                            </Grid>}

                            {selectedButtonOption == 'D' && <Grid item md={2} xs={6} sx={{ display: 'flex' }}>
                                <LoadingButton
                                    size="small"
                                    type="button"
                                    variant="outlined"
                                    loading={false}
                                    disabled={isLoading}
                                    style={{ margin: 5, alignSelf: 'center' }}
                                    onClick={loadDataForDate}
                                    key={`${pageKey}_1185654`}
                                >
                                    Load
                                </LoadingButton>
                            </Grid>}

                            {selectedButtonOption == "M" && <Grid item md={12} sm={12} style={{ display: 'flex', flexDirection: 'row' }}>

                                <CustomMonthPicker
                                    maxYear={currentYear}
                                    minYear={2022}
                                    onChange={onChangeYearMonth}
                                    selectedMonthIndex={selectedMonthIndex}
                                    selectedYear={selectedYear}
                                    key={'MONTH_PICKER'}
                                    onFromDateChange={setFromDated}
                                    onToDateChange={setToDated}
                                    showLoadButton={true}
                                    onLoadClick={loadDataForMonth}
                                    lastNumberOfMonth={isRestrictedLogin(props.loginUser.UserId) ? NumberOfPreviousMonth : 12}
                                />

                                {/* <CustomDatePicker
                                    label={'From Date'}
                                    value={fromDated}
                                    onChange={(v: Date) => {
                                        setFromDated(v);
                                    }}
                                    marginLeft={10}
                                />

                                <CustomDatePicker
                                    label={'To Date'}
                                    value={toDated}
                                    onChange={(v: Date) => {
                                        setToDated(v);
                                    }}
                                    marginLeft={10}
                                /> */}



                            </Grid>}

                        </Grid>
                    </Container>
                </Scrollbar>
                <Card key={`${pageKey}_9`} sx={{ borderRadius: 0 }}>
                    {isLoading == true && <AudoSpin
                        key={`${pageKey}_10`}
                        height="80"
                        width="80"
                        color='green'
                        ariaLabel='three-dots-loading'
                        wrapperStyle={{ alignSelf: 'center', justifyContent: 'center' }}
                    />}

                    {!!props.orderNewData && !!props.orderNewData.OrderNew && props.orderNewData.OrderNew.length > 0 && <Scrollbar key={`${pageKey}_118795`} sx={{}}>

                        {isRestrictedUser == false && <Grid container spacing={2}>
                            <Grid item md={12} xs={12} className="btn-print" style={{justifyContent:'center'}}>
                            <FormControlLabel style={{alignSelf:'flex-end'}} control={<Switch checked={isCashView} onChange={() => {setIsCashView((isYes) => !isYes)}} />} label="Cash View" />
                            <LoadingButton
                                size="small"
                                type="button"
                                variant="outlined"
                                loading={false}
                                disabled={isLoading}
                                style={{ margin: 5, height: 25, alignSelf: 'center', color: 'blue', borderColor: 'blue' }}
                                onClick={() => {
                                    setPrintSettingOpen(true);
                                    setIsPrinting(true);
                                }}
                                key={`${pageKey}_11fff554`}
                            >
                                Print
                            </LoadingButton>
                        </Grid></Grid>}
                        <div ref={componentRef} className="orders-table-parent">

                            {isCashView == true && isRestrictedUser === false && <table border={1} key={`UNT_TABLE_ALL`} cellSpacing={0} cellPadding={5} className="orders-table" style={{ marginBottom: 10, marginTop: 10 }}>
                                <tbody>
                                    {props.orderNewData.OrderNew.map((od, i, { length }) => {
                                        return <React.Fragment key={`OB_${od.EmployeeId}`}>
                                            <tr style={{ pageBreakAfter: 'avoid' }}>
                                                <th style={{ padding: tableCellPadding, fontSize: 14, color: isPrinting ? 'black' : 'blue' }} colSpan={isPrinting ? props.newOrderReportPrintSetting.columnsToPrint.length : (od.DesignationId === 2 ? totalColumns - 1 : totalColumns)} align={'left'}>{`${od.FirstName} ${od.LastName} (${od.UnitShortName} ${od.Designation})`}</th>
                                                {isPrinting == false && od.DesignationId === 2 && <td align='center'>
                                                    <span onClick={() => {
                                                        navigateToTrackingPage(od.EmployeeId);
                                                    }} style={{ color: isPrinting ? 'black' : 'blue', fontSize: 12, cursor: 'pointer', fontWeight: 'bold' }}>Details</span>
                                                </td>}
                                            </tr>
                                            <tr>
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.Target) >= 0) && <th style={{ padding: tableCellPadding }}>Target</th>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.AchievedTarget) >= 0) && <th style={{ padding: tableCellPadding }}>Ach. Target</th>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.RemainingTarget) >= 0) && <th style={{ padding: tableCellPadding }}>Rem. Target</th>}
                                                
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.PendingCredit) >= 0) && <th style={{ padding: tableCellPadding }}>Pnd. Crdt.</th>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.AchievedPercentage) >= 0) && <th style={{ padding: tableCellPadding, background: isPrinting ? undefined : 'rgba(51, 170, 51, .4)' }}>Ach. %age</th>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.RemainingPercentage) >= 0) && <th style={{ padding: tableCellPadding, background: isPrinting ? undefined : 'rgba(255, 0, 0, .4)' }}>Rem. %age</th>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.DOAmount) >= 0) && <th style={{ padding: tableCellPadding }}>DO Amt.</th>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.ReturnAmount) >= 0) && <th style={{ padding: tableCellPadding }}>Return Amt.</th>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.DODelieveredAmount) >= 0) && <th style={{ padding: tableCellPadding, width: '7%' }}>DO Delivered Amt.</th>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.DOCash) >= 0) && <th style={{ padding: tableCellPadding }}>DO Cash</th>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.DOCredit) >= 0) && <th style={{ padding: tableCellPadding }}>DO Crdt.</th>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.Recovery) >= 0) && <th style={{ padding: tableCellPadding }}>Recovery</th>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.Difference) >= 0) && <th style={{ padding: tableCellPadding }}>Diff</th>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.TotalCash) >= 0) && <th style={{ padding: tableCellPadding }}>Total Cash</th>}
                                            </tr>

                                            <tr>
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.Target) >= 0) && <td style={{ padding: tableCellPadding }}>{fCommaSeparatedWithDecial(od.TargetAmount)}</td>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.AchievedTarget) >= 0) && <td style={{ padding: tableCellPadding }}>{fCommaSeparatedWithDecial(od.AchievedTargetAmount)}</td>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.RemainingTarget) >= 0) && <td style={{ padding: tableCellPadding }}>{fCommaSeparatedWithDecial(od.RemainingTargetAmount)}</td>}
                                                
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.PendingCredit) >= 0) && <td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : 'red' }}>{fCommaSeparatedWithDecial(od.EmployeePendingCredit)}</td>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.AchievedPercentage) >= 0) && <td style={{ padding: tableCellPadding, background: isPrinting ? undefined : 'rgba(51, 170, 51, .4)' }}>{od.AchievedPercentage} %</td>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.RemainingPercentage) >= 0) && <td style={{ padding: tableCellPadding, background: isPrinting ? undefined : 'rgba(255, 0, 0, .4)' }}>{od.RemainingPercentage} %</td>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.DOAmount) >= 0) && <td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : 'orange' }}>{fCommaSeparatedWithDecial(od.DoAmount)}</td>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.ReturnAmount) >= 0) && <td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : 'red' }}>{fCommaSeparatedWithDecial(od.DoAmount - od.DoDeliveredAmount)}</td>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.DODelieveredAmount) >= 0) && <td style={{ padding: tableCellPadding, width: '7%', color: isPrinting ? 'black' : 'blue' }}>{fCommaSeparatedWithDecial(od.DoDeliveredAmount)}</td>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.DOCash) >= 0) && <td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : 'green' }}>{fCommaSeparatedWithDecial(od.DoDeliveredAmount - od.DoCreditAmount)}</td>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.DOCredit) >= 0) && <td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : 'red' }}>{fCommaSeparatedWithDecial(od.DoCreditAmount)}</td>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.Recovery) >= 0) && <td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : 'green' }}>{fCommaSeparatedWithDecial(od.EmployeeRecovery)}</td>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.Difference) >= 0) && <td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : ((od.EmployeeRecovery - od.DoCreditAmount) > 0 ? 'green' : 'red') }}>{fCommaSeparatedWithDecial(od.EmployeeRecovery - od.DoCreditAmount)}</td>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.TotalCash) >= 0) && <td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : 'green' }}>{fCommaSeparatedWithDecial((od.DoDeliveredAmount - od.DoCreditAmount) + od.EmployeeRecovery)}</td>}
                                            </tr>
                                            {(length - 1 !== i) && <tr style={{ pageBreakAfter: 'avoid' }}><td colSpan={isPrinting ? props.newOrderReportPrintSetting.columnsToPrint.length : totalColumns} style={{ borderLeft: 0, borderRight: 0 }}>&nbsp;</td></tr>}
                                        </React.Fragment>;
                                    })}
                                </tbody>
                            </table>}

                            {isCashView == false && isRestrictedUser === false && <table border={1} key={`UNT_TABLE_ALL`} cellSpacing={0} cellPadding={5} className="orders-table" style={{ marginBottom: 10, marginTop: 10 }}>
                                <tbody>
                                    {props.orderNewData.OrderNew.map((od, i, { length }) => {
                                        return <React.Fragment key={`OB_${od.EmployeeId}`}>
                                            <tr style={{ pageBreakAfter: 'avoid' }}>
                                                <th style={{ padding: tableCellPadding, fontSize: 14, color: isPrinting ? 'black' : 'blue' }} colSpan={isPrinting ? props.newOrderReportPrintSetting.columnsToPrint.length : (od.DesignationId === 2 ? totalColumns - 1 : totalColumns)} align={'left'}>{`${od.FirstName} ${od.LastName} (${od.UnitShortName} ${od.Designation})`}</th>
                                                {isPrinting == false && od.DesignationId === 2 && <td align='center'>
                                                    <span onClick={() => {
                                                        navigateToTrackingPage(od.EmployeeId);
                                                    }} style={{ color: isPrinting ? 'black' : 'blue', fontSize: 12, cursor: 'pointer', fontWeight: 'bold' }}>Details</span>
                                                </td>}
                                            </tr>
                                            <tr>
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.Target) >= 0) && <th style={{ padding: tableCellPadding }}>Target(Pc.)</th>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.AchievedTarget) >= 0) && <th style={{ padding: tableCellPadding }}>Ach. Target(Pc.)</th>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.RemainingTarget) >= 0) && <th style={{ padding: tableCellPadding }}>Rem. Target(Pc.)</th>}
                                                
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.PendingCredit) >= 0) && <th style={{ padding: tableCellPadding }}>Pnd. Crdt.</th>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.AchievedPercentage) >= 0) && <th style={{ padding: tableCellPadding, background: isPrinting ? undefined : 'rgba(51, 170, 51, .4)' }}>Ach. %age</th>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.RemainingPercentage) >= 0) && <th style={{ padding: tableCellPadding, background: isPrinting ? undefined : 'rgba(255, 0, 0, .4)' }}>Rem. %age</th>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.DOAmount) >= 0) && <th style={{ padding: tableCellPadding }}>DO (Pc.)</th>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.ReturnAmount) >= 0) && <th style={{ padding: tableCellPadding }}>Return (Pc.)</th>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.DODelieveredAmount) >= 0) && <th style={{ padding: tableCellPadding, width: '7%' }}>DO Delivered (Pc.)</th>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.DOCash) >= 0) && <th style={{ padding: tableCellPadding }}>DO Cash</th>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.DOCredit) >= 0) && <th style={{ padding: tableCellPadding }}>DO Crdt.</th>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.Recovery) >= 0) && <th style={{ padding: tableCellPadding }}>Recovery</th>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.Difference) >= 0) && <th style={{ padding: tableCellPadding }}>Diff</th>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.TotalCash) >= 0) && <th style={{ padding: tableCellPadding }}>Total Cash</th>}
                                            </tr>

                                            <tr>
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.Target) >= 0) && <td style={{ padding: tableCellPadding }}>{fUptoTwoDecimal((od.TargetCtn * 48))}</td>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.AchievedTarget) >= 0) && <td style={{ padding: tableCellPadding }}>{fUptoTwoDecimal((od.AchievedCtn * 48))}</td>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.RemainingTarget) >= 0) && <td style={{ padding: tableCellPadding }}>{fUptoTwoDecimal((od.RemainingCtn * 48))}</td>}
                                                
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.PendingCredit) >= 0) && <td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : 'red' }}>{fCommaSeparatedWithDecial(od.EmployeePendingCredit)}</td>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.AchievedPercentage) >= 0) && <td style={{ padding: tableCellPadding, background: isPrinting ? undefined : 'rgba(51, 170, 51, .4)' }}>{od.AchievedPercentage} %</td>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.RemainingPercentage) >= 0) && <td style={{ padding: tableCellPadding, background: isPrinting ? undefined : 'rgba(255, 0, 0, .4)' }}>{od.RemainingPercentage} %</td>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.DOAmount) >= 0) && <td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : 'orange' }}>{fUptoTwoDecimal((od.DoCtn * 48))}</td>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.ReturnAmount) >= 0) && <td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : 'red' }}>{fUptoTwoDecimal((od.DoReturnCtn * 48))}</td>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.DODelieveredAmount) >= 0) && <td style={{ padding: tableCellPadding, width: '7%', color: isPrinting ? 'black' : 'blue' }}>{fUptoTwoDecimal(od.DoDeliveredCtn * 48)}</td>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.DOCash) >= 0) && <td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : 'green' }}>{fCommaSeparatedWithDecial(od.DoDeliveredAmount - od.DoCreditAmount)}</td>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.DOCredit) >= 0) && <td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : 'red' }}>{fCommaSeparatedWithDecial(od.DoCreditAmount)}</td>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.Recovery) >= 0) && <td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : 'green' }}>{fCommaSeparatedWithDecial(od.EmployeeRecovery)}</td>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.Difference) >= 0) && <td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : ((od.EmployeeRecovery - od.DoCreditAmount) > 0 ? 'green' : 'red') }}>{fCommaSeparatedWithDecial(od.EmployeeRecovery - od.DoCreditAmount)}</td>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.TotalCash) >= 0) && <td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : 'green' }}>{fCommaSeparatedWithDecial((od.DoDeliveredAmount - od.DoCreditAmount) + od.EmployeeRecovery)}</td>}
                                            </tr>
                                            {(length - 1 !== i) && <tr style={{ pageBreakAfter: 'avoid' }}><td colSpan={isPrinting ? props.newOrderReportPrintSetting.columnsToPrint.length : totalColumns} style={{ borderLeft: 0, borderRight: 0 }}>&nbsp;</td></tr>}
                                        </React.Fragment>;
                                    })}
                                </tbody>
                            </table>}

                            {isRestrictedUser === true && <table border={1} key={`UNT_TABLE_ALL`} cellSpacing={0} cellPadding={5} className="orders-table" style={{ marginBottom: 10, marginTop: 10 }}>
                                <tbody>
                                    {props.orderNewData.OrderNew.map((od, i, { length }) => {
                                        return <React.Fragment key={`OB_${od.EmployeeId}`}>
                                            <tr style={{ pageBreakAfter: 'avoid' }}>
                                                <th style={{ padding: tableCellPadding, fontSize: 14, color: isPrinting ? 'black' : 'blue' }} colSpan={(od.DesignationId === 2 ? 10 : 11)} align={'left'}>{`${od.FirstName} ${od.LastName} (${od.UnitShortName} ${od.Designation})`}</th>
                                                {isPrinting == false && od.DesignationId === 2 && <td align='center'>
                                                    <span onClick={() => {
                                                        navigateToTrackingPage(od.EmployeeId);
                                                    }} style={{ color: isPrinting ? 'black' : 'blue', fontSize: 12, cursor: 'pointer', fontWeight: 'bold' }}>Details</span>
                                                </td>}
                                            </tr>
                                            <tr>
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.Target) >= 0) && <th style={{ padding: tableCellPadding }}>Target(CTN)</th>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.AchievedTarget) >= 0) && <th style={{ padding: tableCellPadding }}>Ach. Target(CTN)</th>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.RemainingTarget) >= 0) && <th style={{ padding: tableCellPadding }}>Rem. Target(CTN)</th>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.AchievedPercentage) >= 0) && <th style={{ padding: tableCellPadding, background: isPrinting ? undefined : 'rgba(51, 170, 51, .4)' }}>Ach. %age</th>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.RemainingPercentage) >= 0) && <th style={{ padding: tableCellPadding, background: isPrinting ? undefined : 'rgba(255, 0, 0, .4)' }}>Rem. %age</th>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.DOAmount) >= 0) && <th style={{ padding: tableCellPadding }}>DO (CTN)</th>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.ReturnAmount) >= 0) && <th style={{ padding: tableCellPadding }}>Return (CTN)</th>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.DODelieveredAmount) >= 0) && <th style={{ padding: tableCellPadding, width: '7%' }}>DO Delivered (CTN)</th>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.DOCredit) >= 0) && <th style={{ padding: tableCellPadding }}>DO Crdt.</th>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.Difference) >= 0) && <th style={{ padding: tableCellPadding }}>Diff</th>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.PendingCredit) >= 0) && <th style={{ padding: tableCellPadding }}>Pnd. Crdt.</th>}
                                            </tr>

                                            <tr>
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.Target) >= 0) && <td style={{ padding: tableCellPadding }}>{fCommaSeparatedWithDecial(od.TargetCtn.toFixed(2))}</td>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.AchievedTarget) >= 0) && <td style={{ padding: tableCellPadding }}>{fCommaSeparatedWithDecial(od.AchievedCtn.toFixed(2))}</td>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.RemainingTarget) >= 0) && <td style={{ padding: tableCellPadding }}>{fCommaSeparatedWithDecial(od.RemainingCtn.toFixed(2))}</td>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.AchievedPercentage) >= 0) && <td style={{ padding: tableCellPadding, background: isPrinting ? undefined : 'rgba(51, 170, 51, .4)' }}>{od.AchievedPercentage} %</td>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.RemainingPercentage) >= 0) && <td style={{ padding: tableCellPadding, background: isPrinting ? undefined : 'rgba(255, 0, 0, .4)' }}>{od.RemainingPercentage} %</td>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.DOAmount) >= 0) && <td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : 'orange' }}>{fCommaSeparatedWithDecial(od.DoCtn.toFixed(2))}</td>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.ReturnAmount) >= 0) && <td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : 'red' }}>{fCommaSeparatedWithDecial(od.DoReturnCtn.toFixed(2))}</td>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.DODelieveredAmount) >= 0) && <td style={{ padding: tableCellPadding, width: '7%', color: isPrinting ? 'black' : 'blue' }}>{fCommaSeparatedWithDecial(od.DoDeliveredCtn.toFixed(2))}</td>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.DOCredit) >= 0) && <td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : 'red' }}>{fCommaSeparatedWithDecial(od.DoCreditAmount)}</td>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.Difference) >= 0) && <td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : ((od.EmployeeRecovery - od.DoCreditAmount) > 0 ? 'green' : 'red') }}>{fCommaSeparatedWithDecial(od.EmployeeRecovery - od.DoCreditAmount)}</td>}
                                                {(isPrinting == false || props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == NewOrderReportPrintSetting.PendingCredit) >= 0) && <td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : 'red' }}>{fCommaSeparatedWithDecial(od.EmployeePendingCredit)}</td>}
                                            </tr>
                                            {(length - 1 !== i) && <tr style={{ pageBreakAfter: 'avoid' }}><td colSpan={11} style={{ borderLeft: 0, borderRight: 0 }}>&nbsp;</td></tr>}
                                        </React.Fragment>;
                                    })}
                                </tbody>
                            </table>}
                        </div>
                    </Scrollbar>}
                </Card>

            </Container>
        </Page>
    );
}

const mapStateToProps = (state: IApplicationState) => {
    return {
        loginUser: state.loginUser,
        selectedUnitAccesses: state.selectedUnitAccesses,
        orderNewData: state.orderNewData,
        userRoutes: state.userRoutes,
        newOrderReportPrintSetting: state.newOrderReportPrintSetting
    };
}

const mapDispatchToProps = {
    setNewOrderData: setOrderNewDataAction
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(OrdersNewReport);
export { connectedComponent as OrdersNewReport };