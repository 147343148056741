import React from 'react';
import { connect, useSelector } from 'react-redux';
import { MessageComponent } from 'src/components/messages.component';
import { areaApi, routeApi } from 'src/http';
import { IMessage } from 'src/interfaces/message.interface';
import { IApplicationState } from 'src/redux';
import { getCopy } from 'src/utility';
import { fCommaSeparated } from 'src/utils/formatNumber';
import { IRoute } from 'src/interfaces/route.interface';
import { IRoutePoly } from 'src/interfaces/route-poly.interface';
import Page from 'src/components/Page';
import { CircularProgress, Container, FormControlLabel, Switch, TextField, Typography } from '@mui/material';
import { IAutoComplete } from 'src/interfaces/auto-complete.interface';
import { LoadingButton } from '@mui/lab';
import { Icon } from '@iconify/react';
import { IMapShop } from 'src/interfaces/map-shop.interface';
import { IAreaPolyOnMap, IRoutePolyOnMap } from 'src/interfaces/map/new-route-setup';
import '../component-styles/new-route-setup-component.css';
import { IOrderBookerRouteInfoNew } from 'src/interfaces/order-booker-route-info-new.interface';
import { RouteDayDataSource, UnAssignedOrderBookerId } from 'src/app.constant';
import { IAreaPoly } from 'src/interfaces/area-poly.interface';
import { IRouteInfoNew } from 'src/interfaces/route-info-new.interface';
import { IMapPopUpConfirmation } from 'src/interfaces/map-pop-confirmation.interface';
import { MapConfirmationPopup } from '../map-confirmation-pop-up.component';
import { addOrUpdateRouteAction, deleteRouteAction, hardReloadAreaOrderBookerInfoAction, setSelectedAreaIdAction, softReloadAreaOrderBookerInfoAction } from 'src/redux/actions/area-wise-order-booker-info.action';
import { ISelectedRouteIdOnMap } from 'src/interfaces/selected-route-id-on-map.interface';
import { hardReloadAreaPoliesAction, hardReloadExistingShopsAction, hardReloadNewShopsAction, hardReloadRoutePoliesAction, resetNewRouteSetupPageMessageAction, setNewRouteSetupPageErrorMessageAction, setNewRouteSetupPageSuccessMessageAction, setSelectedRouteIdOnMapAction, softReloadAreaPoliesAction, softReloadExistingShopsAction, softReloadNewShopsAction, softReloadRoutePoliesAction } from 'src/redux/actions/new-route-setup-action';
import { any } from 'prop-types';
import { CustomAutoCompleteForMap } from '../common/CustomAutoCompleteForMap';

interface ISelectedRouteId {
  selectedRouteId: number;
}

interface IProps {
  center: google.maps.LatLngLiteral;
  zoom: number;
  isNew: number;
  areaId: number;
  areaName: string;
  routeId: number;
  orderBookerId: number;
  isLoadingOrderBookerRouteInfo: boolean;
  selectedAreaId: number;
  selectedRouteId: ISelectedRouteIdOnMap;
  isSavingRoute: boolean;
  isDeletingRoute: boolean;
  message: IMessage;

  isLoadingRoutePolies: boolean;
  isLoadingAreaPolies: boolean;
  isLoadingExistingShops: boolean;
  isLoadingNewShops: boolean;

  softReloadAreaOrderBookerInfo: (areaId: number) => any;
  reloadOrderBookerRouteInfoFromDatabase: (areaId: number) => any;
  addOrUpdateRoute: (loginUserId: number, routeToAddOrUpdate: IRoute) => any;
  deleteRoute: (areaId: number, routeId: number) => any;
  setNewRouteSetupPageSuccessMessage: (message: string) => any;
  setNewRouteSetupPageErrorMessageAction: (message: string) => any;
  resetNewRouteSetupPageMessageAction: () => any;
  softReloadRoutePoliesAction: (areaId: number) => any;
  hardReloadRoutePoliesAction: (areaId: number) => any;
  setSelectedRouteIdOnMapAction: (sRouteId: ISelectedRouteIdOnMap) => any;

  hardReloadNewShopsAction: (areaId: number) => any;
  softReloadNewShopsAction: (areaId: number) => any;
  hardReloadExistingShopsAction: (areaId: number) => any;
  softReloadExistingShopsAction: (areaId: number) => any;
  hardReloadAreaPoliesAction: (areaId: number) => any;
  softReloadAreaPoliesAction: (areaId: number) => any;
}
const NewRouteSetupComponent = (props: IProps) => {
  //#region New Working (START)

  //#region Page State Variables
  const ref = React.useRef();
  const loginUser = useSelector((state: IApplicationState) => state.loginUser);
  const initialRoute: IRoute = {
    AddedShopIds: [],
    AreaId: props.areaId,
    BoundaryExistingShops: 0,
    BoundaryNewShops: 0,
    CenterPointLatitude: 0,
    CenterPointLongitude: 0,
    CoveredShops: 0,
    CreatedById: loginUser?.UserId ?? 0,
    CreatedOn: new Date(),
    EmployeeId: 0,
    EmployeeName: '',
    ExistingShopIds: [],
    Id: 0,
    IsActive: true,
    IsNew: 1,
    NeverCoveredShops: 0,
    PendingCredit: 0,
    PolygonData: '',
    RouteDay: 0,
    RouteDayName: '',
    RoutePoly: [],
    RouteTitle: '',
    TotalAddedShops: 0,
    TotalExistingShops: 0,
    TotalShops: 0,
    UpdatedById: loginUser?.UserId ?? 0,
    UpdatedOn: new Date(),
    OrderBookerToAssignRoute: 0,
    RouteDayToAssign: 0,
  };

  const [routePoliesOnMap, setRoutePoliesOnMap] = React.useState<IRoutePolyOnMap[]>([]);
  const [areaPoliesOnMap, setAreaPoliesOnMap] = React.useState<IAreaPolyOnMap[]>([]);
  const [selectedOrderBookerId, setSelectedOrderBookerId] = React.useState<number>(props.orderBookerId);
  const [currentMap, setCurrentMap] = React.useState<google.maps.Map>();
  const [newShopsMarker, setNewShopsMarker] = React.useState<google.maps.Marker[]>([]);
  const [existingShopsMarker, setExistingShopsMarker] = React.useState<google.maps.Marker[]>([]);
  const orderBookersRouteInfo = useSelector((state: IApplicationState) => {
    if (!!state && !!state.orderBookerRouteInfo) {
      const areaOrderBookerRouteInfo = state.orderBookerRouteInfo.find(m => m.areaId === props.areaId);

      if (!!areaOrderBookerRouteInfo && !!areaOrderBookerRouteInfo.orderBookerInfo) {
        return areaOrderBookerRouteInfo.orderBookerInfo;
      } else {
        return [];
      }
    } else {
      return [];
    }
  });

  const routePolies = useSelector((state: IApplicationState) => {
    if (!!state && !!state.routePolies) {
      const polies = state.routePolies.find(m => m.areaId === props.areaId);

      if (!!polies && !!polies.routePolies) {
        return polies.routePolies;
      } else {
        return [];
      }
    } else {
      return [];
    }
  });

  const areaPolies = useSelector((state: IApplicationState) => {
    if (!!state && !!state.areaPolies) {
      const polies = state.areaPolies.find(m => m.areaId === props.areaId);

      if (!!polies && !!polies.areaPolies) {
        return polies.areaPolies;
      } else {
        return [];
      }
    } else {
      return [];
    }
  });

  const existingShops = useSelector((state: IApplicationState) => {
    if (!!state && !!state.existingShops) {
      const shops = state.existingShops.find(m => m.areaId === props.areaId);

      if (!!shops && !!shops.existingShops) {
        return shops.existingShops;
      } else {
        return [];
      }
    } else {
      return [];
    }
  });

  const newShops = useSelector((state: IApplicationState) => {
    if (!!state && !!state.newShops) {
      const shops = state.newShops.find(m => m.areaId === props.areaId);

      if (!!shops && !!shops.newShops) {
        return shops.newShops;
      } else {
        return [];
      }
    } else {
      return [];
    }
  });

  const [showExistingShops, setShowExistingShops] = React.useState<boolean>(false);
  const assignedRouteColor = 'green';
  const unassignedRouteColor = 'red';
  const [showAssignment, setShowAssignment] = React.useState<boolean>(false);
  const [selectedRouteToModify, setSelectedRouteToModify] = React.useState<IRoute>(getCopy(initialRoute));
  const [isNewRouteEnabled, setIsNewRouteEnabled] = React.useState<boolean>(false);
  const [drawingManager, setDrawingManager] = React.useState<google.maps.drawing.DrawingManager>(null);
  const [isDirty, setIsDirty] = React.useState<boolean>(false);
  const [popup, setPopUp] = React.useState<IMapPopUpConfirmation>(null);

  const setSelectedRouteId = (sRouteId: ISelectedRouteIdOnMap) => {
    props.setSelectedRouteIdOnMapAction(sRouteId);
  }

  const selectedRouteId = useSelector((state: IApplicationState) => state.selectedRouteIdOnMap);

  const isLoading = React.useMemo(() => {
    return (
      props.isLoadingAreaPolies === true ||
      props.isLoadingRoutePolies === true ||
      props.isLoadingNewShops === true ||
      props.isLoadingExistingShops === true ||
      props.isLoadingOrderBookerRouteInfo === true ||
      props.isDeletingRoute === true ||
      props.isSavingRoute === true
    )
  }, [
    props.isLoadingAreaPolies,
    props.isLoadingRoutePolies,
    props.isLoadingNewShops,
    props.isLoadingExistingShops,
    props.isLoadingOrderBookerRouteInfo,
    props.isDeletingRoute,
    props.isSavingRoute
  ])
  //#endregion

  //#region Use Memo Hooks
  const orderBookersAutoComplete = React.useMemo(() => {
    if (!!orderBookersRouteInfo && orderBookersRouteInfo.length > 0) {
      let bookers: IAutoComplete[] = orderBookersRouteInfo.map(a => { return { DisplayText: a.EmployeeName, Value: a.EmployeeId }; });
      return getCopy(bookers);
    }

    return [];
  }, [orderBookersRouteInfo]);

  const orderBookersAutoCompleteWithoutUnAssigned = React.useMemo(() => {
    if (!!orderBookersRouteInfo && orderBookersRouteInfo.length > 0) {
      let copy = getCopy(orderBookersRouteInfo);
      let bookers: IAutoComplete[] = copy.filter(m => m.EmployeeId !== UnAssignedOrderBookerId).map(a => { return { DisplayText: a.EmployeeName, Value: a.EmployeeId }; });
      return getCopy(bookers);
    }

    return [];
  }, [orderBookersRouteInfo]);

  const routesAutoComplete = React.useMemo(() => {
    if (!!orderBookersRouteInfo && orderBookersRouteInfo.length > 0 && !!routePolies && routePolies.length > 0) {
      const orderBookerToPickRoutes = orderBookersRouteInfo.find(m => m.EmployeeId == selectedOrderBookerId);
      if (!!orderBookerToPickRoutes && !!orderBookerToPickRoutes.Routes && orderBookerToPickRoutes.Routes.length > 0) {
        const routesCopy = getCopy(orderBookerToPickRoutes.Routes).filter(m => m.RouteId > 0);
        let obRoutes: IAutoComplete[] = routesCopy.map(a => {
          return { DisplayText: a.RouteName, Value: a.RouteId };
        });

        return getCopy(obRoutes);
      }
    }

    return [];
  }, [orderBookersRouteInfo, selectedOrderBookerId, routePolies]);


  const RouteDayToAssignAutoComplete = React.useMemo(() => {
    let options: IAutoComplete[] = [];
    if (!!selectedRouteToModify && !!selectedRouteToModify.OrderBookerToAssignRoute && selectedRouteToModify.OrderBookerToAssignRoute > 0) {
      const orderBookerToCheck = getCopy(orderBookersRouteInfo).find(m => m.EmployeeId === selectedRouteToModify.OrderBookerToAssignRoute);

      if (!!orderBookerToCheck && !!orderBookerToCheck.Routes && orderBookerToCheck.Routes.findIndex(m => m.RouteId > 0) >= 0) {
        const alreadyAssignedDays = orderBookerToCheck.Routes.filter(m => m.RouteId > 0);
        RouteDayDataSource.forEach(element => {
          options.push({ DisplayText: `${element.DisplayText}${alreadyAssignedDays.findIndex(m => m.RouteDay === element.Value) >= 0 ? `    (Assigned)` : ''}`, Value: element.Value })
        });
      } else {
        options = getCopy(RouteDayDataSource);
      }
    }

    return getCopy(options);
  }, [orderBookersRouteInfo, selectedRouteToModify, RouteDayDataSource])

  //#endregion

  //#region events
  const onNewRouteButtonClick = () => {
    resetPageMessage();
    setIsDirty((prevVal) => {
      if (prevVal == true) {
        setPopUp({
          title: 'Reset Unsaved Changes',
          message: 'You have unsaved changes. Are you sure you want to reset changes and create new route?',
          titleColor: 'red',
          messageColor: 'red',
          yesButtonColor: 'red',
          noButtonColor: 'green',
          onOk: () => {
            setIsNewRouteEnabled(true);
            setSelectedRouteId({ selectedRouteId: 0 });
            setSelectedOrderBookerId(0);
            setIsDirty(false);
            setPopUp(null);
          }
        })

        return true;
      } else {
        setIsNewRouteEnabled(true);
        setSelectedRouteId({ selectedRouteId: 0 });
        setSelectedOrderBookerId(0);
        return false;
      }
    })
  }

  const onOrderBookerAutoCompleteChange = (option: IAutoComplete) => {
    resetPageMessage();
    setIsDirty((prevVal) => {
      if (prevVal == true) {
        setPopUp({
          title: 'Reset Unsaved Changes',
          message: 'Your Changes will be lost. Are you sure you want to change Order Booker?',
          titleColor: 'red',
          messageColor: 'red',
          yesButtonColor: 'red',
          noButtonColor: 'green',
          onOk: () => {
            if (!!option) {
              setSelectedOrderBookerId(option.Value);
            } else {
              setSelectedOrderBookerId(0);
            }

            setSelectedRouteId({ selectedRouteId: 0 });
            setIsDirty(false);
            setPopUp(null);
          }
        })

        return true;
      } else {
        if (!!option) {
          setSelectedOrderBookerId(option.Value);
        } else {
          setSelectedOrderBookerId(0);
        }

        setSelectedRouteId({ selectedRouteId: 0 });
        return false;
      }
    })
  }

  const modifyStateOfRoutePoliesOnMap = (routeId: number) => {
    setRoutePoliesOnMap(rpOnMap => {
      let routePoliesOnMapToSetEdit = [...rpOnMap];
      let routeIdsToRemove: number[] = [];
      if (!!routePoliesOnMapToSetEdit && routePoliesOnMapToSetEdit.length > 0) {
        routePoliesOnMapToSetEdit.forEach(ap => {
          if (ap.routeId <= 0) {
            ap.polygon.setMap(null);

            if (!!ap.infoWindow) {
              ap.infoWindow.close();
            }

            routeIdsToRemove.push(ap.routeId);
          } else {
            if (ap.routeId == routeId) {
              if (ap.polygon.getEditable() === false) {
                ap.polygon.setEditable(true);
                currentMap.fitBounds(getPolygonBounds(ap.polygon))
              } else {
                resetPolygonOnMap(ap.polygon, ap.routeId, getCopy(routePolies).filter(m => m.RouteId === ap.routeId), ap.infoWindow);
                ap.polygon.setEditable(true);
              }
            } else {
              resetPolygonOnMap(ap.polygon, ap.routeId, getCopy(routePolies).filter(m => m.RouteId === ap.routeId), ap.infoWindow);
            }
          }
        });
      }
      return [...routePoliesOnMapToSetEdit.filter(m => routeIdsToRemove.findIndex(t => m.routeId == t) < 0)];
    })
  }
  const onRoutePolyClick = (routePolyOnMap: google.maps.Polygon, routeId) => {
    resetPageMessage();
    setIsDirty((prevVal) => {
      if (prevVal == true) {
        setPopUp({
          title: 'Unsaved Changes',
          message: 'Your Changes will be lost. Are you sure you want to change route?',
          titleColor: 'red',
          messageColor: 'red',
          yesButtonColor: 'red',
          noButtonColor: 'green',
          onOk: () => {
            setIsDirty(false);
            setIsNewRouteEnabled(false);
            setSelectedRouteId({ selectedRouteId: routeId });
            setPopUp(null);
          }
        })

        return true;
      } else {
        setIsNewRouteEnabled(false);
        setSelectedRouteId({ selectedRouteId: routeId });
        return false;
      }
    })
  }

  const onAssignToOrderBookerAutoCompleteChange = (option: IAutoComplete) => {
    setIsDirty(true);
    resetPageMessage();
    setSelectedRouteToModify((routeToModify) => {
      return { ...routeToModify, OrderBookerToAssignRoute: option?.Value ?? 0 }
    })
  }
  const onRouteAutoCompleteChange = (option: IAutoComplete) => {
    resetPageMessage();
    setIsDirty((prevVal) => {
      if (prevVal == true) {
        setPopUp({
          title: 'Reset Unsaved Changes',
          message: 'Your Changes will be lost. Are you sure you want to change route?',
          titleColor: 'red',
          messageColor: 'red',
          yesButtonColor: 'red',
          noButtonColor: 'green',
          onOk: () => {
            setIsNewRouteEnabled(false);
            if (!!option) {
              setSelectedRouteId({ selectedRouteId: (option.Value ?? 0) });
            } else {
              setSelectedRouteId({ selectedRouteId: 0 });
            }
            setIsDirty(false);
            setPopUp(null);
          }
        })

        return true;
      } else {
        setIsNewRouteEnabled(false);
        if (!!option) {
          setSelectedRouteId({ selectedRouteId: (option.Value ?? 0) });
        } else {
          setSelectedRouteId({ selectedRouteId: 0 });
        }
        return false;
      }
    })
  }

  const onUpdateRoutePoly = (routeId: number, overlay: google.maps.Polygon, infoWindow: google.maps.InfoWindow) => {
    resetPageMessage();
    setIsDirty(true);
    setIsNewRouteEnabled(false);
    let newRoutePolies: IRoutePoly[] = [];
    let arr = overlay.getPath().getArray();
    let pointOrder = 0;
    arr.forEach(element => {
      pointOrder += 1;
      newRoutePolies.push({
        RouteId: routeId,
        Id: 0,
        IsActive: true,
        PointLatitude: element.lat(),
        PointLongitude: element.lng(),
        PointOrder: pointOrder,
      });
    });

    setSelectedRouteToModify((prevVal) => { return { ...prevVal, RoutePoly: getCopy(newRoutePolies) } });


    setRoutePoliesOnMap((ePolies) => {
      let copy = [...ePolies];
      return [...copy.filter(m => m.routeId !== routeId), { routeId: routeId, polygon: overlay, infoWindow: infoWindow }]
    });
  }


  const onRouteNameInputChange = (event: any) => {
    resetPageMessage();
    setIsDirty(true);
    setSelectedRouteToModify((routeToModify) => {
      return { ...routeToModify, RouteTitle: event.target.value };
    });
  }

  const onRouteDayAutoCompleteChange = (option: IAutoComplete) => {
    resetPageMessage();
    setIsDirty(true);
    setSelectedRouteToModify((routeToModify) => {
      return { ...routeToModify, RouteDayToAssign: option?.Value ?? 0 };
    })
  }

  const toggleAssignmentDetail = () => {
    resetPageMessage();
    setShowAssignment((assignment) => !assignment);
  }

  const onSaveClick = () => {
    resetPageMessage();
    setSelectedRouteToModify((toModify) => {
      if (validate(toModify)) {
        let routeToSave = getCopy(toModify);

        setShowAssignment((assignment) => {
          if (assignment == false) {
            routeToSave.OrderBookerToAssignRoute = null;
            routeToSave.RouteDayToAssign = null;
            routeToSave.CreatedById = loginUser?.UserId ?? 0;
            routeToSave.CreatedOn = new Date();
            routeToSave.UpdatedById = loginUser?.UserId ?? 0;
            routeToSave.UpdatedOn = new Date();
            routeToSave.AreaId = props.areaId;
            saveRouteToDatabase(routeToSave);
          } else {
            if (!!orderBookersRouteInfo && orderBookersRouteInfo.findIndex(ri => ri.EmployeeId === routeToSave.OrderBookerToAssignRoute && !!ri.Routes && ri.Routes.findIndex(ari => ari.RouteDay === routeToSave.RouteDayToAssign && ari.RouteId !== routeToSave.Id && ari.RouteId > 0) >= 0) >= 0) {
              let routeTitle: string = '';
              let routeDay: string = '';
              const obToPick = orderBookersRouteInfo.find(m => m.EmployeeId === routeToSave.OrderBookerToAssignRoute);
              const routeToPick = obToPick.Routes.find(r => r.RouteDay === routeToSave.RouteDayToAssign);

              setPopUp({
                title: 'Assignment Alreay Exists',
                message: `${obToPick.EmployeeName} already have "${routeToPick.RouteName}" on ${routeToPick.RouteDayName}. Are you sure you want to replace?`,
                titleColor: 'green',
                messageColor: 'red',
                yesButtonColor: 'red',
                noButtonColor: 'green',
                onOk: () => {
                  saveRouteToDatabase(routeToSave);
                  setPopUp(null);
                }
              })
            } else {
              saveRouteToDatabase(routeToSave);
            }
          }

          return assignment;
        })
      }

      return toModify;
    })
  }

  const saveRouteToDatabase = (routeToModify: IRoute) => {
    props.addOrUpdateRoute(loginUser?.UserId ?? 0, getCopy(routeToModify));
  }

  const onDeleteClick = () => {
    resetPageMessage();
    setSelectedRouteToModify((prevVal) => {
      setPopUp({
        title: 'Route Delete',
        message: `Are you sure you want to delete "${prevVal.RouteTitle}"?`,
        titleColor: 'red',
        messageColor: 'red',
        yesButtonColor: 'red',
        noButtonColor: 'green',
        onOk: () => {
          props.deleteRoute(props.areaId, prevVal.Id);

          setPopUp(null);
        }
      });
      return prevVal;
    })
  }

  const onReset = () => {
    resetPageMessage();
    setIsDirty((prevVal) => {
      if (prevVal == true) {
        setPopUp({
          title: 'Reset Unsaved Changes',
          message: 'Are you sure you want to reset changes?',
          titleColor: 'red',
          messageColor: 'red',
          yesButtonColor: 'red',
          noButtonColor: 'green',
          onOk: () => {
            setIsNewRouteEnabled(false);
            setShowExistingShops(false);
            setSelectedRouteId({ selectedRouteId: 0 });
            setSelectedOrderBookerId(0);
            setIsDirty(false);
            setPopUp(null);
          }
        })

        return true;
      } else {
        return false;
      }
    })
  }
  //#endregion

  //#region page util methods

  const showErrorMessage = (message: string) => addMessage(message, 'error');
  const showSuccessMessage = (message: string) => addMessage(message, 'success');
  const addMessage = (messageText: string, variant: 'success' | 'error' | 'info' | 'warning') => {
    if (variant === 'success') {
      props.setNewRouteSetupPageSuccessMessage(messageText);
    } else {
      props.setNewRouteSetupPageErrorMessageAction(messageText);
    }
  }

  const resetPageMessage = () => {
    props.resetNewRouteSetupPageMessageAction();
  }

  const addNewShopsMarkers = (map: google.maps.Map, shops: IMapShop[]) => {
    if (!!shops && shops.length > 0) {
      let markers: google.maps.Marker[] = [];

      shops.forEach(s => {
        const marker = new google.maps.Marker({
          position: { lat: s.Latitude, lng: s.Longitude },
          map: map,
          icon: '/static/green.png'
        });

        markers.push(marker);
      });

      setNewShopsMarker(markers);
    }
  }

  const addExistingShopsMarkers = (map: google.maps.Map, shops: IMapShop[]) => {
    if (!!shops && shops.length > 0) {
      let markers: google.maps.Marker[] = [];

      shops.forEach(s => {
        const marker = new google.maps.Marker({
          position: { lat: s.Latitude, lng: s.Longitude },
          map: map,
          icon: '/static/green.png'
        });

        markers.push(marker);
      });

      setExistingShopsMarker(markers);
    }
  }

  const removeShopMarkers = (markers: google.maps.Marker[]) => {
    if (!!markers && markers.length > 0) {
      markers.forEach(m => {
        m.setMap(null);
      });
    }
  }

  const validate = (toModify: IRoute) => {
    let copy = getCopy(toModify);
    if (!copy || !copy.RoutePoly || copy.RoutePoly.length <= 0) {
      showErrorMessage('Invalid Route Selection');
      return false;
    }

    if (!copy.RouteTitle || copy.RouteTitle.trim() == '') {
      showErrorMessage('Please Enter Route Name');
      return;
    }

    const aid = getAreaId(copy.RoutePoly);

    if (!aid || aid <= 0) {
      showErrorMessage('Invalid Route. Route Must be under an Area');
      return false;
    }

    const isOverlapping = isRouteOverlapping(copy.RoutePoly, copy.Id);

    if (isOverlapping == true) {
      showErrorMessage('Route Overlaps with other routes');
      return false;
    }

    if (showAssignment == true && (!copy?.OrderBookerToAssignRoute || copy.OrderBookerToAssignRoute <= 0)) {
      showErrorMessage('Please Select Order Booker To Assign Route');
      return false;
    }

    if (showAssignment == true && (!copy?.RouteDayToAssign || copy.RouteDayToAssign <= 0)) {
      showErrorMessage('Please Select Route Day To Assign Route');
      return false;
    }

    return true;
  }

  const getAreaId = (routePolyToCheck: IRoutePoly[]) => {
    let areaIdOfPoly = 0;

    if (!!routePolyToCheck && routePolyToCheck.length > 0) {
      const copyApOnMap = [...areaPoliesOnMap];
      const routePolyToCheckCopy = getCopy(routePolyToCheck);
      for (let i = 0; i < copyApOnMap.length; i++) {
        const ap = copyApOnMap[i];
        let anyPointOutside = routePolyToCheckCopy.findIndex(m => !google.maps.geometry.poly.containsLocation({ lat: m.PointLatitude, lng: m.PointLongitude }, ap.polygon)) >= 0;

        if (anyPointOutside == false) {
          areaIdOfPoly = ap.areaId;
          break;
        }
      }
    }

    return areaIdOfPoly;
  }

  const isRouteOverlapping = (routePolyToCheck: IRoutePoly[], routeId: number) => {
    let isOverlapping = false;

    if (!!routePolyToCheck && routePolyToCheck.length > 0) {
      const routePolyToCheckCopy = getCopy(routePolyToCheck);
      const copy = [...routePoliesOnMap];
      if (!!copy && copy.length > 0 && copy.findIndex(m => m.routeId != routeId) >= 0) {
        const otherRoutePolies = [...copy.filter(n => n.routeId !== routeId)];
        for (let i = 0; i < otherRoutePolies.length; i++) {
          const ap = otherRoutePolies[i];
          let anyPointInside = routePolyToCheckCopy.filter(m => google.maps.geometry.poly.containsLocation({ lat: m.PointLatitude, lng: m.PointLongitude }, ap.polygon))

          if (!!anyPointInside && anyPointInside.length > 0) {
            isOverlapping = true;
            break;
          }
        }
      }
    }

    return isOverlapping;
  }

  const getShopIdsInside = (polygon: google.maps.Polygon, shops: IMapShop[]) => {
    let shopIdsInside: number[] = [];
    if (!!shops && shops.length > 0 && !!polygon) {
      shops.forEach(sh => {
        if (google.maps.geometry.poly.containsLocation({ lat: sh.Latitude, lng: sh.Longitude }, polygon)) {
          shopIdsInside.push(sh.Id);
        }
      });
    }

    return shopIdsInside;
  }

  const drawAreasPolygons = (map: google.maps.Map, areaPolies: IAreaPoly[]) => {
    let areasPolyOnMap: IAreaPolyOnMap[] = [];
    if (!!areaPolies && areaPolies.length > 0) {
      const poly = areaPolies.sort((a, b) => a.PointOrder - b.PointOrder);
      let polyCoordinates = [];

      poly.forEach(p => {
        polyCoordinates.push({ lat: p.PointLatitude, lng: p.PointLongitude });
      });

      const color = 'yellow';
      const areaPolyOnMap = new google.maps.Polygon({
        paths: polyCoordinates,
        strokeColor: color,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: color,
        editable: false,
        fillOpacity: 0.05,
      });

      areasPolyOnMap.push({ areaId: props.areaId, polygon: areaPolyOnMap });
      areaPolyOnMap.setMap(map);
      if (props.routeId <= 0) {
        map.fitBounds(getPolygonBounds(areaPolyOnMap));
      }
      //map.setCenter(center)
    } else {
      if (!!areaPoliesOnMap && areaPoliesOnMap.length > 0) {
        areaPoliesOnMap.forEach(ap => {
          ap.polygon.setMap(null);
        });
      }
    }

    setAreaPoliesOnMap(areasPolyOnMap);
  }

  const resetPolygonOnMap = (polygon: google.maps.Polygon, routeId: number, rtPolies: IRoutePoly[], infoWindow: google.maps.InfoWindow) => {
    if (!!rtPolies && rtPolies.length > 0) {
      const rtPoliesCopy = getCopy(rtPolies);
      const poly = rtPoliesCopy.sort((a, b) => a.PointOrder - b.PointOrder);
      let polyCoordinates = [];

      poly.forEach(p => {
        polyCoordinates.push({ lat: p.PointLatitude, lng: p.PointLongitude });
      });

      polygon.setEditable(false);
      google.maps.event.addListener(polygon.getPath(), 'insert_at', function (index, obj) {
        onUpdateRoutePoly(routeId, polygon, infoWindow);
      });
      google.maps.event.addListener(polygon.getPath(), 'set_at', function (index, obj) {
        onUpdateRoutePoly(routeId, polygon, infoWindow);
      });

      polygon.unbindAll();
      polygon.addListener('click', (event) => {
        onRoutePolyClick(polygon, routeId);
      })
    }
  }

  const drawRoutePolygons = (map: google.maps.Map, obroutes: IOrderBookerRouteInfoNew[], rtPolies: IRoutePoly[]) => {
    setRoutePoliesOnMap((values) => {
      if (!!values && values.length > 0) {
        values.forEach(v => {
          v.polygon.setMap(null);
          if (!!v.infoWindow) {
            v.infoWindow.close();
          }
        })
      }

      return [];
    });

    let rPoliesOnMap: IRoutePolyOnMap[] = [];
    if (!!obroutes && obroutes.length > 0 && !!rtPolies && rtPolies.length > 0) {
      const routePoliesCopy = getCopy(rtPolies);
      obroutes.map(o => {
        const color = o.EmployeeId === UnAssignedOrderBookerId ? unassignedRouteColor : assignedRouteColor;
        if (!!o.Routes && o.Routes.length > 0) {
          o.Routes.map(r => {
            if (r.RouteId > 0) {
              const rPolies = routePoliesCopy.filter(m => m.RouteId == r.RouteId);
              if (!!rPolies && rPolies.length > 0) {
                const poly = rPolies.sort((a, b) => a.PointOrder - b.PointOrder);
                let polyCoordinates = [];

                poly.forEach(p => {
                  polyCoordinates.push({ lat: p.PointLatitude, lng: p.PointLongitude });
                });

                const routePolyOnMap = new google.maps.Polygon({
                  paths: polyCoordinates,
                  strokeColor: color,
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: color,
                  editable: selectedRouteId.selectedRouteId == r.RouteId,
                  fillOpacity: 0.1,
                  zIndex: 10000
                });

                var labelOptions = {
                  content: '<div style="color:' + (o.EmployeeId === UnAssignedOrderBookerId ? 'red' : 'black') + '">' + o.EmployeeName + (o.EmployeeId === UnAssignedOrderBookerId ? '' : ' (' + r.RouteDayName + ')') + '</div>',
                  boxStyle: {
                    textAlign: "center",
                    fontSize: "8pt",
                    width: "50px"
                  },
                  disableAutoPan: true,
                  pixelOffset: new google.maps.Size(-25, 0),
                  position: getCenter(routePolyOnMap),
                  closeBoxURL: "",
                  isHidden: false,
                  backgroundColor: 'green',
                  pane: "mapPane",
                  enableEventPropagation: true
                };

                var polygonLabel = new google.maps.InfoWindow(labelOptions);
                polygonLabel.open(map);

                if (selectedRouteId.selectedRouteId === r.RouteId) {
                  map.fitBounds(getPolygonBounds(routePolyOnMap));
                }


                google.maps.event.addListener(routePolyOnMap.getPath(), 'insert_at', function (index, obj) {
                  onUpdateRoutePoly(r.RouteId, routePolyOnMap, polygonLabel);
                });
                google.maps.event.addListener(routePolyOnMap.getPath(), 'set_at', function (index, obj) {
                  onUpdateRoutePoly(r.RouteId, routePolyOnMap, polygonLabel);
                });

                routePolyOnMap.unbindAll();
                routePolyOnMap.addListener('click', (event) => {
                  onRoutePolyClick(routePolyOnMap, r.RouteId);
                })
                // google.maps.event.addListener(routePolyOnMap, 'click', function (event) {
                //   onRoutePolyClick(routePolyOnMap, r.RouteId);
                // });

                rPoliesOnMap.push({ routeId: r.RouteId, polygon: routePolyOnMap, infoWindow: polygonLabel });
                routePolyOnMap.setMap(map);
              }
            }
          })
        }
      });
    }

    setRoutePoliesOnMap([...rPoliesOnMap]);
  }

  const getPolygonBounds = (poly: google.maps.Polygon) => {
    if (!!poly) {
      var bounds = new google.maps.LatLngBounds()
      poly.getPath().forEach(function (element, index) { bounds.extend(element) })
      return bounds
    }

    return null;
  }

  const getCenter = (poly: google.maps.Polygon) => {
    const bounds = getPolygonBounds(poly);
    if (!!bounds) {
      return bounds.getCenter();
    }

    return new google.maps.LatLng(0, 0);
  }
  //#endregion

  //#region API Calls

  const loadData = (areaId: number) => {
    props.softReloadAreaOrderBookerInfo(areaId);
    props.softReloadAreaPoliesAction(areaId);
    props.softReloadNewShopsAction(areaId);
    props.softReloadExistingShopsAction(areaId);
    props.softReloadRoutePoliesAction(areaId);
  }
  //#endregion

  //#region Use Effect Hooks

  React.useEffect(() => {
    if(!!currentMap){
      removeShopMarkers(showExistingShops == true ? [...newShopsMarker] : [...existingShopsMarker]);
      addExistingShopsMarkers(currentMap, showExistingShops == true ? existingShops : []);
    }
  }, [existingShops, showExistingShops, currentMap]);

  React.useEffect(() => {
    if(!!currentMap){
      removeShopMarkers([...existingShopsMarker]);
      addNewShopsMarkers(currentMap, showExistingShops == false ? newShops : []);
    }
  }, [newShops, showExistingShops, currentMap]);

  React.useEffect(() => {
    if(!!currentMap){
      drawAreasPolygons(currentMap, areaPolies);
    }
  }, [areaPolies, currentMap]);

  React.useEffect(() => {
    if(!!currentMap){
      modifyStateOfRoutePoliesOnMap(selectedRouteId.selectedRouteId);
      setIsDirty(false);
      if (selectedRouteId.selectedRouteId > 0) {
        if (!!orderBookersRouteInfo && orderBookersRouteInfo.length > 0 && orderBookersRouteInfo.findIndex(m => !!m.Routes && m.Routes.length > 0 && m.Routes.findIndex(n => n.RouteId == selectedRouteId.selectedRouteId && !!routePolies && routePolies.findIndex(rt => rt.RouteId == selectedRouteId.selectedRouteId) >= 0) >= 0) >= 0) {
          const orderBookerToPickRoute = orderBookersRouteInfo.find(m => !!m.Routes && m.Routes.length > 0 && m.Routes.findIndex(n => n.RouteId == selectedRouteId.selectedRouteId && routePolies.findIndex(rt => rt.RouteId == selectedRouteId.selectedRouteId) >= 0) >= 0);
          const routeToSetValues = orderBookerToPickRoute.Routes.find(n => n.RouteId == selectedRouteId.selectedRouteId && routePolies.findIndex(rt => rt.RouteId == selectedRouteId.selectedRouteId) >= 0);
          const routePoliesToSet = getCopy(routePolies).filter(m => m.RouteId === selectedRouteId.selectedRouteId).sort((a, b) => a.PointOrder - b.PointOrder);
          const newShopIds = getShopIdsInside(routePoliesOnMap.find(p => p.routeId == routeToSetValues.RouteId)?.polygon, newShops);
          const existingShopIds = getShopIdsInside(routePoliesOnMap.find(p => p.routeId == routeToSetValues.RouteId)?.polygon, existingShops);
          setSelectedOrderBookerId(orderBookerToPickRoute.EmployeeId);
          setSelectedRouteToModify({
            AddedShopIds: newShopIds,
            AreaId: props.areaId,
            BoundaryExistingShops: existingShopIds.length,
            BoundaryNewShops: newShopIds.length,
            CenterPointLatitude: 0,
            CenterPointLongitude: 0,
            CoveredShops: routeToSetValues.AttachedShops ?? 0,
            CreatedById: loginUser?.UserId ?? 0,
            CreatedOn: new Date(),
            EmployeeId: orderBookerToPickRoute.EmployeeId,
            EmployeeName: orderBookerToPickRoute.EmployeeName,
            ExistingShopIds: existingShopIds,
            Id: routeToSetValues.RouteId,
            IsActive: true,
            IsNew: 1,
            NeverCoveredShops: routeToSetValues.NeverAttachedShops ?? 0,
            PendingCredit: routeToSetValues.PendingCredit ?? 0,
            PolygonData: '',
            RouteDay: routeToSetValues.RouteDay ?? 0,
            RouteDayName: routeToSetValues.RouteDayName ?? '',
            RoutePoly: routePoliesToSet,
            RouteTitle: routeToSetValues.RouteName ?? '',
            TotalAddedShops: newShopIds.length,
            TotalExistingShops: existingShopIds.length,
            TotalShops: routeToSetValues.TotalShops,
            UpdatedById: loginUser?.UserId ?? 0,
            UpdatedOn: new Date(),
            OrderBookerToAssignRoute: 0,
            RouteDayToAssign: 0,
          });
        } else {
          setSelectedRouteToModify(getCopy({ ...initialRoute, Id: selectedRouteId.selectedRouteId }));
        }
      } else {
        setSelectedRouteToModify(getCopy({ ...initialRoute, Id: selectedRouteId.selectedRouteId }));
      }
    }
  }, [selectedRouteId, currentMap])

  React.useEffect(() => {
    if(!!currentMap){
      setSelectedRouteToModify((prevVal) => {
        if (!!prevVal && !!orderBookersRouteInfo && orderBookersRouteInfo.length > 0 && orderBookersRouteInfo.findIndex(m => !!m.Routes && m.Routes.length > 0 && m.Routes.findIndex(n => n.RouteId == prevVal.Id && n.RouteId > 0) >= 0) >= 0) {
          const orderBookerToPickRoute = orderBookersRouteInfo.find(m => !!m.Routes && m.Routes.length > 0 && m.Routes.findIndex(n => n.RouteId == prevVal.Id && n.RouteId > 0) >= 0);
          const routeToSetValues = orderBookerToPickRoute.Routes.find(n => n.RouteId == prevVal.Id && n.RouteId > 0);
          setSelectedOrderBookerId(orderBookerToPickRoute.EmployeeId);
          return {
            ...getCopy(prevVal),
            CoveredShops: routeToSetValues.AttachedShops ?? 0,
            EmployeeId: orderBookerToPickRoute.EmployeeId,
            EmployeeName: orderBookerToPickRoute.EmployeeName,
            Id: routeToSetValues.RouteId,
            IsActive: true,
            IsNew: 1,
            NeverCoveredShops: routeToSetValues.NeverAttachedShops ?? 0,
            PendingCredit: routeToSetValues.PendingCredit ?? 0,
            RouteDay: routeToSetValues.RouteDay ?? 0,
            RouteDayName: routeToSetValues.RouteDayName ?? '',
            RouteTitle: routeToSetValues.RouteName ?? '',
            TotalShops: routeToSetValues.TotalShops,
          }
        } else {
          return prevVal;
        }
      });
    }
  }, [orderBookersRouteInfo, currentMap]);

  React.useEffect(() => {
    if(!!currentMap){
      setSelectedRouteToModify((prevVal) => {
        if (!!prevVal && !!routePoliesOnMap && routePoliesOnMap.findIndex(m => m.routeId === prevVal.Id) >= 0) {
          const poly = routePoliesOnMap.find(p => p.routeId == prevVal.Id)?.polygon;
          const center = getCenter(poly);
          const newShopIds = getShopIdsInside(poly, newShops);
          const existingShopIds = getShopIdsInside(poly, existingShops);
          return {
            ...getCopy(prevVal),
            AddedShopIds: newShopIds,
            BoundaryExistingShops: existingShopIds.length,
            BoundaryNewShops: newShopIds.length,
            CenterPointLatitude: center.lat(),
            CenterPointLongitude: center.lng(),
            ExistingShopIds: existingShopIds,
            TotalAddedShops: newShopIds.length,
            TotalExistingShops: existingShopIds.length,
            TotalShops: showExistingShops == true ? existingShopIds.length : newShopIds.length,
          };
        } else {
          return prevVal;
        }
      }); 
    }
  }, [routePoliesOnMap, newShops, existingShops, showExistingShops]);

  React.useEffect(() => {
    if(!!currentMap){
      drawRoutePolygons(currentMap, orderBookersRouteInfo, routePolies);

      setSelectedRouteToModify((prevVal) => {
        if (!!prevVal) {
          const routePoliesToSet = getCopy(routePolies).filter(m => m.RouteId === selectedRouteId.selectedRouteId).sort((a, b) => a.PointOrder - b.PointOrder);
          return {
            ...getCopy(prevVal),
            RoutePoly:routePoliesToSet
          };
        } else {
          return prevVal;
        }
      }); 
    }
  }, [routePolies, orderBookersRouteInfo, currentMap]);

  React.useEffect(() => {
    if(!!currentMap){
      if (!!drawingManager) {
        if (isNewRouteEnabled === true) {
          drawingManager.setMap(currentMap);
        } else {
          drawingManager.setMap(null);
        }
      }
    }
  }, [isNewRouteEnabled, drawingManager, currentMap])

  React.useEffect(() => {
    const map = new window.google.maps.Map(ref.current, {
      center: props.center,
      zoom: props.zoom,
      mapTypeId: window.google.maps.MapTypeId.ROADMAP,
      gestureHandling: 'greedy',
      mapTypeControl: false,
    });

    map.controls[google.maps.ControlPosition.TOP_LEFT].push(document.getElementById("controlBox"))
    map.controls[google.maps.ControlPosition.LEFT_TOP].push(document.getElementById("controlBox2"))
    map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(document.getElementById("messagesControlBox"))
    map.controls[google.maps.ControlPosition.TOP_RIGHT].push(document.getElementById("existingShopsSwitchControl"));
    map.controls[google.maps.ControlPosition.RIGHT_CENTER].push(document.getElementById("loadingControl"));
    map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(document.getElementById("ConfirmControl"));

    const dManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [google.maps.drawing.OverlayType.POLYGON],
      },
      polygonOptions: {
        editable: true
      }
    });

    google.maps.event.addListener(dManager, "overlaycomplete", function (event) {
      onUpdateRoutePoly(0, event.overlay, null);

      google.maps.event.addListener(event.overlay.getPath(), 'insert_at', function (index, obj) {
        onUpdateRoutePoly(0, event.overlay, null);
      });
      google.maps.event.addListener(event.overlay.getPath(), 'set_at', function (index, obj) {
        onUpdateRoutePoly(0, event.overlay, null);
      });
    });


    //drawingManager.setMap(map);
    //getRoutesOnMap(map);
    setDrawingManager(dManager);
    setCurrentMap(map);
    loadData(props.areaId);
  }, [props.areaId]);

  React.useEffect(() =>{
    if(!!props.routeId && props.routeId > 0){
      setSelectedRouteId({selectedRouteId:props.routeId});
    }

    props.resetNewRouteSetupPageMessageAction();
  },[]);
   
  //#endregion
  //#endregion NEW WORKING (END)

  const [showSideBar, setShowSideBar] = React.useState<boolean>(true);
  return <><Page title="Routes | Raza Pak Care">
    <Container style={{ maxWidth: '100%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', height: 850, marginTop: 10 }}>
      <div style={{ width: '650px', height: '75px', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '10px', margin: '5px', borderRadius: 5, border: '3px solid #660000', backgroundColor: 'white' }} id="controlBox">
        <div><span className={'display-text'}><strong className='display-heading'>Area:</strong>{props.areaName}</span>
          <span className={'display-text'}><strong className='display-heading'>Assigned To:</strong>{!!selectedRouteToModify?.EmployeeName && selectedRouteToModify.EmployeeName.trim() !== '' ? selectedRouteToModify.EmployeeName : 'N/A'}</span>
          <span className={'display-text'}><strong className='display-heading'>Day:</strong>{!!selectedRouteToModify?.RouteDayName && selectedRouteToModify.RouteDayName.trim() !== '' ? selectedRouteToModify.RouteDayName : 'N/A'}</span>
          <span className={'display-text'} style={{ color: 'red' }}><strong className='display-heading'>Pend. Crdt.</strong>{!!selectedRouteToModify?.PendingCredit ? fCommaSeparated(selectedRouteToModify.PendingCredit) : 'N/A'}</span>
          <div style={{ marginTop: '5px', marginBottom: '5px' }}><span className={'display-text'}><strong className='display-heading'>Total Shops:</strong>{!!selectedRouteToModify?.TotalShops ? selectedRouteToModify.TotalShops : 'N/A'}</span>
            <span className={'display-text'} style={{ color: 'green' }}><strong className='display-heading'>Covrd. Shops:</strong>{!!selectedRouteToModify?.CoveredShops ? selectedRouteToModify.CoveredShops : 'N/A'}</span>
            <span className={'display-text'} style={{ color: 'red' }}><strong className='display-heading'>Nvr. Covrd. Shops:</strong>{!!selectedRouteToModify?.NeverCoveredShops ? selectedRouteToModify?.NeverCoveredShops : 'N/A'}</span>
          </div>
          <span className={'display-text'}><strong className='display-heading'>Route:</strong>{selectedRouteToModify.RouteTitle ?? 'N/A'}</span></div>
        <div style={{ position: 'absolute', right: 1, bottom: 2 }}>
          {isNewRouteEnabled == false && <span onClick={onNewRouteButtonClick} className='btn-text' style={{ color: 'blue' }}>Tools</span>}
          {(isDirty == true || (props.routeId > 0 && isNewRouteEnabled == true)) && <span onClick={onReset} className='btn-text' style={{ color: 'red' }}>Reset</span>}</div>
      </div>

      <div style={{ borderRadius: 3, maxWidth: 110, border: '1px solid #660000', backgroundColor: 'white', paddingLeft: '5px', paddingRight: '12px', marginTop: '10px', marginRight: '10px' }} id="existingShopsSwitchControl">
        <FormControlLabel labelPlacement='start' style={{ fontSize: '10px', marginLeft: 0 }} control={<Switch
          checked={!showExistingShops}
          size='small'
          onChange={() => {
            resetPageMessage();
            setShowExistingShops((prev) => !prev);
          }}
          inputProps={{ 'aria-label': 'controlled' }}
        />} label={<Typography style={{ fontSize: '11px' }} variant='body1'>New Shops</Typography>} />
      </div>

      <div style={{ width: showSideBar == true ? '325px' : '25px', height: '570px', padding: '10px', margin: '5px', borderRadius: 5, backgroundColor: showSideBar == true ? 'white' : undefined, border: '1px solid #660000' }} id="controlBox2">
        {showSideBar == true && <div onClick={() => {
          resetPageMessage();
          setShowSideBar(false);
        }} style={{ backgroundColor: 'gray', cursor: 'pointer', width: '25px', height: '25px', position: 'absolute', top: '50%', right: -13, borderRadius: '50%', justifyContent: 'center', display: 'flex' }}>
          <Icon style={{ alignSelf: 'center', borderRadius: 10, padding: 0, margin: 0 }} width={20} height={20} color='white' icon={'tabler:chevron-left'} />
        </div>}
        {showSideBar == false && <div onClick={() => {
          resetPageMessage();
          setShowSideBar(true);
        }} style={{ backgroundColor: 'gray', cursor: 'pointer', width: '25px', height: '25px', position: 'absolute', top: '50%', right: 0, borderRadius: '50%', justifyContent: 'center', display: 'flex' }}>
          <Icon style={{ alignSelf: 'center', borderRadius: 10, padding: 0, margin: 0 }} width={20} height={20} color='white' icon={'tabler:chevron-right'} />
        </div>}
        {showSideBar == true && <div style={{ width: '300px', margin: '5px' }}>
          <div style={{ margin: '5px' }}><CustomAutoCompleteForMap<IAutoComplete>
            Id={'Order_Booker_List'}
            Value={!!orderBookersAutoComplete && orderBookersAutoComplete.length > 0 ? (orderBookersAutoComplete.find(m => m.Value == selectedOrderBookerId) ?? null) : null}
            Options={orderBookersAutoComplete}
            displayField={'DisplayText'}
            valueField={'Value'}
            Label={'Order Booker'}
            onChange={onOrderBookerAutoCompleteChange}
            placeholder='Select Order Booker'
            size='small'
          /></div>

          <div style={{ margin: '5px', marginTop: '30px' }}><CustomAutoCompleteForMap<IAutoComplete>
            Id={'Routes_List'}
            Value={!!routesAutoComplete && routesAutoComplete.length > 0 ? (routesAutoComplete.find(m => !!m && m.Value == selectedRouteId.selectedRouteId) ?? null) : null}
            Options={routesAutoComplete}
            displayField={'DisplayText'}
            valueField={'Value'}
            Label={'Route'}
            onChange={onRouteAutoCompleteChange}
            size='small'
            placeholder='Select Route'
          /></div>
          <div style={{ marginTop: '35px' }}>
            <hr />
          </div>

          <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
            <span><strong style={{ fontSize: '13px' }}>Working Area</strong></span>
          </div>
          <div style={{ marginTop: '10px' }}>
            <TextField
              id={'Route Name'}
              fullWidth
              placeholder={'Enter Route Name'}
              label={'Route Name'}
              multiline={true}
              rows={3}
              value={selectedRouteToModify.RouteTitle}
              onChange={onRouteNameInputChange}
              InputProps={{ style: { fontSize: '12px' } }}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <span style={{ alignSelf: 'center' }} onClick={() => {
              if ((!!selectedRouteToModify.RouteTitle && selectedRouteToModify.RouteTitle.trim() !== '') || showAssignment === true) {
                toggleAssignmentDetail();
              }
            }} className={(!!selectedRouteToModify.RouteTitle && selectedRouteToModify.RouteTitle.trim() !== '') || showAssignment === true ? 'btn-text-assign-enabled' : 'btn-text-assign-disabled'}>
              {showAssignment == true ? 'Hide Assign' : 'Show Assign'}
            </span>
          </div>
          {showAssignment == true && <div>
            <div style={{ marginTop: '10px' }}>
              <CustomAutoCompleteForMap<IAutoComplete>
                Id={'Order_Booker_List_AssignTo'}
                Value={!!orderBookersAutoCompleteWithoutUnAssigned ? orderBookersAutoCompleteWithoutUnAssigned.find(m => m.Value === selectedRouteToModify.OrderBookerToAssignRoute) : null}
                Options={orderBookersAutoCompleteWithoutUnAssigned}
                displayField={'DisplayText'}
                valueField={'Value'}
                Label={'Assign To'}
                onChange={onAssignToOrderBookerAutoCompleteChange}
                placeholder='Assign To'
                size='small'
              />
            </div>
            <div style={{ marginTop: '10px' }}>
              <CustomAutoCompleteForMap<IAutoComplete>
                Id={'Assign_Day_List'}
                Value={!!RouteDayToAssignAutoComplete ? (RouteDayToAssignAutoComplete.find(m => m.Value === selectedRouteToModify.RouteDayToAssign) ?? null) : null}
                Options={RouteDayToAssignAutoComplete}
                displayField={'DisplayText'}
                valueField={'Value'}
                Label={'Day'}
                onChange={onRouteDayAutoCompleteChange}
                placeholder='Select Day'
                size='small'
              />
            </div>
          </div>}
          <div style={{ position: 'absolute', bottom: 10, right: 10, justifyContent: 'flex-end' }}>
            <LoadingButton
              size="small"
              type="button"
              variant="outlined"
              disabled={isDirty == false}
              style={{ alignSelf: 'center', margin: 3, borderRadius: 0, color: isDirty == false ? 'gray' : 'blue', borderColor: 'blue', textAlign: 'right' }}
              startIcon={<Icon icon={selectedRouteId.selectedRouteId > 0 ? 'dashicons:update' : 'fluent:save-28-regular'} />}
              onClick={onSaveClick}
            >
              {selectedRouteId.selectedRouteId > 0 ? 'Update' : 'Save'}
            </LoadingButton>
          </div>
          <div style={{ position: 'absolute', bottom: 10, left: 10, justifyContent: 'flex-end' }}>
            {selectedRouteId.selectedRouteId > 0 && <LoadingButton
              size="small"
              type="button"
              variant="outlined"
              style={{ alignSelf: 'center', margin: 3, borderRadius: 0, borderColor: 'red', color: 'red', textAlign: 'right' }}
              startIcon={<Icon icon={'iconamoon:trash-thin'} />}
              onClick={onDeleteClick}
            >
              Delete
            </LoadingButton>}
          </div>
        </div>}
      </div>
      <div id='messagesControlBox' style={{ margin: '5px' }}>
        <MessageComponent onRemove={(i: number) => {
          resetPageMessage();
        }} messages={!!props.message && props.message.messageText && props.message.messageText.trim() !== '' ? [props.message] : []} />
      </div>

      <div id='loadingControl' style={{ display: 'flex', width: (isLoading === true) ? '100%' : '0px', height: (isLoading === true) ? '100%' : '0px', justifyContent: 'center' }}>
        {(isLoading === true) && <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', backgroundColor: 'white', width: '50px', height: '50px' }}><CircularProgress style={{ alignSelf: 'center' }} /></div>}
      </div>
      <div id='ConfirmControl' style={{ height: !!popup ? '100%' : 0, width: !!popup ? '100%' : 0 }}>
        {!!popup && <MapConfirmationPopup params={popup} onClose={() => { setPopUp(null); }} />}
      </div>
      <div style={{ width: '100%', height: '100%' }} ref={ref} id="map" />
    </Container>
  </Page>
  </>
    ;

}

const mapStateToProps = (state: IApplicationState) => {
  return {
    isLoadingOrderBookerRouteInfo: state.isLoadingOrderBookerRouteInfo,
    selectedAreaId: state.selectedAreaId,

    selectedRouteId: state.selectedRouteIdOnMap,
    isSavingRoute: state.isSavingNewRoute,
    isDeletingRoute: state.isDeletingRoute,
    message: state.newRouteSetupPageMessage,

    isLoadingRoutePolies: state.isLoadingRoutePolies,
    isLoadingAreaPolies: state.isLoadingAreaPolies,
    isLoadingExistingShops: state.isLoadingAreaExistingShops,
    isLoadingNewShops: state.isLoadingAreaNewShops,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    softReloadAreaOrderBookerInfo: (areaId: number) => dispatch(softReloadAreaOrderBookerInfoAction(areaId)),
    reloadOrderBookerRouteInfoFromDatabase: (areaId: number) => dispatch(hardReloadAreaOrderBookerInfoAction(areaId)),

    addOrUpdateRoute: (loginUserId: number, routeToAddOrUpdate: IRoute) => dispatch(addOrUpdateRouteAction(loginUserId, routeToAddOrUpdate)),
    deleteRoute: (areaId: number, routeId: number) => dispatch(deleteRouteAction(areaId, routeId)),
    setNewRouteSetupPageSuccessMessage: (message: string) => dispatch(setNewRouteSetupPageSuccessMessageAction(message)),
    setNewRouteSetupPageErrorMessageAction: (message: string) => dispatch(setNewRouteSetupPageErrorMessageAction(message)),
    resetNewRouteSetupPageMessageAction: () => dispatch(resetNewRouteSetupPageMessageAction()),
    softReloadRoutePoliesAction: (areaId: number) => dispatch(softReloadRoutePoliesAction(areaId)),
    hardReloadRoutePoliesAction: (areaId: number) => dispatch(hardReloadRoutePoliesAction(areaId)),
    setSelectedRouteIdOnMapAction: (sRouteId: ISelectedRouteIdOnMap) => dispatch(setSelectedRouteIdOnMapAction(sRouteId)),

    hardReloadNewShopsAction: (areaId: number) => dispatch(hardReloadNewShopsAction(areaId)),
    softReloadNewShopsAction: (areaId: number) => dispatch(softReloadNewShopsAction(areaId)),
    hardReloadExistingShopsAction: (areaId: number) => dispatch(hardReloadExistingShopsAction(areaId)),
    softReloadExistingShopsAction: (areaId: number) => dispatch(softReloadExistingShopsAction(areaId)),
    hardReloadAreaPoliesAction: (areaId: number) => dispatch(hardReloadAreaPoliesAction(areaId)),
    softReloadAreaPoliesAction: (areaId: number) => dispatch(softReloadAreaPoliesAction(areaId)),
  }
}

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(NewRouteSetupComponent);

export { connectedComponent as NewRouteSetupComponent };