import { IShopUpdateRequest } from 'src/interfaces/shop-update-request.interface';
import { IAutoComplete } from '../interfaces/auto-complete.interface';
import { http } from './http-base';
import { concatParam } from 'src/utility';
import { IRouteShopDetail } from 'src/interfaces/route-shop-detail.interface';
import { UnAssignedOrderBookerId } from 'src/app.constant';
import { IShopRequest } from 'src/interfaces/shop-request.interface';



const changeShopRoute = async (
  shopId:number,
  toRouteId:number
): Promise<any> => {
  let url: string = `api/Shop/UpdateShopRoute?shopId=${shopId}&toRouteId=${toRouteId}`;
  let result = await http
    .post(url, null)
    .catch(error => Promise.reject(error));
  return result.data;
};

const updateShopCreditability = async (
    shopId:number,
    creditabilityId:number
  ): Promise<any> => {
    let url: string = `api/Shop/UpdateShopCreditability?shopId=${shopId}&creditabilityId=${creditabilityId}`;
    let result = await http
      .post(url, null)
      .catch(error => Promise.reject(error));
    return result.data;
  };

  const updateShopDetail = async (
    loginUserId:number,
    shop:IShopUpdateRequest
  ): Promise<any> => {
    let url: string = `api/Shop/UpdateShopDetail?loginUserId=${loginUserId}`;
    let result = await http
      .post(url, shop)
      .catch(error => Promise.reject(error));
    return result.data;
  };

  const updateShopCreditStatus = async (
    shopId:number,
    creditStatusId:number
  ): Promise<any> => {
    let url: string = `api/Shop/UpdateShopCreditStatus?shopId=${shopId}&creditStatusId=${creditStatusId}`;
    let result = await http
      .post(url, null)
      .catch(error => Promise.reject(error));
    return result.data;
  };

  const deActivateShop = async (
    userId:number,
    shopId:number
  ): Promise<any> => {
    let url: string = `api/Shop/DeActivateShop?userId=${userId}&shopId=${shopId}`;
    let result = await http
      .post(url, null)
      .catch(error => Promise.reject(error));
    return result.data;
  };

  const searchShops = async (
    areaId:number,
    orderBookerId:number,
    shopTypeId:number,
    routeId:number,
    searchText:string
    ): Promise<IRouteShopDetail[]> => {
      let prams = '';
      prams = concatParam(prams, areaId, 'areaId');

      if(orderBookerId === UnAssignedOrderBookerId || orderBookerId > 0){
        prams = concatParam(prams, orderBookerId, 'orderBookerId');
      }

      if(shopTypeId > 0){
        prams = concatParam(prams, shopTypeId, 'shopTypeId');
      }

      if(routeId > 0){
        prams = concatParam(prams, routeId, 'routeId');
      }

      if(!!searchText && searchText.trim() !== ''){
        prams = concatParam(prams, encodeURIComponent(searchText), 'searchText');
      }
  
      let url: string = `api/Shop/Search${prams}`;
      let result = await http
        .get<IRouteShopDetail[]>(url)
        .catch(error => Promise.reject(error));
      return result.data;
    };

    const getPendingShopRequests = async (
      areaId:number,
      loginUserId:number,
      orderBookerId?:number | null
      ): Promise<IShopRequest[]> => {
        let prams = '';
        prams = concatParam(prams, areaId, 'areaId');
  
        prams = concatParam(prams, areaId, 'areaId');
        prams = concatParam(prams, loginUserId, 'loginUserId');
        if(!!orderBookerId && orderBookerId > 0){
          prams = concatParam(prams, orderBookerId, 'orderBookerId');
        }
    
        let url: string = `api/Shop/PendingShopRequests${prams}`;
        let result = await http
          .get<IShopRequest[]>(url)
          .catch(error => Promise.reject(error));
        return result.data;
      };

      const updateShopRequest = async (
        loginUserId:number,
        shop:IShopRequest
      ): Promise<any> => {
        let url: string = `api/Shop/UpdateShopRequest?loginUserId=${loginUserId}`;
        let result = await http
          .post(url, shop)
          .catch(error => Promise.reject(error));
        return result.data;
      };

    const deActivateShopRequest = async (
        loginUserId:number,
        uniqueId:string
      ): Promise<any> => {
        let url: string = `api/Shop/DeActivateShopRequest?loginUserId=${loginUserId}&uniqueId=${uniqueId}`;
        let result = await http
          .post(url, null)
          .catch(error => Promise.reject(error));
        return result.data;
      };

      const getMatchingShops = async (
        areaId:number,
        latitude:number,
        longitude:number,
        shopName:string,
        shopAddress:string,
        contactName:string,
        contactCellOne:string,
        contactCellTwo:string
        ): Promise<IRouteShopDetail[]> => {
          let prams = '';
          prams = concatParam(prams, areaId, 'areaId');
          prams = concatParam(prams, latitude, 'latitude');
          prams = concatParam(prams, longitude, 'longitude');
          prams = concatParam(prams, !!shopName ? encodeURIComponent(shopName) : '%02%03', 'shopName');
          prams = concatParam(prams, !!shopAddress ? encodeURIComponent(shopAddress) :'%02%03', 'shopAddress');
          prams = concatParam(prams, !!contactName ? encodeURIComponent(contactName) : '%02%03', 'contactName');
          prams = concatParam(prams, !!contactCellOne ? encodeURIComponent(contactCellOne) : '%02%03', 'contactCellOne');
          prams = concatParam(prams, !!contactCellTwo ? encodeURIComponent(contactCellTwo) : '%02%03', 'contactCellTwo');
      
          let url: string = `api/Shop/MatchingShops${prams}`;
          let result = await http
            .get<IRouteShopDetail[]>(url)
            .catch(error => Promise.reject(error));
          return result.data;
        };

        const processShopRequest = async (
          requestUniqueId:string,
          isFakeLocation:boolean,
          loginUserId:number,
          replaceToShopId:number
        ): Promise<any> => {
          let prams = '';
          prams = concatParam(prams, requestUniqueId, 'requestUniqueId');
          prams = concatParam(prams, isFakeLocation, 'isFakeLocation');
          prams = concatParam(prams, loginUserId, 'loginUserId');

          if(!!replaceToShopId && replaceToShopId > 0){
            prams = concatParam(prams, replaceToShopId, 'replaceToShopId');
          }

          let url: string = `api/Shop/ProcessShopRequest${prams}`;
          let result = await http
            .post(url, null)
            .catch(error => Promise.reject(error));
          return result.data;
        };



const shopApi = {
    changeShopRoute,
    updateShopCreditability,
    updateShopCreditStatus,
    deActivateShop,
    updateShopDetail,
    searchShops,
    getPendingShopRequests,
    updateShopRequest,
    deActivateShopRequest,
    getMatchingShops,
    processShopRequest
};

export { shopApi };
