import React from 'react';
import { useSelector } from 'react-redux';
import { MessageComponent } from 'src/components/messages.component';
import { areaApi, employeeApi, masterDataApi, routeApi } from 'src/http';
import { IMessage } from 'src/interfaces/message.interface';
import { IApplicationState } from 'src/redux';
import { confirmAction } from 'src/utility/confirm.util';
import { fCommaSeparated, fCurrency } from 'src/utils/formatNumber';
import { Audio as AudoSpin } from 'react-loader-spinner'
import Page from 'src/components/Page';
import { Container, Grid, TextField } from '@mui/material';
import { CustomAutoComplete } from 'src/components/common/CustomAutoComplete';
import { IAutoComplete } from 'src/interfaces/auto-complete.interface';
import '../page-styles/route-detail.css';
import { IRouteShopDetail } from 'src/interfaces/route-shop-detail.interface';
import { ShopsComponent } from 'src/components/ShopsComponent';
import '../extension-methods/extension-methods';
import { useLocation } from 'react-router-dom';
import { RouteDetailParam, UnAssignedOrderBookerId } from 'src/app.constant';
import { decodeBase64String, getCopy, isNumber } from 'src/utility';
import { LoadingButton } from '@mui/lab';
import { shopApi } from 'src/http/shop.http';
import { ShopRequestsComponent } from 'src/components/ShopRequestsComponent';
import { IShopRequest } from 'src/interfaces/shop-request.interface';

interface IProps{
    scrollToTop:() => void;
}

export const ShopRequestsContainer = (props:IProps) => {

    const search = useLocation().search;
    const queryOrderBookerId = new URLSearchParams(search).get(RouteDetailParam.OrderBookerId);
    const queryAreaId = new URLSearchParams(search).get(RouteDetailParam.AreaId);

    const showErrorMessage = (message: string) => addMessage(message, 'error');
    const showInfoMessage = (message: string) => addMessage(message, 'info');
    const showSuccessMessage = (message: string) => addMessage(message, 'success');
    const showWarningMessage = (message: string) => addMessage(message, 'warning');
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isLoadingShops, setIsLoadingShops] = React.useState<boolean>(false);

    const [areasForAutoComplete, setAreasForAutoComplete] = React.useState<IAutoComplete[]>([]);
    const [isAreaAutoCompleteLoading, setIsAreaAutoCompleteLoading] = React.useState<boolean>(false);
    const [selectedAreaId, setSelectedAreaId] = React.useState<number>(0);
    const [orderBookersAutoComplete, setOrderBookersAutoComplete] = React.useState<IAutoComplete[]>([]);
    const [isOrderBookerAutoCompoleteLoading, setIsOrderBookerAutoCompleteLoading] = React.useState<boolean>(false);
    const [selectedOrderBookerId, setSelectedOrderBookerId] = React.useState<number>(0);

    const [shopInfo, setShopInfo] = React.useState<IShopRequest[]>([]);
    const loginUser = useSelector((state: IApplicationState) => state.loginUser);

    const [pageMessages, setPageMessages] = React.useState<IMessage[]>([]);
    const addMessage = (messageText: string, variant: 'success' | 'error' | 'info' | 'warning') => {
        if (!!messageText && messageText.trim() != '') {
            let msgs = [];
            msgs.push({
                messageText,
                variant
            });

            setPageMessages([...msgs]);
        }
    }

    const clearMessages = () => {
        setPageMessages([]);
    }

    const clearMessagesExceptSuccess = () => {
        setPageMessages((messages: IMessage[]) => [...messages.filter(pm => pm.variant == 'success')]);
    }

    const onAreaChange = (option: IAutoComplete) => {
        setSelectedAreaId(option?.Value ?? 0);
        processOnAreaChange(option?.Value ?? 0);
    }

    const processOnAreaChange = (newAreaId: number) => {
        setSelectedOrderBookerId(0);
        setOrderBookersAutoComplete([]);
        setShopInfo([]);
        setPageMessages([]);

        if (newAreaId > 0) {
            loadOrderBookers(newAreaId, false);
        }
    }

    const onOrderBookerChange = (option: IAutoComplete) => {
        setSelectedOrderBookerId(option?.Value ?? 0);
        processOnOrderBookerChange(selectedAreaId, option?.Value ?? 0);
    }

    const processOnOrderBookerChange = (areaId: number, orderBookerId: number) => {
        setShopInfo([]);
        setPageMessages([])
    }

    const onSearch = () => {
        setPageMessages([]);
        setShopInfo([]);

        if (validate()) {
            loadData()
        }
    }


    const validate = () => {
        if (!selectedAreaId || selectedAreaId <= 0) {
            showErrorMessage('Please Select Area');
            return false;
        }

        return true;
    }

    const getAndSetAreasAutoComplete = () => {
        setIsAreaAutoCompleteLoading(true);
        areaApi.getAreasAutoComplete(loginUser?.UserId ?? 0)
            .then(acomplete => {
                let items = [...acomplete];
                //items.unshift({ Value: 0, DisplayText: 'All' });
                setAreasForAutoComplete([...items]);

                if (!!queryAreaId && queryAreaId.trim() != '') {
                    const parsedAreaId = parseInt(decodeBase64String(queryAreaId));
                    setSelectedAreaId(parsedAreaId);
                    loadOrderBookers(parsedAreaId, true);
                } else {
                    if (!items || items.length > 1) {
                        setSelectedAreaId(0);
                    } else {
                        setSelectedAreaId(items[0].Value);
                        loadOrderBookers(items[0].Value, true);
                    }
                }
                setIsAreaAutoCompleteLoading(false);
            })
            .catch(e => {
                setAreasForAutoComplete([]);
                setIsAreaAutoCompleteLoading(false);
            });
    }

    const loadOrderBookers = (areaId: number, isFirstLoad: boolean) => {
        if (areaId > 0) {
            setIsOrderBookerAutoCompleteLoading(true);
            employeeApi.getOrderBookerAutoComplete(areaId)
                .then(res => {
                    if (!!res && res.length > 0) {
                        const items:IAutoComplete[] = [{DisplayText:'All', Value:-1},...getCopy(res)];
                        setOrderBookersAutoComplete([...items]);
                        setSelectedOrderBookerId(-1);
                    } else {
                        setOrderBookersAutoComplete([]);
                    }

                    setIsOrderBookerAutoCompleteLoading(false);
                })
                .catch(e => {
                    setIsOrderBookerAutoCompleteLoading(false);
                    setOrderBookersAutoComplete([]);
                });
        } else {
            setOrderBookersAutoComplete([]);
        }
    }

    const loadData = () => {
        setIsLoadingShops(true);
        shopApi.getPendingShopRequests(selectedAreaId, loginUser?.UserId ?? 0, selectedOrderBookerId)
            .then(res => {
                if (!!res && res.length > 0) {
                    setShopInfo([...res]);
                } else {
                    resetShopDetails();
                }
                setIsLoadingShops(false)
                //setTimeout(() => {setIsLoadingShops(false)},1);
            })
            .catch(e => {
                setIsLoadingShops(false)
                resetShopDetails();
            });
    }

    const resetShopDetails = () => {
        setShopInfo([]);
    }

    const setShopsOnAction = (shops: IShopRequest[]) => {
        setShopInfo(getCopy(shops));
    }

    React.useEffect(() => {
        getAndSetAreasAutoComplete();
    }, []);

    return <Page title="Shop Requests | Raza Pak Care">
        <Container style={{ paddingBottom: 20, paddingTop: 10, maxWidth: '100%' }}><MessageComponent onRemove={(i: number) => {
            let msgs = [...pageMessages];
            msgs.splice(i, 1);
            setPageMessages([...msgs]);
        }} messages={pageMessages} />
        </Container>
        <Container style={{ paddingBottom: 10, maxWidth: '100%' }}>
            <Grid container spacing={2}>
                <Grid item lg={3} xl={3} md={3} xs={12}>
                    {<CustomAutoComplete<IAutoComplete>
                        Id={'Area_List_Auto_Complete'}
                        Value={!!areasForAutoComplete && areasForAutoComplete.length > 0 && selectedAreaId > 0 ? areasForAutoComplete.find(m => m.Value == selectedAreaId) : null}
                        Options={areasForAutoComplete}
                        displayField={'DisplayText'}
                        valueField={'Value'}
                        Label={'Areas'}
                        onChange={onAreaChange}
                        size='small'
                        isLoading={isAreaAutoCompleteLoading}
                        readOnly={isAreaAutoCompleteLoading || isOrderBookerAutoCompoleteLoading || isLoadingShops}
                    />}
                </Grid>
                <Grid item lg={3} xl={3} md={3} xs={12}>
                    {<CustomAutoComplete<IAutoComplete>
                        Id={'Order_booker_AutoComplete'}
                        Value={!!orderBookersAutoComplete && orderBookersAutoComplete.length > 0 && selectedOrderBookerId != 0 ? orderBookersAutoComplete.find(m => m.Value == selectedOrderBookerId) : null}
                        Options={orderBookersAutoComplete ?? []}
                        displayField={'DisplayText'}
                        valueField={'Value'}
                        Label={'Order Booker'}
                        onChange={onOrderBookerChange}
                        size='small'
                        isLoading={isOrderBookerAutoCompoleteLoading}
                        readOnly={isAreaAutoCompleteLoading || isOrderBookerAutoCompoleteLoading || isLoadingShops}
                    />}
                </Grid>
                <Grid item lg={3} xl={3} md={3} xs={12} style={{ justifyContent: 'flex-start', display: 'flex' }}>
                    <LoadingButton
                        size="small"
                        type="button"
                        variant="outlined"
                        loading={isLoading}
                        style={{ alignSelf: 'center', marginRight: 10, borderRadius: 0 }}
                        onClick={onSearch}
                    >
                        Search
                    </LoadingButton>
                </Grid>
            </Grid>
        </Container>

        <ShopRequestsComponent
            isLoading={isLoadingShops}
            shops={shopInfo}
            loginUserId={loginUser?.UserId ?? 0}
            showErrorMessage={showErrorMessage}
            showInfoMessage={showInfoMessage}
            showSuccessMessage={showSuccessMessage}
            showWarningMessage={showWarningMessage}
            setShopsOnAction={setShopsOnAction}
            resetPageMessage={() => {
                setPageMessages([]);
            }}
            scrollToTop={props.scrollToTop}
            key={`ShopsRequestComponentKey1`}
        />
    </Page>;
}