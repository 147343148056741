import React from 'react';
import { masterDataApi } from 'src/http';
import { formatedDateTime, getCopy, isValidCellNumber, scrollToTop, stableSort, timeAgo } from 'src/utility';
import { confirmAction } from 'src/utility/confirm.util';
import { fCommaSeparated } from 'src/utils/formatNumber';
import { Card, Container, Table, TableBody, TableCell, TableContainer, TableRow, CircularProgress } from '@mui/material';
import { CustomAutoComplete } from 'src/components/common/CustomAutoComplete';
import { IAutoComplete } from 'src/interfaces/auto-complete.interface';
import { Icon } from '@iconify/react';
import { UserListHead } from 'src/components/_dashboard/user';
import { IRouteShopDetail } from 'src/interfaces/route-shop-detail.interface';
import { ShopRouteChangePopup } from 'src/components/ShopRouteChangePopup';
import './component-styles/shops-component.css';
import { Highlighted } from './common/highlighted.component';
import { IShopRouteChangeParam } from 'src/interfaces/shop-route-change-param.interface';
import { shopApi } from 'src/http/shop.http';
import { EditShopPopup } from './EditShopPopup.component';
import { IShopUpdateRequest } from 'src/interfaces/shop-update-request.interface';
import { IShopRequest } from 'src/interfaces/shop-request.interface';
import { EditShopRequestPopup } from './EditShopRequestPopup';
import { ApproveShopRequestPopup } from './ApproveShopRequestPopup';

type Order = 'asc' | 'desc';

const SHOP_TABLE_HEAD = [
    { id: 'action_button_chevron', label: '', alignContent: 'left', enableSort: false },
    { id: 'ShopName', label: 'Name', alignContent: 'left', enableSort: true },
    { id: 'ContactName', label: 'Contact Name', alignContent: 'left', enableSort: true },
    { id: 'CellNumber', label: 'Cell#', alignContent: 'left', enableSort: true },
    { id: 'StreetAddress', label: 'Address', alignContent: 'left', enableSort: true },
    { id: 'action_button', label: '', alignContent: 'right', enableSort: false },
];

interface IProps {
    loginUserId: number;
    shops: IShopRequest[];
    isLoading: boolean;
    searchText?: string;
    showErrorMessage: (message: string) => void;
    showInfoMessage?: (message: string) => void;
    showSuccessMessage?: (message: string) => void;
    showWarningMessage?: (message: string) => void;
    resetPageMessage:() => void;
    setShopsOnAction: (shops: IShopRequest[]) => void;
    scrollToTop:() => void;
}

export const ShopRequestsComponent = (props: IProps) => {
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof IShopRequest>(null);
    const [searchText, setSearchText] = React.useState<string>('');
    const [shopForEdit, setShopForEdit] = React.useState<IShopRequest>(null);
    const [shopForApprove, setShopForApprove] = React.useState<IShopRequest>(null);
    const [isShopTypesLoading, setIsShopTypesLoading] = React.useState<boolean>(false);
    const [shopTypes, setShopTypes] = React.useState<IAutoComplete[]>([]);

    const [isShopInLoading, setIsShopInLoading] = React.useState<boolean>(false);
    const [shopIn, setShopIn] = React.useState<IAutoComplete[]>([]);

    const [isShopSizeLoading, setIsShopSizeLoading] = React.useState<boolean>(false);
    const [shopSize, setShopSize] = React.useState<IAutoComplete[]>([]);

    const onShopCollapseChange = (uniqueId: string) => {
        if(props.resetPageMessage){
            props.resetPageMessage();
        }

        let copy = getCopy(props.shops);
        let shopinfosToModify = copy.filter(m => m.UniqueId === uniqueId);
        shopinfosToModify.forEach(shopinfoToModify => {
            shopinfoToModify.IsCollapsed = !shopinfoToModify.IsCollapsed;
        })
        
        props.setShopsOnAction(copy);
    }

    const onDeleteShopRequest = (requestUniqueId: string) => {
        if(props.resetPageMessage){
            props.resetPageMessage();
        }

        confirmAction('Reject Shop Request', 'Are you sure, you want to reject shop request?', 'Yes', 'No','red', 'green','red','red')
            .then((isConfirmed: boolean) => {
                console.log(isConfirmed)
                if (isConfirmed === true) {
                    shopApi.deActivateShopRequest(props.loginUserId, requestUniqueId)
                        .then(() => {
                            let shopsWithoutDeletedShop = getCopy(props.shops).filter(m => m.UniqueId !== requestUniqueId);
                            props.setShopsOnAction(shopsWithoutDeletedShop);
                        })
                }
            })
    }

    const onApproveShopRequest = (request:IShopRequest) =>{
        if(validate(request)){
            props.resetPageMessage();
            setShopForApprove(getCopy(request));
        }else{
            props.scrollToTop();
        }
    }

    const validate = (request:IShopRequest) => {
        if (!request?.ShopName || request.ShopName.trim() === '') {
            props.showErrorMessage('Invalid Shop Name. Please Update Request Before Approve');
            return false;
        }

        if (!request?.ContactName || request.ContactName.trim() === '') {
            props.showErrorMessage('Request has Invalid Contact Name. Please Update Request Before Approve');
            return false;
        }

        if (!!request.ContactNumberOne && request.ContactNumberOne.trim() !== '' && !isValidCellNumber(request.ContactNumberOne)) {
            props.showErrorMessage('Invalid Cell One. Please update request before approve.');
            return false;
        }

        if (!!request.ContactNumberTwo && request.ContactNumberTwo.trim() !== '' && !isValidCellNumber(request.ContactNumberTwo)) {
            props.showErrorMessage('Invalid Cell Two. Please update request before approve');
            return false;
        }

        if (!request?.ShopTypeId || request.ShopTypeId <= 0) {
            props.showErrorMessage('Shop Type Is Required. Please update request before approve');
            return false;
        }

        if (!request?.ShopInId || request.ShopInId <= 0) {
            props.showErrorMessage('Shop In Is Required. Please update request before approve');
            return false;
        }

        if (!request?.ShopSizeId || request.ShopSizeId <= 0) {
            props.showErrorMessage('Shop Size Is Required. Please update request before approve');
            return false;
        }

        if (!request?.StreetAddress || request.StreetAddress.trim() === '') {
            props.showErrorMessage('Invalid Shop Address. Please update request before approve');
            return false;
        }

        if(request.StreetAddress.length < 10){
            props.showErrorMessage('Shop Address must be atleast 10 characters long. Please update request before approve');
            return false;
        }

        return true;
    }

    const loadShopTypes = () => {
        setIsShopTypesLoading(true);
        masterDataApi.getShopTypes()
            .then(res => {
                if (!!res && res.length > 0) {
                    setShopTypes(res.map(a => { return { DisplayText: a.TypeDescription, Value: a.Id } }))

                } else {
                    setShopTypes([]);
                }
                setIsShopTypesLoading(false);
            })
            .catch(e => {
                setIsShopTypesLoading(false);
                setShopTypes([]);
            });
    }

    const loadShopIn = () => {
        setIsShopInLoading(true);
        masterDataApi.getShopIn()
            .then(res => {
                if (!!res && res.length > 0) {
                    setShopIn(getCopy(res))

                } else {
                    setShopIn([]);
                }
                setIsShopInLoading(false);
            })
            .catch(e => {
                setIsShopInLoading(false);
                setShopIn([]);
            });
    }

    const loadShopSize = () => {
        setIsShopSizeLoading(true);
        masterDataApi.getShopSize()
            .then(res => {
                if (!!res && res.length > 0) {
                    setShopSize(getCopy(res))

                } else {
                    setShopSize([]);
                }
                setIsShopSizeLoading(false);
            })
            .catch(e => {
                setIsShopSizeLoading(false);
                setShopSize([]);
            });
    }

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof IShopRequest,
    ) => {
        if(props.resetPageMessage){
            props.resetPageMessage();
        }
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const orderedRows = React.useMemo(
        () => {
            let copy = getCopy(props.shops);

            if (!!searchText && !!copy && copy.length > 0) {
                if (searchText.trim() !== '') {
                    copy = copy.filter(c =>
                        (!!c.ShopName && c.ShopName.toLowerCase().indexOf(searchText.toLowerCase()) >= 0)
                        ||
                        (!!c.ContactName && c.ContactName.toLowerCase().indexOf(searchText.toLowerCase()) >= 0)
                        ||
                        (!!c.CellNumber && c.CellNumber.toLowerCase().indexOf(searchText.toLowerCase()) >= 0)
                        ||
                        (!!c.StreetAddress && c.StreetAddress.toLowerCase().indexOf(searchText.toLowerCase()) >= 0)
                    );
                }
            }

            if (!!orderBy) {
                return stableSort(copy, order, orderBy)
            } else {
                return copy;
            }
        },
        [order, orderBy, searchText, props.shops],
    );

    const onShopUpdateSuccess = (updatedValues: IShopRequest) => {
        setShopForEdit(null);
        let shopsToModify = getCopy(props.shops);
        let shopsToUpate = shopsToModify.filter(m => m.UniqueId === updatedValues.UniqueId);

        shopsToUpate.forEach(shopToUpdate => {
            shopToUpdate.ShopName = updatedValues.ShopName;
            shopToUpdate.ContactName = updatedValues.ContactName;
            shopToUpdate.ContactNumberOne = updatedValues.ContactNumberOne;
            shopToUpdate.ContactNumberTwo = updatedValues.ContactNumberTwo;
            shopToUpdate.ShopTypeId = updatedValues.ShopTypeId;
            shopToUpdate.ShopInId = updatedValues.ShopInId;
            shopToUpdate.ShopSizeId = updatedValues.ShopSizeId;
            shopToUpdate.StreetAddress = updatedValues.StreetAddress;

            if(!!updatedValues.ContactNumberOne && updatedValues.ContactNumberOne.trim() !== '' && !!updatedValues.ContactNumberTwo && updatedValues.ContactNumberTwo.trim() !== ''){
                shopToUpdate.CellNumber = `${updatedValues.ContactNumberOne}, ${updatedValues.ContactNumberTwo}`;
            }else if(!!updatedValues.ContactNumberOne && updatedValues.ContactNumberOne.trim() !== ''){
                shopToUpdate.CellNumber = `${updatedValues.ContactNumberOne}`;
            }else if(!!updatedValues.ContactNumberTwo && updatedValues.ContactNumberTwo.trim() !== ''){
                shopToUpdate.CellNumber = `${updatedValues.ContactNumberTwo}`;
            }else{
                shopToUpdate.CellNumber = '';
            }
        })

        props.setShopsOnAction(shopsToModify);
    }

    const handleRequestProces = (uniqueId:string) =>{
        setShopForApprove(null);
        props.showSuccessMessage('Shop Request Processed Successfully');
        let shopsToModify = getCopy(props.shops);
        let otherShops = shopsToModify.filter(m => m.UniqueId !== uniqueId);
        props.setShopsOnAction(otherShops);
    }

    const onSearchChange = (text: string) => {
        if(props.resetPageMessage){
            props.resetPageMessage();
        }
        setSearchText(text ?? '');
    }

    React.useEffect(() => {
        if (!props.shops || props.shops.length === 0) {
            setSearchText('');
            setOrderBy(null);
            setShopForEdit(null);
        }

    }, [props.shops]);

    React.useEffect(() => {
        loadShopTypes();
        loadShopSize();
        loadShopIn();
    },[]);

    return <React.Fragment>
        {<Container style={{ paddingBottom: 10, maxWidth: '100%' }}>
            <Card>
                {<TableContainer sx={{ minWidth: 800, maxWidth: '100%' }}>
                    <Table sx={{ borderSpacing: '0px 3px' }} size="small" stickyHeader>
                        <UserListHead
                            order={order}
                            orderBy={orderBy}
                            headLabel={SHOP_TABLE_HEAD}
                            rowCount={0}
                            numSelected={0}
                            onRequestSort={handleRequestSort}
                            onSelectAllClick={undefined}
                            customKey={`TableHeadRow_6_Shops_Table`}
                            typographyVariant='subtitle2'
                            backgroundColor={'#4279a3'}
                            textColor='white'
                            showSearchBox={true}
                            searchText={searchText}
                            onSearchChange={onSearchChange}
                        />
                        <TableBody>
                            {props.isLoading === true && <TableRow
                                key={'LoadingRow'}
                                tabIndex={-1}
                                role="checkbox"
                                selected={false}
                                aria-checked={false}
                            >
                                <TableCell align='center' colSpan={SHOP_TABLE_HEAD.length}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>}
                            {props.isLoading === false && (!orderedRows || orderedRows.length <= 0) && <TableRow
                                key={'LoadingRow'}
                                tabIndex={-1}
                                role="checkbox"
                                selected={false}
                                aria-checked={false}
                            >
                                <TableCell align='center' colSpan={SHOP_TABLE_HEAD.length}>
                                    {`No Data${!!searchText && searchText.trim() !== '' && !!orderedRows && orderedRows.length > 0 ? ` For "${searchText}"` : ''}`}
                                </TableCell>
                            </TableRow>}
                            {!!orderedRows && props.isLoading === false && orderedRows.length > 0 && orderedRows.map((row, index) => {
                                return (
                                    <React.Fragment key={`Shop_Item_${row.UniqueId}`}><TableRow
                                        hover
                                        key={row.UniqueId}
                                        tabIndex={-1}
                                        role="checkbox"
                                        selected={false}
                                        aria-checked={false}
                                        className={row.IsCollapsed === true ? 'shop' : 'shop-selected'}
                                        onClick={() => onShopCollapseChange(row.UniqueId)}
                                    >
                                        <TableCell padding="none" width="1%" align="left" style={{ paddingLeft: 10 }}>
                                            {row.IsCollapsed === false && <Icon color='black' style={{ cursor: 'pointer' }} width={25} height={25} icon={'mdi:chevron-up'} />}
                                            {row.IsCollapsed === true && <Icon color='black' style={{ cursor: 'pointer' }} width={25} height={25} icon={'mdi:chevron-down'} />}
                                        </TableCell>
                                        <TableCell width={'10%'} align="left" style={row.IsCollapsed === false ? { color: 'blue', fontWeight: 'bold' } : {}} className='cell-text'>
                                            <Highlighted text={`${row.ShopName}`} highlight={!!searchText && searchText.trim() !== '' ? searchText : (!!props.searchText && props.searchText.trim() !== '' ? props.searchText.trim() : '')} />
                                        </TableCell>
                                        <TableCell width={'10%'} align="left" className='cell-text'>

                                            <Highlighted text={`${row.IsCollapsed === true ? `${!!row.ContactName && row.ContactName.trim() !== '' ? row.ContactName : 'N/A'}` : ''}`} highlight={searchText} />
                                        </TableCell>
                                        <TableCell width={'15%'} align="left" className='cell-text'>
                                            <Highlighted text={`${row.IsCollapsed === true ? `${!!row.CellNumber && row.CellNumber.trim() !== '' ? row.CellNumber : 'N/A'}` : ''}`} highlight={searchText} />
                                        </TableCell>
                                        <TableCell width={'49%'} align="left" className='cell-text'>
                                            <Highlighted text={`${row.IsCollapsed === true ? `${!!row.StreetAddress && row.StreetAddress.trim() !== '' ? row.StreetAddress : 'N/A'}` : ''}`} highlight={searchText} />
                                        </TableCell>
                                        <TableCell width={'10%'} align='right'>
                                        <span onClick={(e) => {
                                                e.stopPropagation();
                                                if(props.resetPageMessage){
                                                    props.resetPageMessage();
                                                }
                                                setShopForEdit(getCopy(row));
                                            }} className='btn-text'>Edit</span>
                                        {<span onClick={(e) => {
                                                e.stopPropagation();
                                                onApproveShopRequest(row);
                                            }} className='btn-text' style={{ color: 'green' }}>Approve</span>}
                                            {<span onClick={(e) => {
                                                e.stopPropagation();
                                                onDeleteShopRequest(row.UniqueId);
                                            }} className='btn-text' style={{ color: 'red' }}>Reject</span>}
                                        </TableCell>
                                    </TableRow>
                                        {row.IsCollapsed === false && <TableRow key={`Shop_Detail_Card_${row.UniqueId}`}>
                                            <TableCell colSpan={SHOP_TABLE_HEAD.length}>
                                                <Table sx={{ borderCollapse: 'separate', borderSpacing: '0px 4px' }} size={'small'}>
                                                    <TableBody>
                                                        <TableRow
                                                            hover
                                                            key={`ShopDetail_${row.UniqueId}`}
                                                            tabIndex={-1}
                                                            role="checkbox"
                                                            selected={false}
                                                            aria-checked={false}
                                                        >
                                                            <TableCell className='' width={"15%"}>
                                                                <strong>Request Id:</strong>
                                                            </TableCell>
                                                            <TableCell className='' width="20%">
                                                                {row.Id}
                                                            </TableCell>
                                                            <TableCell colSpan={2} rowSpan={3} align='right'>
                                                                <div style={{ display: 'flex' }}><img style={{ alignSelf: 'center', display: 'block' }} src={row.ShopImageUrl} alt="Shop" width="150" height="100" /></div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            hover
                                                            key={`ShopDetail1_${row.UniqueId}`}
                                                            tabIndex={-1}
                                                            role="checkbox"
                                                            selected={false}
                                                            aria-checked={false}
                                                        >
                                                            <TableCell className=''>
                                                                <strong>Name:</strong>
                                                            </TableCell>
                                                            <TableCell className=''>
                                                                <Highlighted text={row.ShopName} highlight={!!searchText && searchText.trim() !== '' ? searchText : (!!props.searchText && props.searchText.trim() !== '' ? props.searchText.trim() : '')} />
                                                                {!!row && row.CellNumber.trim() !== '' && <span style={{ paddingLeft: 5 }}>
                                                                <Highlighted text={row.CellNumber} highlight={!!searchText && searchText.trim() !== '' ? searchText : (!!props.searchText && props.searchText.trim() !== '' ? props.searchText.trim() : '')} />
                                                                </span>}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            hover
                                                            key={`ShopDetail2_${row.UniqueId}`}
                                                            tabIndex={-1}
                                                            role="checkbox"
                                                            selected={false}
                                                            aria-checked={false}
                                                        >
                                                            <TableCell className=''>
                                                                <strong>Shop Type:</strong>
                                                            </TableCell>
                                                            <TableCell className=''>
                                                                {row.ShopType}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            hover
                                                            key={`ShopDetail3_${row.UniqueId}`}
                                                            tabIndex={-1}
                                                            role="checkbox"
                                                            selected={false}
                                                            aria-checked={false}
                                                        >
                                                            <TableCell className=''>
                                                                <strong>Requested By:</strong>
                                                            </TableCell>
                                                            <TableCell className=''>
                                                                {!!row.AddedBy && row.AddedBy.trim() !== '' ? row.AddedBy : 'N/A'}
                                                            </TableCell>
                                                            <TableCell className='' width="15%">
                                                                <strong>Route:</strong>
                                                            </TableCell>
                                                            <TableCell className=''>
                                                                <Highlighted text={!!row.AddedOnRouteName && row.AddedOnRouteName.trim() !== '' ? row.AddedOnRouteName : 'N/A'} highlight={searchText} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            hover
                                                            key={`ShopDetail4_${row.UniqueId}`}
                                                            tabIndex={-1}
                                                            role="checkbox"
                                                            selected={false}
                                                            aria-checked={false}
                                                        >
                                                            <TableCell className=''>
                                                                <strong>Requested On:</strong>
                                                            </TableCell>
                                                            <TableCell className=''>
                                                                {formatedDateTime(row.AddedOn, 'DD-MMM-YYYY hh:mm A')}
                                                            </TableCell>
                                                            <TableCell className=''>
                                                                <strong>Status:</strong>
                                                            </TableCell>
                                                            <TableCell className=''>
                                                                {row.RequestStatus}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            hover
                                                            key={`ShopDetail5_${row.UniqueId}`}
                                                            tabIndex={-1}
                                                            role="checkbox"
                                                            selected={false}
                                                            aria-checked={false}
                                                        >
                                                            <TableCell className=''>
                                                                <strong>Area:</strong>
                                                            </TableCell>
                                                            <TableCell className=''>
                                                                {row.AreaName}
                                                            </TableCell>
                                                            <TableCell className=''>
                                                                <strong>Address:</strong>
                                                            </TableCell>
                                                            <TableCell className=''>
                                                                <Highlighted text={`${(!!row.StreetAddress && row.StreetAddress.trim() !== '' ? row.StreetAddress : 'N/A')}`} highlight={!!searchText && searchText.trim() !== '' ? searchText : (!!props.searchText && props.searchText.trim() !== '' ? props.searchText.trim() : '')} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            hover
                                                            key={`ShopDetail6_${row.UniqueId}`}
                                                            tabIndex={-1}
                                                            role="checkbox"
                                                            selected={false}
                                                            aria-checked={false}
                                                        >
                                                            <TableCell className=''>
                                                                <strong>Shop In:</strong>
                                                            </TableCell>
                                                            <TableCell className=''>
                                                                {row.ShopIn}
                                                            </TableCell>
                                                            <TableCell className=''>
                                                                <strong>Shop Size:</strong>
                                                            </TableCell>
                                                            <TableCell className=''>
                                                                {row.ShopSize}
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableCell>
                                        </TableRow>}
                                    </React.Fragment>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>}
            </Card>
        </Container>}
        {!!shopForEdit && <EditShopRequestPopup
                                onClose={() => setShopForEdit(null)}
                                shopTypes={shopTypes}
                                shopIn={shopIn}
                                shopSize={shopSize}
                                shop={shopForEdit}
                                onSuccess={onShopUpdateSuccess} />}
        {!!shopForApprove && <ApproveShopRequestPopup
                                passedRequest={shopForApprove}
                                loginUserId={props.loginUserId}
                                onClose={() => {setShopForApprove(null)}}
                                onSuccess={handleRequestProces} />}
    </React.Fragment>
}