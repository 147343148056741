import { Container, Grid, Card, Typography, CardHeader, CardContent, Link, Divider, Chip } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import Label from 'src/components/Label';
import Page from 'src/components/Page';
import { IUserRouteDetail } from 'src/interfaces/routes/user-route-detail.interface';
import { IApplicationState } from 'src/redux';
import { useNavigate } from "react-router-dom";
import { Keys } from 'src/component.keys';
import { getUrlFromComponentKey } from 'src/utility/navigation.util';

interface IProps {
    userRoutes:IUserRouteDetail;
}

const Report = (props:IProps) => {
    const navigate = useNavigate();
    const [dateWiseCreditPageUrl, setDateWiseCreditPageUrl] = React.useState<string>('');
    const [creditReceiveHistoryPageUrl, setCreditReceiveHistoryPageUrl] = React.useState<string>('');

      const navigateToUrl = (url:string)=>{
        navigate(url);
      }

      React.useEffect(() =>{
        if(!!props.userRoutes && !!props.userRoutes.Routes && props.userRoutes.Routes.length > 0){
            setDateWiseCreditPageUrl(getUrlFromComponentKey(props.userRoutes,Keys.NewCreditVsRecovered));
            setCreditReceiveHistoryPageUrl(getUrlFromComponentKey(props.userRoutes,Keys.CreditReceiveHistory));
        }
    },[props.userRoutes]);

    return <Page title="Reports | Raza Pak Care">
        <Container style={{ maxWidth: 1500, paddingLeft: 0,paddingBottom:5, paddingRight: 0, marginTop: 50 }}>
            <Grid container spacing={2}>
                {!!dateWiseCreditPageUrl && dateWiseCreditPageUrl.trim() != '' && <Grid item md={3} >
                    <Card onClick={() => {
                        navigateToUrl(dateWiseCreditPageUrl);
                    }} style={{cursor:'pointer'}}>
                        <CardHeader
                            title={
                                <Link
                                    onClick={(e: any) => {
                                        e.stopPropagation();
                                        navigateToUrl(dateWiseCreditPageUrl);
                                    }}
                                    //to={appConfig.SiteUrl+report.resourceURL}
                                    variant="h5"
                                    style={{
                                        cursor: 'pointer', textOverflow: "ellipsis",
                                        overflow: "hidden", color: 'blue',
                                        textDecorationColor:'blue'
                                    }}
                                >
                                    Credit Report
                                </Link>
                            }
                        />
                        <CardContent>
                            New Credit VS Recovered Credit. Unit Wise, Date Wise And Order Booker Wise.
                            <Divider style={{backgroundColor:'gray',marginTop:5}} />
                            <Label style={{color:'white',backgroundColor:'rgba(255, 0, 0, 0.5)',marginTop:10}}>Credit</Label>
                        </CardContent>
                    </Card>
                </Grid>}

                {!!creditReceiveHistoryPageUrl && creditReceiveHistoryPageUrl.trim() != '' && <Grid item md={3} >
                    <Card onClick={() => {
                        navigateToUrl(creditReceiveHistoryPageUrl);
                    }} style={{cursor:'pointer'}}>
                        <CardHeader
                            title={
                                <Link
                                    onClick={(e: any) => {
                                        e.stopPropagation();
                                        navigateToUrl(creditReceiveHistoryPageUrl);
                                    }}
                                    //to={appConfig.SiteUrl+report.resourceURL}
                                    variant="h5"
                                    style={{
                                        cursor: 'pointer', textOverflow: "ellipsis",
                                        overflow: "hidden", color: 'blue',
                                        textDecorationColor:'blue'
                                    }}
                                >
                                    Credit Receive History
                                </Link>
                            }
                        />
                        <CardContent>
                            Credit Receive History Report.
                            <Divider style={{backgroundColor:'gray',marginTop:5}} />
                            <Label style={{color:'white',backgroundColor:'rgba(255, 0, 0, 0.5)',marginTop:10}}>Credit</Label>
                        </CardContent>
                    </Card>
                </Grid>}
            </Grid>
        </Container>
    </Page>;
}

const mapStateToProps = (state: IApplicationState) => {
    return {
        userRoutes:state.userRoutes
    };
}
const connectedReports = connect(mapStateToProps)(Report);
export { connectedReports as Report };