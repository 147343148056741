import {NewRouteSetupConstant} from '../../actions/action.constant';
import {IAction} from '../../actions/action.interface';
import {initialState} from '../../initial-state';

export const isSavingNewRouteReducer = (
  state: boolean = initialState.isSavingNewRoute,
  action: IAction<boolean>,
) => {
  switch (action.type) {
    case NewRouteSetupConstant.SET_IS_SAVING_NEW_ROUTE: {;
      return action.payload;
    }
    default:
      return state;
  }
};
