import React from 'react';
import { useSelector } from 'react-redux';
import { MessageComponent } from 'src/components/messages.component';
import { areaApi, employeeApi, masterDataApi, routeApi } from 'src/http';
import { IMessage } from 'src/interfaces/message.interface';
import { IApplicationState } from 'src/redux';
import { confirmAction } from 'src/utility/confirm.util';
import { fCommaSeparated, fCurrency } from 'src/utils/formatNumber';
import { Audio as AudoSpin } from 'react-loader-spinner'
import Page from 'src/components/Page';
import { Card, CircularProgress, Container, Grid, Table, TableBody, TableCell, TableContainer, TableRow, TextField } from '@mui/material';
import { CustomAutoComplete } from 'src/components/common/CustomAutoComplete';
import { IAutoComplete } from 'src/interfaces/auto-complete.interface';
import '../page-styles/tablet.css';
import { IRouteShopDetail } from 'src/interfaces/route-shop-detail.interface';
import { ShopsComponent } from 'src/components/ShopsComponent';
import '../extension-methods/extension-methods';
import { useLocation } from 'react-router-dom';
import { AllOptionUniqueId, RouteDetailParam, UnAssignedOrderBookerId } from 'src/app.constant';
import { decodeBase64String, formatedDateTime, getCopy, isNumber } from 'src/utility';
import { LoadingButton } from '@mui/lab';
import { shopApi } from 'src/http/shop.http';
import { ShopRequestsComponent } from 'src/components/ShopRequestsComponent';
import { IShopRequest } from 'src/interfaces/shop-request.interface';
import { ITabletInfo } from 'src/interfaces/tablet-info.interface';
import { IUnitAccess } from 'src/interfaces/unit-access.interface';
import { UserListHead } from 'src/components/_dashboard/user';
import { Icon } from '@iconify/react';
import { Highlighted } from 'src/components/common/highlighted.component';
import { ITabletComment } from 'src/interfaces/tablet-comment.interface';
import { tabletApi } from 'src/http/tablet.http';
import { AddNewTabletPopup } from 'src/components/AddNewTabletPopup';
import { IEnumOld } from 'src/interfaces/enum-old.interface';
import { EditTabletStatusPopup } from 'src/components/EditTabStatusPopup';
import { TransferTabletPopup } from 'src/components/TransferTabletPopup';


const TABLET_TABLE_HEAD = [
    { id: 'action_button_chevron', label: '', alignContent: 'left', enableSort: false },
    { id: 'TabName', label: 'Tab ID', alignContent: 'left', enableSort: true },
    { id: 'IMEIOne', label: 'Unique ID', alignContent: 'left', enableSort: true },
    { id: 'TabStatus', label: 'Status', alignContent: 'left', enableSort: true },
    { id: 'AssignToEmployee', label: 'Assign To', alignContent: 'left', enableSort: true },
    { id: 'action_button', label: '', alignContent: 'right', enableSort: false },
];

interface IProps {
}

export const ManageTabletContainer = (props: IProps) => {

    const showErrorMessage = (message: string) => addMessage(message, 'error');
    const showInfoMessage = (message: string) => addMessage(message, 'info');
    const showSuccessMessage = (message: string) => addMessage(message, 'success');
    const showWarningMessage = (message: string) => addMessage(message, 'warning');
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [selectedUnitUniqueId, setSelectedUnitUniqueId] = React.useState<string>(null);
    const [selectedUnitTitle, setSelectedUnitTitle] = React.useState<string>('');
    const [searchText, setSearchText] = React.useState<string>('');
    const [tabletInfo, setTabletInfo] = React.useState<ITabletInfo[]>([]);
    const loginUser = useSelector((state: IApplicationState) => state.loginUser);
    const [employeesAutoComplete, setEmployeesAutoComplete] = React.useState<IAutoComplete[]>([]);
    const [selectedUnitUniqueIdToAddTablet, setSelectedUnitUniqueIdToAddTablet] = React.useState<string>(null);
    const [pageMessages, setPageMessages] = React.useState<IMessage[]>([]);
    const [tabStatuses, setTabStatuses] = React.useState<IEnumOld[]>([]);
    const [selectedTabletIdForEdit, setSelectedTabletIdForEdit] = React.useState<number>(0);
    const [selectedTabletName, setSelectedTabletName] = React.useState<string>('');
    const [selectedTabStatusId, setSelectedTabStatusId] = React.useState<number>(0);

    const addMessage = (messageText: string, variant: 'success' | 'error' | 'info' | 'warning') => {
        if (!!messageText && messageText.trim() != '') {
            let msgs = [];
            msgs.push({
                messageText,
                variant
            });

            setPageMessages([...msgs]);
        }
    }

    const clearMessages = () => {
        setPageMessages([]);
    }

    const clearMessagesExceptSuccess = () => {
        setPageMessages((messages: IMessage[]) => [...messages.filter(pm => pm.variant == 'success')]);
    }

    const loadData = (unitUniqueId: string) => {
        setIsLoading(true);
        tabletApi.getUnitTabletInfo(loginUser?.UserId ?? 0, unitUniqueId)
            .then(res => {
                if (!!res && res.length > 0) {
                    setTabletInfo([...res]);
                } else {
                    setTabletInfo([]);
                }

                setIsLoading(false);
            })
            .catch(e => {
                setIsLoading(false)
                setTabletInfo([]);
            });
    }

    const onUnitChange = (option: IUnitAccess) => {
        setTabletInfo([]);
        setEmployeesAutoComplete([]);
        const uniqueId = option?.UnitUniqueId ?? null;
        setSelectedUnitUniqueId(uniqueId);
        setSelectedUnitTitle(option?.UnitTitle ?? '');

        if (!!uniqueId) {
            loadData(uniqueId);
            getEmployeesAutoCompleteData(uniqueId);
        }
    }

    const getEmployeesAutoCompleteData = (unitUniqueId: string) => {
        setEmployeesAutoComplete([]);

        employeeApi.getEmployeesAutoComplete(unitUniqueId)
            .then(res => {
                if (!!res && res.length > 0) {
                    setEmployeesAutoComplete(getCopy(res));
                } else {
                    setEmployeesAutoComplete([]);
                }
            })
    }

    const getTabStatuses = () => {
        setTabStatuses([]);

        masterDataApi.getTabStatuses()
            .then(res => {
                if (!!res && res.length > 0) {
                    setTabStatuses(getCopy(res));
                } else {
                    setTabStatuses([]);
                }
            })
    }

    const filteredRow = React.useMemo(() => {
        if (!!tabletInfo && tabletInfo.length > 0) {
            let copy = getCopy(tabletInfo);

            if (!!searchText && searchText.trim() !== '') {
                const toSearch = searchText.trim().toLowerCase();
                copy = copy.filter(m =>
                    (!!m.AssignToEmployee && m.AssignToEmployee.trim() !== '' && m.AssignToEmployee.trim().toLowerCase().indexOf(toSearch) >= 0)
                    ||
                    (!!m.IMEIOne && m.IMEIOne.trim() !== '' && m.IMEIOne.toLowerCase().indexOf(toSearch) >= 0)
                    ||
                    (!!m.IMEITwo && m.IMEITwo.trim() !== '' && m.IMEITwo.toLowerCase().indexOf(toSearch) >= 0)
                    ||
                    (!!m.TabName && m.TabName.trim() !== '' && m.TabName.toLowerCase().indexOf(toSearch) >= 0)
                    ||
                    (!!m.TabStatus && m.TabStatus.trim() !== '' && m.TabStatus.toLowerCase().indexOf(toSearch) >= 0)
                )
            }

            return getCopy(copy);
        }

        return [];
    }, [searchText, tabletInfo]);


    const employeeToAssign = React.useMemo(() => {
        let copy = getCopy(tabletInfo);
        let autoCompleteCopy = getCopy(employeesAutoComplete);
        if (!!copy && copy.length > 0 && !!autoCompleteCopy && autoCompleteCopy.length > 0) {
            autoCompleteCopy.forEach(ac => {
                let item = copy.find(m => m.AssignToEmployeeId === ac.Value);

                if (!!item) {
                    ac.DisplayText += ` (Assigned)`;
                }
            })
        }

        return autoCompleteCopy;
    }, [employeesAutoComplete, tabletInfo]);

    const availableTabletsToTransfer = React.useMemo(() => {
        let copy = getCopy(tabletInfo);
        const newList = copy.filter(m => !m.AssignToEmployeeId || m.AssignToEmployeeId <= 0)
            .map(a => {
                const item: IAutoComplete = {
                    DisplayText: a.TabName,
                    Value: a.TabletId
                };

                return item;
            });

        return newList;
    }, [tabletInfo]);

    const [isTransferPopupOpen, setIsTransferPopupOpen] = React.useState<boolean>(false);

    const transferToUnits = React.useMemo(() => {
        let copy = getCopy(loginUser?.UnitAccesses).filter(m => m.UnitUniqueId !== AllOptionUniqueId && m.UnitUniqueId !== selectedUnitUniqueId);
        return copy;
    }, [selectedUnitUniqueId]);

    const [isUnassigning, setIsUniassigning] = React.useState<boolean>();
    const onUnAssignTablet = (tabId: number) => {
        confirmAction('Tablet Unassign', 'Are you sure you want to Unassign Tablet?', 'Yes', 'No'
            , 'red', 'green', 'red', 'red', false, undefined)
            .then(res => {
                if (res === true) {
                    setIsUniassigning(true);
                    tabletApi.unAssignTablet(tabId, loginUser?.UserId ?? 0)
                        .then(rs => {
                            setIsUniassigning(false);
                            showSuccessMessage('Unassigned Successfully');
                            let copy = getCopy(tabletInfo);
                            let item = copy.find(m => m.TabletId === tabId);
                            item.AssignToEmployee = '';
                            item.AssignToEmployeeId = 0;
                            item.AssignToUserId = 0;
                            item.AssignToUserName = '';
                            copy = copy.sort((a, b) => a.TabletId - b.TabletId);
                            copy = copy.sort((a, b) => a.TabStatusId - b.TabStatusId);
                            setTabletInfo(getCopy(copy));
                        })
                        .catch(e => {
                            setIsUniassigning(false);
                            showErrorMessage('Error while unassigning tablet.');
                        })
                }
            })
    }

    const [selectedEmployeeId, setSelectedEmployeeId] = React.useState<number>(0);
    const [selectedEmployeeName, setSelectedEmployeeName] = React.useState<string>('');
    const [isSavingAssignment, setIsSavingAssignment] = React.useState<boolean>(false);
    const onSaveAssignment = (tabletId: number) => {
        setIsSavingAssignment(true);
        tabletApi.changeAssignment(selectedEmployeeId, tabletId)
            .then(res => {
                setIsSavingAssignment(false);
                showSuccessMessage('Saved Successfully');
                let copy = getCopy(tabletInfo);
                let item = copy.find(m => m.TabletId === tabletId);
                item.AssignToEmployee = selectedEmployeeName;
                item.AssignToEmployeeId = selectedEmployeeId;
                item.IsEditMode = false;
                let otherItems = copy.filter(m => m.AssignToEmployeeId === selectedEmployeeId && m.TabletId !== tabletId);

                if(!!otherItems && otherItems.length > 0){
                    otherItems.forEach(otherItem => {
                        otherItem.AssignToEmployee = '';
                        otherItem.AssignToEmployeeId = 0;
                        otherItem.AssignToUserId = 0;
                        otherItem.AssignToUserName = '';
                    })
                }

                setTabletInfo(copy);
            })
            .catch(e => {
                console.log(e);
                setIsSavingAssignment(false);
                showErrorMessage('Error while saving tablet assignment');
            })
    }

    const onCancelEdit = () => {
        let copy = getCopy(tabletInfo);
        let newState = copy.map(a => {
            a.IsEditMode = false;
            return a;
        })

        setTabletInfo(newState);
    }

    const onAssignClick = (tabletId:number) =>{
        setSelectedEmployeeId(0);
        setSelectedEmployeeName('');
        let copy = getCopy(tabletInfo);
        let newState = copy.map(a => {
            a.IsEditMode = a.TabletId === tabletId;
            return a;
        })

        setTabletInfo(newState);
    }

    React.useEffect(() => {
        getTabStatuses();
    }, []);

    return <Page title="Tablets | Raza Pak Care">
        <Container style={{ paddingBottom: 20, paddingTop: 10, maxWidth: '100%' }}><MessageComponent onRemove={(i: number) => {
            let msgs = [...pageMessages];
            msgs.splice(i, 1);
            setPageMessages([...msgs]);
        }} messages={pageMessages} />
        </Container>
        <Container style={{ paddingBottom: 10, maxWidth: '100%' }}>
            <Grid container spacing={2}>
                <Grid item lg={3} xl={3} md={3} xs={12}>
                    {<CustomAutoComplete<IUnitAccess>
                        Id={'Area_List_Auto_Complete'}
                        Value={!!loginUser && !!loginUser?.UnitAccesses && loginUser.UnitAccesses.length > 0 && !!selectedUnitUniqueId ? loginUser.UnitAccesses.find(m => m.UnitUniqueId === selectedUnitUniqueId) : null}
                        Options={(loginUser?.UnitAccesses ?? []).filter(m => m.UnitUniqueId !== AllOptionUniqueId)}
                        displayField={'UnitTitle'}
                        valueField={'Id'}
                        Label={'Unit'}
                        onChange={onUnitChange}
                        size='small'
                        readOnly={isLoading}
                    />}
                </Grid>
                <Grid item md={9} style={{ display: 'flex', justifyContent: 'flex-end' }} xs={12}>
                    {!!selectedUnitUniqueId && <LoadingButton
                        size="small"
                        type="button"
                        variant="contained"
                        loading={false}
                        style={{ alignSelf: 'center', margin: 3, backgroundColor: 'orange', textAlign: 'right' }}
                        startIcon={<Icon icon={'mingcute:transfer-fill'} />}
                        onClick={() => {
                            setIsTransferPopupOpen(true);
                        }}
                        key={`TransferTablet`}
                    >
                        Transfer Tablet
                    </LoadingButton>}
                    {!!selectedUnitUniqueId && <LoadingButton
                        size="small"
                        type="button"
                        variant="contained"
                        loading={false}
                        style={{ alignSelf: 'center', margin: 3, backgroundColor: 'blue', textAlign: 'right' }}
                        startIcon={<Icon icon={'material-symbols:add'} />}
                        onClick={() => {
                            setSelectedUnitUniqueIdToAddTablet(selectedUnitUniqueId);
                        }}
                        key={`AddNewTablet`}
                    >
                        New Tablet
                    </LoadingButton>}
                </Grid>
            </Grid>
        </Container>

        <Container style={{ paddingBottom: 10, maxWidth: '100%' }}>
            <Card>
                {<TableContainer sx={{ minWidth: 800, maxWidth: '100%' }}>
                    <Table sx={{ borderSpacing: '0px 3px' }} size="small" stickyHeader>
                        <UserListHead
                            headLabel={TABLET_TABLE_HEAD}
                            rowCount={0}
                            numSelected={0}
                            onSelectAllClick={undefined}
                            customKey={`TableHeadRow_6_Tablet_Table`}
                            typographyVariant='subtitle2'
                            backgroundColor={'#4279a3'}
                            textColor='white'
                            showSearchBox={true}
                            searchText={searchText}
                            onSearchChange={(text: string) => setSearchText(text)}
                        />
                        <TableBody>
                            {isLoading === true && <TableRow
                                key={'LoadingRow'}
                                tabIndex={-1}
                                role="checkbox"
                                selected={false}
                                aria-checked={false}
                            >
                                <TableCell align='center' colSpan={TABLET_TABLE_HEAD.length}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>}
                            {isLoading === false && (!filteredRow || filteredRow.length <= 0) && <TableRow
                                key={'LoadingRow'}
                                tabIndex={-1}
                                role="checkbox"
                                selected={false}
                                aria-checked={false}
                            >
                                <TableCell align='center' colSpan={TABLET_TABLE_HEAD.length}>
                                    {`No Data${!!searchText && searchText.trim() !== '' && !!filteredRow && filteredRow.length > 0 ? ` For "${searchText}"` : ''}`}
                                </TableCell>
                            </TableRow>}
                            {!!filteredRow && isLoading === false && filteredRow.length > 0 && filteredRow.map((row, index) => {
                                return (
                                    <React.Fragment key={`Tablet_Item_${row.TabletId}`}><TableRow
                                        hover
                                        key={row.TabletId}
                                        tabIndex={-1}
                                        role="checkbox"
                                        selected={false}
                                        aria-checked={false}
                                        className={row.IsCollapsed === true ? 'tablet' : 'tablet-selected'}
                                        onClick={() => {
                                            if (!!row.TabletComments && row.TabletComments.length > 0) {
                                                let copy = getCopy(tabletInfo);
                                                let item = copy.find(m => m.TabletId === row.TabletId);
                                                item.IsCollapsed = !item.IsCollapsed;
                                                setTabletInfo(copy);
                                            }
                                        }}
                                    >
                                        <TableCell padding="none" width="1%" align="left" style={{ paddingLeft: 10 }}>
                                            {row.IsCollapsed === false && !!row.TabletComments && row.TabletComments.length > 0 && <Icon color='black' style={{ cursor: 'pointer' }} width={25} height={25} icon={'mdi:chevron-up'} />}
                                            {row.IsCollapsed === true && !!row.TabletComments && row.TabletComments.length > 0 && <Icon color='black' style={{ cursor: 'pointer' }} width={25} height={25} icon={'mdi:chevron-down'} />}
                                        </TableCell>
                                        <TableCell width={'7%'} align="left" style={row.IsCollapsed === false ? { color: 'blue', fontWeight: 'bold' } : {}} className={row.TabStatusId === 2 ? 'tablet-out-of-order-cell-text' : (!!row.AssignToEmployeeId && row.AssignToEmployeeId > 0 ? 'tablet-assigned-cell-text' : 'tablet-cell-text')}>
                                            <Highlighted text={`${row.TabName}`} highlight={searchText} />
                                        </TableCell>
                                        <TableCell width={'10%'} align="left" className={row.TabStatusId === 2 ? 'tablet-out-of-order-cell-text' : (!!row.AssignToEmployeeId && row.AssignToEmployeeId > 0 ? 'tablet-assigned-cell-text' : 'tablet-cell-text')}>
                                            <Highlighted text={`${!!row.IMEIOne && row.IMEIOne.trim() !== '' && !!row.IMEITwo && row.IMEITwo.trim() !== '' ? `${row.IMEIOne},${row.IMEITwo}` : (!!row.IMEIOne && row.IMEIOne.trim() !== '' ? `${row.IMEIOne}` : `${!!row.IMEITwo && row.IMEITwo.trim() !== '' ? `${row.IMEITwo}` : 'N/A'}`)}`} highlight={searchText} />
                                        </TableCell>
                                        <TableCell width={'10%'} align="left" className={row.TabStatusId === 2 ? 'tablet-out-of-order-cell-text' : (!!row.AssignToEmployeeId && row.AssignToEmployeeId > 0 ? 'tablet-assigned-cell-text' : 'tablet-cell-text')}>
                                            <Highlighted text={row.TabStatus} highlight={searchText} />
                                        </TableCell>
                                        {row.IsEditMode === true && <TableCell colSpan={2}>



                                            <table width={"100%"}><tbody><tr><td width="40%" style={{ paddingRight: 5 }}>
                                                <CustomAutoComplete<IAutoComplete>
                                                    Id={'Employee_LIST'}
                                                    Value={!!employeeToAssign && employeeToAssign.length > 0 && selectedEmployeeId > 0 ? employeeToAssign.find(m => m.Value == selectedEmployeeId) : null}
                                                    Options={employeeToAssign}
                                                    displayField={'DisplayText'}
                                                    valueField={'Value'}
                                                    Label={'Employee'}
                                                    onChange={(option: IAutoComplete) => {
                                                        setSelectedEmployeeId(option?.Value ?? 0);

                                                        let empItem = employeesAutoComplete.find(m => m.Value === option?.Value);

                                                        if(!!empItem && !!empItem.DisplayText){
                                                            setSelectedEmployeeName(empItem.DisplayText ?? '');
                                                        }else{
                                                            setSelectedEmployeeName(option?.DisplayText ?? '');
                                                        }
                                                    }}
                                                    size='small'
                                                    readOnly={isSavingAssignment}
                                                />
                                            </td>
                                                <td align='left'>
                                                    {isSavingAssignment == false && <span onClick={() => {
                                                        if ((selectedEmployeeId > 0)) {
                                                            //update route here
                                                            onSaveAssignment(row.TabletId);
                                                        }
                                                    }} className={selectedEmployeeId > 0 ? 'btn-text' : 'btn-text-disabled'} style={{ marginLeft: 14 }}>Save</span>}
                                                    {isSavingAssignment == false && <span onClick={() => {
                                                        onCancelEdit();
                                                    }} className='btn-text'>Cancel</span>}
                                                    {isSavingAssignment === true && <span className='btn-text'><CircularProgress /></span>}
                                                </td>
                                            </tr></tbody></table>







                                        </TableCell>}
                                        {row.IsEditMode === false && <TableCell align="left" style={{ color: !!row.AssignToEmployeeId && row.AssignToEmployeeId > 0 ? 'blue' : undefined }} className={row.TabStatusId === 2 ? 'tablet-out-of-order-cell-text' : (!!row.AssignToEmployeeId && row.AssignToEmployeeId > 0 ? 'tablet-assigned-cell-text' : 'tablet-cell-text')}>
                                            <Highlighted text={!!row.AssignToEmployee && row.AssignToEmployee.trim() !== '' ? row.AssignToEmployee : 'N/A'} highlight={searchText} />
                                        </TableCell>}
                                        {row.IsEditMode === false && <TableCell align='right'>
                                            <span onClick={(e) => {
                                                e.stopPropagation();
                                                setSelectedTabletIdForEdit(row.TabletId);
                                                setSelectedTabStatusId(row.TabStatusId);
                                                setSelectedTabletName(row.TabName);
                                            }} className='btn-text'>Edit</span>
                                            {(!row.AssignToEmployeeId || row.AssignToEmployeeId <= 0) && row.TabStatusId === 1 && <span onClick={(e) => {
                                                e.stopPropagation();
                                                onAssignClick(row.TabletId);
                                            }} className='btn-text' style={{ color: 'green' }}>Assign</span>}
                                            {row.AssignToEmployeeId > 0 && row.TabStatusId === 1 && <span onClick={(e) => {
                                                e.stopPropagation();
                                                onUnAssignTablet(row.TabletId);
                                            }} className='btn-text' style={{ color: 'red' }}>Unassign</span>}
                                        </TableCell>}
                                    </TableRow>
                                        {row.IsCollapsed === false && !!row.TabletComments && row.TabletComments.length > 0 && row.TabletComments.map((comment, i) => {
                                            return <TableRow key={`Tablet_Comments_Card_${comment.Id}`}>
                                                <TableCell style={{ color: comment.TabStatusId === 2 ? 'red' : (comment.TabStatusId === 1 ? 'green' : 'black') }} colSpan={TABLET_TABLE_HEAD.length - 2}>
                                                    {comment.Comments}
                                                </TableCell>
                                                <TableCell style={{ color: comment.TabStatusId === 2 ? 'red' : (comment.TabStatusId === 1 ? 'green' : 'black') }} colSpan={2}>{`By `}<strong>{`${comment.CommentedBy}`}</strong>{` On `}<strong>{`${formatedDateTime(comment.CommentedOn, 'DD-MMM-YYYY hh:mm A')}`}</strong></TableCell>
                                            </TableRow>
                                        })}
                                    </React.Fragment>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>}
            </Card>
        </Container>
        {!!selectedUnitUniqueIdToAddTablet && selectedUnitUniqueIdToAddTablet.trim() !== '' && <AddNewTabletPopup
            unitUniqueId={selectedUnitUniqueIdToAddTablet}
            unitTitle={selectedUnitTitle}
            onClose={() => { setSelectedUnitUniqueIdToAddTablet(null); }}
            onSuccess={(tabInfo: ITabletInfo) => {
                if (!!tabInfo) {
                    let copy = getCopy((tabletInfo ?? []));

                    let tabItem = copy.find(m => m.TabletId === tabInfo.TabletId);

                    if (!tabItem) {
                        copy.push(getCopy(tabInfo));
                        copy = copy.sort((a, b) => a.TabletId - b.TabletId);
                        copy = copy.sort((a, b) => a.TabStatusId - b.TabStatusId);
                        setTabletInfo(getCopy(copy));
                    }
                }
            }}
        />}

        {!!selectedTabletIdForEdit && selectedTabletIdForEdit > 0 && <EditTabletStatusPopup
            tabletId={selectedTabletIdForEdit}
            tabName={selectedTabletName}
            unitTitle={selectedUnitTitle}
            tabStatusId={selectedTabStatusId}
            tabStatuses={tabStatuses}
            onClose={() => {
                setSelectedTabletIdForEdit(0);
                setSelectedTabStatusId(0);
                setSelectedTabletName('');
            }}
            onSuccess={(tabletId: number, newTabStatusId: number, newTabStatus: string, comments: string, commentById: number, commentedOn: Date) => {
                let copy = getCopy((tabletInfo ?? []));

                let tabItem = copy.find(m => m.TabletId === tabletId);

                if (!!tabItem) {
                    tabItem.TabletComments = [...getCopy(tabItem.TabletComments), { CommentedBy: (`${loginUser?.FirstName ?? ''} ${loginUser?.LastName ?? ''}`), CommentedById: commentById, CommentedOn: commentedOn, Comments: comments, Id: 0, TabletId: tabletId, TabStatus: newTabStatus, TabStatusId: newTabStatusId }]
                    tabItem.TabStatus = newTabStatus;
                    tabItem.TabStatusId = newTabStatusId;

                    if (newTabStatusId === 2) //2 is out of ordr
                    {
                        tabItem.AssignToEmployee = '';
                        tabItem.AssignToEmployeeId = 0;
                        tabItem.AssignToUserId = 0;
                        tabItem.AssignToUserName = '';
                    }

                    copy = copy.sort((a, b) => a.TabletId - b.TabletId);
                    copy = copy.sort((a, b) => a.TabStatusId - b.TabStatusId);
                    setTabletInfo(getCopy(copy));
                    showSuccessMessage('Tab Status Updated Successfully');
                }

                setSelectedTabletIdForEdit(0);
                setSelectedTabStatusId(0);
                setSelectedTabletName('');
            }}
        />}

        {isTransferPopupOpen === true && <TransferTabletPopup
            isOpen={isTransferPopupOpen}
            onClose={() => { setIsTransferPopupOpen(false) }}
            onSuccess={(tabletId: number) => {
                let copy = getCopy(tabletInfo);
                let filtered = copy.filter(m => m.TabletId !== tabletId);
                setTabletInfo(getCopy(filtered));
                setIsTransferPopupOpen(false);
                showSuccessMessage('Transfered Successfully');
            }}
            transferToUnitsAutoComplete={transferToUnits}
            unassignTabletsAutoComplete={availableTabletsToTransfer}
            unitTitle={selectedUnitTitle}
            unitUniqueId={selectedUnitUniqueId}
            key={'Trafer_Pop_up_Manager_Tablet'}
        />

        }

    </Page>;
}
