import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IApplicationState } from 'src/redux';
import { useSelector } from 'react-redux';
import { Container, Grid, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { IAutoComplete } from 'src/interfaces/auto-complete.interface';
import { CustomAutoComplete } from './common/CustomAutoComplete';
import { employeeApi, masterDataApi, routeApi } from 'src/http';
import { shopApi } from 'src/http/shop.http';
import { IRouteShopDetail } from 'src/interfaces/route-shop-detail.interface';
import { IShopUpdateRequest } from 'src/interfaces/shop-update-request.interface';
import { getCopy, isValidCellNumber } from 'src/utility';
import { IMessage } from 'src/interfaces/message.interface';
import { MessageComponent } from './messages.component';
import { IShopRequest } from 'src/interfaces/shop-request.interface';

interface IProps {
    shop: IShopRequest;
    shopTypes:IAutoComplete[];
    shopIn:IAutoComplete[];
    shopSize:IAutoComplete[];
    onClose: () => void;
    onSuccess: (updatedShopInfo: IShopRequest) => void;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    paddingTop: 2,
    paddingBottom: 2
};

export const EditShopRequestPopup = (props: IProps) => {
    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick") {
            return;
        }

        props.onClose();
    }

    const loginUser = useSelector((state: IApplicationState) => state.loginUser);
    const [shopForEdit, setShopForEdit] = React.useState<IShopRequest>(getCopy(props.shop));

    const [pageMessages, setPageMessages] = React.useState<IMessage[]>([]);
    const addMessage = (messageText: string, variant: 'success' | 'error' | 'info' | 'warning') => {
        if (!!messageText && messageText.trim() != '') {
            let msgs = [];
            msgs.push({
                messageText,
                variant
            });

            setPageMessages([...msgs]);
        }
    }

    const showErrorMessage = (message: string) => addMessage(message, 'error');
    const showInfoMessage = (message: string) => addMessage(message, 'info');
    const showSuccessMessage = (message: string) => addMessage(message, 'success');
    const showWarningMessage = (message: string) => addMessage(message, 'warning');

    const onChange = (keyName:keyof IShopRequest, value:any) =>{
        setPageMessages([]);
        let shopCopy:any = getCopy(shopForEdit);
        shopCopy[keyName] = value;
        setShopForEdit(shopCopy);
    }

    const validate = () => {
        if (!shopForEdit?.ShopName || shopForEdit.ShopName.trim() === '') {
            showErrorMessage('Invalid Shop Name');
            return false;
        }

        if (!shopForEdit?.ContactName || shopForEdit.ContactName.trim() === '') {
            showErrorMessage('Invalid Contact Name');
            return false;
        }

        if (((!!props.shop.ContactNumberOne && props.shop.ContactNumberOne.trim() != '') || (!!props.shop.ContactNumberTwo && props.shop.ContactNumberTwo.trim() !== '')) && ((!shopForEdit.ContactNumberOne || shopForEdit.ContactNumberOne.trim() === '') && (!shopForEdit.ContactNumberTwo || shopForEdit.ContactNumberTwo.trim() === ''))) {
            showErrorMessage('Cell Number is required');
            return false;
        }

        if (!!shopForEdit.ContactNumberOne && shopForEdit.ContactNumberOne.trim() !== '' && !isValidCellNumber(shopForEdit.ContactNumberOne)) {
            showErrorMessage('Invalid Cell One.');
            return false;
        }

        if (!!shopForEdit.ContactNumberTwo && shopForEdit.ContactNumberTwo.trim() !== '' && !isValidCellNumber(shopForEdit.ContactNumberTwo)) {
            showErrorMessage('Invalid Cell Two.');
            return false;
        }

        if (!shopForEdit?.ShopTypeId || shopForEdit.ShopTypeId <= 0) {
            showErrorMessage('Shop Type Is Required');
            return false;
        }

        if (!shopForEdit?.ShopInId || shopForEdit.ShopInId <= 0) {
            showErrorMessage('Shop In Is Required');
            return false;
        }

        if (!shopForEdit?.ShopSizeId || shopForEdit.ShopSizeId <= 0) {
            showErrorMessage('Shop Size Is Required');
            return false;
        }

        if (!shopForEdit?.StreetAddress || shopForEdit.StreetAddress.trim() === '') {
            showErrorMessage('Invalid Shop Address');
            return false;
        }

        if(shopForEdit.StreetAddress.length < 10){
            showErrorMessage('Shop Address must be atleast 10 characters long');
            return false;
        }

        

        return true;
    }


    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const onSave = () => {
        if (validate()) {
            setIsLoading(true);
            shopApi.updateShopRequest(loginUser?.UserId ?? 0, getCopy(shopForEdit))
                .then(() => {
                    setIsLoading(false);
                    props.onSuccess(shopForEdit);
                })
                .catch(() => {
                    setIsLoading(false);
                });
        }
    }

    return <Modal
        open={!!props.shop && props.shop.Id > 0}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={style}>
            <Grid item md={12} style={{ justifyContent: 'center', display: 'flex' }}>
                <Typography style={{ alignSelf: 'center' }} id="modal-modal-title" variant="h6" component="h2">
                    Edit Shop
                </Typography>
            </Grid>



            <Container>
                <Grid container spacing={2} style={{ marginTop: 5 }}>
                <Grid item md={12} xs={12}>
                    <MessageComponent onRemove={(i: number) => {
                        let msgs = [...pageMessages];
                        msgs.splice(i, 1);
                        setPageMessages([...msgs]);
                    }} messages={pageMessages} /></Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: 10 }}>
                    <Grid item md={12} xs={12}>
                        <TextField
                            fullWidth
                            autoComplete="shop_name"
                            size='small'
                            variant='outlined'
                            type={'text'}
                            label="Shop Name"
                            value={shopForEdit.ShopName ?? ''}
                            onChange={(event) => {onChange('ShopName', event.target.value);}}
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <TextField
                            fullWidth
                            autoComplete="contact_name"
                            size='small'
                            variant='outlined'
                            type={'text'}
                            label="Contact Name"
                            value={shopForEdit.ContactName ?? ''}
                            onChange={(event) => {onChange('ContactName', event.target.value);}}
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <TextField
                            fullWidth
                            autoComplete="cellOne"
                            size='small'
                            variant='outlined'
                            type={'text'}
                            label="Cell One"
                            value={shopForEdit.ContactNumberOne ?? ''}
                            onChange={(event) => {onChange('ContactNumberOne', event.target.value);}}
                        />
                    </Grid>

                    <Grid item md={12} xs={12}>
                        <TextField
                            fullWidth
                            autoComplete="cell_two"
                            size='small'
                            variant='outlined'
                            type={'text'}
                            label="Cell Two"
                            value={shopForEdit.ContactNumberTwo ?? ''}
                            onChange={(event) => {onChange('ContactNumberTwo', event.target.value);}}
                        />
                    </Grid>

                    <Grid item md={12} xs={12}>
                        {<CustomAutoComplete<IAutoComplete>
                            Id={'shop_types_autocomplete'}
                            Value={!!props.shopTypes && props.shopTypes.length > 0 ? props.shopTypes.find(m => m.Value == (shopForEdit?.ShopTypeId ?? 0)) ?? null : null}
                            Options={props.shopTypes}
                            displayField={'DisplayText'}
                            valueField={'Value'}
                            Label={'Shop Type'}
                            onChange={(event) => {onChange('ShopTypeId', event?.Value ?? 0);}}
                            size='small'
                            isLoading={false}
                        />}
                    </Grid>
                    <Grid item md={12} xs={12}>
                        {<CustomAutoComplete<IAutoComplete>
                            Id={'shop_in_autocomplete'}
                            Value={!!props.shopIn && props.shopIn.length > 0 ? props.shopIn.find(m => m.Value == (shopForEdit?.ShopInId ?? 0)) ?? null : null}
                            Options={props.shopIn}
                            displayField={'DisplayText'}
                            valueField={'Value'}
                            Label={'Shop In'}
                            onChange={(event) => {onChange('ShopInId', event?.Value ?? 0);}}
                            size='small'
                            isLoading={false}
                        />}
                    </Grid>

                    <Grid item md={12} xs={12}>
                        {<CustomAutoComplete<IAutoComplete>
                            Id={'shop_size_autocomplete'}
                            Value={!!props.shopSize && props.shopSize.length > 0 ? props.shopSize.find(m => m.Value == (shopForEdit?.ShopSizeId ?? 0)) ?? null : null}
                            Options={props.shopSize}
                            displayField={'DisplayText'}
                            valueField={'Value'}
                            Label={'Shop Size'}
                            onChange={(event) => {onChange('ShopSizeId', event?.Value ?? 0);}}
                            size='small'
                            isLoading={false}
                        />}
                    </Grid>

                    <Grid item md={12}>
                        <TextField
                            id={'shop_address'}
                            fullWidth
                            placeholder={'Enter Shop Address'}
                            label={'Address'}
                            multiline={true}
                            rows={3}
                            value={shopForEdit?.StreetAddress ?? ''}
                            onChange={(event) => {onChange('StreetAddress', event.target.value);}}
                            InputProps={{ style: { fontSize: '12px' } }}
                        />
                    </Grid>
                </Grid>

                <Grid item md={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <LoadingButton
                        size="small"
                        type="button"
                        variant="outlined"
                        loading={isLoading}
                        style={{ marginTop: 15, alignSelf: 'center', marginRight: 10, borderRadius: 0 }}
                        onClick={() => {
                            onSave();
                        }}
                    >
                        Save
                    </LoadingButton>

                    <LoadingButton
                        size="small"
                        type="button"
                        variant="outlined"
                        loading={false}
                        disabled={false}
                        style={{ marginTop: 15, alignSelf: 'center', color: 'gray', borderRadius: 0, borderColor: 'gray' }}
                        onClick={props.onClose}
                    >
                        Cancel
                    </LoadingButton>
                </Grid>
            </Container>
        </Box>
    </Modal>;
}