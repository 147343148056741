import {IAction} from './action.interface';
import {OrderNewConstants} from './action.constant';
import { IOrderData } from 'src/interfaces/reports/order-data.interface';

export const setOrderNewDataAction = (
  payload: IOrderData,
): IAction<IOrderData> => {
  return {
    type: OrderNewConstants.SET_ORDER_NEW_DATA,
    payload,
  };
};
