import {IAction} from './action.interface';
import {SelectedUnitAccessConstants} from './action.constant';
import { ISelectedUnitAccess } from 'src/interfaces/selected-unit-access.interface';

export const setSelectedUnitAccessesAction = (
  payload: ISelectedUnitAccess,
): IAction<ISelectedUnitAccess> => {
  return {
    type: SelectedUnitAccessConstants.SET_SELECTED_UNIT_ACCESS,
    payload,
  };
};
