import { IAppConfirmation } from 'src/interfaces/app-confirmation.interface';
import {AppConfirmation} from '../actions/action.constant';
import {IAction} from '../actions/action.interface';
import {initialState} from '../initial-state';

export const confirmationReducer = (
  state: IAppConfirmation | null = initialState.confirmation,
  action: IAction<IAppConfirmation | null>,
) => {
  switch (action.type) {
    case AppConfirmation.SET_APP_CONFIRMATION_MODAL: {
      if(!!action.payload){
        return {...action.payload};
      }else{
        return null;
      }
    }
    default:
      return state;
  }
};
