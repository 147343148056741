import { IAreaWiseOrderBookerInfo } from 'src/interfaces/area-wise-order-booker-info.interface';
import {OrderBookerInfoConstants} from '../actions/action.constant';
import {IAction} from '../actions/action.interface';
import {initialState} from '../initial-state';
import { getCopy } from 'src/utility';

export const orderBookerRouteInfoReducer = (
  state: IAreaWiseOrderBookerInfo[] = initialState.orderBookerRouteInfo,
  action: IAction<IAreaWiseOrderBookerInfo>,
) => {
  switch (action.type) {
    case OrderBookerInfoConstants.SET_ORDER_BOOKER_INFO: {
        if(!!action.payload && action.payload.areaId > 0){
            const copy = !!state && state.length > 0 ? getCopy(state) : [];

            if(copy.findIndex(m => m.areaId === action.payload.areaId) >= 0){
                const newState = copy.map(a => {
                    if(a.areaId === action.payload.areaId){
                        a.orderBookerInfo = !!action.payload.orderBookerInfo && action.payload.orderBookerInfo.length > 0 ? getCopy(action.payload.orderBookerInfo) : [];
                    }

                    return a;
                });

                return newState;
            }else{
                return [...copy.map(a => a), {areaId:action.payload.areaId, orderBookerInfo:(!!action.payload.orderBookerInfo && action.payload.orderBookerInfo.length > 0 ? getCopy(action.payload.orderBookerInfo) : [])}];
            }
        }else{
            return state;
        }
    }
    case OrderBookerInfoConstants.RESET_ORDER_BOOKER_INFO:{
        return initialState.orderBookerRouteInfo;
    }
    default:
      return state;
  }
};
