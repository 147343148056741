import { IRoutePoly } from 'src/interfaces/route-poly.interface';
import {NewRouteSetupConstant} from '../../actions/action.constant';
import {IAction} from '../../actions/action.interface';
import {initialState} from '../../initial-state';
import { getCopy } from 'src/utility';
import { IAreaRoutePoly } from 'src/interfaces/area-route-poly.interface';

export const routePoliesReducer = (
  state: IAreaRoutePoly[] = initialState.routePolies,
  action: IAction<IAreaRoutePoly>,
) => {
  switch (action.type) {
    case NewRouteSetupConstant.SET_ROUTE_POLIES: {
        if(!!action.payload && action.payload.areaId > 0){
            const copy = !!state && state.length > 0 ? getCopy(state) : [];

            if(copy.findIndex(m => m.areaId === action.payload.areaId) >= 0){
                const newState = copy.map(a => {
                    if(a.areaId === action.payload.areaId){
                        a.routePolies = !!action.payload.routePolies && action.payload.routePolies.length > 0 ? getCopy(action.payload.routePolies) : [];
                    }

                    return a;
                });

                return newState;
            }else{
                return [...copy.map(a => a), {areaId:action.payload.areaId, routePolies:(!!action.payload.routePolies && action.payload.routePolies.length > 0 ? getCopy(action.payload.routePolies) : [])}];
            }
        }else{
            return state;
        }
    }
    default:
      return state;
  }
};

