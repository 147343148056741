import React from 'react';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import arrowBackFill from '@iconify/icons-eva/arrow-back-fill';
import refreshFill from '@iconify/icons-eva/refresh-fill';
import saveFill from '@iconify/icons-eva/save-fill';
import moneyCollectFill from '@iconify/icons-ant-design/money-collect-fill';
// material
import {
    Card,
    Table,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    Grid,
    TextField,
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { UserListHead } from '../components/_dashboard/user';
//
import { LoadingButton } from '@mui/lab';
import { doCashApi, doCreditApi, productApi } from '../http';

import { useSnackbar } from 'notistack';
import { connect, useSelector } from 'react-redux';
import { IApplicationState } from 'src/redux';
import { Audio as AudoSpin } from 'react-loader-spinner'
import { IDODetail } from 'src/interfaces/delivery-order/do-detail.interface';
import Label from 'src/components/Label';
import { sentenceCase } from 'change-case';
import { IDOCredit } from 'src/interfaces/do-credit.interface';
import { confirmAction } from 'src/utility/confirm.util';
import { IDOCashDetail } from 'src/interfaces/do-cash-detail.interface';
import { IProductStock } from 'src/interfaces/product-stock.interface';
import { IUserDetail } from 'src/interfaces';
import { ISelectedUnitAccess } from 'src/interfaces/selected-unit-access.interface';
import { AllOptionUniqueId, isRestrictedLogin, NumberOfPreviousMonth } from 'src/app.constant';
import { defaultDateTimeFormat, defaultDateTimeTwelveHourFormat, formattedDate, getCommaSeparatedUnitUniqueIdsExceptAll, getDateOnly } from 'src/utility';
import { MessageComponent } from 'src/components/messages.component';
import { NoUnitSelectedComponent } from 'src/components/common/no-unit-selected.component';
import CustomDatePicker from 'src/components/common/CustomDatePicker';
import { IProduct } from 'src/interfaces/product.interface';
import { CustomAutoComplete } from 'src/components/common/CustomAutoComplete';
import { IStockLedger } from 'src/interfaces/stock-ledger.interface';
import { IMessage } from 'src/interfaces/message.interface';
import { IUserRouteDetail } from 'src/interfaces/routes/user-route-detail.interface';
import { Keys } from 'src/component.keys';
import { IRouteLayout } from 'src/interfaces/routes/route-layout.interface';
import { IRouteLayoutChild } from 'src/interfaces/routes/route-layout-child.interface';
import { getUrlFromComponentKey } from 'src/utility/navigation.util';
import { useNavigate } from "react-router-dom";


// ----------------------------------------------------------------------

const TABLE_HEAD= [
    { id: 'sr', label: 'Sr.', alignContent: 'center' },
    { id: 'product_name', label: 'Product Name', alignContent: 'center' },
    { id: 'dated', label: 'Transaction Date', alignContent: 'center' },
    { id: 'description', label: 'Description', alignContent: 'center' },
    { id: 'in', label: 'In Qty', alignContent: 'center' },
    { id: 'out', label: 'Out Qty', alignContent: 'center' },
    { id: 'stock', label: 'Stock Qty', alignContent: 'center' },
];

// ----------------------------------------------------------------------

interface IProps {
    loginUser: IUserDetail | null;
    selectedUnits: ISelectedUnitAccess;
    userRoutes:IUserRouteDetail;
}

const StockLedger = (props:IProps) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [stockLedger, setStockLedger] = React.useState<IStockLedger[]>([]);
    const [fromDate, setFromDate] = React.useState<Date>(new Date());
    const [toDate, setToDate] = React.useState<Date>(new Date());
    const [isInputDirty, setIsInputDirty] = React.useState<boolean>(true);
    const [products, setProducts] = React.useState<IProduct[]>([]);
    const [selectedProductId, setSelectedProductId] = React.useState<number>(0);
    const [viewCurrentStockUrl, setViewCurrentStockUrl] = React.useState<string>('');
    const [addStockUrl, setAddStockUrl] = React.useState<string>('');
    const tDated = new Date();
    const showErrorMessage = (message: string) => addMessage(message, 'error');
    const showInfoMessage = (message: string) => addMessage(message, 'info');
    const showSuccessMessage = (message: string) => addMessage(message, 'success');
    const showWarningMessage = (message: string) => addMessage(message, 'warning');
    const [pageMessages, setPageMessages] = React.useState<IMessage[]>([]);
    const addMessage = (messageText: string, variant: 'success' | 'error' | 'info' | 'warning') => {
        if (!!messageText && messageText.trim() != '') {
            let msgs = [];
            msgs.push({
                messageText,
                variant
            });

            setPageMessages([...msgs]);
        }
    }

    const onAddStock = () => {
        navigate(addStockUrl);
    }

    const onFromDateChange = (e: Date) => {
        setIsInputDirty(true);
        setFromDate(e);
        setPageMessages([]);
      }
      const onToDateChange = (e: Date) => {
        setIsInputDirty(true);
        setToDate(e);
        setPageMessages([]);
      }

      const onProductChange = (e:IProduct) =>{
        setIsInputDirty(true);
        setPageMessages([]);
        if(!!e){
          setSelectedProductId(e.Id);
        }else{
          setSelectedProductId(null);
        }
      }

      const onViewCurrentStock = () =>{
        navigate(viewCurrentStockUrl);
      }

    const getStockLedger = () => {

        if(!selectedProductId || selectedProductId <= 0){
            showErrorMessage("Please Select Product");
            return;
        }

        if(!fromDate){
            showErrorMessage("Please Select From Date");
            return;
        }

        if(!toDate){
            showErrorMessage("Please Select To Date");
            return;
        }

        if(getDateOnly(fromDate) > getDateOnly(toDate)){
            showErrorMessage("From Date Can't Be Greater Than To Date.");
            return;
        }

        setPageMessages([]);
        setIsLoading(true);
        setStockLedger([]);
        setIsInputDirty(false);

        productApi.getUnitWiseProductStockLedger(props.loginUser.UserId, getCommaSeparatedUnitUniqueIdsExceptAll([...props.selectedUnits.UnitAccesses.map(a => a)]),selectedProductId,fromDate,toDate)
            .then(cd => {
                setIsLoading(false);
                setStockLedger([...cd]);
            })
            .catch(e => {
                setIsLoading(false);
                console.log(e);
            })
    }

    const getProducts = () =>{
        setIsLoading(true);
        productApi.getProducts()
        .then(productResults => {
            setIsLoading(false);
            if(!!productResults && productResults.length > 0){
                setProducts([...productResults]);
                if(productResults.length == 1){
                    setSelectedProductId(productResults[0].Id);
                }
            }else{
                setProducts([]);
            }
        })
        .catch(e => {
            setIsLoading(false);
            setProducts([]);
            console.log(e);
        });
    }

    React.useEffect(() => {
        if(!!props.selectedUnits && !!props.selectedUnits.UnitAccesses && props.selectedUnits.UnitAccesses.filter(u => u.UnitUniqueId != AllOptionUniqueId).length > 0){
            if(!!fromDate && !!toDate && selectedProductId > 0){
                getStockLedger();
            }
        }
    }, [props.selectedUnits.UnitAccesses]);

    React.useEffect(() =>{
        if(!!props.userRoutes && !!props.userRoutes.Routes && props.userRoutes.Routes.length > 0){
            setViewCurrentStockUrl(getUrlFromComponentKey(props.userRoutes,Keys.Stock));
            setAddStockUrl(getUrlFromComponentKey(props.userRoutes,Keys.AddStock));
        }
    },[props.userRoutes]);

    React.useEffect(() => {
        getProducts();
    },[]);

    return (
        (!!props.selectedUnits && !!props.selectedUnits.UnitAccesses && props.selectedUnits.UnitAccesses.filter(u => u.UnitUniqueId != AllOptionUniqueId).length > 0) ? <>
        <MessageComponent onRemove={(i: number) => {
            let msgs = [...pageMessages];
            msgs.splice(i, 1);
            setPageMessages([...msgs]);
        }} messages={pageMessages} />
        <Page title="Stock Ledger | Raza Pak Care">
            <Container style={{ maxWidth: 1500, paddingLeft: 0, paddingRight: 0 }}>
            
                <Grid container style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                {(!!addStockUrl && addStockUrl.trim() != '') && <LoadingButton
                        size="small"
                        type="button"
                        variant="contained"
                        loading={isLoading}
                        style={{ margin: 3, backgroundColor: 'black', textAlign: 'right', height: 40 }}
                        startIcon={<Icon icon={'carbon:carbon-for-ibm-product'} />}
                        onClick={onAddStock}
                    >
                        Manage Stock
                    </LoadingButton>}
                    {(!!viewCurrentStockUrl && viewCurrentStockUrl.trim() != '') && <LoadingButton
                        size="small"
                        type="button"
                        variant="contained"
                        loading={isLoading}
                        style={{ margin: 3, backgroundColor: 'rgba(34, 154, 22, 0.72)', textAlign: 'right', height: 40 }}
                        startIcon={<Icon icon={'carbon:carbon-for-ibm-product'} />}
                        onClick={onViewCurrentStock}
                    >
                        View Current Stock
                    </LoadingButton>}
                </Grid>

                <Container style={{ paddingTop: 20, paddingBottom: 10 }}>
            <Grid container spacing={2}>
            {!!products && products.length > 1 && <Grid item md={4} sm={12}>
            <CustomAutoComplete<IProduct>
                Id={'Product_List'}
                Value={!!products && products.length > 0 && selectedProductId > 0 ? products.filter(m => m.Id == selectedProductId)[0] : null}
                Options={products}
                displayField={'ProductName'}
                valueField={'Id'}
                Label={'Product'}
                onChange={onProductChange}
                />
              </Grid>}
            <Grid item md={products.length > 1 ? 4 : 6} sm={12}>
                <CustomDatePicker
                  label='From Date'
                  value={fromDate}
                  inputDisable={true}
                  readonly={isLoading}
                  onChange={onFromDateChange}
                  minDate={isRestrictedLogin(props.loginUser.UserId) ? (new Date(tDated.getFullYear(), tDated.getMonth() - NumberOfPreviousMonth, 1)) : undefined}
                />
              </Grid>

              <Grid item md={products.length > 1 ? 4 : 6} sm={12}>
                <CustomDatePicker
                  label='To Date'
                  value={toDate}
                  inputDisable={true}
                  readonly={isLoading}
                  onChange={onToDateChange}
                  minDate={isRestrictedLogin(props.loginUser.UserId) ? (new Date(tDated.getFullYear(), tDated.getMonth() - NumberOfPreviousMonth, 1)) : undefined}
                />
              </Grid>

            <Grid item xs={12} style={{justifyContent:'center',display:'flex'}}>
            <LoadingButton
                            size="small"
                            type="button"
                            variant="contained"
                            loading={isLoading}
                            style={{alignSelf:'center', margin: 3, backgroundColor: 'rgba(34, 154, 22, 0.72)', textAlign: 'right', height: 40 }}
                            startIcon={<Icon icon={'emojione:ledger'} />}
                            onClick={getStockLedger}
                        >
                            Get Ledger
                        </LoadingButton>
            </Grid>

            </Grid>
          </Container>

                {isInputDirty == false && !!props.selectedUnits && !!props.selectedUnits.UnitAccesses && props.selectedUnits.UnitAccesses.filter(m => m.UnitUniqueId != AllOptionUniqueId).length > 0 && props.selectedUnits.UnitAccesses.filter(m => m.UnitUniqueId != AllOptionUniqueId).map((selectedUnit, unitIndex) => {
                return <><Card>
                    <Typography style={{ backgroundColor: 'rgba(34, 154, 22, 0.72)', color: 'white', paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h4'}>{selectedUnit.UnitTitle} Stock Ledger ({formattedDate(fromDate,'DD-MMM-YYYY')} to {formattedDate(toDate,'DD-MMM-YYYY')})</Typography>
                    {isLoading == true && <AudoSpin
                        height="80"
                        width="80"
                        color='green'
                        ariaLabel='three-dots-loading'
                        wrapperStyle={{ alignSelf: 'center', justifyContent: 'center' }}
                    />}

                    {(!stockLedger || stockLedger.length <= 0 || stockLedger.filter(s => s.UnitUniqueId == selectedUnit.UnitUniqueId).length <= 0) && isLoading == false && <Typography style={{ paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h6'}>No Details Available For {selectedUnit.UnitTitle} Store ({formattedDate(fromDate,'DD-MMM-YYYY')} to {formattedDate(toDate,'DD-MMM-YYYY')}).</Typography>}
                    {!!stockLedger && stockLedger.length > 0 && stockLedger.filter(s => s.UnitUniqueId == selectedUnit.UnitUniqueId).length > 0 && <Scrollbar sx={{}}>
                        <TableContainer sx={{ minWidth: 800, maxHeight:700 }}>
                            {<Table stickyHeader size='small'>
                                <UserListHead
                                    order={'asc'}
                                    orderBy={''}
                                    headLabel={TABLE_HEAD}
                                    rowCount={0}
                                    numSelected={0}
                                    onRequestSort={undefined}
                                    onSelectAllClick={undefined}
                                    customKey={`TableHeadRow_2`}
                                />

                                <TableBody key={`Table_Body_Selection_Details_Summary`}>
                                <TableRow
                                        hover
                                        key={`TableDetailRow_SUMMARY_Opening`}
                                        tabIndex={-1}
                                        role="checkbox"
                                        selected={false}
                                        aria-checked={false}
                                    >
                                        <TableCell colSpan={6}>&nbsp;</TableCell>
                                        <TableCell align="center">
                                            <Typography variant='h6'>
                                                Opening Stock: {stockLedger.filter(u => u.UnitUniqueId == selectedUnit.UnitUniqueId)[0].PreviousQty}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>

                                    {stockLedger.filter(s => s.UnitUniqueId == selectedUnit.UnitUniqueId).map((row,index) => <TableRow
                                        hover
                                        key={`TableDetailRow_SUMMARY_${row.Id}`}
                                        tabIndex={-1}
                                        role="checkbox"
                                        selected={false}
                                        aria-checked={false}
                                    >
                                        <TableCell align="center">{index + 1}</TableCell>
                                        <TableCell align="center">{row.ProductName}</TableCell>
                                        <TableCell align="center">
                                            {formattedDate(row.RecordedOn,defaultDateTimeTwelveHourFormat)}
                                        </TableCell>
                                        <TableCell align="center"><Typography variant='subtitle2'>{row.UpdateDescription ?? ''}</Typography></TableCell>
                                        <TableCell align="center"><Typography variant='subtitle2' style={{color:'green'}}>{row.TransactionQty > 0 ? row.TransactionQty : ''}</Typography></TableCell>
                                        <TableCell align="center"><Typography variant='subtitle2' style={{color:'red'}}>{row.TransactionQty < 0 ? (row.TransactionQty * -1) : ''}</Typography></TableCell>
                                        <TableCell align="center"><Typography variant='subtitle2'>{row.NewQty}</Typography></TableCell>
                                    </TableRow>)}

                                    <TableRow
                                        hover
                                        key={`TableDetailRow_SUMMARY_Closing`}
                                        tabIndex={-1}
                                        role="checkbox"
                                        selected={false}
                                        aria-checked={false}
                                    >
                                        <TableCell colSpan={6}>&nbsp;</TableCell>
                                        <TableCell align="center">
                                            <Typography variant='h6'>
                                                Closing Stock: {stockLedger.filter(u => u.UnitUniqueId == selectedUnit.UnitUniqueId)[stockLedger.filter(u => u.UnitUniqueId == selectedUnit.UnitUniqueId).length - 1].NewQty}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>

                                </TableBody>
                            </Table>}
                        </TableContainer>
                    </Scrollbar>
                    }
                </Card><br /></>})}
            </Container>
        </Page></> : <NoUnitSelectedComponent pageTitle='Stock Ledger | Raza Pak Care' />
    );
}


const mapStateToProps = (state: IApplicationState) => {
    return {
        selectedUnits: state.selectedUnitAccesses,
        loginUser: state.loginUser,
        userRoutes:state.userRoutes
    };
}
const connectedStockLedger = connect(mapStateToProps)(StockLedger);
export { connectedStockLedger as StockLedger };