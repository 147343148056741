import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IApplicationState } from 'src/redux';
import { useSelector } from 'react-redux';
import { Container, Grid, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { IAutoComplete } from 'src/interfaces/auto-complete.interface';
import { CustomAutoComplete } from './common/CustomAutoComplete';
import { employeeApi, masterDataApi, routeApi } from 'src/http';
import { shopApi } from 'src/http/shop.http';
import { IRouteShopDetail } from 'src/interfaces/route-shop-detail.interface';
import { IShopUpdateRequest } from 'src/interfaces/shop-update-request.interface';
import { getCopy, isValidCellNumber } from 'src/utility';
import { IMessage } from 'src/interfaces/message.interface';
import { MessageComponent } from './messages.component';

interface IProps {
    shop: IRouteShopDetail;
    onClose: () => void;
    onSuccess: (updatedShopInfo: IShopUpdateRequest) => void;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    paddingTop: 2,
    paddingBottom: 2
};

export const EditShopPopup = (props: IProps) => {
    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick") {
            return;
        }

        props.onClose();
    }

    const loginUser = useSelector((state: IApplicationState) => state.loginUser);
    const [shopForEdit, setShopForEdit] = React.useState<IShopUpdateRequest>({
        CellOne: props.shop.CellOne,
        CellTwo: props.shop.CellTwo,
        ShopAddress: props.shop.ShopAddress,
        ShopId: props.shop.ShopId,
        ShopName: props.shop.ShopName,
        ShopTypeId: props.shop.ShopTypeId,
        ShopType: props.shop.ShopType
    });


    const [isShopTypesLoading, setIsShopTypesLoading] = React.useState<boolean>(false);
    const [shopTypes, setShopTypes] = React.useState<IAutoComplete[]>([]);

    const [pageMessages, setPageMessages] = React.useState<IMessage[]>([]);
    const addMessage = (messageText: string, variant: 'success' | 'error' | 'info' | 'warning') => {
        if (!!messageText && messageText.trim() != '') {
            let msgs = [];
            msgs.push({
                messageText,
                variant
            });

            setPageMessages([...msgs]);
        }
    }

    const showErrorMessage = (message: string) => addMessage(message, 'error');
    const showInfoMessage = (message: string) => addMessage(message, 'info');
    const showSuccessMessage = (message: string) => addMessage(message, 'success');
    const showWarningMessage = (message: string) => addMessage(message, 'warning');

    const loadShopTypes = () => {
        setIsShopTypesLoading(true);
        masterDataApi.getShopTypes()
            .then(res => {
                if (!!res && res.length > 0) {
                    setShopTypes(res.map(a => { return { DisplayText: a.TypeDescription, Value: a.Id } }))

                } else {
                    setShopTypes([]);
                }
                setIsShopTypesLoading(false);
            })
            .catch(e => {
                setIsShopTypesLoading(false);
                setShopTypes([]);
            });
    }



    const onChangeShopName = (event: any) => {
        setPageMessages([]);
        setShopForEdit({ ...shopForEdit, ShopName: event.target.value });
    }

    const onChangeShopAddress = (event: any) => {
        setPageMessages([]);
        setShopForEdit({ ...shopForEdit, ShopAddress: event.target.value });
    }

    const onCellOneChange = (event: any) => {
        setPageMessages([]);
        setShopForEdit({ ...shopForEdit, CellOne: event.target.value });
    }

    const onCellTwoChange = (event: any) => {
        setPageMessages([]);
        setShopForEdit({ ...shopForEdit, CellTwo: event.target.value });
    }

    const onShopTypeChange = (option: IAutoComplete) => {
        setPageMessages([]);
        setShopForEdit({ ...shopForEdit, ShopTypeId: option?.Value ?? 0, ShopType: option?.DisplayText ?? '' });
    }

    const validate = () => {
        if (!shopForEdit?.ShopName || shopForEdit.ShopName.trim() === '') {
            showErrorMessage('Invalid Shop Name');
            return false;
        }

        if (!shopForEdit?.ShopAddress || shopForEdit.ShopAddress.trim() === '' || shopForEdit.ShopAddress.length < 10) {
            showErrorMessage('Invalid Shop Address');
            return false;
        }

        if (((!!props.shop.CellOne && props.shop.CellOne.trim() != '') || (!!props.shop.CellTwo && props.shop.CellTwo.trim() !== '')) && ((!shopForEdit.CellOne || shopForEdit.CellOne.trim() === '') && (!shopForEdit.CellTwo || shopForEdit.CellTwo.trim() === ''))) {
            showErrorMessage('Cell Number is required');
            return false;
        }

        if (!!shopForEdit.CellOne && shopForEdit.CellOne.trim() !== '' && !isValidCellNumber(shopForEdit.CellOne)) {
            showErrorMessage('Invalid Cell One.');
            return false;
        }

        if (!!shopForEdit.CellTwo && shopForEdit.CellTwo.trim() !== '' && !isValidCellNumber(shopForEdit.CellTwo)) {
            showErrorMessage('Invalid Cell Two.');
            return false;
        }

        if (!shopForEdit?.ShopTypeId || shopForEdit.ShopTypeId <= 0) {
            showErrorMessage('Shop Type Is Required');
            return false;
        }

        return true;
    }


    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const onSave = () => {
        if (validate()) {
            setIsLoading(true);
            shopApi.updateShopDetail(loginUser?.UserId ?? 0, getCopy(shopForEdit))
                .then(() => {
                    setIsLoading(false);
                    props.onSuccess(shopForEdit);
                })
                .catch(() => {
                    setIsLoading(false);
                });
        }
    }

    React.useEffect(() => {
        loadShopTypes();
    }, []);

    return <Modal
        open={!!props.shop && props.shop.ShopId > 0}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={style}>
            <Grid item md={12} style={{ justifyContent: 'center', display: 'flex' }}>
                <Typography style={{ alignSelf: 'center' }} id="modal-modal-title" variant="h6" component="h2">
                    Edit Shop
                </Typography>
            </Grid>



            <Container>
                <Grid container spacing={2} style={{ marginTop: 5 }}>
                <Grid item md={12} xs={12}>
                    <MessageComponent onRemove={(i: number) => {
                        let msgs = [...pageMessages];
                        msgs.splice(i, 1);
                        setPageMessages([...msgs]);
                    }} messages={pageMessages} /></Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: 10 }}>
                    <Grid item md={12} xs={12}>
                        <TextField
                            fullWidth
                            autoComplete="shop_name"
                            size='small'
                            variant='outlined'
                            type={'text'}
                            label="Shop Name"
                            value={shopForEdit.ShopName ?? ''}
                            onChange={onChangeShopName}
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <TextField
                            fullWidth
                            autoComplete="cellOne"
                            size='small'
                            variant='outlined'
                            type={'text'}
                            label="Cell One"
                            value={shopForEdit.CellOne ?? ''}
                            onChange={onCellOneChange}
                        />
                    </Grid>

                    <Grid item md={12} xs={12}>
                        <TextField
                            fullWidth
                            autoComplete="cell_two"
                            size='small'
                            variant='outlined'
                            type={'text'}
                            label="Cell Two"
                            value={shopForEdit.CellTwo ?? ''}
                            onChange={onCellTwoChange}
                        />
                    </Grid>

                    <Grid item md={12} xs={12}>
                        {<CustomAutoComplete<IAutoComplete>
                            Id={'shop_types_autocomplete'}
                            Value={!!shopTypes && shopTypes.length > 0 ? shopTypes.find(m => m.Value == (shopForEdit?.ShopTypeId ?? 0)) ?? null : null}
                            Options={shopTypes}
                            displayField={'DisplayText'}
                            valueField={'Value'}
                            Label={'Shop Type'}
                            onChange={onShopTypeChange}
                            size='small'
                            isLoading={isShopTypesLoading}
                        />}
                    </Grid>

                    <Grid item md={12}>
                        <TextField
                            id={'shop_address'}
                            fullWidth
                            placeholder={'Enter Shop Address'}
                            label={'Address'}
                            multiline={true}
                            rows={3}
                            value={shopForEdit?.ShopAddress ?? ''}
                            onChange={onChangeShopAddress}
                            InputProps={{ style: { fontSize: '12px' } }}
                        />
                    </Grid>
                </Grid>

                <Grid item md={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <LoadingButton
                        size="small"
                        type="button"
                        variant="outlined"
                        loading={isLoading}
                        style={{ marginTop: 15, alignSelf: 'center', marginRight: 10, borderRadius: 0 }}
                        onClick={() => {
                            onSave();
                        }}
                    >
                        Save
                    </LoadingButton>

                    <LoadingButton
                        size="small"
                        type="button"
                        variant="outlined"
                        loading={false}
                        disabled={false}
                        style={{ marginTop: 15, alignSelf: 'center', color: 'gray', borderRadius: 0, borderColor: 'gray' }}
                        onClick={props.onClose}
                    >
                        Cancel
                    </LoadingButton>
                </Grid>
            </Container>
        </Box>
    </Modal>;
}