import {http} from './http-base';
import {IUserDetail} from '../interfaces';
import { IUserRouteDetail } from 'src/interfaces/routes/user-route-detail.interface';

const getUserDetail = async (
  email: string | undefined,
): Promise<IUserDetail> => {
  let url: string = `api/ApplicationUser/UserDetails?userName=${encodeURIComponent(
    email ?? '',
  )}`;
  let result = await http
    .get<IUserDetail>(url)
    .catch(error => Promise.reject(error));
  return result.data;
};

const getUserRouteDetail = async (
  userId:number
): Promise<IUserRouteDetail> => {
  let url: string = `api/ApplicationUser/UserRouteDetail?userId=${userId}`;
  let result = await http
    .get<IUserRouteDetail>(url)
    .catch(error => Promise.reject(error));
  return result.data;
};

const userApi = {
  getUserDetail,
  getUserRouteDetail
};

export {userApi};
