import React from 'react';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import refreshFill from '@iconify/icons-eva/refresh-fill';
// material
import {
    Card,
    Table,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    Grid,
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { UserListHead } from '../components/_dashboard/user';
//
import { LoadingButton } from '@mui/lab';
import { doCreditApi } from '../http';
import { IApplicationState } from 'src/redux';
import { Audio as AudoSpin } from 'react-loader-spinner'
import { IEmployeeCredit } from 'src/interfaces/reports/employee-credit.interface';
import { fCurrency } from 'src/utils/formatNumber';
import { connect } from 'react-redux';
import { IUserDetail } from 'src/interfaces';


// ----------------------------------------------------------------------

const TABLE_HEAD_CREDIT = [
    { id: 'sr', label: 'Sr.', alignContent: 'center' },
    { id: 'unit_name', label: 'Unit', alignContent: 'center' },
    { id: 'responsible_person', label: 'Responsible Person', alignContent: 'center' },
    { id: 'cell_no', label: 'Cell#', alignContent: 'center' },
    {id: 'pending_credit', label:'Pending Credit', alignContent:'center'},
    {id: 'action', label:'', alignContent:'center'}
];

// ----------------------------------------------------------------------
interface IProps{
    loginUser:IUserDetail;
}
const EmployeeCredit = (props:IProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [employeeCredit, setEmployeeCredit] = React.useState<IEmployeeCredit[]>([]);
    const [totalCredit,setTotalCredit] = React.useState<number>(0);
    const getEmployeeCredit = () => {
        setIsLoading(true);
        setEmployeeCredit([]);
        setTotalCredit(0);
        //revist
        doCreditApi.getEmployeeCredit('',props.loginUser.UserId)
            .then(empCredit => {
                setIsLoading(false);
                if(!!empCredit && empCredit.length > 0){
                    let tCredit = 0;
                    empCredit.forEach(c => {
                        tCredit += c.TotalPendingCredit;
                    });

                    setEmployeeCredit([...empCredit]);
                    setTotalCredit(tCredit);
                }
            })
            .catch(e => {
                setIsLoading(false);
                console.log(e);
            })
    }

    React.useEffect(() => {
        getEmployeeCredit();
    }, []);

    React.useEffect(() =>{
        getEmployeeCredit();
    },[props.loginUser])

    return (
        <Page title="Employee Credit">
            <Container style={{ maxWidth: 1500, paddingLeft: 0, paddingRight: 0 }}>
                <Grid container style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>

                    <LoadingButton
                        size="small"
                        type="button"
                        variant="contained"
                        loading={isLoading}
                        style={{ margin: 3, backgroundColor: 'green', textAlign: 'right', height: 40 }}
                        startIcon={<Icon icon={refreshFill} />}
                        onClick={getEmployeeCredit}
                    >
                        Refresh
                    </LoadingButton>
                </Grid>
                <Card>
                    <Typography style={{ backgroundColor: 'rgba(34, 154, 22, 0.72)', color: 'white', paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h4'}>Credit Details</Typography>
                    {isLoading == true && <AudoSpin
                        height="80"
                        width="80"
                        color='green'
                        ariaLabel='three-dots-loading'
                        wrapperStyle={{ alignSelf: 'center', justifyContent: 'center' }}
                    />}

                    {(!employeeCredit || employeeCredit.length <= 0) && isLoading == false && <Typography style={{ paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h6'}>No Credit Details Available</Typography>}
                    {!!employeeCredit && employeeCredit.length > 0 && <Scrollbar sx={{}}>
                        <TableContainer sx={{ minWidth: 800 }}>
                            {<Table size='small'>
                                <UserListHead
                                    order={'asc'}
                                    orderBy={''}
                                    headLabel={TABLE_HEAD_CREDIT}
                                    rowCount={0}
                                    numSelected={0}
                                    onRequestSort={undefined}
                                    onSelectAllClick={undefined}
                                    customKey={`TableHeadRow_2`}
                                />

                                <TableBody key={`Table_Body_Credit_Details`}>
                                    {employeeCredit.map((row,index) => <TableRow
                                        hover
                                        key={`TableDetailRow_Credit_${row.EmployeeId}`}
                                        tabIndex={-1}
                                        role="checkbox"
                                        selected={false}
                                        aria-checked={false}
                                    >
                                        <TableCell align="center">{index + 1}</TableCell>
                                        <TableCell align="center">{row.UnitTitle}</TableCell>
                                        <TableCell align="center">
                                            {`${row.FirstName} ${row.LastName}${!!row.EmployeeTagId && row.EmployeeTagId.trim() != '' ? ` [${row.EmployeeTagId}]` : ''}`}
                                        </TableCell>
                                        <TableCell align="center">{`${!!row.CellNumberOne && row.CellNumberOne.trim() != '' ? row.CellNumberOne : (!!row.CellNumberTwo && row.CellNumberTwo.trim() != '' ? `${row.CellNumberTwo}` : 'N/A')}`}</TableCell>
                                        <TableCell align="center" style={{color:row.TotalPendingCredit > 0 ? 'red' : 'green'}}><h3>{fCurrency(row.TotalPendingCredit)}</h3></TableCell>
                                        <TableCell align='center'></TableCell>
                                    </TableRow>)}
                                    <TableRow>
                                        <TableCell colSpan={4} align='right'><h3>Total:</h3></TableCell>
                                        <TableCell  align='center' style={{color:totalCredit > 0 ? 'red' : 'green'}}><h2>{fCurrency(totalCredit)}</h2></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>}
                        </TableContainer>
                    </Scrollbar>
                    }
                </Card>
            </Container>
        </Page>
    );
}

const mapStateToProps = (state:IApplicationState) =>{
    return {
        loginUser:state.loginUser
    }
}

const connectedEmployeeCredit = connect(mapStateToProps)(EmployeeCredit);
export {connectedEmployeeCredit as EmployeeCredit};
