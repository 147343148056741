import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IApplicationState } from 'src/redux';
import { setNewOrderReportPrintSettingAction } from 'src/redux/actions/new-order-report-print-setting.action';
import { connect } from 'react-redux';
import { INewOrderReportSetting } from 'src/interfaces/new-order-report-setting.interface';
import { IAction } from 'src/redux/actions/action.interface';
import { Checkbox, Container, FormControlLabel, Grid } from '@mui/material';
import { NewOrderReportPrintSetting, NewOrderReportPrintSettings } from 'src/app.constant';
import LoadingButton from '@mui/lab/LoadingButton';

interface IProps{
    open:boolean;
    onClose:() => void;
    newOrderReportPrintSetting:INewOrderReportSetting | null;
    setNewOrderReportPrintSetting:(payload:INewOrderReportSetting) => IAction<INewOrderReportSetting>;
    onPrint:() => void;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const OrderNewReportPrintSettingModal = (props:IProps) =>{

    return <Modal
    open={props.open}
    onClose={props.onClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"

    disablePortal
  >
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Print Settings
      </Typography>
      <Container>
      <Grid container>
        
      <Grid item md={12} key={`Grid_Item_Select_All`}>
                      <FormControlLabel
                        key={'Select_All'}
                        control={
                          <Checkbox
                            value={'SelectAll'}
                            checked={props.newOrderReportPrintSetting.columnsToPrint.length == NewOrderReportPrintSettings.length}
                            onChange={(e:any) => {
                                let val = e.target.value;
                                
                                if(props.newOrderReportPrintSetting.columnsToPrint.length == NewOrderReportPrintSettings.length){
                                    props.setNewOrderReportPrintSetting({columnsToPrint:[NewOrderReportPrintSetting.Target]})
                                }else{
                                    props.setNewOrderReportPrintSetting({columnsToPrint:[...NewOrderReportPrintSettings.map(a => a)]})
                                }
                            }}
                          />
                        }
                        label={'Select All'}
                      />
                      </Grid>

        {NewOrderReportPrintSettings.map((item) => (
            <Grid item md={4} key={`Grid_${item}`}>
                      <FormControlLabel
                        key={item}
                        control={
                          <Checkbox
                            value={item}
                            checked={!!props.newOrderReportPrintSetting && !!props.newOrderReportPrintSetting.columnsToPrint && props.newOrderReportPrintSetting.columnsToPrint.findIndex(m => m == item) >= 0}
                            onChange={(e:any) => {
                                let val = e.target.value;
                                console.log(val);
                                if(!!props.newOrderReportPrintSetting && !!props.newOrderReportPrintSetting.columnsToPrint && props.newOrderReportPrintSetting.columnsToPrint.length > 0){
                                    if(props.newOrderReportPrintSetting.columnsToPrint.length == 1 && props.newOrderReportPrintSetting.columnsToPrint.findIndex(n => n == val) >= 0){
                                        //show message here
                                    }else{
                                        let printColums = {...props.newOrderReportPrintSetting, columnsToPrint:[...props.newOrderReportPrintSetting.columnsToPrint.map(a => a)]};
                                        if(!!printColums && !!printColums.columnsToPrint){
                                            if(printColums.columnsToPrint.findIndex(m => m == val) >= 0){
                                                let filtered = printColums.columnsToPrint.filter(m => m != val);
                                                props.setNewOrderReportPrintSetting({columnsToPrint:[...filtered.map(a => a)]});
                                            }else{
                                                props.setNewOrderReportPrintSetting({columnsToPrint:[...printColums.columnsToPrint.map(a => a),val]});
                                            }
                                        }
                                    }
                                }else{
                                    props.setNewOrderReportPrintSetting({columnsToPrint:[val]});
                                }
                            }}
                          />
                        }
                        label={item}
                      />
                      </Grid>
                    ))}
      </Grid>
      <Grid item md={12} style={{justifyContent:'center', display:'flex'}}>
                    <LoadingButton
                                    size="small"
                                    type="button"
                                    variant="outlined"
                                    loading={false}
                                    disabled={false}
                                    style={{ marginTop: 15, alignSelf: 'center' }}
                                    onClick={props.onPrint}
                                >
                                    Print
                                </LoadingButton>
                    </Grid>
      </Container>
    </Box>
  </Modal>;
}

const mapStateToProps = (state:IApplicationState) =>{
    return {
        newOrderReportPrintSetting:state.newOrderReportPrintSetting
    };
}

const mapDispatchToState = {
    setNewOrderReportPrintSetting:setNewOrderReportPrintSettingAction
};

const connectedComponent = connect(mapStateToProps, mapDispatchToState)(OrderNewReportPrintSettingModal);

export {connectedComponent as OrderNewReportPrintSettingModal};