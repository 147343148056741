import {http} from './http-base';
import {concatParam, formattedDate} from '../utility'
import { IResponseModal } from 'src/interfaces/response-modal.interface';
import { IDOCashPostModel } from 'src/interfaces/do-cash-post-model.interface';
import { IDOCashDetail } from 'src/interfaces/do-cash-detail.interface';
import { ICollectedCashDateWise } from 'src/interfaces/collected-cash-date-wise.interface';
import { ICashDetail } from 'src/interfaces/cash-detail.interface';
import { ICashSubmissionHistory } from 'src/interfaces/cash-submission-history.interface';
import { IDepositHistory } from 'src/interfaces/deposit-history.interface';


  const saveDOCashDetails = async (
    loggedInUserId:number,
    doId:number,
    doCashDetail:IDOCashPostModel
  ): Promise<IResponseModal> => {

    let params:string = '';
    params = concatParam(params,doId,'doId');
    params = concatParam(params,loggedInUserId,'loggedInUserId');

    let url: string = `api/DOCash/PostCashDetail${params}`;

    let result = await http
      .post<IResponseModal>(url,doCashDetail)
      .catch(error => Promise.reject(error));
    return result.data;
  };

  const getCashDetailsFroSubmission = async (
    unitUniqueId:string
  ): Promise<IDOCashDetail> => {

    let params = '';
    params = concatParam(params,unitUniqueId,'unitUniqueId');
    let url: string = `api/DOCash/CashDetailForSubmission${params}`;

    let result = await http
      .get<IDOCashDetail>(url)
      .catch(error => Promise.reject(error));
    return result.data;
  };

  const markCollectedCashAsSubmitted = async (
    unitUniqueId:string
    ): Promise<number> => {
  
      let params = '';
      params = concatParam(params,unitUniqueId,'unitUniqueId');
      let url: string = `api/DOCash/MarkSubmitted${params}`;
  
      let result = await http
        .post<number>(url)
        .catch(error => Promise.reject(error));
      return result.data;
    };

    const getCashDetailsDateWise = async (
      loginUserId:number,
      commaSeparatedUnitUniqueIds:string
    ): Promise<ICollectedCashDateWise[]> => {
  
      let params = '';
      params = concatParam(params,loginUserId,'loginUserId');
      params = concatParam(params,commaSeparatedUnitUniqueIds,'commaSeparatedUnitUniqueIds');
      let url: string = `api/DOCash/CollectedCashDetailDateWise${params}`;
  
      let result = await http
        .get<ICollectedCashDateWise[]>(url)
        .catch(error => Promise.reject(error));
      return result.data;
    };

    const markCashAsRequested = async (
      loginUserId:number,
      unitUniqueId:string,
      commaSeparatedDoCashIds:string,
      commaSeparatedCreditReceiveIds:string
      ): Promise<number> => {
    
        let params = '';
        params = concatParam(params,loginUserId,'loginUserId');
        params = concatParam(params,unitUniqueId,'unitUniqueId');

        if(!!commaSeparatedDoCashIds && commaSeparatedDoCashIds.trim() != ''){
          params = concatParam(params,commaSeparatedDoCashIds,'commaSeparatedDoCashIds');
        }

        if(!!commaSeparatedCreditReceiveIds && commaSeparatedCreditReceiveIds.trim() != ''){
          params = concatParam(params,commaSeparatedCreditReceiveIds,'commaSeparatedCreditReceiveIds');
        }

        let url: string = `api/DOCash/MarkRequestedCreditAndDoCash${params}`;
    
        let result = await http
          .post<number>(url)
          .catch(error => Promise.reject(error));
        return result.data;
      };

      const markAsDeposited = async (
        loginUserId:number,
        unitUniqueId:string,
        commaSeparatedDoCashIds:string,
        commaSeparatedCreditReceiveIds:string
        ): Promise<number> => {
      
          let params = '';
          params = concatParam(params,loginUserId,'loginUserId');
          params = concatParam(params,unitUniqueId,'unitUniqueId');
  
          if(!!commaSeparatedDoCashIds && commaSeparatedDoCashIds.trim() != ''){
            params = concatParam(params,commaSeparatedDoCashIds,'commaSeparatedDoCashIds');
          }
  
          if(!!commaSeparatedCreditReceiveIds && commaSeparatedCreditReceiveIds.trim() != ''){
            params = concatParam(params,commaSeparatedCreditReceiveIds,'commaSeparatedCreditReceiveIds');
          }
  
          let url: string = `api/DOCash/MarkAsDeposited${params}`;
      
          let result = await http
            .post<number>(url)
            .catch(error => Promise.reject(error));
          return result.data;
        };

      const markRequestedCashAsSubmitted = async (
        loginUserId:number,
        unitUniqueId:string,
        commaSeparatedDoCashIds:string,
        commaSeparatedCreditReceiveIds:string
        ): Promise<number> => {
      
          let params = '';
          params = concatParam(params,loginUserId,'loginUserId');
          params = concatParam(params,unitUniqueId,'unitUniqueId');
  
          if(!!commaSeparatedDoCashIds && commaSeparatedDoCashIds.trim() != ''){
            params = concatParam(params,commaSeparatedDoCashIds,'commaSeparatedDoCashIds');
          }
  
          if(!!commaSeparatedCreditReceiveIds && commaSeparatedCreditReceiveIds.trim() != ''){
            params = concatParam(params,commaSeparatedCreditReceiveIds,'commaSeparatedCreditReceiveIds');
          }
  
          let url: string = `api/DOCash/MarkSubmittedCreditAndDoCash${params}`;
      
          let result = await http
            .post<number>(url)
            .catch(error => Promise.reject(error));
          return result.data;
        };

      const getCashDetailsForRequestAndConfirmationDateWise = async (
        loginUserId:number,
        commaSeparatedUnitUniqueIds:string
      ): Promise<ICashDetail> => {
    
        let params = '';
        params = concatParam(params,loginUserId,'loginUserId');
        params = concatParam(params,commaSeparatedUnitUniqueIds,'commaSeparatedUnitUniqueIds');
        let url: string = `api/DOCash/CashDetailForRequestAndConfirmationDateWise${params}`;
    
        let result = await http
          .get<ICashDetail>(url)
          .catch(error => Promise.reject(error));
        return result.data;
      };

      const getCashSubmissionHsitory = async (
        loginUserId:number,
        commaSeparatedUnitUniqueIds:string,
        fromDate:Date,
        toDate:Date
      ): Promise<ICashSubmissionHistory[]> => {
    
        let params = '';
        params = concatParam(params,loginUserId,'loginUserId');
        params = concatParam(params,commaSeparatedUnitUniqueIds,'commaSeparatedUnitUniqueIds');
        const fromattedFromDate = formattedDate(fromDate);
        params = concatParam(params,fromattedFromDate,'fromDate');
        const formattedToDate = formattedDate(toDate);
        params = concatParam(params,formattedToDate,'toDate');
        let url: string = `api/DOCash/CashSubmissionHistory${params}`;
    
        let result = await http
          .get<ICashSubmissionHistory[]>(url)
          .catch(error => Promise.reject(error));
        return result.data;
      };

      const getDepositHistory = async (
        loginUserId:number,
        commaSeparatedUnitUniqueIds:string,
        fromDate:Date,
        toDate:Date
      ): Promise<IDepositHistory[]> => {
    
        let params = '';
        params = concatParam(params,loginUserId,'loginUserId');
        params = concatParam(params,commaSeparatedUnitUniqueIds,'commaSeparatedUnitUniqueIds');
        const fromattedFromDate = formattedDate(fromDate);
        params = concatParam(params,fromattedFromDate,'fromDate');
        const formattedToDate = formattedDate(toDate);
        params = concatParam(params,formattedToDate,'toDate');
        let url: string = `api/DOCash/CashDepositHistory${params}`;
    
        let result = await http
          .get<IDepositHistory[]>(url)
          .catch(error => Promise.reject(error));
        return result.data;
      };

      const getRequestHistory = async (
        loginUserId:number,
        commaSeparatedUnitUniqueIds:string,
        fromDate:Date,
        toDate:Date
      ): Promise<IDepositHistory[]> => {
    
        let params = '';
        params = concatParam(params,loginUserId,'loginUserId');
        params = concatParam(params,commaSeparatedUnitUniqueIds,'commaSeparatedUnitUniqueIds');
        const fromattedFromDate = formattedDate(fromDate);
        params = concatParam(params,fromattedFromDate,'fromDate');
        const formattedToDate = formattedDate(toDate);
        params = concatParam(params,formattedToDate,'toDate');
        let url: string = `api/DOCash/CashRequestHistory${params}`;
    
        let result = await http
          .get<IDepositHistory[]>(url)
          .catch(error => Promise.reject(error));
        return result.data;
      };
  
  const doCashApi = {
    saveDOCashDetails,
    getCashDetailsFroSubmission,
    markCollectedCashAsSubmitted,
    getCashDetailsDateWise,
    markCashAsRequested,
    getCashDetailsForRequestAndConfirmationDateWise,
    markRequestedCashAsSubmitted,
    getCashSubmissionHsitory,
    markAsDeposited,
    getDepositHistory,
    getRequestHistory
  };
  
  export {doCashApi};
