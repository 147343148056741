// routes
import React from 'react';
import {Router} from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';

import { Provider as ReduxProvider } from 'react-redux';
import { store, persistor } from './redux';
import { PersistGate } from 'redux-persist/integration/react';
import { SnackbarProvider } from 'notistack';
import { ConfirmationPopup } from './components/confirmation-pop-up.component';


// ----------------------------------------------------------------------

export default function App() {
  return (
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeConfig>
          <ScrollToTop />
          <GlobalStyles />
          <BaseOptionChartStyle />
          <ConfirmationPopup />
          <SnackbarProvider><Router /></SnackbarProvider>
        </ThemeConfig>
      </PersistGate>
    </ReduxProvider>
  );
}
