import React from 'react';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import arrowBackFill from '@iconify/icons-eva/arrow-back-fill';
import printerFill from '@iconify/icons-eva/printer-fill';
import refreshFill from '@iconify/icons-eva/refresh-fill';
import saveFill from '@iconify/icons-eva/save-fill';
// material
import {
    Card,
    Table,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    Grid,
    Stack,
    Avatar,
    TextField,
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { UserListHead } from '../components/_dashboard/user';
//
import { LoadingButton } from '@mui/lab';
import { employeeApi, deliveryOrderApi, productApi } from '../http';
import { IAutoComplete } from 'src/interfaces/auto-complete.interface';
import { formattedDate, getCommaSeparatedString, getCommaSeparatedUnitUniqueIdsExceptAll } from 'src/utility';
import { connect } from 'react-redux';
import { IApplicationState } from 'src/redux';
import { IOrderBookerForDO } from 'src/interfaces/delivery-order/order-booker-do.interface';
import { Audio as AudoSpin } from 'react-loader-spinner'
import { IDODetail } from 'src/interfaces/delivery-order/do-detail.interface';
import BarcodeReader from 'react-barcode-reader'
import { IDeliveryOrderSummary } from 'src/interfaces/delivery-order/delivery-order-summary.interface';
import { IProductBarcode } from 'src/interfaces/product-barcode.interface';
import Label from 'src/components/Label';
import { sentenceCase } from 'change-case';
import { confirmAction } from 'src/utility/confirm.util';
import { IMessage } from 'src/interfaces/message.interface';
import { MessageComponent } from 'src/components/messages.component';
import { IUserDetail } from 'src/interfaces';
import { ISelectedUnitAccess } from 'src/interfaces/selected-unit-access.interface';
import { AllOptionUniqueId, DoDetailFor } from 'src/app.constant';
import { IDOResultWithDetail } from 'src/interfaces/delivery-order/do-result-with-detail.interface';
import { NoUnitSelectedComponent } from 'src/components/common/no-unit-selected.component';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'sr', label: 'Sr.', alignContent: 'center' },
    { id: 'product_name', label: 'Product', alignContent: 'center' },
    { id: 'available_stock', label: 'Stock', alignContent: 'center' },
    { id: 'order_qty', label: 'Order Qty', alignContent: 'center' },
    { id: 'scheme_qty', label: 'Scheme Qty', alignContent: 'center' },
    { id: 'total_qty_to_issue', label: 'Qty To Issue', alignContent: 'center' },
    { id: 'scanned_qty', label: 'Entered Qty', alignContent: 'center' },
    { id: 'issuance_status', label: 'Status', alignContent: 'center' },
];

const TABLE_HEAD_DO = [
    { id: 'collapse', label: '', alignContent: 'center' },
    { id: 'sr', label: 'Sr.', alignContent: 'center' },
    { id: 'delivery_boy', label: 'Delivery Boy', alignContent: 'center' },
    { id: 'orders_date', label: 'Orders Date', alignContent: 'center' },
    { id: 'do_status', label: 'DO Status', alignContent: 'center' },
    { id: 'delete_do', label: '', alignContent: 'center' },
];

const TABLE_HEAD_DO_Detail = [
    { id: 'sr', label: 'Sr.', alignContent: 'center' },
    { id: 'product', label: 'Product', alignContent: 'center' },
    { id: 'order_qty', label: 'Order Qty', alignContent: 'center' },
    { id: 'scheme_qty', label: 'Scheme Qty', alignContent: 'center' },
    { id: 'total_issued_qty', label: 'Total Qty', alignContent: 'center' },
];


// ----------------------------------------------------------------------
interface IProps{
    loginUser:IUserDetail | null;
    selectedUnits:ISelectedUnitAccess;
  }

const IssueStock = (props:IProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isIssuingStock, setIsIssuingStock] = React.useState(false);
    const [selectedDOSummary, setSelectedDOSummary] = React.useState<IDeliveryOrderSummary[]>([]);
    const [selectedDO, setSelectedDO] = React.useState<IDODetail>(null);
    const [doDetail, setDODetails] = React.useState<IDOResultWithDetail>(null);
    const showErrorMessage = (message: string) => addMessage(message, 'error');
    const showInfoMessage = (message: string) => addMessage(message, 'info');
    const showSuccessMessage = (message: string) => addMessage(message, 'success');
    const showWarningMessage = (message: string) => addMessage(message, 'warning');
    const [pageMessages, setPageMessages] = React.useState<IMessage[]>([]);
    const addMessage = (messageText: string, variant: 'success' | 'error' | 'info' | 'warning') => {
        if (!!messageText && messageText.trim() != '') {
            let msgs = [];
            msgs.push({
                messageText,
                variant
            });

            setPageMessages([...msgs]);
        }
    }

    const resetEverything = () => {
        setDODetails(null);
        setIsIssuingStock(false);
        setSelectedDOSummary([]);
        setSelectedDO(null);
        getDeliveryOrders();
        setIsViewingSummary(false);
    }


    const getDeliveryOrders = () => {
        setIsLoading(true);
        deliveryOrderApi.getDeliveryOrders(props.loginUser.UserId,getCommaSeparatedUnitUniqueIdsExceptAll([...props.selectedUnits.UnitAccesses.map(a => a)]),DoDetailFor.StockIssuance,null,null,null,null)
          .then(doResults => {
            setIsLoading(false);
            if (!!doResults) {
              setDODetails({...doResults});
            }
            else {
              setDODetails(null);
            }
          })
          .catch(e => {
            console.log(e);
            setIsLoading(false);
          });
      }

    const getProductBarcodes = () => {
        productApi.getProductBarcodes()
            .then(barcodes => {
                setProductBarcodes([...barcodes]);
            })
            .catch(e => console.log(e));
    }

    const getDoDetailCopy = () =>{
        let detail = {...doDetail,DeliveryOrders:[...doDetail.DeliveryOrders.map(a => a)],CreditWithProductWiseSummary:{...doDetail.CreditWithProductWiseSummary,ProductWiseSummaries:[...doDetail.CreditWithProductWiseSummary.ProductWiseSummaries.map(a => a)]},UnitWiseSummary:[...doDetail.UnitWiseSummary.map(a => a)]};
        return detail;
      }

    const issueStock = (doId: number) => {
        setPageMessages([]);
        const filteredDO = doDetail.DeliveryOrders.find(m => m.DeliveryOrderId == doId);
        setSelectedDO(filteredDO);
        if (!!filteredDO && !!filteredDO.DOSummary && filteredDO.DOSummary.length > 0) {
            setIsIssuingStock(true);

            filteredDO.DOSummary.forEach(s => {
                s.ScannedQty = 0;
            });

            setSelectedDOSummary([...filteredDO.DOSummary]);
        } else {
            showErrorMessage('No Details Found');
        }
    }

    const saveIssuedStock = () => {
        let doIdToIssueStock = 0;
        let isOk = true;

        selectedDOSummary.forEach(s => {
            doIdToIssueStock = s.DOId;
            if (isOk == true) {
                if (s.TotalQtyToIssue != s.ScannedQty) {
                    showErrorMessage('Qty Must Be Equal To Issue Qty For All Products');
                    isOk = false;
                    return;
                }
            }
        });

        if (isOk == true) {
            setIsLoading(true);
            deliveryOrderApi.saveIssuedStock(props.loginUser.UserId, doIdToIssueStock)
                .then(res => {
                    setIsLoading(false);

                    if (res.IsOk == true) {
                        const msg = `Stock Issued Successfully To ${selectedDO.DeliveryBoy}.`;
                        showSuccessMessage(msg);
                        resetEverything();
                    } else {
                        showErrorMessage(res.StatusMessage);
                    }
                })
                .catch(e => {
                    setIsLoading(false);
                    console.log(e);
                });
        }
    }

    const [productBarcodes, setProductBarcodes] = React.useState<IProductBarcode[]>([]);

    const handleIssueQtyChange = (productId: number, qty: number) => {

        if (!productBarcodes || productBarcodes.length <= 0 || productBarcodes.findIndex(m => m.Barcode == qty.toString()) < 0) {
            const doSummary = [...selectedDOSummary];
            const summary = doSummary.find(m => m.ProductId = productId);
            summary.ScannedQty = qty;
            setSelectedDOSummary([...doSummary]);
        } else {
            const doSummary = [...selectedDOSummary];
            const summary = doSummary.find(m => m.ProductId = productId);
            summary.ScannedQty = 0;
            setSelectedDOSummary([...doSummary]);
        }
    }

    const handleIssueQtyChangeNew = (productId: number, qty: number) => {
        setPageMessages([]);
        let doSummary = [...selectedDOSummary.map(a => a)];
        let summary = doSummary.find(m => m.ProductId == productId);
        summary.ScannedQty = isNaN(qty) ? 0 : qty;
        setSelectedDOSummary([...validate(doSummary)]);
    }

    const validate = (summaries:IDeliveryOrderSummary[]) => {
        if(!!summaries && summaries.length > 0){
            summaries.forEach(s => {
                s.IsIssuanceOk = s.TotalQtyToIssue == s.ScannedQty;
            });
        }

        return summaries;
    }

    const handleScannedIssueQtyChange = (productId: number, qty: number) => {
        const doSummary = [...selectedDOSummary];
        const summary = doSummary.find(m => m.ProductId = productId);

        if (isNaN(summary.ScannedQty)) {
            summary.ScannedQty = 0;
        }

        if (!!summary && summary.ProductId > 0) {
            if (!isNaN(qty)) {
                if (summary.ScannedQty + qty > summary.TotalQtyToIssue) {
                    showErrorMessage("Scanned Qty Can't be more than Total Issue Qty");
                    // let audio = new Audio("../assets/ScannedMoreThanIssue.mp3");
                    //     audio.play();
                    //play sound here
                } else {
                    summary.ScannedQty += qty;
                    setSelectedDOSummary([...doSummary]);

                    if (summary.ScannedQty === summary.TotalQtyToIssue) {
                        // let audio = new Audio("../assets/StopNimalWhite132Gram.mp3");
                        // audio.play();
                    }
                }
            }
        } else {
            showErrorMessage('Product Not Found');
        }
    }

    const handleBarcodeScan = (data: any) => {
        if (!!productBarcodes && productBarcodes.length > 0) {
            const barcode = productBarcodes.find(m => m.Barcode == data);

            if (!!barcode && barcode.ProductId > 0) {
                handleScannedIssueQtyChange(barcode.ProductId, barcode.Qty);
            }
            else {
                showErrorMessage('Product Not Found');
            }
        }
    }

    const handleCollapsed = (doId: number) => {
        let detail = getDoDetailCopy();
        let dOrder = detail.DeliveryOrders.find(d => d.DeliveryOrderId == doId);
        let existingStatus = dOrder.IsExpanded;
        dOrder.IsExpanded = existingStatus == undefined || existingStatus == null ? true : !existingStatus;
        setDODetails(detail);
        setPageMessages([]);

    }

    const handleScanError = (err: any) => {
        console.log(err);
    }

    const onBackToList = () =>{
        confirmAction('Stock Issuance', "You have unsaved changes and will be lost on go back. Are you sure you want to go back?",'Yes','No')
        .then(res => {
            if(res == true){
                resetEverything();
                setPageMessages([]);
            }
        })
    }

    const onRefresh = () => {
        resetEverything();
        setPageMessages([]);
    }

    const [isViewingSummary, setIsViewingSummary] = React.useState<boolean>(false);

    const onViewSummaryClick = () => {
        setIsViewingSummary(true);
        setPageMessages([]);
    }

    const onViewDOClick = () => {
        resetEverything();
        setPageMessages([]);
    }

    React.useEffect(() => {
        getDeliveryOrders();
        getProductBarcodes();
    }, [props.selectedUnits.UnitAccesses]);

    return (
        (!!props.selectedUnits && !!props.selectedUnits.UnitAccesses && props.selectedUnits.UnitAccesses.filter(u => u.UnitUniqueId != AllOptionUniqueId).length > 0) ? <>
        <MessageComponent onRemove={(i: number) => {
            let msgs = [...pageMessages];
            msgs.splice(i, 1);
            setPageMessages([...msgs]);
        }} messages={pageMessages} />
        <Page title="DO | Raza Pak Care">
            {/* <BarcodeReader
                onError={handleScanError}
                onScan={handleBarcodeScan}
            /> */}
            {isIssuingStock == true && isViewingSummary == false && <Container style={{ maxWidth: 1500, paddingLeft: 0, paddingRight: 0 }}>
                <Grid container style={{ flexDirection: 'row', justifyContent: 'flex-end' }}><LoadingButton
                    size="small"
                    type="button"
                    variant="contained"
                    loading={isLoading}
                    style={{ margin: 3, backgroundColor: 'rgba(34, 154, 22, 0.72)', textAlign: 'right', height: 40 }}
                    startIcon={<Icon icon={arrowBackFill} />}
                    onClick={onBackToList}
                >
                    Back To List
                </LoadingButton></Grid>
                <Card>
                    <Typography style={{ backgroundColor: 'rgba(34, 154, 22, 0.72)', color: 'white', paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h4'}>Issue Stock ({selectedDO.UnitTitle})</Typography>

                    {isLoading == true && <AudoSpin
                        height="80"
                        width="80"
                        color='green'
                        ariaLabel='three-dots-loading'
                        wrapperStyle={{ alignSelf: 'center', justifyContent: 'center' }}
                    />}

                    {!!selectedDO && <Container style={{ marginTop: 15 }}>
                        <Grid container>
                            <Grid item md={6}>
                                <Typography><strong>DO#:</strong> {selectedDO.DeliveryOrderId}</Typography>
                            </Grid>
                            <Grid item md={6}>
                                <Stack direction="row" alignItems="center" justifyContent={'center'} spacing={2}>
                                    <Typography><strong>Delivery Boy:</strong> </Typography>
                                    <Avatar alt={selectedDO?.DeliveryBoy} src={selectedDO?.DeliveryBoyImageUrl} />
                                    <Typography>{selectedDO?.DeliveryBoy ?? ''}</Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Container>}

                    <br />
                    <hr style={{height:3,backgroundColor:'green'}} />
                    <br />

                    {!!selectedDOSummary && selectedDOSummary.length > 0 && <Scrollbar sx={{}}>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    order={'asc'}
                                    orderBy={''}
                                    headLabel={TABLE_HEAD}
                                    rowCount={0}
                                    numSelected={0}
                                    onRequestSort={undefined}
                                    onSelectAllClick={undefined}
                                    customKey={`TableHeadRow_1_13332`}
                                />
                                <TableBody>
                                    {!!selectedDOSummary && selectedDOSummary.length > 0 && selectedDOSummary.map((row, index) => {

                                        return (
                                            <TableRow
                                                hover
                                                key={`${row.DOId}_${row.ProductId}`}
                                                tabIndex={-1}
                                                role="checkbox"
                                                selected={false}
                                                aria-checked={false}
                                            >
                                                <TableCell align="center">{index + 1}</TableCell>
                                                <TableCell align="center">{`${row.ProductName} [${row.ProductId}]`}</TableCell>
                                                <TableCell align="center" component="th" scope="row" padding="none">
                                                    {row?.TotalAvailableStock ?? ''}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row?.TotalOrderQty ?? ''}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row?.TotalSchemeQty ?? ''}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row?.TotalQtyToIssue ?? ''}
                                                </TableCell>
                                                <TableCell width={200} align="center">
                                                    <TextField
                                                        fullWidth
                                                        autoComplete="scanned_qty"
                                                        type={'number'}
                                                        label="Issued Qty"
                                                        value={row.ScannedQty > 0 ? row.ScannedQty : ''}
                                                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                            const value = event.target.value;
                                                            let numberValue: number | null = null;

                                                            if (value !== undefined && value != null) {
                                                                numberValue = parseInt(value);
                                                            }

                                                            handleIssueQtyChangeNew(row.ProductId, numberValue);
                                                        }}
                                                        key={`Product_${row.ProductId}`}
                                                    />
                                                </TableCell>

                                                <TableCell align="center">
                                                    {(row.IsIssuanceOk == undefined || row.IsIssuanceOk == null || row.IsIssuanceOk == false) && <Icon color="red" height={30} width={30} icon={"ep:circle-close-filled"} />}
                                                    {row.IsIssuanceOk == true && <Icon color="green" height={30} width={30} icon={"teenyicons:tick-circle-solid"} />}
                                                </TableCell>

                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Scrollbar>}
                </Card>

                <Container style={{ paddingTop: 30, paddingBottom: 10 }}>
                    <Grid container >
                        <Grid item md={12} style={{ textAlign: 'center' }}>
                            <LoadingButton
                                size="small"
                                type="button"
                                variant="contained"
                                loading={isLoading}
                                style={{ margin: 3, backgroundColor: 'blue', width: 180, height: 50 }}
                                startIcon={<Icon icon={saveFill} />}
                                onClick={saveIssuedStock}
                                disabled={!selectedDOSummary || selectedDOSummary.length <= 0 || !!selectedDOSummary.find(m => m.IsIssuanceOk == undefined || m.IsIssuanceOk == null || m.IsIssuanceOk == false)}
                            >
                                Save
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Container>

            </Container>}




            {isIssuingStock == false  && isViewingSummary == false && <Container style={{ maxWidth: 1500, paddingLeft: 0, paddingRight: 0 }}>
                <Grid container style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>

                    <LoadingButton
                        size="small"
                        type="button"
                        variant="contained"
                        loading={isLoading}
                        style={{ margin: 3, backgroundColor: 'rgba(34, 154, 22, 0.72)', textAlign: 'right', height: 40 }}
                        startIcon={<Icon icon={refreshFill} />}
                        onClick={onRefresh}
                    >
                        Refresh
                    </LoadingButton>
                    <LoadingButton
                        size="small"
                        type="button"
                        variant="contained"
                        loading={isLoading}
                        style={{ margin: 3, backgroundColor: 'blue', textAlign: 'right', height: 40 }}
                        startIcon={<Icon icon='ooui:text-summary-ltr' />}
                        onClick={onViewSummaryClick}
                    >
                        View Summary
                    </LoadingButton>
                </Grid>
                {!!props.selectedUnits && !!props.selectedUnits.UnitAccesses && props.selectedUnits.UnitAccesses.filter(m => m.UnitUniqueId != AllOptionUniqueId).length > 0 && props.selectedUnits.UnitAccesses.filter(m => m.UnitUniqueId != AllOptionUniqueId).map((selectedUnit,unitIndex) => {
                return <React.Fragment key={`Unit_${selectedUnit.UnitUniqueId}`}><Card>
                    <Typography style={{ backgroundColor: 'rgba(34, 154, 22, 0.72)', color: 'white', paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h4'}>DO (Stock Issuance) For {selectedUnit.UnitTitle}</Typography>
                    {isLoading == true && <AudoSpin
                        height="80"
                        width="80"
                        color='green'
                        ariaLabel='three-dots-loading'
                        wrapperStyle={{ alignSelf: 'center', justifyContent: 'center' }}
                    />}

                    {(!doDetail || !doDetail.DeliveryOrders || doDetail.DeliveryOrders.length <= 0 || doDetail.DeliveryOrders.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length <= 0) && isLoading == false && <Typography style={{ paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h6'}>No DO created yet for '{selectedUnit.UnitTitle}'</Typography>}

                    {!!doDetail && !!doDetail.DeliveryOrders && doDetail.DeliveryOrders.length > 0 && doDetail.DeliveryOrders.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length > 0 && <Scrollbar sx={{}}>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table size={'small'} key={`Table_${selectedUnit.UnitUniqueId}`}>
                                <UserListHead
                                    order={'asc'}
                                    orderBy={''}
                                    headLabel={TABLE_HEAD_DO}
                                    rowCount={0}
                                    numSelected={0}
                                    onRequestSort={undefined}
                                    onSelectAllClick={undefined}
                                    customKey={`TableHeadRow_2_${selectedUnit.UnitUniqueId}`}
                                />
                                <TableBody key={`TableBody_${selectedUnit.UnitUniqueId}`}>
                                    {!!doDetail && !!doDetail.DeliveryOrders && doDetail.DeliveryOrders.length > 0 && doDetail.DeliveryOrders.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length > 0 && doDetail.DeliveryOrders.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).map((row, index) => {

                                        return (
                                            <React.Fragment key={`row_child_${row.DeliveryOrderId}`}><TableRow
                                                hover
                                                key={`unique_${row.DeliveryOrderId}`}
                                                tabIndex={-1}
                                                role="checkbox"
                                                selected={false}
                                                aria-checked={false}
                                            >
                                                <TableCell width={10} align="right">
                                                    {row.IsExpanded == true && <Icon color='green' onClick={() => { handleCollapsed(row.DeliveryOrderId) }} style={{ cursor: 'pointer' }} width={22} height={22} icon={'akar-icons:arrow-up'} />}
                                                    {(!row.IsExpanded) && <Icon color='blue' onClick={() => { handleCollapsed(row.DeliveryOrderId) }} style={{ cursor: 'pointer' }} width={22} height={22} icon={'akar-icons:arrow-down'} />}
                                                </TableCell>
                                                <TableCell align="center">{index + 1}</TableCell>
                                                <TableCell align="center" component="th" scope="row" padding="none">
                                                    <Stack direction="row" alignItems="center" justifyContent={'center'} spacing={2}>
                                                        <Avatar alt={row?.DeliveryBoy} src={row?.DeliveryBoyImageUrl} />
                                                        <Typography>{row?.DeliveryBoy ?? ''}</Typography>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell align="center" component="th" scope="row" padding="none">
                                                    {formattedDate(row.OrdersDate,'ddd DD-MM-YYYY')}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Label
                                                        variant="ghost"
                                                        color={'info'}
                                                    >
                                                        {sentenceCase(row?.DOStatus ?? '')}
                                                    </Label>
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.DOStatusId == 1 && <LoadingButton
                                                        size="small"
                                                        type="button"
                                                        variant="contained"
                                                        loading={isLoading}
                                                        style={{ margin: 10 }}
                                                        startIcon={<Icon icon='mdi:truck-delivery' />}
                                                        onClick={() => { issueStock(row.DeliveryOrderId); }}
                                                    >
                                                        Issue Stock
                                                    </LoadingButton>}

                                                    {row.DOStatusId != 1 && <Icon icon="teenyicons:tick-circle-solid" color='green' width={30} height={30} />}
                                                </TableCell>
                                            </TableRow>
                                            {row.IsExpanded == true && <TableRow>
                                                    <TableCell colSpan={6}>
                                                        {<TableContainer sx={{ minWidth: 800 }}>
                                                            <Table>
                                                                <UserListHead
                                                                    order={'asc'}
                                                                    orderBy={''}
                                                                    headLabel={TABLE_HEAD_DO_Detail}
                                                                    rowCount={0}
                                                                    numSelected={0}
                                                                    onRequestSort={undefined}
                                                                    onSelectAllClick={undefined}
                                                                    customKey={`TableHeadRow_2_Unique`}
                                                                />
                                                                <TableBody>
                                                                    {!!row.DOSummary && row.DOSummary.length > 0 && row.DOSummary.map((sRow, sIndex) => {

                                                                        return (
                                                                            <TableRow
                                                                                hover
                                                                                key={`DO_DETAIL_${row.DeliveryOrderId}`}
                                                                                tabIndex={-1}
                                                                                role="checkbox"
                                                                                selected={false}
                                                                                aria-checked={false}
                                                                            >
                                                                                <TableCell width={10} align="center">
                                                                                    {sIndex + 1}
                                                                                </TableCell>
                                                                                <TableCell align="center">{sRow.ProductName}</TableCell>
                                                                                <TableCell align="center">{sRow.TotalOrderQty}</TableCell>
                                                                                <TableCell align="center" component="th" scope="row" padding="none">
                                                                                    {sRow.TotalSchemeQty}
                                                                                </TableCell>
                                                                                <TableCell align="center">
                                                                                    {sRow.TotalQtyToIssue}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        );
                                                                    })}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>}
                                                    </TableCell>
                                                </TableRow>}
                                            </React.Fragment>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Scrollbar>}
                </Card><br /></React.Fragment>})}
            </Container>}

            {isViewingSummary == true && <Container style={{ maxWidth: 1500, paddingLeft: 0, paddingRight: 0 }}>
                <Grid container style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>

                    <LoadingButton
                        size="small"
                        type="button"
                        variant="contained"
                        loading={isLoading}
                        style={{ margin: 3, backgroundColor: 'rgba(34, 154, 22, 0.72)', textAlign: 'right', height: 40 }}
                        startIcon={<Icon icon={refreshFill} />}
                        onClick={getDeliveryOrders}
                    >
                        Refresh
                    </LoadingButton>

                    <LoadingButton
                        size="small"
                        type="button"
                        variant="contained"
                        loading={isLoading}
                        style={{ margin: 3, backgroundColor: 'blue', textAlign: 'right', height: 40 }}
                        startIcon={<Icon icon='ep:van' />}
                        onClick={onViewDOClick}
                    >
                        View DO
                    </LoadingButton></Grid>


                {props.selectedUnits.UnitAccesses.filter(u => u.UnitUniqueId != AllOptionUniqueId).length > 0 && props.selectedUnits.UnitAccesses.filter(u => u.UnitUniqueId != AllOptionUniqueId).map((uForS,uForSIndex) => {
                    return <React.Fragment key={`${uForS.UnitUniqueId}`}><Card>
                    <Typography style={{ backgroundColor: 'rgba(34, 154, 22, 0.72)', color: 'white', paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h4'}>{uForS.UnitTitle}</Typography>
                    {isLoading == true && <AudoSpin
                        height="80"
                        width="80"
                        color='green'
                        ariaLabel='three-dots-loading'
                        wrapperStyle={{ alignSelf: 'center', justifyContent: 'center' }}
                    />}

                    {(!doDetail || !doDetail.UnitWiseSummary || doDetail.UnitWiseSummary.length <= 0 || doDetail.UnitWiseSummary.filter(u => u.UnitUniqueId == uForS.UnitUniqueId).length <= 0) && isLoading == false && <Typography style={{ paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h6'}>No Summary For {uForS.UnitTitle} Today</Typography>}

                    {!!doDetail && !!doDetail.UnitWiseSummary && doDetail.UnitWiseSummary.length > 0 && doDetail.UnitWiseSummary.filter(u => u.UnitUniqueId == uForS.UnitUniqueId).length > 0 && <Scrollbar sx={{}}>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    order={'asc'}
                                    orderBy={''}
                                    headLabel={TABLE_HEAD_DO_Detail}
                                    rowCount={0}
                                    numSelected={0}
                                    onRequestSort={undefined}
                                    onSelectAllClick={undefined}
                                    customKey={`TableHeadRow_2_Unique`}
                                />
                                <TableBody>
                                    {!!doDetail && !!doDetail.UnitWiseSummary && doDetail.UnitWiseSummary.length > 0 && doDetail.UnitWiseSummary.filter(u => u.UnitUniqueId == uForS.UnitUniqueId).length > 0 && doDetail.UnitWiseSummary.filter(u => u.UnitUniqueId == uForS.UnitUniqueId).map((usRow, usIndex) => {

                                        return (
                                            <TableRow
                                                hover
                                                key={`SUMMARY_ROW_KEY_${usRow.UnitUniqueId}_${usIndex}`}
                                                tabIndex={-1}
                                                role="checkbox"
                                                selected={false}
                                                aria-checked={false}
                                            >
                                                <TableCell width={10} align="center">
                                                    {usIndex + 1}
                                                </TableCell>
                                                <TableCell align="center">{usRow.ProductName}</TableCell>
                                                <TableCell align="center">{usRow.TotalOrderQty}</TableCell>
                                                <TableCell align="center" component="th" scope="row" padding="none">
                                                    {usRow.TotalSchemeQty}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {usRow.TotalQtyToIssue}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Scrollbar>}
                </Card><br /></React.Fragment>})}




                {props.selectedUnits.UnitAccesses.filter(u => u.UnitUniqueId != AllOptionUniqueId).length > 1 && <Card>
                    <Typography style={{ backgroundColor: 'rgba(34, 154, 22, 0.72)', color: 'white', paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h4'}>Overall Summary</Typography>
                    {isLoading == true && <AudoSpin
                        height="80"
                        width="80"
                        color='green'
                        ariaLabel='three-dots-loading'
                        wrapperStyle={{ alignSelf: 'center', justifyContent: 'center' }}
                    />}

                    {(!doDetail || !doDetail.CreditWithProductWiseSummary || !doDetail.CreditWithProductWiseSummary.ProductWiseSummaries || doDetail.CreditWithProductWiseSummary.ProductWiseSummaries.length <= 0) && isLoading == false && <Typography style={{ paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h6'}>No Summary For Today</Typography>}

                    {!!doDetail && !!doDetail.CreditWithProductWiseSummary && !!doDetail.CreditWithProductWiseSummary.ProductWiseSummaries && doDetail.CreditWithProductWiseSummary.ProductWiseSummaries.length > 0  && <Scrollbar sx={{}}>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    order={'asc'}
                                    orderBy={''}
                                    headLabel={TABLE_HEAD_DO_Detail}
                                    rowCount={0}
                                    numSelected={0}
                                    onRequestSort={undefined}
                                    onSelectAllClick={undefined}
                                    customKey={`TableHeadRow_2_Unique`}
                                />
                                <TableBody>
                                    {!!doDetail && !!doDetail.CreditWithProductWiseSummary && !!doDetail.CreditWithProductWiseSummary.ProductWiseSummaries && doDetail.CreditWithProductWiseSummary.ProductWiseSummaries.length > 0 && doDetail.CreditWithProductWiseSummary.ProductWiseSummaries.map((sRow, sIndex) => {

                                        return (
                                            <TableRow
                                                hover
                                                key={`SUMMARY_ROW_KEY_${sRow.ProductId}`}
                                                tabIndex={-1}
                                                role="checkbox"
                                                selected={false}
                                                aria-checked={false}
                                            >
                                                <TableCell width={10} align="center">
                                                    {sIndex + 1}
                                                </TableCell>
                                                <TableCell align="center">{sRow.ProductName}</TableCell>
                                                <TableCell align="center">{sRow.TotalOrderQty}</TableCell>
                                                <TableCell align="center" component="th" scope="row" padding="none">
                                                    {sRow.TotalSchemeQty}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {sRow.TotalQtyToIssue}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Scrollbar>}
                </Card>}
            </Container>}
        </Page></> : <NoUnitSelectedComponent pageTitle='DO | Raza Pak Care' />
    );
}


const mapStateToProps = (state:IApplicationState) =>{
    return {
        selectedUnits:state.selectedUnitAccesses,
        loginUser:state.loginUser
      };
}
const connectedIssueStock = connect(mapStateToProps)(IssueStock);
export {connectedIssueStock as IssueStock};