import {http} from './http-base';
import {IAutoComplete} from '../interfaces/auto-complete.interface';
import { concatParam } from 'src/utility';
import { ISalesManager } from 'src/interfaces/sales-manager.interface';
import { ISubOrdinate } from 'src/interfaces/sub-ordinates.interface';

const getOrderBookers = async (
  loginUserId:number,
  commaSeparatedUnitUniqueIds:String
): Promise<IAutoComplete[]> => {
  let url: string = `api/Employee/GetOrderBookers?loginUserId=${loginUserId}&commaSeparatedUnitUniqueIds=${commaSeparatedUnitUniqueIds}`;
  let result = await http
    .get<IAutoComplete[]>(url)
    .catch(error => Promise.reject(error));
  return result.data;
};

const getUnitOrderBookers = async (
  unitUniqueId:string
  ): Promise<IAutoComplete[]> => {
    let url: string = `api/Employee/GetUnitOrderBookers?unitUniqueId=${unitUniqueId}`;
    let result = await http
      .get<IAutoComplete[]>(url)
      .catch(error => Promise.reject(error));
    return result.data;
  };

const getDeliveryBoysForDO = async (
    unitUniqueId:string
  ): Promise<IAutoComplete[]> => {
    let url: string = `api/Employee/GetDeliveryBoysForDO?unitUniqueId=${unitUniqueId}`;
    let result = await http
      .get<IAutoComplete[]>(url)
      .catch(error => Promise.reject(error));
    return result.data;
  };

const getEmployeeRoutes = async (
    employeeId:number | null | undefined
    ): Promise<IAutoComplete[]> => {
        let params = '';
        params = concatParam(params,employeeId,'employeeId');
      let url: string = `api/Employee/GetEmployeeRoutes${params}`;
      let result = await http
        .get<IAutoComplete[]>(url)
        .catch(error => Promise.reject(error));
      return result.data;
    };

    const getOrderBookerAutoComplete = async (
      areaId:number
      ): Promise<IAutoComplete[]> => {
        let url: string = `api/Employee/OrderBookerAutoComplete?areaId=${areaId}`;
        let result = await http
          .get<IAutoComplete[]>(url)
          .catch(error => Promise.reject(error));
        return result.data;
      };

      const getEmployeesAutoComplete = async (
        unitUniqueId:string
        ): Promise<IAutoComplete[]> => {
          let url: string = `api/Employee/EmployeesAutoComplete?unitUniqueId=${unitUniqueId}`;
          let result = await http
            .get<IAutoComplete[]>(url)
            .catch(error => Promise.reject(error));
          return result.data;
        };

        const getSalesManagers = async (
          loginUserId:number
          ): Promise<ISalesManager[]> => {
            let url: string = `api/Employee/SalesManagerAutoComplete?loginUserId=${loginUserId}`;
            let result = await http
              .get<ISalesManager[]>(url)
              .catch(error => Promise.reject(error));
            return result.data;
          };

          const getSubOrdinates = async (
            employeeId:number
            ): Promise<ISubOrdinate[]> => {
              let url: string = `api/Employee/SubOrdinatesToSetCreditLimit?employeeId=${employeeId}`;
              let result = await http
                .get<ISubOrdinate[]>(url)
                .catch(error => Promise.reject(error));
              return result.data;
            };

            const saveCreditLimit = async (
              assignByEmployeeId:number,
              employeeId:number,
              newLimit:number
              ): Promise<string> => {
                let url: string = `api/Employee/SaveCreditLimit?assignByEmployeeId=${assignByEmployeeId}&employeeId=${employeeId}&newLimit=${newLimit}`;
                let result = await http
                  .get<string>(url)
                  .catch(error => Promise.reject(error));
                return result.data;
              };

const employeeApi = {
  getOrderBookers,
  getEmployeeRoutes,
  getDeliveryBoysForDO,
  getOrderBookerAutoComplete,
  getUnitOrderBookers,
  getEmployeesAutoComplete,
  getSalesManagers,
  getSubOrdinates,
  saveCreditLimit
};

export {employeeApi};
