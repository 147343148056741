import {http} from './http-base';
import {concatParam, formattedDate} from '../utility'
import { IDOCredit } from 'src/interfaces/do-credit.interface';
import { ICreditReceiveDetail } from 'src/interfaces/credit-receive-detail.interface';
import { IResponseModal } from 'src/interfaces/response-modal.interface';
import { IEmployeeCredit } from 'src/interfaces/reports/employee-credit.interface';
import { ICreditDetail } from 'src/interfaces/credit-detail.interface';
import { IDateWiseCredit } from 'src/interfaces/date-wise-credit.interface';
import { INewAndRecoveredCreditResult } from 'src/interfaces/new-and-recovered-credit-result.interface';
import { IMonthWiseCredit } from 'src/interfaces/month-wise-credit.interface';
import { IWeekWiseCreditDetail } from 'src/interfaces/week-wise-credit-detail.interface';
import { IEmployeeRecovery } from 'src/interfaces/employee-recovery.interface';
import { INewMonthWiseCredit } from 'src/interfaces/new-month-wise-credit.interface';
import { IRouteShopDetail } from 'src/interfaces/route-shop-detail.interface';


  const getDoCredits = async (
    loginUserId:number,
    commaSeparatedUnitUniqueIds:string,
    responsiblePersonEmployeeId:number | null,
    fromDate:Date | null,
    toDate:Date | null
  ): Promise<IDOCredit[]> => {
    let params = '';
    params = concatParam(params,loginUserId,'loginUserId');
    params = concatParam(params,commaSeparatedUnitUniqueIds,'commaSeparatedUnitUniqueIds');

    if(!!responsiblePersonEmployeeId){
      params = concatParam(params,responsiblePersonEmployeeId,'responsiblePersonEmployeeId');
    }

    if(!!fromDate){
      const formattedFromDate = formattedDate(fromDate);
      params = concatParam(params,formattedFromDate,'fromDate');
    }

    if(!!toDate){
      const formattedToDate = formattedDate(toDate);
      params = concatParam(params,formattedToDate,'toDate');
    }

    let url: string = `api/DOCredit/PendingDOCredit${params}`;

    let result = await http
      .get<IDOCredit[]>(url)
      .catch(error => Promise.reject(error));
    return result.data;
  };

  const getEmployeeCredit = async (
    commaSeparatedUnitUniqueIds:string,
    loginUserId:number
  ): Promise<IEmployeeCredit[]> => {
    let params = '';
    params = concatParam(params,commaSeparatedUnitUniqueIds,'commaSeparatedUnitUniqueIds');
    params = concatParam(params,loginUserId,'loginUserId');
    
    let url: string = `api/DOCredit/EmployeeCredit${params}`;

    let result = await http
      .get<IEmployeeCredit[]>(url)
      .catch(error => Promise.reject(error));
    return result.data;
  };

  const getCreditDetail = async (
    commaSeparatedUnitUniqueIds:string,
    loginUserId:number
  ): Promise<ICreditDetail> => {
    let params = '';
    params = concatParam(params,commaSeparatedUnitUniqueIds,'commaSeparatedUnitUniqueIds');
    params = concatParam(params,loginUserId,'loginUserId');
    
    let url: string = `api/DOCredit/CreditDetail${params}`;

    let result = await http
      .get<ICreditDetail>(url)
      .catch(error => Promise.reject(error));
    return result.data;
  };

  const getNewAndRecoveredCredit = async (
    loginUserId:number,
    commaSeparatedUnitUniqueIds:string,
    fromDate:Date,
    toDate:Date,
    orderBookerId:number | null
  ): Promise<INewAndRecoveredCreditResult> => {
    let params = '';
    params = concatParam(params,loginUserId,'loginUserId');
    params = concatParam(params,commaSeparatedUnitUniqueIds,'commaSeparatedUnitUniqueIds');
    const fromattedFromDate = formattedDate(fromDate);
    params = concatParam(params,fromattedFromDate,'fromDate');
    const formattedToDate = formattedDate(toDate);
    params = concatParam(params,formattedToDate,'toDate');

    if(!!orderBookerId && orderBookerId > 0){
      params = concatParam(params,orderBookerId,'orderBookerId');
    }
    
    let url: string = `api/DOCredit/DateWiseCredit${params}`;

    let result = await http
      .get<INewAndRecoveredCreditResult>(url)
      .catch(error => Promise.reject(error));
    return result.data;
  };

  const saveCashReceiveDetails = async (
    loggedInUserId:number,
    creditReceiveDetail:ICreditReceiveDetail
    ): Promise<IResponseModal> => {
      let url: string = `api/CreditReceiveDetail?loggedInUserId=${loggedInUserId}`;
  
      let result = await http
        .post<IResponseModal>(url,creditReceiveDetail)
        .catch(error => Promise.reject(error));
      return result.data;
    };

    const getCreditReceiveHistory = async (
      commaSeparatedUnitUniqueIds:string,
      loginUserId:number,
      slipNumber?:number,
      shopName?:string,
      shopAddress?:string,
      ownerName?:string,
      ownerCellNumber?:string,
      responsiblePersonName?:string,
      fromDate?:Date,
      toDate?:Date,
      searchText?:string
    ): Promise<IDOCredit[]> => {
      let params = '';
      params = concatParam(params,commaSeparatedUnitUniqueIds,'commaSeparatedUnitUniqueIds');
      params = concatParam(params,loginUserId,'loginUserId');

      if(!!slipNumber){
        params = concatParam(params,slipNumber,'slipNumber');
      }

      if(!!shopName){
        params = concatParam(params,shopName,'shopName');
      }

      if(!!shopAddress){
        params = concatParam(params,shopAddress,'shopAddress');
      }

      if(!!ownerName){
        params = concatParam(params,ownerName,'ownerName');
      }

      if(!!ownerCellNumber){
        params = concatParam(params,ownerCellNumber,'ownerCellNumber');
      }

      if(!!responsiblePersonName){
        params = concatParam(params,responsiblePersonName,'responsiblePersonName');
      }

      if(!!fromDate){
        const fromattedFromDate = formattedDate(fromDate);
        params = concatParam(params,fromattedFromDate,'fromDate');
      }

      if(!!toDate){
        const formattedToDate = formattedDate(toDate);
        params = concatParam(params,formattedToDate,'toDate');
      }

      if(!!searchText){
        params = concatParam(params,searchText,'searchText');
      }
      
      let url: string = `api/DOCredit/CreditReceiveHistory${params}`;
  
      let result = await http
        .get<IDOCredit[]>(url)
        .catch(error => Promise.reject(error));
      return result.data;
    };

    const getCreditHistory = async (
      commaSeparatedUnitUniqueIds:string,
      loginUserId:number,
      slipNumber?:number,
      shopName?:string,
      shopAddress?:string,
      ownerName?:string,
      ownerCellNumber?:string,
      responsiblePersonName?:string,
      fromDate?:Date,
      toDate?:Date
    ): Promise<IDOCredit[]> => {
      let params = '';
      params = concatParam(params,commaSeparatedUnitUniqueIds,'commaSeparatedUnitUniqueIds');
      params = concatParam(params,loginUserId,'loginUserId');

      if(!!slipNumber){
        params = concatParam(params,slipNumber,'slipNumber');
      }

      if(!!shopName){
        params = concatParam(params,shopName,'shopName');
      }

      if(!!shopAddress){
        params = concatParam(params,shopAddress,'shopAddress');
      }

      if(!!ownerName){
        params = concatParam(params,ownerName,'ownerName');
      }

      if(!!ownerCellNumber){
        params = concatParam(params,ownerCellNumber,'ownerCellNumber');
      }

      if(!!responsiblePersonName){
        params = concatParam(params,responsiblePersonName,'responsiblePersonName');
      }

      if(!!fromDate){
        const fromattedFromDate = formattedDate(fromDate);
        params = concatParam(params,fromattedFromDate,'fromDate');
      }

      if(!!toDate){
        const formattedToDate = formattedDate(toDate);
        params = concatParam(params,formattedToDate,'toDate');
      }
      
      let url: string = `api/DOCredit/DOCreditHistory${params}`;
  
      let result = await http
        .get<IDOCredit[]>(url)
        .catch(error => Promise.reject(error));
      return result.data;
    };

    const getMonthWiseCredits = async (
      commaSeparatedUnitUniqueIds:string,
      loginUserId:number,
      responsiblePersonEmployeeId:number | null,
      monthNumber:number | null
    ): Promise<IMonthWiseCredit[]> => {
      let params = '';
      params = concatParam(params,commaSeparatedUnitUniqueIds,'commaSeparatedUnitUniqueIds');
      params = concatParam(params,loginUserId,'loginUserId');

      if(!!responsiblePersonEmployeeId){
        params = concatParam(params,responsiblePersonEmployeeId,'responsiblePersonEmployeeId');
      }

      if(!!monthNumber){
        params = concatParam(params,monthNumber,'monthNumber');
      }
      
      let url: string = `api/DOCredit/MonthWiseCredit${params}`;
  
      let result = await http
        .get<IMonthWiseCredit[]>(url)
        .catch(error => Promise.reject(error));
      return result.data;
    };

    const getWeekWiseCredits = async (
      unitUniqueId:string,
      loginUserId:number,
      responsiblePersonEmployeeId:number,
      monthStartDate:Date
    ): Promise<IWeekWiseCreditDetail[]> => {
      let params = '';
      params = concatParam(params,unitUniqueId,'unitUniqueId');
      params = concatParam(params,loginUserId,'loginUserId');
      params = concatParam(params,responsiblePersonEmployeeId,'responsiblePersonEmployeeId');
      params = concatParam(params,formattedDate(monthStartDate),'monthStartDate');
      
      let url: string = `api/DOCredit/WeekWiseCreditDetail${params}`;
  
      let result = await http
        .get<IWeekWiseCreditDetail[]>(url)
        .catch(error => Promise.reject(error));
      return result.data;
    };

    const getRecoveryTargets = async (
      loginUserId:number
    ): Promise<IEmployeeRecovery[]> => {
      
      let url: string = `api/DOCredit/RecoveryTargets?loginUserId=${loginUserId}`;
  
      let result = await http
        .get<IEmployeeRecovery[]>(url)
        .catch(error => Promise.reject(error));
      return result.data;
    };

    const getMonthWiseCreditNew = async (
      loginUserId:number,
      employeeId:number | null,
      unitUniqueId:string | null,
      isPreviousMonth:boolean | null,
    ): Promise<INewMonthWiseCredit[]> => {
      let prams = '';
      prams = concatParam(prams, loginUserId,'loginUserId');

      if(!!employeeId && employeeId > 0){
        prams = concatParam(prams, employeeId,'employeeId');
      }

      if(!!unitUniqueId && unitUniqueId.trim() !== ''){
        prams = concatParam(prams, unitUniqueId,'unitUniqueId');
      }

      if(isPreviousMonth === false){
        prams = concatParam(prams, isPreviousMonth,'isPreviousMonth');
      }

      let url: string = `api/DOCredit/MonthWiseDoCreditForRecoveryTargets${prams}`;
  
      let result = await http
        .get<INewMonthWiseCredit[]>(url)
        .catch(error => Promise.reject(error));
      return result.data;
    };

    const getMonthPendingCreditShops = async (
      loginUserId:number,
      month:number,
      year:number,
      employeeId:number | null,
      unitUniqueId:string | null
    ): Promise<IRouteShopDetail[]> => {
      let prams = '';
      prams = concatParam(prams, loginUserId,'loginUserId');
      prams = concatParam(prams, month,'month');
      prams = concatParam(prams, year,'year');

      if(!!employeeId && employeeId > 0){
        prams = concatParam(prams, employeeId,'employeeId');
      }

      if(!!unitUniqueId && unitUniqueId.trim() !== ''){
        prams = concatParam(prams, unitUniqueId,'unitUniqueId');
      }

      let url: string = `api/DOCredit/PendingCreditShops${prams}`;
  
      let result = await http
        .get<IRouteShopDetail[]>(url)
        .catch(error => Promise.reject(error));
      return result.data;
    };
  
  const doCreditApi = {
    getDoCredits,
    saveCashReceiveDetails,
    getEmployeeCredit,
    getCreditDetail,
    getNewAndRecoveredCredit,
    getCreditReceiveHistory,
    getMonthWiseCredits,
    getWeekWiseCredits,
    getCreditHistory,
    getRecoveryTargets,
    getMonthWiseCreditNew,
    getMonthPendingCreditShops
  };
  
  export {doCreditApi};
