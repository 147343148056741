import { IAutoComplete } from "./interfaces/auto-complete.interface";
import { IStockUpdateReason } from "./interfaces/stock-update-reason.interface";

//export const ApiBaseUrl = 'http://localhost:44337/';
export const ApiBaseUrl = 'https://api.razapakcare.com/';
//export const ApiBaseUrl = 'http://192.168.100.3/RPCBridge/';
//export const ApiBaseUrl = 'https://razapakcareapi.azurewebsites.net/';
//export const ApiBaseUrl = 'http://localhost/RPCBridge/';
//export const ApiBaseUrl = 'http://localhost/RPCBridge/';
//export const ApiBaseUrl = 'http://182.180.143.102:8080/';
//export const ApiBaseUrl = 'https://test-api.apply4u.co.uk/';

export const dropdownPlaceholderValue: number = -1;
export const logsEnabled: boolean = false;
export const AllOptionUniqueId = "FEAB633F-06F7-438F-8B34-E874153A670D";
export const jobCardHeight = 160;
export const storagePermission = {
  title: 'Apply4U',
  message: 'Storage Permission required to choose file',
};

export const pdfFileExtension = '.pdf';
export const docFileExtension = '.doc';
export const docxFileExtension = '.docx';
export const rtfFileExtension = '.rtf';
export const txtFileExtension = '.txt';
export const MapKey = "AIzaSyCNq51BSIH5Lws4S8-zpnkmx-YBP8v0d7E";
export const DefaultMapZoom = 8;
export const ExtraQtyReasons = {
  StockIssuanceMistake:1,
  ForgotToGiveScheme:2,
  InvalidDelivery:3,
};

export const DoDetailFor = {
  DoDetail:1,
  StockIssuance:2,
  StockReturn:3,
  CashReceive:4,
  DOCreation:5,
  ForCashSubmission:6
};

export const StockUpdateReason:IStockUpdateReason[] = [
  {Id:1,TypeDescription:'New Production',TypeKey:'NewProduction', IsNegativeAllowed:false},
  {Id:5,TypeDescription:'Transfer To Other Unit',TypeKey:'TransferToOtherUnit', IsNegativeAllowed:false},
  {Id:8,TypeDescription:'Stock Adjustment',TypeKey:'StockAdjustment', IsNegativeAllowed:true},
];
export const EmptyGuid = '00000000-0000-0000-0000-000000000000';
export const AllowedNumberOfDaysForRed = 7;
export const AliUserId = 1010;
export const DateFormat = "DD-MMM-YYYY";

export const NewOrderReportPrintSetting = {
  Target:"Target",
  AchievedTarget:"AchievedTarget",
  RemainingTarget:"RemainingTarget",
  CreditLimit:"CreditLimit",
  PendingCredit:"PendingCredit",
  AchievedPercentage:"AchievedPercentage",
  RemainingPercentage:"RemainingPercentage",
  DOAmount:"DOAmount",
  ReturnAmount:"ReturnAmount",
  DODelieveredAmount:"DODelieveredAmount",
  DOCash:"DOCash",
  DOCredit:"DOCredit",
  Recovery:"Recovery",
  Difference:"Difference",
  TotalCash:"TotalCash"
};

export const NewOrderReportPrintSettings = [
  NewOrderReportPrintSetting.Target,
  NewOrderReportPrintSetting.AchievedTarget,
  NewOrderReportPrintSetting.RemainingTarget,
  NewOrderReportPrintSetting.PendingCredit,
  NewOrderReportPrintSetting.AchievedPercentage,
  NewOrderReportPrintSetting.RemainingPercentage,
  NewOrderReportPrintSetting.DOAmount,
  NewOrderReportPrintSetting.ReturnAmount,
  NewOrderReportPrintSetting.DODelieveredAmount,
  NewOrderReportPrintSetting.DOCash,
  NewOrderReportPrintSetting.DOCredit,
  NewOrderReportPrintSetting.Recovery,
  NewOrderReportPrintSetting.Difference,
  NewOrderReportPrintSetting.TotalCash
];

export const NumberOfPreviousMonth = 1;

export const isRestrictedLogin = (userId:number) => userId !== 1 && userId !== 1010;
export const UnAssignedOrderBookerId = -1;
export const RouteDetailParam = {
  OrderBookerId:"OrderBookerId",
  AreaId:"AreaId",
  RouteId:"RouteId"
};

export const ManageShopsParam = {
  SelectedTabKey:'tab'
}

export const NewRouteSetupPageParam = {
  AreaId:"aid",
  AreaName:"ann",
  RouteId:"rid",
  OrderBookerId:"obid"
}

export const RouteDayDataSource:IAutoComplete[] = [
  {DisplayText:'Saturday', Value:7},
  {DisplayText:'Sunday', Value:1},
  {DisplayText:'Monday', Value:2},
  {DisplayText:'Tuesday', Value:3},
  {DisplayText:'Wednesday', Value:4},
  {DisplayText:'Thursday', Value:5},
];

