import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IApplicationState } from 'src/redux';
import { setNewOrderReportPrintSettingAction } from 'src/redux/actions/new-order-report-print-setting.action';
import { connect } from 'react-redux';
import { INewOrderReportSetting } from 'src/interfaces/new-order-report-setting.interface';
import { IAction } from 'src/redux/actions/action.interface';
import { Checkbox, Container, FormControlLabel, Grid, TextField } from '@mui/material';
import { NewOrderReportPrintSetting, NewOrderReportPrintSettings, UnAssignedOrderBookerId } from 'src/app.constant';
import LoadingButton from '@mui/lab/LoadingButton';
import { IAutoComplete } from 'src/interfaces/auto-complete.interface';
import { CustomAutoComplete } from './common/CustomAutoComplete';
import { Icon } from '@iconify/react';
import { IShopRouteChangeParam } from 'src/interfaces/shop-route-change-param.interface';
import { employeeApi, routeApi } from 'src/http';
import { shopApi } from 'src/http/shop.http';

interface IProps {
    open: boolean;
    changeRouteParam:IShopRouteChangeParam;
    onClose: () => void;
    onSuccess:(shopId:number, toOrderBookerId:number, toOrderBookerName:string, toRouteId:number, toRouteName:string, toRouteDayNumber:number, toRouteDay:string) => void;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    paddingTop: 2,
    paddingBottom: 2
};

export const ShopRouteChangePopup = (props: IProps) => {
    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick") {
            return;
        }

        props.onClose();
    }

    const [selectedOrderBookerId, setSelectedOrderBookerId] = React.useState<number>(0);
    const [selectedOrderBookerName, setSelectedOrderBookerName] = React.useState<string>('');
    const [orderBookersAutoComplete, setOrderBookersAutoComplete] = React.useState<IAutoComplete[]>([]);
    const [routeAutoComplete, setRouteAutoComplete] = React.useState<IAutoComplete[]>([]);
    const [routeId, setRouteId] = React.useState<number>(0);
    const [route, setRoute] = React.useState<string>('');
    const [selectedRouteDay, setSelectedRouteDay] = React.useState<string>('');
    const [selectedRouteDayNumber, setSelectedRouteDayNumber] = React.useState<number>(-1);
    const [isOrderBookerAutoCompoleteLoading, setIsOrderBookerAutoCompleteLoading] = React.useState<boolean>(false);
    const [isRouteDayLoading, setIsRouteDayLoading] = React.useState<boolean>(false);
    const [isRouteAutoCompleteLoading, setIsRouteAutoCompleteLoading] = React.useState<boolean>(false);


    const loadOrderBookers = (areaId: number) => {
        if (areaId > 0) {
            setIsOrderBookerAutoCompleteLoading(true);
            employeeApi.getOrderBookerAutoComplete(areaId)
                .then(res => {
                    if (!!res && res.length > 0) {
                        let items: IAutoComplete[] = [{ Value: UnAssignedOrderBookerId, DisplayText: 'Unassigned' }, ...res];
                        setOrderBookersAutoComplete([...items]);
                    } else {
                        setOrderBookersAutoComplete([]);
                    }

                    setIsOrderBookerAutoCompleteLoading(false);
                })
                .catch(e => {
                    setIsOrderBookerAutoCompleteLoading(false);
                    setOrderBookersAutoComplete([]);
                });
        } else {
            setOrderBookersAutoComplete([]);
        }
    }

    const loadRoutes = (areaId: number, orderBookerId: number) => {
        if (areaId > 0) {
            setIsRouteAutoCompleteLoading(true);
            routeApi.getRouteAutoComplete(props.changeRouteParam.LoginUserId, areaId, orderBookerId == UnAssignedOrderBookerId ? null : orderBookerId)
                .then(res => {
                    if (!!res && res.length > 0) {
                        let items: IAutoComplete[] = [...res.filter(m => m.Value != props.changeRouteParam.FromRouteId)];
                        setRouteAutoComplete([...items]);
                        
                    } else {
                        setRouteAutoComplete([]);
                    }
                    setIsRouteAutoCompleteLoading(false);
                })
                .catch(e => {
                    setIsRouteAutoCompleteLoading(false);
                    setRouteAutoComplete([]);
                });
        } else {
            setRouteAutoComplete([]);
        }
    }

    const loadRouteDay = (routeId:number) => {
        if (routeId > 0) {
            setIsRouteDayLoading(true);
            routeApi.getRouteDay(routeId)
                .then(res => {
                    if (!!res && res.length > 0) {
                        setSelectedRouteDay(res[0].DisplayText);
                        setSelectedRouteDayNumber(res[0].Value);
                        
                    } else {
                        setSelectedRouteDay('Unassigned');
                        setSelectedRouteDayNumber(-1);
                    }

                    setIsRouteDayLoading(false);
                })
                .catch(e => {
                    setIsRouteDayLoading(false);
                    setSelectedRouteDay('Unassigned');
                    setSelectedRouteDayNumber(-1);
                });
        } else {
            setSelectedRouteDay('Unassigned');
            setSelectedRouteDayNumber(-1);
        }
    }

    const onOrderBookerChange = (option:IAutoComplete) =>{
        setSelectedOrderBookerId(option?.Value ?? 0);
        setSelectedOrderBookerName(option?.DisplayText ?? '');
        setSelectedRouteDay('');
        setSelectedRouteDayNumber(0);
        processOnOrderBookerChange(props.changeRouteParam.AreaId, option?.Value ?? 0);
    }

    const processOnOrderBookerChange = (areaId:number, orderBookerId:number) =>{
        setRouteId(0);
        setRouteAutoComplete([]);

        if(orderBookerId > 0 || orderBookerId == UnAssignedOrderBookerId){
            loadRoutes(areaId, orderBookerId);
        }
    }

    const onRouteChange = (option:IAutoComplete) => {
        setRouteId(option?.Value ?? 0);
        setRoute(option?.DisplayText ?? '');
        processOnRouteChange(option?.Value ?? 0);
    }

    const processOnRouteChange = (routeId:number) =>{
        setSelectedRouteDay('');
        setSelectedRouteDayNumber(0);

        if(routeId > 0){
            loadRouteDay(routeId);
        }
    }


    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const onSave = () =>{
        setIsLoading(true);
        shopApi.changeShopRoute(props.changeRouteParam.ShopId, routeId)
        .then(() => {
            setIsLoading(false);
            props.onSuccess(props.changeRouteParam.ShopId, selectedOrderBookerId, selectedOrderBookerName, routeId, route, selectedRouteDayNumber, selectedRouteDay);
        })
        .catch(() => {
            setIsLoading(false);
        });
    }

    React.useEffect(() =>{
        loadOrderBookers(props.changeRouteParam.AreaId);
    },[props.changeRouteParam.AreaId]);

    return <Modal
        open={props.open && !!props.changeRouteParam}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={style}>
            <Grid item md={12} style={{ justifyContent: 'center', display: 'flex' }}>
                <Typography style={{ alignSelf: 'center' }} id="modal-modal-title" variant="h6" component="h2">
                    Shop Route Change
                </Typography>
            </Grid>

            <Container>
                <Grid container spacing={2} style={{ marginTop: 10 }}>
                    <Grid item md={4} xs={12}>
                        <TextField
                            autoComplete="search_text"
                            size='small'
                            variant='outlined'
                            type={'text'}
                            label="From Order Booker"
                            value={props.changeRouteParam.FromRouteId > 0 ? (props.changeRouteParam.FromOrderBookerName ?? '') : 'N/A'}
                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {

                            }}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <TextField
                            autoComplete="search_text"
                            size='small'
                            variant='outlined'
                            type={'text'}
                            label="From Route"
                            value={!!props.changeRouteParam.FromRouteName && props.changeRouteParam.FromRouteName.trim() != '' ? props.changeRouteParam.FromRouteName : 'N/A'}
                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {

                            }}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <TextField
                            autoComplete="search_text"
                            size='small'
                            variant='outlined'
                            type={'text'}
                            label="From Day"
                            value={props.changeRouteParam.FromRouteId > 0 ?  (props.changeRouteParam.FromRouteDay ?? 'N/A') : 'N/A'}
                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {

                            }}
                            disabled={true}
                        />
                    </Grid>
                </Grid>


                <Grid item md={12} style={{ justifyContent: 'center', marginTop: 10, marginBottom: 10, display: 'flex' }}>
                    <Icon style={{ alignSelf: 'center' }} width={30} height={30} icon={'ic:baseline-arrow-circle-down'} />
                </Grid>


                <Grid container spacing={2}>
                    <Grid item md={4} xs={12}>
                        {<CustomAutoComplete<IAutoComplete>
                        Id={'Order_booker_AutoComplete'}
                        Value={!!orderBookersAutoComplete && orderBookersAutoComplete.length > 0 ? orderBookersAutoComplete.filter(m => m.Value == selectedOrderBookerId)[0] : null}
                        Options={orderBookersAutoComplete ?? []}
                        displayField={'DisplayText'}
                        valueField={'Value'}
                        Label={'To Order Booker'}
                        onChange={onOrderBookerChange}
                        size='small'
                        isLoading={isOrderBookerAutoCompoleteLoading}
                        readOnly={isOrderBookerAutoCompoleteLoading || isRouteAutoCompleteLoading || isRouteDayLoading}
                    />}
                    </Grid>
                    <Grid item md={4} xs={12}>
                        {<CustomAutoComplete<IAutoComplete>
                        Id={'Route_List'}
                        Value={!!routeAutoComplete && routeAutoComplete.length > 0 ? routeAutoComplete.filter(m => m.Value == routeId)[0] : null}
                        Options={routeAutoComplete}
                        displayField={'DisplayText'}
                        valueField={'Value'}
                        Label={'To Route'}
                        onChange={onRouteChange}
                        size='small'
                        isLoading={isRouteAutoCompleteLoading}
                        readOnly={isRouteDayLoading || isOrderBookerAutoCompoleteLoading || isRouteAutoCompleteLoading}
                    />}
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <TextField
                            autoComplete="search_text"
                            size='small'
                            variant='outlined'
                            type={'text'}
                            label="To Day"
                            value={selectedRouteDay}
                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {

                            }}
                            disabled={true}
                        />
                    </Grid>
                </Grid>
                <Grid item md={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <LoadingButton
                        size="small"
                        type="button"
                        variant="outlined"
                        loading={isLoading}
                        disabled={selectedOrderBookerId == 0 || routeId <= 0 || selectedRouteDay == '' || selectedOrderBookerName == '' || route == '' || selectedRouteDayNumber == 0}
                        style={{ marginTop: 15, alignSelf: 'center', marginRight: 10, borderRadius: 0 }}
                        onClick={() => {
                            onSave();
                        }}
                    >
                        Save
                    </LoadingButton>

                    <LoadingButton
                        size="small"
                        type="button"
                        variant="outlined"
                        loading={false}
                        disabled={false}
                        style={{ marginTop: 15, alignSelf: 'center', color: 'gray', borderRadius: 0, borderColor: 'gray' }}
                        onClick={props.onClose}
                    >
                        Cancel
                    </LoadingButton>
                </Grid>
            </Container>
        </Box>
    </Modal>;
}