import React from 'react';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import refreshFill from '@iconify/icons-eva/refresh-fill';
// material
import {
    Card,
    Table,
    TableRow,
    TableBody,
    TableCell,
    Container,
    TableContainer,
    Grid,
} from '@mui/material';
// components
import Page from '../Page';
import Scrollbar from '../Scrollbar';
import { UserListHead } from '../_dashboard/user';
//
import { LoadingButton } from '@mui/lab';
import { orderApi } from '../../http';
import { Audio as AudoSpin } from 'react-loader-spinner'
import { getDateOnly } from 'src/utility';
import { IUserDetail } from 'src/interfaces';
import { fCurrency } from 'src/utils/formatNumber';
import { IMessage } from 'src/interfaces/message.interface';
import { MessageComponent } from 'src/components/messages.component';
import { useNavigate } from "react-router-dom";
import { OrdersParamComponent } from './orders-param.component';
import { IOrderBookerSaleReportResult } from 'src/interfaces/order-booker-sale-report-result.interface';
// --------------------------------------------------------------------


//#region interfaces
interface IProps {
    loginUser: IUserDetail | null;
    unitUniqueId:string;
    unitTitle:string;
    fromDate:Date;
    toDate:Date;
    onBackClick:() => void;
    showBackButton:boolean;
    showProductWiseDetail:boolean;
    onOrderBookerClick:(orderBookerEmployeeId:number, orderBookerName:string, fromDate:Date, toDate:Date) => void;
}

interface IOrderBookerTotal {
    Visited: number;
    Orders: number;
    NoOrders: number;
    OrderQty: number;
    ReturnQty: number;
    DeliveredQty: number;
    SchemeQty: number;
    ReturnAmount: number;
    DiscountAmount: number;
    SchemeCost: number;
    NetSale: number;
    Sale:number;
}

interface IProductTotal {
    OrderQty: number;
    ReturnQty: number;
    SchemeQty: number;
    DeliveredQty: number;
    ReturnAmount: number;
    DiscountAmount: number;
    SchemeCost: number;
    NetSale: number;
    Sale:number;
}

//#endregion

export const OrderBookerOrdersComponent = (props: IProps) => {
    const navigate = useNavigate();
    //#region state variables

    const normalHeaders = [
        { id: 'sr', label: 'Sr.', alignContent: 'center' },
        { id: 'order_booker', label: 'Order Booker', alignContent: 'center' },
        { id: 'visited', label: 'Visited', alignContent: 'center' },
        { id: 'orders', label: 'Orders', alignContent: 'center' },
        { id: 'no_orders', label: 'No Orders', alignContent: 'center' },
        { id: 'pieces', label: 'Total Qty', alignContent: 'center' },
        { id: 'return_qty', label: 'Return Qty', alignContent: 'center' },
        { id: 'delivered_qty', label: 'Delivered Qty', alignContent: 'center' }
    ];

    const AllFieldsHeader = [
        { id: 'sr', label: 'Sr.', alignContent: 'center' },
        { id: 'order_booker', label: 'Order Booker', alignContent: 'center' },
        { id: 'visited', label: 'Visited', alignContent: 'center' },
        { id: 'orders', label: 'Orders', alignContent: 'center' },
        { id: 'no_orders', label: 'No Orders', alignContent: 'center' },
        { id: 'pieces', label: 'Total Qty', alignContent: 'center' },
        { id: 'return_qty', label: 'Return Qty', alignContent: 'center' },
        { id: 'delivered_qty', label: 'Delivered Qty', alignContent: 'center' },
        { id: 'total_return', label: 'Return', alignContent: 'center' },
        { id: 'total_sale', label: 'Sale', alignContent: 'center' },
        { id: 'total_discount', label: 'Discount', alignContent: 'center' },
        { id: 'scheme', label: 'Scheme Cost', alignContent: 'center' },
        { id: 'net_sale', label: 'Net Sale', alignContent: 'center' },
    ];

    const normalProductHeaders = [
        { id: 'sr', label: 'Sr.', alignContent: 'center' },
        { id: 'product', label: 'Product', alignContent: 'center' },
        { id: 'pieces', label: 'Total Qty', alignContent: 'center' },
        { id: 'return_qty', label: 'Return Qty', alignContent: 'center' },
        { id: 'delivered_qty', label: 'Delivered Qty', alignContent: 'center' }
    ];

    const AllFieldsProductHeader = [
        { id: 'sr', label: 'Sr.', alignContent: 'center' },
        { id: 'product', label: 'Product', alignContent: 'center' },
        { id: 'pieces', label: 'Total Qty', alignContent: 'center' },
        { id: 'return_qty', label: 'Return Qty', alignContent: 'center' },
        { id: 'delivered_qty', label: 'Delivered Qty', alignContent: 'center' },
        { id: 'total_return', label: 'Return', alignContent: 'center' },
        { id: 'total_sale', label: 'Sale', alignContent: 'center' },
        { id: 'total_discount', label: 'Discount', alignContent: 'center' },
        { id: 'scheme', label: 'Scheme Cost', alignContent: 'center' },
        { id: 'net_sale', label: 'Net Sale', alignContent: 'center' },
    ];

    const [showAllFieldsHeaders, setShowAllFieldsHeader] = React.useState<boolean>(false);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [ordersResult, setOrdersResult] = React.useState<IOrderBookerSaleReportResult>({
        ProductSaleReport: [],
        OrderBookerOrderReport: []
    });

    const initialOrderBookerTotal = {
        DeliveredQty: 0,
        DiscountAmount: 0,
        NetSale: 0,
        NoOrders: 0,
        OrderQty: 0,
        Orders: 0,
        ReturnAmount: 0,
        ReturnQty: 0,
        SchemeCost: 0,
        SchemeQty: 0,
        Visited: 0,
        Sale:0
    };

    const [orderBookerTotal, setOrderBookerTotal] = React.useState<IOrderBookerTotal>({ ...initialOrderBookerTotal });

    const initialProductTotal = {
        DeliveredQty: 0,
        DiscountAmount: 0,
        NetSale: 0,
        OrderQty: 0,
        ReturnAmount: 0,
        ReturnQty: 0,
        SchemeCost: 0,
        SchemeQty: 0,
        Sale:0
    };
    const [productTotal, setProductTotal] = React.useState<IProductTotal>({ ...initialProductTotal });

    const [pageMessages, setPageMessages] = React.useState<IMessage[]>([]);
    const [isResultsLoadedAfterLastChange, setIsResultsLoadedAfterLastChange] = React.useState<boolean>(false);
    const [fromDate, setFromDate] = React.useState<Date>(props.fromDate);
    const [toDate, setToDate] = React.useState<Date>(props.toDate);
    const showErrorMessage = (message: string) => addMessage(message, 'error');
    const showInfoMessage = (message: string) => addMessage(message, 'info');
    const showSuccessMessage = (message: string) => addMessage(message, 'success');
    const showWarningMessage = (message: string) => addMessage(message, 'warning');

    const addMessage = (messageText: string, variant: 'success' | 'error' | 'info' | 'warning') => {
        if (!!messageText && messageText.trim() != '') {
            let msgs = [];
            msgs.push({
                messageText,
                variant
            });

            setPageMessages([...msgs]);
        }
    }

    //#endregion

    //#region handlers start

    const resetEverythingAndReloadData = () => {
        resetEverything();
        getOrderBookerOrderSaleResult(true);
    }

    const resetEverything = () => {
        setPageMessages([]);
        setOrdersResult({
            ProductSaleReport: [],
            OrderBookerOrderReport: []
        });

        setOrderBookerTotal({ ...initialOrderBookerTotal });
        setProductTotal({ ...initialProductTotal });
    }

    const goBack = () => {
        navigate(-1);
    }

    //#endregion handlers end

    //#region apis call start

    const validateFilters = (showMessages: boolean) => {

        if (fromDate === undefined || fromDate == null || toDate === undefined || toDate == null) {

            if (showMessages == true) {
                showErrorMessage('Please Select Dates');
            }

            return false;
        }

        if (getDateOnly(fromDate) > getDateOnly(toDate)) {

            if (showMessages == true) {
                showErrorMessage(`From Date Can't be Greater Than To Date`);
            }

            return false;
        }

        return true;
    }

    const getOrderBookerOrderSaleResult = (showMessages: boolean) => {

        if (validateFilters(showMessages) == false) {
            return;
        }

        resetEverything();
        setIsLoading(true);
        setIsResultsLoadedAfterLastChange(true);

        orderApi.getOrderBookerSaleReport(props.loginUser.UserId, props.unitUniqueId, fromDate, toDate)
            .then(results => {
                setIsLoading(false);
                if (!!results) {
                    setShowAllFieldsHeader((!!results.ProductSaleReport && results.ProductSaleReport.length > 0 && results.ProductSaleReport[0].TotalAmount > -1));
                    setOrdersResult({ ...results, ProductSaleReport: [...results.ProductSaleReport.map(a => a)], OrderBookerOrderReport: [...results.OrderBookerOrderReport.map(a => a)] });
                    setTotals({...results});
                }
                else {
                    setOrdersResult({
                        ProductSaleReport: [],
                        OrderBookerOrderReport: []
                    });
                }
            })
            .catch(e => {
                console.log(e);
                setIsLoading(false);
                setOrdersResult({
                    ProductSaleReport: [],
                    OrderBookerOrderReport: []
                });
            });
    }

    const setTotals = (results: IOrderBookerSaleReportResult) => {
        let uTotal: IOrderBookerTotal = {...initialOrderBookerTotal};
        let pTotal: IProductTotal = {...initialProductTotal};

        if (!!results) {
            if (!!results.OrderBookerOrderReport && results.OrderBookerOrderReport.length > 0) {
                results.OrderBookerOrderReport.forEach(ut => {
                    uTotal.Visited += ut.TotalVisited;
                    uTotal.Orders += ut.TotalOrders;
                    uTotal.NoOrders += ut.TotalNoOrders;
                    uTotal.OrderQty += ut.TotalPieceOrder;
                    uTotal.ReturnQty += ut.OrderReturnQty;
                    uTotal.DeliveredQty += ut.DeliveredQty;
                    uTotal.SchemeQty += ut.SchemeQty;
                    uTotal.ReturnAmount += ut.TotalReturnAmount;
                    uTotal.DiscountAmount += ut.TotalDiscount;
                    uTotal.SchemeCost += ut.TotalSchemeCost;
                    uTotal.Sale += ut.TotalAmount;
                    uTotal.NetSale += (ut.TotalAmount - ut.TotalDiscount - ut.TotalSchemeCost);
                });
            }

            if (!!results.ProductSaleReport && results.ProductSaleReport.length > 0) {
                results.ProductSaleReport.forEach(pt => {
                    pTotal.OrderQty += pt.TotalPieceOrder;
                    pTotal.ReturnQty += pt.OrderReturnQty;
                    pTotal.DeliveredQty += pt.DeliveredQty;
                    pTotal.SchemeQty += pt.SchemeQty;
                    pTotal.ReturnAmount += pt.TotalReturnAmount;
                    pTotal.DiscountAmount += pt.TotalDiscount;
                    pTotal.SchemeCost += pt.TotalSchemeCost;
                    pTotal.Sale += pt.TotalAmount;
                    pTotal.NetSale += (pt.TotalAmount - pt.TotalDiscount - pt.TotalSchemeCost);
                });
            }
        }

        setOrderBookerTotal({ ...uTotal });
        setProductTotal({ ...pTotal });
    }

    //#endregion apis calls end

    //#region hooks start

    React.useEffect(() => {
        if (props.unitUniqueId !== undefined && props.unitUniqueId != null && fromDate !== undefined && fromDate != null && toDate !== undefined && toDate != null) {
            getOrderBookerOrderSaleResult(true);
        }
    }, [fromDate, toDate, props.unitUniqueId]);

    //#endregion hooks end

    const borderTopStyle = {borderTop:'solid',borderTopColor:'black'};
    const pageKey = 'OrderBookerOrdersComponentPageKey';
    return (
        <Page key={pageKey} title="Order Booker Orders | Raza Pak Care">
            <MessageComponent key={`${pageKey}_1`} onRemove={(i: number) => {
                let msgs = [...pageMessages];
                msgs.splice(i, 1);
                setPageMessages([...msgs]);
            }} messages={pageMessages} />
            <OrdersParamComponent fromDate={fromDate} toDate={toDate} isLoading={isLoading} onLoad={getOrderBookerOrderSaleResult} setFromDate={setFromDate} setToDate={setToDate} key={`${pageKey}_2`} />
            <Container key={`${pageKey}_3`} style={{ maxWidth: 1500, paddingLeft: 0, paddingRight: 0 }}>
                {<Grid key={`${pageKey}_4`} item md={12}>
                    <Grid key={`${pageKey}_5`} container style={{ flexDirection: 'row' }}>
                        <Grid key={`${pageKey}_6`} container spacing={2}>
                            <Grid key={`${pageKey}_7`} xs={12} sm={12} md={12} style={{ flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }} item>
                            {props.showBackButton == true && <LoadingButton
                        size="small"
                        type="button"
                        variant="contained"
                        loading={isLoading}
                        style={{alignSelf:'center',marginRight:5, backgroundColor: 'blue', textAlign: 'right' }}
                        startIcon={<Icon icon={'eva:arrow-back-outline'} />}
                        onClick={props.onBackClick}
                        key={`${pageKey}_555`}
                    >
                        Back
                    </LoadingButton>}
                                <LoadingButton
                                    size="small"
                                    type="button"
                                    variant="contained"
                                    loading={isLoading}
                                    style={{ alignSelf: 'flex-end', margin: 5, backgroundColor: 'rgba(34, 154, 22, 0.72)' }}
                                    startIcon={<Icon icon={refreshFill} />}
                                    onClick={() => {
                                        resetEverythingAndReloadData();
                                    }}
                                    key={`${pageKey}_8`}
                                >
                                    Refresh
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>}

                <Card key={`${pageKey}_9`} sx={{borderRadius:0}}>
                    {isLoading == true && <AudoSpin
                        key={`${pageKey}_10`}
                        height="80"
                        width="80"
                        color='green'
                        ariaLabel='three-dots-loading'
                        wrapperStyle={{ alignSelf: 'center', justifyContent: 'center' }}
                    />}

                    {!!ordersResult && !!ordersResult.OrderBookerOrderReport && ordersResult.OrderBookerOrderReport.length > 0 && <Scrollbar key={`${pageKey}_11`} sx={{}}>
                        <TableContainer key={`${pageKey}_12`} sx={{ minWidth: 800 }}>
                            <Table key={`${pageKey}_13`} size='small'>
                                
                                <UserListHead
                                    order={'asc'}
                                    orderBy={''}
                                    headLabel={showAllFieldsHeaders ? AllFieldsHeader : normalHeaders}
                                    rowCount={0}
                                    numSelected={0}
                                    onRequestSort={undefined}
                                    onSelectAllClick={undefined}
                                    customKey={`${pageKey}_14`}
                                    key={`${pageKey}_15`}
                                    typographyVariant={'subtitle1'}
                                    tableHeadTitle={`${props.unitTitle}'s Order Bookers Detail`}
                                    tableHeadAlignment='left'
                                    tableHeadTypoVariant='h5'
                                    showHeaderBorder={true}
                                />
                                <TableBody key={`${pageKey}_116`}>
                                    {!!ordersResult && !!ordersResult.OrderBookerOrderReport && ordersResult.OrderBookerOrderReport.length > 0 && ordersResult.OrderBookerOrderReport.map((row, index) => {

                                        return (
                                            <TableRow
                                                hover
                                                key={`DataRow_${row.EmployeeId}`}
                                                tabIndex={-1}
                                                role="checkbox"
                                                selected={false}
                                                aria-checked={false}
                                                style={{cursor:'pointer'}}
                                                onClick={() => {
                                                    props.onOrderBookerClick(row.EmployeeId,row.EmployeeName,fromDate,toDate);
                                                }}
                                            >
                                                <TableCell key={`DataCell_1_${row.EmployeeId}`} align="center">{index + 1}</TableCell>
                                                <TableCell key={`DataCell_2_${row.EmployeeId}`} align="center">{row.EmployeeName}</TableCell>
                                                <TableCell key={`DataCell_3_${row.EmployeeId}`} align="center">{row.TotalVisited}</TableCell>
                                                <TableCell key={`DataCell_4_${row.EmployeeId}`} align="center">{row.TotalOrders}</TableCell>
                                                <TableCell key={`DataCell_8_${row.EmployeeId}`} align="center" component="th" scope="row" padding="none">
                                                    {row.TotalNoOrders}
                                                </TableCell>
                                                <TableCell key={`DataCell_10_${row.EmployeeId}`} align="center">{row.TotalPieceOrder}</TableCell>

                                                <TableCell key={`DataCell_12_${row.EmployeeId}`} align="center" component="th" scope="row" padding="none">
                                                    {row.OrderReturnQty}
                                                </TableCell>

                                                <TableCell key={`DataCell_13_${row.EmployeeId}`} align="center" component="th" scope="row" padding="none">
                                                    {row.DeliveredQty}
                                                </TableCell>

                                                {row.TotalAmount > -1 && <TableCell key={`DataCell_14_${row.EmployeeId}`} align="center" component="th" scope="row" padding="none" style={{color:'red'}}>
                                                    {fCurrency(row.TotalReturnAmount)}
                                                </TableCell>}

                                                {row.TotalAmount > -1 && <TableCell key={`DataCell_99_${row.EmployeeId}`} align="center" component="th" scope="row" padding="none" style={{color:'blue'}}>
                                                    {fCurrency(row.TotalAmount)}
                                                </TableCell>}

                                                {row.TotalAmount > -1 && <TableCell key={`DataCell_169_${row.EmployeeId}`} align="center" component="th" scope="row" padding="none" style={{ color: 'red' }}>
                                                    {fCurrency(row.TotalDiscount)}
                                                </TableCell>}

                                                {row.TotalAmount > -1 && <TableCell key={`DataCell_168_${row.EmployeeId}`} align="center" component="th" scope="row" padding="none" style={{ color: 'red' }}>
                                                    {fCurrency(row.TotalSchemeCost)}
                                                </TableCell>}

                                                {row.TotalAmount > -1 && <TableCell key={`DataCell_167_${row.EmployeeId}`} align="center" component="th" scope="row" padding="none" style={{ color: 'green' }}>
                                                    {fCurrency(row.TotalAmount - row.TotalDiscount - row.TotalSchemeCost)}
                                                </TableCell>}
                                            </TableRow>
                                        );
                                    })}
                                    <TableRow key={`${pageKey}_21`}>
                                        <TableCell style={borderTopStyle} key={`${pageKey}_22`} colSpan={2} align='right'><h3>Total:</h3></TableCell>
                                        <TableCell style={borderTopStyle} key={`${pageKey}_23`} align="center">
                                            <strong>{orderBookerTotal.Visited}</strong>
                                        </TableCell>
                                        <TableCell style={borderTopStyle} key={`${pageKey}_24`} align="center">
                                            <strong>{orderBookerTotal.Orders}</strong>
                                        </TableCell>
                                        <TableCell style={borderTopStyle} key={`${pageKey}_25`} align="center" component="th" scope="row" padding="none">
                                            <strong>{orderBookerTotal.NoOrders}</strong>
                                        </TableCell>
                                        <TableCell style={borderTopStyle} key={`${pageKey}_26`} align="center">
                                            <strong>{orderBookerTotal.OrderQty}</strong>
                                        </TableCell>

                                        <TableCell style={borderTopStyle} key={`${pageKey}_27`} align="center" component="th" scope="row" padding="none">
                                            <strong>{orderBookerTotal.ReturnQty}</strong>
                                        </TableCell>

                                        <TableCell style={borderTopStyle} key={`${pageKey}_28`} align="center" component="th" scope="row" padding="none">
                                            <strong>{orderBookerTotal.DeliveredQty}</strong>
                                        </TableCell>

                                        {showAllFieldsHeaders == true && <TableCell style={{...borderTopStyle,color:'red'}} key={`${pageKey}_219`} align="center" component="th" scope="row" padding="none">
                                            <strong>{fCurrency(orderBookerTotal.ReturnAmount)}</strong>
                                        </TableCell>}

                                        {showAllFieldsHeaders == true && <TableCell style={{...borderTopStyle,color:'blue'}} key={`${pageKey}_229`} align="center" component="th" scope="row" padding="none">
                                            <strong>{fCurrency(orderBookerTotal.Sale)}</strong>
                                        </TableCell>}

                                        {showAllFieldsHeaders == true && <TableCell key={`${pageKey}_30`} align="center" component="th" scope="row" padding="none" style={{ ...borderTopStyle, color: 'red' }}>
                                            <strong>{fCurrency(orderBookerTotal.DiscountAmount)}</strong>
                                        </TableCell>}

                                        {showAllFieldsHeaders == true && <TableCell key={`${pageKey}_31`} align="center" component="th" scope="row" padding="none" style={{...borderTopStyle, color: 'red' }}>
                                            <strong>{fCurrency(orderBookerTotal.SchemeCost)}</strong>
                                        </TableCell>}

                                        {showAllFieldsHeaders == true && <TableCell key={`${pageKey}_32`} align="center" component="th" scope="row" padding="none" style={{ ...borderTopStyle, borderTopWidth:'small',color: 'green' }}>
                                            <strong>{fCurrency(orderBookerTotal.NetSale)}</strong>
                                        </TableCell>}
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Scrollbar>}

                    {props.showProductWiseDetail == true && !!ordersResult && !!ordersResult.ProductSaleReport && ordersResult.ProductSaleReport.length > 0 && <Scrollbar key={`${pageKey}_product_11`} sx={{}}>
                        <TableContainer key={`${pageKey}_product_12`} sx={{ minWidth: 800, marginTop:5 }}>
                            <Table key={`${pageKey}_product_13`} size='small'>
                                
                                <UserListHead
                                    order={'asc'}
                                    orderBy={''}
                                    headLabel={showAllFieldsHeaders ? AllFieldsProductHeader : normalProductHeaders}
                                    rowCount={0}
                                    numSelected={0}
                                    onRequestSort={undefined}
                                    onSelectAllClick={undefined}
                                    customKey={`${pageKey}_product_14`}
                                    key={`${pageKey}_product_15`}
                                    typographyVariant={'subtitle1'}
                                    tableHeadTitle={`${props.unitTitle}'s Products Information`}
                                    tableHeadAlignment='left'
                                    tableHeadTypoVariant='h5'
                                    showHeaderBorder={true}
                                />
                                <TableBody key={`${pageKey}_product_126`}>
                                    {!!ordersResult && !!ordersResult.ProductSaleReport && ordersResult.ProductSaleReport.length > 0 && ordersResult.ProductSaleReport.map((row, index) => {

                                        return (
                                            <TableRow
                                                hover
                                                key={`DataRow_product_${row.ProductId}`}
                                                tabIndex={-1}
                                                role="checkbox"
                                                selected={false}
                                                aria-checked={false}
                                            >
                                                <TableCell key={`DataCell_1_product_${row.ProductId}`} align="center">{index + 1}</TableCell>
                                                <TableCell key={`DataCell_2_product_${row.ProductId}`} align="center">{row.ProductName}</TableCell>
                                                
                                                <TableCell key={`DataCell_product_10_${row.ProductId}`} align="center">{row.TotalPieceOrder}</TableCell>

                                                <TableCell key={`DataCell_product_12_${row.ProductId}`} align="center" component="th" scope="row" padding="none">
                                                    {row.OrderReturnQty}
                                                </TableCell>

                                                <TableCell key={`DataCell_product_13_${row.ProductId}`} align="center" component="th" scope="row" padding="none">
                                                    {row.DeliveredQty}
                                                </TableCell>

                                                {row.TotalAmount > -1 && <TableCell key={`DataCell_product_999_${row.ProductId}`} align="center" component="th" scope="row" padding="none" style={{color:'red'}}>
                                                    {fCurrency(row.TotalReturnAmount)}
                                                </TableCell>}

                                                {row.TotalAmount > -1 && <TableCell key={`DataCell_product_9999_${row.ProductId}`} align="center" component="th" scope="row" padding="none" style={{color:'blue'}}>
                                                    {fCurrency(row.TotalAmount)}
                                                </TableCell>}

                                                {row.TotalAmount > -1 && <TableCell key={`DataCell_product_136_${row.ProductId}`} align="center" component="th" scope="row" padding="none" style={{ color: 'red' }}>
                                                    {fCurrency(row.TotalDiscount)}
                                                </TableCell>}

                                                {row.TotalAmount > -1 && <TableCell key={`DataCell_product_146_${row.ProductId}`} align="center" component="th" scope="row" padding="none" style={{ color: 'red' }}>
                                                    {fCurrency(row.TotalSchemeCost)}
                                                </TableCell>}

                                                {row.TotalAmount > -1 && <TableCell key={`DataCell_product_156_${row.ProductId}`} align="center" component="th" scope="row" padding="none" style={{ color: 'green' }}>
                                                    {fCurrency(row.TotalAmount - row.TotalDiscount - row.TotalSchemeCost)}
                                                </TableCell>}
                                            </TableRow>
                                        );
                                    })}
                                    <TableRow key={`${pageKey}_product_21`}>
                                        <TableCell style={borderTopStyle} key={`${pageKey}_product_22`} colSpan={2} align='right'><h3>Total:</h3></TableCell>
                                        
                                        
                                        
                                        <TableCell style={borderTopStyle} key={`${pageKey}_product_26`} align="center">
                                            <strong>{productTotal.OrderQty}</strong>
                                        </TableCell>

                                        <TableCell style={borderTopStyle} key={`${pageKey}_product_27`} align="center" component="th" scope="row" padding="none">
                                            <strong>{productTotal.ReturnQty}</strong>
                                        </TableCell>

                                        <TableCell style={borderTopStyle} key={`${pageKey}_product_28`} align="center" component="th" scope="row" padding="none">
                                            <strong>{productTotal.DeliveredQty}</strong>
                                        </TableCell>

                                        {showAllFieldsHeaders == true && <TableCell style={{...borderTopStyle,color:'red'}} key={`${pageKey}_product_239`} align="center" component="th" scope="row" padding="none">
                                            <strong>{fCurrency(productTotal.ReturnAmount)}</strong>
                                        </TableCell>}

                                        {showAllFieldsHeaders == true && <TableCell style={{...borderTopStyle,color:'blue'}} key={`${pageKey}_product_29`} align="center" component="th" scope="row" padding="none">
                                            <strong>{fCurrency(productTotal.Sale)}</strong>
                                        </TableCell>}

                                        {showAllFieldsHeaders == true && <TableCell key={`${pageKey}_product_30`} align="center" component="th" scope="row" padding="none" style={{...borderTopStyle, color: 'red' }}>
                                            <strong>{fCurrency(productTotal.DiscountAmount)}</strong>
                                        </TableCell>}

                                        {showAllFieldsHeaders == true && <TableCell key={`${pageKey}_product_31`} align="center" component="th" scope="row" padding="none" style={{...borderTopStyle, color: 'red' }}>
                                            <strong>{fCurrency(productTotal.SchemeCost)}</strong>
                                        </TableCell>}

                                        {showAllFieldsHeaders == true && <TableCell key={`${pageKey}_product_32`} align="center" component="th" scope="row" padding="none" style={{...borderTopStyle, color: 'green' }}>
                                            <strong>{fCurrency(productTotal.NetSale)}</strong>
                                        </TableCell>}
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Scrollbar>}
                </Card>

            </Container>
        </Page>
    );
}
