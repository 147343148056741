import {LoginUserActionConstants} from '../actions/action.constant';
import {IAction} from '../actions/action.interface';
import {IUserDetail} from '../../interfaces';
import {initialState} from '../initial-state';

export const loginUserReducer = (
  state: IUserDetail | null = initialState.loginUser,
  action: IAction<IUserDetail | null>,
) => {
  switch (action.type) {
    case LoginUserActionConstants.SET_LOGIN_USER_DETAIL: {
      let newState: IUserDetail | null = {...action.payload};
      return newState;
    }
    default:
      return state;
  }
};
