import { Grid } from '@mui/material';
import React from 'react';
import { fCurrency } from 'src/utils/formatNumber';

interface IProps{
    routeName:string;
    routeDay:string;
    assignTo:string;
    totalShops:number;
    totalWholeSaleShops:number;
    totalRetailShops:number;
    totalCoveredShops:number;
    wholeSaleCoveredShops:number;
    retailCoveredShops:number;
    pendingCredit:number;
    numberOfShopsWithCredit:number;
}

export const UserRouteInfoComponent = (props:IProps) =>{
    return <table border={1} cellSpacing={0} cellPadding={5} style={{ width: '100%', fontSize: 11, marginBottom: 10, marginTop:10 }}>
            <tbody>
            <tr>
              <th style={{padding:7}} colSpan={5} align={'center'}>{props.routeDay}</th>
            </tr>
            <tr>
              <th style={{padding:7}} colSpan={5} align={'center'}>{props.routeName ?? ''}</th>
            </tr>
            <tr>
              <td style={{padding:7}}>Pending Credit:</td>
              <td colSpan={4} align={'center'} style={{padding:7,color:'red'}}>{fCurrency(props.pendingCredit)}</td>
            </tr>
            <tr>
              <td style={{padding:7}}>Pending Credit Shops:</td>
              <td colSpan={4} align={'center'} style={{padding:7,color:'red'}}>{props.numberOfShopsWithCredit}</td>
            </tr>
            <tr>
              <th style={{padding:7}}>Detail</th>
              <th style={{padding:7}}>Total</th>
              <th style={{padding:7, background: 'rgba(51, 170, 51, .4)'}}>Covered</th>
              <th style={{padding:7, background:'rgba(255, 0, 0, .4)'}}>Never Covered</th>
              <th style={{padding:7, background: 'rgba(51, 170, 51, .4)'}}>Percentage</th>
            </tr>
            <tr>
              <td style={{padding:7}}>Total Shops</td>
              <td style={{padding:7}} align='center'>{props.totalShops}</td>
              <td style={{padding:7, background: 'rgba(51, 170, 51, .4)'}} align='center'>{props.totalCoveredShops}</td>
              <td style={{padding:7, background:'rgba(255, 0, 0, .4)'}} align='center'>{props.totalShops - props.totalCoveredShops}</td>
              <td style={{padding:7, background: 'rgba(51, 170, 51, .4)'}} align='center'>{`${parseFloat(((props.totalCoveredShops / (props.totalShops > 0 ? props.totalShops : 1)) * 100).toString()).toFixed(2)} %`}</td>
            </tr>
            <tr>
              <td style={{padding:7}}>Whole Sale Shops</td>
              <td style={{padding:7}} align='center'>{props.totalWholeSaleShops}</td>
              <td style={{padding:7, background: 'rgba(51, 170, 51, .4)'}} align='center'>{props.wholeSaleCoveredShops}</td>
              <td style={{padding:7, background:'rgba(255, 0, 0, .4)'}} align='center'>{props.totalWholeSaleShops - props.wholeSaleCoveredShops}</td>
              <td style={{padding:7, background: 'rgba(51, 170, 51, .4)'}} align='center'>{`${parseFloat(((props.wholeSaleCoveredShops / (props.totalWholeSaleShops > 0 ? props.totalWholeSaleShops : 1)) * 100).toString()).toFixed(2)} %`}</td>
            </tr>
            <tr>
              <td style={{padding:7}}>Retail Shops</td>
              <td style={{padding:7}} align='center'>{props.totalRetailShops}</td>
              <td style={{padding:7, background: 'rgba(51, 170, 51, .4)'}} align='center'>{props.retailCoveredShops}</td>
              <td style={{padding:7, background:'rgba(255, 0, 0, .4)'}} align='center'>{props.totalRetailShops - props.retailCoveredShops}</td>
              <td style={{padding:7, background: 'rgba(51, 170, 51, .4)'}} align='center'>{`${parseFloat(((props.retailCoveredShops / (props.totalRetailShops > 0 ? props.totalRetailShops : 1)) * 100).toString()).toFixed(2)} %`}</td>
            </tr>
            
            </tbody>
          </table>
}