import React from 'react';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import arrowBackFill from '@iconify/icons-eva/arrow-back-fill';
import searchFill from '@iconify/icons-eva/search-fill';
import plusFill from '@iconify/icons-eva/plus-fill';
import refreshFill from '@iconify/icons-eva/refresh-fill';
import saveFill from '@iconify/icons-eva/save-fill';
import moneyCollectFill from '@iconify/icons-ant-design/money-collect-fill';
import closeFill from '@iconify/icons-eva/close-fill';
// material
import {
    Card,
    Table,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    Grid,
    Stack,
    Avatar,
    TextField,
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { UserListHead } from '../components/_dashboard/user';
//
import { LoadingButton } from '@mui/lab';
import { employeeApi, deliveryOrderApi, doCashApi } from '../http';
import { IAutoComplete } from 'src/interfaces/auto-complete.interface';
import { formattedDate, getCommaSeparatedString, getCommaSeparatedUnitUniqueIdsExceptAll, scrollToTop } from 'src/utility';

import { useSnackbar } from 'notistack';
import { connect, useSelector } from 'react-redux';
import { IApplicationState } from 'src/redux';
import { IOrderBookerForDO } from 'src/interfaces/delivery-order/order-booker-do.interface';
import { Audio as AudoSpin } from 'react-loader-spinner'
import { IDODetail } from 'src/interfaces/delivery-order/do-detail.interface';
import BarcodeReader from 'react-barcode-reader'
import { IDeliveryOrderSummary } from 'src/interfaces/delivery-order/delivery-order-summary.interface';
import { IProductBarcode } from 'src/interfaces/product-barcode.interface';
import Label from 'src/components/Label';
import { sentenceCase } from 'change-case';
import { IProductWiseOrderDetail } from 'src/interfaces/product-wise-order-detail';
import { IOrderMaster } from 'src/interfaces/order-master.interface';
import { IProductScheme } from 'src/interfaces/product-scheme.interface';
import { CustomAutoComplete } from 'src/components/common/CustomAutoComplete';
import { IDOCashDetail } from 'src/interfaces/do-cash-detail.interface';
import { IDOCredit } from 'src/interfaces/do-credit.interface';
import { IDOCashPostModel } from 'src/interfaces/do-cash-post-model.interface';
import { confirmAction } from 'src/utility/confirm.util';
import { IMessage } from 'src/interfaces/message.interface';
import { MessageComponent } from 'src/components/messages.component';
import { IUserDetail } from 'src/interfaces';
import { ISelectedUnitAccess } from 'src/interfaces/selected-unit-access.interface';
import { IDOResultWithDetail } from 'src/interfaces/delivery-order/do-result-with-detail.interface';
import { AllOptionUniqueId, DoDetailFor, EmptyGuid } from 'src/app.constant';
import { fCurrency } from 'src/utils/formatNumber';
import { NoUnitSelectedComponent } from 'src/components/common/no-unit-selected.component';


// ----------------------------------------------------------------------


const TABLE_HEAD_OrderDetail = [
    { id: 'order_id', label: 'Order Id', alignContent: 'center' },
    { id: 'route_name', label: 'Route Name', alignContent: 'center' },
    { id: 'shop_name', label: 'Shop Name', alignContent: 'center' },
    { id: 'total_payment', label: 'Total Payment', alignContent: 'center' },
    { id: 'resposible_person', label: 'Responsible Person', alignContent: 'center' },
    { id: 'credit_amount', label: 'Credit Amount', alignContent: 'center' },
    { id: 'action', label: '', alignContent: 'center' },
];

const TABLE_HEAD_DO = [
    { id: 'collapse', label: '', alignContent: 'center' },
    { id: 'sr', label: 'Sr.', alignContent: 'center' },
    { id: 'delivery_boy', label: 'Delivery Boy', alignContent: 'center' },
    { id: 'orders_date', label: 'Orders Date', alignContent: 'center' },
    { id: 'total_amount', label: 'Total Amount', alignContent: 'center' },
    { id: 'do_status', label: 'DO Status', alignContent: 'center' },
    { id: 'delete_do', label: '', alignContent: 'center' },
];

const TABLE_HEAD_DO_Return_Detail = [
    { id: 'sr', label: 'Sr.', alignContent: 'center' },
    { id: 'product', label: 'Product', alignContent: 'center' },
    { id: 'retail_qty', label: 'Retail Qty', alignContent: 'center' },
    { id: 'wholesale_qty', label: 'Wholesale Qty', alignContent: 'center' },
    { id: 'retail_amount', label: 'Retail Amount', alignContent: 'center' },
    { id: 'delivery_boy_amount', label: 'Delivery Boy Sale Amount', alignContent: 'center' },
    { id: 'wholesale_amount', label: 'Wholesale Amount', alignContent: 'center' },
    { id: 'total_amount', label: 'Total Amount', alignContent: 'center' },
];

// ----------------------------------------------------------------------

interface IProductWiseReturn {
    ProductId: number;
    ProductName: string;
    TotalReturnQty: number;
    ScannedQty: number;
}

interface IProps{
    loginUser:IUserDetail | null;
    selectedUnits:ISelectedUnitAccess;
  }

const ReceiveCash = (props:IProps) => {

    const initialCashDetail = {
        CoinOne: 0,
        CoinTwo: 0,
        CoinFive: 0,
        NoteTen: 0,
        NoteTwenty: 0,
        NoteFifty: 0,
        NoteHundred: 0,
        NoteFiveHundred: 0,
        NoteThousand: 0,
        NoteFiveThousand: 0,
        DOCashId: 0,
        Id: 0,
        RecordedBy: props.loginUser.UserId,
        RecordedOn: new Date(),
        TotalCashAmount: 0
    };

    //#region state variables
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isReceiving, setIsReceiving] = React.useState(false);
    const [selectedDO, setSelectedDO] = React.useState<IDODetail>(null);
    const [doDetail, setDODetails] = React.useState<IDOResultWithDetail>(null);
    const [isViewingSummary, setIsViewingSummary] = React.useState<boolean>(false);
    const [summaryTotalAmount, setSummaryTotalAmount] = React.useState<number>(0);
    const [creditTotalAmount, setCreditTotalAmount] = React.useState<number>(0);
    const [cashDetail, setCashDetail] = React.useState<IDOCashDetail>(initialCashDetail);
    const [enteredCashAmount, setEnteredCashAmount] = React.useState<number>(0);
    const [orderNumber, setOrderNumber] = React.useState<number>(null);
    const [orderForCredit, setOrderForCredit] = React.useState<IOrderMaster>(null);
    const [pageMessages, setPageMessages] = React.useState<IMessage[]>([]);
    const [selectedOrderForCredit, setSelectedOrdersForCredit] = React.useState<IOrderMaster[]>([]);
    const [hasCredit, setHasCredit] = React.useState<boolean>(true);
    const [isAddingCredit, setIsAddingCredit] = React.useState<boolean>(true);
    const [totalCredit, setTotalCredit] = React.useState<number>(0);

    const inputRefs:any[] = [
        React.useRef(null),
        React.useRef(null),
        React.useRef(null),
        React.useRef(null),
        React.useRef(null),
        React.useRef(null),
        React.useRef(null),
        React.useRef(null),
        React.useRef(null),
        React.useRef(null)
    ];

    const orderNumberInputRef = React.useRef(null);
    const creditAmountInputRef = React.useRef(null);

    //#endregion

    //#region Util Methods
    const showErrorMessage = (message: string) => addMessage(message, 'error');
    const showInfoMessage = (message: string) => addMessage(message, 'info');
    const showSuccessMessage = (message: string) => addMessage(message, 'success');
    const showWarningMessage = (message: string) => addMessage(message, 'warning');

    const addMessage = (messageText: string, variant: 'success' | 'error' | 'info' | 'warning') => {
        if (!!messageText && messageText.trim() != '') {
            let msgs = [];
            msgs.push({
                messageText,
                variant
            });

            setPageMessages([...msgs]);
        }
    }

    const getDoDetailCopy = () =>{
        let detail = {...doDetail,DeliveryOrders:[...doDetail.DeliveryOrders.map(a => a)],CreditWithProductWiseSummary:{...doDetail.CreditWithProductWiseSummary,ProductWiseSummaries:[...doDetail.CreditWithProductWiseSummary.ProductWiseSummaries.map(a => a)]},UnitWiseSummary:[...doDetail.UnitWiseSummary.map(a => a)]};
        return detail;
      }

    const getUnitTotals = (unitUniqueId:string) =>{
        let totals = {
            totalAmount:0,
            totalCredit:0,
            netAmount:0,
        };

        if(!!doDetail && !!doDetail.UnitWiseSummary && doDetail.UnitWiseSummary.length > 0 && doDetail.UnitWiseSummary.filter(u => u.UnitUniqueId == unitUniqueId).length > 0){
            const unitSummary = [...doDetail.UnitWiseSummary.filter(u => u.UnitUniqueId == unitUniqueId)];

            unitSummary.forEach(oas => {
                totals.totalAmount += (oas.TotalRetailAmount + oas.TotalWholeSaleAmount + oas.TotalDeliveryBoySaleAmount);
                totals.totalCredit = isNaN(oas.TotalCredit) ? 0 : oas.TotalCredit;
            });
        }

        totals.netAmount = totals.totalAmount - totals.totalCredit;

        return {...totals};
    }

    const calculateTotalCashAmount = (detail: IDOCashDetail) => {
        const tAmount = ((isNaN(detail.CoinOne) ? 0 : detail.CoinOne) * 1)
            +
            ((isNaN(detail.CoinTwo) ? 0 : detail.CoinTwo) * 2)
            +
            ((isNaN(detail.CoinFive) ? 0 : detail.CoinFive) * 5)
            +
            ((isNaN(detail.NoteTen) ? 0 : detail.NoteTen) * 10)
            +
            ((isNaN(detail.NoteTwenty) ? 0 : detail.NoteTwenty) * 20)
            +
            ((isNaN(detail.NoteFifty) ? 0 : detail.NoteFifty) * 50)
            +
            ((isNaN(detail.NoteHundred) ? 0 : detail.NoteHundred) * 100)
            +
            ((isNaN(detail.NoteFiveHundred) ? 0 : detail.NoteFiveHundred) * 500)
            +
            ((isNaN(detail.NoteThousand) ? 0 : detail.NoteThousand) * 1000)
            +
            ((isNaN(detail.NoteFiveThousand) ? 0 : detail.NoteFiveThousand) * 5000);

        setEnteredCashAmount(tAmount);
    }

    const calculateTotalCredit = (details: IOrderMaster[]) => {
        if (!!details && details.length > 0) {
            let creditedAmount = 0;
            details.forEach(d => {
                creditedAmount += (d.CreditAmount ?? 0);
            });

            setTotalCredit(creditedAmount);
        } else {
            setTotalCredit(0);
            setHasCredit(false);
        }
    }

    //#endregion

    //#region handlers
    const resetEverything = () => {
        setDODetails(null);
        setIsReceiving(false);
        setSelectedDO(null);
        getDeliveryOrders();
        setOrderNumber(null);
        setOrderForCredit(null);
        setSelectedOrdersForCredit([]);
        setHasCredit(true);
        setIsAddingCredit(true);
        setTotalCredit(0);
        setCashDetail(initialCashDetail);
        setEnteredCashAmount(0);
        setIsViewingSummary(false);
    }

    const onViewSummaryClick = () => {
        setIsViewingSummary(true);
        setPageMessages([]);
        let totalSummaryAmount = 0;
        let cTotalAmount = 0;

        if (!!doDetail && !!doDetail.CreditWithProductWiseSummary && !!doDetail.CreditWithProductWiseSummary.ProductWiseSummaries && doDetail.CreditWithProductWiseSummary.ProductWiseSummaries.length > 0) {
            doDetail.CreditWithProductWiseSummary.ProductWiseSummaries.forEach(oas => {
                totalSummaryAmount += (oas.TotalRetailAmount + oas.TotalWholeSaleAmount + oas.TotalDeliveryBoySaleAmount);
            });

            setSummaryTotalAmount(totalSummaryAmount);
            setCreditTotalAmount(doDetail.CreditWithProductWiseSummary.PendingCredit);
        }else{
            setSummaryTotalAmount(0);
            setCreditTotalAmount(0);
        }
    }

    const receiveCash = (doId: number) => {
        setPageMessages([]);
        const filteredDO = doDetail.DeliveryOrders.find(m => m.DeliveryOrderId == doId);

        if (!!filteredDO && !!filteredDO.DOSummary && filteredDO.DOSummary.length > 0) {
            setIsReceiving(true);

            filteredDO.DOSummary.forEach(s => {
                s.ScannedQty = 0;
            });

            setSelectedDO({ ...filteredDO });
            setOrderNumber(null);
            setOrderForCredit(null);
            setTotalCredit(0);
            setSelectedOrdersForCredit([]);
            setCashDetail(initialCashDetail);
        } else {
            enqueueSnackbar('No Details Found', { autoHideDuration: 3000, variant: 'error' });
        }
    }

    const onCashDetailChange = (value: number, name: keyof IDOCashDetail) => {
        setPageMessages([]);
        let copy = { ...cashDetail };
        (copy as any)[name] = isNaN(value) ? 0 : value;
        calculateTotalCashAmount({ ...copy });
        setCashDetail({ ...copy });
    }

    const saveCashDetail = () => {
        setPageMessages([]);
        const doIdToSave = selectedDO.DeliveryOrderId;
        const totalDOPayment = selectedDO.TotalPayment;
        const totalDoCredit = totalCredit;
        const totalAmountForDOToReceive = totalDOPayment - totalDoCredit;

        const totalCashAmount = ((isNaN(cashDetail.CoinOne) ? 0 : cashDetail.CoinOne) * 1)
            +
            ((isNaN(cashDetail.CoinTwo) ? 0 : cashDetail.CoinTwo) * 2)
            +
            ((isNaN(cashDetail.CoinFive) ? 0 : cashDetail.CoinFive) * 5)
            +
            ((isNaN(cashDetail.NoteTen) ? 0 : cashDetail.NoteTen) * 10)
            +
            ((isNaN(cashDetail.NoteTwenty) ? 0 : cashDetail.NoteTwenty) * 20)
            +
            ((isNaN(cashDetail.NoteFifty) ? 0 : cashDetail.NoteFifty) * 50)
            +
            ((isNaN(cashDetail.NoteHundred) ? 0 : cashDetail.NoteHundred) * 100)
            +
            ((isNaN(cashDetail.NoteFiveHundred) ? 0 : cashDetail.NoteFiveHundred) * 500)
            +
            ((isNaN(cashDetail.NoteThousand) ? 0 : cashDetail.NoteThousand) * 1000)
            +
            ((isNaN(cashDetail.NoteFiveThousand) ? 0 : cashDetail.NoteFiveThousand) * 5000);

        if (totalCashAmount == totalAmountForDOToReceive) {
            let cashDetailForPost = { ...cashDetail };
            cashDetailForPost.TotalCashAmount = totalCashAmount;

            let creditDetails: IDOCredit[] = [];
            if (!!selectedOrderForCredit && selectedOrderForCredit.length > 0) {
                selectedOrderForCredit.forEach(c => {
                    creditDetails.push({
                        Id: 0,
                        DOId: doIdToSave,
                        CreditedOn: new Date(),
                        CreditStatus: '',
                        CreditStatusId: 1,
                        DOCashId: 0,
                        OrderId: c.OrderId,
                        PendingAmount: c.CreditAmount,
                        ReceivedAmount: 0,
                        ReceivedById: null,
                        ReceivedOn: null,
                        ResponsibleEmployeeCellNumber: '',
                        ResponsibleEmployeeId: c.ResponsiblePersonId,
                        ResponsibleEmployeeName: c.ResponsiblePersonText,
                        TotalAmount: c.CreditAmount,
                        UnitId:0,
                        UnitUniqueId:EmptyGuid,
                        UnitTitle:'',
                    });
                });
            }

            let doModel: IDOCashPostModel = {
                DOCashDetail: { ...cashDetailForPost },
                DOCredits: [...creditDetails]
            };

            setIsLoading(true);
            doCashApi.saveDOCashDetails(props.loginUser.UserId, doIdToSave, doModel)
                .then(res => {
                    setIsLoading(false);

                    if (res.IsOk == true) {
                        const msg = `Cash Received Successfully From ${selectedDO.DeliveryBoy} Against DO# ${selectedDO.DeliveryOrderId}`;
                        showInfoMessage(msg);
                        resetEverything();
                        scrollToTop();
                    } else {
                        showErrorMessage(res.StatusMessage);
                        scrollToTop();
                        confirmAction('Error', res.StatusMessage,'Ok', 'Ok','red','red','red', 'red',true,'red').then();
                    }
                })
                .catch(e => {
                    setIsLoading(false);
                    console.log(e);
                });
        } else {
            showErrorMessage("Total Cash Amount Must Be Eqaul To Total Receive Amount");
        }

    }

    const onRefresh = () => {
        setPageMessages([]);
        resetEverything();
    }

    const onViewDOClick = () => {
        resetEverything();
        setPageMessages([]);
    }

    const onBackToList = () => {
        confirmAction('Cash Receive', "You have't saved your changes and those will be lost on go back. Are you sure you want to go back?", 'Yes', 'No')
            .then(res => {
                if (res == true) {
                    resetEverything();
                    setPageMessages([]);
                }
            });
    }

    const onReceiveCashAfterCredit = () => {
        if ((!selectedOrderForCredit || selectedOrderForCredit.length <= 0 || !selectedOrderForCredit.find(m => m.CreditAmount > 0))) {
            confirmAction('Credit', "You haven't added any credit. Are you sure you want to proceed?", 'Proceed With No Credit', 'No')
                .then(res => {
                    if (res == true) {
                        setHasCredit(false);
                        setIsAddingCredit(false);
                        setOrderNumber(null);
                        setOrderForCredit(null);
                    }
                });
        } else {
            setHasCredit(false);
            setIsAddingCredit(false);
            setOrderNumber(null);
            setOrderForCredit(null);
        }
    }

    const handleCollapsed = (doId: number) => {
        let detail = getDoDetailCopy();
        let dOrder = detail.DeliveryOrders.find(d => d.DeliveryOrderId == doId);
        let existingStatus = dOrder.IsExpanded;
        dOrder.IsExpanded = existingStatus == undefined || existingStatus == null ? true : !existingStatus;
        setDODetails(detail);
        setPageMessages([]);
    }

    const handleCreditAmountChange = (creditAmount: number, orderId: number) => {
        setPageMessages([]);
        let copy = { ...orderForCredit };
        copy.CreditAmount = creditAmount;
        setOrderForCredit({ ...copy });
    }

    const addToCreditList = (orderId: number) => {
        setPageMessages([]);
        let orderForCreditListCopy = [...selectedOrderForCredit];

        const index = orderForCreditListCopy.findIndex(m => m.OrderId == orderId);

        if (index >= 0) {
            showErrorMessage("Order Already Exists In Credit List");
        } else {
            if (orderForCredit.CreditAmount == undefined || orderForCredit.CreditAmount == null || isNaN(orderForCredit.CreditAmount) || orderForCredit.CreditAmount <= 0) {
                showErrorMessage("Please Enter Credit Amount");
            } else {
                if (orderForCredit.ResponsiblePersonId > 0) {
                    if (orderForCredit.CreditAmount > orderForCredit.TotalPayment) {
                        showErrorMessage("Credit Amount Can't be greater than Total Payment");
                    } else {
                        orderForCreditListCopy = [...selectedOrderForCredit, { ...orderForCredit, ResponsiblePersonText: orderForCredit.OrderBooker }];
                        setSelectedOrdersForCredit([...orderForCreditListCopy]);
                        calculateTotalCredit([...orderForCreditListCopy]);
                        setOrderForCredit(null);
                        setOrderNumber(null);

                        if (isAddingCredit == false) {
                            setHasCredit(false);
                        }
                    }
                } else {
                    showErrorMessage("Please Select Responsible Person");
                }
            }
        }
    }

    const onChangeResponsiblePerson = (option: IAutoComplete) => {
        let copy = { ...orderForCredit };
        copy.ResponsiblePersonId = option?.Value ?? 0;
        copy.ResponsiblePersonText = option?.DisplayText ?? '';
        setOrderForCredit({ ...copy });
    }

    const removeFromCreditList = (orderId: number) => {
        setPageMessages([]);
        let copy = [...selectedOrderForCredit];
        const indexToRemove = copy.findIndex(m => m.OrderId == orderId);
        copy.splice(indexToRemove, 1);
        setSelectedOrdersForCredit([...copy]);
        calculateTotalCredit([...copy]);
    }

    //#endregion

    //#region Apis Call
    const getOrderDetails = () => {
        setPageMessages([]);
        setOrderForCredit(null);
        if (orderNumber > 0) {
            if(selectedOrderForCredit.find(m => m.OrderId == orderNumber)){
                showErrorMessage(`Credit Already Added For Order Number:${orderNumber}`);
            }else{
                setIsLoading(true);
            deliveryOrderApi.getOrderDetailsForStockReturn(selectedDO.DeliveryOrderId, orderNumber)
                .then(detailForReturn => {
                    setIsLoading(false);

                    if (!!detailForReturn && detailForReturn.TotalPayment > 0) {
                        setOrderForCredit({ ...detailForReturn, ResponsiblePersonId: detailForReturn.OrderBookerId });
                    } else {
                        showErrorMessage(`No Order Found Against Order Number:${orderNumber}`);
                    }
                })
                .catch(e => {
                    setIsLoading(false);
                    showErrorMessage(`No Order Found Against Order Number:${orderNumber}`);
                    console.log(e);
                });
            }
        } else {
            showErrorMessage("Please Enter Order Number");
        }
    }

    const getDeliveryOrders = () => {
        setIsLoading(true);
        deliveryOrderApi.getDeliveryOrders(props.loginUser.UserId,getCommaSeparatedUnitUniqueIdsExceptAll([...props.selectedUnits.UnitAccesses.map(a => a)]),DoDetailFor.CashReceive,null,null,null,null)
          .then(doResults => {
            setIsLoading(false);
            if (!!doResults) {
              setDODetails({...doResults});
            }
            else {
              setDODetails(null);
            }
          })
          .catch(e => {
            console.log(e);
            setIsLoading(false);
          });
      }

      //#endregion

    //#region hooks
    React.useEffect(() => {
        getDeliveryOrders();
    }, [props.selectedUnits.UnitAccesses]);

    React.useEffect(() =>{
        if(isReceiving){
            const handleWheel = e => e.preventDefault();

            if(hasCredit){
                orderNumberInputRef.current.removeEventListener("wheel", handleWheel);
                orderNumberInputRef.current.addEventListener("wheel", handleWheel);
            }

            if(!!orderForCredit && !!orderForCredit.ProductWiseOrderDetails && orderForCredit.ProductWiseOrderDetails.length > 0){
                creditAmountInputRef.current.removeEventListener("wheel", handleWheel);
                creditAmountInputRef.current.addEventListener("wheel", handleWheel);
            }

            if(isAddingCredit == false){
                inputRefs.forEach(r => {
                    r.current.removeEventListener("wheel", handleWheel);
                    r.current.addEventListener("wheel", handleWheel);
                });
            }

            return () => {
                inputRefs.forEach(r => {
                    if(!!r && !!r.current){
                        r.current.removeEventListener("wheel", handleWheel);
                    }
                });

                if(!!orderNumberInputRef && !!orderNumberInputRef.current){
                    orderNumberInputRef.current.removeEventListener("wheel", handleWheel);
                }

                if(!!creditAmountInputRef && !!creditAmountInputRef.current){
                    creditAmountInputRef.current.removeEventListener("wheel", handleWheel);
                }
            };
        }
    },[isReceiving,isAddingCredit,orderForCredit,hasCredit]);

    //#endregion

    //#region Component Return
    return (
        (!!props.selectedUnits && !!props.selectedUnits.UnitAccesses && props.selectedUnits.UnitAccesses.filter(u => u.UnitUniqueId != AllOptionUniqueId).length > 0) ? <>
            <MessageComponent onRemove={(i: number) => {
                let msgs = [...pageMessages];
                msgs.splice(i, 1);
                setPageMessages([...msgs]);
            }} messages={pageMessages} />
            <Page title="DO | Raza Pak Care">
                {isReceiving == false && isViewingSummary == false && <Container style={{ maxWidth: 1500, paddingLeft: 0, paddingRight: 0 }}>
                    <Grid container style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>

                        <LoadingButton
                            size="small"
                            type="button"
                            variant="contained"
                            loading={isLoading}
                            style={{ margin: 3, backgroundColor: 'rgba(34, 154, 22, 0.72)', textAlign: 'right', height: 40 }}
                            startIcon={<Icon icon={refreshFill} />}
                            onClick={onRefresh}
                        >
                            Refresh
                        </LoadingButton>
                        <LoadingButton
                            size="small"
                            type="button"
                            variant="contained"
                            loading={isLoading}
                            style={{ margin: 3, backgroundColor: 'blue', textAlign: 'right', height: 40 }}
                            startIcon={<Icon icon='ooui:text-summary-ltr' />}
                            onClick={onViewSummaryClick}
                        >
                            View Summary
                        </LoadingButton>
                    </Grid>
                    {!!props.selectedUnits && !!props.selectedUnits.UnitAccesses && props.selectedUnits.UnitAccesses.filter(m => m.UnitUniqueId != AllOptionUniqueId).length > 0 && props.selectedUnits.UnitAccesses.filter(m => m.UnitUniqueId != AllOptionUniqueId).map((selectedUnit,unitIndex) => {
                        return <><Card>
                        <Typography style={{ backgroundColor: 'rgba(34, 154, 22, 0.72)', color: 'white', paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h4'}>DO (Cash Receive) For {selectedUnit.UnitTitle}</Typography>
                        {isLoading == true && <AudoSpin
                            height="80"
                            width="80"
                            color='green'
                            ariaLabel='three-dots-loading'
                            wrapperStyle={{ alignSelf: 'center', justifyContent: 'center' }}
                        />}

                        {(!doDetail || !doDetail.DeliveryOrders || doDetail.DeliveryOrders.length <= 0 || doDetail.DeliveryOrders.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length <= 0) && isLoading == false && <Typography style={{ paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h6'}>No DO to receive cash for {selectedUnit.UnitTitle}</Typography>}

                        {!!doDetail && !!doDetail.DeliveryOrders && doDetail.DeliveryOrders.length > 0 && doDetail.DeliveryOrders.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length > 0 && <Scrollbar sx={{}}>
                            <TableContainer sx={{ minWidth: 800 }}>
                                <Table>
                                    <UserListHead
                                        order={'asc'}
                                        orderBy={''}
                                        headLabel={TABLE_HEAD_DO}
                                        rowCount={0}
                                        numSelected={0}
                                        onRequestSort={undefined}
                                        onSelectAllClick={undefined}
                                        customKey={`TableHeadRow_2`}
                                    />
                                    <TableBody>
                                        {!!doDetail && !!doDetail.DeliveryOrders && doDetail.DeliveryOrders.length > 0 && doDetail.DeliveryOrders.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length > 0 && doDetail.DeliveryOrders.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).map((row, index) => {

                                            return (
                                                <><TableRow
                                                    hover
                                                    key={row.DeliveryOrderId}
                                                    tabIndex={-1}
                                                    role="checkbox"
                                                    selected={false}
                                                    aria-checked={false}
                                                >
                                                    <TableCell width={10} align="right">
                                                        {(row.IsExpanded == true && row.DOStatusId == 6) && <Icon color='green' onClick={() => { handleCollapsed(row.DeliveryOrderId) }} style={{ cursor: 'pointer' }} width={22} height={22} icon={'akar-icons:arrow-up'} />}
                                                        {(!row.IsExpanded && row.DOStatusId == 6) && <Icon color='blue' onClick={() => { handleCollapsed(row.DeliveryOrderId) }} style={{ cursor: 'pointer' }} width={22} height={22} icon={'akar-icons:arrow-down'} />}
                                                    </TableCell>
                                                    <TableCell align="center">{index + 1}</TableCell>
                                                    <TableCell align="center" component="th" scope="row" padding="none">
                                                        <Stack direction="row" alignItems="center" justifyContent={'center'} spacing={2}>
                                                            <Avatar alt={row?.DeliveryBoy} src={row?.DeliveryBoyImageUrl} />
                                                            <Typography>{row?.DeliveryBoy ?? ''}</Typography>
                                                        </Stack>
                                                    </TableCell>
                                                    <TableCell align="center" component="th" scope="row" padding="none">
                                                        {formattedDate(row.OrdersDate, 'ddd DD-MM-YYYY')}
                                                    </TableCell>
                                                    <TableCell align="center" style={{ color: 'green' }}>
                                                        <Typography variant="h6">Rs.{row.TotalPayment}/-</Typography>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Label
                                                            variant="ghost"
                                                            color={'success'}
                                                        >
                                                            {sentenceCase(row?.DOStatus ?? '')}
                                                        </Label>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {(row.DOStatusId == 3 || row.DOStatusId == 5) && <LoadingButton
                                                            size="small"
                                                            type="button"
                                                            variant="contained"
                                                            loading={isLoading}
                                                            style={{ margin: 10, backgroundColor: 'blue' }}
                                                            startIcon={<Icon icon={moneyCollectFill} />}
                                                            onClick={() => { receiveCash(row.DeliveryOrderId); }}
                                                        >
                                                            Receive Cash
                                                        </LoadingButton>}
                                                        {row.DOStatusId == 6 && <Icon icon="teenyicons:tick-circle-solid" color='green' width={30} height={30} />}
                                                    </TableCell>
                                                </TableRow>
                                                    {row.IsExpanded == true && <TableRow>
                                                        <TableCell colSpan={7}>
                                                            {<TableContainer>
                                                                <Table>
                                                                    <UserListHead
                                                                        order={'asc'}
                                                                        orderBy={''}
                                                                        headLabel={TABLE_HEAD_DO_Return_Detail}
                                                                        rowCount={0}
                                                                        numSelected={0}
                                                                        onRequestSort={undefined}
                                                                        onSelectAllClick={undefined}
                                                                        customKey={`TableHeadRow_2_Unique`}
                                                                    />
                                                                    <TableBody>
                                                                        {!!row.DOSummary && row.DOSummary.length > 0 && row.DOSummary.map((sRow, sIndex) => {
                                                                            row.TotalCredit = (isNaN(sRow.TotalCredit) ? 0 : sRow.TotalCredit);

                                                                            return (
                                                                                <TableRow
                                                                                    hover
                                                                                    key={row.DeliveryOrderId}
                                                                                    tabIndex={-1}
                                                                                    role="checkbox"
                                                                                    selected={false}
                                                                                    aria-checked={false}
                                                                                >
                                                                                    <TableCell width={10} align="center">
                                                                                        {sIndex + 1}
                                                                                    </TableCell>
                                                                                    <TableCell align="center">{sRow.ProductName}</TableCell>
                                                                                    <TableCell align="center">{sRow.TotalRetailDeliveredQty}</TableCell>
                                                                                    <TableCell align="center">
                                                                                        {sRow.TotalWholeSaleDeliveredQty}
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        Rs.{sRow.TotalRetailAmount}/-
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        Rs.{sRow.TotalDeliveryBoySaleAmount}/-
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        Rs.{sRow.TotalWholeSaleAmount}/-
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        Rs.{sRow.TotalRetailAmount + sRow.TotalWholeSaleAmount + sRow.TotalDeliveryBoySaleAmount}/-
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            );
                                                                        })}
                                                                        <TableRow>
                                                                            <TableCell colSpan={7} align='right'><strong>Total Amount:</strong></TableCell>
                                                                            <TableCell align='center'><Typography variant='h6'>Rs.{row.TotalPayment}/-</Typography></TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell colSpan={7} align='right'><strong>Total Credit:</strong></TableCell>
                                                                            <TableCell align='center' style={{ color: 'red' }}><Typography variant='h6'>Rs.{row.TotalCredit}/-</Typography></TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell colSpan={7} align='right'><strong>Net Amount:</strong></TableCell>
                                                                            <TableCell align='center' style={{ color: 'green' }}><Typography variant='h6'>Rs.{row.TotalPayment - row.TotalCredit}/-</Typography></TableCell>
                                                                        </TableRow>
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>}
                                                        </TableCell>
                                                    </TableRow>}
                                                </>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Scrollbar>}
                    </Card><br /></>})}
                </Container>}


                {isReceiving == true && isViewingSummary == false && <Container style={{ maxWidth: 1500, paddingLeft: 0, paddingRight: 0 }}>
                    <Grid container style={{ flexDirection: 'row', justifyContent: 'flex-end' }}><LoadingButton
                        size="small"
                        type="button"
                        variant="contained"
                        loading={isLoading}
                        style={{ margin: 3, backgroundColor: 'rgba(34, 154, 22, 0.72)', textAlign: 'right', height: 40 }}
                        startIcon={<Icon icon={arrowBackFill} />}
                        onClick={onBackToList}
                    >
                        Back To List
                    </LoadingButton></Grid>
                    <Card>
                        <Typography style={{ backgroundColor: 'rgba(34, 154, 22, 0.72)', color: 'white', paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h4'}>Cash Receive ({selectedDO.UnitTitle})</Typography>

                        {isLoading == true && <AudoSpin
                            height="80"
                            width="80"
                            color='green'
                            ariaLabel='three-dots-loading'
                            wrapperStyle={{ alignSelf: 'center', justifyContent: 'center' }}
                        />}
                        <Container style={{ marginTop: 15, paddingBottom: 10 }}>
                            <Grid container>
                                <Grid item md={5}>
                                    <Typography><strong>DO#:</strong> {selectedDO.DeliveryOrderId}</Typography>
                                </Grid>
                                <Grid item md={5}>
                                    <Stack direction="row" alignItems="center" justifyContent={'center'} spacing={2}>
                                        <Typography><strong>Delivery Boy:</strong> </Typography>
                                        <Avatar alt={selectedDO?.DeliveryBoy} src={selectedDO?.DeliveryBoyImageUrl} />
                                        <Typography>{selectedDO?.DeliveryBoy ?? ''}</Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Container>

                        {isAddingCredit == false && <LoadingButton
                            size="small"
                            type="button"
                            variant="contained"
                            loading={isLoading}
                            style={{ margin: 4, minWidth: 100, height: 40, position: 'absolute', top: 62, right: 0 }}
                            startIcon={<Icon icon={hasCredit ? 'el:eye-close' : 'akar-icons:circle-plus-fill'} />}
                            onClick={() => {
                                setHasCredit(!hasCredit);
                            }}
                        >
                            {!hasCredit ? 'Credit' : 'Hide Add Credit'}
                        </LoadingButton>}

                        {hasCredit == true && <Container style={{ marginBottom: 10 }}>
                            <Typography style={{ padding: 10, marginTop: 10, marginBottom: 10, textAlign: 'center' }} variant='h4'>Add Credit Details</Typography>
                            <Grid container>
                                <Grid item md={4} sm={12}><TextField
                                    fullWidth
                                    autoComplete="order-number"
                                    type={'number'}
                                    label="Order Number"
                                    value={orderNumber > 0 ? orderNumber : ''}
                                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                        const value = event.target.value;
                                        let numberValue: number | null = null;

                                        if (value !== undefined && value != null) {
                                            numberValue = parseInt(value);
                                        }

                                        setOrderNumber(numberValue);
                                        setPageMessages([]);
                                        setOrderForCredit(null);
                                    }}

                                    inputRef={orderNumberInputRef}
                                    onKeyDown={(e: any) => {
                                        if (e.keyCode === 38 || e.keyCode === 40) {
                                            e.preventDefault();
                                          }
                                    }}
                                /></Grid>

                                <Grid item md={4} sm={12}>
                                    <LoadingButton
                                        size="small"
                                        type="button"
                                        variant="contained"
                                        loading={isLoading}
                                        style={{ margin: 4, marginLeft: 15, width: 150, height: 50 }}
                                        startIcon={<Icon icon={searchFill} />}
                                        onClick={getOrderDetails}
                                    >
                                        Get Details
                                    </LoadingButton>
                                </Grid>

                            </Grid>
                        </Container>}

                        {(!orderForCredit || !orderForCredit.ProductWiseOrderDetails || orderForCredit.ProductWiseOrderDetails.length <= 0) && hasCredit == true && <><br /><br /><br /><br /><br /><br /><br /><br /><br /></>}

                        {!!orderForCredit && <Scrollbar sx={{}}>
                            <TableContainer sx={{ minWidth: 800 }}>
                                <Table sx={{ backgroundColor: '#F5F5F5' }}>
                                    <UserListHead
                                        order={'asc'}
                                        key={`Table_${orderForCredit.OrderId}`}
                                        customKey={`TableDetailHeadRow_${orderForCredit.OrderId}`}
                                        orderBy={'asc'}
                                        headLabel={TABLE_HEAD_OrderDetail}
                                        rowCount={0}
                                        numSelected={0}
                                    />
                                    <TableBody key={`Table_Body_${orderForCredit.OrderId}`}>
                                        <TableRow
                                            key={`TableDetailRow_${orderForCredit.OrderId}}`}
                                            tabIndex={-1}
                                            role="checkbox"
                                            selected={false}
                                            aria-checked={false}
                                        >
                                            <TableCell width={90} align="center">
                                                {orderForCredit.OrderId ?? ''}
                                            </TableCell>
                                            <TableCell width={300} align="center">
                                                {orderForCredit.RouteTitle ?? ''}
                                            </TableCell>
                                            <TableCell width={200} align="center">
                                                {orderForCredit.ShopTitle ?? ''}
                                            </TableCell>
                                            <TableCell width={150} align="center">
                                                {orderForCredit.TotalPayment ?? 0}
                                            </TableCell>
                                            <TableCell align="center">
                                                <CustomAutoComplete<IAutoComplete>
                                                    Id={'responsible_person'}
                                                    Value={!!orderForCredit && !!orderForCredit.CreditResponsiblePersons && orderForCredit.ResponsiblePersonId > 0 ? orderForCredit.CreditResponsiblePersons.filter(m => m.Value == orderForCredit.ResponsiblePersonId)[0] : null}
                                                    Options={orderForCredit.CreditResponsiblePersons}
                                                    displayField={'DisplayText'}
                                                    valueField={'Value'}
                                                    Label={'Responsible Person'}
                                                    onChange={onChangeResponsiblePerson}
                                                    disabled={true}
                                                />
                                            </TableCell>
                                            <TableCell width={180} align="center">
                                                <TextField
                                                    fullWidth
                                                    autoComplete="credit-amount"
                                                    type={'number'}
                                                    label="Credit Amount"
                                                    value={orderForCredit.CreditAmount}
                                                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                        const value = event.target.value;
                                                        let numberValue: number | null = null;

                                                        if (value !== undefined && value != null) {
                                                            numberValue = parseInt(value);
                                                        }

                                                        handleCreditAmountChange(numberValue, orderForCredit.OrderId);
                                                    }}
                                                    inputRef={creditAmountInputRef}
                                                    onKeyDown={(e: any) => {
                                                        if (e.keyCode === 38 || e.keyCode === 40) {
                                                            e.preventDefault();
                                                          }
                                                    }}
                                                />
                                            </TableCell>

                                            <TableCell align="center">
                                                <LoadingButton
                                                    size="small"
                                                    type="button"
                                                    variant="contained"
                                                    loading={isLoading}
                                                    style={{ margin: 3, height: 30 }}
                                                    startIcon={<Icon icon={plusFill} />}
                                                    onClick={() => {
                                                        addToCreditList(orderForCredit.OrderId);
                                                    }}
                                                >
                                                    Add
                                                </LoadingButton>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Scrollbar>}
                        <hr style={{ height: 3, backgroundColor: 'green' }} />
                        {!!selectedOrderForCredit && selectedOrderForCredit.length > 0 && <Scrollbar sx={{}}>
                            <TableContainer sx={{ minWidth: 800 }}>
                                <Typography style={{ padding: 10, textAlign: 'center' }} variant='h4'>Credit Details</Typography>
                                {(!selectedOrderForCredit || selectedOrderForCredit.length <= 0) && <Typography style={{ padding: 10, textAlign: 'center' }}><strong>No Item Added Yet</strong></Typography>}
                                {!!selectedOrderForCredit && selectedOrderForCredit.length > 0 && <Table sx={{ backgroundColor: '#F5F5F5' }}>
                                    <UserListHead
                                        order={'asc'}
                                        key={`Table_Details_Selection`}
                                        customKey={`TableDetailHeadRow_Details`}
                                        orderBy={'asc'}
                                        headLabel={TABLE_HEAD_OrderDetail}
                                        rowCount={0}
                                        numSelected={0}
                                    />
                                    <TableBody key={`Table_Body_Selection_Details`}>
                                        {!!selectedOrderForCredit && selectedOrderForCredit.length > 0 && selectedOrderForCredit.map((dt, dtIndex) => {
                                            return <TableRow
                                                key={`TableDetailRow_${dt.OrderId}`}
                                                tabIndex={-1}
                                                role="checkbox"
                                                selected={false}
                                                aria-checked={false}
                                            >
                                                <TableCell align="center">
                                                    {dt.OrderId ?? ''}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {dt.RouteTitle ?? ''}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {dt.ShopTitle ?? ''}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {dt.TotalPayment ?? ''}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {dt.ResponsiblePersonText ?? ''}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {dt.CreditAmount ?? 0}
                                                </TableCell>

                                                <TableCell align="center">
                                                    <LoadingButton
                                                        size="small"
                                                        type="button"
                                                        variant="contained"
                                                        loading={isLoading}
                                                        style={{ margin: 3, width: 100, backgroundColor: 'red' }}
                                                        startIcon={<Icon icon={closeFill} />}
                                                        onClick={() => {
                                                            removeFromCreditList(dt.OrderId);
                                                        }}
                                                    >
                                                        Remove
                                                    </LoadingButton>
                                                </TableCell>
                                            </TableRow>
                                        })
                                        }
                                    </TableBody>
                                </Table>}
                            </TableContainer>
                        </Scrollbar>}

                        {isAddingCredit == true && <Grid container >
                            <Grid item md={12} style={{ textAlign: 'center', marginTop: 10 }}>
                                <LoadingButton
                                    size="small"
                                    type="button"
                                    variant="contained"
                                    loading={isLoading}
                                    style={{ margin: 3, backgroundColor: 'blue', minWidth: 180, height: 50 }}
                                    startIcon={<Icon icon={saveFill} />}
                                    onClick={onReceiveCashAfterCredit}
                                >
                                    {(!selectedOrderForCredit || selectedOrderForCredit.length <= 0 || !selectedOrderForCredit.find(m => m.CreditAmount > 0)) ? 'Receive Cash With No Credit' : 'Receive Remaining Cash'}
                                </LoadingButton>
                            </Grid>
                        </Grid>}


                        {isAddingCredit == false && <Scrollbar sx={{}}>
                            <TableContainer sx={{ minWidth: 800 }}>
                                <Typography style={{ padding: 10, textAlign: 'center' }} variant='h4'>DO Summary</Typography>
                                {<TableContainer sx={{ minWidth: 800 }}>
                                    <Table>
                                        <UserListHead
                                            order={'asc'}
                                            orderBy={''}
                                            headLabel={TABLE_HEAD_DO_Return_Detail}
                                            rowCount={0}
                                            numSelected={0}
                                            onRequestSort={undefined}
                                            onSelectAllClick={undefined}
                                            customKey={`TableHeadRow_2_Unique`}
                                        />
                                        <TableBody>
                                            {!!selectedDO.DOSummary && selectedDO.DOSummary.length > 0 && selectedDO.DOSummary.map((sRow, sIndex) => {

                                                return (
                                                    <TableRow
                                                        hover
                                                        key={selectedDO.DeliveryOrderId}
                                                        tabIndex={-1}
                                                        role="checkbox"
                                                        selected={false}
                                                        aria-checked={false}
                                                    >
                                                        <TableCell width={10} align="center">
                                                            {sIndex + 1}
                                                        </TableCell>
                                                        <TableCell align="center">{sRow.ProductName}</TableCell>
                                                        <TableCell align="center">{sRow.TotalRetailDeliveredQty}</TableCell>
                                                        <TableCell align="center">
                                                            {sRow.TotalWholeSaleDeliveredQty}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            Rs.{sRow.TotalRetailAmount}/-
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            Rs.{sRow.TotalDeliveryBoySaleAmount}/-
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            Rs.{sRow.TotalWholeSaleAmount}/-
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            Rs.{sRow.TotalRetailAmount + sRow.TotalWholeSaleAmount + sRow.TotalDeliveryBoySaleAmount}/-
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                            <TableRow>
                                                <TableCell colSpan={7} align='right'><strong>Total Amount:</strong></TableCell>
                                                <TableCell align='center'><Typography variant='h6'>Rs.{selectedDO.TotalPayment}/-</Typography></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={7} align='right'><strong>Total Credit:</strong></TableCell>
                                                <TableCell align='center' style={{ color: 'red' }}><Typography variant='h6'>Rs.{totalCredit}/-</Typography></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={7} align='right'><strong>Net Amount:</strong></TableCell>
                                                <TableCell align='center' style={{ color: 'green' }}><Typography variant='h6'>Rs.{selectedDO.TotalPayment - totalCredit}/-</Typography></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>}
                                <Typography style={{ padding: 10, textAlign: 'center' }} variant='h4'>Cash Details</Typography>
                                {<Table size='small'>
                                    <TableBody key={`Table_Body_Selection_Details_Summary`}>
                                        <TableRow
                                            key={`TableDetailRow_SUMMARY`}
                                            tabIndex={-1}
                                            role="checkbox"
                                            selected={false}
                                            aria-checked={false}
                                            style={{ borderBottomWidth: 2, borderBottomStyle: 'solid', borderBottomColor: 'green' }}
                                        >
                                            <TableCell align="right">
                                                <strong>Total DO Amount:&nbsp;&nbsp;&nbsp;</strong><Typography display="inline" variant='h6'>{`Rs.${selectedDO.TotalPayment ?? ''}/-`}</Typography>
                                            </TableCell>

                                            <TableCell align="center" style={{ color: 'red' }}>
                                                <strong>Total Credit:&nbsp;&nbsp;&nbsp;</strong><Typography display="inline" variant='h6'>{`Rs.${totalCredit}/-`}</Typography>
                                            </TableCell>


                                            <TableCell align="left" style={{ color: 'green' }}>
                                                <strong>Net Amount:&nbsp;&nbsp;&nbsp;</strong><Typography display="inline" variant='h6'>{`Rs.${(selectedDO.TotalPayment ?? 0) - totalCredit}/-`}</Typography>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow
                                            hover
                                            key={`TableDetailRow_SUMMARY_1`}
                                            tabIndex={-1}
                                            role="checkbox"
                                            selected={false}
                                            aria-checked={false}
                                        >
                                            <TableCell style={{ paddingTop: 20 }} align="right"><strong>1X</strong></TableCell>
                                            <TableCell style={{ paddingTop: 20 }} align="center">
                                                <TextField
                                                    autoComplete="credit-amount"
                                                    size='small'
                                                    variant='outlined'
                                                    type={'number'}
                                                    label=""
                                                    value={cashDetail.CoinOne > 0 ? cashDetail.CoinOne : ''}
                                                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                        const value = event.target.value;
                                                        let numberValue: number | null = null;

                                                        if (value !== undefined && value != null) {
                                                            numberValue = parseInt(value);
                                                        }

                                                        onCashDetailChange(numberValue, 'CoinOne');
                                                    }}
                                                    inputRef={inputRefs[0]}

                                                onKeyDown={(e: any) => {
                                                    if (e.keyCode === 38) {
                                                        e.preventDefault();
                                                        inputRefs[9].current.focus();
                                                      }else if(e.keyCode === 40){
                                                        e.preventDefault();
                                                        inputRefs[1].current.focus();
                                                      }
                                                }}
                                                />
                                            </TableCell>
                                            <TableCell style={{ paddingTop: 20 }} align='left'>
                                                <strong>Rs.{cashDetail.CoinOne * 1}/-</strong>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow
                                            hover
                                            key={`TableDetailRow_SUMMARY_3`}
                                            tabIndex={-1}
                                            role="checkbox"
                                            selected={false}
                                            aria-checked={false}
                                        >
                                            <TableCell align="right"><strong>2X</strong></TableCell>
                                            <TableCell align="center">
                                                <TextField
                                                    size='small'
                                                    variant='outlined'
                                                    autoComplete="credit-amount"
                                                    type={'number'}
                                                    label=""
                                                    value={cashDetail.CoinTwo > 0 ? cashDetail.CoinTwo : ''}
                                                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                        const value = event.target.value;
                                                        let numberValue: number | null = null;

                                                        if (value !== undefined && value != null) {
                                                            numberValue = parseInt(value);
                                                        }

                                                        onCashDetailChange(numberValue, 'CoinTwo');
                                                    }}

                                                    inputRef={inputRefs[1]}
                                                onKeyDown={(e: any) => {
                                                    if (e.keyCode === 38) {
                                                        e.preventDefault();
                                                        inputRefs[0].current.focus();
                                                      }else if(e.keyCode === 40){
                                                        e.preventDefault();
                                                        inputRefs[2].current.focus();
                                                      }
                                                }}
                                                />
                                            </TableCell>
                                            <TableCell align='left'>
                                                <strong>Rs.{cashDetail.CoinTwo * 2}/-</strong>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow
                                            hover
                                            key={`TableDetailRow_SUMMARY_4`}
                                            tabIndex={-1}
                                            role="checkbox"
                                            selected={false}
                                            aria-checked={false}
                                        >
                                            <TableCell align="right"><strong>5X</strong></TableCell>
                                            <TableCell align="center">
                                                <TextField
                                                    size='small'
                                                    variant='outlined'
                                                    autoComplete="credit-amount"
                                                    type={'number'}
                                                    label=""
                                                    value={cashDetail.CoinFive > 0 ? cashDetail.CoinFive : ''}
                                                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                        const value = event.target.value;
                                                        let numberValue: number | null = null;

                                                        if (value !== undefined && value != null) {
                                                            numberValue = parseInt(value);
                                                        }

                                                        onCashDetailChange(numberValue, 'CoinFive');
                                                    }}

                                                    inputRef={inputRefs[2]}
                                                onKeyDown={(e: any) => {
                                                    if (e.keyCode === 38) {
                                                        e.preventDefault();
                                                        inputRefs[1].current.focus();
                                                      }else if(e.keyCode === 40){
                                                        e.preventDefault();
                                                        inputRefs[3].current.focus();
                                                      }
                                                }}
                                                />
                                            </TableCell>
                                            <TableCell align='left'>
                                                <strong>Rs.{cashDetail.CoinFive * 5}/-</strong>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow
                                            hover
                                            key={`TableDetailRow_SUMMARY_5`}
                                            tabIndex={-1}
                                            role="checkbox"
                                            selected={false}
                                            aria-checked={false}
                                        >

                                            <TableCell align="right"><strong>10X</strong></TableCell>
                                            <TableCell align="center">
                                                <TextField
                                                    size='small'
                                                    variant='outlined'
                                                    autoComplete="credit-amount"
                                                    type={'number'}
                                                    label=""
                                                    value={cashDetail.NoteTen > 0 ? cashDetail.NoteTen : ''}
                                                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                        const value = event.target.value;
                                                        let numberValue: number | null = null;

                                                        if (value !== undefined && value != null) {
                                                            numberValue = parseInt(value);
                                                        }

                                                        onCashDetailChange(numberValue, 'NoteTen');
                                                    }}

                                                    inputRef={inputRefs[3]}
                                                onKeyDown={(e: any) => {
                                                    if (e.keyCode === 38) {
                                                        e.preventDefault();
                                                        inputRefs[2].current.focus();
                                                      }else if(e.keyCode === 40){
                                                        e.preventDefault();
                                                        inputRefs[4].current.focus();
                                                      }
                                                }}
                                                />
                                            </TableCell>
                                            <TableCell align='left'>
                                                <strong>Rs.{cashDetail.NoteTen * 10}/-</strong>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow
                                            hover
                                            key={`TableDetailRow_SUMMARY_6`}
                                            tabIndex={-1}
                                            role="checkbox"
                                            selected={false}
                                            aria-checked={false}
                                        >
                                            <TableCell align="right"><strong>20X</strong></TableCell>
                                            <TableCell align="center">
                                                <TextField
                                                    size='small'
                                                    variant='outlined'
                                                    autoComplete="credit-amount"
                                                    type={'number'}
                                                    label=""
                                                    value={cashDetail.NoteTwenty > 0 ? cashDetail.NoteTwenty : ''}
                                                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                        const value = event.target.value;
                                                        let numberValue: number | null = null;

                                                        if (value !== undefined && value != null) {
                                                            numberValue = parseInt(value);
                                                        }

                                                        onCashDetailChange(numberValue, 'NoteTwenty');
                                                    }}

                                                    inputRef={inputRefs[4]}
                                                onKeyDown={(e: any) => {
                                                    if (e.keyCode === 38) {
                                                        e.preventDefault();
                                                        inputRefs[3].current.focus();
                                                      }else if(e.keyCode === 40){
                                                        e.preventDefault();
                                                        inputRefs[5].current.focus();
                                                      }
                                                }}
                                                />
                                            </TableCell>
                                            <TableCell align='left'>
                                                <strong>Rs.{cashDetail.NoteTwenty * 20}/-</strong>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow
                                            hover
                                            key={`TableDetailRow_SUMMARY_7`}
                                            tabIndex={-1}
                                            role="checkbox"
                                            selected={false}
                                            aria-checked={false}
                                        >
                                            <TableCell align="right"><strong>50X</strong></TableCell>
                                            <TableCell align="center">
                                                <TextField
                                                    size='small'
                                                    variant='outlined'
                                                    autoComplete="credit-amount"
                                                    type={'number'}
                                                    label=""
                                                    value={cashDetail.NoteFifty > 0 ? cashDetail.NoteFifty : ''}
                                                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                        const value = event.target.value;
                                                        let numberValue: number | null = null;

                                                        if (value !== undefined && value != null) {
                                                            numberValue = parseInt(value);
                                                        }

                                                        onCashDetailChange(numberValue, 'NoteFifty');
                                                    }}

                                                    inputRef={inputRefs[5]}
                                                onKeyDown={(e: any) => {
                                                    if (e.keyCode === 38) {
                                                        e.preventDefault();
                                                        inputRefs[4].current.focus();
                                                      }else if(e.keyCode === 40){
                                                        e.preventDefault();
                                                        inputRefs[6].current.focus();
                                                      }
                                                }}
                                                />
                                            </TableCell>
                                            <TableCell align='left'>
                                                <strong>Rs.{cashDetail.NoteFifty * 50}/-</strong>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow
                                            hover
                                            key={`TableDetailRow_SUMMARY_8`}
                                            tabIndex={-1}
                                            role="checkbox"
                                            selected={false}
                                            aria-checked={false}
                                        >
                                            <TableCell align="right"><strong>100X</strong></TableCell>
                                            <TableCell align="center">
                                                <TextField
                                                    size='small'
                                                    variant='outlined'
                                                    autoComplete="credit-amount"
                                                    type={'number'}
                                                    label=""
                                                    value={cashDetail.NoteHundred > 0 ? cashDetail.NoteHundred : ''}
                                                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                        const value = event.target.value;
                                                        let numberValue: number | null = null;

                                                        if (value !== undefined && value != null) {
                                                            numberValue = parseInt(value);
                                                        }

                                                        onCashDetailChange(numberValue, 'NoteHundred');
                                                    }}

                                                    inputRef={inputRefs[6]}
                                                onKeyDown={(e: any) => {
                                                    if (e.keyCode === 38) {
                                                        e.preventDefault();
                                                        inputRefs[5].current.focus();
                                                      }else if(e.keyCode === 40){
                                                        e.preventDefault();
                                                        inputRefs[7].current.focus();
                                                      }
                                                }}
                                                />
                                            </TableCell>
                                            <TableCell align='left'>
                                                <strong>Rs.{cashDetail.NoteHundred * 100}/-</strong>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow
                                            hover
                                            key={`TableDetailRow_SUMMARY_9`}
                                            tabIndex={-1}
                                            role="checkbox"
                                            selected={false}
                                            aria-checked={false}
                                        >

                                            <TableCell align="right"><strong>500X</strong></TableCell>
                                            <TableCell align="center">
                                                <TextField
                                                    size='small'
                                                    variant='outlined'
                                                    autoComplete="credit-amount"
                                                    type={'number'}
                                                    label=""
                                                    value={cashDetail.NoteFiveHundred > 0 ? cashDetail.NoteFiveHundred : ''}
                                                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                        const value = event.target.value;
                                                        let numberValue: number | null = null;

                                                        if (value !== undefined && value != null) {
                                                            numberValue = parseInt(value);
                                                        }

                                                        onCashDetailChange(numberValue, 'NoteFiveHundred');
                                                    }}
                                                    inputRef={inputRefs[7]}
                                                onKeyDown={(e: any) => {
                                                    if (e.keyCode === 38) {
                                                        e.preventDefault();
                                                        inputRefs[6].current.focus();
                                                      }else if(e.keyCode === 40){
                                                        e.preventDefault();
                                                        inputRefs[8].current.focus();
                                                      }
                                                }}
                                                />
                                            </TableCell>
                                            <TableCell align='left'>
                                                <strong>Rs.{cashDetail.NoteFiveHundred * 500}/-</strong>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow
                                            hover
                                            key={`TableDetailRow_SUMMARY_10`}
                                            tabIndex={-1}
                                            role="checkbox"
                                            selected={false}
                                            aria-checked={false}
                                        >
                                            <TableCell align="right"><strong>1000X</strong></TableCell>
                                            <TableCell align="center">
                                                <TextField
                                                    size='small'
                                                    variant='outlined'
                                                    autoComplete="credit-amount"
                                                    type={'number'}
                                                    label=""
                                                    value={cashDetail.NoteThousand > 0 ? cashDetail.NoteThousand : ''}
                                                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                        const value = event.target.value;
                                                        let numberValue: number | null = null;

                                                        if (value !== undefined && value != null) {
                                                            numberValue = parseInt(value);
                                                        }

                                                        onCashDetailChange(numberValue, 'NoteThousand');
                                                    }}
                                                    inputRef={inputRefs[8]}
                                                onKeyDown={(e: any) => {
                                                    if (e.keyCode === 38) {
                                                        e.preventDefault();
                                                        inputRefs[7].current.focus();
                                                      }else if(e.keyCode === 40){
                                                        e.preventDefault();
                                                        inputRefs[9].current.focus();
                                                      }
                                                }}
                                                />
                                            </TableCell>
                                            <TableCell align='left'>
                                                <strong>Rs.{cashDetail.NoteThousand * 1000}/-</strong>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow
                                            hover
                                            key={`TableDetailRow_SUMMARY_11`}
                                            tabIndex={-1}
                                            role="checkbox"
                                            selected={false}
                                            aria-checked={false}
                                        >
                                            <TableCell align="right"><strong>5000X</strong></TableCell>
                                            <TableCell align="center">
                                                <TextField
                                                    size='small'
                                                    variant='outlined'
                                                    autoComplete="credit-amount"
                                                    type={'number'}
                                                    label=""
                                                    value={cashDetail.NoteFiveThousand > 0 ? cashDetail.NoteFiveThousand : ''}
                                                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                        const value = event.target.value;
                                                        let numberValue: number | null = null;

                                                        if (value !== undefined && value != null) {
                                                            numberValue = parseInt(value);
                                                        }

                                                        onCashDetailChange(numberValue, 'NoteFiveThousand');
                                                    }}
                                                    inputRef={inputRefs[9]}
                                                onKeyDown={(e: any) => {
                                                    if (e.keyCode === 38) {
                                                        e.preventDefault();
                                                        inputRefs[8].current.focus();
                                                      }else if(e.keyCode === 40){
                                                        e.preventDefault();
                                                        inputRefs[0].current.focus();
                                                      }
                                                }}
                                                />
                                            </TableCell>
                                            <TableCell align='left'>
                                                <strong>Rs.{cashDetail.NoteFiveThousand * 5000}/-</strong>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow
                                            hover
                                            key={`TableDetailRow_SUMMARY_11`}
                                            tabIndex={-1}
                                            role="checkbox"
                                            selected={false}
                                            aria-checked={false}
                                        >
                                            <TableCell colSpan={3} align='right'>
                                                <Typography style={{marginRight:10, color:'green'}} display={'inline'} variant='h6'>Amount To Receive: {fCurrency((selectedDO.TotalPayment - totalCredit))}</Typography>
                                                <Typography style={{color:(selectedDO.TotalPayment - totalCredit) - enteredCashAmount == 0 ? 'green' : ((selectedDO.TotalPayment - totalCredit) - enteredCashAmount < 0 ? 'red' : 'blue'),marginRight:10}} display={'inline'} variant='h6'>Remaining Cash: Rs.{(selectedDO.TotalPayment - totalCredit) - enteredCashAmount}/-</Typography>
                                                <Typography style={{ color: enteredCashAmount == (selectedDO.TotalPayment - totalCredit) ? 'green' : 'red' }} display={'inline'} variant='h6'>Total Entered Cash: Rs.{enteredCashAmount}/-</Typography>{enteredCashAmount == (selectedDO.TotalPayment - totalCredit) && <Icon icon="teenyicons:tick-circle-solid" style={{ marginLeft: 10 }} color='green' width={30} height={30} />}{enteredCashAmount != (selectedDO.TotalPayment - totalCredit) && <Icon style={{ marginLeft: 10 }} color="red" height={30} width={30} icon={"ep:circle-close-filled"} />}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>}
                            </TableContainer>
                        </Scrollbar>}




                    </Card>

                    {isAddingCredit == false && <Container style={{ paddingTop: 10, paddingBottom: 10 }}>
                        <Grid container >
                            <Grid item md={12} style={{ textAlign: 'center' }}>
                                <LoadingButton
                                    size="small"
                                    type="button"
                                    variant="contained"
                                    loading={isLoading}
                                    style={{ margin: 3, backgroundColor: 'blue', width: 180, height: 50 }}
                                    startIcon={<Icon icon={saveFill} />}
                                    onClick={saveCashDetail}
                                >
                                    Save
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Container>}

                </Container>}

                {isViewingSummary == true && <Container style={{ maxWidth: 1500, paddingLeft: 0, paddingRight: 0 }}>
                    <Grid container style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>

                        <LoadingButton
                            size="small"
                            type="button"
                            variant="contained"
                            loading={isLoading}
                            style={{ margin: 3, backgroundColor: 'rgba(34, 154, 22, 0.72)', textAlign: 'right', height: 40 }}
                            startIcon={<Icon icon={refreshFill} />}
                            onClick={getDeliveryOrders}
                        >
                            Refresh
                        </LoadingButton>

                        <LoadingButton
                            size="small"
                            type="button"
                            variant="contained"
                            loading={isLoading}
                            style={{ margin: 3, backgroundColor: 'blue', textAlign: 'right', height: 40 }}
                            startIcon={<Icon icon='ep:van' />}
                            onClick={onViewDOClick}
                        >
                            View DO
                        </LoadingButton></Grid>


                        {props.selectedUnits.UnitAccesses.filter(u => u.UnitUniqueId != AllOptionUniqueId).length > 0 && props.selectedUnits.UnitAccesses.filter(u => u.UnitUniqueId != AllOptionUniqueId).map((uForS,uForSIndex) => {
                            const uTotals = getUnitTotals(uForS.UnitUniqueId);
                        return <><Card>
                        <Typography style={{ backgroundColor: 'rgba(34, 154, 22, 0.72)', color: 'white', paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h4'}>Cash Summary For {uForS.UnitTitle}</Typography>
                        {isLoading == true && <AudoSpin
                            height="80"
                            width="80"
                            color='green'
                            ariaLabel='three-dots-loading'
                            wrapperStyle={{ alignSelf: 'center', justifyContent: 'center' }}
                        />}

                        {(!doDetail || !doDetail.UnitWiseSummary || doDetail.UnitWiseSummary.length <= 0 || doDetail.UnitWiseSummary.filter(u => u.UnitUniqueId == uForS.UnitUniqueId).length <= 0) && isLoading == false && <Typography style={{ paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h6'}>No Summary For {uForS.UnitTitle} Today</Typography>}

                        {!!doDetail && !!doDetail.UnitWiseSummary && doDetail.UnitWiseSummary.length > 0 && doDetail.UnitWiseSummary.filter(u => u.UnitUniqueId == uForS.UnitUniqueId).length > 0 && <Scrollbar sx={{}}>
                            <TableContainer sx={{ minWidth: 800 }}>
                                <Table>
                                    <UserListHead
                                        order={'asc'}
                                        orderBy={''}
                                        headLabel={TABLE_HEAD_DO_Return_Detail}
                                        rowCount={0}
                                        numSelected={0}
                                        onRequestSort={undefined}
                                        onSelectAllClick={undefined}
                                        customKey={`TableHeadRow_2_Unique`}
                                    />
                                    <TableBody>
                                        {!!doDetail && !!doDetail.UnitWiseSummary && doDetail.UnitWiseSummary.length > 0 && doDetail.UnitWiseSummary.filter(u => u.UnitUniqueId == uForS.UnitUniqueId).length > 0 && doDetail.UnitWiseSummary.filter(u => u.UnitUniqueId == uForS.UnitUniqueId).map((sRow, sIndex) => {

                                            return (
                                                <TableRow
                                                    hover
                                                    key={sRow.ProductId}
                                                    tabIndex={-1}
                                                    role="checkbox"
                                                    selected={false}
                                                    aria-checked={false}
                                                >
                                                    <TableCell width={10} align="center">
                                                        {sIndex + 1}
                                                    </TableCell>
                                                    <TableCell align="center">{sRow.ProductName}</TableCell>
                                                    <TableCell align="center">{sRow.TotalRetailDeliveredQty}</TableCell>
                                                    <TableCell align="center">
                                                        {sRow.TotalWholeSaleDeliveredQty}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        Rs.{sRow.TotalRetailAmount}/-
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        Rs.{sRow.TotalDeliveryBoySaleAmount}/-
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        Rs.{sRow.TotalWholeSaleAmount}/-
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        Rs.{sRow.TotalRetailAmount + sRow.TotalWholeSaleAmount + sRow.TotalDeliveryBoySaleAmount}/-
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                        <TableRow>
                                            <TableCell colSpan={7} align='right'><strong>Total Amount:</strong></TableCell>
                                            <TableCell align='center'><Typography variant='h6'>{fCurrency(uTotals.totalAmount)}</Typography></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={7} align='right'><strong>Total Credit:</strong></TableCell>
                                            <TableCell align='center' style={{ color: 'red' }}><Typography variant='h6'>{fCurrency(uTotals.totalCredit)}</Typography></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={7} align='right'><strong>Net Amount:</strong></TableCell>
                                            <TableCell align='center' style={{ color: 'green' }}><Typography variant='h6'>{fCurrency(uTotals.netAmount)}</Typography></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Scrollbar>}
                    </Card><br /></>})}



                    {props.selectedUnits.UnitAccesses.filter(u => u.UnitUniqueId != AllOptionUniqueId).length > 1 && <Card>
                        <Typography style={{ backgroundColor: 'rgba(34, 154, 22, 0.72)', color: 'white', paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h4'}>Cash Summary</Typography>
                        {isLoading == true && <AudoSpin
                            height="80"
                            width="80"
                            color='green'
                            ariaLabel='three-dots-loading'
                            wrapperStyle={{ alignSelf: 'center', justifyContent: 'center' }}
                        />}

                        {(!doDetail || !doDetail.CreditWithProductWiseSummary || !doDetail.CreditWithProductWiseSummary.ProductWiseSummaries || doDetail.CreditWithProductWiseSummary.ProductWiseSummaries.length <= 0) && isLoading == false && <Typography style={{ paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h6'}>No Summary For Today</Typography>}

                        {!!doDetail && !!doDetail.CreditWithProductWiseSummary && !!doDetail.CreditWithProductWiseSummary.ProductWiseSummaries && doDetail.CreditWithProductWiseSummary.ProductWiseSummaries.length > 0 && <Scrollbar sx={{}}>
                            <TableContainer sx={{ minWidth: 800 }}>
                                <Table>
                                    <UserListHead
                                        order={'asc'}
                                        orderBy={''}
                                        headLabel={TABLE_HEAD_DO_Return_Detail}
                                        rowCount={0}
                                        numSelected={0}
                                        onRequestSort={undefined}
                                        onSelectAllClick={undefined}
                                        customKey={`TableHeadRow_2_Unique`}
                                    />
                                    <TableBody>
                                        {!!doDetail && !!doDetail.CreditWithProductWiseSummary && !!doDetail.CreditWithProductWiseSummary.ProductWiseSummaries && doDetail.CreditWithProductWiseSummary.ProductWiseSummaries.length > 0 && doDetail.CreditWithProductWiseSummary.ProductWiseSummaries.map((sRow, sIndex) => {

                                            return (
                                                <TableRow
                                                    hover
                                                    key={sRow.ProductId}
                                                    tabIndex={-1}
                                                    role="checkbox"
                                                    selected={false}
                                                    aria-checked={false}
                                                >
                                                    <TableCell width={10} align="center">
                                                        {sIndex + 1}
                                                    </TableCell>
                                                    <TableCell align="center">{sRow.ProductName}</TableCell>
                                                    <TableCell align="center">{sRow.TotalRetailDeliveredQty}</TableCell>
                                                    <TableCell align="center">
                                                        {sRow.TotalWholeSaleDeliveredQty}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        Rs.{sRow.TotalRetailAmount}/-
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        Rs.{sRow.TotalDeliveryBoySaleAmount}/-
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        Rs.{sRow.TotalWholeSaleAmount}/-
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        Rs.{sRow.TotalRetailAmount + sRow.TotalWholeSaleAmount + sRow.TotalDeliveryBoySaleAmount}/-
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                        <TableRow>
                                            <TableCell colSpan={7} align='right'><strong>Total Amount:</strong></TableCell>
                                            <TableCell align='center'><Typography variant='h6'>{fCurrency(summaryTotalAmount)}</Typography></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={7} align='right'><strong>Total Credit:</strong></TableCell>
                                            <TableCell align='center' style={{ color: 'red' }}><Typography variant='h6'>{fCurrency(creditTotalAmount)}</Typography></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={7} align='right'><strong>Net Amount:</strong></TableCell>
                                            <TableCell align='center' style={{ color: 'green' }}><Typography variant='h6'>{fCurrency(summaryTotalAmount - creditTotalAmount)}</Typography></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Scrollbar>}
                    </Card>}
                </Container>}
            </Page>
        </>: <NoUnitSelectedComponent pageTitle='DO | Raza Pak Care' />
    );

    //#endregion
}

const mapStateToProps = (state:IApplicationState) =>{
    return {
        selectedUnits:state.selectedUnitAccesses,
        loginUser:state.loginUser
      };
}
const connectedReceiveCash = connect(mapStateToProps)(ReceiveCash);
export {connectedReceiveCash as ReceiveCash};
