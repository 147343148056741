import {UserRoutesConstatns} from '../actions/action.constant';
import {IAction} from '../actions/action.interface';
import {IUserRouteDetail} from '../../interfaces/routes/user-route-detail.interface';
import {initialState} from '../initial-state';

export const userRoutesReducer = (
  state = initialState.userRoutes,
  action: IAction<IUserRouteDetail | null>,
) => {
  switch (action.type) {
    case UserRoutesConstatns.SET_USER_ROUTES:
      let newState: IUserRouteDetail = {...action.payload};
      return newState;
    default:
      return state;
  }
};
