import React from 'react';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import searchFill from '@iconify/icons-eva/search-fill';
import refreshFill from '@iconify/icons-eva/refresh-fill';
import printerFill from '@iconify/icons-eva/printer-fill';
import plusSquareOutline from '@iconify/icons-eva/plus-square-outline';
import minusSquareOutline from '@iconify/icons-eva/minus-square-outline';
// material
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Grid,
  TextField,
} from '@mui/material';
// components
import Page from '../Page';
import Scrollbar from '../Scrollbar';
import { UserListHead } from '../_dashboard/user';
//
import USERLIST from '../../_mocks_/user';
import { CustomAutoComplete } from '../common/CustomAutoComplete';
import CustomDatePicker from '../common/CustomDatePicker';
import { LoadingButton } from '@mui/lab';
import { orderApi, employeeApi, routeApi } from '../../http';
import { IProductOrder } from 'src/interfaces/product-order.interface';
import { IAutoComplete } from 'src/interfaces/auto-complete.interface';
import { IEnum } from 'src/interfaces';
import { formattedDate, getCommaSeparatedUnitUniqueIdsExceptAll } from 'src/utility';
import { base64ToBlob } from 'src/utility/base64.util';
import { downloadFile } from 'src/utility/file.util';
import { useSelector } from 'react-redux';
import { IApplicationState } from 'src/redux';
import { isRestrictedLogin, NumberOfPreviousMonth } from 'src/app.constant';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: '', label: '', alignContent: 'center' },
  { id: 'sr', label: 'Sr.', alignContent: 'center' },
  { id: 'order_id', label: 'ID', alignContent: 'center' },
  { id: 'shop_name', label: 'Shop Name', alignContent: 'center' },
  { id: 'shop_address', label: 'Shop Address', alignContent: 'center' },
  { id: 'order_booker', label: 'Order Booker', alignContent: 'center' },
  { id: 'order_date', label: 'Ordered On', alignContent: 'center' },
  { id: 'total_qty', label: 'Total Qty', alignContent: 'center' },
  { id: 'order_status', label: 'Order Status', alignContent: 'center' }
];

const TABLE_DETAIL_HEAD = [
  { id: 'sr', label: 'Sr.', alignContent: 'center' },
  { id: 'product_name', label: 'Product Name', alignContent: 'center' },
  { id: 'product_qty', label: 'Product Qty', alignContent: 'center' },
  { id: 'delievered_qty', label: 'Delievered Qty', alignContent: 'center' },
  { id: 'delievered_by', label: 'Delievered By', alignContent: 'center' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export const OrdersOldComponent = () => {
  const [orders, setOrders] = React.useState<IProductOrder[]>([]);
  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const [totalPiece, setTotalPiece] = React.useState<number>(0);

  const [totalRouteShops, setTotalRouteShops] = React.useState<number>(0);
  const [totalOrders, setTotalOrders] = React.useState<number>(0);
  const [totalNoOrders, setTotalNoOrders] = React.useState<number>(0);

  const [orderNumber, setOrderNumber] = useState<number | null>(null);
  const [orderBookerId, setOrderBookerId] = useState<number | undefined>(undefined);
  const [routeId, setRouteId] = useState<number | undefined>(undefined);
  const [shopId, setShopId] = useState<number | undefined>(undefined);
  const [fromDate, setFromDate] = useState<Date | null>(new Date());
  const [toDate, setToDate] = useState<Date | null>(new Date());
  const [orderStatusId, setOrderStatusId] = useState<number | undefined>(undefined);

  const [orderBookers, setOrderBookers] = useState<IAutoComplete[]>([]);
  const [routes, setRoutes] = useState<IAutoComplete[]>([]);
  const [shops, setShops] = useState<IAutoComplete[]>([]);
  const [orderStatuses, setOrderStatuses] = useState<IEnum[]>([]);
  const state = useSelector((s: IApplicationState) => s);


  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    loadResults(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    loadResults(0, parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalRecords) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  const getOrderBookers = () => {
    employeeApi.getOrderBookers(state.loginUser?.UserId ?? 0, getCommaSeparatedUnitUniqueIdsExceptAll(state.selectedUnitAccesses?.UnitAccesses ?? []))
      .then(bookers => {
        setOrderBookers([...bookers]);
      })
      .catch(e => console.log(e));
  };

  const getOrderStatuses = () => {
    orderApi.getOrderStatuses()
      .then(statuses => {
        setOrderStatuses([...statuses]);
      })
      .catch(e => console.log(e));
  };

  const getEmployeeRoutes = (employeeId: number | null | undefined) => {
    employeeApi.getEmployeeRoutes(employeeId)
      .then(employeeRoutes => {
        setRoutes([...employeeRoutes]);
      })
      .catch(e => console.log(e));
  };

  const getRouteShops = (routeId: number) => {
    routeApi.getRouteShops(routeId)
      .then(routeShops => {
        setShops([...routeShops]);
      })
      .catch(e => console.log(e));
  };

  const onOrderBookerChange = (e: IAutoComplete) => {
    if (!!e) {
      setOrderBookerId(e.Value);
      setRouteId(undefined);
      setRoutes([]);
      setShops([]);
      setShopId(undefined);
      getEmployeeRoutes(e.Value);
    } else {
      setOrderBookerId(undefined);
      setRouteId(undefined);
      setRoutes([]);
      setShops([]);
      setShopId(undefined);
      getEmployeeRoutes(undefined);
    }
  }

  const onRouteChange = (e: IAutoComplete) => {
    if (!!e) {
      setRouteId(e.Value);
      setShopId(undefined);
      setShops([]);
      getRouteShops(e.Value);
    } else {
      setRouteId(undefined);
      setShopId(undefined);
      setShops([]);
    }
  }

  const resetFilters = () => {
    setOrderNumber(null);
    setOrderBookerId(undefined);
    setRouteId(undefined);
    setShopId(undefined);
    setFromDate(null);
    setToDate(null);
    setOrderStatusId(undefined);

    setRoutes([]);
    setShops([]);
    getEmployeeRoutes(undefined);
  }

  const loadResults = (pNumber: number, pSize: number) => {
    setIsLoading(true);
    setOrders([]);
    setTotalRecords(0);
    setTotalPiece(0);
    setTotalRouteShops(0);
    setTotalOrders(0);
    setTotalNoOrders(0);

    orderApi.getOrders(orderNumber, orderBookerId, routeId, shopId, fromDate, toDate, orderStatusId, pNumber, pSize)
      .then(results => {
        setIsLoading(false);
        setPage(pNumber);
        setRowsPerPage(pSize);
        if (!!results) {
          setTotalRecords(results.TotalRecords);
          setTotalPiece(results.TotalPiece);
          setTotalRouteShops(results.TotalRouteShops);
          setTotalOrders(results.TotalOrders);
          setTotalNoOrders(results.TotalNoOrders);

          if (!!results.Data && results.Data.length > 0) {
            results.Data.forEach(o => {
              o.IsCollapsed = true;
            });
          }

          setOrders([...results.Data]);
        }
      })
      .catch(e => {
        setIsLoading(false);
      })
  }

  const handleSearchClick = () => {
    setPage(0);
    loadResults(0, rowsPerPage);
  }

  const downloadInvoicesFile = () => {
    setIsLoading(true);
    orderApi.downloadInvoicesZip(orderNumber, orderBookerId, routeId, shopId, fromDate, toDate, orderStatusId)
      .then(result => {
        setIsLoading(false);
        const zipBlob = base64ToBlob(result);
        downloadFile(zipBlob, `Invoices-${formattedDate(new Date(), 'DD-MMM-YYYY')}.docx`);
      })
      .catch(e => {
        setIsLoading(false);
        console.log(e);
      })
  }

  const handleCollapseChanged = (rowIndex: number) => {
    if (!!orders && orders.length > 0) {
      const modifiedOrders = orders.map((value: IProductOrder, index: number) => {
        value.IsCollapsed = rowIndex == index ? !value.IsCollapsed : value.IsCollapsed;
        return value;
      });

      setOrders([...modifiedOrders]);
    }
  }

  const userId = useSelector((st: IApplicationState) => st.loginUser.UserId);
  const tDated = new Date();

  React.useEffect(() => {
    getOrderBookers();
    getOrderStatuses();
    getEmployeeRoutes(undefined);
    loadResults(0, rowsPerPage);
  }, []);

  return (
    <Page title="Orders | Raza Pak Care">
      <Container style={{ maxWidth: 1500, paddingLeft: 0, paddingRight: 0 }}>
        <Card>
          <Typography style={{ backgroundColor: 'rgba(34, 154, 22, 0.72)', color: 'white', paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h4'}>Orders</Typography>

          <Container style={{ paddingTop: 20, paddingBottom: 10 }}>
            <Grid container spacing={2}>
              <Grid item md={4} sm={12}><TextField
                fullWidth
                autoComplete="order-number"
                type={'number'}
                label="Order Number"
                value={orderNumber > 0 ? orderNumber : ''}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                  const value = event.target.value;
                  let numberValue: number | null = null;

                  if (value !== undefined && value != null) {
                    numberValue = parseInt(value);
                  }

                  setOrderNumber(numberValue);
                }}
              /></Grid>

              <Grid item md={4} sm={12}><CustomAutoComplete<IAutoComplete>
                Id={'Order_Booker_List'}
                Value={!!orderBookers && orderBookers.length > 0 && orderBookerId > 0 ? orderBookers.filter(m => m.Value == orderBookerId)[0] : null}
                Options={orderBookers}
                displayField={'DisplayText'}
                valueField={'Value'}
                Label={'Order Booker'}
                onChange={onOrderBookerChange}
              /></Grid>

              <Grid item md={4} sm={12}>
                <CustomAutoComplete<IAutoComplete>
                  Id={'Route_List'}
                  Value={!!routes && routes.length > 0 && routeId > 0 ? routes.filter(m => m.Value == routeId)[0] : null}
                  Options={routes}
                  displayField={'DisplayText'}
                  valueField={'Value'}
                  Label={'Route'}
                  onChange={onRouteChange}
                />
              </Grid>

              <Grid item md={4} sm={12}>
                <CustomAutoComplete<IAutoComplete>
                  Id={'Shop_List'}
                  Value={!!shops && shops.length > 0 && shopId > 0 ? shops.filter(m => m.Value == shopId)[0] : null}
                  Options={shops}
                  displayField={'DisplayText'}
                  valueField={'Value'}
                  Label={'Shop'}
                  onChange={(e: IAutoComplete) => {
                    if (!!e) {
                      setShopId(e.Value);
                    } else {
                      setShopId(undefined);
                    }
                  }}
                />
              </Grid>
              <Grid item md={4} sm={12}>
                <CustomDatePicker
                  label='From Date'
                  value={fromDate}
                  onChange={(v: Date) => {
                    console.clear();
                    console.log(v);
                    setFromDate(v);
                  }}

                  minDate={isRestrictedLogin(userId) ? (new Date(tDated.getFullYear(), tDated.getMonth() - NumberOfPreviousMonth, 1)) : undefined}
                  inputDisable={true}
                />
              </Grid>

              <Grid item md={4} sm={12}>
                <CustomDatePicker
                  label='To Date'
                  value={toDate}
                  onChange={(v: Date) => {
                    setToDate(v);
                  }}
                  minDate={isRestrictedLogin(userId) ? (new Date(tDated.getFullYear(), tDated.getMonth() - NumberOfPreviousMonth, 1)) : undefined}
                  inputDisable={true}
                />
              </Grid>

              <Grid item md={4} sm={12}>
                <CustomAutoComplete<IEnum>
                  Id={'Order_Status_List'}
                  Value={!!orderStatuses && orderStatuses.length > 0 && orderStatusId > 0 ? orderStatuses.filter(m => m.Id == orderStatusId)[0] : null}
                  Options={orderStatuses}
                  displayField={'TypeDescription'}
                  valueField={'Id'}
                  Label={'Order Status'}
                  onChange={(option: IEnum) => {
                    if (!!option) {
                      setOrderStatusId(option.Id);
                    } else {
                      setOrderStatusId(undefined);
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: 20 }} >

              <Grid item md={12} style={{ margin: 5, textAlign: 'right' }}>
                <LoadingButton
                  size="small"
                  type="button"
                  variant="contained"
                  loading={isLoading}
                  style={{ margin: 3, width: 100 }}
                  startIcon={<Icon icon={searchFill} />}
                  onClick={handleSearchClick}
                >
                  Search
                </LoadingButton>
                <LoadingButton
                  size="small"
                  type="button"
                  variant="contained"
                  loading={false}
                  onClick={resetFilters}
                  style={{ margin: 3, backgroundColor: 'red', width: 100 }}
                  startIcon={<Icon icon={refreshFill} />}
                >
                  Reset
                </LoadingButton>

                <LoadingButton
                  size="small"
                  type="button"
                  variant="contained"
                  loading={isLoading}
                  style={{ margin: 3, backgroundColor: 'blue', width: 180 }}
                  startIcon={<Icon icon={printerFill} />}
                  onClick={downloadInvoicesFile}
                >
                  Download Invoices
                </LoadingButton>

              </Grid>
            </Grid>
          </Container>

          <hr style={{ backgroundColor: 'whitesmoke' }} />
          <Grid container spacing={2}>
            {totalRouteShops > 0 && <Grid item md={2} style={{ paddingLeft: 30 }}>
              <Typography><strong>Shops: {totalRouteShops}</strong></Typography>
            </Grid>}
            <Grid item md={totalRouteShops > 0 ? 2 : 3} style={{ textAlign: 'right', paddingRight: 20 }}>
              <Typography><strong>Visited: {totalOrders + totalNoOrders}</strong></Typography>
            </Grid>
            <Grid item md={3} style={{ textAlign: 'right', paddingRight: 20 }}>
              <Typography><strong>Orders: {totalOrders}</strong></Typography>
            </Grid>
            <Grid item md={3} style={{ textAlign: 'right', color: 'red', paddingRight: 20 }}>
              <Typography><strong>No Orders: {totalNoOrders}</strong></Typography>
            </Grid>
            <Grid item md={totalRouteShops > 0 ? 2 : 3} style={{ textAlign: 'right', paddingRight: 20 }}>
              <Typography><strong>Pieces: {totalPiece}</strong></Typography>
            </Grid>
          </Grid>
          <hr style={{ backgroundColor: 'whitesmoke' }} />
          <Scrollbar sx={{}}>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  customKey={`TableHeadRow_1`}
                />
                <TableBody>
                  {orders.map((row, index) => {
                    const isItemSelected = false;

                    return (
                      <><TableRow
                        hover
                        key={row.Id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell align="right"><Icon style={{ cursor: 'pointer' }} icon={!!row.IsCollapsed ? plusSquareOutline : minusSquareOutline} fontSize={15} onClick={() => {
                          handleCollapseChanged(index);
                        }} /></TableCell>
                        <TableCell align="center">{index + (rowsPerPage * (page)) + 1}</TableCell>
                        <TableCell align="center">{row.Id}</TableCell>
                        <TableCell align="center" component="th" scope="row" padding="none">
                          {row?.ShopDetails?.ShopTitle ?? ''}
                        </TableCell>
                        <TableCell align="center">
                          {row?.ShopDetails?.ShopAddress ?? ''}
                        </TableCell>
                        <TableCell align="center">
                          {row.OrderBy}
                        </TableCell>
                        <TableCell align="center">
                          {formattedDate(row.OrderedOn, 'DD-MMM-YYYY hh:mm A')}
                        </TableCell>
                        <TableCell align="center">
                          {row.TotalQty}
                        </TableCell>
                        <TableCell align="center">
                          {row.OrderStatus}
                        </TableCell>
                      </TableRow>

                        {row.OrderDetails != null && row.OrderDetails !== undefined && row.OrderDetails.length > 0 && !row.IsCollapsed && <TableRow key={`Row_Detail_${row.Id}`}><TableCell colSpan={9}><TableContainer sx={{ minWidth: 800, paddingLeft: 5, paddingRight: 5 }}>
                          <Table sx={{ backgroundColor: '#F5F5F5' }}>
                            <UserListHead
                              order={order}
                              key={`Table_${row.Id}`}
                              customKey={`TableDetailHeadRow_${row.Id}`}
                              orderBy={orderBy}
                              headLabel={TABLE_DETAIL_HEAD}
                              rowCount={0}
                              numSelected={selected.length}
                              onRequestSort={handleRequestSort}
                              onSelectAllClick={handleSelectAllClick}
                            />
                            <TableBody key={`Table_Body_${row.Id}`}>
                              {row.OrderDetails.map((dt, dtIndex) => {
                                return <TableRow
                                  key={`TableDetailRow_${row.Id}_${dt.Id}`}
                                  tabIndex={-1}
                                  role="checkbox"
                                  selected={isItemSelected}
                                  aria-checked={isItemSelected}
                                >
                                  <TableCell align="center">{dtIndex + 1}</TableCell>
                                  <TableCell align="center" component="th" scope="row" padding="none">
                                    {dt.ProductName ?? ''}
                                  </TableCell>
                                  <TableCell align="center">
                                    {dt.ProductQty ?? ''}
                                  </TableCell>
                                  <TableCell align="center">
                                    {dt.DelieveredQty ?? 0}
                                  </TableCell>
                                  <TableCell align="center">
                                    {dt.DelieveredBy}
                                  </TableCell>
                                </TableRow>
                              })
                              }
                            </TableBody>
                          </Table>
                        </TableContainer></TableCell></TableRow>}
                      </>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={10} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 15, 25, 50, 100]}
            component="div"
            count={totalRecords}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
