import { Grid } from '@mui/material';
import React from 'react';
import { IOrderNew } from 'src/interfaces/reports/order-new.interface';
import { formattedDate } from 'src/utility';
import { fCommaSeparatedWithDecial, fCurrency } from 'src/utils/formatNumber';

interface IProps {
    lastMonthTarget:IOrderNew;
    currentMonthTarget:IOrderNew;
    currentDayTarget:IOrderNew;
    dated:Date;
    lastMonthFromDate:Date;
    lastMonthToDate:Date;
    currentMonthFromDate:Date;
    currentMonthToDate:Date;
}

export const TargetInfoComponent = (props: IProps) => {
    const pendingCreditColor = 'red';
    const achievedBackground = 'rgba(51, 170, 51, .4)';
    const remainingBackground = 'rgba(255, 0, 0, .4)';

    return <span>
        <Grid container>
            <Grid item md={12}>
                {<Grid container>
                    <table border={1} cellSpacing={0} cellPadding={5} style={{ width: '100%', fontSize: 11, marginBottom: 10, marginTop: 10 }}>
                        <tbody>
                            <tr>
                                <th style={{ padding: 7, color:'blue' }} colSpan={9} align={'center'}>{`Target Achievement (${props.currentDayTarget?.FirstName ?? ''} ${props.currentDayTarget?.LastName ?? ''})`}</th>
                            </tr>
                            <tr>
                                <th style={{ padding: 7, borderBottom:'2px solid' }} colSpan={2} align={'center'}>{'Credit Limit'}</th>
                                <th style={{ padding: 7, borderBottom:'2px solid' }} colSpan={2} align={'center'}>{0}</th>
                                <th style={{ padding: 7, borderBottom:'2px solid' }} colSpan={2} align={'center'}>{'Pending Credit'}</th>
                                <th style={{ padding: 7, borderBottom:'2px solid black', color:pendingCreditColor }} colSpan={3} align={'center'}>{fCommaSeparatedWithDecial(props.currentDayTarget?.EmployeePendingCredit ?? 0)}</th>
                            </tr>
                            <tr>
                                <td colSpan={9} style={{border:'none', borderRight:'1px solid', borderRightColor:'white'}}>&nbsp;</td>
                            </tr>
                            <tr>
                                <th style={{ padding: 7 }} colSpan={3} align={'center'}>Last Month Target Achievement <br /> ({formattedDate(props.lastMonthFromDate,'DD-MMM-YYYY')} to {formattedDate(props.lastMonthToDate, 'DD-MMM-YYYY')})</th>
                                <th style={{ padding: 7 }} colSpan={3} align={'center'}>Current Month Target Achievement <br /> ({formattedDate(props.currentMonthFromDate,'DD-MMM-YYYY')} to {formattedDate(props.currentMonthToDate, 'DD-MMM-YYYY')})</th>
                                <th style={{ padding: 7 }} colSpan={3} align={'center'}>Current Day Target Achievement <br /> ({formattedDate(props.dated, 'DD-MMM-YYYY')})</th>
                            </tr>

                            <tr>
                                <th style={{ padding: 7 }} colSpan={1} align={'center'}>Target</th>
                                <th style={{ padding: 7 }} colSpan={2} align={'center'}>{fCommaSeparatedWithDecial((props.lastMonthTarget?.TargetCtn ?? 0).toFixed(2))}</th>
                                <th style={{ padding: 7 }} colSpan={1} align={'center'}>Target</th>
                                <th style={{ padding: 7 }} colSpan={2} align={'center'}>{fCommaSeparatedWithDecial((props.currentMonthTarget?.TargetCtn ?? 0).toFixed(2))}</th>
                                <th style={{ padding: 7 }} colSpan={1} align={'center'}>Target</th>
                                <th style={{ padding: 7 }} colSpan={2} align={'center'}>{fCommaSeparatedWithDecial((props.currentDayTarget?.TargetCtn ?? 0).toFixed(2))}</th>
                            </tr>

                            <tr>
                                <td style={{ padding: 7 }}>Achieved:</td>
                                <td style={{ padding: 7, background: achievedBackground }}>{fCommaSeparatedWithDecial((props.lastMonthTarget?.AchievedCtn ?? 0).toFixed(2))}</td>
                                <td style={{ padding: 7, background: achievedBackground }}>{`${props.lastMonthTarget?.AchievedPercentage} %`}</td>

                                <td style={{ padding: 7 }}>Achieved:</td>
                                <td style={{ padding: 7, background: achievedBackground }}>{fCommaSeparatedWithDecial((props.currentMonthTarget?.TargetCtn ?? 0).toFixed(2))}</td>
                                <td style={{ padding: 7, background: achievedBackground }}>{`${props.currentMonthTarget?.AchievedPercentage} %`}</td>

                                <td style={{ padding: 7 }}>Achieved:</td>
                                <td style={{ padding: 7, background: achievedBackground }}>{fCommaSeparatedWithDecial((props.currentDayTarget?.AchievedCtn ?? 0).toFixed(2))}</td>
                                <td style={{ padding: 7, background: achievedBackground }}>{`${props.currentDayTarget?.AchievedPercentage ?? 0} %`}</td>
                            </tr>
                            <tr>
                                <td style={{ padding: 7 }}>Remaining:</td>
                                <td style={{ padding: 7, background:remainingBackground }}>{fCommaSeparatedWithDecial((props.lastMonthTarget?.RemainingCtn ?? 0).toFixed(2))}</td>
                                <td style={{ padding: 7, background:remainingBackground }}>{`${props.lastMonthTarget?.RemainingPercentage ?? 0} %`}</td>

                                <td style={{ padding: 7 }}>Remaining:</td>
                                <td style={{ padding: 7, background:remainingBackground }}>{fCommaSeparatedWithDecial((props.currentMonthTarget?.RemainingCtn ?? 0).toFixed(2))}</td>
                                <td style={{ padding: 7, background:remainingBackground }}>{`${props.currentMonthTarget?.RemainingPercentage ?? 0} %`}</td>

                                <td style={{ padding: 7 }}>Remaining:</td>
                                <td style={{ padding: 7, background:remainingBackground }}>{fCommaSeparatedWithDecial((props.currentDayTarget?.TargetCtn ?? 0).toFixed(2))}</td>
                                <td style={{ padding: 7, background:remainingBackground }}>{`${props.currentDayTarget?.RemainingPercentage ?? 0} %`}</td>
                            </tr>
                        </tbody>
                    </table>
                </Grid>}
            </Grid>
        </Grid>
    </span>;
}