import { Icon } from '@iconify/react';
import { Grid, Typography } from '@mui/material';
import React from 'react';
import { IMessage } from 'src/interfaces/message.interface';

interface IProps{
    messages:IMessage[];
    onRemove:(index:number) => void;
}

export const MessageComponent = (props:IProps) =>{
    const mainKeyText = 'MessageComponentKey';
    return (
        <React.Fragment key={`${mainKeyText}_1`}>{!!props.messages && props.messages.length > 0 && props.messages.map((row,index) => {
        let iconName = 'teenyicons:tick-circle-solid';
        let colorName = '#28a745';

        if(row.variant == 'error'){
            iconName = 'ci:off-close';
            colorName = '#dc3545';
        }else if(row.variant == 'info'){
            iconName = 'ion:information-circle';
            colorName = '#17a2b8';
        }else if(row.variant == 'warning'){
            iconName = 'clarity:warning-standard-solid';
            colorName = '#ffc107';
        }

        return (<Grid key={`${mainKeyText}_2_${index}`} style={{minHeight:30,backgroundColor:colorName,opacity:0.8, color:'white', borderRadius:5,display:'flex',flex:1,flexDirection:'column', justifyContent:'center'}}>
            <Grid key={`${mainKeyText}_3_${index}`} style={{display:'flex',flex:1,flexDirection:'row',padding:10}} item md={12}>
                <Grid key={`${mainKeyText}_4_${index}`} style={{display:'flex',width:30,flexDirection:'column',justifyContent:'center'}}><Icon style={{alignSelf:'flex-start'}} height={20} width={20} icon={iconName} /></Grid>
                <Grid key={`${mainKeyText}_5_${index}`} style={{display:'flex',width:'100%',flexDirection:'row'}}><Typography style={{float:'left',alignSelf:'center'}} variant='subtitle1'>{row.messageText}</Typography></Grid>
                <Grid key={`${mainKeyText}_6_${index}`} style={{display:'flex',width:50,justifyContent:'flex-end'}}><Icon onClick={() => {props.onRemove(index)}} style={{cursor:'pointer',alignSelf:'center'}} height={30} width={20} icon='ci:close-small' color='white' /></Grid>
            </Grid>
        </Grid>)})}</React.Fragment>
    );
}