import {NewRouteSetupConstant} from '../../actions/action.constant';
import {IAction} from '../../actions/action.interface';
import {initialState} from '../../initial-state';

export const isDeletingRouteReducer = (
  state: boolean = initialState.isDeletingRoute,
  action: IAction<boolean>,
) => {
  switch (action.type) {
    case NewRouteSetupConstant.SET_IS_DELETING_ROUTE: {;
      return action.payload;
    }
    default:
      return state;
  }
};
