import { Icon } from '@iconify/react';
import { PropaneSharp } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Container, Grid, TextField } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { isRestrictedLogin, NumberOfPreviousMonth } from 'src/app.constant';
import { ICreditHistoryParam } from 'src/interfaces/credit-history-param.interface';
import { IApplicationState } from 'src/redux';
import CustomDatePicker from './common/CustomDatePicker';

interface IProps {
    params: ICreditHistoryParam;
    onChange: (prms:ICreditHistoryParam) => void;
    isLoading: boolean;
    onLoad: (isOnRefresh) => void;
    onReset:() => void;
}

export const CreditHistoryParam = (props: IProps) => {

    const [params, setParams] = React.useState<ICreditHistoryParam>(props.params);
    
    const onParamChange = (value:any, name:keyof ICreditHistoryParam) => {
        let prms:any = {...params};
        prms[name] = value;
        setParams(prms);
        props.onChange({...prms});
    }

    const userId = useSelector((st:IApplicationState) => st.loginUser.UserId);
    const tDated = new Date();

    const onReset = () =>{
        let changed = {
            SlipNumber:null,
            FromDate:null,
            ToDate:null,
            OwnerCellNumber:null,
            OwnerName:null,
            ResponsiblePersonName:null,
            ShopAddress:null,
            ShopName:null,
            SearchText:null
        };

        setParams(changed);
        props.onReset();
    }

    const creditHistoryParamKey = 'CreditHistoryParamComponentKey';

    return <Container key={`${creditHistoryParamKey}_1`} style={{ paddingTop: 20, paddingBottom: 10, paddingLeft:0, paddingRight:0 }}>
        <Grid key={`${creditHistoryParamKey}_2`} container spacing={2} style={{paddingLeft:0, paddingRight:0}}>
            <Grid key={`${creditHistoryParamKey}_3`} item md={3} sm={12}>
                <TextField
                    fullWidth
                    autoComplete="slip-number"
                    type={'number'}
                    label="Slip Number"
                    value={!!params && !!params.SlipNumber && params.SlipNumber > 0 ? params.SlipNumber : ''}
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                        const value = event.target.value;
                        onParamChange(value, 'SlipNumber');
                    }}
                    key={`${creditHistoryParamKey}_4`}
                />
            </Grid>
            <Grid key={`${creditHistoryParamKey}_5`} item md={3} sm={12}>
                <TextField
                    fullWidth
                    autoComplete="shop-name"
                    type={'text'}
                    label="Shop Name"
                    value={!!params && !!params.ShopName ? params.ShopName : ''}
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                        const value = event.target.value;
                        onParamChange(value, 'ShopName');
                    }}
                    key={`${creditHistoryParamKey}_6`}
                />
            </Grid>
            <Grid key={`${creditHistoryParamKey}_7`} item md={3} sm={12}>
                <TextField
                    fullWidth
                    autoComplete="shop-address"
                    type={'text'}
                    label="Shop Address"
                    value={!!params && !!params.ShopAddress ? params.ShopAddress : ''}
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                        const value = event.target.value;
                        onParamChange(value, 'ShopAddress');
                    }}

                    key={`${creditHistoryParamKey}_8`}
                />
            </Grid>

            <Grid key={`${creditHistoryParamKey}_9`} item md={3} sm={12}>
                <TextField
                    fullWidth
                    autoComplete="owner-name"
                    type={'text'}
                    label="Owner Name"
                    value={!!params && !!params.OwnerName ? params.OwnerName : ''}
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                        const value = event.target.value;
                        onParamChange(value, 'OwnerName');
                    }}

                    key={`${creditHistoryParamKey}_10`}
                />
            </Grid>

            <Grid key={`${creditHistoryParamKey}_11`} item md={3} sm={12}>
                <TextField
                    fullWidth
                    autoComplete="owner-cell-number"
                    type={'text'}
                    label="Owner Cell No."
                    value={!!params && !!params.OwnerCellNumber ? params.OwnerCellNumber : ''}
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                        const value = event.target.value;
                        onParamChange(value, 'OwnerCellNumber');
                    }}
                    key={`${creditHistoryParamKey}_12`}
                />
            </Grid>

            <Grid key={`${creditHistoryParamKey}_13`} item md={3} sm={12}>
                <TextField
                    fullWidth
                    autoComplete="responsible-person"
                    type={'text'}
                    label="Responsible Person"
                    value={!!params && !!params.ResponsiblePersonName ? params.ResponsiblePersonName : ''}
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                        const value = event.target.value;
                        onParamChange(value, 'ResponsiblePersonName');
                    }}
                    key={`${creditHistoryParamKey}_14`}
                />
            </Grid>

            <Grid key={`${creditHistoryParamKey}_15`} item md={3} sm={12}>
                <CustomDatePicker
                    label='From Date'
                    value={params.FromDate}
                    readonly={props.isLoading}
                    onChange={(dated: Date) => {
                        onParamChange(dated, 'FromDate');
                    }}
                    key={`${creditHistoryParamKey}_16`}
                    minDate={isRestrictedLogin(userId) ? (new Date(tDated.getFullYear(),tDated.getMonth() - NumberOfPreviousMonth,1)) : undefined}
                    inputDisable={true}
                />
            </Grid>

            <Grid key={`${creditHistoryParamKey}_17`} item md={3} sm={12}>
                <CustomDatePicker
                    label='To Date'
                    value={params.ToDate}
                    readonly={props.isLoading}
                    onChange={(dated: Date) => {
                        onParamChange(dated, 'ToDate');
                    }}
                    key={`${creditHistoryParamKey}_18`}
                    minDate={isRestrictedLogin(userId) ? (new Date(tDated.getFullYear(),tDated.getMonth() - NumberOfPreviousMonth,1)) : undefined}
                    inputDisable={true}
                />
            </Grid>

            <Grid key={`${creditHistoryParamKey}_19`} item xs={12} style={{ justifyContent: 'center', display: 'flex' }}>
                <LoadingButton
                    size="small"
                    type="button"
                    variant="contained"
                    loading={props.isLoading}
                    style={{ alignSelf: 'center', margin: 3, backgroundColor: 'blue', textAlign: 'right', height: 40 }}
                    startIcon={<Icon icon={'bi:clock-history'} />}
                    onClick={() => {
                        props.onLoad(false);
                    }}
                    key={`${creditHistoryParamKey}_20`}
                >
                    Load History
                </LoadingButton>

                <LoadingButton
                    size="small"
                    type="button"
                    variant="contained"
                    loading={props.isLoading}
                    style={{ alignSelf: 'center', margin: 3, backgroundColor: 'orange', textAlign: 'right', height: 40 }}
                    startIcon={<Icon icon={'carbon:reset'} />}
                    onClick={onReset}
                    key={`${creditHistoryParamKey}_21`}
                >
                    Reset Filters
                </LoadingButton>

            </Grid>

        </Grid>
    </Container>;
}