import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {Chip} from '@mui/material';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface IProps<T>{
    Id:string;
    Label:string;
    Value:T[] | undefined;
    Options:T[];
    displayField:keyof T;
    onChange?:(selectedOptions:T[]) => void;
    disabled?:boolean;
    style?:React.CSSProperties;
    disableCloseOnSelect?:boolean;
    limitTags?:number;
    placeholder:string;
    label:string;
}

export const CustomMultiSelect = <T extends {}>(props:IProps<T>) => {
  return (
    <Autocomplete
      multiple
      id={props.Id}
      options={props.Options}
      disableCloseOnSelect={props.disableCloseOnSelect === undefined || props.disableCloseOnSelect == null || props.disableCloseOnSelect == false ? false : true}
      getOptionLabel={(option) => (option as any)[props.displayField]}
      renderOption={(innerProps, option, state) => (
        <li {...innerProps}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={state.selected}
          />
          {(option as any)[props.displayField]}
        </li>
      )}
      style={props.style}
      renderInput={(params) => (
        <TextField {...params} label={props.label} placeholder={props.placeholder} />
      )}
      limitTags={props.limitTags}
      value={props.Value}
      onChange={(e:any,v:T[],reason:any,details:any) => {
        if(props.onChange){
            props.onChange(v);
        }
    }}
    disabled={props.disabled == true}
    renderTags={(value, getTagProps) =>
      value.map((option, index) => {
        return <Chip
          label={(option as any)[props.displayField]}
          {...getTagProps({ index })}
        />
    })
    }
    />
  );
}
