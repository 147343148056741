import {IAction} from './action.interface';
import {UserRoutesConstatns} from './action.constant';
import {IUserRouteDetail} from '../../interfaces/routes/user-route-detail.interface';

export const setUserRouteDetailAction = (
  payload: IUserRouteDetail | null,
): IAction<IUserRouteDetail> => {
  return {
    type: UserRoutesConstatns.SET_USER_ROUTES,
    payload,
  };
};
