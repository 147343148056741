import { IRoutePoly } from 'src/interfaces/route-poly.interface';
import {NewRouteSetupConstant} from '../../actions/action.constant';
import {IAction} from '../../actions/action.interface';
import {initialState} from '../../initial-state';
import { getCopy } from 'src/utility';
import { IAreaExistingShops } from 'src/interfaces/area-existing-shops.interface';

export const existingShopsReducer = (
  state: IAreaExistingShops[] = initialState.existingShops,
  action: IAction<IAreaExistingShops>,
) => {
  switch (action.type) {
    case NewRouteSetupConstant.SET_AREA_EXISTING_SHOPS: {
        if(!!action.payload && action.payload.areaId > 0){
            const copy = !!state && state.length > 0 ? getCopy(state) : [];

            if(copy.findIndex(m => m.areaId === action.payload.areaId) >= 0){
                const newState = copy.map(a => {
                    if(a.areaId === action.payload.areaId){
                        a.existingShops = !!action.payload.existingShops && action.payload.existingShops.length > 0 ? getCopy(action.payload.existingShops) : [];
                    }

                    return a;
                });

                return newState;
            }else{
                return [...copy.map(a => a), {areaId:action.payload.areaId, existingShops:(!!action.payload.existingShops && action.payload.existingShops.length > 0 ? getCopy(action.payload.existingShops) : [])}];
            }
        }else{
            return state;
        }
    }
    default:
      return state;
  }
};

