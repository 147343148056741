import { Autocomplete, CircularProgress, Popper, TextField } from "@mui/material";
import React from "react";

interface IProps<T> {
    Id: string;
    Label: string;
    Value: T | null;
    Options: T[];
    displayField: keyof T;
    valueField: keyof T;
    onChange?: (option: T) => void;
    disabled?: boolean;
    style?: React.CSSProperties;
    fontSize?: number;
    isLoading?: boolean;
    size?: "small" | "medium";
    readOnly?: boolean;
    placeholder?: string;
}

export const CustomAutoCompleteForMap = <T extends {}>(props: IProps<T>) => {
    const Pop = props => {
        const { className, anchorEl, disablePortal, style, ...rest } = props
        const bound = anchorEl.getBoundingClientRect()
        return <div {...rest} style={{
            position: 'absolute',
            zIndex: 9999,
            width: bound.width
        }} />
    }
    return <Autocomplete
        style={props.style}
        size={props.size}
        id={props.Id}
        readOnly={(props.readOnly == true || props.isLoading == true)}
        options={props.Options ?? []}
        onChange={(e: any, v: any, reason: any, details: any) => {
            if (props.onChange) {
                props.onChange(v);
            }
        }}
        onBlur={(e: any) => {
            if (!e.target.value && props.onChange) {
                props.onChange(null);
            }
        }}
        value={(props.Value ?? null) as any}
        disabled={props.disabled == true}
        getOptionLabel={(option: T) => (!!option && option[props.displayField] !== undefined && option[props.displayField] != null ? option[props.displayField] : '').toString()}
        renderInput={(params) => <TextField
            {...params}
            InputProps={
                {
                    ...params.InputProps,
                    style: { fontSize: !!props.fontSize ? `${props.fontSize} !important` : undefined },
                    endAdornment: (
                        <React.Fragment>
                            {props.isLoading == true && <CircularProgress color={'inherit'} size={20} />}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                    )
                }
            }
            placeholder={props.placeholder}
            label={props.Label}
            />}

        PopperComponent={Pop}
    />;
}