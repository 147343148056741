import React from 'react';
import { IOrderNew } from 'src/interfaces/reports/order-new.interface';
import { SaleInfoComponent } from './sale-info.component';
import { TargetInfoComponent } from './target-info.component';
import { Audio as AudoSpin } from 'react-loader-spinner'
import { generateUUID, getCommaSeparatedUnitUniqueIdsExceptAll, getNumberOfDaysInMonth } from 'src/utility';
import { orderApi } from 'src/http';
import { useSelector } from 'react-redux';
import { IApplicationState } from 'src/redux';
import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';

interface IProps {
    tillDate: Date;
    orderBookerId: number;
    refreshToken: string;
    refreshMe:(token:string) => void;
}

export const TargetAndSaleInfoComponent = (props: IProps) => {
    const [dated, setDated] = React.useState<Date>(props.tillDate ?? new Date());
    const [orderBookerId, setOrderBookerId] = React.useState<number>(props.orderBookerId);
    const [lastMonthFromDate, setLastMonthFromDate] = React.useState<Date>();
    const [lastMonthToDate, setLastMonthToDate] = React.useState<Date>();
    const [currentMonthFromDate, setCurrentMonthFromDate] = React.useState<Date>();
    const [currentMonthToDate, setCurrentMonthToDate] = React.useState<Date>();
    const [lastMonthOrder, setLastMonthOrder] = React.useState<IOrderNew>();
    const [currentMonthOrder, setCurrentMonthOrder] = React.useState<IOrderNew>();
    const [currentDayOrder, setCurrentDayOrder] = React.useState<IOrderNew>();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [paramChanged, setParamChanged] = React.useState<boolean>(false);
    const [refreshToken, setRefreshToken] = React.useState<string>(props.refreshToken);
    const state = useSelector((s:IApplicationState) => s);

    const reloadData = (refreshToken:string, orderBookerId:number, tillDate:Date) =>{
        setRefreshToken(refreshToken);
        setIsLoading(true);
        setParamChanged(false);
        const dates = getDates(tillDate);
        setCurrentMonthFromDate(dates.currentMonthFromDate);
        setCurrentMonthToDate(dates.currentMonthToDate);
        setLastMonthFromDate(dates.previousMonthFromDate);
        setLastMonthToDate(dates.previousMonthToDate);

        let tasks:Promise<IOrderNew[]>[] = [];

        tasks.push(orderApi.getNewOrdersReport(state.loginUser?.UserId ?? 0, getCommaSeparatedUnitUniqueIdsExceptAll(state.selectedUnitAccesses?.UnitAccesses ?? []), dates.previousMonthFromDate, dates.previousMonthToDate, orderBookerId));
        tasks.push(orderApi.getNewOrdersReport(state.loginUser?.UserId ?? 0, getCommaSeparatedUnitUniqueIdsExceptAll(state.selectedUnitAccesses?.UnitAccesses ?? []), dates.currentMonthFromDate, dates.currentMonthToDate, orderBookerId));
        tasks.push(orderApi.getNewOrdersReport(state.loginUser?.UserId ?? 0, getCommaSeparatedUnitUniqueIdsExceptAll(state.selectedUnitAccesses?.UnitAccesses ?? []), tillDate, tillDate, orderBookerId));

        Promise.all(tasks).then(results => {
            setIsLoading(false);
            if(!!results && results.length > 2){
                setLastMonthOrder({...results[0][0]});
                setCurrentMonthOrder({...results[1][0]});
                setCurrentDayOrder({...results[2][0]})
            }else{
                setParamChanged(true);
            }
        })
        .catch(e => {
            setIsLoading(false);
            setParamChanged(true);
            console.log(e);
        });
    }

    const getDates = (tillDated:Date) =>{
        const monthIndex = tillDated.getMonth();
        const currentMonthYear = tillDated.getFullYear();

        const previousMonthIndex = monthIndex == 0 ? 11 : monthIndex - 1;
        const previousMonthYear = monthIndex == 0 ? currentMonthYear - 1 : currentMonthYear;

        const currentMonthFromDate = new Date(currentMonthYear,monthIndex,1,0,0,0,0);
        const currentMonthToDate = tillDated;

        const currentMonthDayNumber = tillDated.getDate();
        let previousMonthDayNumber = 1;
        const currentMonthTotalNumberOfDays = getNumberOfDaysInMonth(monthIndex, currentMonthYear);
        const previousMonthTotalNumberofDays = getNumberOfDaysInMonth(previousMonthIndex, previousMonthYear);

        if(currentMonthDayNumber == currentMonthTotalNumberOfDays || currentMonthDayNumber >= previousMonthTotalNumberofDays){
            previousMonthDayNumber = previousMonthTotalNumberofDays;
        }else{
            previousMonthDayNumber = currentMonthDayNumber;
        }

        const previousMonthFromDate = new Date(previousMonthYear, previousMonthIndex,1,0,0,0,0);
        const previousMonthToDate = new Date(previousMonthYear, previousMonthIndex,previousMonthDayNumber,0,0,0,0);

        return {
            currentMonthFromDate,
            currentMonthToDate,
            previousMonthFromDate,
            previousMonthToDate
        };
    }

    React.useEffect(() => {
        if(!!props.tillDate && props.tillDate != dated){
            setParamChanged(true);
            setDated(props.tillDate);
        }

        if(!!props.orderBookerId && props.orderBookerId > 0 && props.orderBookerId != orderBookerId){
            setParamChanged(true);
            setOrderBookerId(props.orderBookerId);
        }

        if(!!props.refreshToken && props.refreshToken != refreshToken && !!props.orderBookerId && props.orderBookerId > 0 && !!props.tillDate){
            reloadData(props.refreshToken, props.orderBookerId, props.tillDate);
        }

    },[props.tillDate, props.orderBookerId, props.refreshToken]);

    return <React.Fragment>
            {isLoading == false && <Grid container>
        <Grid item md={12} style={{justifyContent:'flex-end', display:'flex'}}>
        <LoadingButton
                  type="button"
                  size='small'
                  variant="contained"
                  loading={isLoading}
                  style={{ margin: 3, marginTop:10, width: 100,alignSelf:'flex-end', display:'flex' }}
                  disabled={isLoading}
                  onClick={() => {
                    props.refreshMe(generateUUID())
                  }}
                >
                  Refresh
                </LoadingButton>
        </Grid>
    </Grid>}
        {isLoading == true && <AudoSpin
            height="80"
            width="80"
            color='green'
            ariaLabel='three-dots-loading'
            wrapperStyle={{ alignSelf: 'center', justifyContent: 'center' }}
        />}
        {isLoading == false && paramChanged == false && <TargetInfoComponent
            currentDayTarget={currentDayOrder}
            currentMonthFromDate={currentMonthFromDate}
            currentMonthTarget={currentMonthOrder}
            currentMonthToDate={currentMonthToDate}
            dated={dated}
            lastMonthFromDate={lastMonthFromDate}
            lastMonthTarget={lastMonthOrder}
            lastMonthToDate={lastMonthToDate}
            key={`Target_Info_Component_Live_Tracking`}
        />}
        {isLoading == false && paramChanged == false &&  <SaleInfoComponent
            currentDayTarget={currentDayOrder}
            currentMonthFromDate={currentMonthFromDate}
            currentMonthTarget={currentMonthOrder}
            currentMonthToDate={currentMonthToDate}
            dated={dated}
            lastMonthFromDate={lastMonthFromDate}
            lastMonthTarget={lastMonthOrder}
            lastMonthToDate={lastMonthToDate}
            key={`Sale_Info_Component_Live_Tracking`}
        />}
    </React.Fragment>
}