import moment from 'moment';
import React from 'react';
import { DateWiseCreditDetail } from './DateWiseCreditDetail';
import { MonthWiseCreditDetail } from './MonthWiseCreditDetail';
import { OrderBookerWiseCreditDetail } from './OrderBookerWiseCreditDetail';
import { ShopWiseCreditDetail } from './ShopWiseCreditDetail';
import { getNumberOfDaysInMonth } from 'src/utility';

interface IViewPort {
    OrderBookerWise: boolean;
    OrderBookerMonthWiseDetail: boolean;
    OrderBookerDateWiseDetail: boolean;
    OrderBookerShopWiseDetail: boolean;
}

interface IMonthWiseDetailParam{
    UnitUniqueId:string;
    UnitTitle:string;
    ResponsiblePersonEmployeeId:number;
    ResponsiblePersonName:string;
}

interface IDateWiseDetailParam extends IMonthWiseDetailParam{
    MonthStartDate:Date;
}

interface IShopWiseDetailParam{
    EmployeeId:number;
    EmployeeName:string;
    UnitUniqueId:string;
    UnitTitle:string;
    FromDate:Date;
    ToDate:Date;
    IsViewingDetailForMonth:boolean;
}

export const OrderBookerWiseCredit = () => {
    const initialViewPort: IViewPort = {
        OrderBookerWise: true,
        OrderBookerMonthWiseDetail: false,
        OrderBookerDateWiseDetail: false,
        OrderBookerShopWiseDetail: false
    };

    const viewPortAllFalse: IViewPort = {
        OrderBookerWise: false,
        OrderBookerMonthWiseDetail: false,
        OrderBookerDateWiseDetail: false,
        OrderBookerShopWiseDetail: false
    };

    const [monthWiseDetailParams, setMonthWiseDetailParams] = React.useState<IMonthWiseDetailParam>(null);
    const [dateWiseDetailParams, setDateWiseDetailParams] = React.useState<IDateWiseDetailParam>(null);
    const [shopWiseDetailParams, setShopWiseDetailParams] = React.useState<IShopWiseDetailParam>(null);
    const [viewPort, setViewPort] = React.useState<IViewPort>({ ...initialViewPort });


    const onViewOrderBookerWise = () => {
        let viewPortCopy = { ...viewPortAllFalse, OrderBookerWise: true };
        setViewPort(viewPortCopy);
    }

    const onResponsiblePersonClick = (unitUniqueId:string,unitTitle:string, employeeId:number,employeeName:string) => {
        setMonthWiseDetailParams({
            ResponsiblePersonEmployeeId:employeeId,
            ResponsiblePersonName:employeeName,
            UnitTitle:unitTitle,
            UnitUniqueId:unitUniqueId   
        });

        let viewPortCopy = { ...viewPortAllFalse, OrderBookerMonthWiseDetail: true };
        setViewPort(viewPortCopy);
        setDateWiseDetailParams(null);
        setShopWiseDetailParams(null);
    }

    const onWeekCreditClick = (monthStartDate:Date, employeeId:number, employeeName:string, unitUniqueId:string, unitTitle:string) => {
        setDateWiseDetailParams({MonthStartDate:monthStartDate,ResponsiblePersonEmployeeId:employeeId, ResponsiblePersonName:employeeName, UnitTitle:unitTitle,UnitUniqueId:unitUniqueId});
        let viewPortCopy = { ...viewPortAllFalse, OrderBookerDateWiseDetail: true };
        setViewPort(viewPortCopy);
        setShopWiseDetailParams(null);
    }

    const onMonthClick = (monthStartDate:Date, employeeId:number, employeeName:string, unitUniqueId:string, unitTitle:string) => {
        const dated = new Date(monthStartDate);
        const monthIndex = dated.getMonth();
        const year = dated.getFullYear();
        const numberOfDaysInMonth = getNumberOfDaysInMonth(monthIndex, year);
        const endDate = new Date(year, monthIndex, numberOfDaysInMonth, 0,0,0,0);


        setShopWiseDetailParams({
            FromDate:monthStartDate,
            ToDate:endDate,
            EmployeeId:employeeId,
            EmployeeName:employeeName,
            UnitTitle:unitTitle,
            UnitUniqueId:unitUniqueId,
            IsViewingDetailForMonth:true
        });

        let viewPortCopy = { ...viewPortAllFalse, OrderBookerShopWiseDetail: true };
        setViewPort(viewPortCopy);
        setDateWiseDetailParams(null);
    }

    const monthDetailBackClick = () =>{
        setViewPort({...initialViewPort});
        setMonthWiseDetailParams(null);
        setDateWiseDetailParams(null);
        setShopWiseDetailParams(null);
    }

    const onDateClick = (employeeId:number, employeeName:string, unitUniqueId:string, unitTitle:string, dated:Date) => {
        setShopWiseDetailParams({
            FromDate:dated,
            ToDate:dated,
            EmployeeId:employeeId,
            EmployeeName:employeeName,
            UnitTitle:unitTitle,
            UnitUniqueId:unitUniqueId,
            IsViewingDetailForMonth:false
        });

        let viewPortCopy = { ...viewPortAllFalse, OrderBookerShopWiseDetail: true };
        setViewPort(viewPortCopy);
    }

    const onBackClickFromShopWiseDetail = (monthStartDate:Date, employeeId:number, employeeName:string, unitUniqueId:string, unitTitle:string) =>{
        if(shopWiseDetailParams.IsViewingDetailForMonth == true){
            onResponsiblePersonClick(unitUniqueId,unitTitle,employeeId,employeeName);
        }else{
            onWeekCreditClick(monthStartDate,employeeId,employeeName,unitUniqueId,unitTitle);
        }
    }

    const orderBookerWiseCreditKey = 'OrderBookerWiseParentComponentKey';

    return <>
        {viewPort.OrderBookerWise == true && <OrderBookerWiseCreditDetail key={`${orderBookerWiseCreditKey}_1`} onClick={onResponsiblePersonClick} />}
        {viewPort.OrderBookerMonthWiseDetail == true && <MonthWiseCreditDetail key={`${orderBookerWiseCreditKey}_2`} onBackClick={monthDetailBackClick} onClick={onMonthClick} onWeekWiseClick={onWeekCreditClick} params={monthWiseDetailParams} />}
        {viewPort.OrderBookerDateWiseDetail == true && <DateWiseCreditDetail key={`${orderBookerWiseCreditKey}_3`} onClick={onDateClick} onBackClick={onResponsiblePersonClick} params={dateWiseDetailParams} />}
        {viewPort.OrderBookerShopWiseDetail == true && <ShopWiseCreditDetail key={`${orderBookerWiseCreditKey}_4`} onBackClick={onBackClickFromShopWiseDetail} params={shopWiseDetailParams} />}
    </>
}