import * as React from 'react';
import { TabControl } from 'src/components/common/TabControl';
import { CreditReceiveHistory } from './CreditReceiveHistory';
import { ReceiveCredit } from './ReceiveCredit';
import { decodeBase64String, generateUUID, getCopy, getQueryParams } from 'src/utility';
import { OrderBookerWiseCredit } from 'src/components/credit/OrderBookerWiseCredit';
import { TableContainer } from '@mui/material';
import useWindowDimensions from 'src/components/common/useWindowDimentions';
import { DateWiseCredit } from './DateWiseCredit';
import { IApplicationState } from 'src/redux';
import { connect } from 'react-redux';
import { IUserRouteDetail } from 'src/interfaces/routes/user-route-detail.interface';
import { getUrlFromComponentKey } from 'src/utility/navigation.util';
import { Keys } from 'src/component.keys';
import { CreditHistory } from './CreditHistory';
import { OrdersOldComponent } from 'src/components/orders/orders-old.component';
import { UnitOrdersMain } from 'src/components/orders/unit-orders-main.component';
import { OrdersNewReport } from './OrdersNewReport';
import { useLocation } from 'react-router-dom';
import { ManageShopsParam } from 'src/app.constant';
import { ShopsContainer } from './ShopsContainer';
import { ShopRequestsContainer } from './ShopRequestsContainer';

interface IProps{
    userRoutes:IUserRouteDetail;
}

const ManageShopsContainer = (props:IProps) => {
    const tabKeys = {
        Shops: 'Shops',
        ShopRequests: 'ShopRequests',
    };
    const [tabs,setTabs] = React.useState<any[]>([
        {
            title: 'Shops',
            keyName: tabKeys.Shops,
            order: 1
        },
    {
        title: 'Shop Requests',
        keyName: tabKeys.ShopRequests,
        order: 2
    }]);

    const PageKey = 'ManageShopsParentComponentKey';

    const search = useLocation().search;
    const tabQuery = !!search ? new URLSearchParams(search).get(ManageShopsParam.SelectedTabKey) : null;
    const decodedTab = !!tabQuery ? decodeBase64String(tabQuery) : null;
    const [selectedTab, setSelectedTab] = React.useState<string>();

    const handleChange = (newValue: string) => {
        setSelectedTab(newValue);
    };

    //const [dimensions,setDimentions] = React.useState<IWindoDimension>(useWindowDimensions());
    const dimensions = useWindowDimensions();

    React.useEffect(() =>{
        let editedTabs = getCopy(tabs);
        let keysToShow = [];
        if(!!props.userRoutes && !!props.userRoutes.Routes && props.userRoutes.Routes.length > 0){
            let url = getUrlFromComponentKey(props.userRoutes,Keys.ShopsContainer);
            if(!!url && url.trim() != ''){
                keysToShow.push(tabKeys.Shops);
            }

            url = getUrlFromComponentKey(props.userRoutes,Keys.ShopRequests);

            if(!!url && url.trim() != ''){
                keysToShow.push(tabKeys.ShopRequests);
            }
        }

        let newTabs = [...editedTabs.filter(m => keysToShow.length > 0 && keysToShow.indexOf(m.keyName) >= 0).map(a => a)];
        setTabs([...newTabs]);

        if(newTabs !== undefined && newTabs != null && newTabs.length > 0){
            if(!!decodedTab && newTabs.findIndex(m => m.keyName === decodedTab) >= 0){
                const index = newTabs.findIndex(n => n.keyName === decodedTab);
                setSelectedTab(newTabs[index].keyName);
            }else{
                setSelectedTab(newTabs[0].keyName);
            }
        }

    },[props.userRoutes]);

    const TableContainerRef = React.useRef(null);
    const scrollToTop = () => {
        TableContainerRef.current.scrollTo(0,0);
    }

    return (
        <React.Fragment key={`${PageKey}_1`}>
            {!!tabs && tabs.length > 0 && !!selectedTab && selectedTab.trim() != '' && <TabControl widthtPercent='30%' key={`${PageKey}_2`} tabs={tabs} selected={selectedTab} onChange={handleChange} />}
            <TableContainer key={`${PageKey}_3`} ref={TableContainerRef} style={{height:dimensions.height - 130, width:'100%'}}>
                {selectedTab == tabKeys.Shops && <ShopsContainer key={`${PageKey}_4`} />}
                {selectedTab == tabKeys.ShopRequests && <ShopRequestsContainer scrollToTop={scrollToTop} key={`${PageKey}_5`} />}
            </TableContainer>
        </React.Fragment>
    );
}

const mapStateToProps = (state: IApplicationState) => {
    return {
        userRoutes:state.userRoutes
    };
}
const connectedComponent = connect(mapStateToProps)(ManageShopsContainer);
export { connectedComponent as ManageShopsContainer };
