import React from 'react';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import arrowBackFill from '@iconify/icons-eva/arrow-back-fill';
import refreshFill from '@iconify/icons-eva/refresh-fill';
import saveFill from '@iconify/icons-eva/save-fill';
import moneyCollectFill from '@iconify/icons-ant-design/money-collect-fill';
// material
import {
    Card,
    Table,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    Grid,
    TextField,
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { UserListHead } from '../components/_dashboard/user';
//
import { LoadingButton } from '@mui/lab';
import { doCashApi, doCreditApi, productApi } from '../http';

import { useSnackbar } from 'notistack';
import { connect, useSelector } from 'react-redux';
import { IApplicationState } from 'src/redux';
import { Audio as AudoSpin } from 'react-loader-spinner'
import { IDODetail } from 'src/interfaces/delivery-order/do-detail.interface';
import Label from 'src/components/Label';
import { sentenceCase } from 'change-case';
import { IDOCredit } from 'src/interfaces/do-credit.interface';
import { confirmAction } from 'src/utility/confirm.util';
import { IDOCashDetail } from 'src/interfaces/do-cash-detail.interface';
import { IProductStock } from 'src/interfaces/product-stock.interface';
import { IUserDetail } from 'src/interfaces';
import { ISelectedUnitAccess } from 'src/interfaces/selected-unit-access.interface';
import { AllOptionUniqueId, EmptyGuid, StockUpdateReason } from 'src/app.constant';
import { getCommaSeparatedUnitUniqueIdsExceptAll } from 'src/utility';
import { MessageComponent } from 'src/components/messages.component';
import { NoUnitSelectedComponent } from 'src/components/common/no-unit-selected.component';
import { Keys } from 'src/component.keys';
import { getUrlFromComponentKey } from 'src/utility/navigation.util';
import { IUserRouteDetail } from 'src/interfaces/routes/user-route-detail.interface';
import { useNavigate } from "react-router-dom";
import { IUnitAccess } from 'src/interfaces/unit-access.interface';
import { CustomAutoComplete } from 'src/components/common/CustomAutoComplete';
import { IMessage } from 'src/interfaces/message.interface';
import { IStockUpdateReason } from 'src/interfaces/stock-update-reason.interface';


// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'sr', label: 'Sr.', alignContent: 'center' },
    { id: 'product_name', label: 'Product Name', alignContent: 'center' },
    { id: 'available_stock_ctn', label: 'Available Stock(CTN)', alignContent: 'center' },
    { id: 'available_stock', label: 'Available Stock(Pieces)', alignContent: 'center' },
    { id: 'update_Reason', label: 'Update Reason', alignContent: 'center' },
    { id: 'new_stock', label: 'Stock To Update(Pieces)', alignContent: 'center' },
];

// ----------------------------------------------------------------------

interface IProps {
    loginUser: IUserDetail | null;
    selectedUnits: ISelectedUnitAccess;
    userRoutes:IUserRouteDetail;
}

const AddStock = (props:IProps) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [stockDetail, setStockDetail] = React.useState<IProductStock[]>([]);
    const [viewStockLedgerUrl, setViewStockLedgerUrl] = React.useState<string>('');
    const [unitAccesses,setUnitAccesses] = React.useState<IUnitAccess[]>([]);
    const [selectedUnitUniqueId, setSelectedUnitUniqueId] = React.useState<string>();
    const [selectedUnit, setSelectedUnit] = React.useState<IUnitAccess>(null);
    const [viewCurrentStockUrl, setViewCurrentStockUrl] = React.useState<string>('');
    const [TableHead, setTableHead] = React.useState<any[]>([...TABLE_HEAD])
    const showErrorMessage = (message: string) => addMessage(message, 'error');
    const showInfoMessage = (message: string) => addMessage(message, 'info');
    const showSuccessMessage = (message: string) => addMessage(message, 'success');
    const showWarningMessage = (message: string) => addMessage(message, 'warning');
    const [pageMessages, setPageMessages] = React.useState<IMessage[]>([]);
    const addMessage = (messageText: string, variant: 'success' | 'error' | 'info' | 'warning') => {
        if (!!messageText && messageText.trim() != '') {
            let msgs = [];
            msgs.push({
                messageText,
                variant
            });

            setPageMessages([...msgs]);
        }
    }

    const inputRef = React.useRef(null);
    const onViewStockLedger = () => {
        navigate(viewStockLedgerUrl);
    }

    const onViewCurrentStock = () =>{
        navigate(viewCurrentStockUrl);
      }

      const onUpdateStock = () =>{
        if(!!stockDetail && stockDetail.length > 0){
            var toUpdate = stockDetail.filter(t => !!t.StockToUpdate && (t.StockToUpdate < 0 || t.StockToUpdate > 0));

            if(!!toUpdate && toUpdate.length > 0){

                var isValid:number = 1;
                let message = '';

                toUpdate.forEach(tu => {
                    if(isValid == 1 && (!tu.StockUpdateReasonId || tu.StockUpdateReasonId <= 0)){
                        isValid = 0;
                        message = `Please Select Stock Update Reason For ${tu.ProductName}`;
                    }

                    if(isValid == 1 && (tu.StockUpdateReasonId == 5 && (!tu.TransferToUnitUniqueId || tu.TransferToUnitUniqueId.trim() == ''))){
                        isValid = 0;
                        message = `Please Select Transfer To Unit For ${tu.ProductName}`;
                    }

                    if(isValid == 1 && (tu.StockUpdateReasonId != 8 && tu.StockToUpdate < 0)){
                        isValid = 0;
                        message = `Stock Qty For ${tu.ProductName} Can't Be Negative`;
                    }

                    if(isValid == 1 && (tu.StockToUpdate < 0 && (tu.StockToUpdate * -1) > tu.AvailableQty)){
                        isValid = 0;
                        message = `Stock Adjustment Qty For ${tu.ProductName} Can't Be Less Than Total Available Stock.`;
                    }

                    if(isValid == 1 && (tu.StockToUpdate > 0 && tu.StockUpdateReasonId == 5 && tu.StockToUpdate > tu.AvailableQty)){
                        isValid = 0;
                        message = `Stock Transfer Qty For ${tu.ProductName} Can't Be Greater Than Total Available Stock.`;
                    }
                });

                if(isValid == 0){
                    showErrorMessage(message);
                    return;
                }

                confirmAction(`${selectedUnit.UnitTitle}`, `Are you sure you want to update stock for '${selectedUnit.UnitTitle}' store?`,'Yes','No')
                .then(res => {
                    if(res == true){
                        setIsLoading(true);

                        toUpdate.forEach(tup => {
                            if(tup.StockUpdateReasonId != 5){
                                tup.TransferToUnitUniqueId = EmptyGuid;
                            }
                        });

                        productApi.updateProductStock(props.loginUser.UserId, props.loginUser.EmailId,[...toUpdate])
                        .then(rest =>{
                            setIsLoading(false);
                            if(rest.IsOk == true){
                                showSuccessMessage(rest.StatusMessage);
                                getProductStock(selectedUnit.UnitUniqueId);
                            }else{
                                showErrorMessage(rest.StatusMessage);
                            }
                            
                        })
                        .catch(e => {
                            showErrorMessage("Failed To Update Stock");
                            setIsLoading(false);
                            console.log(e);
                        });
                    }
                });
            }else{
                showErrorMessage("No Stock To Update");
            }
        }else{
            showErrorMessage("Stock Details Not Found.");
        }
      }

    const onUnitChange = (e:IUnitAccess) =>{
        setPageMessages([]);
        if(!!e){
            setSelectedUnit({...e});
          setSelectedUnitUniqueId(e.UnitUniqueId);
          getProductStock(e.UnitUniqueId);
        }else{
            setSelectedUnit(null);
            setSelectedUnitUniqueId(null);
            setStockDetail([]);
        }
      }

    const getProductStock = (unitUniqueId:string) => {
        setIsLoading(true);
        setStockDetail([]);
        //revist
        productApi.getUnitWiseProductStock(props.loginUser.UserId, unitUniqueId)
            .then(cd => {
                setIsLoading(false);
                setStockDetail([...cd]);
            })
            .catch(e => {
                setIsLoading(false);
                console.log(e);
            })
    }

    const onUpdateStockToUpdate = (index:number, qty:number) =>{
        setPageMessages([]);
        let copy = [...stockDetail.map(a => a)];
        if(isNaN(qty)){
            copy[index].StockToUpdate = 0;
        }else{
            copy[index].StockToUpdate = qty;
        }

        setStockDetail(copy);
    }

    const onChangeStockUpdateReason= (index:number, e:IStockUpdateReason) =>{
        setPageMessages([]);
        let copy = [...stockDetail.map(a => a)];
        if(!!e){
            copy[index].StockUpdateReasonId = e.Id;

            let headerText = "Update Reason";
            if(e.Id == 5){
                headerText = "Update Reason & Unit";
            }else{
                copy[index].TransferToUnitUniqueId = null;
            }

            if(e.Id != 8 && copy[index].StockToUpdate < 0){
                copy[index].StockToUpdate = null;
            }

            let tableHeadCopy = [...TableHead.map(a => a)];
            tableHeadCopy[4].label = headerText;
            setTableHead([...tableHeadCopy.map(a => a)])
        }else{
            copy[index].StockUpdateReasonId = null;
            copy[index].TransferToUnitUniqueId = null;
            copy[index].StockToUpdate = null;
            let tableHeadCopy = [...TableHead.map(a => a)];
            tableHeadCopy[4].label = "Update Reason";
            setTableHead([...tableHeadCopy.map(a => a)])
        }

        setStockDetail(copy);
    }

    const onChangeTransferToUnit = (index:number, e:IUnitAccess) =>{
        setPageMessages([]);
        let copy = [...stockDetail.map(a => a)];
        if(!!e){
            copy[index].TransferToUnitUniqueId = e.UnitUniqueId;
        }else{
            copy[index].TransferToUnitUniqueId = null;
        }

        setStockDetail(copy);
    }

    React.useEffect(() =>{
        if(!!props.userRoutes && !!props.userRoutes.Routes && props.userRoutes.Routes.length > 0){
            setViewStockLedgerUrl(getUrlFromComponentKey(props.userRoutes,Keys.StockLedger));
            setViewCurrentStockUrl(getUrlFromComponentKey(props.userRoutes,Keys.Stock));
        }
    },[props.userRoutes]);

    React.useEffect(() => {
        if(!!props.loginUser.UnitAccesses){
            setUnitAccesses([...props.loginUser.UnitAccesses.filter(u => u.UnitUniqueId != AllOptionUniqueId).map(a => a)]);   
        }
    }, [props.loginUser.UnitAccesses]);


    React.useEffect(() => {
        const handleWheel = e => e.preventDefault();

        if(!!stockDetail && stockDetail.length > 0){
                inputRef.current.removeEventListener("wheel", handleWheel);
                inputRef.current.addEventListener("wheel", handleWheel);
        }

        return () => {
            if(!!inputRef && !!inputRef.current){
                inputRef.current.removeEventListener("wheel", handleWheel);
            }
        }
    },[stockDetail]);

    return (
        (!!props.selectedUnits && !!props.selectedUnits.UnitAccesses && props.selectedUnits.UnitAccesses.filter(u => u.UnitUniqueId != AllOptionUniqueId).length > 0) ? <>
        <MessageComponent onRemove={(i: number) => {
            let msgs = [...pageMessages];
            msgs.splice(i, 1);
            setPageMessages([...msgs]);
        }} messages={pageMessages} />
        <Page title="Stock | Raza Pak Care">
            <Container style={{ maxWidth: 1500, paddingLeft: 0, paddingRight: 0 }}>
                <Grid container style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>

                {(!!viewCurrentStockUrl && viewCurrentStockUrl.trim() != '') && <LoadingButton
                        size="small"
                        type="button"
                        variant="contained"
                        loading={isLoading}
                        style={{ margin: 3, backgroundColor: 'rgba(34, 154, 22, 0.72)', textAlign: 'right', height: 40 }}
                        startIcon={<Icon icon={'carbon:carbon-for-ibm-product'} />}
                        onClick={onViewCurrentStock}
                    >
                        View Current Stock
                    </LoadingButton>}

                {(!!viewStockLedgerUrl && viewStockLedgerUrl.trim() != '') && <LoadingButton
                        size="small"
                        type="button"
                        variant="contained"
                        loading={isLoading}
                        style={{ margin: 3, backgroundColor: 'blue', textAlign: 'right', height: 40 }}
                        startIcon={<Icon icon={'emojione:ledger'} />}
                        onClick={onViewStockLedger}
                    >
                        View Stock Ledger
                    </LoadingButton>}
                </Grid>

                <Grid container style={{paddingBottom:10}}>
                <Grid item md={4}></Grid>
                    <Grid item md={4} sm={12}>
                        <CustomAutoComplete<IUnitAccess>
                        Id={'Unit_List'}
                        Value={!!unitAccesses && unitAccesses.length > 0 && !!selectedUnitUniqueId && selectedUnitUniqueId.trim() != '' ? unitAccesses.find(m => m.UnitUniqueId == selectedUnitUniqueId) : null}
                        Options={unitAccesses}
                        displayField={'UnitTitle'}
                        valueField={'UnitUniqueId'}
                        Label={'Unit'}
                        onChange={onUnitChange}
                    />
                    <Grid item md={4}></Grid>
              </Grid>
                </Grid>
                {!!selectedUnit && <Card>
                    <Typography style={{ backgroundColor: 'rgba(34, 154, 22, 0.72)', color: 'white', paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h4'}>Manage Stock For {selectedUnit.UnitTitle}</Typography>
                    {isLoading == true && <AudoSpin
                        height="80"
                        width="80"
                        color='green'
                        ariaLabel='three-dots-loading'
                        wrapperStyle={{ alignSelf: 'center', justifyContent: 'center' }}
                    />}

                    {(!stockDetail || stockDetail.length <= 0 || stockDetail.filter(s => s.UnitUniqueId == selectedUnit.UnitUniqueId).length <= 0) && isLoading == false && <Typography style={{ paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h6'}>No Details Available For {selectedUnit.UnitTitle} Store.</Typography>}
                    {!!stockDetail && stockDetail.length > 0 && stockDetail.filter(s => s.UnitUniqueId == selectedUnit.UnitUniqueId).length > 0 && <Scrollbar sx={{}}>
                        <TableContainer sx={{ minWidth: 800 }}>
                            {<Table>
                                <UserListHead
                                    order={'asc'}
                                    orderBy={''}
                                    headLabel={TableHead}
                                    rowCount={0}
                                    numSelected={0}
                                    onRequestSort={undefined}
                                    onSelectAllClick={undefined}
                                    customKey={`TableHeadRow_2`}
                                />

                                <TableBody key={`Table_Body_Selection_Details_Summary`}>
                                    {stockDetail.filter(s => s.UnitUniqueId == selectedUnit.UnitUniqueId).map((row,index) => <TableRow
                                        hover
                                        key={`TableDetailRow_SUMMARY_${row.Id}`}
                                        tabIndex={-1}
                                        role="checkbox"
                                        selected={false}
                                        aria-checked={false}
                                    >
                                        <TableCell align="center">{index + 1}</TableCell>
                                        <TableCell align="center">
                                            {row.ProductName}
                                        </TableCell>
                                        <TableCell align="center"><Typography variant='subtitle2'>{`${Math.floor(row.AvailableQty / 48)} CTN ${row.AvailableQty - (Math.floor(row.AvailableQty / 48) * 48)} Pieces`}</Typography></TableCell>
                                        <TableCell align="center"><Typography variant='subtitle2'>{row.AvailableQty} Pieces</Typography></TableCell>
                                        <TableCell align='center' style={{width:row.StockUpdateReasonId == 5 ? 600 : 300,display:row.StockUpdateReasonId == 5 ? 'flex' : undefined,flexDirection:'row',justifyContent:'center'}}>
                                            <CustomAutoComplete<IStockUpdateReason>
                                                Id={'Update_reason'}
                                                style={{width:row.StockUpdateReasonId == 5 ? '48%' : '100%', marginRight:row.StockUpdateReasonId == 5 ? 5 : 0}}
                                                Value={!!StockUpdateReason && StockUpdateReason.length > 0 && !!row.StockUpdateReasonId ? StockUpdateReason.find(m => m.Id == row.StockUpdateReasonId) : null}
                                                Options={StockUpdateReason}
                                                displayField={'TypeDescription'}
                                                valueField={'Id'}
                                                Label={'Reason'}
                                                onChange={(e:IStockUpdateReason) => {
                                                    onChangeStockUpdateReason(index,e);
                                                }}
                                            />

                                            {row.StockUpdateReasonId == 5 && <CustomAutoComplete<IUnitAccess>
                                                Id={'Transfer_To_Unit'}
                                                style={{width:'48%'}}
                                                Value={!!unitAccesses && unitAccesses.length > 0 && !!row.TransferToUnitUniqueId && row.TransferToUnitUniqueId.trim() != '' ? unitAccesses.find(m => m.UnitUniqueId == row.TransferToUnitUniqueId) : null}
                                                Options={unitAccesses.filter(m => m.UnitUniqueId != selectedUnit.UnitUniqueId)}
                                                displayField={'UnitTitle'}
                                                valueField={'UnitUniqueId'}
                                                Label={'Unit To Transfer'}
                                                onChange={(e:IUnitAccess) => {
                                                    onChangeTransferToUnit(index,e);
                                                }}
                                            />}
                                        </TableCell>
                                        <TableCell align='center'>
                                        <TextField
                                            autoComplete="qty-to-update"
                                            type={'number'}
                                            label="Qty To Update"
                                            disabled={(!row.StockUpdateReasonId || (row.StockUpdateReasonId == 5 && (!row.TransferToUnitUniqueId || row.TransferToUnitUniqueId.trim() == '')))}
                                            value={!!row.StockToUpdate ? row.StockToUpdate : ''}
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                const value = event.target.value;
                                                let numberValue:number | null = null;

                                                if(value !== undefined && value != null){
                                                        numberValue = parseInt(value);
                                                }

                                                onUpdateStockToUpdate(index,numberValue);
                                        }}
                                        inputRef={inputRef}
                                        onKeyDown={(e: any) => {
                                            if (e.keyCode === 38 || e.keyCode === 40) {
                                                e.preventDefault();
                                              }
                                        }}
                                        />
                                        </TableCell>
                                    </TableRow>)}
                                    <TableRow>
                                        <TableCell colSpan={6} align='center'>
                                            <LoadingButton
                                                size="small"
                                                type="button"
                                                variant="contained"
                                                loading={isLoading}
                                                style={{ margin: 3, backgroundColor: 'blue', textAlign: 'right', height: 40 }}
                                                startIcon={<Icon icon={'carbon:carbon-for-ibm-product'} />}
                                                onClick={onUpdateStock}
                                            >
                                                Update Stock
                                            </LoadingButton>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>}
                        </TableContainer>
                    </Scrollbar>
                    }
                </Card>}
            </Container>
        </Page></> : <NoUnitSelectedComponent pageTitle='Stock | Raza Pak Care' />
    );
}


const mapStateToProps = (state: IApplicationState) => {
    return {
        selectedUnits: state.selectedUnitAccesses,
        loginUser: state.loginUser,
        userRoutes:state.userRoutes
    };
}
const connectedAddStock = connect(mapStateToProps)(AddStock);
export { connectedAddStock as AddStock };