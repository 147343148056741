import React from 'react';
import { useSelector } from 'react-redux';
import { MessageComponent } from 'src/components/messages.component';
import { areaApi, employeeApi, routeApi } from 'src/http';
import { IMessage } from 'src/interfaces/message.interface';
import { IApplicationState } from 'src/redux';
import { confirmAction } from 'src/utility/confirm.util';
import { fCommaSeparated, fCurrency } from 'src/utils/formatNumber';
import { Audio as AudoSpin } from 'react-loader-spinner'
import Page from 'src/components/Page';
import { Container, Grid } from '@mui/material';
import { CustomAutoComplete } from 'src/components/common/CustomAutoComplete';
import { IAutoComplete } from 'src/interfaces/auto-complete.interface';
import '../page-styles/route-detail.css';
import { IRouteShopDetail } from 'src/interfaces/route-shop-detail.interface';
import { ShopsComponent } from 'src/components/ShopsComponent';
import '../extension-methods/extension-methods';
import { useLocation } from 'react-router-dom';
import { RouteDetailParam, UnAssignedOrderBookerId } from 'src/app.constant';
import { decodeBase64String, getCopy } from 'src/utility';

type Filters = 'RouteShops' | 'WithSale' | 'OtherShops' | 'TotalShops' | 'WholeSaleShops' | 'RetailShops' | 'CoveredShops' | 'NeverCoveredShops' | 'CreditShops' | 'RouteShopsInOtherRoutes';
interface ITotals {
    RouteShops: number;
    TotalSale: number;
    OtherShops: number;
    TotalShops: number;
    WholeSaleShops: number;
    RetailShops: number;
    CoveredShops: number;
    NeverCoveredShops: number;
    CreditShops: number;
    TotalCredit: number;
    RouteShopsInOtherRoutes: number;
}

const shopInfoDummy = [
    { ShopId: 1010, ShopName: 'Ali K/S', IsCovered: true, LastOrderedOn: new Date(2023, 1, 1, 1, 1, 1, 1), LastVisitedOn: new Date(), Route: 'Dera Bakha', ShopAddress: 'Main Ada Dera Bakha Street Number 1', IsCollapsed: true, PendingCredit: 0, TotalSale: 10000, RouteId: 1, OrderBookerId: 2, OrderBookerName: 'Muhammad Zubair', RouteDayNumber: 1, RouteDay: 'Sunday', UnitId: 1, UnitUniqueId: '889CB2B2-9F99-4013-AA65-FADE87A0B851', UnitTitle: 'Bahawalpur', CellOne: '03008833038', CellTwo: '03312233038', CreditStatusId: 1, CreditStatus: 'Active', CreditabilityId: 1, Creditability: 'Excellent', ShopTypeId: 1, ShopType: 'K/S', AddedOn: new Date(2023, 1, 1), OrderOnCashAmount: 10000, OrderOnCreditAmount: 20000, TotalRecovery: 40000, Distance: '16 KM', ImageUrl: 'https://www.blogtyrant.com/wp-content/uploads/2019/07/how-to-start-an-online-store-min.png', IsRouteShop: true, IsOtherShop: false, IsWholeSale: true, IsInOtherRoute: false },
    { ShopId: 2020, ShopName: 'Ahmad K/S', IsCovered: false, LastOrderedOn: new Date(2023, 1, 1, 1, 1, 10, 1), LastVisitedOn: new Date(), Route: 'Islami Colony', ShopAddress: 'Kharak Chowk', IsCollapsed: true, PendingCredit: 3000, TotalSale: 0, RouteId: 1, OrderBookerId: 2, OrderBookerName: 'Muhammad Zubair', RouteDayNumber: 1, RouteDay: 'Sunday', UnitId: 1, UnitUniqueId: '889CB2B2-9F99-4013-AA65-FADE87A0B851', UnitTitle: 'Bahawalpur', CellOne: '03008833038', CellTwo: '03312233038', CreditStatusId: 1, CreditStatus: 'Active', CreditabilityId: 1, Creditability: 'Excellent', ShopTypeId: 1, ShopType: 'K/S', AddedOn: new Date(2023, 1, 1), OrderOnCashAmount: 10000, OrderOnCreditAmount: 20000, TotalRecovery: 40000, Distance: '16 KM', ImageUrl: 'https://www.blogtyrant.com/wp-content/uploads/2019/07/how-to-start-an-online-store-min.png', IsRouteShop: false, IsOtherShop: true, IsWholeSale: true, IsInOtherRoute: false },
    { ShopId: 3030, ShopName: 'Raza K/S', IsCovered: false, LastOrderedOn: new Date(2023, 1, 1, 1, 10, 1, 1), LastVisitedOn: new Date(2023, 1, 1, 1, 10, 1, 1), Route: '13 Solang', ShopAddress: 'Chandni Chowk', IsCollapsed: true, PendingCredit: 0, TotalSale: 30000, RouteId: 1, OrderBookerId: 2, OrderBookerName: 'Muhammad Zubair', RouteDayNumber: 1, RouteDay: 'Sunday', UnitId: 1, UnitUniqueId: '889CB2B2-9F99-4013-AA65-FADE87A0B851', UnitTitle: 'Bahawalpur', CellOne: '03008833038', CellTwo: '03312233038', CreditStatusId: 1, CreditStatus: 'Active', CreditabilityId: 1, Creditability: 'Excellent', ShopTypeId: 1, ShopType: 'K/S', AddedOn: new Date(2023, 1, 1), OrderOnCashAmount: 10000, OrderOnCreditAmount: 20000, TotalRecovery: 40000, Distance: '16 KM', ImageUrl: 'https://www.blogtyrant.com/wp-content/uploads/2019/07/how-to-start-an-online-store-min.png', IsRouteShop: false, IsOtherShop: false, IsWholeSale: true, IsInOtherRoute: true },
    { ShopId: 4050, ShopName: 'Dilshad K/S', IsCovered: true, LastOrderedOn: new Date(2023, 1, 1, 10, 1, 1, 1), LastVisitedOn: new Date(2023, 1, 1, 10, 1, 1, 1), Route: 'Yazman', ShopAddress: 'Yazman main chowk', IsCollapsed: true, PendingCredit: 5000, TotalSale: 40000, RouteId: 1, OrderBookerId: 2, OrderBookerName: 'Muhammad Zubair', RouteDayNumber: 1, RouteDay: 'Sunday', UnitId: 1, UnitUniqueId: '889CB2B2-9F99-4013-AA65-FADE87A0B851', UnitTitle: 'Bahawalpur', CellOne: '03008833038', CellTwo: '03312233038', CreditStatusId: 1, CreditStatus: 'Active', CreditabilityId: 1, Creditability: 'Excellent', ShopTypeId: 1, ShopType: 'K/S', AddedOn: new Date(2023, 1, 1), OrderOnCashAmount: 10000, OrderOnCreditAmount: 20000, TotalRecovery: 40000, Distance: '16 KM', ImageUrl: 'https://www.blogtyrant.com/wp-content/uploads/2019/07/how-to-start-an-online-store-min.png', IsRouteShop: false, IsOtherShop: false, IsWholeSale: true, IsInOtherRoute: true },
    { ShopId: 5040, ShopName: 'Zainab K/S', IsCovered: true, LastOrderedOn: new Date(2023, 1, 2, 1, 1, 1, 1), LastVisitedOn: new Date(2023, 1, 2, 1, 1, 1, 1), Route: 'Lodhran', ShopAddress: 'Main City Lodhran', IsCollapsed: true, PendingCredit: 6000, TotalSale: 50000, RouteId: 1, OrderBookerId: 2, OrderBookerName: 'Muhammad Zubair', RouteDayNumber: 1, RouteDay: 'Sunday', UnitId: 1, UnitUniqueId: '889CB2B2-9F99-4013-AA65-FADE87A0B851', UnitTitle: 'Bahawalpur', CellOne: '03008833038', CellTwo: '03312233038', CreditStatusId: 1, CreditStatus: 'Active', CreditabilityId: 1, Creditability: 'Excellent', ShopTypeId: 1, ShopType: 'K/S', AddedOn: new Date(2023, 1, 1), OrderOnCashAmount: 10000, OrderOnCreditAmount: 20000, TotalRecovery: 40000, Distance: '16 KM', ImageUrl: 'https://www.blogtyrant.com/wp-content/uploads/2019/07/how-to-start-an-online-store-min.png', IsRouteShop: false, IsOtherShop: true, IsWholeSale: true, IsInOtherRoute: false },
    { ShopId: 66070, ShopName: 'Rabia K/S', IsCovered: false, LastOrderedOn: new Date(2023, 2, 2, 1, 1, 1, 1), LastVisitedOn: new Date(), Route: 'Khanqah Sharif', ShopAddress: 'City Khanqah Sharif', IsCollapsed: true, PendingCredit: 7000, TotalSale: 60000, RouteId: 1, OrderBookerId: 2, OrderBookerName: 'Muhammad Zubair', RouteDayNumber: 1, RouteDay: 'Sunday', UnitId: 1, UnitUniqueId: '889CB2B2-9F99-4013-AA65-FADE87A0B851', UnitTitle: 'Bahawalpur', CellOne: '03008833038', CellTwo: '03312233038', CreditStatusId: 1, CreditStatus: 'Active', CreditabilityId: 1, Creditability: 'Excellent', ShopTypeId: 1, ShopType: 'K/S', AddedOn: new Date(2023, 1, 1), OrderOnCashAmount: 10000, OrderOnCreditAmount: 20000, TotalRecovery: 40000, Distance: '16 KM', ImageUrl: 'https://www.blogtyrant.com/wp-content/uploads/2019/07/how-to-start-an-online-store-min.png', IsRouteShop: true, IsOtherShop: false, IsWholeSale: true, IsInOtherRoute: false },
    { ShopId: 79850, ShopName: 'Labo K/S', IsCovered: true, LastOrderedOn: new Date(2022, 1, 2, 1, 1, 1, 1), LastVisitedOn: new Date(), Route: 'Ahmadpur', ShopAddress: 'Ahmad pur road', IsCollapsed: true, PendingCredit: 8000, TotalSale: 70000, RouteId: 1, OrderBookerId: 2, OrderBookerName: 'Muhammad Zubair', RouteDayNumber: 1, RouteDay: 'Sunday', UnitId: 1, UnitUniqueId: '889CB2B2-9F99-4013-AA65-FADE87A0B851', UnitTitle: 'Bahawalpur', CellOne: '03008833038', CellTwo: '03312233038', CreditStatusId: 1, CreditStatus: 'Active', CreditabilityId: 1, Creditability: 'Excellent', ShopTypeId: 1, ShopType: 'K/S', AddedOn: new Date(2023, 1, 1), OrderOnCashAmount: 10000, OrderOnCreditAmount: 20000, TotalRecovery: 40000, Distance: '16 KM', ImageUrl: 'https://www.blogtyrant.com/wp-content/uploads/2019/07/how-to-start-an-online-store-min.png', IsRouteShop: true, IsOtherShop: false, IsWholeSale: false, IsInOtherRoute: false },
    { ShopId: 8891, ShopName: 'Billa K/S', IsCovered: true, LastOrderedOn: new Date(2023, 1, 3, 1, 1, 1, 1), LastVisitedOn: new Date(), Route: 'Dhanot', ShopAddress: 'Street Number 1 Dhanot', IsCollapsed: true, PendingCredit: 9000, TotalSale: 0, RouteId: 1, OrderBookerId: 2, OrderBookerName: 'Muhammad Zubair', RouteDayNumber: 1, RouteDay: 'Sunday', UnitId: 1, UnitUniqueId: '889CB2B2-9F99-4013-AA65-FADE87A0B851', UnitTitle: 'Bahawalpur', CellOne: '03008833038', CellTwo: '03312233038', CreditStatusId: 1, CreditStatus: 'Active', CreditabilityId: 1, Creditability: 'Excellent', ShopTypeId: 1, ShopType: 'K/S', AddedOn: new Date(2023, 1, 1), OrderOnCashAmount: 10000, OrderOnCreditAmount: 20000, TotalRecovery: 40000, Distance: '16 KM', ImageUrl: 'https://www.blogtyrant.com/wp-content/uploads/2019/07/how-to-start-an-online-store-min.png', IsRouteShop: false, IsOtherShop: true, IsWholeSale: false, IsInOtherRoute: false },
    { ShopId: 42579, ShopName: 'Dabbu K/S', IsCovered: false, LastOrderedOn: new Date(2023, 1, 4, 1, 1, 1, 1), LastVisitedOn: new Date(), Route: 'Dhanot', ShopAddress: 'Sri Nagar Highway', IsCollapsed: true, PendingCredit: 10000, TotalSale: 0, RouteId: 1, OrderBookerId: 2, OrderBookerName: 'Muhammad Zubair', RouteDayNumber: 1, RouteDay: 'Sunday', UnitId: 1, UnitUniqueId: '889CB2B2-9F99-4013-AA65-FADE87A0B851', UnitTitle: 'Bahawalpur', CellOne: '03008833038', CellTwo: '03312233038', CreditStatusId: 1, CreditStatus: 'Active', CreditabilityId: 1, Creditability: 'Excellent', ShopTypeId: 1, ShopType: 'K/S', AddedOn: new Date(2023, 1, 1), OrderOnCashAmount: 10000, OrderOnCreditAmount: 20000, TotalRecovery: 40000, Distance: '16 KM', ImageUrl: 'https://www.blogtyrant.com/wp-content/uploads/2019/07/how-to-start-an-online-store-min.png', IsRouteShop: true, IsOtherShop: false, IsWholeSale: false, IsInOtherRoute: false },

];

export const RouteDetail = () => {
    const initialTotals: ITotals = {
        CoveredShops: 0,
        CreditShops: 0,
        NeverCoveredShops: 0,
        OtherShops: 0,
        RetailShops: 0,
        RouteShops: 0,
        RouteShopsInOtherRoutes: 0,
        TotalCredit: 0,
        TotalSale: 0,
        TotalShops: 0,
        WholeSaleShops: 0
    };

    const search = useLocation().search;
    const queryOrderBookerId = new URLSearchParams(search).get(RouteDetailParam.OrderBookerId);
    const queryAreaId = new URLSearchParams(search).get(RouteDetailParam.AreaId);
    const queryRouteId = new URLSearchParams(search).get(RouteDetailParam.RouteId);

    const showErrorMessage = (message: string) => addMessage(message, 'error');
    const showInfoMessage = (message: string) => addMessage(message, 'info');
    const showSuccessMessage = (message: string) => addMessage(message, 'success');
    const showWarningMessage = (message: string) => addMessage(message, 'warning');
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isLoadingShops, setIsLoadingShops] = React.useState<boolean>(false);
    const [selectedFilter, setSelectedFilter] = React.useState<Filters | null>('TotalShops');

    const [areasForAutoComplete, setAreasForAutoComplete] = React.useState<IAutoComplete[]>([]);
    const [isAreaAutoCompleteLoading, setIsAreaAutoCompleteLoading] = React.useState<boolean>(false);
    const [selectedAreaId, setSelectedAreaId] = React.useState<number>(0);
    const [orderBookersAutoComplete, setOrderBookersAutoComplete] = React.useState<IAutoComplete[]>([]);
    const [isOrderBookerAutoCompoleteLoading, setIsOrderBookerAutoCompleteLoading] = React.useState<boolean>(false);
    const [selectedOrderBookerId, setSelectedOrderBookerId] = React.useState<number>(0);
    const [routeAutoComplete, setRouteAutoComplete] = React.useState<IAutoComplete[]>([]);
    const [routeId, setRouteId] = React.useState<number>(0);
    const [isRouteAutoCompleteLoading, setIsRouteAutoCompleteLoading] = React.useState<boolean>(false);

    const [shopInfo, setShopInfo] = React.useState<IRouteShopDetail[]>([]);
    const loginUser = useSelector((state: IApplicationState) => state.loginUser);

    const [pageMessages, setPageMessages] = React.useState<IMessage[]>([]);
    const addMessage = (messageText: string, variant: 'success' | 'error' | 'info' | 'warning') => {
        if (!!messageText && messageText.trim() != '') {
            let msgs = [];
            msgs.push({
                messageText,
                variant
            });

            setPageMessages([...msgs]);
        }
    }

    const clearMessages = () => {
        setPageMessages([]);
    }

    const clearMessagesExceptSuccess = () => {
        setPageMessages((messages: IMessage[]) => [...messages.filter(pm => pm.variant == 'success')]);
    }

    const onAreaChange = (option: IAutoComplete) => {
        setSelectedAreaId(option?.Value ?? 0);
        processOnAreaChange(option?.Value ?? 0);
    }

    const processOnAreaChange = (newAreaId: number) => {
        setSelectedOrderBookerId(0);
        setOrderBookersAutoComplete([]);
        setRouteId(0);
        setRouteAutoComplete([]);
        setShopInfo([]);

        if(newAreaId > 0){
            loadOrderBookers(newAreaId, false);   
        }
    }

    const onOrderBookerChange = (option:IAutoComplete) =>{
        setSelectedOrderBookerId(option?.Value ?? 0);
        processOnOrderBookerChange(selectedAreaId, option?.Value ?? 0);
    }

    const processOnOrderBookerChange = (areaId:number, orderBookerId:number) =>{
        setRouteId(0);
        setRouteAutoComplete([]);
        setShopInfo([]);

        if(orderBookerId > 0 || orderBookerId == UnAssignedOrderBookerId){
            loadRoutes(areaId, orderBookerId, false);
        }
    }

    const onRouteChange = (option:IAutoComplete) => {
        setRouteId(option?.Value ?? 0);
        processOnRouteChange(selectedAreaId, selectedOrderBookerId, option?.Value ?? 0);
    }

    const processOnRouteChange = (areaId:number, orderBookerId:number, routeId:number) =>{
        setShopInfo([]);
        setSelectedFilter('TotalShops');

        if(routeId > 0){
            loadData(routeId);
        }
    }

    const getAndSetAreasAutoComplete = () => {
        setIsAreaAutoCompleteLoading(true);
        areaApi.getAreasAutoComplete(loginUser?.UserId ?? 0)
            .then(acomplete => {
                let items = [...acomplete];
                //items.unshift({ Value: 0, DisplayText: 'All' });
                setAreasForAutoComplete([...items]);

                if(!!queryAreaId && queryAreaId.trim() != ''){
                    const parsedAreaId = parseInt(decodeBase64String(queryAreaId));
                    setSelectedAreaId(parsedAreaId);
                    loadOrderBookers(parsedAreaId, true);
                }else{
                    if (!items || items.length > 1) {
                        setSelectedAreaId(0);
                    } else {
                        setSelectedAreaId(items[0].Value);
                        loadOrderBookers(items[0].Value, true);
                    }
                }
                setIsAreaAutoCompleteLoading(false);
            })
            .catch(e => {
                setAreasForAutoComplete([]);
                setIsAreaAutoCompleteLoading(false);
            });
    }

    const loadOrderBookers = (areaId: number, isFirstLoad:boolean) => {
        if (areaId > 0) {
            setIsOrderBookerAutoCompleteLoading(true);
            employeeApi.getOrderBookerAutoComplete(areaId)
                .then(res => {
                    if (!!res && res.length > 0) {
                        let items: IAutoComplete[] = [{ Value: UnAssignedOrderBookerId, DisplayText: 'Unassigned' }, ...res];
                        setOrderBookersAutoComplete([...items]);
                        
                        if(isFirstLoad){
                            if(!!queryOrderBookerId && queryOrderBookerId.trim() != ''){
                                const parsedOrderBookerId = parseInt(decodeBase64String(queryOrderBookerId));
                                setSelectedOrderBookerId(parsedOrderBookerId);
                                loadRoutes(areaId, parsedOrderBookerId, isFirstLoad);
                            }else{
                                if (!items || items.length > 1) {
                                    setSelectedOrderBookerId(0);
                                } else {
                                    setSelectedOrderBookerId(items[0].Value);
                                    loadRoutes(areaId, items[0].Value, isFirstLoad);
                                }
                            }
                        }
                    } else {
                        setOrderBookersAutoComplete([]);
                    }

                    setIsOrderBookerAutoCompleteLoading(false);
                })
                .catch(e => {
                    setIsOrderBookerAutoCompleteLoading(false);
                    setOrderBookersAutoComplete([]);
                });
        } else {
            setOrderBookersAutoComplete([]);
        }
    }

    const loadRoutes = (areaId: number, orderBookerId: number, isFirstLoad:boolean) => {
        if (areaId > 0) {
            setIsRouteAutoCompleteLoading(true);
            routeApi.getRouteAutoComplete(loginUser?.UserId ?? 0, areaId, orderBookerId == UnAssignedOrderBookerId ? null : orderBookerId)
                .then(res => {
                    if (!!res && res.length > 0) {
                        let items: IAutoComplete[] = [...res];
                        setRouteAutoComplete([...items]);
                        
                        if(isFirstLoad){
                            if(!!queryRouteId && queryRouteId.trim() != ''){
                                const parsedRouteId = parseInt(decodeBase64String(queryRouteId));
                                setRouteId(parsedRouteId);
                                loadData(parsedRouteId);
                            }else{
                                if (!items || items.length > 1) {
                                    setRouteId(0);
                                } else {
                                    setRouteId(items[0].Value);
                                    loadData(items[0].Value);
                                }
                            }
                        }
                        
                    } else {
                        setRouteAutoComplete([]);
                    }
                    setIsRouteAutoCompleteLoading(false);
                })
                .catch(e => {
                    setIsRouteAutoCompleteLoading(false);
                    setRouteAutoComplete([]);
                });
        } else {
            setRouteAutoComplete([]);
        }
    }

    const totals = React.useMemo(() => {
        if (!!shopInfo && shopInfo.length > 0) {
            let copy = getCopy(shopInfo);
            let t: ITotals = {
                CoveredShops: copy.count(m => m.IsCovered == true && m.IsInOtherRoute == false),
                CreditShops: copy.count(m => (m.PendingCredit ?? 0) > 0 && m.IsInOtherRoute == false),
                NeverCoveredShops: copy.count(m => m.IsCovered == false && m.IsInOtherRoute == false),
                OtherShops: copy.count(m => m.IsOtherShop == true && m.IsInOtherRoute == false),
                RetailShops: copy.count(m => m.IsWholesale == false && m.IsInOtherRoute == false),
                RouteShops: copy.count(m => m.IsRouteShop == true && m.IsInOtherRoute == false),
                RouteShopsInOtherRoutes: copy.count(m => m.IsInOtherRoute == true),
                TotalCredit: copy.filter(m => m.IsInOtherRoute == false).sum(m => (m.PendingCredit ?? 0)),
                TotalSale: copy.filter(m => m.IsInOtherRoute == false).sum(m => (m.TotalSale ?? 0)),
                TotalShops: copy.count(m => (m.IsRouteShop == true || m.IsOtherShop == true) && m.IsInOtherRoute == false),
                WholeSaleShops: copy.count(m => m.IsWholesale == true && m.IsInOtherRoute == false)
            }
            return t;
        } else {
            return {...initialTotals};
        }
    },[shopInfo]);

    const loadData = (routeId:number) => {
        if (routeId > 0) {
            setIsLoadingShops(true);
            routeApi.getRouteShopsAsync(routeId)
                .then(res => {
                    if (!!res && res.length > 0) {
                        setShopInfo([...res]);
                    } else {
                        resetShopDetails();
                    }
                    setIsLoadingShops(false)
                    //setTimeout(() => {setIsLoadingShops(false)},1);
                })
                .catch(e => {
                    setIsLoadingShops(false)
                    resetShopDetails();
                });
        } else {
            setIsLoadingShops(false);
            resetShopDetails();
        }
    }

    const resetShopDetails = () => {
        setShopInfo([]);
        setSelectedFilter('TotalShops');
    }

    const onFilterChange = (newValue: Filters) => {
        if (newValue != selectedFilter) {
            setSelectedFilter(newValue);
        }
    }

    const setShopsOnAction = (shops:IRouteShopDetail[]) =>{
        setShopInfo(getCopy(shops));
    }

    React.useEffect(() => {
        getAndSetAreasAutoComplete();
    }, []);

    return <Page title="Route Detail | Raza Pak Care">
        <Container style={{ paddingBottom: 20, maxWidth: '100%' }}><MessageComponent onRemove={(i: number) => {
            let msgs = [...pageMessages];
            msgs.splice(i, 1);
            setPageMessages([...msgs]);
        }} messages={pageMessages} />
        </Container>
        <Container style={{ paddingBottom: 10, maxWidth: '100%' }}>
            <Grid container spacing={2}>
                <Grid item lg={4} xl={3} xs={12}>
                    {<CustomAutoComplete<IAutoComplete>
                        Id={'Area_List_Auto_Complete'}
                        Value={!!areasForAutoComplete && areasForAutoComplete.length > 0 && selectedAreaId > 0 ? areasForAutoComplete.find(m => m.Value == selectedAreaId) : null}
                        Options={areasForAutoComplete}
                        displayField={'DisplayText'}
                        valueField={'Value'}
                        Label={'Areas'}
                        onChange={onAreaChange}
                        size='small'
                        isLoading={isAreaAutoCompleteLoading}
                        readOnly={isAreaAutoCompleteLoading || isOrderBookerAutoCompoleteLoading || isRouteAutoCompleteLoading || isLoadingShops}
                    />}
                </Grid>
                <Grid item lg={4} xl={3} xs={12}>
                    {<CustomAutoComplete<IAutoComplete>
                        Id={'Order_booker_AutoComplete'}
                        Value={!!orderBookersAutoComplete && orderBookersAutoComplete.length > 0 && selectedOrderBookerId != 0 ? orderBookersAutoComplete.find(m => m.Value == selectedOrderBookerId) : null}
                        Options={orderBookersAutoComplete ?? []}
                        displayField={'DisplayText'}
                        valueField={'Value'}
                        Label={'Order Booker'}
                        onChange={onOrderBookerChange}
                        size='small'
                        isLoading={isOrderBookerAutoCompoleteLoading}
                        readOnly={isAreaAutoCompleteLoading || isOrderBookerAutoCompoleteLoading || isRouteAutoCompleteLoading || isLoadingShops}
                    />}
                </Grid>
                <Grid item lg={4} xl={3} xs={12}>
                    {<CustomAutoComplete<IAutoComplete>
                        Id={'Route_List'}
                        Value={!!routeAutoComplete && routeAutoComplete.length > 0 && routeId > 0 ? routeAutoComplete.find(m => m.Value == routeId) : null}
                        Options={routeAutoComplete}
                        displayField={'DisplayText'}
                        valueField={'Value'}
                        Label={'Route'}
                        onChange={onRouteChange}
                        size='small'
                        isLoading={isRouteAutoCompleteLoading}
                        readOnly={isAreaAutoCompleteLoading || isOrderBookerAutoCompoleteLoading || isRouteAutoCompleteLoading || isLoadingShops}
                    />}
                </Grid>
            </Grid>
        </Container>

        {isLoading == true && <AudoSpin
            height="80"
            width="80"
            color='green'
            ariaLabel='three-dots-loading'
            wrapperStyle={{ alignSelf: 'center', justifyContent: 'center' }}
        />}

        <Container style={{ paddingBottom: 10, maxWidth: '100%' }}>
            <span onClick={() => { onFilterChange('RouteShops'); }} className={selectedFilter == 'RouteShops' ? 'btn-selected-filter' : 'btn-text'}>Route Shops: {totals.RouteShops}</span>
            <span onClick={() => { onFilterChange('OtherShops'); }} className={selectedFilter == 'OtherShops' ? 'btn-selected-filter' : 'btn-text'}>Other Shops: {totals.OtherShops}</span>
            <span onClick={() => { onFilterChange('TotalShops'); }} className={selectedFilter == 'TotalShops' ? 'btn-selected-filter' : 'btn-text'}>T-Shops: {totals.TotalShops}</span>
            <span onClick={() => { onFilterChange('WholeSaleShops'); }} className={selectedFilter == 'WholeSaleShops' ? 'btn-selected-filter' : 'btn-text'}>W.H Sale Shops: {totals.WholeSaleShops}</span>
            <span onClick={() => { onFilterChange('RetailShops'); }} className={selectedFilter == 'RetailShops' ? 'btn-selected-filter' : 'btn-text'}>Retail Shops: {totals.RetailShops}</span>
            <span onClick={() => { onFilterChange('CoveredShops'); }} className={selectedFilter == 'CoveredShops' ? 'btn-selected-filter' : 'btn-text'} style={{ color: 'green' }}>Covrd. Shops: {totals.CoveredShops}</span>
            <span onClick={() => { onFilterChange('NeverCoveredShops'); }} className={selectedFilter == 'NeverCoveredShops' ? 'btn-selected-filter' : 'btn-text'} style={{ color: 'red' }}>Nvr.Cvrd. Shops: {totals.NeverCoveredShops}</span>
            <span onClick={() => { onFilterChange('CreditShops'); }} className={selectedFilter == 'CreditShops' ? 'btn-selected-filter' : 'btn-text'} style={{ color: 'red' }}>Crdt. Shops: {totals.CreditShops}</span>
            <span onClick={() => { onFilterChange('CreditShops'); }} className={selectedFilter == 'CreditShops' ? 'btn-selected-filter' : 'btn-text'} style={{ color: 'red' }}>T-Credit: {fCommaSeparated(totals.TotalCredit)}</span>
            <span onClick={() => { onFilterChange('WithSale'); }} className={selectedFilter == 'WithSale' ? 'btn-selected-filter' : 'btn-text'} style={{ color: 'blue' }}>T-Sale: {fCommaSeparated(totals.TotalSale)}</span>
            <span onClick={() => { onFilterChange('RouteShopsInOtherRoutes'); }} className={selectedFilter == 'RouteShopsInOtherRoutes' ? 'btn-selected-filter' : 'btn-text'}>Route Shops In Other Routes: {totals.RouteShopsInOtherRoutes}</span>

        </Container>

        <ShopsComponent
            isLoading={isLoadingShops}
            shops={shopInfo}
            filter={selectedFilter}
            areaId={selectedAreaId}
            isRouteDetailPage={true}
            selectedRouteId={routeId}
            loginUserId={loginUser?.UserId ?? 0}
            setFilter={setSelectedFilter}
            showErrorMessage={showErrorMessage}
            showInfoMessage={showInfoMessage}
            showSuccessMessage={showSuccessMessage}
            showWarningMessage={showWarningMessage}
            setShopsOnAction={setShopsOnAction}
            key={`ShopsComponentKey`}
        />
    </Page>;
}