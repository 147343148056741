import { Card, CircularProgress, Container, Grid, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import React from 'react';
import { AddNewTabletPopup } from 'src/components/AddNewTabletPopup';
import { EditTabletStatusPopup } from 'src/components/EditTabStatusPopup';
import Page from 'src/components/Page';
import { UserListHead } from 'src/components/_dashboard/user';
import { Highlighted } from 'src/components/common/highlighted.component';
import { ITabletInfo } from 'src/interfaces/tablet-info.interface';
import { getCopy, formatedDateTime } from 'src/utility';
import '../page-styles/recovery-target.css';
import { IEmployeeRecovery } from 'src/interfaces/employee-recovery.interface';
import { useSelector } from 'react-redux';
import { IApplicationState } from 'src/redux';
import { doCreditApi } from 'src/http';
import { fCommaSeparated, fCommaSeparatedWithDecial } from 'src/utils/formatNumber';
import { LoadingButton } from '@mui/lab';
import { Icon } from '@iconify/react';
import { LinearProgressComponent } from 'src/components/common/LinearProgressComponent';
import { CustomAutoComplete } from 'src/components/common/CustomAutoComplete';
import { IAutoComplete } from 'src/interfaces/auto-complete.interface';
import { OrderReportComparison } from 'src/components/OrderReportComparison';
import { isRestrictedLogin } from 'src/app.constant';


export const RecoveryTargetNewContainer = () => {

    const [recovery, setRecovery] = React.useState<IEmployeeRecovery[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const loginUser = useSelector((state: IApplicationState) => state.loginUser);
    const [selectedEmployeeId, setSelectedEmployeeId] = React.useState<number>(0);
    const [refreshedOn, setRefreshedOn] = React.useState<Date>();
    
    const loadData = (userId: number) => {
        setRecovery([]);
        setIsLoading(true);
        doCreditApi.getRecoveryTargets(userId)
            .then(res => {
                setIsLoading(false);
                setRecovery(getCopy(res));
            })
            .catch(e => {
                setIsLoading(false);
                console.log(e);
            })
    }

    const employeeAutoComplete = React.useMemo(() =>{
        let options:IAutoComplete[] = [];
        if(!!recovery && recovery.length > 0){
            options.push({
                DisplayText:'All',
                Value:0
            });

            recovery.forEach(rec => {
                options.push({
                    DisplayText:`${rec.EmployeeName} (${rec.Designation} ${rec.UnitShortName})`,
                    Value:rec.EmployeeId
                })
            });
        }

        return getCopy(options);
    },[recovery]);

    const selectedEmployee = React.useMemo(() => {
        if(!!recovery && recovery.length > 0 && !!selectedEmployeeId && selectedEmployeeId > 0 && recovery.findIndex(m => m.EmployeeId === selectedEmployeeId) >= 0){
            const item = getCopy(recovery.find(n => n.EmployeeId === selectedEmployeeId));
            return {
                employeeId:item.DesignationId !== 8 ? item.EmployeeId : null,
                isOverall: item.DesignationId === 8 ? true : false,
            };
        }

        return {
            employeeId:-1,
            isOverall: false,
        };
    },[selectedEmployeeId, recovery]);

    const filteredResults = React.useMemo(() =>{
        let copy = getCopy(recovery).filter(m => selectedEmployeeId === 0 || m.EmployeeId === selectedEmployeeId);
        return copy;
    },[recovery, selectedEmployeeId]);

    React.useEffect(() => {
        loadData(loginUser.UserId ?? 0);
    }, [loginUser]);

    return <Page title="Recovery Target | Raza Pak Care">
        <Container style={{ paddingBottom: 10, marginTop: 10, }}>



            <Typography style={{ color: '#521607', padding: 5, textAlign: 'center', }} variant={'subtitle1'}>Reovery Targets</Typography>
            {isLoading === true && <Grid container>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
                    <CircularProgress />
                </Grid>
            </Grid>}

            {isLoading === false && <Grid container style={{paddingLeft:12, paddingRight:12}}>
                <Grid item lg={4} md={6}>
                    <CustomAutoComplete<IAutoComplete>
                        Id={'employees_autocompolete'}
                        Value={!!employeeAutoComplete && employeeAutoComplete.length > 0 ? employeeAutoComplete.find(m => m.Value == selectedEmployeeId) : null}
                        Options={employeeAutoComplete}
                        displayField={'DisplayText'}
                        valueField={'Value'}
                        Label={'Officers'}
                        onChange={(option: IAutoComplete) => { 
                            setSelectedEmployeeId(option?.Value ?? 0);
                        }}
                        size='small'
                    />
                </Grid>
                <Grid item lg={8} md={6} style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row' }}>
                    <LoadingButton
                        size="small"
                        type="button"
                        variant="contained"
                        loading={false}
                        style={{ alignSelf: 'flex-end', margin: 3, backgroundColor: 'green', textAlign: 'right' }}
                        startIcon={<Icon icon={'ep:refresh'} />}
                        onClick={() => {
                            loadData(loginUser?.UserId ?? 0);
                            setRefreshedOn(new Date());
                        }}
                        key={`Refresh`}
                    >
                        Refresh
                    </LoadingButton>
                </Grid>
            </Grid>}

            {!!filteredResults && filteredResults.length > 0 && isLoading === false && filteredResults.map(rec => {
                return <TableContainer key={`Container_${rec.EmployeeId}`} sx={{ minWidth: 800, maxWidth: '100%', padding: 2 }}>
                    <Table size="small" key={`${rec.EmployeeId}_rm_`} stickyHeader sx={{ marginBottom: 2 }}>
                            <TableBody>
                                <TableRow>
                                    <TableCell className='head-table-cell-new' style={{ borderLeft: '1px solid #521607' }} width="15%">Prev. Rem</TableCell>
                                    <TableCell className='head-table-cell-new' width="8%">T.Target</TableCell>
                                    <TableCell className='head-table-cell-new' width="8%">Achieved</TableCell>
                                    <TableCell className='head-table-cell-new' width="8%">Rem.</TableCell>
                                    <TableCell className='head-table-cell-new' width="15%" align='center'>{`${rec.EmployeeName} (${rec.Designation} ${rec.UnitShortName})`}</TableCell>
                                    <TableCell className='head-table-cell-new' style={{ borderRight: '1px solid #521607' }} align='right'><span style={{fontSize:12}}>Crdt.Limit:&nbsp;{fCommaSeparated(rec.CreditLimit)} &nbsp; Used:&nbsp;{fCommaSeparated(rec.UsedLimit)} &nbsp; Rem:&nbsp;{fCommaSeparated(rec.CreditLimit - rec.UsedLimit)}</span>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ color: 'red', borderLeft: '1px solid #521607' }} className='normal-table-cell-new clickable-text' width="10%">{fCommaSeparated(rec.PreviousTargetRunningAmount)}</TableCell>
                                    <TableCell style={{ color: 'blue' }} className='normal-table-cell-new' width="8%">{fCommaSeparated(rec.OverallTargetAmount)}</TableCell>
                                    <TableCell style={{ color: 'green' }} className='normal-table-cell-new' width="8%">{fCommaSeparated(rec.OverallAchievedAmount)}</TableCell>
                                    <TableCell style={{ color: (rec.OverallTargetAmount - rec.OverallAchievedAmount) > 0 ? 'red' : 'green' }} className='normal-table-cell-new' width="15%">{fCommaSeparated(rec.OverallTargetAmount - rec.OverallAchievedAmount)}</TableCell>
                                    <TableCell colSpan={2} style={{ borderBottom: '1px solid #521607', borderRight: '1px solid #521607' }}><LinearProgressComponent value={((rec.OverallAchievedAmount * (rec.OverallTargetAmount > 0 ? 1 : 0)) / (rec.OverallTargetAmount > 0 ? rec.OverallTargetAmount : 1)) * 100} /><span style={{color:'red'}}>Est. Recovery Fine: {fCommaSeparated(rec.EstimatedRecoveryFine)}</span></TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell style={{ borderLeft: '1px solid #521607' }} className='normal-table-cell-new clickable-text' width="10%"><strong>Sale Target (ctn):</strong></TableCell>
                                    <TableCell style={{ color: 'blue' }} className='normal-table-cell-new' width="8%">{fCommaSeparatedWithDecial(rec.SaleTargetCtn.toFixed(2))}</TableCell>
                                    <TableCell style={{ color: 'green' }} className='normal-table-cell-new' width="8%">{fCommaSeparatedWithDecial(rec.SaleAchievedCtn.toFixed(2))}</TableCell>
                                    <TableCell style={{ color: (rec.OverallTargetAmount - rec.OverallAchievedAmount) > 0 ? 'red' : 'green' }} className='normal-table-cell-new' width="15%">{fCommaSeparatedWithDecial(rec.SaleRemainingCtn.toFixed(2))}</TableCell>
                                    <TableCell colSpan={2} style={{ borderBottom: '1px solid #521607', borderRight: '1px solid #521607' }}><LinearProgressComponent value={rec.SaleAchievedPercentage} /> <span style={{fontSize:'10px'}}>{rec.FineDescription}</span><span style={{color:'red'}}>{` Target Fine: ${fCommaSeparated(rec.SaleTargetFine)}`}</span></TableCell>
                                </TableRow>

                            </TableBody>
                        </Table>
                </TableContainer>
            })}
        </Container>

        {!isRestrictedLogin(loginUser?.UserId ?? 0) && <OrderReportComparison isLoadingParent={isLoading} refreshedOn={refreshedOn} employeeId={selectedEmployee.employeeId} isOverall={selectedEmployee.isOverall} />}
    </Page>;
}