import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IApplicationState } from 'src/redux';
import { useSelector } from 'react-redux';
import { CircularProgress, Container, Grid, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { IAutoComplete } from 'src/interfaces/auto-complete.interface';
import { CustomAutoComplete } from './common/CustomAutoComplete';
import { employeeApi, masterDataApi, routeApi } from 'src/http';
import { shopApi } from 'src/http/shop.http';
import { IRouteShopDetail } from 'src/interfaces/route-shop-detail.interface';
import { IShopUpdateRequest } from 'src/interfaces/shop-update-request.interface';
import { getCopy, isValidCellNumber, isValidIMEI } from 'src/utility';
import { IMessage } from 'src/interfaces/message.interface';
import { MessageComponent } from './messages.component';
import { ITabletInfo } from 'src/interfaces/tablet-info.interface';
import { tabletApi } from 'src/http/tablet.http';
import { IEnumOld } from 'src/interfaces/enum-old.interface';

interface IProps {
    tabletId:number;
    tabName:string;
    tabStatusId:number;
    tabStatuses:IEnumOld[];
    unitTitle:string;
    onClose: () => void;
    onSuccess: (tabletId:number, newTabStatusId:number, newTabStatus:string, comments:string, commentById:number, commentedOn:Date) => void;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    paddingTop: 2,
    paddingBottom: 2
};

export const EditTabletStatusPopup = (props: IProps) => {
    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick") {
            return;
        }

        props.onClose();
    }

    const loginUser = useSelector((state: IApplicationState) => state.loginUser);
    const [selectedTabStatusId, setSelectedTabStatusId] = React.useState<number>(props.tabStatusId);
    const [selectedTabStatus, setSelectedTabStatus] = React.useState<string>('');
    const [comments, setComments] = React.useState<string>('');

    const [pageMessages, setPageMessages] = React.useState<IMessage[]>([]);
    const addMessage = (messageText: string, variant: 'success' | 'error' | 'info' | 'warning') => {
        if (!!messageText && messageText.trim() != '') {
            let msgs = [];
            msgs.push({
                messageText,
                variant
            });

            setPageMessages([...msgs]);
        }
    }

    const showErrorMessage = (message: string) => addMessage(message, 'error');
    const showInfoMessage = (message: string) => addMessage(message, 'info');
    const showSuccessMessage = (message: string) => addMessage(message, 'success');
    const showWarningMessage = (message: string) => addMessage(message, 'warning');



    const onChangeTabStatus = (option:IEnumOld) => {
        setPageMessages([]);
        setSelectedTabStatusId(option?.Id ?? 0);
        setSelectedTabStatus(option?.EnumDescription ?? '');
    }

    const onChangeComments = (event: any) => {
        setPageMessages([]);
        setComments(event.target.value);
    }

    const validate = ():Promise<boolean> => {
        return new Promise((resolve,reject) => {
            setIsLoading(true);
            if (!selectedTabStatusId || selectedTabStatusId <= 0) {
                showErrorMessage('Tab Status is Required.');
                setIsLoading(false);
                resolve(false);
            }else if(props.tabStatusId === selectedTabStatusId){
                showErrorMessage("Tab is already in the same status.");
                setIsLoading(false);
                resolve(false);
            }else if(!comments || comments.trim() === ''){
                showErrorMessage('Comments Required.');
                setIsLoading(false);
                resolve(false);
            }else if(comments.trim().length < 10){
                showErrorMessage("Comments must be at least 10 characters long.");
                setIsLoading(false);
                resolve(false);
            }
            else{
                resolve(true);
            }
        });
    }


    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const onSave = () => {
        validate().then(isvalid => {
            if(isvalid){
                tabletApi.updateTabStatus(props.tabletId, selectedTabStatusId,comments,loginUser?.UserId ?? 0)
                    .then((addedResult) => {
                        setIsLoading(false);
                        props.onSuccess(props.tabletId, selectedTabStatusId, selectedTabStatus, comments, loginUser?.UserId ?? 0, new Date());
                        showSuccessMessage('Updated Successfully')
                    })
                    .catch((e) => {
                        console.log(e);
                        showErrorMessage('Error while updating tablet status.');
                        setIsLoading(false);
                    });
            }
        })
    }

    return <Modal
        open={!!props.tabletId && props.tabletId > 0}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={style}>
            <Grid item md={12} style={{ justifyContent: 'center', display: 'flex', flexDirection:'column' }}>
                <Typography style={{ alignSelf: 'center' }} id="modal-modal-title" variant="h6" component="h2">
                    Update Tablet
                </Typography>
                <Typography style={{ alignSelf: 'center' }} id="modal-modal-title" variant="subtitle1" component="span">
                    {props?.unitTitle ?? ''}
                </Typography>
                <Typography style={{ alignSelf: 'center' }} id="modal-modal-title" variant="subtitle1" component="span">
                    {`Tab ID: ${(props.tabName ?? '')}`}
                </Typography>
            </Grid>

            <Container>
                {(isLoading === true || (!!pageMessages && pageMessages.length > 0)) && <Grid container spacing={2} style={{ marginTop: 2 }}>
                {isLoading === true && <Grid item md={12} style={{ justifyContent: 'center', display: 'flex' }}>
                    <CircularProgress />
                </Grid>}
                {!!pageMessages && pageMessages.length > 0 && <Grid item md={12} xs={12}>
                    <MessageComponent onRemove={(i: number) => {
                        let msgs = [...pageMessages];
                        msgs.splice(i, 1);
                        setPageMessages([...msgs]);
                    }} messages={pageMessages} /></Grid>}
                </Grid>}
                <Grid container spacing={2} style={{ marginTop: 2 }}>
                    <Grid item md={12} xs={12}>
                    {<CustomAutoComplete<IEnumOld>
                            Id={'tab_statuses_autocomplete'}
                            Value={!!props.tabStatuses && props.tabStatuses.length > 0 ? props.tabStatuses.find(m => m.Id === selectedTabStatusId) ?? null : null}
                            Options={props.tabStatuses}
                            displayField={'EnumDescription'}
                            valueField={'Id'}
                            Label={'Tab Status'}
                            onChange={onChangeTabStatus}
                            size='small'
                            disabled={isLoading}
                            isLoading={false}
                        />}
                    </Grid>

                    <Grid item md={12} xs={12}>
                        <TextField
                            id={'Comments'}
                            fullWidth
                            placeholder={'Enter Comments'}
                            label={'Comments'}
                            multiline={true}
                            rows={3}
                            value={comments}
                            onChange={onChangeComments}
                            InputProps={{ style: { fontSize: '12px' } }}
                        />
                    </Grid>
                </Grid>

                <Grid item md={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <LoadingButton
                        size="small"
                        type="button"
                        variant="outlined"
                        loading={isLoading}
                        disabled={isLoading}
                        style={{ marginTop: 15, alignSelf: 'center', marginRight: 10, borderRadius: 0 }}
                        onClick={() => {
                            onSave();
                        }}
                    >
                        Save
                    </LoadingButton>

                    <LoadingButton
                        size="small"
                        type="button"
                        variant="outlined"
                        loading={false}
                        disabled={isLoading}
                        style={{ marginTop: 15, alignSelf: 'center', color: 'gray', borderRadius: 0, borderColor: 'gray' }}
                        onClick={props.onClose}
                    >
                        Close
                    </LoadingButton>
                </Grid>
            </Container>
        </Box>
    </Modal>;
}