import React, { ReactElement } from 'react';
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { ErrorComponent } from '../google-map/error.component';
import { SpinnerComponent } from 'src/components/common/spinner.component';
import { DefaultMapZoom, MapKey, NewRouteSetupPageParam } from 'src/app.constant';
import { NewRouteSetupComponent } from 'src/components/google-map/new-route-setup.component';
import { useLocation, useNavigate, useNavigation } from 'react-router-dom';
import { decodeBase64String } from 'src/utility';

// const render = (status: Status): ReactElement => {
//     if (status === Status.FAILURE) return <ErrorComponent />;
//     return <SpinnerComponent />;
//   };
  
  interface IParams{
    areaId:number;
    areaName:string;
    routeId:number;
    orderBookerId:number;
  }

export const NewRouteSetupOnMapContainer = () => {
  const search = useLocation().search;
  const navigate = useNavigate();
  const [params, setParams] = React.useState<IParams>(null);
  const [isPageLoaded, setIsPageLoaded] = React.useState<boolean>(false);

  const render = (status) => {
    switch (status) {
      case Status.LOADING:
        return <SpinnerComponent />;
      case Status.FAILURE:
        return <ErrorComponent />;
      case Status.SUCCESS:
        {return !!params && !!params.areaId && !!params.areaName ? <NewRouteSetupComponent
        areaId={params.areaId}
        areaName={params.areaName}
        isNew={1}
        zoom={10}
        routeId={params.routeId}
        orderBookerId={params.orderBookerId}
        center={{ lat: 29.386461717608718, lng: 71.68734480932993 }} /> : <SpinnerComponent />};
    }
  };

  React.useEffect(() => {
    if(!!search){
      const queryAreaId = !!search ? new URLSearchParams(search).get(NewRouteSetupPageParam.AreaId) : "0";
      const decodedAreaId = !!queryAreaId ? decodeBase64String(queryAreaId) : "0";
      const areaId = !!decodedAreaId ? parseInt(decodedAreaId) : 0;
      const queryAreaName = !!search ? new URLSearchParams(search).get(NewRouteSetupPageParam.AreaName) : "";
      const areaName = !!queryAreaName ? decodeBase64String(queryAreaName).trim() : "";
    
      const queryobid = !!search ? new URLSearchParams(search).get(NewRouteSetupPageParam.OrderBookerId) : "0";
      const decodedobid = !!queryobid ? decodeBase64String(queryobid) : "0";
      const orderBookerId = !!decodedobid ? parseInt(decodedobid) : 0;
    
      const queryrid = !!search ? new URLSearchParams(search).get(NewRouteSetupPageParam.RouteId) : "0";
      const decodedrid = !!queryrid ? decodeBase64String(queryrid) : "0";
      const routeId = !!decodedrid ? parseInt(decodedrid) : 0;
  
      if(!areaId || areaId <= 0){
        navigate('/');
      }else{
        setParams({
          areaId,
          areaName,
          routeId,
          orderBookerId
        })
      }
    }else{
      navigate('/');
    }
  },[search]);

    return <Wrapper  libraries={['drawing','geometry']} apiKey={MapKey} render={render} />;
}