import React from 'react';
import { connect, useSelector } from 'react-redux';
import { MessageComponent } from 'src/components/messages.component';
import { areaApi, employeeApi, routeApi } from 'src/http';
import { IMessage } from 'src/interfaces/message.interface';
import { IApplicationState } from 'src/redux';
import { encodeBase64String, getCopy } from 'src/utility';
import { confirmAction } from 'src/utility/confirm.util';
import { fCurrency } from 'src/utils/formatNumber';
import Page from 'src/components/Page';
import { Card, CircularProgress, Container, Grid, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { CustomAutoComplete } from 'src/components/common/CustomAutoComplete';
import { IAutoComplete } from 'src/interfaces/auto-complete.interface';
import { LoadingButton } from '@mui/lab';
import { Icon } from '@iconify/react';
import { UserListHead } from 'src/components/_dashboard/user';
import '../page-styles/route-setup.css';
import { getUrlFromComponentKey } from 'src/utility/navigation.util';
import { Keys } from 'src/component.keys';
import { useNavigate } from 'react-router-dom';
import { NewRouteSetupPageParam, RouteDayDataSource, RouteDetailParam, UnAssignedOrderBookerId } from 'src/app.constant';
import { IRouteInfoNew } from 'src/interfaces/route-info-new.interface';
import { cancelEditOrderBookerRouteInfoAction, editOrderBookerRouteInfoAction, hardReloadAreaOrderBookerInfoAction, setSelectedAreaIdAction, softReloadAreaOrderBookerInfoAction, toggleOrderBookerRowCollapseAction, unAssignRouteAction, updateRouteAssignmentAction } from 'src/redux/actions/area-wise-order-booker-info.action';


const ORDER_BOOKER_TABLE_HEAD = [
    { id: 'employee_name', label: 'Name', alignContent: 'left' },
    { id: 'assigned_routes', label: 'Assig. Routes', alignContent: 'left' },
    { id: 'total_shops', label: 'T.Shops', alignContent: 'left' },
    { id: 'attached_shops', label: 'Cvrd.Shops', alignContent: 'left' },
    { id: 'never_attached_shops', label: 'Nvr.Cvrd.Shops', alignContent: 'left' },
    { id: 'action_button', label: '', alignContent: 'left' },
];

const ROUTES_TABLE_HEAD = [
    { id: 'route_day', label: 'Day', alignContent: 'left' },
    { id: 'route_name', label: 'Route Name', alignContent: 'left' },
    { id: 'credit', label: 'Pend. Credit', alignContent: 'left' },
    { id: 'total_shops', label: 'T. Shops', alignContent: 'left' },
    { id: 'attached_shops', label: 'Cvrd. Shops', alignContent: 'left' },
    { id: 'never_attached_shops', label: 'Nvr. Cvrd. Shops', alignContent: 'left' },
    { id: 'action_btn', label: 'Action', alignContent: 'right' },
];

const dummayData = [
    {
        AttachedShops: 200, EmployeeId: 2, EmployeeName: 'Muhammad Zubair', NeverAttachedShops: 300, NumberOfRoutes: 6, TotalShops: 500, Routes: [
            { AreaId: 1, AttachedShops: 10, NeverAttachedShops: 70, PendingCredit: 10000, RouteDay: 1, RouteDayName: 'Sunday', RouteId: 0, RouteName: 'Kachi Basti -To - Chak 29 - Chak 12 bc - 13 Solang - ', TotalShops: 80, IsEditingAny: false, EditMode: false },
            { AreaId: 1, AttachedShops: 10, NeverAttachedShops: 70, PendingCredit: 12000, RouteDay: 2, RouteDayName: 'Monday', RouteId: 163, RouteName: 'Taj Hotel To Lodhran', TotalShops: 80, IsEditingAny: false, EditMode: false },
            { AreaId: 1, AttachedShops: 10, NeverAttachedShops: 70, PendingCredit: 10000, RouteDay: 3, RouteDayName: 'Tuesday', RouteId: 165, RouteName: 'Goth Bajan+Munshiwala+Nunari+Samma Satta To Basti Suleman', TotalShops: 80, IsEditingAny: false, EditMode: false },
            { AreaId: 1, AttachedShops: 10, NeverAttachedShops: 70, PendingCredit: 12000, RouteDay: 4, RouteDayName: 'Wednesday', RouteId: 155, RouteName: 'Khuram Pump - 10 BC To Dera Bakha', TotalShops: 80, IsEditingAny: false, EditMode: false },
            { AreaId: 1, AttachedShops: 10, NeverAttachedShops: 70, PendingCredit: 10000, RouteDay: 5, RouteDayName: 'Thursday', RouteId: 156, RouteName: 'Tibba Badar Sher-TO-Mohallah Gulshan Habib', TotalShops: 80, IsEditingAny: false, EditMode: false },
            { AreaId: 1, AttachedShops: 10, NeverAttachedShops: 70, PendingCredit: 12000, RouteDay: 6, RouteDayName: 'Saturday', RouteId: 160, RouteName: 'Sadar Pully To Islami Cly', TotalShops: 80, IsEditingAny: false, EditMode: false },
        ], IsOrderBookerCollapsed: true
    },
    {
        AttachedShops: 300, EmployeeId: 19, EmployeeName: 'Shoaib Imtiaz', NeverAttachedShops: 400, NumberOfRoutes: 6, TotalShops: 700, Routes: [
            { AreaId: 1, AttachedShops: 10, NeverAttachedShops: 70, PendingCredit: 10000, RouteDay: 1, RouteDayName: 'Sunday', RouteId: 119, RouteName: '26BC - 27BC - 24BC - 23BC - Chak 42 Ada-41 DB-44 DB', TotalShops: 80, IsEditingAny: false, EditMode: false },
            { AreaId: 1, AttachedShops: 10, NeverAttachedShops: 70, PendingCredit: 12000, RouteDay: 2, RouteDayName: 'Monday', RouteId: 108, RouteName: 'University Chok- Girls Clg Road', TotalShops: 80, IsEditingAny: false, EditMode: false },
            { AreaId: 1, AttachedShops: 10, NeverAttachedShops: 70, PendingCredit: 10000, RouteDay: 3, RouteDayName: 'Tuesday', RouteId: 173, RouteName: 'Nawab Cly-Umar Cly-Dera Izzat-Hotwali', TotalShops: 80, IsEditingAny: false, EditMode: false },
            { AreaId: 1, AttachedShops: 10, NeverAttachedShops: 70, PendingCredit: 12000, RouteDay: 4, RouteDayName: 'Wednesday', RouteId: 115, RouteName: 'Trust Cly-Muhammadi Cly-Noor Mehal Road-Basti Ridan-Sadiq Cly', TotalShops: 80, IsEditingAny: false, EditMode: false },
            { AreaId: 1, AttachedShops: 10, NeverAttachedShops: 70, PendingCredit: 10000, RouteDay: 5, RouteDayName: 'Thursday', RouteId: 147, RouteName: 'Baqirpur Road-Eastern Bypass-Goth Lashkar-Jhangi wla Road', TotalShops: 80, IsEditingAny: false, EditMode: false },
            { AreaId: 1, AttachedShops: 10, NeverAttachedShops: 70, PendingCredit: 12000, RouteDay: 6, RouteDayName: 'Saturday', RouteId: 171, RouteName: 'Railway-Road-Goth-Ghani-Arshad-Town-Quad-Cly', TotalShops: 80, IsEditingAny: false, EditMode: false },
        ], IsOrderBookerCollapsed: true
    },
    {
        AttachedShops: 50, EmployeeId: 24, EmployeeName: 'Muhammad Zohaib Ali', NeverAttachedShops: 200, NumberOfRoutes: 6, TotalShops: 250, Routes: [
            { AreaId: 2, AttachedShops: 10, NeverAttachedShops: 70, PendingCredit: 10000, RouteDay: 1, RouteDayName: 'Sunday', RouteId: 146, RouteName: 'Qaem Pur To Khair Pur', TotalShops: 80, IsEditingAny: false, EditMode: false },
            { AreaId: 2, AttachedShops: 10, NeverAttachedShops: 70, PendingCredit: 12000, RouteDay: 2, RouteDayName: 'Monday', RouteId: 141, RouteName: '13F-14F- 15F-16F-10F- 11F-12F Chowk Gaduki Chistian Tak', TotalShops: 80, IsEditingAny: false, EditMode: false },
            { AreaId: 2, AttachedShops: 10, NeverAttachedShops: 70, PendingCredit: 10000, RouteDay: 3, RouteDayName: 'Tuesday', RouteId: 145, RouteName: '17- 16-15-14-11-10-bakhshan', TotalShops: 80, IsEditingAny: false, EditMode: false },
            { AreaId: 2, AttachedShops: 10, NeverAttachedShops: 70, PendingCredit: 12000, RouteDay: 4, RouteDayName: 'Wednesday', RouteId: 143, RouteName: 'Chak-Katora-Ismailpur-Noorkot-Basti Farid-Sikandarabad', TotalShops: 80, IsEditingAny: false, EditMode: false },
            { AreaId: 2, AttachedShops: 10, NeverAttachedShops: 70, PendingCredit: 10000, RouteDay: 5, RouteDayName: 'Thursday', RouteId: 132, RouteName: 'Full City Hasilpur---', TotalShops: 80, IsEditingAny: false, EditMode: false },
            { AreaId: 2, AttachedShops: 10, NeverAttachedShops: 70, PendingCredit: 12000, RouteDay: 6, RouteDayName: 'Saturday', RouteId: 136, RouteName: '75 Mor-156M-157M-158M-161M-162M-68F-70F-71F-73F-75F-76F-77F-78F-79F', TotalShops: 80, IsEditingAny: false, EditMode: false },
        ], IsOrderBookerCollapsed: true
    },
    {
        AttachedShops: 50, EmployeeId: UnAssignedOrderBookerId, EmployeeName: 'Unassigned', NeverAttachedShops: 200, NumberOfRoutes: 6, TotalShops: 250, Routes: [
            { AreaId: 1, AttachedShops: 10, NeverAttachedShops: 70, PendingCredit: 10000, RouteDay: UnAssignedOrderBookerId, RouteDayName: 'Unassigned', RouteId: 126, RouteName: 'Manual (D Wala Bhai Asif)', TotalShops: 80, IsEditingAny: false, EditMode: false },
            { AreaId: 1, AttachedShops: 10, NeverAttachedShops: 70, PendingCredit: 12000, RouteDay: UnAssignedOrderBookerId, RouteDayName: 'Unassigned', RouteId: 134, RouteName: 'Khokha Market', TotalShops: 80, IsEditingAny: false, EditMode: false },
            { AreaId: 1, AttachedShops: 10, NeverAttachedShops: 70, PendingCredit: 10000, RouteDay: UnAssignedOrderBookerId, RouteDayName: 'Unassigned', RouteId: 139, RouteName: 'Gaman-Puli-Madina-Chowk-Inayti-Qaempur-Sheikh-Wahan', TotalShops: 80, IsEditingAny: false, EditMode: false },
            { AreaId: 1, AttachedShops: 10, NeverAttachedShops: 70, PendingCredit: 12000, RouteDay: UnAssignedOrderBookerId, RouteDayName: 'Unassigned', RouteId: 152, RouteName: 'Lodhran-Temp-Route-Shoaib', TotalShops: 80, IsEditingAny: false, EditMode: false },
            { AreaId: 1, AttachedShops: 10, NeverAttachedShops: 70, PendingCredit: 10000, RouteDay: UnAssignedOrderBookerId, RouteDayName: 'Unassigned', RouteId: 154, RouteName: 'Kachi-Basti-42 ADA', TotalShops: 80, IsEditingAny: false, EditMode: false },
            { AreaId: 1, AttachedShops: 10, NeverAttachedShops: 70, PendingCredit: 12000, RouteDay: UnAssignedOrderBookerId, RouteDayName: 'Unassigned', RouteId: 162, RouteName: 'Krachi Morr - To - Ghariba Abad ', TotalShops: 80, IsEditingAny: false, EditMode: false },
        ], IsOrderBookerCollapsed: true
    },
];

interface IProps {
    isLoadingOrderBookerRouteInfo: boolean;
    isSavingRouteAssignment: boolean;
    selectedAreaId:number;
    setSelectedAreaId:(payload:number) => any;
    softReloadAreaOrderBookerInfo: (areaId: number) => any;
    reloadOrderBookerRouteInfoFromDatabase: (areaId: number) => any;
    toggleOrderBookerRowCollapse: (areaId: number, employeeId: number) => any;
    editOrderBookerRouteInfo: (areaId: number, employeeId: number, routeDay: number, routeId: number) => any;
    cancelEditOrderBookerRouteInfo: (areaId: number) => any;
    unAssignRoute: (areaId: number, orderBookerId: number, routeDay: number, routeForProcess: IRouteInfoNew) => any;
    updateRouteAssignment: (areaId: number, loginUserId: number, fromOrderBookerId: number, toOrderBookerId: number, toRouteDay: number, routeForProcess: IRouteInfoNew) => any;
}

const RouteSetup = (props: IProps) => {
    const navigate = useNavigate();
    const showErrorMessage = (message: string) => addMessage(message, 'error');
    const showInfoMessage = (message: string) => addMessage(message, 'info');
    const showSuccessMessage = (message: string) => addMessage(message, 'success');
    const showWarningMessage = (message: string) => addMessage(message, 'warning');
    const selectedOrderBookerBackgroundColor: string = '#f7fafa';

    const [areasForAutoComplete, setAreasForAutoComplete] = React.useState<IAutoComplete[]>([]);
    const [isAreaAutoCompleteLoading, setIsAreaAutoCompleteLoading] = React.useState<boolean>(false);
    const [orderBookersAutoComplete, setOrderBookersAutoComplete] = React.useState<IAutoComplete[]>([]);
    const [isOrderBookerAutoCompoleteLoading, setIsOrderBookerAutoCompleteLoading] = React.useState<boolean>(false);
    const [selectedOrderBookerId, setSelectedOrderBookerId] = React.useState<number>(0);
    const [routeAutoComplete, setRouteAutoComplete] = React.useState<IAutoComplete[]>([]);
    const [routeId, setRouteId] = React.useState<number>(0);
    const [isRouteAutoCompleteLoading, setIsRouteAutoCompleteLoading] = React.useState<boolean>(false);
    

    const [selectedRouteDay, setSelectedRouteDay] = React.useState<IAutoComplete>(null);

    const loginUser = useSelector((state: IApplicationState) => state.loginUser);
    const userRoutes = useSelector((state: IApplicationState) => state.userRoutes);
    const orderBookersRouteInfo = useSelector((state:IApplicationState) => {
        if(!!state && !!state.orderBookerRouteInfo){
            const areaOrderBookerRouteInfo = state.orderBookerRouteInfo.find(m => m.areaId === props.selectedAreaId);

            if(!!areaOrderBookerRouteInfo && !!areaOrderBookerRouteInfo.orderBookerInfo){
                return areaOrderBookerRouteInfo.orderBookerInfo;
            }else{
                return [];
            }
        }else{
            return [];
        }
    });

    const selectedRoute = useSelector((state:IApplicationState) => {
        if(!!state && !!state.orderBookerRouteInfo){
            const areaOrderBookerRouteInfo = state.orderBookerRouteInfo.find(m => m.areaId === props.selectedAreaId);

            if(!!areaOrderBookerRouteInfo && !!areaOrderBookerRouteInfo.orderBookerInfo){
                const orderBooker = areaOrderBookerRouteInfo.orderBookerInfo.find(t => t.EmployeeId === selectedOrderBookerId);

                if(!!orderBooker && !!orderBooker.Routes){
                    const route = orderBooker.Routes.find(r => r.RouteId === routeId && r.RouteId > 0);

                    if(!!route){
                        return route;
                    }
                }
            }
        }

        return null;
    })

    const filteredOrderBookerAutoComplete = React.useMemo(() => {
        let copy = getCopy(orderBookersAutoComplete);
        return getCopy(copy.filter(m => m.Value != UnAssignedOrderBookerId));
    }, [orderBookersAutoComplete])

    const [pageMessages, setPageMessages] = React.useState<IMessage[]>([]);
    const addMessage = (messageText: string, variant: 'success' | 'error' | 'info' | 'warning') => {
        if (!!messageText && messageText.trim() != '') {
            let msgs = [];
            msgs.push({
                messageText,
                variant
            });

            setPageMessages([...msgs]);
        }
    }

    const clearMessages = () => {
        setPageMessages([]);
    }

    const clearMessagesExceptSuccess = () => {
        setPageMessages((messages: IMessage[]) => [...messages.filter(pm => pm.variant == 'success')]);
    }

    const onRouteAssignToCollpse = (employeeId: number) => {
        props.toggleOrderBookerRowCollapse(props.selectedAreaId, employeeId);
    }


    const onAreaChange = (option: IAutoComplete) => {
        props.setSelectedAreaId(option?.Value ?? 0);
    }

    const processOnAreaChange = (newAreaId: number) => {
        setSelectedOrderBookerId(0);
        setOrderBookersAutoComplete([]);
        setRouteId(0);
        setSelectedRouteDay(null);
        //setSelectedRoute(null);
        setRouteAutoComplete([]);

        if (newAreaId > 0) {
            loadOrderBookers(newAreaId, false);
            //loadData(newAreaId);
            props.softReloadAreaOrderBookerInfo(newAreaId);
        }
    }

    const onOrderBookerChange = (option: IAutoComplete, routeIdToExclude: number) => {
        setSelectedOrderBookerId(option?.Value ?? 0);
        processOnOrderBookerChange(props.selectedAreaId, option?.Value ?? 0, routeIdToExclude);
    }

    const onRouteDayChange = (option: IAutoComplete) => {
        setSelectedRouteDay(getCopy(option));
    }

    const processOnOrderBookerChange = (areaId: number, orderBookerId: number, routeIdToExclude: number) => {
        setRouteId(0);
        setRouteAutoComplete([]);
        //setSelectedRoute(null);

        if ((orderBookerId > 0 || orderBookerId == UnAssignedOrderBookerId) && routeIdToExclude != -1) {
            loadRoutes(areaId, orderBookerId, routeIdToExclude);
        }
    }

    const onRouteChange = (option: IAutoComplete) => {
        setRouteId(option?.Value ?? 0);
    }

    const getAndSetAreasAutoComplete = () => {
        setIsAreaAutoCompleteLoading(true);
        areaApi.getAreasAutoComplete(loginUser?.UserId ?? 0)
            .then(acomplete => {
                let items = [...acomplete];
                //items.unshift({ Value: 0, DisplayText: 'All' });
                setAreasForAutoComplete([...items]);
                setIsAreaAutoCompleteLoading(false);

                if (!!items && items.length == 1) {
                    onAreaChange(items[0]);
                }
            })
            .catch(e => {
                setAreasForAutoComplete([]);
                setIsAreaAutoCompleteLoading(false);
            });
    }

    const loadOrderBookers = (areaId: number, isFirstLoad: boolean) => {
        if (areaId > 0) {
            setIsOrderBookerAutoCompleteLoading(true);
            employeeApi.getOrderBookerAutoComplete(areaId)
                .then(res => {
                    if (!!res && res.length > 0) {
                        let items: IAutoComplete[] = [{ Value: UnAssignedOrderBookerId, DisplayText: 'Unassigned' }, ...res];
                        setOrderBookersAutoComplete([...items]);
                    } else {
                        setOrderBookersAutoComplete([]);
                    }

                    setIsOrderBookerAutoCompleteLoading(false);
                })
                .catch(e => {
                    setIsOrderBookerAutoCompleteLoading(false);
                    setOrderBookersAutoComplete([]);
                });
        } else {
            setOrderBookersAutoComplete([]);
        }
    }

    const loadRoutes = (areaId: number, orderBookerId: number, routeIdToExclude: number) => {
        if (areaId > 0) {
            setIsRouteAutoCompleteLoading(true);
            routeApi.getRouteAutoComplete(loginUser?.UserId ?? 0, areaId, orderBookerId == UnAssignedOrderBookerId ? null : orderBookerId)
                .then(res => {
                    if (!!res && res.length > 0) {
                        let items: IAutoComplete[] = [...res.filter(m => m.Value != routeIdToExclude)];
                        setRouteAutoComplete([...items]);
                    } else {
                        setRouteAutoComplete([]);
                    }
                    setIsRouteAutoCompleteLoading(false);
                })
                .catch(e => {
                    setIsRouteAutoCompleteLoading(false);
                    setRouteAutoComplete([]);
                });
        } else {
            setRouteAutoComplete([]);
        }
    }

    const onEditRoute = (employeeId: number, routeDay: number, routeId: number) => {
        setSelectedOrderBookerId(0);
        setRouteId(0);
        setSelectedRouteDay(null);
        //setSelectedRoute(null);
        props.editOrderBookerRouteInfo(props.selectedAreaId, employeeId, routeDay, routeId);
    }

    
    const onSaveAssignmentUpdate = (fromOrderBookerId: number, toOrderBookerId: number, toRouteDay: number, routeForProcess: IRouteInfoNew) => {
        props.updateRouteAssignment(props.selectedAreaId, loginUser?.UserId ?? 0, fromOrderBookerId, toOrderBookerId, toRouteDay, routeForProcess);
    }

    const onUnAssign = (orderBookerId: number, routeDay: number, routeForProcess: IRouteInfoNew) => {
        confirmAction('Unassign Route', 'Are you sure?', 'Yes', 'No', 'red', 'green', 'red', 'red')
            .then(isConfirmed => {
                if (isConfirmed == true) {
                    props.unAssignRoute(props.selectedAreaId, orderBookerId, routeDay, routeForProcess);
                }
            })
    }

    const onCancelEidt = () => {
        setSelectedOrderBookerId(0);
        setRouteId(0);
        setSelectedRouteDay(null);
        //setSelectedRoute(null);
        props.cancelEditOrderBookerRouteInfo(props.selectedAreaId);
    }

    const navigateToRouteDetail = (areaId: number, orderBookerId: number, routeId: number) => {
        let url = getUrlFromComponentKey(userRoutes, Keys.RouteDetail);

        if (!!url && url.trim() != '') {
            url = `${url}?${RouteDetailParam.OrderBookerId}=${encodeBase64String(orderBookerId.toString())}&${RouteDetailParam.AreaId}=${encodeBase64String(areaId.toString())}&${RouteDetailParam.RouteId}=${encodeBase64String(routeId.toString())}`;
            navigate(url);
        }
    }

    const navigateToSetupNewRoute = (rid: number, obid: number) => {
        const selectedAreaOption = areasForAutoComplete.find(m => m.Value == props.selectedAreaId);

        if (!!selectedAreaOption && !!selectedAreaOption.Value) {
            let url = getUrlFromComponentKey(userRoutes, Keys.NewRouteMapContainer);
            if (!!url && url.trim() != '') {
                url = `${url}?${NewRouteSetupPageParam.AreaId}=${encodeBase64String(selectedAreaOption.Value.toString())}&${NewRouteSetupPageParam.AreaName}=${encodeBase64String(selectedAreaOption.DisplayText.toString())}${!!rid && rid > 0 ? `&${NewRouteSetupPageParam.RouteId}=${encodeBase64String(rid.toString())}` : ''}${!!obid && (obid > 0 || obid == UnAssignedOrderBookerId) ? `&${NewRouteSetupPageParam.OrderBookerId}=${encodeBase64String(obid.toString())}` : ''}`;
                console.log(url)
                navigate(url);
            }
        }
    }

    const RouteDayToAssignAutoComplete = React.useMemo(() => {
        let options: IAutoComplete[] = [];
        if (!!selectedOrderBookerId && selectedOrderBookerId > 0) {
            const orderBookerToCheck = getCopy(orderBookersRouteInfo).find(m => m.EmployeeId === selectedOrderBookerId);

            if (!!orderBookerToCheck && !!orderBookerToCheck.Routes && orderBookerToCheck.Routes.findIndex(m => m.RouteId > 0) >= 0) {
                const alreadyAssignedDays = orderBookerToCheck.Routes.filter(m => m.RouteId > 0);
                RouteDayDataSource.forEach(element => {
                    options.push({ DisplayText: `${element.DisplayText}${alreadyAssignedDays.findIndex(m => m.RouteDay === element.Value) >= 0 ? `    (Assigned)` : ''}`, Value: element.Value })
                });
            } else {
                options = getCopy(RouteDayDataSource);
            }
        }

        return getCopy(options);
    }, [orderBookersRouteInfo, selectedOrderBookerId, RouteDayDataSource])

    React.useEffect(() => {
        getAndSetAreasAutoComplete();
    }, []);

    React.useEffect(() =>{
        processOnAreaChange(props.selectedAreaId);
    },[props.selectedAreaId])

    return <Page title="Route Setup | Raza Pak Care">
        <Container style={{ paddingBottom: 20, maxWidth: '100%' }}><MessageComponent onRemove={(i: number) => {
            let msgs = [...pageMessages];
            msgs.splice(i, 1);
            setPageMessages([...msgs]);
        }} messages={pageMessages} />
        </Container>
        <Container style={{ paddingBottom: 10, maxWidth: '100%' }}>
            <Grid container spacing={2}>
                <Grid item md={3} xs={12}>
                    {<CustomAutoComplete<IAutoComplete>
                        Id={'Area_List_Auto_Complete'}
                        Value={!!areasForAutoComplete && areasForAutoComplete.length > 0 && props.selectedAreaId > 0 ? areasForAutoComplete.find(m => m.Value == props.selectedAreaId) : null}
                        Options={areasForAutoComplete}
                        displayField={'DisplayText'}
                        valueField={'Value'}
                        Label={'Areas'}
                        onChange={onAreaChange}
                        size='small'
                        isLoading={isAreaAutoCompleteLoading}
                        readOnly={isAreaAutoCompleteLoading || isOrderBookerAutoCompoleteLoading || isRouteAutoCompleteLoading}
                    />}
                </Grid>
                <Grid item md={3} xs={12} style={{display:'flex', flexDirection:'column', justifyContent:'center'}}>
                <LoadingButton
                        size="small"
                        type="button"
                        variant="outlined"
                        disabled={props.selectedAreaId <= 0 || props.isLoadingOrderBookerRouteInfo}
                        style={{ alignSelf: 'flex-start' }}
                        startIcon={<Icon icon={'mdi:refresh'} />}
                        onClick={() => {
                            props.reloadOrderBookerRouteInfoFromDatabase(props.selectedAreaId);
                        }}
                        key={`btnRefresh`}
                    >
                        Refresh
                    </LoadingButton>
                </Grid>

                <Grid item md={6} style={{ display: 'flex', justifyContent: 'flex-end' }} xs={12}>
                    {props.selectedAreaId > 0 && <LoadingButton
                        size="small"
                        type="button"
                        variant="contained"
                        loading={false}
                        style={{ alignSelf: 'center', margin: 3, backgroundColor: 'blue', textAlign: 'right' }}
                        startIcon={<Icon icon={'material-symbols:add'} />}
                        onClick={() => {
                            navigateToSetupNewRoute(0, 0);
                        }}
                        key={`NewRoute`}
                    >
                        New Route
                    </LoadingButton>}
                </Grid>

            </Grid>
        </Container>

        {<Container style={{ paddingBottom: 10, maxWidth: '100%' }}>
            <Card>


                {<TableContainer sx={{ minWidth: 800, maxWidth: '100%' }}>
                    <Table sx={{ borderSpacing: '0px 3px' }} stickyHeader>
                        <UserListHead
                            order={'asc'}
                            orderBy={''}
                            headLabel={ORDER_BOOKER_TABLE_HEAD}
                            rowCount={0}
                            numSelected={0}
                            onRequestSort={undefined}
                            onSelectAllClick={undefined}
                            customKey={`TableHeadRow_6`}
                            typographyVariant='subtitle2'
                            backgroundColor={'#4279a3'}
                            textColor='white'
                        />
                        <TableBody>
                            {props.isLoadingOrderBookerRouteInfo == true && <TableRow
                                key={'LoadingRow'}
                                tabIndex={-1}
                                role="checkbox"
                                selected={false}
                                aria-checked={false}
                            >
                                <TableCell align='center' colSpan={ORDER_BOOKER_TABLE_HEAD.length}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>}
                            {props.isLoadingOrderBookerRouteInfo == false && (!orderBookersRouteInfo || orderBookersRouteInfo.length <= 0) && <TableRow
                                key={'LoadingRow'}
                                tabIndex={-1}
                                role="checkbox"
                                selected={false}
                                aria-checked={false}
                            >
                                <TableCell align='center' colSpan={ORDER_BOOKER_TABLE_HEAD.length}>
                                    {`No Data`}
                                </TableCell>
                            </TableRow>}
                            {props.isLoadingOrderBookerRouteInfo == false && !!orderBookersRouteInfo && orderBookersRouteInfo.length > 0 && orderBookersRouteInfo.map((row, index) => {
                                return (
                                    <React.Fragment key={`Orderbookerrow_${row.EmployeeId}`}><TableRow
                                        hover
                                        key={row.EmployeeId}
                                        tabIndex={-1}
                                        role="checkbox"
                                        selected={false}
                                        aria-checked={false}
                                        className={row.IsOrderBookerCollapsed == true ? 'order-booker' : 'order-booker-selected'}
                                        onClick={() => onRouteAssignToCollpse(row.EmployeeId)}
                                    >
                                        <TableCell width={'50%'} style={{ fontWeight: 'bold', color: 'blue' }} align="left">
                                            {row.EmployeeName}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.NumberOfRoutes}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.TotalShops}
                                        </TableCell>
                                        <TableCell align="left" style={{ color: 'green' }} >
                                            {row.AttachedShops}
                                        </TableCell>
                                        <TableCell style={{ color: 'red' }} align="left">
                                            {row.NeverAttachedShops}
                                        </TableCell>
                                        <TableCell padding="none" align="left">
                                            {row.IsOrderBookerCollapsed == false && !!row.Routes && row.Routes.length > 0 && <Icon color='black' style={{ cursor: 'pointer' }} width={25} height={25} icon={'mdi:chevron-up'} />}
                                            {row.IsOrderBookerCollapsed == true && !!row.Routes && row.Routes.length > 0 && <Icon color='black' style={{ cursor: 'pointer' }} width={25} height={25} icon={'mdi:chevron-down'} />}
                                        </TableCell>
                                    </TableRow>
                                        {row.IsOrderBookerCollapsed == false && !!row.Routes && row.Routes.length > 0 && <TableRow>
                                            <TableCell colSpan={7}>
                                                <Table sx={{ borderCollapse: 'separate', borderSpacing: '0px 4px' }} size={'small'}>
                                                    <UserListHead
                                                        order={'asc'}
                                                        orderBy={''}
                                                        headLabel={ROUTES_TABLE_HEAD}
                                                        rowCount={0}
                                                        numSelected={0}
                                                        onRequestSort={undefined}
                                                        onSelectAllClick={undefined}
                                                        customKey={`TableHeadRow_2`}
                                                        typographyVariant='subtitle2'
                                                        backgroundColor={selectedOrderBookerBackgroundColor}
                                                    />
                                                    <TableBody>
                                                        {row.Routes.map((rowRoute, ind) => {
                                                            return (
                                                                <TableRow
                                                                    hover
                                                                    key={`${rowRoute.RouteId}_${rowRoute.RouteDay}_${row.EmployeeId}`}
                                                                    tabIndex={-1}
                                                                    role="checkbox"
                                                                    selected={false}
                                                                    aria-checked={false}
                                                                    className='route'
                                                                    onDoubleClick={() => {
                                                                        if (rowRoute.EditMode == false && rowRoute.RouteId > 0) {
                                                                            navigateToRouteDetail(rowRoute.AreaId, row.EmployeeId, rowRoute.RouteId)
                                                                        }
                                                                    }}
                                                                >
                                                                    <TableCell className='' width="5%">
                                                                        {rowRoute.RouteDayName}
                                                                    </TableCell>
                                                                    <TableCell width={rowRoute.IsEditingAny == true ? "10%" : "40%"}>
                                                                        {rowRoute.EditMode == true && (row.EmployeeId != UnAssignedOrderBookerId) ? (selectedRoute?.RouteName ?? '') : (rowRoute.RouteId > 0 ? rowRoute.RouteName : '')}
                                                                    </TableCell>
                                                                    <TableCell width="5%" align="left" style={{ color: 'red' }}>
                                                                        {rowRoute.EditMode == true && (row.EmployeeId != UnAssignedOrderBookerId) ? (!!selectedRoute && selectedRoute.PendingCredit !== undefined && selectedRoute.PendingCredit != null ? fCurrency(selectedRoute.PendingCredit ?? 0) : '') : rowRoute.RouteId > 0 ? fCurrency(rowRoute.PendingCredit ?? 0) : ''}
                                                                    </TableCell>
                                                                    <TableCell width="5%" style={{ color: 'blue' }}>
                                                                        {rowRoute.EditMode == true && (row.EmployeeId != UnAssignedOrderBookerId) ? (selectedRoute?.TotalShops ?? '') : rowRoute.RouteId > 0 ? rowRoute.TotalShops : ''}
                                                                    </TableCell>
                                                                    <TableCell width="5%" style={{ color: 'green' }}>
                                                                        {rowRoute.EditMode == true && (row.EmployeeId != UnAssignedOrderBookerId) ? (selectedRoute?.AttachedShops ?? '') : rowRoute.RouteId > 0 ? rowRoute.AttachedShops : ''}
                                                                    </TableCell>
                                                                    <TableCell width={"5%"} style={{ color: 'red' }}>
                                                                        {rowRoute.EditMode == true && (row.EmployeeId != UnAssignedOrderBookerId) ? (selectedRoute?.NeverAttachedShops ?? '') : rowRoute.RouteId > 0 ? rowRoute.NeverAttachedShops : ''}
                                                                    </TableCell>
                                                                    <TableCell width={rowRoute.IsEditingAny ? "65%" : "35%"} align={rowRoute.EditMode == true ? "left" : "right"} padding="none" style={{ paddingRight: 10 }}>


                                                                        {rowRoute.EditMode == false && !!rowRoute.RouteId && rowRoute.RouteId > 0 && <span onClick={() => {
                                                                            navigateToSetupNewRoute(rowRoute.RouteId, row.EmployeeId);
                                                                        }} className='btn-text'>View on Map</span>}
                                                                        {rowRoute.EditMode == false && <span onClick={() => {
                                                                            onEditRoute(row.EmployeeId, rowRoute.RouteDay, rowRoute.RouteId);
                                                                        }} className='btn-text' style={{ color: (!!rowRoute.RouteId && rowRoute.RouteId > 0 && (row.EmployeeId != UnAssignedOrderBookerId)) ? 'black' : 'green' }}>{!!rowRoute.RouteId && rowRoute.RouteId > 0 && (row.EmployeeId != UnAssignedOrderBookerId) ? 'Edit' : 'Assign'}</span>}
                                                                        {rowRoute.EditMode == false && (row.EmployeeId != UnAssignedOrderBookerId && rowRoute.RouteId > 0) && <span onClick={() => {
                                                                            onUnAssign(row.EmployeeId, rowRoute.RouteDay, rowRoute);
                                                                        }} style={{ color: 'red' }} className='btn-text'>Unassign</span>}
                                                                        {rowRoute.EditMode == true && (row.EmployeeId != UnAssignedOrderBookerId) && <table width={"100%"}><tbody><tr><td width="30%" style={{ paddingRight: 5 }}>
                                                                            <CustomAutoComplete<IAutoComplete>
                                                                                Id={'Order_booker_AutoComplete'}
                                                                                Value={!!orderBookersAutoComplete && orderBookersAutoComplete.length > 0 && selectedOrderBookerId != 0 ? orderBookersAutoComplete.find(m => m.Value == selectedOrderBookerId) : null}
                                                                                Options={orderBookersAutoComplete ?? []}
                                                                                displayField={'DisplayText'}
                                                                                valueField={'Value'}
                                                                                Label={'Order Booker'}
                                                                                onChange={(option: IAutoComplete) => { onOrderBookerChange(option, rowRoute?.RouteId ?? 0) }}
                                                                                size='small'
                                                                                isLoading={isOrderBookerAutoCompoleteLoading}
                                                                                readOnly={isAreaAutoCompleteLoading || isOrderBookerAutoCompoleteLoading || isRouteAutoCompleteLoading}
                                                                            />
                                                                        </td><td width="60%">
                                                                                <CustomAutoComplete<IAutoComplete>
                                                                                    Id={'Route_List'}
                                                                                    Value={!!routeAutoComplete && routeAutoComplete.length > 0 && routeId > 0 ? routeAutoComplete.find(m => m.Value == routeId) : null}
                                                                                    Options={routeAutoComplete}
                                                                                    displayField={'DisplayText'}
                                                                                    valueField={'Value'}
                                                                                    Label={'Route'}
                                                                                    onChange={onRouteChange}
                                                                                    size='small'
                                                                                    isLoading={isRouteAutoCompleteLoading}
                                                                                    readOnly={isAreaAutoCompleteLoading || isOrderBookerAutoCompoleteLoading || isRouteAutoCompleteLoading}
                                                                                />
                                                                            </td>
                                                                            <td width="10%" align='center'>
                                                                                {props.isSavingRouteAssignment == false && <span onClick={() => {
                                                                                    if ((selectedOrderBookerId > 0 || selectedOrderBookerId == UnAssignedOrderBookerId) && routeId > 0 && !!selectedRoute) {
                                                                                        //update route here
                                                                                        onSaveAssignmentUpdate(selectedOrderBookerId, row.EmployeeId, rowRoute.RouteDay, selectedRoute);
                                                                                    }
                                                                                }} className={(selectedOrderBookerId > 0 || selectedOrderBookerId == UnAssignedOrderBookerId) && routeId > 0 ? 'btn-text' : 'btn-text-disabled'} style={{ marginLeft: 14 }}>{!!rowRoute.RouteId && rowRoute.RouteId > 0 ? 'Update' : 'Save'}</span>}
                                                                                {props.isSavingRouteAssignment == false && <span onClick={() => {
                                                                                    onCancelEidt();
                                                                                }} className='btn-text'>Cancel</span>}
                                                                                {props.isSavingRouteAssignment == true && <span className='btn-text'><CircularProgress /></span>}
                                                                            </td>
                                                                        </tr></tbody></table>}

                                                                        {rowRoute.EditMode == true && (row.EmployeeId == UnAssignedOrderBookerId) && <table width={"100%"}><tbody><tr><td width="40%" style={{ paddingRight: 5 }}>
                                                                            <CustomAutoComplete<IAutoComplete>
                                                                                Id={'Order_booker_AutoComplete'}
                                                                                Value={!!filteredOrderBookerAutoComplete && filteredOrderBookerAutoComplete.length > 0 && selectedOrderBookerId != 0 ? filteredOrderBookerAutoComplete.find(m => m.Value == selectedOrderBookerId) : null}
                                                                                Options={filteredOrderBookerAutoComplete}
                                                                                displayField={'DisplayText'}
                                                                                valueField={'Value'}
                                                                                Label={'Order Booker'}
                                                                                onChange={(option: IAutoComplete) => { onOrderBookerChange(option, -1) }}
                                                                                size='small'
                                                                                isLoading={isOrderBookerAutoCompoleteLoading}
                                                                                readOnly={isAreaAutoCompleteLoading || isOrderBookerAutoCompoleteLoading || isRouteAutoCompleteLoading}
                                                                            />
                                                                        </td><td width="40%">
                                                                                <CustomAutoComplete<IAutoComplete>
                                                                                    Id={'Day_List'}
                                                                                    Value={selectedRouteDay}
                                                                                    Options={RouteDayToAssignAutoComplete}
                                                                                    displayField={'DisplayText'}
                                                                                    valueField={'Value'}
                                                                                    Label={'Day'}
                                                                                    onChange={onRouteDayChange}
                                                                                    size='small'
                                                                                    isLoading={false}
                                                                                    readOnly={false}
                                                                                />
                                                                            </td>
                                                                            <td width="10%" align='center'>
                                                                                {props.isSavingRouteAssignment == false && <span onClick={() => {
                                                                                    if ((selectedOrderBookerId > 0) && !!selectedRouteDay) {
                                                                                        //update route here
                                                                                        onSaveAssignmentUpdate(row.EmployeeId, selectedOrderBookerId, selectedRouteDay.Value, rowRoute);
                                                                                    }
                                                                                }} className={selectedOrderBookerId > 0 && !!selectedRouteDay ? 'btn-text' : 'btn-text-disabled'} style={{ marginLeft: 14 }}>Save</span>}
                                                                                {props.isSavingRouteAssignment == false && <span onClick={() => {
                                                                                    onCancelEidt();
                                                                                }} className='btn-text'>Cancel</span>}
                                                                                {props.isSavingRouteAssignment == true && <span className='btn-text'><CircularProgress /></span>}
                                                                            </td>
                                                                        </tr></tbody></table>}

                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </TableCell>
                                        </TableRow>}
                                    </React.Fragment>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>}
            </Card>
        </Container>}
    </Page>;
}

const mapStateToProps = (state: IApplicationState) => {
    return {
        isLoadingOrderBookerRouteInfo: state.isLoadingOrderBookerRouteInfo,
        isSavingRouteAssignment: state.isSavingRouteAssignment,
        selectedAreaId:state.selectedAreaId
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        softReloadAreaOrderBookerInfo: (areaId: number) => dispatch(softReloadAreaOrderBookerInfoAction(areaId)),
        reloadOrderBookerRouteInfoFromDatabase: (areaId: number) => dispatch(hardReloadAreaOrderBookerInfoAction(areaId)),
        toggleOrderBookerRowCollapse: (areaId: number, employeeId: number) => dispatch(toggleOrderBookerRowCollapseAction(areaId, employeeId)),
        editOrderBookerRouteInfo: (areaId: number, employeeId: number, routeDay: number, routeId: number) => dispatch(editOrderBookerRouteInfoAction(areaId, employeeId, routeDay, routeId)),
        cancelEditOrderBookerRouteInfo: (areaId: number) => dispatch(cancelEditOrderBookerRouteInfoAction(areaId)),
        unAssignRoute: (areaId: number, orderBookerId: number, routeDay: number, routeForProcess: IRouteInfoNew) => dispatch(unAssignRouteAction(areaId, orderBookerId, routeDay, routeForProcess)),
        updateRouteAssignment: (areaId: number, loginUserId: number, fromOrderBookerId: number, toOrderBookerId: number, toRouteDay: number, routeForProcess: IRouteInfoNew) => dispatch(updateRouteAssignmentAction(areaId, loginUserId, fromOrderBookerId, toOrderBookerId, toRouteDay, routeForProcess)),
        setSelectedAreaId:(areaId:number) => dispatch(setSelectedAreaIdAction(areaId)),
    }
}

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(RouteSetup);

export { connectedComponent as RouteSetup };