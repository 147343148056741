import {OrderBookerInfoConstants} from '../actions/action.constant';
import {IAction} from '../actions/action.interface';
import {initialState} from '../initial-state';

export const isLoadingOrderBookerRouteInfoReducer = (
  state: boolean = initialState.isLoadingOrderBookerRouteInfo,
  action: IAction<boolean>,
) => {
  switch (action.type) {
    case OrderBookerInfoConstants.SET_ORDER_BOOKER_INFO_LOADING: {
      return (action.payload ?? state);
    }
    default:
      return state;
  }
};
