import { IMessage } from 'src/interfaces/message.interface';
import {NewRouteSetupConstant} from '../../actions/action.constant';
import {IAction} from '../../actions/action.interface';
import {initialState} from '../../initial-state';
import { getCopy } from 'src/utility';

export const newRouteSetupPageMessageReducer = (
  state: IMessage | null = initialState.newRouteSetupPageMessage,
  action: IAction<IMessage | null>,
) => {
  switch (action.type) {
    case NewRouteSetupConstant.SET_NEW_ROUTE_SETUP_PAGE_MESSAGE: {
      return !!action.payload ? getCopy(action.payload) : null;
    }
    default:
      return state;
  }
};
