import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { IUnitAccess } from 'src/interfaces/unit-access.interface';
import { CustomAutoComplete } from './common/CustomAutoComplete';
import { IMessage } from 'src/interfaces/message.interface';
import { MessageComponent } from './messages.component';

interface IProps{
    open:boolean;
    close:() => void;
    onValueChange:(unitAccess:IUnitAccess) => void;
    units:IUnitAccess[];
    value:string;
    ok:() => void;
    pageMessages:IMessage[];
    onRemoveMessage:() => void;
}
export const UnitDialogComponent = (props:IProps) => {

  return (
    <div>
      <Dialog fullWidth open={props.open} onClose={props.close}>
        <DialogTitle>Unit</DialogTitle>
        <DialogContent>
        <div style={{marginBottom:10}}><MessageComponent onRemove={(i: number) => {
      props.onRemoveMessage();
  }} messages={props.pageMessages} /></div>
          <CustomAutoComplete<IUnitAccess>
                Id={'Unit_List'}
                Value={!!props.units && props.units.length > 0 && !!props.value && props.value.trim() != '' ? props.units.find(m => m.UnitUniqueId == props.value) : null}
                Options={props.units}
                displayField={'UnitTitle'}
                valueField={'UnitUniqueId'}
                Label={'Select Unit'}
                onChange={props.onValueChange}
                />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.ok}>Login</Button>
          <Button style={{color:'red'}} onClick={props.close}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
