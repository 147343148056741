import {http} from './http-base';
import {ITokenResult} from '../interfaces';

const login = async (
  userName: string,
  password: string,
): Promise<ITokenResult> => {
  let body = `client_id=${encodeURIComponent(
    userName,
  )}&client_secret=${encodeURIComponent(
    password,
  )}&grant_type=client_credentials`;

  let result = await http
    .post<ITokenResult>('api/Token', body)
    .catch((error:any) => Promise.reject(error));

  return result.data;
};

const loginApi = {
  login,
};

export {loginApi};
