import { Grid } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { IRouteInfoResult } from 'src/interfaces/route-info-result.interface';
import { IApplicationState } from 'src/redux';
import { RoutesSummaryComponent } from './routes-summary.component';
import { UserRouteInfoComponent } from './user-route-info.component';
import { Audio as AudoSpin } from 'react-loader-spinner'
import { routeApi } from 'src/http';
import { generateUUID, getCommaSeparatedUnitUniqueIdsExceptAll } from 'src/utility';
import { LoadingButton } from '@mui/lab';

interface IProps{
    orderBookerId: number;
    refreshToken: string;
    refreshMe:(token:string) => void;
}

export const UserRoutesInfoComponent = (props:IProps) =>{
    const [orderBookerId, setOrderBookerId] = React.useState<number>(props.orderBookerId);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [paramChanged, setParamChanged] = React.useState<boolean>(false);
    const [refreshToken, setRefreshToken] = React.useState<string>(props.refreshToken);
    const [data, setData] = React.useState<IRouteInfoResult>();
    const state = useSelector((s:IApplicationState) => s);
    
    const reloadData = (refreshToken:string, obId:number) =>{
        setRefreshToken(refreshToken);
        setIsLoading(true);
        setParamChanged(false);

        routeApi.getOrderBookerRouteInfo(state.loginUser?.UserId ?? 0, getCommaSeparatedUnitUniqueIdsExceptAll(state.selectedUnitAccesses.UnitAccesses ?? []),obId)
        .then(res => {
            setIsLoading(false);
            if(!!res){
                console.log(res)
                setData({...res});
            }else{
                setParamChanged(true);
            }
        })
        .catch(e => {
            setIsLoading(false);
            setParamChanged(true);
            console.log(e);
        });
    }

    React.useEffect(() => {
        if(!!props.orderBookerId && props.orderBookerId > 0 && props.orderBookerId != orderBookerId){
            setParamChanged(true);
            setOrderBookerId(props.orderBookerId);
        }

        if(!!props.refreshToken && props.refreshToken != refreshToken && !!props.orderBookerId && props.orderBookerId > 0){
            reloadData(props.refreshToken, props.orderBookerId);
        }

    },[props.orderBookerId, props.refreshToken]);

    return <React.Fragment>
    {isLoading == true && <AudoSpin
        height="80"
        width="80"
        color='green'
        ariaLabel='three-dots-loading'
        wrapperStyle={{ alignSelf: 'center', justifyContent: 'center' }}
    />}
    {isLoading == false && <Grid container>
        <Grid item md={4} style={{justifyContent:'flex-end', display:'flex'}}>
        <LoadingButton
                  type="button"
                  size='small'
                  variant="contained"
                  loading={isLoading}
                  style={{ margin: 3, marginRight:10, marginTop:10, width: 100,alignSelf:'flex-end', display:'flex' }}
                  disabled={isLoading}
                  onClick={() => {
                    props.refreshMe(generateUUID())
                  }}
                >
                  Refresh
                </LoadingButton>
        </Grid>
    </Grid>}
    
    {isLoading == false && paramChanged == false && !!data && <Grid container style={{marginBottom:20}}>
        {!!data.AllRoutesSummary && <Grid item md={12}>
            <Grid item md={4} style={{paddingRight:10}}>
            <RoutesSummaryComponent
                orderBooker={data.AllRoutesSummary.OrderBookerName}
                numberOfShopsWithCredit={data.AllRoutesSummary.TotalCreditShops}
                pendingCredit={data.AllRoutesSummary.TotalPendingCredit}
                retailCoveredShops={data.AllRoutesSummary.TotalCoveredShops - data.AllRoutesSummary.WholeSaleCoveredShops}
                totalCoveredShops={data.AllRoutesSummary.TotalCoveredShops}
                totalRetailShops={data.AllRoutesSummary.TotalShops - data.AllRoutesSummary.WholeSaleShops}
                totalShops={data.AllRoutesSummary.TotalShops}
                totalWholeSaleShops={data.AllRoutesSummary.WholeSaleShops}
                wholeSaleCoveredShops={data.AllRoutesSummary.WholeSaleCoveredShops}
                outOfRouteNumberOfShopsWithCredit={data.AllRoutesSummary.OutOfRoutesCreditShops}
                outOfRoutePendingCredit={data.AllRoutesSummary.OutOfRoutesPendingCredit}
            />
            </Grid>
        </Grid>}
        {!!data.AllRoutesInfo && data.AllRoutesInfo.length > 0 && data.AllRoutesInfo.map((a,i) => {
            return <Grid key={`Route_Info_Grid_${a.RouteId}`} item md={4} style={i != 2 && i != 5 ? {paddingRight:10} : {}}>
            <UserRouteInfoComponent
                assignTo={a.OrderBookerName}
                numberOfShopsWithCredit={a.InRoutesCreditShops}
                pendingCredit={a.InRoutesPendingCredit}
                retailCoveredShops={a.TotalCoveredShops - a.WholeSaleCoveredShops}
                routeDay={a.RouteDayName}
                routeName={a.RouteName}
                totalCoveredShops={a.TotalCoveredShops}
                totalRetailShops={a.TotalShops - a.WholeSaleShops}
                totalShops={a.TotalShops}
                totalWholeSaleShops={a.WholeSaleShops}
                wholeSaleCoveredShops={a.WholeSaleCoveredShops}
            />
        </Grid>
        })}
    </Grid>}
    </React.Fragment>
}