import React from 'react';
import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import {connect} from 'react-redux';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { IApplicationState } from 'src/redux';
import { setLoginUserDetailAction, setSelectedUnitAccessesAction, setTokenAction } from 'src/redux/actions';
import { ITokenResult, IUserDetail } from 'src/interfaces';
import { IAction } from 'src/redux/actions/action.interface';
import {userApi,loginApi} from '../../../http';
import { setUserRouteDetailAction } from 'src/redux/actions/user-routes.action';
import { IUserRouteDetail } from 'src/interfaces/routes/user-route-detail.interface';
import { setUserMenuAction } from 'src/redux/actions/user-menu.action';
import { IUserMenu } from 'src/interfaces/routes/user-menu.interface';
import { IMessage } from 'src/interfaces/message.interface';
import { MessageComponent } from 'src/components/messages.component';
import { UnitDialogComponent } from 'src/components/dialog.component';
import { IUnitAccess } from 'src/interfaces/unit-access.interface';
import { AllOptionUniqueId } from 'src/app.constant';
import { ISelectedUnitAccess } from 'src/interfaces/selected-unit-access.interface';

// ----------------------------------------------------------------------

interface IProps{
  setToken:(payload:ITokenResult | null) => IAction<ITokenResult>;
  setLoginUser:(payload:IUserDetail|null) => IAction<IUserDetail>;
  setUserRoutes:(payload:IUserRouteDetail | null) => IAction<IUserRouteDetail>;
  setUserMenu:(payload:IUserMenu[]) => IAction<IUserMenu[]>;
  setSelectedUnit:(payload:ISelectedUnitAccess) => IAction<ISelectedUnitAccess>;
}

interface ILoginDetails{
  userDetail:IUserDetail;
  token:ITokenResult;
  userRoute:IUserRouteDetail;
  userMenu:IUserMenu[];
}
const LoginForm = (props:IProps) => {

    const showErrorMessage = (message: string) => addMessage(message, 'error');
    const showInfoMessage = (message: string) => addMessage(message, 'info');
    const showSuccessMessage = (message: string) => addMessage(message, 'success');
    const showWarningMessage = (message: string) => addMessage(message, 'warning');
    const [pageMessages, setPageMessages] = React.useState<IMessage[]>([]);
    const addMessage = (messageText: string, variant: 'success' | 'error' | 'info' | 'warning') => {
        if (!!messageText && messageText.trim() != '') {
            let msgs = [];
            msgs.push({
                messageText,
                variant
            });

            setPageMessages([...msgs]);
        }
    }
    
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required')
  });

  const [loginDetails,setLoginDetails] = React.useState<ILoginDetails>();

  const [isUnitDialogOpen, setIsUnitDialogOpen] = React.useState<boolean>(false);
  const [dropdownUnits,setDropDownUnits] = React.useState<IUnitAccess[]>([]);
  const onUnitDialogClose = () =>{
    removeMessage();
    setIsUnitDialogOpen(false);
  }

  const [selectedUnitId,setSelectedUnitId] = React.useState<string>('');
  const [selectedUnit,setSelectedUnit] = React.useState<IUnitAccess>();

  const handleUnitChange = (option:IUnitAccess) =>{
    if(!!option){
      setSelectedUnitId(option.UnitUniqueId);
      setSelectedUnit({...option});
    }else{
      setSelectedUnitId('');
      setSelectedUnit(null);
    }
  }

  const onUnitLogin = () =>{
    if(!!selectedUnit){
      setIsUnitDialogOpen(false);
      props.setLoginUser({...loginDetails.userDetail,UnitAccesses:loginDetails.userDetail.UnitAccesses.map(u => Object.assign({},u))});
      props.setToken(Object.assign({},loginDetails.token));
      props.setUserRoutes({...loginDetails.userRoute,Routes:loginDetails.userRoute.Routes.map(r => Object.assign({},r)), UserMenu:[]});
      props.setUserMenu(loginDetails.userMenu.map(a => Object.assign({},a)));
    }else{
      showErrorMessage("Please Select Unit To Login");
    }
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true,
      isSubmitting:false,
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      setSelectedUnit(null);
      setSelectedUnitId('');
      setDropDownUnits([]);
      const email = formik.getFieldProps('email').value;
      const password = formik.getFieldProps('password').value;
      loginApi.login(email,password)
      .then((tokenResult:ITokenResult) => {
        let expireOn = new Date(
          Date.now() + (tokenResult.expires_in as number) * 1000,
        );

        userApi.getUserDetail(email).then((userDetail:IUserDetail) =>{
          if(!!userDetail && !!userDetail.UnitAccesses && userDetail.UnitAccesses.length > 0){
            
            userApi.getUserRouteDetail(userDetail.UserId)
            .then(routeDetail => {
              formik.setSubmitting(false);
              if(userDetail.UnitAccesses.length > 1){

                  //show popup here
                  // setLoginDetails({
                  //   userDetail:{...userDetail,EmailId:email, CurrentUnit:Object.assign({},userDetail.UnitAccesses[0]),UnitAccesses:[...userDetail.UnitAccesses]},
                  //   token:{...tokenResult,expire_on:expireOn},
                  //   userMenu:[...routeDetail.UserMenu],
                  //   userRoute:{...routeDetail,Routes:[...routeDetail.Routes], UserMenu:[...routeDetail.UserMenu]}
                  // });

                  // setDropDownUnits([...userDetail.UnitAccesses]);
                  // setIsUnitDialogOpen(true);
                const currentUnit:IUnitAccess = {
                  Id:0,
                  CreatedById:1,
                  CreatedOn:new Date(),
                  IsActive:true,
                  UnitTitle:'All',
                  UnitUniqueId:AllOptionUniqueId,
                  UpdatedById:1,
                  UpdatedOn:new Date(),
                  UserId:userDetail.UserId
                };

                const unitAccesses = [{...currentUnit},...userDetail.UnitAccesses]
                props.setLoginUser({...userDetail,EmailId:email,UnitAccesses:[...unitAccesses.map(a => a)]});
                props.setToken({...tokenResult,expire_on:expireOn});
                props.setUserRoutes({...routeDetail});
                props.setUserMenu([...routeDetail.UserMenu]);
                props.setSelectedUnit({IsAllSelected:true,UnitAccesses:[...unitAccesses.map(a => a)]})
              }else{
                props.setLoginUser({...userDetail,UnitAccesses:[...userDetail.UnitAccesses.map(a => a)],EmailId:email});
                props.setToken({...tokenResult,expire_on:expireOn});
                props.setUserRoutes({...routeDetail});
                props.setUserMenu([...routeDetail.UserMenu]);
                props.setSelectedUnit({IsAllSelected:false,UnitAccesses:[...userDetail.UnitAccesses.map(a => a)]})
              }
            })
            .catch(e => {
              formik.setSubmitting(false);
              showErrorMessage('Unable to get user rotues detail.');
              console.log(e);
            });
          }else{
            formik.setSubmitting(false);
            showErrorMessage('User Details Not Found');
          }
          
          //navigate('/dashboard', { replace: true });
        })
        .catch((e:any) => {
          console.log('Error On getting User Details',e);
          showErrorMessage('An Error Occured while fetching use details');
          formik.setSubmitting(false);
        });
      })
      .catch((error:any) => {
        console.log('Error on Login:',error);
        showErrorMessage('Invalid User Name or Password');
        formik.setSubmitting(false);
      });
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const removeMessage = () =>{
    setPageMessages([]);
  }
  return (
    <><div style={{marginBottom:10}}><MessageComponent onRemove={(i: number) => {
      let msgs = [...pageMessages];
      msgs.splice(i, 1);
      setPageMessages([...msgs]);
  }} messages={pageMessages} /></div>
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />

          <Link component={RouterLink} variant="subtitle2" to="#">
            Forgot password?
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Login
        </LoadingButton>
      </Form>
    </FormikProvider>
    <UnitDialogComponent pageMessages={pageMessages} onRemoveMessage={removeMessage} value={selectedUnitId} units={dropdownUnits} open={isUnitDialogOpen} close={onUnitDialogClose} ok={onUnitLogin} onValueChange={handleUnitChange} />
    </>
  );
}

const mapStateToProps = (state:IApplicationState) =>{
  return {};
}

const mapDispatchToProps = {
  setToken:setTokenAction,
  setLoginUser:setLoginUserDetailAction,
  setUserRoutes:setUserRouteDetailAction,
  setUserMenu:setUserMenuAction,
  setSelectedUnit:setSelectedUnitAccessesAction
};

const connectedLoginForm = connect(mapStateToProps,mapDispatchToProps)(LoginForm);
export {connectedLoginForm as LoginForm}