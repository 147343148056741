import React from 'react';
import { useState } from 'react';
// material
import {
    CircularProgress,
    Container, FormControlLabel, Grid, Switch,
} from '@mui/material';
// components
//
import { orderApi } from '../http';
import { formattedDate, getCommaSeparatedUnitUniqueIdsExceptAll, getCopy, getNumberOfDaysInMonth } from 'src/utility';
import { fCommaSeparatedWithDecial, fCurrency, fUptoTwoDecimal } from 'src/utils/formatNumber';
import { IOrderNew } from 'src/interfaces/reports/order-new.interface';
import { AllOptionUniqueId, NewOrderReportPrintSetting } from 'src/app.constant';
import useWindowDimensions from 'src/components/common/useWindowDimentions';
import '../page-styles/order-new-report.css';
import { useSelector } from 'react-redux';
import { IApplicationState } from 'src/redux';
// --------------------------------------------------------------------


//#region interfaces


//#endregion
interface IProps {
    isOverall:boolean;
    employeeId:number | null;
    refreshedOn:Date;
    isLoadingParent:boolean;
}

export const OrderReportComparison = (props: IProps) => {
    //#region state variable


    const loginUser = useSelector((state:IApplicationState) => {
        return state.loginUser;
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isPreviousMonthLoading, setIsPreviousMonthLoading] = React.useState<boolean>(false);
    const [ordersData, setOrdersData] = React.useState<IOrderNew[]>([]);
    const [ordersDataPreviousMonth, setOrdersDataPreviousMonth] = React.useState<IOrderNew[]>([]);
    const [currentMonthStartDate, setCurrentMonthStartDate] = React.useState<Date>();
    const [currentMonthEndDate, setCurrentMonthEndDate] = React.useState<Date>();
    const [previousMonthStartDate, setPreviousMonthStartDate] = React.useState<Date>();
    const [previousMonthEndDate, setPreviousMonthEndDate] = React.useState<Date>();

    //#endregion

    //#region handlers start

    const resetEverything = () => {
        setOrdersData([]);
        setOrdersDataPreviousMonth([]);
    }

    //#endregion handlers end

    //#region apis call start

    const getDates = () =>{
        const todayDated = new Date();
        const day = todayDated.getDate();
        const monthIndex = todayDated.getMonth();
        const year = todayDated.getFullYear();
        const currentMonthFromDate = new Date(year,monthIndex,1,0,0,0,0);
        const currentMonthToDate = new Date(year, monthIndex, day, 0,0,0,0);
        
        let previousMonthYear = year;
        let previousMonth = monthIndex - 1;
        if(monthIndex === 0){
            previousMonthYear = year - 1;
            previousMonth = 11;
        }

        const numberOfDaysInMonth = getNumberOfDaysInMonth(previousMonth, year);
        let previousMonthDay = day;
        if(day > numberOfDaysInMonth){
            previousMonthDay = numberOfDaysInMonth;
        }

        const previousMonthToDate = new Date(previousMonthYear, previousMonth, previousMonthDay, 0,0,0,0);
        const previousMonthFromDate = new Date(previousMonthYear, previousMonth, 1, 0,0,0,0);

        return {
            currentMonthFromDate,
            currentMonthToDate,
            previousMonthFromDate,
            previousMonthToDate
        };


    }

    const getReport = () => {
        resetEverything();
        const dates = getDates();
        setCurrentMonthStartDate(dates.currentMonthFromDate);
        setCurrentMonthEndDate(dates.currentMonthToDate);
        setPreviousMonthStartDate(dates.previousMonthFromDate);
        setPreviousMonthEndDate(dates.previousMonthToDate);
        setIsLoading(true);
        orderApi.getNewOrdersReport(loginUser.UserId, getCommaSeparatedUnitUniqueIdsExceptAll(loginUser.UnitAccesses), dates.currentMonthFromDate, dates.currentMonthToDate, null)
            .then(results => {
                if (!!results) {
                    setOrdersData(getCopy(results));
                }
                else {
                    setOrdersData([]);
                }

                setIsLoading(false);
            })
            .catch(e => {
                setOrdersData([]);
                setIsLoading(false);
            });

            setIsPreviousMonthLoading(true);
            orderApi.getNewOrdersReport(loginUser.UserId, getCommaSeparatedUnitUniqueIdsExceptAll(loginUser.UnitAccesses), dates.previousMonthFromDate, dates.previousMonthToDate, null)
            .then(results => {
                if (!!results) {
                    setOrdersDataPreviousMonth(getCopy(results));
                }
                else {
                    setOrdersDataPreviousMonth([]);
                }

                setIsPreviousMonthLoading(false);
            })
            .catch(e => {
                setOrdersDataPreviousMonth([]);
                setIsPreviousMonthLoading(false);
            });
    }

    //#endregion apis calls end

    //#region hooks start

    React.useEffect(() => {
        if (!!loginUser.UnitAccesses && loginUser.UnitAccesses.length > 0) {
            getReport();
        }
    }, [loginUser.UnitAccesses, props.refreshedOn]);

    //#endregion hooks end

    const selectedButtonColor: string = 'rgba(51, 170, 51, .4)';
    const defaultButtonColor: string = 'rgba(255,255,255,.1)';
    const tableCellPadding = 2;
    const [isPrinting, setIsPrinting] = React.useState<boolean>(false);
    const [selectedButtonOption, setSelectedButtonOption] = React.useState<'D' | 'M'>('M');
    const dimensions = useWindowDimensions();
    const totalColumns = 15;
    const [isCashView, setIsCashView] = React.useState<boolean>(false);
    const pageKey = 'New_Compoarison_OrdersComponentPageKey';
    return (
        <Container key={`${pageKey}_3`} style={{marginBottom: 20 }}>
            <span></span>
            {((isLoading === true || isPreviousMonthLoading == true) && ((!!props.employeeId && props.employeeId > 0) || props.isOverall === true)) && props.isLoadingParent === false && <Grid container>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
                    <CircularProgress />
                </Grid>
            </Grid>}

            {isLoading === false && isPreviousMonthLoading === false && ((!!props.employeeId && props.employeeId > 0) || props.isOverall === true) && <Grid container>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row' }}>
                    <FormControlLabel style={{alignSelf:'flex-end'}} control={<Switch checked={isCashView} onChange={() => {setIsCashView((isYes) => !isYes)}} />} label="Cash View" />
                </Grid>
            </Grid>}
                
                    {props.isLoadingParent === false && isLoading === false && ((!!props.employeeId && props.employeeId > 0) || (props.isOverall === true)) && isPreviousMonthLoading === false && !!ordersData && <div className="orders-table-parent">
                        {isCashView === true && <table border={1} cellSpacing={0} cellPadding={5} style={{marginBottom:10}} className="orders-table">
                            <tbody>
                            <tr><th colSpan={14} align='center' style={{ fontSize: 18 }}><span style={{ fontSize: 12 }}>{`Current Month Report (${formattedDate(currentMonthStartDate, 'DD-MMM-yyyy')} to ${formattedDate(currentMonthEndDate, 'DD-MMM-yyyy')})`}</span></th></tr>
                                {ordersData.filter(m => (!!props.employeeId && props.employeeId > 0 && m.EmployeeId === props.employeeId) || (!!props.isOverall === true && (m.DesignationId === 8 || m.DesignationId === 9))).map(od => {
                                    return <React.Fragment key={`UNT_OB_1_${od.UnitUniqueId}_${od.EmployeeId}`}>
                                        <tr><th colSpan={14} align='left' style={{color:'blue', fontWeight:'bold'}}>{`${od.FirstName} ${od.LastName} (${od.UnitShortName} ${od.Designation})`} </th></tr>
                                        <tr>
                                            {<th style={{ padding: tableCellPadding }}>Target</th>}
                                            {<th style={{ padding: tableCellPadding }}>Ach. Target</th>}
                                            {<th style={{ padding: tableCellPadding }}>Rem. Target</th>}
                                            {<th style={{ padding: tableCellPadding }}>Pend. Crdt.</th>}
                                            {<th style={{ padding: tableCellPadding, background: isPrinting ? undefined : 'rgba(51, 170, 51, .4)' }}>Ach. %age</th>}
                                            {<th style={{ padding: tableCellPadding, background: isPrinting ? undefined : 'rgba(255, 0, 0, .4)' }}>Rem. %age</th>}
                                            {<th style={{ padding: tableCellPadding }}>DO Amt.</th>}
                                            {<th style={{ padding: tableCellPadding }}>Return Amt.</th>}
                                            {<th style={{ padding: tableCellPadding, width: '7%' }}>DO Delivered Amt.</th>}
                                            {<th style={{ padding: tableCellPadding }}>DO Cash</th>}
                                            {<th style={{ padding: tableCellPadding }}>DO Crdt.</th>}
                                            {<th style={{ padding: tableCellPadding }}>Recovery</th>}
                                            {<th style={{ padding: tableCellPadding }}>Diff</th>}
                                            {<th style={{ padding: tableCellPadding }}>Total Cash</th>}
                                        </tr>

                                        <tr>
                                            {<td style={{ padding: tableCellPadding }}>{fCommaSeparatedWithDecial(od.TargetAmount)}</td>}
                                            {<td style={{ padding: tableCellPadding }}>{fCommaSeparatedWithDecial(od.AchievedTargetAmount)}</td>}
                                            {<td style={{ padding: tableCellPadding }}>{fCommaSeparatedWithDecial(od.RemainingTargetAmount)}</td>}
                                            {<td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : 'red' }}>{fCommaSeparatedWithDecial(od.EmployeePendingCredit)}</td>}
                                            {<td style={{ padding: tableCellPadding, background: isPrinting ? undefined : 'rgba(51, 170, 51, .4)' }}>{od.AchievedPercentage} %</td>}
                                            {<td style={{ padding: tableCellPadding, background: isPrinting ? undefined : 'rgba(255, 0, 0, .4)' }}>{od.RemainingPercentage} %</td>}
                                            {<td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : 'orange' }}>{fCommaSeparatedWithDecial(od.DoAmount)}</td>}
                                            {<td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : 'red' }}>{fCommaSeparatedWithDecial(od.DoAmount - od.DoDeliveredAmount)}</td>}
                                            {<td style={{ padding: tableCellPadding, width: '7%', color: isPrinting ? 'black' : 'blue' }}>{fCommaSeparatedWithDecial(od.DoDeliveredAmount)}</td>}
                                            {<td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : 'green' }}>{fCommaSeparatedWithDecial(od.DoDeliveredAmount - od.DoCreditAmount)}</td>}
                                            {<td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : 'red' }}>{fCommaSeparatedWithDecial(od.DoCreditAmount)}</td>}
                                            {<td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : 'green' }}>{fCommaSeparatedWithDecial(od.EmployeeRecovery)}</td>}
                                            {<td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : ((od.EmployeeRecovery - od.DoCreditAmount) > 0 ? 'green' : 'red') }}>{fCommaSeparatedWithDecial(od.EmployeeRecovery - od.DoCreditAmount)}</td>}
                                            {<td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : 'green' }}>{fCommaSeparatedWithDecial((od.DoDeliveredAmount - od.DoCreditAmount) + od.EmployeeRecovery)}</td>}
                                        </tr>
                                        <tr><td colSpan={14} style={{ borderLeft: 0, borderRight: 0 }}>&nbsp;</td></tr>
                                    </React.Fragment>;
                                })}
                            </tbody>
                        </table>}

                        {isCashView === false && <table border={1} cellSpacing={0} cellPadding={5} style={{marginBottom:10}} className="orders-table">
                            <tbody>
                            <tr><th colSpan={14} align='center' style={{ fontSize: 18 }}><span style={{ fontSize: 12 }}>{`Current Month Report (${formattedDate(currentMonthStartDate, 'DD-MMM-yyyy')} to ${formattedDate(currentMonthEndDate, 'DD-MMM-yyyy')})`}</span></th></tr>
                                {ordersData.filter(m => (!!props.employeeId && props.employeeId > 0 && m.EmployeeId === props.employeeId) || (!!props.isOverall === true && (m.DesignationId === 8 || m.DesignationId === 9))).map(od => {
                                    return <React.Fragment key={`UNT_OB_1_${od.UnitUniqueId}_${od.EmployeeId}`}>
                                        <tr><th colSpan={14} align='left' style={{color:'blue', fontWeight:'bold'}}>{`${od.FirstName} ${od.LastName} (${od.UnitShortName} ${od.Designation})`} </th></tr>
                                        <tr>
                                            {<th style={{ padding: tableCellPadding }}>Target(Pc.)</th>}
                                            {<th style={{ padding: tableCellPadding }}>Ach. Target(Pc.)</th>}
                                            {<th style={{ padding: tableCellPadding }}>Rem. Target(Pc.)</th>}
                                            {<th style={{ padding: tableCellPadding }}>Pend.Crdt.</th>}
                                            {<th style={{ padding: tableCellPadding, background: isPrinting ? undefined : 'rgba(51, 170, 51, .4)' }}>Ach. %age</th>}
                                            {<th style={{ padding: tableCellPadding, background: isPrinting ? undefined : 'rgba(255, 0, 0, .4)' }}>Rem. %age</th>}
                                            {<th style={{ padding: tableCellPadding }}>DO(Pc.)</th>}
                                            {<th style={{ padding: tableCellPadding }}>Return(Pc.)</th>}
                                            {<th style={{ padding: tableCellPadding, width: '7%' }}>DO Delivered(Pc.)</th>}
                                            {<th style={{ padding: tableCellPadding }}>DO Cash</th>}
                                            {<th style={{ padding: tableCellPadding }}>DO Crdt.</th>}
                                            {<th style={{ padding: tableCellPadding }}>Recovery</th>}
                                            {<th style={{ padding: tableCellPadding }}>Diff</th>}
                                            {<th style={{ padding: tableCellPadding }}>Total Cash</th>}
                                        </tr>

                                        <tr>
                                            {<td style={{ padding: tableCellPadding }}>{fUptoTwoDecimal(od.TargetCtn * 48)}</td>}
                                            {<td style={{ padding: tableCellPadding }}>{fUptoTwoDecimal(od.AchievedCtn * 48)}</td>}
                                            {<td style={{ padding: tableCellPadding }}>{fUptoTwoDecimal(od.RemainingCtn * 48)}</td>}
                                            {<td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : 'red' }}>{fCommaSeparatedWithDecial(od.EmployeePendingCredit)}</td>}
                                            {<td style={{ padding: tableCellPadding, background: isPrinting ? undefined : 'rgba(51, 170, 51, .4)' }}>{od.AchievedPercentage} %</td>}
                                            {<td style={{ padding: tableCellPadding, background: isPrinting ? undefined : 'rgba(255, 0, 0, .4)' }}>{od.RemainingPercentage} %</td>}
                                            {<td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : 'orange' }}>{fUptoTwoDecimal(od.DoCtn * 48)}</td>}
                                            {<td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : 'red' }}>{fUptoTwoDecimal(od.DoReturnCtn * 48)}</td>}
                                            {<td style={{ padding: tableCellPadding, width: '7%', color: isPrinting ? 'black' : 'blue' }}>{fUptoTwoDecimal(od.DoDeliveredCtn * 48)}</td>}
                                            {<td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : 'green' }}>{fCommaSeparatedWithDecial(od.DoDeliveredAmount - od.DoCreditAmount)}</td>}
                                            {<td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : 'red' }}>{fCommaSeparatedWithDecial(od.DoCreditAmount)}</td>}
                                            {<td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : 'green' }}>{fCommaSeparatedWithDecial(od.EmployeeRecovery)}</td>}
                                            {<td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : ((od.EmployeeRecovery - od.DoCreditAmount) > 0 ? 'green' : 'red') }}>{fCommaSeparatedWithDecial(od.EmployeeRecovery - od.DoCreditAmount)}</td>}
                                            {<td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : 'green' }}>{fCommaSeparatedWithDecial((od.DoDeliveredAmount - od.DoCreditAmount) + od.EmployeeRecovery)}</td>}
                                        </tr>
                                        <tr><td colSpan={14} style={{ borderLeft: 0, borderRight: 0 }}>&nbsp;</td></tr>
                                    </React.Fragment>;
                                })}
                            </tbody>
                        </table>}
                    </div>}
                
                    {props.isLoadingParent === false && isLoading === false && ((!!props.employeeId && props.employeeId > 0) || (props.isOverall === true))  && isPreviousMonthLoading === false && !!ordersDataPreviousMonth && <div className="orders-table-parent">
                        {isCashView === true && <table border={1} cellSpacing={0} cellPadding={5} className="orders-table">
                            <tbody>
                            <tr><th colSpan={14} align='center' style={{ fontSize: 18 }}><span style={{ fontSize: 12 }}>{`Previous Month Report (${formattedDate(previousMonthStartDate, 'DD-MMM-yyyy')} to ${formattedDate(previousMonthEndDate, 'DD-MMM-yyyy')})`}</span></th></tr>
                                {ordersDataPreviousMonth.filter(m => (!!props.employeeId && props.employeeId > 0 && m.EmployeeId === props.employeeId) || (props.isOverall === true && (m.DesignationId === 8 || m.DesignationId === 9))).map(od => {
                                    return <React.Fragment key={`UNT_OB_2_${od.UnitUniqueId}_${od.EmployeeId}`}>
                                        <tr><th colSpan={14} align='left' style={{color:'blue', fontWeight:'bold'}}>{`${od.FirstName} ${od.LastName} (${od.UnitShortName} ${od.Designation})`} </th></tr>
                                        <tr>
                                            {<th style={{ padding: tableCellPadding }}>Target</th>}
                                            {<th style={{ padding: tableCellPadding }}>Ach. Target</th>}
                                            {<th style={{ padding: tableCellPadding }}>Rem. Target</th>}
                                            {<th style={{ padding: tableCellPadding }}>Pnd. Crdt.</th>}
                                            {<th style={{ padding: tableCellPadding, background: isPrinting ? undefined : 'rgba(51, 170, 51, .4)' }}>Ach. %age</th>}
                                            {<th style={{ padding: tableCellPadding, background: isPrinting ? undefined : 'rgba(255, 0, 0, .4)' }}>Rem. %age</th>}
                                            {<th style={{ padding: tableCellPadding }}>DO Amt.</th>}
                                            {<th style={{ padding: tableCellPadding }}>Return Amt.</th>}
                                            {<th style={{ padding: tableCellPadding, width: '7%' }}>DO Delivered Amt.</th>}
                                            {<th style={{ padding: tableCellPadding }}>DO Cash</th>}
                                            {<th style={{ padding: tableCellPadding }}>DO Crdt.</th>}
                                            {<th style={{ padding: tableCellPadding }}>Recovery</th>}
                                            {<th style={{ padding: tableCellPadding }}>Diff</th>}
                                            {<th style={{ padding: tableCellPadding }}>Total Cash</th>}
                                        </tr>

                                        <tr>
                                            {<td style={{ padding: tableCellPadding }}>{fCommaSeparatedWithDecial(od.TargetAmount)}</td>}
                                            {<td style={{ padding: tableCellPadding }}>{fCommaSeparatedWithDecial(od.AchievedTargetAmount)}</td>}
                                            {<td style={{ padding: tableCellPadding }}>{fCommaSeparatedWithDecial(od.RemainingTargetAmount)}</td>}
                                            {<td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : 'red' }}>{fCommaSeparatedWithDecial(od.EmployeePendingCredit)}</td>}
                                            {<td style={{ padding: tableCellPadding, background: isPrinting ? undefined : 'rgba(51, 170, 51, .4)' }}>{od.AchievedPercentage} %</td>}
                                            {<td style={{ padding: tableCellPadding, background: isPrinting ? undefined : 'rgba(255, 0, 0, .4)' }}>{od.RemainingPercentage} %</td>}
                                            {<td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : 'orange' }}>{fCommaSeparatedWithDecial(od.DoAmount)}</td>}
                                            {<td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : 'red' }}>{fCommaSeparatedWithDecial(od.DoAmount - od.DoDeliveredAmount)}</td>}
                                            {<td style={{ padding: tableCellPadding, width: '7%', color: isPrinting ? 'black' : 'blue' }}>{fCommaSeparatedWithDecial(od.DoDeliveredAmount)}</td>}
                                            {<td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : 'green' }}>{fCommaSeparatedWithDecial(od.DoDeliveredAmount - od.DoCreditAmount)}</td>}
                                            {<td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : 'red' }}>{fCommaSeparatedWithDecial(od.DoCreditAmount)}</td>}
                                            {<td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : 'green' }}>{fCommaSeparatedWithDecial(od.EmployeeRecovery)}</td>}
                                            {<td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : ((od.EmployeeRecovery - od.DoCreditAmount) > 0 ? 'green' : 'red') }}>{fCommaSeparatedWithDecial(od.EmployeeRecovery - od.DoCreditAmount)}</td>}
                                            {<td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : 'green' }}>{fCommaSeparatedWithDecial((od.DoDeliveredAmount - od.DoCreditAmount) + od.EmployeeRecovery)}</td>}
                                        </tr>
                                        <tr><td colSpan={14} style={{ borderLeft: 0, borderRight: 0 }}>&nbsp;</td></tr>
                                    </React.Fragment>;
                                })}
                            </tbody>
                        </table>}

                        {isCashView === false && <table border={1} cellSpacing={0} cellPadding={5} className="orders-table">
                            <tbody>
                            <tr><th colSpan={14} align='center' style={{ fontSize: 18 }}><span style={{ fontSize: 12 }}>{`Previous Month Report (${formattedDate(previousMonthStartDate, 'DD-MMM-yyyy')} to ${formattedDate(previousMonthEndDate, 'DD-MMM-yyyy')})`}</span></th></tr>
                                {ordersDataPreviousMonth.filter(m => (!!props.employeeId && props.employeeId > 0 && m.EmployeeId === props.employeeId) || (props.isOverall === true && (m.DesignationId === 8 || m.DesignationId === 9))).map(od => {
                                    return <React.Fragment key={`UNT_OB_2_${od.UnitUniqueId}_${od.EmployeeId}`}>
                                        <tr><th colSpan={14} align='left' style={{color:'blue', fontWeight:'bold'}}>{`${od.FirstName} ${od.LastName} (${od.UnitShortName} ${od.Designation})`} </th></tr>
                                        <tr>
                                            {<th style={{ padding: tableCellPadding }}>Target(Pc.)</th>}
                                            {<th style={{ padding: tableCellPadding }}>Ach. Target(Pc.)</th>}
                                            {<th style={{ padding: tableCellPadding }}>Rem. Target(Pc.)</th>}
                                            {<th style={{ padding: tableCellPadding }}>Pnd. Crdt.</th>}
                                            {<th style={{ padding: tableCellPadding, background: isPrinting ? undefined : 'rgba(51, 170, 51, .4)' }}>Ach. %age</th>}
                                            {<th style={{ padding: tableCellPadding, background: isPrinting ? undefined : 'rgba(255, 0, 0, .4)' }}>Rem. %age</th>}
                                            {<th style={{ padding: tableCellPadding }}>DO(Pc.)</th>}
                                            {<th style={{ padding: tableCellPadding }}>Return(Pc.)</th>}
                                            {<th style={{ padding: tableCellPadding, width: '7%' }}>DO Delivered(Pc.)</th>}
                                            {<th style={{ padding: tableCellPadding }}>DO Cash</th>}
                                            {<th style={{ padding: tableCellPadding }}>DO Crdt.</th>}
                                            {<th style={{ padding: tableCellPadding }}>Recovery</th>}
                                            {<th style={{ padding: tableCellPadding }}>Diff</th>}
                                            {<th style={{ padding: tableCellPadding }}>Total Cash</th>}
                                        </tr>

                                        <tr>
                                            {<td style={{ padding: tableCellPadding }}>{fUptoTwoDecimal(od.TargetCtn * 48)}</td>}
                                            {<td style={{ padding: tableCellPadding }}>{fUptoTwoDecimal(od.AchievedCtn * 48)}</td>}
                                            {<td style={{ padding: tableCellPadding }}>{fUptoTwoDecimal(od.RemainingCtn * 48)}</td>}
                                            {<td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : 'red' }}>{fCommaSeparatedWithDecial(od.EmployeePendingCredit)}</td>}
                                            {<td style={{ padding: tableCellPadding, background: isPrinting ? undefined : 'rgba(51, 170, 51, .4)' }}>{od.AchievedPercentage} %</td>}
                                            {<td style={{ padding: tableCellPadding, background: isPrinting ? undefined : 'rgba(255, 0, 0, .4)' }}>{od.RemainingPercentage} %</td>}
                                            {<td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : 'orange' }}>{fUptoTwoDecimal(od.DoCtn * 48)}</td>}
                                            {<td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : 'red' }}>{fUptoTwoDecimal(od.DoReturnCtn * 48)}</td>}
                                            {<td style={{ padding: tableCellPadding, width: '7%', color: isPrinting ? 'black' : 'blue' }}>{fUptoTwoDecimal(od.DoDeliveredCtn * 48)}</td>}
                                            {<td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : 'green' }}>{fCommaSeparatedWithDecial(od.DoDeliveredAmount - od.DoCreditAmount)}</td>}
                                            {<td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : 'red' }}>{fCommaSeparatedWithDecial(od.DoCreditAmount)}</td>}
                                            {<td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : 'green' }}>{fCommaSeparatedWithDecial(od.EmployeeRecovery)}</td>}
                                            {<td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : ((od.EmployeeRecovery - od.DoCreditAmount) > 0 ? 'green' : 'red') }}>{fCommaSeparatedWithDecial(od.EmployeeRecovery - od.DoCreditAmount)}</td>}
                                            {<td style={{ padding: tableCellPadding, color: isPrinting ? 'black' : 'green' }}>{fCommaSeparatedWithDecial((od.DoDeliveredAmount - od.DoCreditAmount) + od.EmployeeRecovery)}</td>}
                                        </tr>
                                        <tr><td colSpan={14} style={{ borderLeft: 0, borderRight: 0 }}>&nbsp;</td></tr>
                                    </React.Fragment>;
                                })}
                            </tbody>
                        </table>}
                    </div>}

        </Container>
    );
}