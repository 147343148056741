import moment from 'moment';
export const defaultDateTimeFormat: string = 'DD-MM-YYYY HH:mm:ss';
export const defaultDateTimeTwelveHourFormat: string = 'DD-MMM-YYYY hh:mm A';
export const defaultDateFormat: string = 'MM/DD/YYYY';
export const formattedDate = (
  date: string | Date,
  format: string = defaultDateFormat,
) => moment(date).format(format);
export const formatedDateTime = (
  date: string | Date,
  format: string = defaultDateTimeFormat,
) => moment(date).format(format);

export const getDateOnly = (date:string | Date) => {
  const dated = moment(date).format(defaultDateFormat);
  return moment(dated).toDate();
}

export const timeAgo = (date:string | Date) =>{
  return (moment(date).fromNow());
}

export const getDaysDiff = (dateA:string | Date, dateB: string | Date) =>{
  var start = moment(dateA, "YYYY-MM-DD");
  var end = moment(dateB, "YYYY-MM-DD");
  const days = moment.duration(start.diff(end)).asDays();
  return days;
}

export const getNumberOfDaysInMonth = (monthIndex:number, year:number) =>{
  let day:number = 29;

  switch(monthIndex){
    case 0:{
      //jan
      day = 31;
      break;
    }
    case 1:{
      //feb
      day = isLeapYear(year) == true ? 29 : 28;
      break;
    }
    case 2:{
      //mar
      day = 31;
      break;
    }
    case 3:{
      //apr
      day = 30;
      break;
    }
    case 4:{
      //may
      day = 31;
      break;
    }
    case 5:{
      //jun
      day = 30;
      break;
    }
    case 6:{
      //jul
      day = 31;
      break;
    }
    case 7:{
      //aug
      day = 31;
      break;
    }
    case 8:{
      //sep
      day = 30;
      break;
    }
    case 9:{
      //oct
      day = 31;
      break;
    }
    case 10:{
      //nov
      day = 30;
      break;
    }
    case 11:{
      //dec
      day = 31;
      break;
    }
    default:{
      break;
    }
  }

  return day;
}

export const isLeapYear = (year:number) =>{
  return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
}
