import { ISelectedRouteIdOnMap } from 'src/interfaces/selected-route-id-on-map.interface';
import {NewRouteSetupConstant} from '../../actions/action.constant';
import {IAction} from '../../actions/action.interface';
import {initialState} from '../../initial-state';
import { getCopy } from 'src/utility';

export const selectedRouteIdOnMapReducer = (
  state: ISelectedRouteIdOnMap = initialState.selectedRouteIdOnMap,
  action: IAction<ISelectedRouteIdOnMap>,
) => {
  switch (action.type) {
    case NewRouteSetupConstant.SET_SELECTED_ROUTE_ID_ON_MAP: {;
      return getCopy(action.payload);
    }
    default:
      return state;
  }
};
