import {store} from '../redux/store';
import axios, {AxiosResponse} from 'axios';
import {handleError} from '../http-error-handler';
import {ApiBaseUrl} from '../app.constant';

const axiosInstance = axios.create({
  baseURL: ApiBaseUrl,
});

axiosInstance.interceptors.request.use(
  request => {
    const token = store.getState().token;

    if (!!token && !!token.access_token && !!request && !!request.headers) {
      request.headers['Authorization'] = `Bearer ${token.access_token}`;
    }

    return request;
  },
  error => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  undefined,
  function axiosRetryInterceptor(err) {
    if (
      err.response.status === 401 ||
      err.response.data.message === '401 Unauthorized'
    ) {
      //check if user is valid if not send him to login screen
      window.location.href = '/login';
    }

    handleError(axiosInstance.getUri(), err);
    return Promise.reject(err);
  },
);

export const parallelRequests = (requests:Promise<AxiosResponse<any, any>>[], success:(response:any[]) => void, error:(e:any) => void) => {
  axios.all(requests)
  .then(responses => {
    success(responses.map(r => r.data));
  })
  .catch(er => error(er))
}

export {axiosInstance as http};
