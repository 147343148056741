declare global {
    interface Date {
       addDays(days: number): Date;
    }
 
    interface Array<T> {
     count:(predicate:(x:T) => boolean) => number;
     sum:(column:(x:T) => number) => number;
    }
 }
 
 Date.prototype.addDays = function (days: number): Date {
    if (!days) return this;
    let date = this;
    date.setDate(date.getDate() + days);
 
    return date;
 };
 
 Array.prototype.count = function <T> (predicate:(x:T) => boolean) : number {
     return this.filter(predicate).length;
 }

 Array.prototype.sum = function <T> (getValue:(x:T) => number) : number {
    let totalSum = 0;
    if(!!this && this.length > 0){
        this.forEach(tt => {
            totalSum += getValue(tt);
        });
    }


    return totalSum;
}
 
 export {}