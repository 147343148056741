import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MonthPicker } from '@mui/x-date-pickers';
import { IAutoComplete } from 'src/interfaces/auto-complete.interface';
import { Grid } from '@mui/material';
import { CustomAutoComplete } from './CustomAutoComplete';
import { LoadingButton } from '@mui/lab';
import CustomDatePicker from './CustomDatePicker';
import { getNumberOfDaysInMonth } from 'src/utility';

interface IProps {
    minYear: number;
    maxYear: number;
    selectedYear: number;
    selectedMonthIndex: number;
    onFromDateChange: (dated: Date) => void;
    onToDateChange: (dated: Date) => void;
    onChange: (year: number, monthIndex: number) => void;
    showLoadButton?: boolean;
    onLoadClick?: () => void;
    lastNumberOfMonth?: number;
}

export default function CustomMonthPicker(props: IProps) {
    const allMonthIndexes: IAutoComplete[] = [
        { DisplayText: 'Jan', Value: 0 },
        { DisplayText: 'Feb', Value: 1 },
        { DisplayText: 'Mar', Value: 2 },
        { DisplayText: 'Apr', Value: 3 },
        { DisplayText: 'May', Value: 4 },
        { DisplayText: 'Jun', Value: 5 },
        { DisplayText: 'Jul', Value: 6 },
        { DisplayText: 'Aug', Value: 7 },
        { DisplayText: 'Sep', Value: 8 },
        { DisplayText: 'Oct', Value: 9 },
        { DisplayText: 'Nov', Value: 10 },
        { DisplayText: 'Dec', Value: 11 },
    ];

    const [years, setYears] = React.useState<IAutoComplete[]>([]);
    const [monthIndexes, setMonthIndexes] = React.useState<IAutoComplete[]>([...allMonthIndexes]);
    const [fromDate, _setFromDate] = React.useState<Date>();
    const [toDate, _setToDate] = React.useState<Date>();
    const [minDate, setMinDate] = React.useState<Date>();
    const [maxDate, setMaxDate] = React.useState<Date>();

    const setFromDate = (date: Date) => {
        _setFromDate(date);
        props.onFromDateChange(date);
    }

    const setToDate = (date: Date) => {
        _setToDate(date);
        props.onToDateChange(date);
    }

    React.useEffect(() => {
        if (!!props.lastNumberOfMonth && props.lastNumberOfMonth < 12) {
            let todayDated = new Date();
            let startMonthDated = new Date();
            startMonthDated.setMonth(todayDated.getMonth() - props.lastNumberOfMonth);

            let minimumYear = startMonthDated.getFullYear();
            let maximumYear = todayDated.getFullYear();

            let tempYears: IAutoComplete[] = [];
            for (var i = minimumYear; i <= maximumYear; i++) {
                tempYears.push({
                    DisplayText: i.toString(),
                    Value: i
                });
            }

            setYears([...tempYears]);
            setMonthIndexes([...allMonthIndexes.filter(m => m.Value >= startMonthDated.getMonth() && m.Value <= todayDated.getMonth())]);

        } else {
            if (props.minYear > 0 && props.maxYear > 0) {
                let tempYears: IAutoComplete[] = [];
                for (var i = props.minYear; i <= props.maxYear; i++) {
                    tempYears.push({
                        DisplayText: i.toString(),
                        Value: i
                    });
                }

                setYears([...tempYears]);
            }
        }
    }, [props.minYear, props.maxYear, props.lastNumberOfMonth]);

    React.useEffect(() => {
        if (props.selectedYear > 0 && props.selectedMonthIndex >= 0 && props.selectedMonthIndex <= 11) {
            const firstDate = new Date(props.selectedYear, props.selectedMonthIndex, 1, 0, 0, 0, 0);
            const lastDate = new Date(props.selectedYear, props.selectedMonthIndex, getNumberOfDaysInMonth(props.selectedMonthIndex, props.selectedYear), 0, 0, 0, 0);
            setMinDate(firstDate);
            setMaxDate(lastDate);

            setFromDate(firstDate);
            setToDate(lastDate);
        }
    }, [props.selectedMonthIndex, props.selectedYear]);

    return (
        <Grid container spacing={2}>
            {!!years && years.length > 0 && <Grid item md={12} sm={12} sx={{ display: 'flex', flexDirection: 'row' }}>
                <CustomAutoComplete<IAutoComplete>
                    Id={'YEAR_AUTOCOMPLETE'}
                    Value={!!years && years.length > 0 && props.selectedYear > 0 ? years.find(m => m.Value == props.selectedYear) : null}
                    Options={years}
                    displayField={'DisplayText'}
                    valueField={'Value'}
                    Label={'Year'}
                    onChange={(option: IAutoComplete) => {
                        let yearId = 0;
                        if (!!option) {
                            yearId = option.Value;
                        }

                        props.onChange(yearId, props.selectedMonthIndex);
                    }}
                    style={{ minWidth: 130, width: 130 }}
                />

                <CustomAutoComplete<IAutoComplete>
                    Id={'MONTH_AUTOCOMPLETE'}
                    Value={!!monthIndexes && monthIndexes.length > 0 && props.selectedMonthIndex >= 0 ? monthIndexes.find(m => m.Value == props.selectedMonthIndex) : null}
                    Options={monthIndexes}
                    displayField={'DisplayText'}
                    valueField={'Value'}
                    Label={'Month'}
                    onChange={(option: IAutoComplete) => {
                        let monthIndex = 0;
                        if (!!option) {
                            monthIndex = option.Value;
                        }

                        props.onChange(props.selectedYear, monthIndex);
                    }}
                    style={{ minWidth: 130, width: 130, marginLeft: 10 }}
                />

                <div style={{ maxWidth: 300 }}><CustomDatePicker
                    label={'From Date'}
                    value={fromDate}
                    onChange={(v: Date) => {
                        setFromDate(v);
                    }}
                    marginLeft={10}
                    minDate={minDate}
                    maxDate={maxDate}
                    inputDisable={true}
                /></div>

                <div style={{ maxWidth: 300, marginLeft: 10 }}><CustomDatePicker
                    label={'To Date'}
                    value={toDate}
                    onChange={(v: Date) => {
                        setToDate(v);
                    }}
                    marginLeft={10}
                    minDate={minDate}
                    maxDate={maxDate}
                    inputDisable={true}
                /></div>

                {props.showLoadButton == true && <LoadingButton
                    size="small"
                    type="button"
                    variant={'outlined'}
                    loading={false}
                    disabled={false}
                    style={{ alignSelf: 'center', marginLeft: 20 }}
                    onClick={() => {
                        if (props.onLoadClick) {
                            props.onLoadClick();
                        }
                    }}
                    key={`LOAD_MONTH_`}
                >
                    Load
                </LoadingButton>}

                {/* {monthIndexes.map(mIndex => {
                    return <LoadingButton
                        size="small"
                        type="button"
                        variant={props.selectedMonthIndex == mIndex.Value ? 'contained' : 'outlined'}
                        loading={false}
                        disabled={false}
                        style={{ alignSelf: 'center', marginLeft:10 }}
                        onClick={() => {
                            props.onChange(props.selectedYear, mIndex.Value);
                        }}
                        key={`MONTH_ITEM_${mIndex.Value}`}
                    >
                        {mIndex.DisplayText}
                    </LoadingButton>
                })} */}

            </Grid>}
        </Grid>
    );
}
