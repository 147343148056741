import React from 'react';
import { connect, useSelector } from 'react-redux';
import { MessageComponent } from 'src/components/messages.component';
import { areaApi, employeeApi, routeApi } from 'src/http';
import { IMessage } from 'src/interfaces/message.interface';
import { IApplicationState } from 'src/redux';
import { encodeBase64String, getCopy, isNumber } from 'src/utility';
import { confirmAction } from 'src/utility/confirm.util';
import { fCommaSeparated, fCurrency } from 'src/utils/formatNumber';
import Page from 'src/components/Page';
import { Card, CircularProgress, Container, Grid, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from '@mui/material';
import { CustomAutoComplete } from 'src/components/common/CustomAutoComplete';
import { IAutoComplete } from 'src/interfaces/auto-complete.interface';
import { LoadingButton } from '@mui/lab';
import { Icon } from '@iconify/react';
import { UserListHead } from 'src/components/_dashboard/user';
import '../page-styles/route-setup.css';
import { getUrlFromComponentKey } from 'src/utility/navigation.util';
import { Keys } from 'src/component.keys';
import { useNavigate } from 'react-router-dom';
import { NewRouteSetupPageParam, RouteDayDataSource, RouteDetailParam, UnAssignedOrderBookerId } from 'src/app.constant';
import { ISalesManager } from 'src/interfaces/sales-manager.interface';
import { ISubOrdinate } from 'src/interfaces/sub-ordinates.interface';




const CREDIT_LIMITS_TABLE_HEAD = [
    { id: 'employee_name', label: 'Employee', alignContent: 'left' },
    { id: 'Unit', label: 'Unit', alignContent: 'left' },
    { id: 'credit_limit', label: 'Credit Limit', alignContent: 'left' },
    { id: 'used_limit', label: 'Used Limit', alignContent: 'left' },
    { id: 'remaining_limit', label: 'Remaining', alignContent: 'left' },
    { id: 'action_button', label: '', alignContent: 'left' },
];

interface IProps {

}

export const CreditLimits = (props: IProps) => {
    const navigate = useNavigate();
    const showErrorMessage = (message: string) => addMessage(message, 'error');
    const showInfoMessage = (message: string) => addMessage(message, 'info');
    const showSuccessMessage = (message: string) => addMessage(message, 'success');
    const showWarningMessage = (message: string) => addMessage(message, 'warning');
    const selectedOrderBookerBackgroundColor: string = '#f7fafa';
    const [managersAutoComplete, setManagersAutoComplete] = React.useState<ISalesManager[]>([]);
    const [isLoadingManagersAutoComplete, setIsLoadingManagersAutoComplete] = React.useState<boolean>(false);
    const [selectedManager, setSelectedManager] = React.useState<ISalesManager>(null);
    const [subOrdinates, setSubOrdinates] = React.useState<ISubOrdinate[]>([]);
    const [isSubOrdinatesLoading, setIsSubOrdinatesLoading] = React.useState<boolean>(false);

    const [editedLimit, setEditedLimit] = React.useState<number>(0);
    const [editedLimitText, setEditedLimitText] = React.useState<string>('');
    const loginUser = useSelector((state: IApplicationState) => state.loginUser);
    const [selectedEmployeeIdToEdit, setSelectedEmployeeIdToEdit] = React.useState<number>(0);
    const [pageMessages, setPageMessages] = React.useState<IMessage[]>([]);
    const [isSavingLimit, setIsSavingLimit] = React.useState<boolean>(false);
    const addMessage = (messageText: string, variant: 'success' | 'error' | 'info' | 'warning') => {
        if (!!messageText && messageText.trim() != '') {
            let msgs = [];
            msgs.push({
                messageText,
                variant
            });

            setPageMessages([...msgs]);
        }
    }

    const clearMessages = () => {
        setPageMessages([]);
    }

    const clearMessagesExceptSuccess = () => {
        setPageMessages((messages: IMessage[]) => [...messages.filter(pm => pm.variant == 'success')]);
    }


    const onManagerAutoCompleteChange = (option: ISalesManager) => {
        setSelectedManager(option ?? null);
        setPageMessages([]);


        if (!!option && !!option.EmployeeId && option.EmployeeId > 0) {
            loadSubOrdinates(option.EmployeeId);
        }
    }

    const loadSubOrdinates = (managerId: number) => {
        setSubOrdinates([]);
        setIsSubOrdinatesLoading(true);
        employeeApi.getSubOrdinates(managerId)
            .then(results => {
                setIsSubOrdinatesLoading(false);
                setSubOrdinates(getCopy(results));
            })
            .catch(e => {
                console.log(e);
                setSubOrdinates([]);
                setIsSubOrdinatesLoading(false);
            })
    }

    const loadManagersAutoComplete = () => {
        setManagersAutoComplete([]);
        setIsLoadingManagersAutoComplete(true);
        employeeApi.getSalesManagers(loginUser?.UserId ?? 0)
            .then(results => {
                setIsLoadingManagersAutoComplete(false);
                setManagersAutoComplete(getCopy(results));
            })
            .catch(e => {
                console.log(e);
                setIsLoadingManagersAutoComplete(false);
                setManagersAutoComplete([]);
            })
    }

    const editEmployeeLimit = (employeeId: number, currentLimit: number) => {
        setSelectedEmployeeIdToEdit(employeeId);
        setEditedLimit(currentLimit);
        setEditedLimitText(currentLimit.toString());
        setPageMessages([]);
    }

    const onCancelEidt = () => {
        setSelectedEmployeeIdToEdit(0);
        setEditedLimit(0);
        setEditedLimitText("");
        setPageMessages([]);
    }

    const validate = (oldLimit:number, newLimit:number, usedLimit:number) =>{
        setPageMessages([]);
        if(newLimit === undefined || newLimit == null){
            showErrorMessage('Please Enter Limit');
            return false;
        }

        if(newLimit < (usedLimit ?? 0)){
            showErrorMessage(`Credit Limit Can't be less than already used limit.`)
            return false;
        }

        const assignedLimit = ((managerLimitInfo?.alreadyAssignedLimit ?? 0) - (oldLimit ?? 0)) + (newLimit ?? 0);
        if(assignedLimit > (managerLimitInfo?.creditLimit ?? 0)){
            showErrorMessage(`You don't have enough balance to set this limit.`);
            return false;
        }

        return true;
    }

    const onSaveLimit = (oldLimit:number, newLimit:number, usedLimit:number, employeeId:number) =>{
        if(validate(oldLimit, newLimit, usedLimit)){
            setIsSavingLimit(true);
            employeeApi.saveCreditLimit(selectedManager.EmployeeId, employeeId, newLimit)
            .then(res => {
                if(!!res && res.toLowerCase().indexOf('success') >= 0){
                    showSuccessMessage('Saved Successfully');
                    let copy = getCopy(subOrdinates);
                    let item = copy.find(m => m.EmployeeId === employeeId);
                    item.CreditLimit = newLimit;
                    setSubOrdinates(getCopy(copy));
                    setSelectedEmployeeIdToEdit(0);
                    setEditedLimit(0);
                    setEditedLimitText("");
                    
                    let managersCopy = getCopy(managersAutoComplete);
                    let manager = managersCopy.find(m => m.EmployeeId === employeeId);
                    if(!!manager){
                        manager.CreditLimit = newLimit;
                        setManagersAutoComplete(managersCopy);
                    }
                }else{
                    showErrorMessage(res);
                }

                setIsSavingLimit(false);
            })
            .catch(e => {
                console.log(e);
                showErrorMessage('Unable to save limit. Please contact system admin');
                setIsSavingLimit(false);
            })
        }
    }

    const managersAutoCompleteAltered = React.useMemo(() => {
        return managersAutoComplete.map(a => {
            a.DisplayName = a.DesignationId === 8 || a.DesignationId === 10 ? `${a.Designation}` : `${a.FirstName} ${a.LastName} (${a.Designation}) (${a.UnitShortName})`;
            return a;
        })
    }, [managersAutoComplete]);

    const managerLimitInfo = React.useMemo(() => {
        let balance = 0;
        let alreadyAssignedLimit = 0;
        let creditLimit = 0;
        let usedLimit = 0;
        if (!!selectedManager && selectedManager.EmployeeId > 0 && !!subOrdinates && subOrdinates.length > 0) {
            subOrdinates.forEach(so => {
                alreadyAssignedLimit += so.CreditLimit;
                usedLimit += so.UsedLimit;
            });

            creditLimit = selectedManager.CreditLimit;
            balance = selectedManager.CreditLimit - alreadyAssignedLimit;
        }

        return {
            creditLimit,
            alreadyAssignedLimit,
            balance,
            usedLimit
        };

    }, [subOrdinates, selectedManager])

    React.useEffect(() => {
        loadManagersAutoComplete();
    }, [loginUser]);

    return <Page title="Credit Limit | Raza Pak Care">
        <Container style={{ paddingBottom: 20, marginTop:10, maxWidth: '100%' }}><MessageComponent onRemove={(i: number) => {
            let msgs = [...pageMessages];
            msgs.splice(i, 1);
            setPageMessages([...msgs]);
        }} messages={pageMessages} />
        </Container>
        <Container style={{ paddingBottom: 10, maxWidth: '100%' }}>
            <Grid container spacing={2}>
                <Grid item md={3} xs={12}>
                    {<CustomAutoComplete<ISalesManager>
                        Id={'Managers_Auto_Complete'}
                        Value={selectedManager}
                        Options={managersAutoCompleteAltered}
                        displayField={'DisplayName'}
                        valueField={'EmployeeId'}
                        Label={'Officer'}
                        onChange={onManagerAutoCompleteChange}
                        size='small'
                        isLoading={isLoadingManagersAutoComplete}
                        readOnly={isLoadingManagersAutoComplete || isSubOrdinatesLoading}
                    />}
                </Grid>
                <Grid item md={3} xs={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <LoadingButton
                        size="small"
                        type="button"
                        variant="outlined"
                        disabled={!selectedManager || !selectedManager.EmployeeId || selectedManager.EmployeeId <= 0}
                        style={{ alignSelf: 'flex-start' }}
                        startIcon={<Icon icon={'mdi:refresh'} />}
                        onClick={() => {
                            loadSubOrdinates(selectedManager.EmployeeId);
                            setPageMessages([]);
                        }}
                        key={`btnRefresh`}
                    >
                        Refresh
                    </LoadingButton>
                </Grid>
                {!!selectedManager && selectedManager.EmployeeId > 0 && <Grid item md={6} xs={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Typography style={{alignSelf:'flex-end', marginRight:10, color:'blue'}} variant='subtitle1'>{`Manager Limit: ${fCommaSeparated(managerLimitInfo.creditLimit)}`}</Typography>
                    <Typography style={{alignSelf:'flex-end', marginRight:10, color:'red'}} variant='subtitle1'>{`Assigned Limit: ${fCommaSeparated(managerLimitInfo.alreadyAssignedLimit)}`}</Typography>
                    <Typography style={{alignSelf:'flex-end', marginRight:10, color:managerLimitInfo.balance > 0 ? 'green' : 'black'}} variant='subtitle1'>{`Balance:${fCommaSeparated(managerLimitInfo.balance)}`}</Typography>
                </Grid>}
            </Grid>
        </Container>

        {<Container style={{ paddingBottom: 10, maxWidth: '100%' }}>
            <Card>


                {<TableContainer sx={{ minWidth: 800, maxWidth: '100%' }}>
                    <Table sx={{ borderSpacing: '0px 3px' }} stickyHeader>
                        <UserListHead
                            order={'asc'}
                            orderBy={''}
                            headLabel={CREDIT_LIMITS_TABLE_HEAD}
                            rowCount={0}
                            numSelected={0}
                            onRequestSort={undefined}
                            onSelectAllClick={undefined}
                            customKey={`TableHeadRow_6`}
                            typographyVariant='subtitle2'
                            backgroundColor={'#4279a3'}
                            textColor='white'
                        />
                        <TableBody>
                            {isSubOrdinatesLoading == true && <TableRow
                                key={'LoadingRow'}
                                tabIndex={-1}
                                role="checkbox"
                                selected={false}
                                aria-checked={false}
                            >
                                <TableCell align='center' colSpan={CREDIT_LIMITS_TABLE_HEAD.length}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>}
                            {isSubOrdinatesLoading == false && (!subOrdinates || subOrdinates.length <= 0) && <TableRow
                                key={'LoadingRow'}
                                tabIndex={-1}
                                role="checkbox"
                                selected={false}
                                aria-checked={false}
                            >
                                <TableCell align='center' colSpan={CREDIT_LIMITS_TABLE_HEAD.length}>
                                    {`No Data`}
                                </TableCell>
                            </TableRow>}
                            {isSubOrdinatesLoading == false && !!subOrdinates && subOrdinates.length > 0 && subOrdinates.map((row, index) => {
                                return (
                                    <React.Fragment key={`SUB_Ordinate_${row.EmployeeId}`}><TableRow
                                        hover
                                        key={row.EmployeeId}
                                        tabIndex={-1}
                                        role="checkbox"
                                        selected={false}
                                        aria-checked={false}
                                        className={'order-booker'}
                                    >
                                        <TableCell width={'20%'} style={{ fontWeight: 'bold', color: 'blue' }} align="left">
                                            {`${row.FirstName} ${row.LastName} (${row.Designation})`}
                                        </TableCell>
                                        <TableCell width={'10%'} align="left">
                                            {row.UnitTitle}
                                        </TableCell>
                                        {selectedEmployeeIdToEdit !== row.EmployeeId && <TableCell style={{color:'blue'}} width={!!selectedEmployeeIdToEdit && selectedEmployeeIdToEdit > 0 ? '40%': '10%'} align="left">
                                            {fCommaSeparated(row.CreditLimit)}
                                        </TableCell>}
                                        {selectedEmployeeIdToEdit === row.EmployeeId && <TableCell width={'40%'}>
                                            <table width={"100%"}><tbody><tr><td width="40%" style={{ paddingRight: 5 }}>
                                                <TextField
                                                    id={'edited_limit'}
                                                    fullWidth
                                                    inputMode='numeric'
                                                    placeholder={'New Limit'}
                                                    label={'New Limit'}
                                                    value={editedLimitText}
                                                    onChange={(event) => {
                                                        setPageMessages([]);
                                                        setEditedLimitText(event.target.value);
                                                        if(isNumber(event.target.value)){
                                                            setEditedLimit(parseInt(event.target.value))
                                                        }else{
                                                            setEditedLimit(0)
                                                        }
                                                    }}
                                                />
                                            </td>
                                                <td width="20%" align='left'>
                                                    {isSavingLimit == false && <span onClick={() => {
                                                        onSaveLimit(row.CreditLimit, editedLimit, row.UsedLimit, row.EmployeeId);
                                                    }} className={'btn-text'} style={{ marginLeft: 14 }}>Save</span>}
                                                    {isSavingLimit == false && <span onClick={() => {
                                                        onCancelEidt();
                                                    }} className='btn-text'>Cancel</span>}
                                                    {isSavingLimit == true && <span className='btn-text'><CircularProgress /></span>}
                                                </td>
                                            </tr></tbody></table>
                                        </TableCell>}
                                        <TableCell style={{color:'red'}} width={'10%'} align="left">
                                            {fCommaSeparated(row.UsedLimit)}
                                        </TableCell>
                                        <TableCell style={{color:'green'}} width={'10%'} align="left">
                                            {fCommaSeparated(row.CreditLimit - row.UsedLimit)}
                                        </TableCell>
                                        
                                        {<TableCell align="right">
                                            {selectedEmployeeIdToEdit !== row.EmployeeId && !!selectedManager && selectedManager.CanBeEdited === true && <span onClick={() => {
                                                editEmployeeLimit(row.EmployeeId, row.CreditLimit);
                                            }} className='btn-text'>Edit</span>}
                                        </TableCell>}
                                        
                                    </TableRow>
                                    </React.Fragment>
                                );
                            })}

                                    {isSubOrdinatesLoading == false && !!subOrdinates && subOrdinates.length > 0 && <TableRow
                                        hover
                                        key={'Totals'}
                                        tabIndex={-1}
                                        role="checkbox"
                                        selected={false}
                                        aria-checked={false}
                                        className={'order-booker'}
                                    >
                                        <TableCell width={'30%'} style={{ fontWeight: 'bold', color: 'blue' }} align="right" colSpan={2}>
                                            {`Totals:`}
                                        </TableCell>
                                        {<TableCell style={{fontWeight: 'bold',color:'blue'}} width={!!selectedEmployeeIdToEdit && selectedEmployeeIdToEdit > 0 ? '40%': '10%'} align="left">
                                            {fCommaSeparated(managerLimitInfo.alreadyAssignedLimit)}
                                        </TableCell>}
                                        
                                        <TableCell style={{fontWeight: 'bold',color:'red'}} width={'10%'} align="left">
                                            {fCommaSeparated(managerLimitInfo.usedLimit)}
                                        </TableCell>
                                        <TableCell style={{fontWeight: 'bold',color:'green'}} width={'10%'} align="left">
                                            {fCommaSeparated(managerLimitInfo.alreadyAssignedLimit - managerLimitInfo.usedLimit)}
                                        </TableCell>
                                        
                                        {<TableCell align="right">
                                            &nbsp;
                                        </TableCell>}
                                        
                                    </TableRow>}

                        </TableBody>
                    </Table>
                </TableContainer>}
            </Card>
        </Container>}
    </Page>;
}