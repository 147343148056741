import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IApplicationState } from 'src/redux';
import { useSelector } from 'react-redux';
import { CircularProgress, Container, Grid, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { IAutoComplete } from 'src/interfaces/auto-complete.interface';
import { CustomAutoComplete } from './common/CustomAutoComplete';
import { employeeApi, masterDataApi, routeApi } from 'src/http';
import { shopApi } from 'src/http/shop.http';
import { IRouteShopDetail } from 'src/interfaces/route-shop-detail.interface';
import { IShopUpdateRequest } from 'src/interfaces/shop-update-request.interface';
import { getCopy, isValidCellNumber, isValidIMEI } from 'src/utility';
import { IMessage } from 'src/interfaces/message.interface';
import { MessageComponent } from './messages.component';
import { ITabletInfo } from 'src/interfaces/tablet-info.interface';
import { tabletApi } from 'src/http/tablet.http';

interface IProps {
    unitUniqueId: string;
    unitTitle:string;
    onClose: () => void;
    onSuccess: (newTablet: ITabletInfo) => void;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    paddingTop: 2,
    paddingBottom: 2
};

export const AddNewTabletPopup = (props: IProps) => {
    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick") {
            return;
        }

        props.onClose();
    }

    const loginUser = useSelector((state: IApplicationState) => state.loginUser);
    const [tabletToAdd, setTabletToAdd] = React.useState<ITabletInfo>();

    const [pageMessages, setPageMessages] = React.useState<IMessage[]>([]);
    const addMessage = (messageText: string, variant: 'success' | 'error' | 'info' | 'warning') => {
        if (!!messageText && messageText.trim() != '') {
            let msgs = [];
            msgs.push({
                messageText,
                variant
            });

            setPageMessages([...msgs]);
        }
    }

    const showErrorMessage = (message: string) => addMessage(message, 'error');
    const showInfoMessage = (message: string) => addMessage(message, 'info');
    const showSuccessMessage = (message: string) => addMessage(message, 'success');
    const showWarningMessage = (message: string) => addMessage(message, 'warning');



    const onChangeIMEIOne = (event: any) => {
        setPageMessages([]);
        setTabletToAdd({ ...tabletToAdd, IMEIOne: event.target.value });
    }

    const onChangeIMEITwo = (event: any) => {
        setPageMessages([]);
        setTabletToAdd({ ...tabletToAdd, IMEITwo: event.target.value });
    }

    const validate = ():Promise<boolean> => {
        return new Promise((resolve,reject) => {
            setIsLoading(true);
            if (!tabletToAdd?.IMEIOne || tabletToAdd.IMEIOne.trim() === '') {
                showErrorMessage('Unique ID Is Required.');
                setIsLoading(false);
                resolve(false);
            }
            else{
                console.log('going to send call');
                tabletApi.isTabletAlreadyExists(tabletToAdd.IMEIOne, tabletToAdd.IMEITwo)
                .then(isExists => {
                    if(isExists === true){
                        showErrorMessage('Device Already Registered');
                        setIsLoading(false);
                        resolve(false);
                    }else{
                        resolve(true);
                    }
                })
                .catch(e => {
                    showErrorMessage('Unable to get device info.');
                    setIsLoading(false);
                    resolve(false);
                })
            }
        });
    }


    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const onSave = () => {
        validate().then(isvalid => {
            if(isvalid){
                tabletApi.addNewTablet(getCopy(tabletToAdd), loginUser?.UserId ?? 0)
                    .then((addedResult) => {
                        setIsLoading(false);
                        props.onSuccess(addedResult);
                        setTabletToAdd({...tabletToAdd,IMEIOne:'', IMEITwo:''});
                        showSuccessMessage('Added Successfully')
                    })
                    .catch(() => {
                        showErrorMessage('Error while adding tablet.');
                        setIsLoading(false);
                    });
            }
        })
    }

    React.useEffect(() => {
        setTabletToAdd({
            AssignToEmployee:'',
            AssignToEmployeeId:0,
            AssignToUserId:0,
            AssignToUserName:'',
            DeviceTag:'',
            IMEIOne:'',
            IMEITwo:'',
            IsCollapsed:true,
            TabletComments:[],
            TabletId:0,
            TabName:'',
            TabStatus:'OK',
            TabStatusId:1,
            UnitId:0,
            UnitTitle:'',
            UnitUniqueId:props.unitUniqueId,
            IsEditMode:false,
        });
    },[props.unitUniqueId]);

    return <Modal
        open={!!props.unitUniqueId && props.unitUniqueId.trim() !== ''}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={style}>
            <Grid item md={12} style={{ justifyContent: 'center', display: 'flex', flexDirection:'column' }}>
                <Typography style={{ alignSelf: 'center' }} id="modal-modal-title" variant="h6" component="h2">
                    Add New Tablet
                </Typography>
                <Typography style={{ alignSelf: 'center' }} id="modal-modal-title" variant="subtitle1" component="span">
                    {props?.unitTitle ?? ''}
                </Typography>
            </Grid>

            <Container>
                {(isLoading === true || (!!pageMessages && pageMessages.length > 0)) && <Grid container spacing={2} style={{ marginTop: 2 }}>
                {isLoading === true && <Grid item md={12} style={{ justifyContent: 'center', display: 'flex' }}>
                    <CircularProgress />
                </Grid>}
                {!!pageMessages && pageMessages.length > 0 && <Grid item md={12} xs={12}>
                    <MessageComponent onRemove={(i: number) => {
                        let msgs = [...pageMessages];
                        msgs.splice(i, 1);
                        setPageMessages([...msgs]);
                    }} messages={pageMessages} /></Grid>}
                </Grid>}
                <Grid container spacing={2} style={{ marginTop: 2 }}>
                    <Grid item md={12} xs={12}>
                        <TextField
                            fullWidth
                            autoComplete="imei_one"
                            size='small'
                            variant='outlined'
                            type={'text'}
                            label="Unique ID"
                            value={tabletToAdd?.IMEIOne ?? ''}
                            disabled={isLoading}
                            onChange={onChangeIMEIOne}
                        />
                    </Grid>

                    {/* <Grid item md={12} xs={12}>
                        <TextField
                            fullWidth
                            autoComplete="imei_two"
                            size='small'
                            variant='outlined'
                            type={'text'}
                            label="IMEI Two"
                            value={tabletToAdd?.IMEITwo ?? ''}
                            disabled={isLoading}
                            onChange={onChangeIMEITwo}
                        />
                    </Grid> */}
                </Grid>

                <Grid item md={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <LoadingButton
                        size="small"
                        type="button"
                        variant="outlined"
                        loading={isLoading}
                        disabled={isLoading}
                        style={{ marginTop: 15, alignSelf: 'center', marginRight: 10, borderRadius: 0 }}
                        onClick={() => {
                            onSave();
                        }}
                    >
                        Save
                    </LoadingButton>

                    <LoadingButton
                        size="small"
                        type="button"
                        variant="outlined"
                        loading={false}
                        disabled={isLoading}
                        style={{ marginTop: 15, alignSelf: 'center', color: 'gray', borderRadius: 0, borderColor: 'gray' }}
                        onClick={props.onClose}
                    >
                        Close
                    </LoadingButton>
                </Grid>
            </Container>
        </Box>
    </Modal>;
}