import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IApplicationState } from 'src/redux';
import { useSelector } from 'react-redux';
import { CircularProgress, Container, Grid, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { IAutoComplete } from 'src/interfaces/auto-complete.interface';
import { CustomAutoComplete } from './common/CustomAutoComplete';
import { employeeApi, masterDataApi, routeApi } from 'src/http';
import { shopApi } from 'src/http/shop.http';
import { IRouteShopDetail } from 'src/interfaces/route-shop-detail.interface';
import { IShopUpdateRequest } from 'src/interfaces/shop-update-request.interface';
import { getCopy, isValidCellNumber, isValidIMEI } from 'src/utility';
import { IMessage } from 'src/interfaces/message.interface';
import { MessageComponent } from './messages.component';
import { ITabletInfo } from 'src/interfaces/tablet-info.interface';
import { tabletApi } from 'src/http/tablet.http';
import { StaticDatePicker } from '@mui/lab';
import { IUnitAccess } from 'src/interfaces/unit-access.interface';
import { Icon } from '@iconify/react';

interface IProps {
    isOpen:boolean;
    unitUniqueId: string;
    unitTitle:string;
    transferToUnitsAutoComplete:IUnitAccess[];
    unassignTabletsAutoComplete:IAutoComplete[];
    onClose: () => void;
    onSuccess: (tabletId:number) => void;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    paddingTop: 2,
    paddingBottom: 2
};

export const TransferTabletPopup = (props: IProps) => {
    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick") {
            return;
        }

        props.onClose();
    }

    const loginUser = useSelector((state: IApplicationState) => state.loginUser);
    const [tabletIdToTransfer, setTabletIdToTransfer] = React.useState<number>(0);
    const [transferToUnitUniqueId, setTransferToUnitUniqueId] = React.useState<string>('');

    const [pageMessages, setPageMessages] = React.useState<IMessage[]>([]);
    const addMessage = (messageText: string, variant: 'success' | 'error' | 'info' | 'warning') => {
        if (!!messageText && messageText.trim() != '') {
            let msgs = [];
            msgs.push({
                messageText,
                variant
            });

            setPageMessages([...msgs]);
        }
    }

    const showErrorMessage = (message: string) => addMessage(message, 'error');
    const showInfoMessage = (message: string) => addMessage(message, 'info');
    const showSuccessMessage = (message: string) => addMessage(message, 'success');
    const showWarningMessage = (message: string) => addMessage(message, 'warning');



    const onChangeTablet = (option:IAutoComplete) => {
        setPageMessages([]);
        setTabletIdToTransfer(option?.Value ?? 0);
    }

    const onChangeTransferToUnit = (option:IUnitAccess) =>{
        setPageMessages([]);
        setTransferToUnitUniqueId(option?.UnitUniqueId ?? '');
    }

    const validate = ():Promise<boolean> => {
        return new Promise((resolve,reject) => {
            setIsLoading(true);
            if (!tabletIdToTransfer || tabletIdToTransfer <= 0) {
                showErrorMessage('Select Tablet To Transfer.');
                setIsLoading(false);
                resolve(false);
            }else if(!transferToUnitUniqueId || transferToUnitUniqueId.trim() === ''){
                showErrorMessage('Select Unit To Transfer Tablet.');
                setIsLoading(false);
                resolve(false);
            }
            else{
                setIsLoading(false);
                resolve(true);
            }
        });
    }


    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const onSave = () => {
        validate().then(isvalid => {
            if(isvalid){
                tabletApi.transfer(tabletIdToTransfer, transferToUnitUniqueId, loginUser?.UserId ?? 0)
                    .then((addedResult) => {
                        setIsLoading(false);
                        showSuccessMessage('Transfered Successfully')
                        props.onSuccess(tabletIdToTransfer);
                    })
                    .catch(() => {
                        showErrorMessage('Error while adding tablet.');
                        setIsLoading(false);
                    });
            }
        })
    }

    React.useEffect(() => {
        if(props.isOpen === true){
            setTransferToUnitUniqueId('');
            setTabletIdToTransfer(0);
        }
    },[props.isOpen]);

    return <Modal
        open={!!props.unitUniqueId && props.unitUniqueId.trim() !== ''}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={style}>
            <Grid item md={12} style={{ justifyContent: 'center', display: 'flex', flexDirection:'column' }}>
                <Typography style={{ alignSelf: 'center' }} id="modal-modal-title" variant="h6" component="h2">
                    Transfer Tablet
                </Typography>
            </Grid>

            <Container>
                {(isLoading === true || (!!pageMessages && pageMessages.length > 0)) && <Grid container spacing={2} style={{ marginTop: 2 }}>
                {isLoading === true && <Grid item md={12} style={{ justifyContent: 'center', display: 'flex' }}>
                    <CircularProgress />
                </Grid>}
                {!!pageMessages && pageMessages.length > 0 && <Grid item md={12} xs={12}>
                    <MessageComponent onRemove={(i: number) => {
                        let msgs = [...pageMessages];
                        msgs.splice(i, 1);
                        setPageMessages([...msgs]);
                    }} messages={pageMessages} /></Grid>}
                </Grid>}
                <Grid container spacing={2} style={{ marginTop: 2 }}>
                    <Grid item md={12} xs={12}>
                    <CustomAutoComplete<IAutoComplete>
                        Id={'Transfer_Tablet_List'}
                        Value={!!props.unassignTabletsAutoComplete && props.unassignTabletsAutoComplete.length > 0 ? props.unassignTabletsAutoComplete.find(m => m.Value == tabletIdToTransfer) : null}
                        Options={props.unassignTabletsAutoComplete ?? []}
                        displayField={'DisplayText'}
                        valueField={'Value'}
                        Label={'Tablet To Transfer'}
                        onChange={onChangeTablet}
                        size='small'
                        readOnly={isLoading}
                    />
                    </Grid>

                    <Grid item md={12} xs={12}>
                        <TextField
                            fullWidth
                            autoComplete="from_unit"
                            size='small'
                            variant='outlined'
                            type={'text'}
                            label="From Unit"
                            value={props.unitTitle}
                            disabled={true}
                        />
                    </Grid>

                    <Grid item xs={12} style={{ justifyContent: 'center', marginTop: 10, marginBottom: 10, display: 'flex' }}>
                        <Icon style={{ alignSelf: 'center' }} width={30} height={30} icon={'ic:baseline-arrow-circle-down'} />
                    </Grid>

                    <Grid item md={12} xs={12}>
                    <CustomAutoComplete<IUnitAccess>
                        Id={'Transfer_To_Unit_List'}
                        Value={!!props.transferToUnitsAutoComplete && props.transferToUnitsAutoComplete.length > 0 ? props.transferToUnitsAutoComplete.find(m => m.UnitUniqueId === transferToUnitUniqueId) : null}
                        Options={props.transferToUnitsAutoComplete ?? []}
                        displayField={'UnitTitle'}
                        valueField={'UnitUniqueId'}
                        Label={'To Unit'}
                        onChange={onChangeTransferToUnit}
                        size='small'
                        readOnly={isLoading}
                    />
                    </Grid>

                    
                </Grid>

                <Grid item md={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <LoadingButton
                        size="small"
                        type="button"
                        variant="outlined"
                        loading={isLoading}
                        disabled={isLoading}
                        style={{ marginTop: 15, alignSelf: 'center', marginRight: 10, borderRadius: 0 }}
                        onClick={() => {
                            onSave();
                        }}
                    >
                        Transfer
                    </LoadingButton>

                    <LoadingButton
                        size="small"
                        type="button"
                        variant="outlined"
                        loading={false}
                        disabled={isLoading}
                        style={{ marginTop: 15, alignSelf: 'center', color: 'gray', borderRadius: 0, borderColor: 'gray' }}
                        onClick={props.onClose}
                    >
                        Close
                    </LoadingButton>
                </Grid>
            </Container>
        </Box>
    </Modal>;
}