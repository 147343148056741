import {combineReducers} from 'redux';
import {tokenReducer} from './token.reducer';
import {loginUserReducer} from './login-user.reducer';
import { userMenuReducer } from './user-menu.reducer';
import { userRoutesReducer } from './user-routes.reducer';
import {selectedUnitAccessReducer} from './selected-unit-access.reducer';
import { orderNewDataReducer } from './order-new-data.reducer';
import { newOrderReportPrintSettingReducer } from './new-order-report-print-setting.reducer';
import { confirmationReducer } from './confirmation.reducer';
import { orderBookerRouteInfoReducer } from './order-booker-route-info.reducer';
import { isLoadingOrderBookerRouteInfoReducer } from './is-loading-orderbooker-route-info.reducer';
import { isSavingRouteAssignmentReducer } from './is-saving-route-assignment.reducer';
import { selectedAreaIdReducer } from './selected-area-id.reducer';
import { selectedRouteToModifyReducer } from './new-route-setup-on-map/selected-route-to-modify.reducer';
import { newRouteSetupPageMessageReducer } from './new-route-setup-on-map/new-route-setup-page-message.reducer';
import { mapConfirmationPopupReducer } from './new-route-setup-on-map/map-confirmation-pop-up.reducer';
import { isSavingNewRouteReducer } from './new-route-setup-on-map/is-saving-new-route.reducer';
import { selectedRouteIdOnMapReducer } from './new-route-setup-on-map/selected-route-id-on-map.reducer';
import { routePoliesReducer } from './new-route-setup-on-map/route-polies.reducer';
import { isDeletingRouteReducer } from './new-route-setup-on-map/is-deleting-route.reducer';
import { areaPoliesReducer } from './new-route-setup-on-map/area-polies.reducer';
import { existingShopsReducer } from './new-route-setup-on-map/existing-shops.reducer';
import { newShopsReducer } from './new-route-setup-on-map/new-shops.reducer';
import { isLoadingRoutePoliesReducer } from './new-route-setup-on-map/is-loading-route-polies.reducer';
import { isLoadingAreaPoliesReducer } from './new-route-setup-on-map/is-loading-area-polies.reducer';
import { isLoadingAreaExistingShopsReducer } from './new-route-setup-on-map/is-loading-existing-shops.reducer';
import { isLoadingAreaNewShopsReducer } from './new-route-setup-on-map/is-loading-new-shops.reducer';

const rootReducer = combineReducers({
  token: tokenReducer,
  loginUser: loginUserReducer,
  userMenu:userMenuReducer,
  userRoutes:userRoutesReducer,
  selectedUnitAccesses:selectedUnitAccessReducer,
  orderNewData:orderNewDataReducer,
  newOrderReportPrintSetting:newOrderReportPrintSettingReducer,
  confirmation:confirmationReducer,
  orderBookerRouteInfo:orderBookerRouteInfoReducer,
  isLoadingOrderBookerRouteInfo:isLoadingOrderBookerRouteInfoReducer,
  isSavingRouteAssignment:isSavingRouteAssignmentReducer,
  selectedAreaId:selectedAreaIdReducer,
  selectedRouteToModify:selectedRouteToModifyReducer,
  newRouteSetupPageMessage:newRouteSetupPageMessageReducer,
  mapConfirmationPopup:mapConfirmationPopupReducer,
  isSavingNewRoute:isSavingNewRouteReducer,
  selectedRouteIdOnMap:selectedRouteIdOnMapReducer,
  routePolies:routePoliesReducer,
  isDeletingRoute:isDeletingRouteReducer,
  areaPolies:areaPoliesReducer,
  existingShops:existingShopsReducer,
  newShops:newShopsReducer,
  isLoadingRoutePolies:isLoadingRoutePoliesReducer,
  isLoadingAreaPolies:isLoadingAreaPoliesReducer,
  isLoadingAreaExistingShops:isLoadingAreaExistingShopsReducer,
  isLoadingAreaNewShops:isLoadingAreaNewShopsReducer

});

export default rootReducer;
