import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

interface IProps{
  label:string;
  value:Date;
  inputDisable?:boolean;
  readonly?:boolean;
  onChange?:(v:Date) => void;
  size?:'small' | 'medium';
  marginTop?:number;
  marginLeft?:number;
  minDate?:Date;
  maxDate?:Date;
}
export default function CustomDatePicker(props:IProps) {

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={props.label}
        value={props.value}
        readOnly={props.readonly == true}
        onChange={(newValue) => {
          if(props.onChange){
            props.onChange(newValue);
          }
        }}
        minDate={props.minDate}
        maxDate={props.maxDate}
        InputProps={
          {
            onKeyDown:(e:any) => { if(props.inputDisable == true){e.preventDefault()}}
          }
        }
        renderInput={(params) => <TextField size={!!props.size ? props.size : 'medium'} style={{width:'100%', marginTop:!!props.marginTop ? props.marginTop : undefined, marginLeft:!!props.marginLeft ? props.marginLeft : undefined, minWidth:150}} {...params} />}
      />
    </LocalizationProvider>
  );
}
