import React from 'react';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import refreshFill from '@iconify/icons-eva/refresh-fill';
// material
import {
    Card,
    Table,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    Grid,
    Checkbox,
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { UserListHead } from '../components/_dashboard/user';
//
import { LoadingButton } from '@mui/lab';
import { doCashApi } from '../http';
import { defaultDateTimeTwelveHourFormat, formattedDate, getCommaSeparatedIds, getCommaSeparatedString, getCommaSeparatedUnitUniqueIdsExceptAll, getDateOnly } from 'src/utility';

import { useSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { IApplicationState } from 'src/redux';
import { Audio as AudoSpin } from 'react-loader-spinner';
import { confirmAction } from 'src/utility/confirm.util';
import { IMessage } from 'src/interfaces/message.interface';
import { MessageComponent } from 'src/components/messages.component';
import { IUserDetail } from 'src/interfaces';
import { ISelectedUnitAccess } from 'src/interfaces/selected-unit-access.interface';
import { AllOptionUniqueId, isRestrictedLogin, NumberOfPreviousMonth } from 'src/app.constant';
import { fCurrency } from 'src/utils/formatNumber';
import { NoUnitSelectedComponent } from 'src/components/common/no-unit-selected.component';
import { ICollectedCashDateWise } from 'src/interfaces/collected-cash-date-wise.interface';
import { ICashSubmissionHistory } from 'src/interfaces/cash-submission-history.interface';
import CustomDatePicker from 'src/components/common/CustomDatePicker';
import { ICashDetail } from 'src/interfaces/cash-detail.interface';
import Label from 'src/components/Label';
import { IDepositHistory } from 'src/interfaces/deposit-history.interface';


// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'sr', label: 'Sr.', alignContent: 'center' },
    { id: 'dated', label: 'Date', alignContent: 'center' },
    { id: 'do_amount', label: 'DO Amount', alignContent: 'center' },
    { id: 'credit_amount', label: 'Credit Amount', alignContent: 'center' },
    { id: 'total_amount', label: 'Total Amount', alignContent: 'center' },
];

const TABLE_HEAD_PENDING_DEPOSITS = [
    { id: 'sr', label: 'Sr.', alignContent: 'center' },
    { id: 'dated', label: 'Date', alignContent: 'center' },
    { id: 'do_amount', label: 'DO Amount', alignContent: 'center' },
    { id: 'credit_amount', label: 'Credit Amount', alignContent: 'center' },
    { id: 'total_amount', label: 'Total Amount', alignContent: 'center' },
    { id: 'status', label: 'Request Status', alignContent: 'center' },
];

const TABLE_HEAD_DEPOSIT_HISTORY = [
    { id: 'sr', label: 'Sr.', alignContent: 'center' },
    { id: 'dated', label: 'Deposit Date', alignContent: 'center' },
    { id: 'deposited_by', label: 'Deposited By', alignContent: 'center' },
    { id: 'do_amount', label: 'DO Amount', alignContent: 'center' },
    { id: 'credit_amount', label: 'Credit Amount', alignContent: 'center' },
    { id: 'total_amount', label: 'Total Amount', alignContent: 'center' },
];

const TABLE_HEAD_DEPOSIT_REQUEST_HISTORY = [
    { id: 'sr', label: 'Sr.', alignContent: 'center' },
    { id: 'dated', label: 'Requested On', alignContent: 'center' },
    { id: 'deposited_date', label: 'Deposited On', alignContent: 'center' },
    { id: 'received_on', label: 'Received On', alignContent: 'center' },
    { id: 'deposited_by', label: 'Deposited By', alignContent: 'center' },
    { id: 'do_amount', label: 'DO Amount', alignContent: 'center' },
    { id: 'credit_amount', label: 'Credit Amount', alignContent: 'center' },
    { id: 'total_amount', label: 'Total Amount', alignContent: 'center' },
];

// ----------------------------------------------------------------------

interface ITotal {
    TotalCashAmount: number;
    TotalDoCashAmount: number;
    TotalCreditCashAmount: number;
    UnitUniqueId: string;
}

interface IViewPort {
    ShowCashInHandScreen: boolean;
    ShowPendingDepositsScreen: boolean;
    ShowDepositsScreen: boolean;
    ShowHistoryScreen: boolean;
    ShowRequestHistoryScreen: boolean;
}

interface IProps {
    loginUser: IUserDetail | null;
    selectedUnits: ISelectedUnitAccess;
}

interface IDateWiseDepositHistory {
    Dated: Date;
    UnitUniqueId: string;
    TotalDoCashAmount: number;
    TotalCreditCashAmount: number;
    TotalCashAmount: number;
    DepositHistory: IDepositHistory[];
}

const CollectedCashDetailNew = (props: IProps) => {

    const initialCashDetail = {
        CoinOne: 0,
        CoinTwo: 0,
        CoinFive: 0,
        NoteTen: 0,
        NoteTwenty: 0,
        NoteFifty: 0,
        NoteHundred: 0,
        NoteFiveHundred: 0,
        NoteThousand: 0,
        NoteFiveThousand: 0,
        DOCashId: 0,
        Id: 0,
        RecordedBy: props.loginUser.UserId,
        RecordedOn: new Date(),
        TotalCashAmount: 0
    };

    const initialViewPort: IViewPort = {
        ShowPendingDepositsScreen: false,
        ShowCashInHandScreen: true,
        ShowDepositsScreen: false,
        ShowHistoryScreen: false,
        ShowRequestHistoryScreen: false
    };

    const initialViewPortAllFalse: IViewPort = {
        ShowPendingDepositsScreen: false,
        ShowCashInHandScreen: false,
        ShowDepositsScreen: false,
        ShowHistoryScreen: false,
        ShowRequestHistoryScreen: false,
    };

    //#region state variables
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [cashInHand, setCashInHand] = React.useState<ICollectedCashDateWise[]>([]);
    const [pendingDeposits, setPendingDeposits] = React.useState<ICollectedCashDateWise[]>([]);
    const [deposits, setDeposits] = React.useState<ICollectedCashDateWise[]>([]);

    const [cashInHandUnitWiseTotals, setCashInHandUnitWiseTotals] = React.useState<ITotal[]>([]);
    const [pendingDepositsUnitWiseTotals, setPendingDepositsUnitWiseTotals] = React.useState<ITotal[]>([]);
    const [depositsUnitWiseTotals, setDepositsUnitWiseTotals] = React.useState<ITotal[]>([]);

    const [selectedCashInHandUnitWiseTotals, setSelectedCashInHandUnitWiseTotals] = React.useState<ITotal[]>([]);
    const [selectedPendingDepositsUnitWiseTotals, setSelectedPendingDepositsUnitWiseTotals] = React.useState<ITotal[]>([]);
    const [selectedDepositsUnitWiseTotals, setSelectedDepositsUnitWiseTotals] = React.useState<ITotal[]>([]);

    const [cashInHandTotal, setCashInHandTotal] = React.useState<ITotal>({
        TotalCashAmount: 0,
        TotalCreditCashAmount: 0,
        TotalDoCashAmount: 0,
        UnitUniqueId: ''
    });
    const [pendingDepositsTotal, setPendingDepositsTotal] = React.useState<ITotal>({
        TotalCashAmount: 0,
        TotalCreditCashAmount: 0,
        TotalDoCashAmount: 0,
        UnitUniqueId: ''
    });
    const [depositsTotal, setDepositsTotal] = React.useState<ITotal>({
        TotalCashAmount: 0,
        TotalCreditCashAmount: 0,
        TotalDoCashAmount: 0,
        UnitUniqueId: ''
    });

    const [depositHistory, setDepositHistory] = React.useState<IDepositHistory[]>([]);
    const [depositHistoryUnitWiseTotals, setDepositHistoryUnitWiseTotals] = React.useState<ITotal[]>([]);
    const [depositHistoryTotal, setDepositHistoryTotal] = React.useState<ITotal>({
        TotalCashAmount: 0,
        TotalCreditCashAmount: 0,
        TotalDoCashAmount: 0,
        UnitUniqueId: ''
    });

    const [requestHistoryDateWise, setRequestHistoryDateWise] = React.useState<IDateWiseDepositHistory[]>([]);

    const [requestHistory, setRequestHistory] = React.useState<IDepositHistory[]>([]);
    const [requestHistoryUnitWiseTotals, setRequestHistoryUnitWiseTotals] = React.useState<ITotal[]>([]);
    const [requestHistoryTotal, setRequestHistoryTotal] = React.useState<ITotal>({
        TotalCashAmount: 0,
        TotalCreditCashAmount: 0,
        TotalDoCashAmount: 0,
        UnitUniqueId: ''
    });

    const [depositHistoryDateWise, setDepositHistoryDateWise] = React.useState<IDateWiseDepositHistory[]>([]);

    const [pageMessages, setPageMessages] = React.useState<IMessage[]>([]);
    const [viewPort, setViewPort] = React.useState<IViewPort>(initialViewPort);
    const [fromDate, setFromDate] = React.useState<Date>(new Date());
    const [toDate, setToDate] = React.useState<Date>(new Date());
    const [isHistoryLoadedAfterLastChange, setIsHistoryLoadedAfterLastChange] = React.useState<boolean>(false);
    //#endregion

    //#region Util Methods
    const showErrorMessage = (message: string) => addMessage(message, 'error');
    const showInfoMessage = (message: string) => addMessage(message, 'info');
    const showSuccessMessage = (message: string) => addMessage(message, 'success');
    const showWarningMessage = (message: string) => addMessage(message, 'warning');

    const addMessage = (messageText: string, variant: 'success' | 'error' | 'info' | 'warning') => {
        if (!!messageText && messageText.trim() != '') {
            let msgs = [];
            msgs.push({
                messageText,
                variant
            });

            setPageMessages([...msgs]);
        }
    }

    const getCopy = (details: ICollectedCashDateWise[]) => {
        let detail = [...details.map(d => {
            d.DoCashIds = !!d.DoCashIds && d.DoCashIds.length > 0 ? ([...d.DoCashIds.map(c => c)]) : [];
            d.CreditReceiveIds = !!d.CreditReceiveIds && d.CreditReceiveIds.length > 0 ? [...d.CreditReceiveIds.map(cr => cr)] : [];
            return d;
        })];

        return detail;
    }

    const setTotalsOnLoad = (cashDetail: ICashDetail) => {
        if (!!cashDetail) {

            let calculatedCashInHandTotal: ITotal = {
                TotalCashAmount: 0,
                TotalCreditCashAmount: 0,
                TotalDoCashAmount: 0,
                UnitUniqueId: ''
            };

            let calculatedCashInHandUnitWiseTotals: ITotal[] = [];

            if (!!cashDetail.CashInHand && cashDetail.CashInHand.length > 0) {

                cashDetail.CashInHand.forEach(cih => {

                    calculatedCashInHandTotal.TotalDoCashAmount += cih.CollectedDoCashAmount;
                    calculatedCashInHandTotal.TotalCreditCashAmount += cih.CollectedCreditCashAmount;
                    calculatedCashInHandTotal.TotalCashAmount += (cih.CollectedDoCashAmount + cih.CollectedCreditCashAmount)

                    let cashInHandTotal: ITotal = calculatedCashInHandUnitWiseTotals.find(m => m.UnitUniqueId == cih.UnitUniqueId);

                    if (!!cashInHandTotal) {
                        cashInHandTotal.TotalDoCashAmount += cih.CollectedDoCashAmount;
                        cashInHandTotal.TotalCreditCashAmount += cih.CollectedCreditCashAmount;
                        cashInHandTotal.TotalCashAmount += (cih.CollectedDoCashAmount + cih.CollectedCreditCashAmount)
                    } else {
                        calculatedCashInHandUnitWiseTotals.push({
                            TotalDoCashAmount: cih.CollectedDoCashAmount,
                            TotalCreditCashAmount: cih.CollectedCreditCashAmount,
                            TotalCashAmount: cih.CollectedDoCashAmount + cih.CollectedCreditCashAmount,
                            UnitUniqueId: cih.UnitUniqueId
                        });
                    }
                });
            }

            setCashInHandUnitWiseTotals([...calculatedCashInHandUnitWiseTotals.map(u => u)]);
            setCashInHandTotal({ ...calculatedCashInHandTotal })

            let calculatedDepositsTotal = {
                TotalCashAmount: 0,
                TotalCreditCashAmount: 0,
                TotalDoCashAmount: 0,
                UnitUniqueId: ''
            };

            let calculatedDepositsUnitWiseTotals: ITotal[] = [];

            if (!!cashDetail.Deposits && cashDetail.Deposits.length > 0) {

                cashDetail.Deposits.forEach(d => {

                    calculatedDepositsTotal.TotalDoCashAmount += d.CollectedDoCashAmount;
                    calculatedDepositsTotal.TotalCreditCashAmount += d.CollectedCreditCashAmount;
                    calculatedDepositsTotal.TotalCashAmount += (d.CollectedDoCashAmount + d.CollectedCreditCashAmount)

                    let deposit = calculatedDepositsUnitWiseTotals.find(ud => ud.UnitUniqueId == d.UnitUniqueId);

                    if (!!deposit) {
                        deposit.TotalDoCashAmount += d.CollectedDoCashAmount;
                        deposit.TotalCreditCashAmount += d.CollectedCreditCashAmount;
                        deposit.TotalCashAmount += (d.CollectedDoCashAmount + d.CollectedCreditCashAmount)
                    } else {
                        calculatedDepositsUnitWiseTotals.push({
                            TotalDoCashAmount: d.CollectedDoCashAmount,
                            TotalCreditCashAmount: d.CollectedCreditCashAmount,
                            TotalCashAmount: d.CollectedDoCashAmount + d.CollectedCreditCashAmount,
                            UnitUniqueId: d.UnitUniqueId
                        });
                    }
                });
            }

            setDepositsUnitWiseTotals([...calculatedDepositsUnitWiseTotals.map(d => d)]);
            setDepositsTotal({ ...calculatedDepositsTotal });

            let calculatedPendingDepositsTotal: ITotal = {
                TotalCashAmount: 0,
                TotalCreditCashAmount: 0,
                TotalDoCashAmount: 0,
                UnitUniqueId: ''
            };

            let calculatedPendingDepositUnitWiseTotals: ITotal[] = [];

            if (!!cashDetail.PendingDeposits && cashDetail.PendingDeposits.length > 0) {

                cashDetail.PendingDeposits.forEach(pd => {

                    calculatedPendingDepositsTotal.TotalDoCashAmount += pd.CollectedDoCashAmount;
                    calculatedPendingDepositsTotal.TotalCreditCashAmount += pd.CollectedCreditCashAmount;
                    calculatedPendingDepositsTotal.TotalCashAmount += (pd.CollectedDoCashAmount + pd.CollectedCreditCashAmount)

                    let pendingDepositTotal = calculatedPendingDepositUnitWiseTotals.find(pdt => pdt.UnitUniqueId == pd.UnitUniqueId);

                    if (!!pendingDepositTotal) {
                        pendingDepositTotal.TotalDoCashAmount += pd.CollectedDoCashAmount;
                        pendingDepositTotal.TotalCreditCashAmount += pd.CollectedCreditCashAmount;
                        pendingDepositTotal.TotalCashAmount += (pd.CollectedDoCashAmount + pd.CollectedCreditCashAmount)
                    } else {
                        calculatedPendingDepositUnitWiseTotals.push({
                            TotalDoCashAmount: pd.CollectedDoCashAmount,
                            TotalCreditCashAmount: pd.CollectedCreditCashAmount,
                            TotalCashAmount: pd.CollectedDoCashAmount + pd.CollectedCreditCashAmount,
                            UnitUniqueId: pd.UnitUniqueId
                        });
                    }
                });
            }

            setPendingDepositsUnitWiseTotals([...calculatedPendingDepositUnitWiseTotals.map(pd => pd)]);
            setPendingDepositsTotal({ ...calculatedPendingDepositsTotal });
        } else {
            setCashInHandUnitWiseTotals([]);
            setPendingDepositsUnitWiseTotals([]);
            setDepositsUnitWiseTotals([]);

            setCashInHandTotal({
                TotalCashAmount: 0,
                TotalCreditCashAmount: 0,
                TotalDoCashAmount: 0,
                UnitUniqueId: ''
            });
            setPendingDepositsTotal({
                TotalCashAmount: 0,
                TotalCreditCashAmount: 0,
                TotalDoCashAmount: 0,
                UnitUniqueId: ''
            });

            setDepositsTotal({
                TotalCashAmount: 0,
                TotalCreditCashAmount: 0,
                TotalDoCashAmount: 0,
                UnitUniqueId: ''
            });
        }
    }

    const setSelectedTotalsOnLoad = (cashDetail: ICashDetail) => {
        if (!!cashDetail) {
            calculateSelectedCashInHandUnitWiseTotal(cashDetail.CashInHand);
            calculateSelectedPendingDepositsUnitWiseTotal(cashDetail.PendingDeposits);
            calculateSelectedDepositsUnitWiseTotal(cashDetail.Deposits);
        } else {
            setSelectedCashInHandUnitWiseTotals([]);
            setSelectedPendingDepositsUnitWiseTotals([]);
            setSelectedDepositsUnitWiseTotals([]);
        }
    }

    const calculateSelectedCashInHandUnitWiseTotal = (detail: ICollectedCashDateWise[]) => {
        setSelectedCashInHandUnitWiseTotals([...calculateSelectedUnitWiseTotal(detail).map(u => u)]);
    }

    const calculateSelectedPendingDepositsUnitWiseTotal = (detail: ICollectedCashDateWise[]) => {
        setSelectedPendingDepositsUnitWiseTotals([...calculateSelectedUnitWiseTotal(detail).map(u => u)]);
    }

    const calculateSelectedDepositsUnitWiseTotal = (detail: ICollectedCashDateWise[]) => {
        setSelectedDepositsUnitWiseTotals([...calculateSelectedUnitWiseTotal(detail).map(u => u)]);
    }

    const calculateSelectedUnitWiseTotal = (detail: ICollectedCashDateWise[]) => {
        let calculatedDepositsTotals: ITotal[] = [];
        if (!!detail && detail.length > 0) {
            detail.filter(m => m.IsChecked == true).forEach(cih => {
                let cashInHandTotal: ITotal = calculatedDepositsTotals.find(m => m.UnitUniqueId == cih.UnitUniqueId);

                if (!!cashInHandTotal) {
                    cashInHandTotal.TotalDoCashAmount += cih.CollectedDoCashAmount;
                    cashInHandTotal.TotalCreditCashAmount += cih.CollectedCreditCashAmount;
                    cashInHandTotal.TotalCashAmount += (cih.CollectedDoCashAmount + cih.CollectedCreditCashAmount)
                } else {
                    calculatedDepositsTotals.push({
                        TotalDoCashAmount: cih.CollectedDoCashAmount,
                        TotalCreditCashAmount: cih.CollectedCreditCashAmount,
                        TotalCashAmount: cih.CollectedDoCashAmount + cih.CollectedCreditCashAmount,
                        UnitUniqueId: cih.UnitUniqueId
                    });
                }
            });
        }

        return [...calculatedDepositsTotals.map(u => u)];
    }

    const resetDepositHistoryVariables = () => {
        setDepositHistory([]);
        setDepositHistoryUnitWiseTotals([]);
        setDepositHistoryDateWise([]);
        setDepositHistoryTotal({
            TotalCashAmount: 0,
            TotalCreditCashAmount: 0,
            TotalDoCashAmount: 0,
            UnitUniqueId: ''
        });
    }

    const resetRequestHistoryVariables = () => {
        setRequestHistory([]);
        setRequestHistoryUnitWiseTotals([]);
        setRequestHistoryDateWise([]);
        setRequestHistoryTotal({
            TotalCashAmount: 0,
            TotalCreditCashAmount: 0,
            TotalDoCashAmount: 0,
            UnitUniqueId: ''
        });
    }

    //#endregion

    //#region handlers
    const resetEverythingAndGetData = () => {
        resetEverything();
        getCollectedCashDetail();
    }

    const resetEverything = () => {
        resetDepositHistoryVariables();
        resetRequestHistoryVariables();
        setCashInHand([]);
        setPendingDeposits([]);
        setDeposits([]);
        setCashInHandUnitWiseTotals([]);
        setPendingDepositsUnitWiseTotals([]);
        setDepositsUnitWiseTotals([]);
        setSelectedCashInHandUnitWiseTotals([]);
        setSelectedPendingDepositsUnitWiseTotals([]);
        setSelectedDepositsUnitWiseTotals([]);
        setCashInHandTotal({
            TotalCashAmount: 0,
            TotalCreditCashAmount: 0,
            TotalDoCashAmount: 0,
            UnitUniqueId: ''
        });

        setPendingDepositsTotal({
            TotalCashAmount: 0,
            TotalCreditCashAmount: 0,
            TotalDoCashAmount: 0,
            UnitUniqueId: ''
        });

        setDepositsTotal({
            TotalCashAmount: 0,
            TotalCreditCashAmount: 0,
            TotalDoCashAmount: 0,
            UnitUniqueId: ''
        });
    }

    const onRefresh = () => {
        setPageMessages([]);
        resetEverythingAndGetData();
    }

    const handleCheckboxChangeCashInHand = (unitUniqueId: string, dated: Date) => {
        setPageMessages([]);
        const cashDetailCopy = getCopy(cashInHand);
        const item = cashDetailCopy.find(m => m.UnitUniqueId == unitUniqueId && m.Dated == dated);
        item.IsChecked = !item.IsChecked;
        calculateSelectedCashInHandUnitWiseTotal([...cashDetailCopy]);
        setCashInHand([...cashDetailCopy]);
    }

    const handleCashInHandSelectAllClick = (unitUniqueId: string) => {
        setPageMessages([]);
        if (!!cashInHand && cashInHand.length > 0) {
            let shouldSelectAll = false;
            if ((cashInHand.filter(u => u.UnitUniqueId == unitUniqueId).length != cashInHand.filter(u => u.UnitUniqueId == unitUniqueId && u.IsChecked == true).length)) {
                shouldSelectAll = true;
            }

            const cashDetailCopy = getCopy(cashInHand);
            cashDetailCopy.filter(u => u.UnitUniqueId == unitUniqueId).forEach(cd => {
                cd.IsChecked = shouldSelectAll;
            });

            calculateSelectedCashInHandUnitWiseTotal([...cashDetailCopy]);
            setCashInHand([...cashDetailCopy]);
        }
    }

    const handleCheckboxChangeDeposits = (unitUniqueId: string, dated: Date) => {
        setPageMessages([]);
        const cashDetailCopy = getCopy(deposits);
        const item = cashDetailCopy.find(m => m.UnitUniqueId == unitUniqueId && m.Dated == dated);
        item.IsChecked = !item.IsChecked;
        calculateSelectedDepositsUnitWiseTotal([...cashDetailCopy]);
        setDeposits([...cashDetailCopy]);
    }

    const handleDepositsSelectAllClick = (unitUniqueId: string) => {
        setPageMessages([]);
        if (!!deposits && deposits.length > 0) {
            let shouldSelectAll = false;
            if ((deposits.filter(u => u.UnitUniqueId == unitUniqueId).length != deposits.filter(u => u.UnitUniqueId == unitUniqueId && u.IsChecked == true).length)) {
                shouldSelectAll = true;
            }

            const cashDetailCopy = getCopy(deposits);
            cashDetailCopy.filter(u => u.UnitUniqueId == unitUniqueId).forEach(cd => {
                cd.IsChecked = shouldSelectAll;
            });

            calculateSelectedDepositsUnitWiseTotal([...cashDetailCopy]);
            setDeposits([...cashDetailCopy]);
        }
    }

    const handleCreateCashRequest = (unitUniqueId: string) => {
        setPageMessages([]);
        let unitTitle = props.selectedUnits.UnitAccesses.find(u => u.UnitUniqueId == unitUniqueId)?.UnitTitle ?? '';
        let sTotal = selectedCashInHandUnitWiseTotals?.find(m => m.UnitUniqueId == unitUniqueId);

        if (!!cashInHand && cashInHand.length > 0 && cashInHand.filter(m => m.UnitUniqueId == unitUniqueId && m.IsChecked == true).length > 0) {
            let cashDetailForUnit = cashInHand.filter(m => m.UnitUniqueId == unitUniqueId && m.IsChecked);
            let doCashIds: number[] = [];
            let creditReceiveIds: number[] = [];

            cashDetailForUnit.forEach(cdu => {
                if (!!cdu.DoCashIds && cdu.DoCashIds.length > 0) {
                    cdu.DoCashIds.forEach(dci => {
                        if (doCashIds.findIndex(dc => dc == dci) < 0) {
                            doCashIds.push(dci);
                        }
                    });
                }

                if (!!cdu.CreditReceiveIds && cdu.CreditReceiveIds.length > 0) {
                    cdu.CreditReceiveIds.forEach(cri => {
                        if (creditReceiveIds.findIndex(i => i == cri) < 0) {
                            creditReceiveIds.push(cri);
                        }
                    });
                }
            });

            if (doCashIds.length > 0 || creditReceiveIds.length > 0) {

                confirmAction('Cash Request', `Are you sure you want to create Cash Request of Amount ${fCurrency(sTotal?.TotalCashAmount ?? 0)} For ${unitTitle}?`, 'Yes', 'No')
                    .then(crn => {
                        if (crn == true) {
                            let commaSeparatedDoCashIds = getCommaSeparatedIds(doCashIds);
                            let commaSeparatedCreditReceiveIds = getCommaSeparatedIds(creditReceiveIds);

                            setIsLoading(true);
                            doCashApi.markCashAsRequested(props.loginUser.UserId ?? 0, unitUniqueId, commaSeparatedDoCashIds, commaSeparatedCreditReceiveIds)
                                .then(res => {
                                    showSuccessMessage(`Cash Request Created Successfully For ${unitTitle}`);
                                    setIsLoading(false);
                                    resetEverythingAndGetData();
                                })
                                .catch(e => {
                                    setIsLoading(false);
                                    showErrorMessage(`Error while creating cash request for ${unitTitle}`);
                                });
                        }
                    });
            } else {
                showErrorMessage(`Please Select Date To Create Cash Request For ${unitTitle}`);
            }

        } else {
            showErrorMessage(`Please Select Date To Create Cash Request For ${unitTitle}`);
        }
    }

    const handleConfirmDeposit = (unitUniqueId: string) => {
        setPageMessages([]);
        let unitTitle = props.selectedUnits.UnitAccesses.find(u => u.UnitUniqueId == unitUniqueId)?.UnitTitle ?? '';
        let sTotal = selectedDepositsUnitWiseTotals?.find(m => m.UnitUniqueId == unitUniqueId);

        if (!!deposits && deposits.length > 0 && deposits.filter(m => m.UnitUniqueId == unitUniqueId && m.IsChecked == true).length > 0) {
            let cashDetailForUnit = deposits.filter(m => m.UnitUniqueId == unitUniqueId && m.IsChecked);
            let doCashIds: number[] = [];
            let creditReceiveIds: number[] = [];

            cashDetailForUnit.forEach(cdu => {
                if (!!cdu.DoCashIds && cdu.DoCashIds.length > 0) {
                    cdu.DoCashIds.forEach(dci => {
                        if (doCashIds.findIndex(dc => dc == dci) < 0) {
                            doCashIds.push(dci);
                        }
                    });
                }

                if (!!cdu.CreditReceiveIds && cdu.CreditReceiveIds.length > 0) {
                    cdu.CreditReceiveIds.forEach(cri => {
                        if (creditReceiveIds.findIndex(i => i == cri) < 0) {
                            creditReceiveIds.push(cri);
                        }
                    });
                }
            });

            if (doCashIds.length > 0 || creditReceiveIds.length > 0) {

                confirmAction('Deposit Confirmation', `Are you sure you received Amount ${fCurrency(sTotal?.TotalCashAmount ?? 0)} For ${unitTitle}?`, 'Yes', 'No')
                    .then(crn => {
                        if (crn == true) {
                            let commaSeparatedDoCashIds = getCommaSeparatedIds(doCashIds);
                            let commaSeparatedCreditReceiveIds = getCommaSeparatedIds(creditReceiveIds);

                            setIsLoading(true);
                            doCashApi.markRequestedCashAsSubmitted(props.loginUser.UserId ?? 0, unitUniqueId, commaSeparatedDoCashIds, commaSeparatedCreditReceiveIds)
                                .then(res => {
                                    showSuccessMessage(`Cash Receive Confirmed Successfully For ${unitTitle}`);
                                    setIsLoading(false);
                                    resetEverythingAndGetData();
                                })
                                .catch(e => {
                                    setIsLoading(false);
                                    showErrorMessage(`Something went wrong for ${unitTitle}`);
                                });
                        }
                    });
            } else {
                showErrorMessage(`Please Select Date To Mark As Confirmed For ${unitTitle}`);
            }

        } else {
            showErrorMessage(`Please Select Date To Mark As Confirmed For ${unitTitle}`);
        }
    }

    const onViewCashInHandScreen = () => {
        setPageMessages([]);
        setViewPort({ ...initialViewPortAllFalse, ShowCashInHandScreen: true });
    }

    const onViewPendingDepositsScreen = () => {
        setPageMessages([]);
        setViewPort({ ...initialViewPortAllFalse, ShowPendingDepositsScreen: true });
    }

    const onViewDepositsScreen = () => {
        setPageMessages([]);
        setViewPort({ ...initialViewPortAllFalse, ShowDepositsScreen: true });
    }

    const onViewCashSubmissionHistoryScreen = () => {
        setIsHistoryLoadedAfterLastChange(false);
        resetRequestHistoryVariables();
        resetDepositHistoryVariables();
        setPageMessages([]);
        setViewPort({ ...initialViewPortAllFalse, ShowHistoryScreen: true });
    }

    const onViewRequestHistoryScreen = () => {
        setIsHistoryLoadedAfterLastChange(false);
        resetRequestHistoryVariables();
        resetDepositHistoryVariables();
        setPageMessages([]);
        setViewPort({ ...initialViewPortAllFalse, ShowRequestHistoryScreen: true });
    }

    //#endregion

    //#region Apis Call

    const getCollectedCashDetail = () => {
        setIsLoading(true);
        doCashApi.getCashDetailsForRequestAndConfirmationDateWise(props.loginUser.UserId, getCommaSeparatedUnitUniqueIdsExceptAll([...props.selectedUnits.UnitAccesses.map(a => a)]))
            .then(results => {
                setIsLoading(false);
                if (!!results) {
                    if (!!results.CashInHand && results.CashInHand.length > 0) {
                        results.CashInHand.forEach(r => {
                            r.IsChecked = true;
                        });

                        setCashInHand(getCopy(results.CashInHand));
                    } else {
                        setCashInHand([]);
                    }

                    if (!!results.PendingDeposits && results.PendingDeposits.length > 0) {
                        results.PendingDeposits.forEach(r => {
                            r.IsChecked = true;
                        });

                        setPendingDeposits(getCopy(results.PendingDeposits));
                    } else {
                        setPendingDeposits([]);
                    }

                    if (!!results.Deposits && results.Deposits.length > 0) {
                        // results.Deposits.forEach(r => {
                        //     r.IsChecked = true;
                        // });

                        setDeposits(getCopy(results.Deposits));
                    } else {
                        setDeposits([]);
                    }
                }
                else {
                    setCashInHand([]);
                    setPendingDeposits([]);
                    setDeposits([]);
                }

                setTotalsOnLoad(results);
                setSelectedTotalsOnLoad(results);
            })
            .catch(e => {
                console.log(e);
                setIsLoading(false);
            });
    }

    const getDepositHistory = () => {

        if (fromDate === undefined || fromDate == null) {
            showErrorMessage('Please Select From Date');
            return;
        }

        if (toDate === undefined || toDate == null) {
            showErrorMessage('Please Select To Date');
            return;
        }

        if (getDateOnly(fromDate) > getDateOnly(toDate)) {
            showErrorMessage(`From Date Can't be Greater Than To Date`);
            return;
        }

        setIsLoading(true);
        resetDepositHistoryVariables();
        setIsHistoryLoadedAfterLastChange(true);
        doCashApi.getDepositHistory(props.loginUser.UserId, getCommaSeparatedUnitUniqueIdsExceptAll([...props.selectedUnits.UnitAccesses.map(a => a)]), fromDate, toDate)
            .then(results => {
                setIsLoading(false);
                setDateWiseDepositHistory(results);
                if (!!results && results.length > 0) {
                    setDepositHistory([...results]);
                    setTotalsForDepositHistory(results);
                }
                else {
                    resetDepositHistoryVariables();
                }
            })
            .catch(e => {
                console.log(e);
                setIsLoading(false);
            });
    }

    const getRequestHistory = () => {

        if (fromDate === undefined || fromDate == null) {
            showErrorMessage('Please Select From Date');
            return;
        }

        if (toDate === undefined || toDate == null) {
            showErrorMessage('Please Select To Date');
            return;
        }

        if (getDateOnly(fromDate) > getDateOnly(toDate)) {
            showErrorMessage(`From Date Can't be Greater Than To Date`);
            return;
        }

        setIsLoading(true);
        resetRequestHistoryVariables();
        setIsHistoryLoadedAfterLastChange(true);
        doCashApi.getRequestHistory(props.loginUser.UserId, getCommaSeparatedUnitUniqueIdsExceptAll([...props.selectedUnits.UnitAccesses.map(a => a)]), fromDate, toDate)
            .then(results => {
                setIsLoading(false);
                setDateWiseRequestHistory(results);
                if (!!results && results.length > 0) {
                    setRequestHistory([...results]);
                    setTotalsForRequestHistory(results);
                }
                else {
                    resetRequestHistoryVariables();
                }
            })
            .catch(e => {
                console.log(e);
                setIsLoading(false);
            });
    }

    const setTotalsForDepositHistory = (cashHistory: IDepositHistory[]) => {
        if (!!props.selectedUnits.UnitAccesses && props.selectedUnits.UnitAccesses.length > 0 && props.selectedUnits.UnitAccesses.filter(u => u.UnitUniqueId != AllOptionUniqueId).length > 0 && !!cashHistory && cashHistory.length > 0) {
            let calculatedDepositHistoryUnitWiseTotals: ITotal[] = [];
            let calculatedDepositTotal: ITotal = {
                TotalCashAmount: 0,
                TotalCreditCashAmount: 0,
                TotalDoCashAmount: 0,
                UnitUniqueId: ''
            };
            props.selectedUnits.UnitAccesses.filter(ua => ua.UnitUniqueId != AllOptionUniqueId).forEach(uaf => {
                let uTotal: ITotal = {
                    TotalCashAmount: 0,
                    TotalCreditCashAmount: 0,
                    TotalDoCashAmount: 0,
                    UnitUniqueId: uaf.UnitUniqueId
                };

                cashHistory.filter(ch => ch.UnitUniqueId == uaf.UnitUniqueId).forEach(chu => {
                    uTotal.TotalDoCashAmount += chu.DoCashAmount;
                    uTotal.TotalCreditCashAmount += chu.CreditCashAmount;
                    uTotal.TotalCashAmount += chu.TotalCashAmount;

                    calculatedDepositTotal.TotalDoCashAmount += chu.DoCashAmount;
                    calculatedDepositTotal.TotalCreditCashAmount += chu.CreditCashAmount;
                    calculatedDepositTotal.TotalCashAmount += chu.TotalCashAmount;
                });

                calculatedDepositHistoryUnitWiseTotals.push({ ...uTotal });
            });

            setDepositHistoryUnitWiseTotals([...calculatedDepositHistoryUnitWiseTotals]);
            setDepositHistoryTotal({ ...calculatedDepositTotal })
        }
    }

    const setDateWiseDepositHistory = (cashHistory: IDepositHistory[]) => {
        let calculatedDateWiseDepositHistory: IDateWiseDepositHistory[] = [];
        if (!!cashHistory && cashHistory.length > 0) {
            cashHistory.forEach(chu => {

                let dated = formattedDate(chu.Dated);
                if (calculatedDateWiseDepositHistory.findIndex(m => m.UnitUniqueId == chu.UnitUniqueId && formattedDate(m.Dated) == dated) < 0) {
                    let datedHistory = [...cashHistory.filter(dh => dh.UnitUniqueId == chu.UnitUniqueId && formattedDate(dh.Dated) == dated).map(a => a)];
                    if (!!datedHistory && datedHistory.length > 0) {
                        let datedTotal: ITotal = {
                            TotalCashAmount: 0,
                            TotalCreditCashAmount: 0,
                            TotalDoCashAmount: 0,
                            UnitUniqueId: ''
                        };

                        datedHistory.forEach(dhh => {
                            datedTotal.TotalCashAmount += dhh.TotalCashAmount;
                            datedTotal.TotalCreditCashAmount += dhh.CreditCashAmount;
                            datedTotal.TotalDoCashAmount += dhh.DoCashAmount;
                        });

                        calculatedDateWiseDepositHistory.push({
                            Dated: getDateOnly(dated),
                            DepositHistory: datedHistory,
                            TotalCashAmount: datedTotal.TotalCashAmount,
                            TotalCreditCashAmount: datedTotal.TotalCreditCashAmount,
                            TotalDoCashAmount: datedTotal.TotalDoCashAmount,
                            UnitUniqueId: chu.UnitUniqueId
                        });
                    }
                }
            });
        }

        setDepositHistoryDateWise([...calculatedDateWiseDepositHistory]);
    }


    const onFromDateChange = (e: Date) => {
        resetDepositHistoryVariables();
        resetRequestHistoryVariables();
        setIsHistoryLoadedAfterLastChange(false);
        setFromDate(e);
    }
    const onToDateChange = (e: Date) => {
        resetDepositHistoryVariables();
        resetRequestHistoryVariables();
        setIsHistoryLoadedAfterLastChange(false);
        setToDate(e);
    }

    const onHistoryRefresh = () => {
        resetDepositHistoryVariables();
        getDepositHistory();
    }

    const onRequestHistoryRefresh = () => {
        resetRequestHistoryVariables();
        getRequestHistory();
    }

    const setDateWiseRequestHistory = (cashHistory: IDepositHistory[]) => {
        let calculatedDateWiseRequestHistory: IDateWiseDepositHistory[] = [];
        if (!!cashHistory && cashHistory.length > 0) {
            cashHistory.forEach(chu => {

                let dated = formattedDate(chu.Dated);
                if (calculatedDateWiseRequestHistory.findIndex(m => m.UnitUniqueId == chu.UnitUniqueId && formattedDate(m.Dated) == dated) < 0) {
                    let datedHistory = [...cashHistory.filter(dh => dh.UnitUniqueId == chu.UnitUniqueId && formattedDate(dh.Dated) == dated).map(a => a)];
                    if (!!datedHistory && datedHistory.length > 0) {
                        let datedTotal: ITotal = {
                            TotalCashAmount: 0,
                            TotalCreditCashAmount: 0,
                            TotalDoCashAmount: 0,
                            UnitUniqueId: ''
                        };

                        datedHistory.forEach(dhh => {
                            datedTotal.TotalCashAmount += dhh.TotalCashAmount;
                            datedTotal.TotalCreditCashAmount += dhh.CreditCashAmount;
                            datedTotal.TotalDoCashAmount += dhh.DoCashAmount;
                        });

                        calculatedDateWiseRequestHistory.push({
                            Dated: getDateOnly(dated),
                            DepositHistory: datedHistory,
                            TotalCashAmount: datedTotal.TotalCashAmount,
                            TotalCreditCashAmount: datedTotal.TotalCreditCashAmount,
                            TotalDoCashAmount: datedTotal.TotalDoCashAmount,
                            UnitUniqueId: chu.UnitUniqueId
                        });
                    }
                }
            });
        }

        setRequestHistoryDateWise([...calculatedDateWiseRequestHistory]);
    }

    const setTotalsForRequestHistory = (cashHistory: IDepositHistory[]) => {
        if (!!props.selectedUnits.UnitAccesses && props.selectedUnits.UnitAccesses.length > 0 && props.selectedUnits.UnitAccesses.filter(u => u.UnitUniqueId != AllOptionUniqueId).length > 0 && !!cashHistory && cashHistory.length > 0) {
            let calculatedRequestHistoryUnitWiseTotals: ITotal[] = [];
            let calculatedRequestTotal: ITotal = {
                TotalCashAmount: 0,
                TotalCreditCashAmount: 0,
                TotalDoCashAmount: 0,
                UnitUniqueId: ''
            };
            props.selectedUnits.UnitAccesses.filter(ua => ua.UnitUniqueId != AllOptionUniqueId).forEach(uaf => {
                let uTotal: ITotal = {
                    TotalCashAmount: 0,
                    TotalCreditCashAmount: 0,
                    TotalDoCashAmount: 0,
                    UnitUniqueId: uaf.UnitUniqueId
                };

                cashHistory.filter(ch => ch.UnitUniqueId == uaf.UnitUniqueId).forEach(chu => {
                    uTotal.TotalDoCashAmount += chu.DoCashAmount;
                    uTotal.TotalCreditCashAmount += chu.CreditCashAmount;
                    uTotal.TotalCashAmount += chu.TotalCashAmount;

                    calculatedRequestTotal.TotalDoCashAmount += chu.DoCashAmount;
                    calculatedRequestTotal.TotalCreditCashAmount += chu.CreditCashAmount;
                    calculatedRequestTotal.TotalCashAmount += chu.TotalCashAmount;
                });

                calculatedRequestHistoryUnitWiseTotals.push({ ...uTotal });
            });

            setRequestHistoryUnitWiseTotals([...calculatedRequestHistoryUnitWiseTotals]);
            setRequestHistoryTotal({ ...calculatedRequestTotal })
        }
    }

    const tDated = new Date();

    //#endregion

    //#region hooks
    React.useEffect(() => {

        if (!!props.selectedUnits.UnitAccesses && props.selectedUnits.UnitAccesses.length > 0) {
            if (viewPort.ShowHistoryScreen == true && viewPort.ShowRequestHistoryScreen == true) {
                if (fromDate !== undefined && fromDate != null && toDate !== undefined && toDate != null) {
                    if (viewPort.ShowHistoryScreen == true) {
                        getDepositHistory();
                    } else {
                        getRequestHistory();
                    }
                }
            } else {
                getCollectedCashDetail();
            }

        } else {
            resetEverything();
        }

    }, [props.selectedUnits.UnitAccesses]);

    //#endregion

    const selectedBackgroundColor = 'blue';
    const normalBackgroundColor = 'rgba(34, 154, 22, 0.72)';

    const TopButtons = (buttonsProps: any) => {
        return <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            <LoadingButton
                size="small"
                type="button"
                variant="contained"
                loading={isLoading}
                style={{ alignSelf: 'flex-end', margin: 3, backgroundColor: viewPort.ShowCashInHandScreen == true ? selectedBackgroundColor : normalBackgroundColor, textAlign: 'right', height: 40 }}
                startIcon={<Icon icon={'bi:cash'} />}
                onClick={onViewCashInHandScreen}
            >
                Cash In Hand
            </LoadingButton>
            <LoadingButton
                size="small"
                type="button"
                variant="contained"
                loading={isLoading}
                style={{ alignSelf: 'flex-end', margin: 3, backgroundColor: viewPort.ShowPendingDepositsScreen == true ? selectedBackgroundColor : normalBackgroundColor, textAlign: 'right', height: 40 }}
                startIcon={<Icon icon={'carbon:face-pending'} />}
                onClick={onViewPendingDepositsScreen}
            >
                Pending Deposits
            </LoadingButton>
            <LoadingButton
                size="small"
                type="button"
                variant="contained"
                loading={isLoading}
                style={{ margin: 3, backgroundColor: viewPort.ShowDepositsScreen == true ? selectedBackgroundColor : normalBackgroundColor, textAlign: 'right', alignSelf: 'flex-end', height: 40 }}
                startIcon={<Icon icon={'teenyicons:tick-circle-outline'} />}
                onClick={onViewDepositsScreen}
            >
                Deposits
            </LoadingButton>

            <LoadingButton
                size="small"
                type="button"
                variant="contained"
                loading={isLoading}
                style={{ margin: 3, backgroundColor: viewPort.ShowRequestHistoryScreen == true ? selectedBackgroundColor : normalBackgroundColor, textAlign: 'right', alignSelf: 'flex-end', height: 40 }}
                startIcon={<Icon icon={'ic:outline-history-toggle-off'} />}
                onClick={onViewRequestHistoryScreen}
            >
                Request History
            </LoadingButton>

            <LoadingButton
                size="small"
                type="button"
                variant="contained"
                loading={isLoading}
                style={{ margin: 3, backgroundColor: viewPort.ShowHistoryScreen == true ? selectedBackgroundColor : normalBackgroundColor, textAlign: 'right', alignSelf: 'flex-end', height: 40 }}
                startIcon={<Icon icon={'ic:outline-history-toggle-off'} />}
                onClick={onViewCashSubmissionHistoryScreen}
            >
                Deposit History
            </LoadingButton>

            <LoadingButton
                size="small"
                type="button"
                variant="contained"
                loading={isLoading}
                style={{ alignSelf: 'flex-end', margin: 3, backgroundColor: normalBackgroundColor, textAlign: 'right', height: 40 }}
                startIcon={<Icon icon={refreshFill} />}
                onClick={buttonsProps.onRefresh}
            >
                Refresh
            </LoadingButton>
        </Grid>;
    }

    //#region Component Return
    return (
        (!!props.selectedUnits && !!props.selectedUnits.UnitAccesses && props.selectedUnits.UnitAccesses.filter(u => u.UnitUniqueId != AllOptionUniqueId).length > 0) ? <>
            <MessageComponent onRemove={(i: number) => {
                let msgs = [...pageMessages];
                msgs.splice(i, 1);
                setPageMessages([...msgs]);
            }} messages={pageMessages} />
            <Page title="Cash Detail | Raza Pak Care">
                {viewPort.ShowCashInHandScreen == true && <Container style={{ maxWidth: 1500, paddingLeft: 0, paddingRight: 0 }}>
                    <Grid container>
                        <Grid item md={3}>
                            <Typography style={{ marginLeft: 10, textDecoration: 'underline', alignSelf: 'center', color: 'rgba(34, 154, 22, 0.72)' }} variant='subtitle2'>Cash In Hand</Typography>
                            <Typography style={{ marginLeft: 10, alignSelf: 'center', color: 'rgba(34, 154, 22, 0.72)' }} variant='subtitle2'>DO Cash: {fCurrency(cashInHandTotal.TotalDoCashAmount)}</Typography>
                            <Typography style={{ marginLeft: 10, alignSelf: 'center', color: 'rgba(34, 154, 22, 0.72)' }} variant='subtitle2'>Credit Cash: {fCurrency(cashInHandTotal.TotalCreditCashAmount)}</Typography>
                            <Typography style={{ marginLeft: 10, alignSelf: 'center', color: 'blue' }} variant='subtitle2'>Total Cash: {fCurrency(cashInHandTotal.TotalCashAmount)}</Typography>
                        </Grid>
                        <Grid item md={9} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                            <TopButtons onRefresh={onRefresh} />
                        </Grid>
                    </Grid>
                    {!!props.selectedUnits && !!props.selectedUnits.UnitAccesses && props.selectedUnits.UnitAccesses.filter(m => m.UnitUniqueId != AllOptionUniqueId).length > 0 && props.selectedUnits.UnitAccesses.filter(m => m.UnitUniqueId != AllOptionUniqueId).map((selectedUnit, unitIndex) => {
                        return <><Card>
                            <Typography style={{ backgroundColor: selectedBackgroundColor, color: 'white', paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h4'}>{selectedUnit.UnitTitle} Cash Detail To Create Cash Request</Typography>
                            {isLoading == true && <AudoSpin
                                height="80"
                                width="80"
                                color='green'
                                ariaLabel='three-dots-loading'
                                wrapperStyle={{ alignSelf: 'center', justifyContent: 'center' }}
                            />}

                            {(!cashInHand || cashInHand.length <= 0 || cashInHand.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length <= 0) && isLoading == false && <Typography style={{ paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h6'}>No Pending Cash In Hand To Create Request For {selectedUnit.UnitTitle}</Typography>}

                            {!!cashInHand && cashInHand.length > 0 && cashInHand.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length > 0 && <Scrollbar sx={{}}>
                                <TableContainer sx={{ minWidth: 800, maxHeight: 700 }}>
                                    <Table stickyHeader>
                                        <UserListHead
                                            order={'asc'}
                                            orderBy={''}
                                            headLabel={TABLE_HEAD}
                                            rowCount={0}
                                            numSelected={0}
                                            onRequestSort={undefined}
                                            onSelectAllClick={() => {
                                                handleCashInHandSelectAllClick(selectedUnit.UnitUniqueId)
                                            }}
                                            isAllSelected={!!cashInHand && cashInHand.length > 0 && cashInHand.filter(u => u.UnitUniqueId == selectedUnit.UnitUniqueId).length > 0 && (cashInHand.filter(u => u.UnitUniqueId == selectedUnit.UnitUniqueId).length == cashInHand.filter(u => u.UnitUniqueId == selectedUnit.UnitUniqueId && u.IsChecked == true).length)}
                                            showSelectAllCheckbox={true}
                                            customKey={`TableHeadRow_2`}
                                        />
                                        <TableBody>
                                            {!!cashInHand && cashInHand.length > 0 && cashInHand.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length > 0 && cashInHand.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).map((row, index) => {

                                                return (
                                                    <><TableRow
                                                        hover
                                                        key={`${row.UnitUniqueId}_${formattedDate(row.Dated, 'MM_DD_YYYY')}`}
                                                        tabIndex={-1}
                                                        role="checkbox"
                                                        selected={false}
                                                        aria-checked={false}
                                                    >
                                                        <TableCell width={10} align="right">
                                                            <Checkbox
                                                                checked={row.IsChecked == true}
                                                                onChange={(event) => handleCheckboxChangeCashInHand(row.UnitUniqueId, row.Dated)}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="center">{index + 1}</TableCell>
                                                        <TableCell align="center" component="th" scope="row" padding="none">
                                                            {formattedDate(row.Dated, 'DD-MMM-YYYY')}
                                                        </TableCell>
                                                        <TableCell align="center" component="th" scope="row" padding="none">
                                                            {fCurrency(row.CollectedDoCashAmount)}
                                                        </TableCell>
                                                        <TableCell align="center" component="th" scope="row" padding="none">
                                                            {fCurrency(row.CollectedCreditCashAmount)}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Typography variant="h6">{fCurrency(row.CollectedDoCashAmount + row.CollectedCreditCashAmount)}</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    </>
                                                );
                                            })}
                                            <TableRow>
                                                <TableCell style={{ borderBottom: 2, borderBottomStyle: 'solid', borderBottomColor: 'black' }} colSpan={3} align='right'>
                                                    <Typography variant='h6' style={{ textAlign: 'right' }}>Total:</Typography>
                                                </TableCell>
                                                <TableCell style={{ borderBottom: 2, borderBottomStyle: 'solid', borderBottomColor: 'black' }} align='center'><Typography style={{ color: 'rgba(34, 154, 22, 0.72)' }} variant='h6'>{fCurrency(cashInHandUnitWiseTotals?.find(m => m.UnitUniqueId == selectedUnit.UnitUniqueId)?.TotalDoCashAmount ?? 0)}</Typography></TableCell>
                                                <TableCell style={{ borderBottom: 2, borderBottomStyle: 'solid', borderBottomColor: 'black' }} align='center'><Typography style={{ color: 'rgba(34, 154, 22, 0.72)' }} variant='h6'>{fCurrency(cashInHandUnitWiseTotals?.find(m => m.UnitUniqueId == selectedUnit.UnitUniqueId)?.TotalCreditCashAmount ?? 0)}</Typography></TableCell>
                                                <TableCell style={{ borderBottom: 2, borderBottomStyle: 'solid', borderBottomColor: 'black' }} align='center'><Typography style={{ color: 'rgba(34, 154, 22, 0.72)' }} variant='h5'>{fCurrency(cashInHandUnitWiseTotals?.find(m => m.UnitUniqueId == selectedUnit.UnitUniqueId)?.TotalCashAmount ?? 0)}</Typography></TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell colSpan={6} align='center'>
                                                    <Typography variant='h4' style={{ textAlign: 'center', textDecoration: 'underline', color: 'blue' }}>Selected Total Amount To Create Cash Request For {selectedUnit.UnitTitle}</Typography>
                                                </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell colSpan={6} align='center'>
                                                    <Typography variant='h3' style={{ color: 'blue' }}>{fCurrency(selectedCashInHandUnitWiseTotals?.find(m => m.UnitUniqueId == selectedUnit.UnitUniqueId)?.TotalCashAmount ?? 0)}</Typography>
                                                </TableCell>
                                            </TableRow>

                                            {(selectedCashInHandUnitWiseTotals?.find(m => m.UnitUniqueId == selectedUnit.UnitUniqueId)?.TotalCashAmount ?? 0) > 0 && <TableRow>
                                                <TableCell colSpan={6} align='center'>
                                                    {<LoadingButton
                                                        size="small"
                                                        type="button"
                                                        variant="contained"
                                                        loading={isLoading}
                                                        style={{ margin: 3, backgroundColor: 'blue', textAlign: 'right', height: 40 }}
                                                        startIcon={<Icon icon={'ic:baseline-create'} />}
                                                        onClick={() => {
                                                            handleCreateCashRequest(selectedUnit.UnitUniqueId);
                                                        }}
                                                    >
                                                        Create Cash Request For {selectedUnit.UnitTitle}
                                                    </LoadingButton>}
                                                </TableCell>
                                            </TableRow>}

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Scrollbar>}
                        </Card><br /></>
                    })}
                </Container>}



                {viewPort.ShowPendingDepositsScreen == true && <Container style={{ maxWidth: 1500, paddingLeft: 0, paddingRight: 0 }}>
                    <Grid container>
                        <Grid item md={3}>
                            <Typography style={{ marginLeft: 10, textDecoration: 'underline', alignSelf: 'center', color: 'rgba(34, 154, 22, 0.72)' }} variant='subtitle2'>Pending Deposits</Typography>
                            <Typography style={{ marginLeft: 10, marginRight: 10, alignSelf: 'center', color: 'rgba(34, 154, 22, 0.72)' }} variant='subtitle2'>DO Cash: {fCurrency(pendingDepositsTotal.TotalDoCashAmount)}</Typography>
                            <Typography style={{ marginLeft: 10, marginRight: 10, alignSelf: 'center', color: 'rgba(34, 154, 22, 0.72)' }} variant='subtitle2'>Credit Cash: {fCurrency(pendingDepositsTotal.TotalCreditCashAmount)}</Typography>
                            <Typography style={{ marginLeft: 10, marginRight: 100, alignSelf: 'center', color: 'blue' }} variant='subtitle2'>Total Cash: {fCurrency(pendingDepositsTotal.TotalCashAmount)}</Typography>
                        </Grid>
                        <Grid item md={9} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                            <TopButtons onRefresh={onRefresh} />
                        </Grid>
                    </Grid>
                    {!!props.selectedUnits && !!props.selectedUnits.UnitAccesses && props.selectedUnits.UnitAccesses.filter(m => m.UnitUniqueId != AllOptionUniqueId).length > 0 && props.selectedUnits.UnitAccesses.filter(m => m.UnitUniqueId != AllOptionUniqueId).map((selectedUnit, unitIndex) => {
                        return <><Card>
                            <Typography style={{ backgroundColor: selectedBackgroundColor, color: 'white', paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h4'}>{selectedUnit.UnitTitle} Pending Deposits</Typography>
                            {isLoading == true && <AudoSpin
                                height="80"
                                width="80"
                                color='green'
                                ariaLabel='three-dots-loading'
                                wrapperStyle={{ alignSelf: 'center', justifyContent: 'center' }}
                            />}

                            {(!pendingDeposits || pendingDeposits.length <= 0 || pendingDeposits.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length <= 0) && isLoading == false && <Typography style={{ paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h6'}>No Pending Deposits For {selectedUnit.UnitTitle}</Typography>}

                            {!!pendingDeposits && pendingDeposits.length > 0 && pendingDeposits.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length > 0 && <Scrollbar sx={{}}>
                                <TableContainer sx={{ minWidth: 800, maxHeight: 700 }}>
                                    <Table stickyHeader>
                                        <UserListHead
                                            order={'asc'}
                                            orderBy={''}
                                            headLabel={TABLE_HEAD_PENDING_DEPOSITS}
                                            rowCount={0}
                                            numSelected={0}
                                            onRequestSort={undefined}
                                            showSelectAllCheckbox={false}
                                            customKey={`TableHeadRow_2`}
                                        />
                                        <TableBody>
                                            {!!pendingDeposits && pendingDeposits.length > 0 && pendingDeposits.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length > 0 && pendingDeposits.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).map((row, index) => {

                                                return (
                                                    <><TableRow
                                                        hover
                                                        key={`${row.UnitUniqueId}_${formattedDate(row.Dated, 'MM_DD_YYYY')}`}
                                                        tabIndex={-1}
                                                        role="checkbox"
                                                        selected={false}
                                                        aria-checked={false}
                                                    >
                                                        <TableCell align="center">{index + 1}</TableCell>
                                                        <TableCell align="center" component="th" scope="row" padding="none">
                                                            {formattedDate(row.Dated, 'DD-MMM-YYYY')}
                                                        </TableCell>
                                                        <TableCell align="center" component="th" scope="row" padding="none">
                                                            {fCurrency(row.CollectedDoCashAmount)}
                                                        </TableCell>
                                                        <TableCell align="center" component="th" scope="row" padding="none">
                                                            {fCurrency(row.CollectedCreditCashAmount)}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Typography variant="h6">{fCurrency(row.CollectedDoCashAmount + row.CollectedCreditCashAmount)}</Typography>
                                                        </TableCell>
                                                        <TableCell align='center'>
                                                            <Label
                                                                variant="ghost"
                                                                color={'info'}
                                                            >
                                                                Deposit Pending
                                                            </Label>
                                                        </TableCell>
                                                    </TableRow>
                                                    </>
                                                );
                                            })}
                                            <TableRow>
                                                <TableCell style={{ borderBottom: 2, borderBottomStyle: 'solid', borderBottomColor: 'black' }} colSpan={3} align='right'>
                                                    <Typography variant='h6' style={{ textAlign: 'right' }}>Total:</Typography>
                                                </TableCell>
                                                <TableCell style={{ borderBottom: 2, borderBottomStyle: 'solid', borderBottomColor: 'black' }} align='center'><Typography style={{ color: 'rgba(34, 154, 22, 0.72)' }} variant='h6'>{fCurrency(pendingDepositsUnitWiseTotals?.find(m => m.UnitUniqueId == selectedUnit.UnitUniqueId)?.TotalDoCashAmount ?? 0)}</Typography></TableCell>
                                                <TableCell style={{ borderBottom: 2, borderBottomStyle: 'solid', borderBottomColor: 'black' }} align='center'><Typography style={{ color: 'rgba(34, 154, 22, 0.72)' }} variant='h6'>{fCurrency(pendingDepositsUnitWiseTotals?.find(m => m.UnitUniqueId == selectedUnit.UnitUniqueId)?.TotalCreditCashAmount ?? 0)}</Typography></TableCell>
                                                <TableCell style={{ borderBottom: 2, borderBottomStyle: 'solid', borderBottomColor: 'black' }} align='center'><Typography style={{ color: 'rgba(34, 154, 22, 0.72)' }} variant='h5'>{fCurrency(pendingDepositsUnitWiseTotals?.find(m => m.UnitUniqueId == selectedUnit.UnitUniqueId)?.TotalCashAmount ?? 0)}</Typography></TableCell>
                                                <TableCell>&nbsp;</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Scrollbar>}
                        </Card><br /></>
                    })}
                </Container>}



                {viewPort.ShowDepositsScreen == true && <Container style={{ maxWidth: 1500, paddingLeft: 0, paddingRight: 0 }}>
                    <Grid container>
                        <Grid item md={3}>
                            <Typography style={{ marginLeft: 10, textDecoration: 'underline', alignSelf: 'center', color: 'rgba(34, 154, 22, 0.72)' }} variant='subtitle2'>Deposits</Typography>
                            <Typography style={{ marginLeft: 10, marginRight: 10, alignSelf: 'center', color: 'rgba(34, 154, 22, 0.72)' }} variant='subtitle2'>DO Cash: {fCurrency(depositsTotal.TotalDoCashAmount)}</Typography>
                            <Typography style={{ marginLeft: 10, marginRight: 10, alignSelf: 'center', color: 'rgba(34, 154, 22, 0.72)' }} variant='subtitle2'>Credit Cash: {fCurrency(depositsTotal.TotalCreditCashAmount)}</Typography>
                            <Typography style={{ marginLeft: 10, marginRight: 100, alignSelf: 'center', color: 'blue' }} variant='subtitle2'>Total Cash: {fCurrency(depositsTotal.TotalCashAmount)}</Typography>
                        </Grid>
                        <Grid item md={9} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                            <TopButtons onRefresh={onRefresh} />
                        </Grid>
                    </Grid>
                    {!!props.selectedUnits && !!props.selectedUnits.UnitAccesses && props.selectedUnits.UnitAccesses.filter(m => m.UnitUniqueId != AllOptionUniqueId).length > 0 && props.selectedUnits.UnitAccesses.filter(m => m.UnitUniqueId != AllOptionUniqueId).map((selectedUnit, unitIndex) => {
                        return <><Card>
                            <Typography style={{ backgroundColor: selectedBackgroundColor, color: 'white', paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h4'}>{selectedUnit.UnitTitle} Deposits</Typography>
                            {isLoading == true && <AudoSpin
                                height="80"
                                width="80"
                                color='green'
                                ariaLabel='three-dots-loading'
                                wrapperStyle={{ alignSelf: 'center', justifyContent: 'center' }}
                            />}

                            {(!deposits || deposits.length <= 0 || deposits.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length <= 0) && isLoading == false && <Typography style={{ paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h6'}>No Recent Cash Deposited For {selectedUnit.UnitTitle}</Typography>}

                            {!!deposits && deposits.length > 0 && deposits.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length > 0 && <Scrollbar sx={{}}>
                                <TableContainer sx={{ minWidth: 800, maxHeight: 700 }}>
                                    <Table stickyHeader>
                                        <UserListHead
                                            order={'asc'}
                                            orderBy={''}
                                            headLabel={TABLE_HEAD}
                                            rowCount={0}
                                            numSelected={0}
                                            onRequestSort={undefined}
                                            onSelectAllClick={() => {
                                                handleDepositsSelectAllClick(selectedUnit.UnitUniqueId)
                                            }}
                                            isAllSelected={!!deposits && deposits.length > 0 && deposits.filter(u => u.UnitUniqueId == selectedUnit.UnitUniqueId).length > 0 && (deposits.filter(u => u.UnitUniqueId == selectedUnit.UnitUniqueId).length == deposits.filter(u => u.UnitUniqueId == selectedUnit.UnitUniqueId && u.IsChecked == true).length)}
                                            showSelectAllCheckbox={true}
                                            customKey={`TableHeadRow_2`}
                                        />
                                        <TableBody>
                                            {!!deposits && deposits.length > 0 && deposits.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length > 0 && deposits.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).map((row, index) => {

                                                return (
                                                    <><TableRow
                                                        hover
                                                        key={`${row.UnitUniqueId}_${formattedDate(row.Dated, 'MM_DD_YYYY')}`}
                                                        tabIndex={-1}
                                                        role="checkbox"
                                                        selected={false}
                                                        aria-checked={false}
                                                    >
                                                        <TableCell width={10} align="right">
                                                            <Checkbox
                                                                checked={row.IsChecked == true}
                                                                onChange={(event) => handleCheckboxChangeDeposits(row.UnitUniqueId, row.Dated)}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="center">{index + 1}</TableCell>
                                                        <TableCell align="center" component="th" scope="row" padding="none">
                                                            {formattedDate(row.Dated, 'DD-MMM-YYYY')}
                                                        </TableCell>
                                                        <TableCell align="center" component="th" scope="row" padding="none">
                                                            {fCurrency(row.CollectedDoCashAmount)}
                                                        </TableCell>
                                                        <TableCell align="center" component="th" scope="row" padding="none">
                                                            {fCurrency(row.CollectedCreditCashAmount)}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Typography variant="h6">{fCurrency(row.CollectedDoCashAmount + row.CollectedCreditCashAmount)}</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    </>
                                                );
                                            })}
                                            <TableRow>
                                                <TableCell style={{ borderBottom: 2, borderBottomStyle: 'solid', borderBottomColor: 'black' }} colSpan={3} align='right'>
                                                    <Typography variant='h6' style={{ textAlign: 'right' }}>Total:</Typography>
                                                </TableCell>
                                                <TableCell style={{ borderBottom: 2, borderBottomStyle: 'solid', borderBottomColor: 'black' }} align='center'><Typography style={{ color: 'rgba(34, 154, 22, 0.72)' }} variant='h6'>{fCurrency(depositsUnitWiseTotals?.find(m => m.UnitUniqueId == selectedUnit.UnitUniqueId)?.TotalDoCashAmount ?? 0)}</Typography></TableCell>
                                                <TableCell style={{ borderBottom: 2, borderBottomStyle: 'solid', borderBottomColor: 'black' }} align='center'><Typography style={{ color: 'rgba(34, 154, 22, 0.72)' }} variant='h6'>{fCurrency(depositsUnitWiseTotals?.find(m => m.UnitUniqueId == selectedUnit.UnitUniqueId)?.TotalCreditCashAmount ?? 0)}</Typography></TableCell>
                                                <TableCell style={{ borderBottom: 2, borderBottomStyle: 'solid', borderBottomColor: 'black' }} align='center'><Typography style={{ color: 'rgba(34, 154, 22, 0.72)' }} variant='h5'>{fCurrency(depositsUnitWiseTotals?.find(m => m.UnitUniqueId == selectedUnit.UnitUniqueId)?.TotalCashAmount ?? 0)}</Typography></TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell colSpan={6} align='center'>
                                                    <Typography variant='h4' style={{ textAlign: 'center', textDecoration: 'underline', color: 'blue' }}>Selected Deposited Amount To Confirm For {selectedUnit.UnitTitle}</Typography>
                                                </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell colSpan={6} align='center'>
                                                    <Typography variant='h3' style={{ color: 'blue' }}>{fCurrency(selectedDepositsUnitWiseTotals?.find(m => m.UnitUniqueId == selectedUnit.UnitUniqueId)?.TotalCashAmount ?? 0)}</Typography>
                                                </TableCell>
                                            </TableRow>

                                            {(selectedDepositsUnitWiseTotals?.find(m => m.UnitUniqueId == selectedUnit.UnitUniqueId)?.TotalCashAmount ?? 0) > 0 && <TableRow>
                                                <TableCell colSpan={6} align='center'>
                                                    {<LoadingButton
                                                        size="small"
                                                        type="button"
                                                        variant="contained"
                                                        loading={isLoading}
                                                        style={{ margin: 3, backgroundColor: 'blue', textAlign: 'right', height: 40 }}
                                                        startIcon={<Icon icon={'emojione-monotone:white-heavy-check-mark'} />}
                                                        onClick={() => {
                                                            handleConfirmDeposit(selectedUnit.UnitUniqueId);
                                                        }}
                                                    >
                                                        Confirm Deposit For {selectedUnit.UnitTitle}
                                                    </LoadingButton>}
                                                </TableCell>
                                            </TableRow>}

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Scrollbar>}
                        </Card><br /></>
                    })}
                </Container>}















                {viewPort.ShowHistoryScreen == true && <Container style={{ maxWidth: 1500, paddingLeft: 0, paddingRight: 0 }}>
                    <Grid container>
                        <Grid item md={3}>
                            <Typography style={{ marginLeft: 10, textDecoration: 'underline', alignSelf: 'center', color: 'rgba(34, 154, 22, 0.72)' }} variant='subtitle2'>History {!!fromDate && !!toDate && isHistoryLoadedAfterLastChange == true ? `(${formattedDate(fromDate, 'DD-MMM-YYYY')} to ${formattedDate(toDate, 'DD-MMM-YYYY')})` : ''}</Typography>
                            <Typography style={{ marginLeft: 10, marginRight: 10, alignSelf: 'center', color: 'rgba(34, 154, 22, 0.72)' }} variant='subtitle2'>DO Cash: {!!fromDate && !!toDate && isHistoryLoadedAfterLastChange == true ? fCurrency(depositHistoryTotal.TotalDoCashAmount) : 'N/A'}</Typography>
                            <Typography style={{ marginLeft: 10, marginRight: 10, alignSelf: 'center', color: 'rgba(34, 154, 22, 0.72)' }} variant='subtitle2'>Credit Cash: {!!fromDate && !!toDate && isHistoryLoadedAfterLastChange == true ? fCurrency(depositHistoryTotal.TotalCreditCashAmount) : 'N/A'}</Typography>
                            <Typography style={{ marginLeft: 10, marginRight: 100, alignSelf: 'center', color: 'blue' }} variant='subtitle2'>Total Cash: {!!fromDate && !!toDate && isHistoryLoadedAfterLastChange == true ? fCurrency(depositHistoryTotal.TotalCashAmount) : 'N/A'}</Typography>
                        </Grid>
                        <Grid item md={9} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                            <TopButtons onRefresh={onHistoryRefresh} />
                        </Grid>
                    </Grid>

                    <Container style={{ paddingTop: 20, paddingBottom: 10 }}>
                        <Grid container spacing={2}>
                            <Grid item md={6} sm={12}>
                                <CustomDatePicker
                                    label='From Date'
                                    value={fromDate}
                                    inputDisable={true}
                                    readonly={isLoading}
                                    onChange={onFromDateChange}
                                    minDate={isRestrictedLogin(props.loginUser.UserId) ? (new Date(tDated.getFullYear(), tDated.getMonth() - NumberOfPreviousMonth, 1)) : undefined}
                                />
                            </Grid>

                            <Grid item md={6} sm={12}>
                                <CustomDatePicker
                                    label='To Date'
                                    value={toDate}
                                    inputDisable={true}
                                    readonly={isLoading}
                                    onChange={onToDateChange}
                                    minDate={isRestrictedLogin(props.loginUser.UserId) ? (new Date(tDated.getFullYear(), tDated.getMonth() - NumberOfPreviousMonth, 1)) : undefined}
                                />
                            </Grid>

                            <Grid item xs={12} style={{ justifyContent: 'center', display: 'flex' }}>
                                <LoadingButton
                                    size="small"
                                    type="button"
                                    variant="contained"
                                    loading={isLoading}
                                    style={{ alignSelf: 'center', margin: 3, backgroundColor: normalBackgroundColor, textAlign: 'right', height: 40 }}
                                    startIcon={<Icon icon={'bi:clock-history'} />}
                                    onClick={getDepositHistory}
                                >
                                    Get Deposit History
                                </LoadingButton>
                            </Grid>

                        </Grid>
                    </Container>

                    {!!props.selectedUnits && !!props.selectedUnits.UnitAccesses && props.selectedUnits.UnitAccesses.filter(m => m.UnitUniqueId != AllOptionUniqueId).length > 0 && props.selectedUnits.UnitAccesses.filter(m => m.UnitUniqueId != AllOptionUniqueId).map((selectedUnit, unitIndex) => {
                        return <><Card>
                            {!!fromDate && !!toDate && isHistoryLoadedAfterLastChange == true && <Typography style={{ backgroundColor: selectedBackgroundColor, color: 'white', paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h4'}>{selectedUnit.UnitTitle} Deposit History ({`${formattedDate(fromDate, 'DD-MMM-YYYY')} to ${formattedDate(toDate, 'DD-MMM-YYYY')}`})</Typography>}
                            {isLoading == true && <AudoSpin
                                height="80"
                                width="80"
                                color='green'
                                ariaLabel='three-dots-loading'
                                wrapperStyle={{ alignSelf: 'center', justifyContent: 'center' }}
                            />}

                            {(!depositHistoryDateWise || depositHistoryDateWise.length <= 0 || depositHistoryDateWise.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length <= 0) && !!fromDate && !!toDate && isHistoryLoadedAfterLastChange == true && isLoading == false && <Typography style={{ paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h6'}>No Cash History For {selectedUnit.UnitTitle} ({`${formattedDate(fromDate, 'DD-MMM-YYYY')} to ${formattedDate(toDate, 'DD-MMM-YYYY')}`})</Typography>}

                            {!!depositHistoryDateWise && depositHistoryDateWise.length > 0 && depositHistoryDateWise.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length > 0 && <Scrollbar sx={{}}>
                                <TableContainer sx={{ minWidth: 800, maxHeight: 700 }}>
                                    <Table stickyHeader>
                                        <UserListHead
                                            order={'asc'}
                                            orderBy={''}
                                            headLabel={TABLE_HEAD_DEPOSIT_HISTORY}
                                            rowCount={0}
                                            numSelected={0}
                                            onRequestSort={undefined}
                                            onSelectAllClick={undefined}
                                            isAllSelected={false}
                                            showSelectAllCheckbox={false}
                                            customKey={`TableHeadRow_3`}
                                        />
                                        <TableBody>
                                            {!!depositHistoryDateWise && depositHistoryDateWise.length > 0 && depositHistoryDateWise.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length > 0 && depositHistoryDateWise.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).map((datedRow, datedIndex) => {
                                                return (<>{!!datedRow.DepositHistory && datedRow.DepositHistory.length > 0 && datedRow.DepositHistory.map((row, index) => {
                                                    return (
                                                        <TableRow
                                                            hover
                                                            key={`${row.UnitUniqueId}_${formattedDate(row.Dated, 'MM_DD_YYYY')}`}
                                                            tabIndex={-1}
                                                            role="checkbox"
                                                            selected={false}
                                                            aria-checked={false}
                                                        >
                                                            <TableCell align="center">{index + 1}</TableCell>
                                                            <TableCell align="center" component="th" scope="row" padding="none">
                                                                {formattedDate(row.Dated, defaultDateTimeTwelveHourFormat)}
                                                            </TableCell>
                                                            <TableCell align="center" component="th" scope="row" padding="none">
                                                                {row.DepositedBy}
                                                            </TableCell>
                                                            <TableCell align="center" component="th" scope="row" padding="none">
                                                                {fCurrency(row.DoCashAmount)}
                                                            </TableCell>
                                                            <TableCell align="center" component="th" scope="row" padding="none">
                                                                {fCurrency(row.CreditCashAmount)}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Typography variant="subtitle2">{fCurrency(row.TotalCashAmount)}</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}

                                                    <TableRow
                                                        hover
                                                        key={`${datedRow.UnitUniqueId}_datedRow_${formattedDate(datedRow.Dated, 'MM_DD_YYYY')}`}
                                                        tabIndex={-1}
                                                        role="checkbox"
                                                        selected={false}
                                                        aria-checked={false}
                                                    >
                                                        <TableCell style={{ borderBottom: 1, borderBottomColor: 'black', borderBottomStyle: 'solid' }} align="right" colSpan={3}><Typography variant='subtitle2'>Sub Total ({formattedDate(datedRow.Dated, 'DD-MMM-YYYY')}):</Typography></TableCell>



                                                        <TableCell style={{ borderBottom: 1, borderBottomColor: 'black', borderBottomStyle: 'solid' }} align="center" component="th" scope="row" padding="none">
                                                            <Typography variant="subtitle2">{fCurrency(datedRow.TotalDoCashAmount)}</Typography>
                                                        </TableCell>

                                                        <TableCell style={{ borderBottom: 1, borderBottomColor: 'black', borderBottomStyle: 'solid' }} align="center" component="th" scope="row" padding="none">
                                                            <Typography variant="subtitle2">{fCurrency(datedRow.TotalCreditCashAmount)}</Typography>
                                                        </TableCell>

                                                        <TableCell style={{ borderBottom: 1, borderBottomColor: 'black', borderBottomStyle: 'solid' }} align="center" component="th" scope="row" padding="none">
                                                            <Typography variant="subtitle2">{fCurrency(datedRow.TotalCashAmount)}</Typography>
                                                        </TableCell>

                                                    </TableRow>

                                                </>);
                                            })}
                                            <TableRow>
                                                <TableCell colSpan={3} align='right'>
                                                    <Typography variant='h6' style={{ textAlign: 'right' }}>Total:</Typography>
                                                </TableCell>
                                                <TableCell align='center'><Typography style={{ color: 'rgba(34, 154, 22, 0.72)' }} variant='h6'>{fCurrency(depositHistoryUnitWiseTotals?.find(m => m.UnitUniqueId == selectedUnit.UnitUniqueId)?.TotalDoCashAmount ?? 0)}</Typography></TableCell>
                                                <TableCell align='center'><Typography style={{ color: 'rgba(34, 154, 22, 0.72)' }} variant='h6'>{fCurrency(depositHistoryUnitWiseTotals?.find(m => m.UnitUniqueId == selectedUnit.UnitUniqueId)?.TotalCreditCashAmount ?? 0)}</Typography></TableCell>
                                                <TableCell align='center'><Typography style={{ color: 'rgba(34, 154, 22, 0.72)' }} variant='h5'>{fCurrency(depositHistoryUnitWiseTotals?.find(m => m.UnitUniqueId == selectedUnit.UnitUniqueId)?.TotalCashAmount ?? 0)}</Typography></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Scrollbar>}
                        </Card><br /></>
                    })}
                </Container>}



                {viewPort.ShowRequestHistoryScreen == true && <Container style={{ maxWidth: 1500, paddingLeft: 0, paddingRight: 0 }}>
                    <Grid container>
                        <Grid item md={3}>
                            <Typography style={{ marginLeft: 10, textDecoration: 'underline', alignSelf: 'center', color: 'rgba(34, 154, 22, 0.72)' }} variant='subtitle2'>Request History {!!fromDate && !!toDate && isHistoryLoadedAfterLastChange == true ? `(${formattedDate(fromDate, 'DD-MMM-YYYY')} to ${formattedDate(toDate, 'DD-MMM-YYYY')})` : ''}</Typography>
                            <Typography style={{ marginLeft: 10, marginRight: 10, alignSelf: 'center', color: 'rgba(34, 154, 22, 0.72)' }} variant='subtitle2'>DO Cash: {!!fromDate && !!toDate && isHistoryLoadedAfterLastChange == true ? fCurrency(requestHistoryTotal.TotalDoCashAmount) : 'N/A'}</Typography>
                            <Typography style={{ marginLeft: 10, marginRight: 10, alignSelf: 'center', color: 'rgba(34, 154, 22, 0.72)' }} variant='subtitle2'>Credit Cash: {!!fromDate && !!toDate && isHistoryLoadedAfterLastChange == true ? fCurrency(requestHistoryTotal.TotalCreditCashAmount) : 'N/A'}</Typography>
                            <Typography style={{ marginLeft: 10, marginRight: 100, alignSelf: 'center', color: 'blue' }} variant='subtitle2'>Total Cash: {!!fromDate && !!toDate && isHistoryLoadedAfterLastChange == true ? fCurrency(requestHistoryTotal.TotalCashAmount) : 'N/A'}</Typography>
                        </Grid>
                        <Grid item md={9} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                            <TopButtons onRefresh={onRequestHistoryRefresh} />
                        </Grid>
                    </Grid>

                    <Container style={{ paddingTop: 20, paddingBottom: 10 }}>
                        <Grid container spacing={2}>
                            <Grid item md={6} sm={12}>
                                <CustomDatePicker
                                    label='From Date'
                                    value={fromDate}
                                    inputDisable={true}
                                    readonly={isLoading}
                                    onChange={onFromDateChange}
                                    minDate={isRestrictedLogin(props.loginUser.UserId) ? (new Date(tDated.getFullYear(), tDated.getMonth() - NumberOfPreviousMonth, 1)) : undefined}
                                />
                            </Grid>

                            <Grid item md={6} sm={12}>
                                <CustomDatePicker
                                    label='To Date'
                                    value={toDate}
                                    inputDisable={true}
                                    readonly={isLoading}
                                    onChange={onToDateChange}
                                    minDate={isRestrictedLogin(props.loginUser.UserId) ? (new Date(tDated.getFullYear(), tDated.getMonth() - NumberOfPreviousMonth, 1)) : undefined}
                                />
                            </Grid>

                            <Grid item xs={12} style={{ justifyContent: 'center', display: 'flex' }}>
                                <LoadingButton
                                    size="small"
                                    type="button"
                                    variant="contained"
                                    loading={isLoading}
                                    style={{ alignSelf: 'center', margin: 3, backgroundColor: normalBackgroundColor, textAlign: 'right', height: 40 }}
                                    startIcon={<Icon icon={'bi:clock-history'} />}
                                    onClick={getRequestHistory}
                                >
                                    Get Request History
                                </LoadingButton>
                            </Grid>

                        </Grid>
                    </Container>

                    {!!props.selectedUnits && !!props.selectedUnits.UnitAccesses && props.selectedUnits.UnitAccesses.filter(m => m.UnitUniqueId != AllOptionUniqueId).length > 0 && props.selectedUnits.UnitAccesses.filter(m => m.UnitUniqueId != AllOptionUniqueId).map((selectedUnit, unitIndex) => {
                        return <><Card>
                            {!!fromDate && !!toDate && isHistoryLoadedAfterLastChange == true && <Typography style={{ backgroundColor: selectedBackgroundColor, color: 'white', paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h4'}>{selectedUnit.UnitTitle} Request History ({`${formattedDate(fromDate, 'DD-MMM-YYYY')} to ${formattedDate(toDate, 'DD-MMM-YYYY')}`})</Typography>}
                            {isLoading == true && <AudoSpin
                                height="80"
                                width="80"
                                color='green'
                                ariaLabel='three-dots-loading'
                                wrapperStyle={{ alignSelf: 'center', justifyContent: 'center' }}
                            />}

                            {(!requestHistoryDateWise || requestHistoryDateWise.length <= 0 || requestHistoryDateWise.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length <= 0) && !!fromDate && !!toDate && isHistoryLoadedAfterLastChange == true && isLoading == false && <Typography style={{ paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h6'}>No Request Cash History For {selectedUnit.UnitTitle} ({`${formattedDate(fromDate, 'DD-MMM-YYYY')} to ${formattedDate(toDate, 'DD-MMM-YYYY')}`})</Typography>}

                            {!!requestHistoryDateWise && requestHistoryDateWise.length > 0 && requestHistoryDateWise.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length > 0 && <Scrollbar sx={{}}>
                                <TableContainer sx={{ minWidth: 800, maxHeight: 700 }}>
                                    <Table stickyHeader>
                                        <UserListHead
                                            order={'asc'}
                                            orderBy={''}
                                            headLabel={TABLE_HEAD_DEPOSIT_REQUEST_HISTORY}
                                            rowCount={0}
                                            numSelected={0}
                                            onRequestSort={undefined}
                                            onSelectAllClick={undefined}
                                            isAllSelected={false}
                                            showSelectAllCheckbox={false}
                                            customKey={`TableHeadRow_3`}
                                        />
                                        <TableBody>
                                            {!!requestHistoryDateWise && requestHistoryDateWise.length > 0 && requestHistoryDateWise.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length > 0 && requestHistoryDateWise.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).map((datedRow, datedIndex) => {
                                                return (<>{!!datedRow.DepositHistory && datedRow.DepositHistory.length > 0 && datedRow.DepositHistory.map((row, index) => {
                                                    return (
                                                        <TableRow
                                                            hover
                                                            key={`${row.UnitUniqueId}_${formattedDate(row.Dated, 'MM_DD_YYYY')}`}
                                                            tabIndex={-1}
                                                            role="checkbox"
                                                            selected={false}
                                                            aria-checked={false}
                                                        >
                                                            <TableCell align="center">{index + 1}</TableCell>
                                                            <TableCell align="center" component="th" scope="row" padding="none">
                                                                {formattedDate(row.Dated, defaultDateTimeTwelveHourFormat)}
                                                            </TableCell>
                                                            <TableCell align="center" component="th" scope="row" padding="none">
                                                                {formattedDate(row.DepositedOn, defaultDateTimeTwelveHourFormat)}
                                                            </TableCell>

                                                            <TableCell align="center" component="th" scope="row" padding="none">
                                                                {formattedDate(row.CashSubmittedOn, defaultDateTimeTwelveHourFormat)}
                                                            </TableCell>

                                                            <TableCell align="center" component="th" scope="row" padding="none">
                                                                {row.DepositedBy}
                                                            </TableCell>
                                                            <TableCell align="center" component="th" scope="row" padding="none">
                                                                {fCurrency(row.DoCashAmount)}
                                                            </TableCell>
                                                            <TableCell align="center" component="th" scope="row" padding="none">
                                                                {fCurrency(row.CreditCashAmount)}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Typography variant="subtitle2">{fCurrency(row.TotalCashAmount)}</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}

                                                    <TableRow
                                                        hover
                                                        key={`${datedRow.UnitUniqueId}_datedRow_${formattedDate(datedRow.Dated, 'MM_DD_YYYY')}`}
                                                        tabIndex={-1}
                                                        role="checkbox"
                                                        selected={false}
                                                        aria-checked={false}
                                                    >
                                                        <TableCell style={{ borderBottom: 1, borderBottomColor: 'black', borderBottomStyle: 'solid' }} align="right" colSpan={5}><Typography variant='subtitle2'>Sub Total ({formattedDate(datedRow.Dated, 'DD-MMM-YYYY')}):</Typography></TableCell>



                                                        <TableCell style={{ borderBottom: 1, borderBottomColor: 'black', borderBottomStyle: 'solid' }} align="center" component="th" scope="row" padding="none">
                                                            <Typography variant="subtitle2">{fCurrency(datedRow.TotalDoCashAmount)}</Typography>
                                                        </TableCell>

                                                        <TableCell style={{ borderBottom: 1, borderBottomColor: 'black', borderBottomStyle: 'solid' }} align="center" component="th" scope="row" padding="none">
                                                            <Typography variant="subtitle2">{fCurrency(datedRow.TotalCreditCashAmount)}</Typography>
                                                        </TableCell>

                                                        <TableCell style={{ borderBottom: 1, borderBottomColor: 'black', borderBottomStyle: 'solid' }} align="center" component="th" scope="row" padding="none">
                                                            <Typography variant="subtitle2">{fCurrency(datedRow.TotalCashAmount)}</Typography>
                                                        </TableCell>

                                                    </TableRow>

                                                </>);
                                            })}
                                            <TableRow>
                                                <TableCell colSpan={5} align='right'>
                                                    <Typography variant='h6' style={{ textAlign: 'right' }}>Total:</Typography>
                                                </TableCell>
                                                <TableCell align='center'><Typography style={{ color: 'rgba(34, 154, 22, 0.72)' }} variant='h6'>{fCurrency(requestHistoryUnitWiseTotals?.find(m => m.UnitUniqueId == selectedUnit.UnitUniqueId)?.TotalDoCashAmount ?? 0)}</Typography></TableCell>
                                                <TableCell align='center'><Typography style={{ color: 'rgba(34, 154, 22, 0.72)' }} variant='h6'>{fCurrency(requestHistoryUnitWiseTotals?.find(m => m.UnitUniqueId == selectedUnit.UnitUniqueId)?.TotalCreditCashAmount ?? 0)}</Typography></TableCell>
                                                <TableCell align='center'><Typography style={{ color: 'rgba(34, 154, 22, 0.72)' }} variant='h5'>{fCurrency(requestHistoryUnitWiseTotals?.find(m => m.UnitUniqueId == selectedUnit.UnitUniqueId)?.TotalCashAmount ?? 0)}</Typography></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Scrollbar>}
                        </Card><br /></>
                    })}
                </Container>}


            </Page>
        </> : <NoUnitSelectedComponent pageTitle='Cash Detail | Raza Pak Care' />
    );

    //#endregion
}

const mapStateToProps = (state: IApplicationState) => {
    return {
        selectedUnits: state.selectedUnitAccesses,
        loginUser: state.loginUser
    };
}
const connectedCollectedCashDetailNew = connect(mapStateToProps)(CollectedCashDetailNew);
export { connectedCollectedCashDetailNew as CollectedCashDetailNew };
