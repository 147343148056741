import { IOrderData } from 'src/interfaces/reports/order-data.interface';
import {OrderNewConstants} from '../actions/action.constant';
import {IAction} from '../actions/action.interface';
import {initialState} from '../initial-state';

export const orderNewDataReducer = (
  state = initialState.orderNewData,
  action: IAction<IOrderData>,
) => {
  switch (action.type) {
    case OrderNewConstants.SET_ORDER_NEW_DATA:
      let newState: IOrderData = {...action.payload};
      return newState;
    default:
      return state;
  }
};
