import { IProductBarcode } from 'src/interfaces/product-barcode.interface';
import { IProductScheme } from 'src/interfaces/product-scheme.interface';
import { IProductStock } from 'src/interfaces/product-stock.interface';
import { IProduct } from 'src/interfaces/product.interface';
import { IResponseModal } from 'src/interfaces/response-modal.interface';
import { IStockLedger } from 'src/interfaces/stock-ledger.interface';
import { concatParam, formattedDate } from 'src/utility';
import {http} from './http-base';

const getProductSchemes = async (
  commaSeparatedUnitUniqueIds:string
  ): Promise<IProductScheme[]> => {

    let param = '';
    param = concatParam(param,commaSeparatedUnitUniqueIds,'commaSeparatedUnitUniqueIds');

    let url: string = `api/Product/Schemes${param}`;

    let result = await http
      .get<IProductScheme[]>(url)
      .catch(error => Promise.reject(error));
    return result.data;
  };

  const getProductBarcodes = async (): Promise<IProductBarcode[]> => {
      let url: string = `api/Product/Barcodes`;
  
      let result = await http
        .get<IProductBarcode[]>(url)
        .catch(error => Promise.reject(error));
      return result.data;
    };

    const getProducts = async (): Promise<IProduct[]> => {
      let url: string = `api/Product/search`;
  
      let result = await http
        .get<IProduct[]>(url)
        .catch(error => Promise.reject(error));
      return result.data;
    };

    const getProductStock = async (unitUniqueId:string): Promise<IProductStock[]> => {
      let url: string = `api/ProductStock/search?unitUniqueId=${unitUniqueId}`;
  
      let result = await http
        .get<IProductStock[]>(url)
        .catch(error => Promise.reject(error));
      return result.data;
    };

    const getUnitWiseProductStock = async (loginUserId:number, commaSeparatedUnitUniqueIds:string): Promise<IProductStock[]> => {
      let params = '';
      params = concatParam(params,loginUserId,'loginUserId');
      params = concatParam(params, commaSeparatedUnitUniqueIds, 'commaSeparatedUnitUniqueIds');
      let url: string = `api/ProductStock/UnitWiseProductStock${params}`;
  
      let result = await http
        .get<IProductStock[]>(url)
        .catch(error => Promise.reject(error));
      return result.data;
    };

    const getUnitWiseProductStockLedger = async (loginUserId:number, commaSeparatedUnitUniqueIds:string, productId:number, fromDate:Date, toDate:Date): Promise<IStockLedger[]> => {
      let params = '';
      params = concatParam(params,loginUserId,'loginUserId');
      params = concatParam(params, commaSeparatedUnitUniqueIds, 'commaSeparatedUnitUniqueIds');
      params = concatParam(params, productId, 'productId');
      const formattedFromDate = formattedDate(fromDate);
      params = concatParam(params,formattedFromDate, 'fromDate');
      const formattedToDate = formattedDate(toDate);
      params = concatParam(params,formattedToDate, 'toDate');
      let url: string = `api/StockLedger/UnitWiseLedger${params}`;
  
      let result = await http
        .get<IStockLedger[]>(url)
        .catch(error => Promise.reject(error));
      return result.data;
    };

    const updateProductStock = async (loginUserId:number, loginEmailId:string, productStock:IProductStock[]): Promise<IResponseModal> => {
      let params = '';
      params = concatParam(params,loginUserId,'loginUserId');
      params = concatParam(params,loginEmailId,'loginEmailId');
      let url: string = `api/ProductStock/StockUpdate${params}`;
  
      let result = await http
        .post<IResponseModal>(url,productStock)
        .catch(error => Promise.reject(error));
      return result.data;
    };
  
  const productApi = {
    getProductSchemes,
    getProductBarcodes,
    getProductStock,
    getUnitWiseProductStock,
    getUnitWiseProductStockLedger,
    getProducts,
    updateProductStock
  };
  
  export {productApi};
