import React, { ReactElement } from 'react';
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { ErrorComponent } from './error.component';
import { SpinnerComponent } from 'src/components/common/spinner.component';
import { RouteMapComponent } from './route-map.component';
import { DefaultMapZoom, MapKey } from 'src/app.constant';
import LeafletMap from 'src/leaflet-map/LeafletMap';
import { TrackingMapComponent } from './tracking-map.component';
import { OrdersNewReport } from 'src/pages/OrdersNewReport';

const render = (status: Status): ReactElement => {
    if (status === Status.FAILURE) return <ErrorComponent />;
    return <SpinnerComponent />;
  };
  
export const TrackingMapContainer = () => {
        return <Wrapper libraries={['drawing']} apiKey={MapKey} render={render}>
            <TrackingMapComponent zoom={DefaultMapZoom} center={{ lat: 29.386461717608718, lng: 71.68734480932993 }} />
        </Wrapper>;
}