import React from 'react';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import refreshFill from '@iconify/icons-eva/refresh-fill';

// material
import {
    Card,
    Table,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    Grid,
    Stack,
    Avatar,
    TextField,
    Checkbox,
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { UserListHead } from '../components/_dashboard/user';
//
import { LoadingButton } from '@mui/lab';
import { doCashApi } from '../http';
import { formattedDate, getCommaSeparatedIds, getCommaSeparatedString, getCommaSeparatedUnitUniqueIdsExceptAll, getDateOnly } from 'src/utility';

import { connect } from 'react-redux';
import { IApplicationState } from 'src/redux';
import { Audio as AudoSpin } from 'react-loader-spinner'
import { confirmAction } from 'src/utility/confirm.util';
import { IMessage } from 'src/interfaces/message.interface';
import { MessageComponent } from 'src/components/messages.component';
import { IUserDetail } from 'src/interfaces';
import { ISelectedUnitAccess } from 'src/interfaces/selected-unit-access.interface';
import { AllOptionUniqueId } from 'src/app.constant';
import { fCurrency } from 'src/utils/formatNumber';
import { NoUnitSelectedComponent } from 'src/components/common/no-unit-selected.component';
import { ICollectedCashDateWise } from 'src/interfaces/collected-cash-date-wise.interface';
import { ICashSubmissionHistory } from 'src/interfaces/cash-submission-history.interface';
import { ICashDetail } from 'src/interfaces/cash-detail.interface';
import Label from 'src/components/Label';


// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'sr', label: 'Sr.', alignContent: 'center' },
    { id: 'dated', label: 'Date', alignContent: 'center' },
    { id: 'do_amount', label: 'DO Amount', alignContent: 'center' },
    { id: 'credit_amount', label: 'Credit Amount', alignContent: 'center' },
    { id: 'total_amount', label: 'Total Amount', alignContent: 'center' },
];

const TABLE_HEAD_DEPOSITS = [
    { id: 'sr', label: 'Sr.', alignContent: 'center' },
    { id: 'dated', label: 'Date', alignContent: 'center' },
    { id: 'do_amount', label: 'DO Amount', alignContent: 'center' },
    { id: 'credit_amount', label: 'Credit Amount', alignContent: 'center' },
    { id: 'total_amount', label: 'Total Amount', alignContent: 'center' },
    { id: 'status', label: 'Status', alignContent: 'center' },
];

const TABLE_HEAD_PENDING_DEPOSITS = [
    { id: 'sr', label: 'Sr.', alignContent: 'center' },
    { id: 'dated', label: 'Date', alignContent: 'center' },
    { id: 'do_amount', label: 'DO Amount', alignContent: 'center' },
    { id: 'credit_amount', label: 'Credit Amount', alignContent: 'center' },
    { id: 'total_amount', label: 'Total Amount', alignContent: 'center' },
    { id: 'status', label: 'Request Status', alignContent: 'center' },
];

// ----------------------------------------------------------------------

interface ITotal {
    TotalCashAmount: number;
    TotalDoCashAmount: number;
    TotalCreditCashAmount: number;
    UnitUniqueId: string;
}

interface IViewPort{
    ShowCashInHandScreen:boolean;
    ShowPendingDepositsScreen:boolean;
    ShowDepositedScreen:boolean;
}

interface IProps {
    loginUser: IUserDetail | null;
    selectedUnits: ISelectedUnitAccess;
}

const CashDetailNew = (props: IProps) => {

    const initialCashDetail = {
        CoinOne: 0,
        CoinTwo: 0,
        CoinFive: 0,
        NoteTen: 0,
        NoteTwenty: 0,
        NoteFifty: 0,
        NoteHundred: 0,
        NoteFiveHundred: 0,
        NoteThousand: 0,
        NoteFiveThousand: 0,
        DOCashId: 0,
        Id: 0,
        RecordedBy: props.loginUser.UserId,
        RecordedOn: new Date(),
        TotalCashAmount: 0
    };

    const initialViewPort:IViewPort = {
        ShowCashInHandScreen:true,
        ShowDepositedScreen:false,
        ShowPendingDepositsScreen:false
    };

    const initialViewPortAllFalse:IViewPort = {
        ShowCashInHandScreen:false,
        ShowDepositedScreen:false,
        ShowPendingDepositsScreen:false
    };

    //#region state variables
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [cashInHand, setCashInHand] = React.useState<ICollectedCashDateWise[]>([]);
    const [pendingDeposits, setPendingDeposits] = React.useState<ICollectedCashDateWise[]>([]);
    const [deposits, setDeposits] = React.useState<ICollectedCashDateWise[]>([]);
    const [pageMessages, setPageMessages] = React.useState<IMessage[]>([]);
    const [cashInHandUnitWiseTotals, setCashInHandUnitWiseTotals] = React.useState<ITotal[]>([]);
    const [pendingDepositsUnitWiseTotals, setPendingDepositsUnitWiseTotals] = React.useState<ITotal[]>([]);
    const [depositsUnitWiseTotals, setDepositsUnitWiseTotals] = React.useState<ITotal[]>([]);
    const [selectedPendingDepositsUnitWiseTotals, setSelectedPendingDepositsUnitWiseTotals] = React.useState<ITotal[]>([]);

    const [viewPort,setViewPort] = React.useState<IViewPort>(initialViewPort);
    //#endregion

    //#region Util Methods
    const showErrorMessage = (message: string) => addMessage(message, 'error');
    const showInfoMessage = (message: string) => addMessage(message, 'info');
    const showSuccessMessage = (message: string) => addMessage(message, 'success');
    const showWarningMessage = (message: string) => addMessage(message, 'warning');

    const addMessage = (messageText: string, variant: 'success' | 'error' | 'info' | 'warning') => {
        if (!!messageText && messageText.trim() != '') {
            let msgs = [];
            msgs.push({
                messageText,
                variant
            });

            setPageMessages([...msgs]);
        }
    }

    const getCopy = (details: ICollectedCashDateWise[]) => {
        let detail = [...details.map(d => {
            d.DoCashIds = !!d.DoCashIds && d.DoCashIds.length > 0 ? ([...d.DoCashIds.map(c => c)]) : [];
            d.CreditReceiveIds = !!d.CreditReceiveIds && d.CreditReceiveIds.length > 0 ? [...d.CreditReceiveIds.map(cr => cr)] : [];
            return d;
        })];

        return detail;
    }

    const setTotalsOnLoad = (cashDetail:ICashDetail) =>{
        if(!!cashDetail){

            if(!!cashDetail.CashInHand && cashDetail.CashInHand.length > 0){
                let cashInHandUTotals:ITotal[] = [];
                cashDetail.CashInHand.forEach(cih => {
                    let cashInHandTotal:ITotal = cashInHandUTotals.find(m => m.UnitUniqueId == cih.UnitUniqueId);

                    if(!!cashInHandTotal){
                        cashInHandTotal.TotalDoCashAmount += cih.CollectedDoCashAmount;
                        cashInHandTotal.TotalCreditCashAmount += cih.CollectedCreditCashAmount;
                        cashInHandTotal.TotalCashAmount += (cih.CollectedDoCashAmount + cih.CollectedCreditCashAmount)
                    }else{
                        cashInHandUTotals.push({
                            TotalDoCashAmount : cih.CollectedDoCashAmount,
                            TotalCreditCashAmount : cih.CollectedCreditCashAmount,
                            TotalCashAmount : cih.CollectedDoCashAmount + cih.CollectedCreditCashAmount,
                            UnitUniqueId : cih.UnitUniqueId
                        });
                    }
                });

                setCashInHandUnitWiseTotals([...cashInHandUTotals.map(u => u)]);
            }

            if(!!cashDetail.Deposits && cashDetail.Deposits.length > 0){
                let depositsUTotals:ITotal[] = [];
                cashDetail.Deposits.forEach(d => {
                    let deposit = depositsUTotals.find(ud => ud.UnitUniqueId == d.UnitUniqueId);

                    if(!!deposit){
                        deposit.TotalDoCashAmount += d.CollectedDoCashAmount;
                        deposit.TotalCreditCashAmount += d.CollectedCreditCashAmount;
                        deposit.TotalCashAmount += (d.CollectedDoCashAmount + d.CollectedCreditCashAmount)
                    }else{
                        depositsUTotals.push({
                            TotalDoCashAmount : d.CollectedDoCashAmount,
                            TotalCreditCashAmount : d.CollectedCreditCashAmount,
                            TotalCashAmount : d.CollectedDoCashAmount + d.CollectedCreditCashAmount,
                            UnitUniqueId : d.UnitUniqueId
                        });
                    }
                });

                setDepositsUnitWiseTotals([...depositsUTotals.map(d => d)]);
            }

            if(!!cashDetail.PendingDeposits && cashDetail.PendingDeposits.length > 0){
                let pendingDepositUTotals:ITotal[] = [];
                cashDetail.PendingDeposits.forEach(pd => {
                    let pendingDepositTotal = pendingDepositUTotals.find(pdt => pdt.UnitUniqueId == pd.UnitUniqueId);

                    if(!!pendingDepositTotal){
                        pendingDepositTotal.TotalDoCashAmount += pd.CollectedDoCashAmount;
                        pendingDepositTotal.TotalCreditCashAmount += pd.CollectedCreditCashAmount;
                        pendingDepositTotal.TotalCashAmount += (pd.CollectedDoCashAmount + pd.CollectedCreditCashAmount)
                    }else{
                        pendingDepositUTotals.push({
                            TotalDoCashAmount : pd.CollectedDoCashAmount,
                            TotalCreditCashAmount : pd.CollectedCreditCashAmount,
                            TotalCashAmount : pd.CollectedDoCashAmount + pd.CollectedCreditCashAmount,
                            UnitUniqueId : pd.UnitUniqueId
                        });
                    }
                });

                setPendingDepositsUnitWiseTotals([...pendingDepositUTotals.map(pd => pd)]);
            }
        }else{
            setCashInHandUnitWiseTotals([]);
            setPendingDepositsUnitWiseTotals([]);
            setDepositsUnitWiseTotals([]);
        }
    }

    //#endregion

    //#region handlers
    const resetEverything = () => {
        setCashInHand([]);
        setCashInHandUnitWiseTotals([]);
        setPendingDeposits([]);
        setPendingDepositsUnitWiseTotals([]);
        setSelectedPendingDepositsUnitWiseTotals([]);
        setDeposits([]);
        setDepositsUnitWiseTotals([]);
        getCollectedCashDetail();
    }

    const setSelectedTotals = (detail:ICollectedCashDateWise[]) =>{
        let uWiseSelectedTotals:ITotal[] = [];

        if(!!detail && detail.length > 0){
            detail.filter(m => m.IsChecked == true).forEach(d => {
                let existingTotal = uWiseSelectedTotals.find(u => u.UnitUniqueId == d.UnitUniqueId);

                if(!!existingTotal){
                    existingTotal.TotalDoCashAmount += d.CollectedDoCashAmount;
                    existingTotal.TotalCreditCashAmount += d.CollectedCreditCashAmount;
                    existingTotal.TotalCashAmount += (d.CollectedDoCashAmount + d.CollectedCreditCashAmount)
                }else{
                    uWiseSelectedTotals.push({
                        TotalDoCashAmount : d.CollectedDoCashAmount,
                        TotalCreditCashAmount : d.CollectedCreditCashAmount,
                        TotalCashAmount : d.CollectedDoCashAmount + d.CollectedCreditCashAmount,
                        UnitUniqueId : d.UnitUniqueId
                    });
                }
            });
        }

        setSelectedPendingDepositsUnitWiseTotals([...uWiseSelectedTotals]);
    }

    const onRefresh = () => {
        setPageMessages([]);
        resetEverything();
    }

    const handleCheckboxChange = (unitUniqueId: string, dated: Date) => {
        setPageMessages([]);
        const copy = getCopy(pendingDeposits);
        const item = copy.find(m => m.UnitUniqueId == unitUniqueId && m.Dated == dated);
        item.IsChecked = !item.IsChecked;
        setSelectedTotals([...copy]);
        setPendingDeposits([...copy]);
    }

    const handleSelectAllClick = (unitUniqueId: string) => {
        setPageMessages([]);
        if (!!pendingDeposits && pendingDeposits.length > 0) {
            let shouldSelectAll = false;
            if ((pendingDeposits.filter(u => u.UnitUniqueId == unitUniqueId).length != pendingDeposits.filter(u => u.UnitUniqueId == unitUniqueId && u.IsChecked == true).length)) {
                shouldSelectAll = true;
            }

            const cashDetailCopy = getCopy(pendingDeposits);
            cashDetailCopy.filter(u => u.UnitUniqueId == unitUniqueId).forEach(cd => {
                cd.IsChecked = shouldSelectAll;
            });

            setSelectedTotals([...cashDetailCopy]);
            setPendingDeposits([...cashDetailCopy]);
        }
    }

    const handleMarkAsDeposited = (unitUniqueId: string) => {
        setPageMessages([]);
        let unitTitle = props.selectedUnits.UnitAccesses.find(u => u.UnitUniqueId == unitUniqueId)?.UnitTitle ?? '';
        let sTotal = selectedPendingDepositsUnitWiseTotals?.find(m => m.UnitUniqueId == unitUniqueId);

        if (!!pendingDeposits && pendingDeposits.length > 0 && pendingDeposits.filter(m => m.UnitUniqueId == unitUniqueId && m.IsChecked == true).length > 0) {
            let cashDetailForUnit = pendingDeposits.filter(m => m.UnitUniqueId == unitUniqueId && m.IsChecked);
            let doCashIds: number[] = [];
            let creditReceiveIds: number[] = [];

            cashDetailForUnit.forEach(cdu => {
                if (!!cdu.DoCashIds && cdu.DoCashIds.length > 0) {
                    cdu.DoCashIds.forEach(dci => {
                        if (doCashIds.findIndex(dc => dc == dci) < 0) {
                            doCashIds.push(dci);
                        }
                    });
                }

                if (!!cdu.CreditReceiveIds && cdu.CreditReceiveIds.length > 0) {
                    cdu.CreditReceiveIds.forEach(cri => {
                        if (creditReceiveIds.findIndex(i => i == cri) < 0) {
                            creditReceiveIds.push(cri);
                        }
                    });
                }
            });

            if (doCashIds.length > 0 || creditReceiveIds.length > 0) {

                confirmAction('Cash Request', `Are you sure you want to mark Amount ${fCurrency(sTotal?.TotalCashAmount ?? 0)} For ${unitTitle} as deposited?`, 'Yes', 'No')
                    .then(crn => {
                        if (crn == true) {
                            let commaSeparatedDoCashIds = getCommaSeparatedIds(doCashIds);
                            let commaSeparatedCreditReceiveIds = getCommaSeparatedIds(creditReceiveIds);
            
                            setIsLoading(true);
                            doCashApi.markAsDeposited(props.loginUser.UserId ?? 0, unitUniqueId, commaSeparatedDoCashIds, commaSeparatedCreditReceiveIds)
                            .then(res => {
                                showSuccessMessage(`Marked As Deposited Successfully For ${unitTitle}`);
                                setIsLoading(false);
                                resetEverything();
                            })
                            .catch(e => {
                                setIsLoading(false);
                                showErrorMessage(`Error while marking as deposit for ${unitTitle}`);
                            });
                        }
                    });
            } else {
                showErrorMessage(`Please Select Date To Mark As Deposited For ${unitTitle}`);
            }

        } else {
            showErrorMessage(`Please Select Date To Mark As Deposited For ${unitTitle}`);
        }
    }

    const onViewCashInHandScreen = () =>{
        setPageMessages([]);
        setViewPort({...initialViewPortAllFalse,ShowCashInHandScreen:true});
    }

    const onViewPendingDepositsScreen = () =>{
        setPageMessages([]);
        setViewPort({...initialViewPortAllFalse,ShowPendingDepositsScreen:true});
    }

    const onViewDepositedScreen = () => {
        setPageMessages([]);
        setViewPort({...initialViewPortAllFalse, ShowDepositedScreen:true});
    }

    //#endregion

    //#region Apis Call

    const getCollectedCashDetail = () => {
        setIsLoading(true);
        doCashApi.getCashDetailsForRequestAndConfirmationDateWise(props.loginUser.UserId, getCommaSeparatedUnitUniqueIdsExceptAll([...props.selectedUnits.UnitAccesses.map(a => a)]))
            .then(results => {
                setIsLoading(false);
                if (!!results) {
                    setTotalsOnLoad({...results});

                    if(!!results.PendingDeposits && results.PendingDeposits.length > 0){
                        // results.PendingDeposits.forEach(r => {
                        //     r.IsChecked = true;
                        // });
    
                        setPendingDeposits([...results.PendingDeposits]);
                        setSelectedTotals([...results.PendingDeposits]);
                    }else{
                        setPendingDeposits([]);
                        setSelectedTotals([]);
                    }

                    if(!!results.CashInHand && results.CashInHand.length > 0){

                        setCashInHand([...results.CashInHand]);
                    }else{
                        setCashInHand([]);
                    }

                    if(!!results.Deposits && results.Deposits.length > 0){
                        setDeposits([...results.Deposits]);
                    }else{
                        setDeposits([]);
                    }
                }
                else {
                    resetData();
                }
            })
            .catch(e => {
                console.log(e);
                setIsLoading(false);
            });
    }

    const resetData = () =>{
        setTotalsOnLoad(null);
                    setDeposits([]);
                    setPendingDeposits([]);
                    setCashInHand([]);
                    setSelectedTotals([]);
    }

    //#endregion

    //#region hooks
    React.useEffect(() => {

        if (!!props.selectedUnits.UnitAccesses && props.selectedUnits.UnitAccesses.length > 0) {
            getCollectedCashDetail();
            
        } else {
            resetData();
        }

    }, [props.selectedUnits.UnitAccesses]);

    //#endregion

    const selectedBackgroundColor = 'rgba(34, 154, 22, 0.72)';
    const normalBackgroundColor = 'blue';

    const TopButtons = () => {
        return <Grid item md={12} style={{ display:'flex',flexDirection: 'row', justifyContent: 'flex-end' }}>
        <LoadingButton
                size="small"
                type="button"
                variant="contained"
                loading={isLoading}
                style={{ alignSelf:'flex-end',margin: 3, backgroundColor: viewPort.ShowCashInHandScreen == true ? selectedBackgroundColor : normalBackgroundColor, textAlign: 'right', height: 40 }}
                startIcon={<Icon icon={'bi:cash'} />}
                onClick={onViewCashInHandScreen}
            >
                Cash In Hand
            </LoadingButton>
        <LoadingButton
                size="small"
                type="button"
                variant="contained"
                loading={isLoading}
                style={{ alignSelf:'flex-end',margin: 3, backgroundColor: viewPort.ShowPendingDepositsScreen == true ? selectedBackgroundColor : normalBackgroundColor, textAlign: 'right', height: 40 }}
                startIcon={<Icon icon={'carbon:face-pending'} />}
                onClick={onViewPendingDepositsScreen}
            >
                Pending Deposits
            </LoadingButton>
        <LoadingButton
                size="small"
                type="button"
                variant="contained"
                loading={isLoading}
                style={{ margin: 3, backgroundColor: viewPort.ShowDepositedScreen == true ? selectedBackgroundColor : normalBackgroundColor, textAlign: 'right',alignSelf:'flex-end', height: 40 }}
                startIcon={<Icon icon={'teenyicons:tick-circle-outline'} />}
                onClick={onViewDepositedScreen}
            >
                Deposits
            </LoadingButton>

            <LoadingButton
                size="small"
                type="button"
                variant="contained"
                loading={isLoading}
                style={{ alignSelf:'flex-end',margin: 3, backgroundColor: normalBackgroundColor, textAlign: 'right', height: 40 }}
                startIcon={<Icon icon={refreshFill} />}
                onClick={onRefresh}
            >
                Refresh
            </LoadingButton>
            </Grid>;
    }

    //#region Component Return
    return (
        (!!props.selectedUnits && !!props.selectedUnits.UnitAccesses && props.selectedUnits.UnitAccesses.filter(u => u.UnitUniqueId != AllOptionUniqueId).length > 0) ? <>
            <MessageComponent onRemove={(i: number) => {
                let msgs = [...pageMessages];
                msgs.splice(i, 1);
                setPageMessages([...msgs]);
            }} messages={pageMessages} />
            <Page title="Cash Detail | Raza Pak Care">
                {viewPort.ShowCashInHandScreen == true && <Container style={{ maxWidth: 1500, paddingLeft: 0, paddingRight: 0 }}>
                    <Grid container>
                        <TopButtons />
                    </Grid>
                    {!!props.selectedUnits && !!props.selectedUnits.UnitAccesses && props.selectedUnits.UnitAccesses.filter(m => m.UnitUniqueId != AllOptionUniqueId).length > 0 && props.selectedUnits.UnitAccesses.filter(m => m.UnitUniqueId != AllOptionUniqueId).map((selectedUnit, unitIndex) => {
                        return <><Card>
                            <Typography style={{ backgroundColor: selectedBackgroundColor, color: 'white', paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h4'}>{selectedUnit.UnitTitle} Cash In Hand Detail</Typography>
                            {isLoading == true && <AudoSpin
                                height="80"
                                width="80"
                                color='green'
                                ariaLabel='three-dots-loading'
                                wrapperStyle={{ alignSelf: 'center', justifyContent: 'center' }}
                            />}

                            {(!cashInHand || cashInHand.length <= 0 || cashInHand.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length <= 0) && isLoading == false && <Typography style={{ paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h6'}>No Cash In Hand For {selectedUnit.UnitTitle}</Typography>}

                            {!!cashInHand && cashInHand.length > 0 && cashInHand.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length > 0 && <Scrollbar sx={{}}>
                                <TableContainer sx={{ minWidth: 800, maxHeight:700 }}>
                                    <Table stickyHeader>
                                        <UserListHead
                                            order={'asc'}
                                            orderBy={''}
                                            headLabel={TABLE_HEAD}
                                            rowCount={0}
                                            numSelected={0}
                                            onRequestSort={undefined}
                                            customKey={`TableHeadRow_2`}
                                        />
                                        <TableBody>
                                            {!!cashInHand && cashInHand.length > 0 && cashInHand.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length > 0 && cashInHand.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).map((row, index) => {

                                                return (
                                                    <><TableRow
                                                        hover
                                                        key={`${row.UnitUniqueId}_${formattedDate(row.Dated, 'MM_DD_YYYY')}`}
                                                        tabIndex={-1}
                                                        role="checkbox"
                                                        selected={false}
                                                        aria-checked={false}
                                                    >
                                                        <TableCell align="center">{index + 1}</TableCell>
                                                        <TableCell align="center" component="th" scope="row" padding="none">
                                                            {formattedDate(row.Dated, 'DD-MMM-YYYY')}
                                                        </TableCell>
                                                        <TableCell align="center" component="th" scope="row" padding="none">
                                                            {fCurrency(row.CollectedDoCashAmount)}
                                                        </TableCell>
                                                        <TableCell align="center" component="th" scope="row" padding="none">
                                                            {fCurrency(row.CollectedCreditCashAmount)}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Typography variant="h6">{fCurrency(row.CollectedDoCashAmount + row.CollectedCreditCashAmount)}</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    </>
                                                );
                                            })}
                                            <TableRow>
                                                <TableCell style={{ borderBottom: 2, borderBottomStyle: 'solid', borderBottomColor: 'black' }} colSpan={2} align='right'>
                                                    <Typography variant='h6' style={{ textAlign: 'right' }}>Total:</Typography>
                                                </TableCell>
                                                <TableCell style={{ borderBottom: 2, borderBottomStyle: 'solid', borderBottomColor: 'black' }} align='center'><Typography style={{color:'rgba(34, 154, 22, 0.72)'}} variant='h6'>{fCurrency(cashInHandUnitWiseTotals?.find(m => m.UnitUniqueId == selectedUnit.UnitUniqueId)?.TotalDoCashAmount ?? 0)}</Typography></TableCell>
                                                <TableCell style={{ borderBottom: 2, borderBottomStyle: 'solid', borderBottomColor: 'black' }} align='center'><Typography style={{color:'rgba(34, 154, 22, 0.72)'}} variant='h6'>{fCurrency(cashInHandUnitWiseTotals?.find(m => m.UnitUniqueId == selectedUnit.UnitUniqueId)?.TotalCreditCashAmount ?? 0)}</Typography></TableCell>
                                                <TableCell style={{ borderBottom: 2, borderBottomStyle: 'solid', borderBottomColor: 'black' }} align='center'><Typography style={{color:'rgba(34, 154, 22, 0.72)'}} variant='h5'>{fCurrency(cashInHandUnitWiseTotals?.find(m => m.UnitUniqueId == selectedUnit.UnitUniqueId)?.TotalCashAmount ?? 0)}</Typography></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Scrollbar>}
                        </Card><br /></>
                    })}
                </Container>}






                {viewPort.ShowPendingDepositsScreen == true && <Container style={{ maxWidth: 1500, paddingLeft: 0, paddingRight: 0 }}>
                    <Grid container>
                        <TopButtons />
                    </Grid>
                    {!!props.selectedUnits && !!props.selectedUnits.UnitAccesses && props.selectedUnits.UnitAccesses.filter(m => m.UnitUniqueId != AllOptionUniqueId).length > 0 && props.selectedUnits.UnitAccesses.filter(m => m.UnitUniqueId != AllOptionUniqueId).map((selectedUnit, unitIndex) => {
                        return <><Card>
                            <Typography style={{ backgroundColor: selectedBackgroundColor, color: 'white', paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h4'}>{selectedUnit.UnitTitle} Pending Deposits</Typography>
                            {isLoading == true && <AudoSpin
                                height="80"
                                width="80"
                                color='green'
                                ariaLabel='three-dots-loading'
                                wrapperStyle={{ alignSelf: 'center', justifyContent: 'center' }}
                            />}

                            {(!pendingDeposits || pendingDeposits.length <= 0 || pendingDeposits.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length <= 0) && isLoading == false && <Typography style={{ paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h6'}>No Pending Deposits For {selectedUnit.UnitTitle}</Typography>}

                            {!!pendingDeposits && pendingDeposits.length > 0 && pendingDeposits.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length > 0 && <Scrollbar sx={{}}>
                                <TableContainer sx={{ minWidth: 800, maxHeight:700 }}>
                                    <Table stickyHeader>
                                        <UserListHead
                                            order={'asc'}
                                            orderBy={''}
                                            headLabel={TABLE_HEAD}
                                            rowCount={0}
                                            numSelected={0}
                                            onRequestSort={undefined}
                                            onSelectAllClick={() => {
                                                handleSelectAllClick(selectedUnit.UnitUniqueId)
                                            }}
                                            isAllSelected={!!pendingDeposits && pendingDeposits.length > 0 && pendingDeposits.filter(u => u.UnitUniqueId == selectedUnit.UnitUniqueId).length > 0 && (pendingDeposits.filter(u => u.UnitUniqueId == selectedUnit.UnitUniqueId).length == pendingDeposits.filter(u => u.UnitUniqueId == selectedUnit.UnitUniqueId && u.IsChecked == true).length)}
                                            showSelectAllCheckbox={true}
                                            customKey={`TableHeadRow_2`}
                                        />
                                        <TableBody>
                                            {!!pendingDeposits && pendingDeposits.length > 0 && pendingDeposits.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length > 0 && pendingDeposits.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).map((row, index) => {

                                                return (
                                                    <><TableRow
                                                        hover
                                                        key={`${row.UnitUniqueId}_${formattedDate(row.Dated, 'MM_DD_YYYY')}`}
                                                        tabIndex={-1}
                                                        role="checkbox"
                                                        selected={false}
                                                        aria-checked={false}
                                                    >
                                                        <TableCell width={10} align="right">
                                                            <Checkbox
                                                                checked={row.IsChecked == true}
                                                                onChange={(event) => handleCheckboxChange(row.UnitUniqueId, row.Dated)}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="center">{index + 1}</TableCell>
                                                        <TableCell align="center" component="th" scope="row" padding="none">
                                                            {formattedDate(row.Dated, 'DD-MMM-YYYY')}
                                                        </TableCell>
                                                        <TableCell align="center" component="th" scope="row" padding="none">
                                                            {fCurrency(row.CollectedDoCashAmount)}
                                                        </TableCell>
                                                        <TableCell align="center" component="th" scope="row" padding="none">
                                                            {fCurrency(row.CollectedCreditCashAmount)}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Typography variant="h6">{fCurrency(row.CollectedDoCashAmount + row.CollectedCreditCashAmount)}</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    </>
                                                );
                                            })}
                                            <TableRow>
                                                <TableCell style={{ borderBottom: 2, borderBottomStyle: 'solid', borderBottomColor: 'black' }} colSpan={3} align='right'>
                                                    <Typography variant='h6' style={{ textAlign: 'right' }}>Total:</Typography>
                                                </TableCell>
                                                <TableCell style={{ borderBottom: 2, borderBottomStyle: 'solid', borderBottomColor: 'black' }} align='center'><Typography style={{color:'rgba(34, 154, 22, 0.72)'}} variant='h6'>{fCurrency(pendingDepositsUnitWiseTotals?.find(m => m.UnitUniqueId == selectedUnit.UnitUniqueId)?.TotalDoCashAmount ?? 0)}</Typography></TableCell>
                                                <TableCell style={{ borderBottom: 2, borderBottomStyle: 'solid', borderBottomColor: 'black' }} align='center'><Typography style={{color:'rgba(34, 154, 22, 0.72)'}} variant='h6'>{fCurrency(pendingDepositsUnitWiseTotals?.find(m => m.UnitUniqueId == selectedUnit.UnitUniqueId)?.TotalCreditCashAmount ?? 0)}</Typography></TableCell>
                                                <TableCell style={{ borderBottom: 2, borderBottomStyle: 'solid', borderBottomColor: 'black' }} align='center'><Typography style={{color:'rgba(34, 154, 22, 0.72)'}} variant='h5'>{fCurrency(pendingDepositsUnitWiseTotals?.find(m => m.UnitUniqueId == selectedUnit.UnitUniqueId)?.TotalCashAmount ?? 0)}</Typography></TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell colSpan={6} align='center'>
                                                    <Typography variant='h4' style={{ textAlign: 'center', textDecoration: 'underline', color:'blue' }}>Selected Total Amount To Deposit For {selectedUnit.UnitTitle}</Typography>
                                                </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell colSpan={6} align='center'>
                                                    <Typography variant='h3' style={{ color: 'blue' }}>{fCurrency(selectedPendingDepositsUnitWiseTotals?.find(m => m.UnitUniqueId == selectedUnit.UnitUniqueId)?.TotalCashAmount ?? 0)}</Typography>
                                                </TableCell>
                                            </TableRow>

                                            {(selectedPendingDepositsUnitWiseTotals?.find(m => m.UnitUniqueId == selectedUnit.UnitUniqueId)?.TotalCashAmount ?? 0) > 0 && <TableRow>
                                                <TableCell colSpan={6} align='center'>
                                                    {<LoadingButton
                                                        size="small"
                                                        type="button"
                                                        variant="contained"
                                                        loading={isLoading}
                                                        style={{ margin: 3, backgroundColor: 'blue', textAlign: 'right', height: 40 }}
                                                        startIcon={<Icon icon={'emojione-monotone:white-heavy-check-mark'} />}
                                                        onClick={() => {
                                                            handleMarkAsDeposited(selectedUnit.UnitUniqueId);
                                                        }}
                                                    >
                                                        Mark As Deposited
                                                    </LoadingButton>}
                                                </TableCell>
                                            </TableRow>}

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Scrollbar>}
                        </Card><br /></>
                    })}
                </Container>}





                {viewPort.ShowDepositedScreen == true && <Container style={{ maxWidth: 1500, paddingLeft: 0, paddingRight: 0 }}>
                    <Grid container>
                        <TopButtons />
                    </Grid>
                    {!!props.selectedUnits && !!props.selectedUnits.UnitAccesses && props.selectedUnits.UnitAccesses.filter(m => m.UnitUniqueId != AllOptionUniqueId).length > 0 && props.selectedUnits.UnitAccesses.filter(m => m.UnitUniqueId != AllOptionUniqueId).map((selectedUnit, unitIndex) => {
                        return <><Card>
                            <Typography style={{ backgroundColor: selectedBackgroundColor, color: 'white', paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h4'}>{selectedUnit.UnitTitle} Deposits</Typography>
                            {isLoading == true && <AudoSpin
                                height="80"
                                width="80"
                                color='green'
                                ariaLabel='three-dots-loading'
                                wrapperStyle={{ alignSelf: 'center', justifyContent: 'center' }}
                            />}

                            {(!deposits || deposits.length <= 0 || deposits.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length <= 0) && isLoading == false && <Typography style={{ paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h6'}>No Deposits Or All Deposits Already Confirmed For {selectedUnit.UnitTitle}</Typography>}

                            {!!deposits && deposits.length > 0 && deposits.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length > 0 && <Scrollbar sx={{}}>
                                <TableContainer sx={{ minWidth: 800, maxHeight:700 }}>
                                    <Table stickyHeader>
                                        <UserListHead
                                            order={'asc'}
                                            orderBy={''}
                                            headLabel={TABLE_HEAD_DEPOSITS}
                                            rowCount={0}
                                            numSelected={0}
                                            onRequestSort={undefined}
                                            customKey={`TableHeadRow_2`}
                                        />
                                        <TableBody>
                                            {!!deposits && deposits.length > 0 && deposits.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length > 0 && deposits.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).map((row, index) => {

                                                return (
                                                    <><TableRow
                                                        hover
                                                        key={`${row.UnitUniqueId}_${formattedDate(row.Dated, 'MM_DD_YYYY')}`}
                                                        tabIndex={-1}
                                                        role="checkbox"
                                                        selected={false}
                                                        aria-checked={false}
                                                    >
                                                        <TableCell align="center">{index + 1}</TableCell>
                                                        <TableCell align="center" component="th" scope="row" padding="none">
                                                            {formattedDate(row.Dated, 'DD-MMM-YYYY')}
                                                        </TableCell>
                                                        <TableCell align="center" component="th" scope="row" padding="none">
                                                            {fCurrency(row.CollectedDoCashAmount)}
                                                        </TableCell>
                                                        <TableCell align="center" component="th" scope="row" padding="none">
                                                            {fCurrency(row.CollectedCreditCashAmount)}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Typography variant="h6">{fCurrency(row.CollectedDoCashAmount + row.CollectedCreditCashAmount)}</Typography>
                                                        </TableCell>
                                                        <TableCell width={30} align="center">
                                                        <Label
                                                            variant="ghost"
                                                            color={'info'}
                                                            >
                                                                Awaiting Confirmation
                                                            </Label>
                                                        </TableCell>
                                                    </TableRow>
                                                    </>
                                                );
                                            })}
                                            <TableRow>
                                                <TableCell colSpan={2} align='right'>
                                                    <Typography variant='h6' style={{ textAlign: 'right' }}>Total:</Typography>
                                                </TableCell>
                                                <TableCell align='center'><Typography style={{color:'rgba(34, 154, 22, 0.72)'}} variant='h6'>{fCurrency(depositsUnitWiseTotals?.find(m => m.UnitUniqueId == selectedUnit.UnitUniqueId)?.TotalDoCashAmount ?? 0)}</Typography></TableCell>
                                                <TableCell align='center'><Typography style={{color:'rgba(34, 154, 22, 0.72)'}} variant='h6'>{fCurrency(depositsUnitWiseTotals?.find(m => m.UnitUniqueId == selectedUnit.UnitUniqueId)?.TotalCreditCashAmount ?? 0)}</Typography></TableCell>
                                                <TableCell align='center'><Typography style={{color:'rgba(34, 154, 22, 0.72)'}} variant='h5'>{fCurrency(depositsUnitWiseTotals?.find(m => m.UnitUniqueId == selectedUnit.UnitUniqueId)?.TotalCashAmount ?? 0)}</Typography></TableCell>
                                                <TableCell>&nbsp;</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Scrollbar>}
                        </Card><br /></>
                    })}
                </Container>}




            </Page>
        </> : <NoUnitSelectedComponent pageTitle='Cash Detail | Raza Pak Care' />
    );

    //#endregion
}

const mapStateToProps = (state: IApplicationState) => {
    return {
        selectedUnits: state.selectedUnitAccesses,
        loginUser: state.loginUser
    };
}
const connectedCashDetailNew = connect(mapStateToProps)(CashDetailNew);
export { connectedCashDetailNew as CashDetailNew };
