import React from 'react';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import refreshFill from '@iconify/icons-eva/refresh-fill';
// material
import {
    Card,
    Table,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    Grid,
} from '@mui/material';
// components
import Page from '../Page';
import Scrollbar from '../Scrollbar';
import { UserListHead } from '../_dashboard/user';
//
import { LoadingButton } from '@mui/lab';
import { doCreditApi } from '../../http';
import { connect } from 'react-redux';
import { IApplicationState } from 'src/redux';
import { Audio as AudoSpin } from 'react-loader-spinner'
import Label from 'src/components/Label';
import { sentenceCase } from 'change-case';
import { IDOCredit } from 'src/interfaces/do-credit.interface';
import { formattedDate, generateUUID, getDaysDiff } from 'src/utility';
import { IUserDetail } from 'src/interfaces';
import { AllowedNumberOfDaysForRed, DateFormat } from 'src/app.constant';
import { fCurrency } from 'src/utils/formatNumber';
import { NoSelectionComponent } from '../common/NoSelectionComponent';


// ----------------------------------------------------------------------

const TABLE_HEAD_DO_Credit = [
    { id: 'sr', label: 'Sr.', alignContent: 'center' },
    { id: 'order_id', label: 'Slip#', alignContent: 'center' },
    { id: 'shop_name', label: 'Shop Name', alignContent: 'center' },
    { id: 'shop_address', label: 'Shop Address', alignContent: 'center' },
    { id: 'owner_cell', label: 'Owner Cell', alignContent: 'center' },
    { id: 'responsible_person', label: 'Responsible Person', alignContent: 'center' },
    { id: 'credited_on', label: 'Credited On', alignContent: 'center' },
    { id: 'total_amount', label: 'Total Amount', alignContent: 'center' },
    { id: 'received_amount', label: 'Received Amount', alignContent: 'center' },
    { id: 'pending_amount', label: 'Pending Amount', alignContent: 'center' },
    { id: 'credit_status', label: 'Credit Status', alignContent: 'center' },
];

interface IParam{
    EmployeeId:number;
    EmployeeName:string;
    UnitUniqueId:string;
    UnitTitle:string;
    FromDate:Date;
    ToDate:Date;
    IsViewingDetailForMonth:boolean;
}

interface ITotal{
    TotalAmount:number;
    ReceivedAmount:number;
    PendingAmount:number;
}
//#region interfaces
interface IProps {
    loginUser: IUserDetail | null;
    params:IParam;
    onBackClick:(monthStartDate:Date, employeeId:number, employeeName:string, unitUniqueId:string, unitTitle:string) => void;
}

//#endregion

const ShopWiseCreditDetail = (props: IProps) => {

    //#region state variables
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [credits, setCredits] = React.useState<IDOCredit[]>([]);
    const [totals,setTotals] = React.useState<ITotal>({
        PendingAmount:0,
        ReceivedAmount:0,
        TotalAmount:0
    });

    const orderBookerShopWiseCreditDetailKey = 'OrderBookerShopWiseCreditDetailKey';

    //#endregion

    //#region util methods start

    const setTotalsOnLoad = (crdts:IDOCredit[]) =>{
        let totalC:ITotal = {
            PendingAmount:0,
            ReceivedAmount:0,
            TotalAmount:0
        };

        if(!!crdts && crdts.length > 0){
            crdts.forEach(ec => {
                totalC.PendingAmount += ec.PendingAmount;
                totalC.TotalAmount += ec.TotalAmount;
                totalC.ReceivedAmount += ec.ReceivedAmount;
            });
        }

        setTotals(totalC);
    }

    const getColor = (creditedOn:Date) =>{
        let color = 'black';

        if(!!creditedOn){
            const numberOfDays = getDaysDiff(new Date(), creditedOn);
            if(numberOfDays >= AllowedNumberOfDaysForRed){
                color = 'red'
            }
        }

        return color;
    }

    const isCreditLated = (creditedOn:Date) =>{
        let isYes = false;

        if(!!creditedOn){
            const numberOfDays = getDaysDiff(new Date(), creditedOn);
            if(numberOfDays >= AllowedNumberOfDaysForRed){
                isYes = true
            }
        }

        return isYes;
    }
    //#endregion util methods end

    //#region handlers start

    const resetEverything = () => {
        setCredits([]);
        setTotals({
            PendingAmount:0,
            ReceivedAmount:0,
            TotalAmount:0
        });
    }

    const onRefresh = () =>{
        resetEverything();
        getCredits();
    }

    //#endregion handlers end

    //#region apis call start

    const getCredits = () => {
        setIsLoading(true);
        doCreditApi.getDoCredits(props.loginUser?.UserId, props.params.UnitUniqueId, props.params.EmployeeId,props.params.FromDate,props.params.ToDate)
            .then(results => {
                setIsLoading(false);
                if (!!results) {
                    setCredits([...results]);
                    setTotalsOnLoad([...results]);
                }
                else {
                    resetEverything();
                }
            })
            .catch(e => {
                console.log(e);
                setIsLoading(false);
            });
    }

    //#endregion apis calls end

    //#region hooks start
    React.useEffect(() => {
        if(!!props.params && !!props.params.FromDate && !!props.params.ToDate){
            getCredits();
        }else{
            resetEverything();
        }
    }, [props.params]);
    //#endregion hooks end

    return (
        (!!props.params && !!props.params.FromDate && !!props.params.ToDate) ? <Page key={`${orderBookerShopWiseCreditDetailKey}_1`} title="Shop Wise Credit Detail | Raza Pak Care">
            {<Container key={`${orderBookerShopWiseCreditDetailKey}_2`} style={{ maxWidth: 1500, paddingLeft: 0, paddingRight: 0 }}>
                <Grid key={`${orderBookerShopWiseCreditDetailKey}_3`} container style={{ flexDirection: 'row', marginBottom:5}}>
                    <Grid key={`${orderBookerShopWiseCreditDetailKey}_4`} xs={12} sm={12} style={{flexDirection:'row',display:'flex',justifyContent:'flex-end'}} item>
                    <LoadingButton
                        size="small"
                        type="button"
                        variant="contained"
                        loading={isLoading}
                        style={{alignSelf:'center',marginRight:5, backgroundColor: 'blue', textAlign: 'right', height: 40 }}
                        startIcon={<Icon icon={'eva:arrow-back-outline'} />}
                        onClick={() => {
                            const prm = props.params;
                            props.onBackClick(prm.FromDate, prm.EmployeeId, prm.EmployeeName, prm.UnitUniqueId, prm.UnitTitle);
                        }}
                        key={`${orderBookerShopWiseCreditDetailKey}_5`}
                    >
                        Back
                    </LoadingButton>

                    <LoadingButton
                        size="small"
                        type="button"
                        variant="contained"
                        loading={isLoading}
                        style={{alignSelf:'center', backgroundColor: 'rgba(34, 154, 22, 0.72)', textAlign: 'right', height: 40 }}
                        startIcon={<Icon icon={refreshFill} />}
                        onClick={() => {
                            onRefresh();
                        }}
                        key={`${orderBookerShopWiseCreditDetailKey}_6`}
                    >
                        Refresh
                    </LoadingButton>
                    </Grid>
                </Grid>


                <Card key={`${orderBookerShopWiseCreditDetailKey}_7`}>
                        <Typography key={`${orderBookerShopWiseCreditDetailKey}_8`} style={{ backgroundColor: 'rgba(34, 154, 22, 0.72)', color: 'white', paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h4'}>{props.params.UnitTitle} Shop Wise Credit Detail Of {props.params.EmployeeName} ({`${formattedDate(props.params.FromDate, DateFormat)} to ${formattedDate(props.params.ToDate, DateFormat)}`})</Typography>
                        {isLoading == true && <AudoSpin
                        key={`${orderBookerShopWiseCreditDetailKey}_9`}
                            height="80"
                            width="80"
                            color='green'
                            ariaLabel='three-dots-loading'
                            wrapperStyle={{ alignSelf: 'center', justifyContent: 'center' }}
                        />}

                        {(!credits || credits.length <= 0) && isLoading == false && <Typography key={`${orderBookerShopWiseCreditDetailKey}_10`} style={{ paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h6'}>{`No Shop Wise Credit Details Of ${props.params.EmployeeName} (${formattedDate(props.params.FromDate,DateFormat)} to ${formattedDate(props.params.ToDate,DateFormat)})`}</Typography>}

                        {!!credits && credits.length > 0 && <Scrollbar key={`${orderBookerShopWiseCreditDetailKey}_11`} sx={{}}>
                            <TableContainer key={`${orderBookerShopWiseCreditDetailKey}_12`} sx={{ minWidth: 800 }}>
                                <Table key={`${orderBookerShopWiseCreditDetailKey}_13`} size='small'>
                                    <UserListHead
                                        order={'asc'}
                                        orderBy={''}
                                        headLabel={TABLE_HEAD_DO_Credit}
                                        rowCount={0}
                                        numSelected={0}
                                        onRequestSort={undefined}
                                        onSelectAllClick={undefined}
                                        customKey={`${orderBookerShopWiseCreditDetailKey}_14`}
                                        key={`${orderBookerShopWiseCreditDetailKey}_15`}
                                    />
                                    <TableBody key={`${orderBookerShopWiseCreditDetailKey}_16`}>
                                        {!!credits && credits.length > 0 && credits.map((row, index) => {

                                            return (
                                                <TableRow
                                                    hover
                                                    key={`${orderBookerShopWiseCreditDetailKey}_${row.Id}_1`}
                                                    tabIndex={-1}
                                                    role="checkbox"
                                                    selected={false}
                                                    aria-checked={false}
                                                >
                                                    <TableCell key={`${orderBookerShopWiseCreditDetailKey}_${row.Id}_2`} style={{color:getColor(row.CreditedOn)}} align="center">{index + 1}</TableCell>
                                                    <TableCell key={`${orderBookerShopWiseCreditDetailKey}_${row.Id}_3`} style={{color:getColor(row.CreditedOn)}} align="center">{row.OrderId}</TableCell>
                                                    <TableCell key={`${orderBookerShopWiseCreditDetailKey}_${row.Id}_4`} style={{color:getColor(row.CreditedOn)}} align="center">{row.ShopName}</TableCell>
                                                    <TableCell key={`${orderBookerShopWiseCreditDetailKey}_${row.Id}_5`} style={{color:getColor(row.CreditedOn)}} align="center">{row.ShopAddress}</TableCell>
                                                    <TableCell key={`${orderBookerShopWiseCreditDetailKey}_${row.Id}_6`} style={{color:getColor(row.CreditedOn)}} align="center" component="th" scope="row" padding="none">
                                                        {`${!!row.OwnerCellNumber && row.OwnerCellNumber.trim() != '' ? `${row.OwnerCellNumber}` : 'N/A'}`}
                                                    </TableCell>
                                                    <TableCell key={`${orderBookerShopWiseCreditDetailKey}_${row.Id}_7`} style={{color:getColor(row.CreditedOn)}} align="center">{row.ResponsibleEmployeeName}</TableCell>

                                                    <TableCell key={`${orderBookerShopWiseCreditDetailKey}_${row.Id}_8`} style={{color:getColor(row.CreditedOn)}} align="center" component="th" scope="row" padding="none">
                                                        {formattedDate(row.CreditedOn, 'ddd DD-MM-YYYY')}
                                                    </TableCell>

                                                    <TableCell key={`${orderBookerShopWiseCreditDetailKey}_${row.Id}_9`} align="center" component="th" scope="row" padding="none">
                                                        <strong>{fCurrency(row.TotalAmount)}</strong>
                                                    </TableCell>

                                                    <TableCell key={`${orderBookerShopWiseCreditDetailKey}_${row.Id}_10`} align="center" component="th" scope="row" padding="none" style={{ color: 'green' }}>
                                                        <strong>{fCurrency(row.ReceivedAmount)}</strong>
                                                    </TableCell>

                                                    <TableCell key={`${orderBookerShopWiseCreditDetailKey}_${row.Id}_11`} align="center" component="th" scope="row" padding="none" style={{ color: 'red' }}>
                                                        <Typography variant='h6'>{fCurrency(row.PendingAmount)}</Typography>
                                                    </TableCell>

                                                    <TableCell key={`${orderBookerShopWiseCreditDetailKey}_${row.Id}_12`} align="center">
                                                        <Label
                                                        key={`${orderBookerShopWiseCreditDetailKey}_${row.Id}_13`}
                                                            variant="ghost"
                                                            color={row.CreditStatusId == 1 ? 'error' : (row.CreditStatusId == 2 ? 'info' : 'success')}
                                                        >
                                                            {sentenceCase(row.CreditStatus ?? '')}
                                                        </Label>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                        <TableRow key={`${orderBookerShopWiseCreditDetailKey}_14`}>
                                            <TableCell key={`${orderBookerShopWiseCreditDetailKey}_15`} colSpan={7} align='right'><h3>Total:</h3></TableCell>
                                            <TableCell key={`${orderBookerShopWiseCreditDetailKey}_16`} align='center'><h3>{fCurrency(totals.TotalAmount)}</h3></TableCell>
                                            <TableCell key={`${orderBookerShopWiseCreditDetailKey}_17`} align='center' style={{color:'green'}}><h3>{fCurrency(totals.ReceivedAmount)}</h3></TableCell>
                                            <TableCell key={`${orderBookerShopWiseCreditDetailKey}_18`} align='center' style={{color:'red'}}><h3>{fCurrency(totals.PendingAmount)}</h3></TableCell>
                                            <TableCell key={`${orderBookerShopWiseCreditDetailKey}_19`} colSpan={2}>&nbsp;</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Scrollbar>}
                    </Card>


            </Container>}
        </Page> : <NoSelectionComponent key={`${orderBookerShopWiseCreditDetailKey}_20`} pageTitle='Shop Wise Credit Detail | Raza Pak Care' message='Please Go Back And Click On Date To View Details.' />
    );
}

const mapStateToProps = (state: IApplicationState) => {
    return {
        loginUser: state.loginUser,
    };
}
const connectedShopWiseCreditDetail = connect(mapStateToProps)(ShopWiseCreditDetail);
export { connectedShopWiseCreditDetail as ShopWiseCreditDetail };
