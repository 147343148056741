import {IHaveId} from '../interfaces';
import {Buffer} from 'buffer';
import { AllOptionUniqueId } from 'src/app.constant';
import { IUnitAccess } from 'src/interfaces/unit-access.interface';
import {getPreciseDistance} from 'geolib';
import { cloneDeep } from 'lodash';

export const isNullOrEmpty = (obj: any) => {
  return !obj || Object.keys(obj).length <= 0;
};

export const isNullOrUndefined = (value: any): boolean => {
  return value === undefined || value == null;
};

export const isNullOrWhitespace = (
  value: string | null | undefined,
): boolean => {
  let isEmpty: boolean = false;
  if (!value || value.trim() == '') {
    isEmpty = true;
  }

  return isEmpty;
};

export const isNullOrZero = (value?: number): boolean => {
  return value === undefined || value == null || value == 0;
};

export const greaterThanZero = (value?: any): boolean => {
  return (
    value !== undefined &&
    value != null &&
    typeof value === 'number' &&
    Number(value) > 0
  );
};

export const concatParam = (
  params: string,
  value: any,
  paramName: string,
): string => {
  if (!isNullOrUndefined(value)) {
    params += isNullOrWhitespace(params) ? '?' : '&';
    params += paramName + '=' + value;
  }

  return String(params);
};

export const formatNumberUptoSevenDigit = (num: number): string => {
  let numStr = '';
  if (!isNullOrUndefined(num)) {
    numStr = num.toString();
  }

  let numLen = numStr.length;
  let remainingZeroLen = 7 - numLen;

  for (let index = remainingZeroLen; index > 0; index--) {
    numStr = '0' + numStr;
  }

  return numStr;
};

export const getClientDateTime = (utcDateTime: Date) => {
  if (utcDateTime !== undefined && utcDateTime != null) {
    let dated: Date = new Date(utcDateTime);
    let offset = dated.getTimezoneOffset() * -1;
    dated.setMinutes(dated.getMinutes() + offset);
    utcDateTime = dated;
  }

  return utcDateTime;
};

export const isSpecialCharacterExist = (value: string): boolean => {
  let regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  return regex.test(value);
};

export const hasNumber = (myString: string) => {
  return /\d/.test(myString);
};

export const isNumberExists = (str: string): boolean => {
  let isExists = false;
  let regex = /\d+/;

  if (!isNullOrWhitespace(str) && str.match(regex)) {
    isExists = true;
  }

  return isExists;
};

export const hasSpecialCharacters = (value: any) => {
  let regex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  return (
    !isNullOrUndefined(value) && !isNullOrWhitespace(value) && regex.test(value)
  );
};

export const isNumber = (value: any): boolean => {
  let regex = /^\d+$/;
  return (
    !isNullOrUndefined(value) && !isNullOrWhitespace(value) && regex.test(value)
  );
};

export const isValidURL = (url: string): boolean => {
  let isValid: boolean = false;

  if (url !== undefined && url != null && url.trim() != '') {
    let regex =
      /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
    isValid = regex.test(url);
  }

  return isValid;
};

export const isValidWebAddress = (webAddressString: string): boolean => {
  let isValid: boolean = false;

  if (!isNullOrWhitespace(webAddressString)) {
    let regex =
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
    isValid = regex.test(webAddressString);
  }

  return isValid;
};

export const isValidEmail = (emailString: string): boolean => {
  let isValid: boolean = false;

  if (!isNullOrWhitespace(emailString)) {
    emailString = emailString.trim();
    let regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    isValid = regex.test(String(emailString).toLowerCase());
  }

  return isValid;
};

export const hasValues = (items: any[]) => {
  return !isNullOrUndefined(items) && items.length > 0;
};

export const isNew = (item: IHaveId) => {
  return isNullOrZero(item.Id);
};

export const getMethods = (obj: any) =>
  Object.getOwnPropertyNames(obj).filter(
    item => typeof obj[item] === 'function',
  );

export const getPropertyNames = (obj: any) =>
  Object.getOwnPropertyNames(obj).filter(
    item => typeof obj[item] !== 'function',
  );

export const getKeys = (obj: any) => Object.keys(obj);

export const getQueryParams = (params: any) => {
  let queryParams = '';
  if (params) {
    let keys = getPropertyNames(params);
    if (keys && keys.length > 0) {
      keys.forEach(key => {
        if (key && !isNullOrUndefined(params[key])) {
          queryParams += `${
            isNullOrEmpty(queryParams) ? '?' : '&'
          }${key}=${encodeURIComponent(params[key])}`;
        }
      });
    }
  }

  return queryParams;
};

export const getChunks = <T extends any>(arr: T[], size: number) => {
  let newArray: T[][] = [];
  let i: number,
    j: number,
    temporary: T[],
    chunk: number = size;
  for (i = 0, j = arr.length; i < j; i += chunk) {
    temporary = arr.slice(i, i + chunk);
    newArray = [...newArray, temporary];
  }

  return newArray;
};

export const getCommaSeparatedString = (values: number[] | string[]) => {
  let returnValue: string = '';

  if (hasValues(values)) {
    values.forEach(value => {
      returnValue = `${returnValue}${
        isNullOrWhitespace(returnValue) ? '' : ','
      }${value}`;
    });
  }

  return returnValue;
};

export const getCommaSeparatedSelectedUnitsString = (unitAccesses:IUnitAccess[]) => {
  let returnValue: string = '';

  if (hasValues(unitAccesses)) {
    unitAccesses.forEach(value => {
      returnValue = `${returnValue}${
        isNullOrWhitespace(returnValue) ? '' : ','
      } ${value.UnitTitle}`;
    });
  }

  return returnValue;
};

export const getCommaSeparatedUnitUniqueIdsExceptAll = (unitAccesses:IUnitAccess[]) => {
  let returnValue: string = '';

  if (hasValues(unitAccesses)) {
    unitAccesses.filter(m => m.UnitUniqueId != AllOptionUniqueId).forEach(value => {
      returnValue = `${returnValue}${isNullOrWhitespace(returnValue) ? '' : ','}${value.UnitUniqueId}`;
    });
  }

  return returnValue;
};

export const getCommaSeparatedIds = (ids:number[]) => {
  let returnValue: string = '';

  if (hasValues(ids)) {
    ids.forEach(value => {
      returnValue = `${returnValue}${isNullOrWhitespace(returnValue) ? '' : ','}${value}`;
    });
  }

  return returnValue;
};

export const getSingleUnitUniqueId = (unitAccess:IUnitAccess[]) =>{
  let returnValue = '';

  if(!!unitAccess && unitAccess.length == 1 && unitAccess.findIndex(m => m.UnitUniqueId == AllOptionUniqueId) <= 0){
    returnValue = unitAccess[0].UnitUniqueId;
  }

  return returnValue;
}

export const isHtml = (value: string) => {
  return /<[a-z/][\s\S]*>/i.test(value);
};

export const getExtensionFromFileName = (fileName: string) => {
  if (!isNullOrWhitespace(fileName) && fileName.indexOf('.') >= 0) {
    const extension = fileName.substring(fileName.lastIndexOf('.'));
    return extension;
  }

  return '';
};

export const decodeBase64String = (base64String: string): string => {
  return Buffer.from(base64String, 'base64').toString();
};

export const encodeBase64String = (stringToEncode: string): string => {
  let buff = Buffer.from(stringToEncode);
  return buff.toString('base64');
};

export const getCommaSeparatedValues = <T extends {}>(toProcessArray:T[], keyForName:keyof T) =>{
  let toReturn = '';
  
  if(!!toProcessArray && toProcessArray.length > 0){
    toProcessArray.forEach(p => {
      const value = p[keyForName];
      toReturn += (toReturn == '' ? '' : ', ') + value;
    });
  }

  return toReturn;
}

export const generateUUID = () => { // Public Domain/MIT
  var d = new Date().getTime();//Timestamp
  var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16;//random number between 0 and 16
      if(d > 0){//Use timestamp until depleted
          r = (d + r)%16 | 0;
          d = Math.floor(d/16);
      } else {//Use microseconds since page-load if supported
          r = (d2 + r)%16 | 0;
          d2 = Math.floor(d2/16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}

export const getEmployeeId = (employeeId:number, unitId:number) =>{
  let empId = '';
  let empIdWithZeros = '';
  let areaIdWithZeros = '';

  if(unitId < 10){
    areaIdWithZeros = `0${unitId}`;
  }else{
    areaIdWithZeros = `${unitId}`;
  }

  if(employeeId < 10){
    empIdWithZeros = `000${employeeId}`;
  }else if(employeeId < 100){
    empIdWithZeros = `00${employeeId}`;
  }else if (employeeId < 1000){
    empIdWithZeros = `0${employeeId}`;
  }else{
    empIdWithZeros = `${employeeId}`;
  }

  empId = `EMP-${areaIdWithZeros}-${empIdWithZeros}`;

  return empId;
}

export const getDistanceInMeters = (lat: number, lng: number, lat1: number, lng1: number) => {
  const start = {
      latitude: lat,
      longitude: lng
  }

  const startPoint = {latitude: lat, longitude: lng};
  const endPoint = {latitude: lat1, longitude: lng1};

  const end = {
      latitude: lat1,
      longitude: lng1
  }

  const distance = getPreciseDistance(startPoint,endPoint,1);
  return distance;
}


function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {

  if(b[orderBy] === undefined || b[orderBy] == null){
    return -1;
  }

  if(a[orderBy] === undefined || a[orderBy] == null){
    return 1;
  }

  if(typeof(b[orderBy]) == 'string'){
    if (b[orderBy].toString().toLowerCase() < a[orderBy].toString().toLowerCase()) {
      return -1;
    }
    if (b[orderBy].toString().toLowerCase() > a[orderBy].toString().toLowerCase()) {
      return 1;
    }
    return 0;  
  }else{
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
}

function ascendingComparator<T>(a: T, b: T, orderBy: keyof T) {

  if(b[orderBy] === undefined || b[orderBy] == null){
    return -1;
  }

  if(a[orderBy] === undefined || a[orderBy] == null){
    return 1;
  }

  if(typeof(b[orderBy]) == 'string'){
    if (b[orderBy].toString().toLowerCase() < a[orderBy].toString().toLowerCase()) {
      return 1;
    }
    if (b[orderBy].toString().toLowerCase() > a[orderBy].toString().toLowerCase()) {
      return -1;
    }
    return 0;  
  }else{
    if (b[orderBy] < a[orderBy]) {
      return 1;
    }
    if (b[orderBy] > a[orderBy]) {
      return -1;
    }
    return 0;
  }
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string | Date | boolean },
  b: { [key in Key]: number | string | Date | boolean },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => ascendingComparator(a, b, orderBy);
}

export function stableSort<T>(array: readonly T[], order:Order, orderBy: keyof T) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  const comparator = getComparator(order, orderBy);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0] as any, b[0] as any);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

export const getCopy = <T>(data:T):T => {
  return cloneDeep(data);
}

// export const getCopy = <T extends {} | []>(data:T | T) : T =>{
//   if(!!data){
//     if(Array.isArray(data)){
//       if(!!data && data.length > 0){
//         const copy = data.map(a => {
//           if(!!a){
//             const names = getPropertyNames(a);
//             if(!!names && names.length > 0){
//               names.forEach(name => {
//                 if(Array.isArray(a[name]) || typeof a[name] == 'object'){
//                   a[name] = getCopy(a[name]);
//                 }
//               });
//             }
//           }
    
//           if(typeof a == 'object'){
//             return Object.assign({},a);
//           }else{
//             return a;
//           }
          
//         });
    
//         return copy as T;
//       }else{
//         return [] as T;
//       }
//     }else{
//       const dataObj = Object.assign({},data);
//       if(!!dataObj){
//         const names = getPropertyNames(dataObj);
//         if(!!names && names.length > 0){
//           names.forEach(name => {
//             if(Array.isArray(dataObj[name] || typeof dataObj[name] == 'object')){
//               dataObj[name] = getCopy(dataObj[name]);
//             }
//           });
//         }
//       }

//           return dataObj as T;
//     }
//   }else{
//     return data;
//   }
// }

export const isValidCellNumber = (value: any) => {
  let regex = /^[0]{1}[1-9]{1}[0-9]{9}$/gm;
  if (!!value && !regex.test(value)) {
    return false;
  } else {
    return true;
  }
}

export const isValidIMEI = (imei:string) =>
{
    if(!!imei && imei.trim() !== '' && isNumber(imei)){
      let len = imei.trim().length;
      let n = parseInt(imei.trim());
      if (len != 15)
          return false;
  
      let sum = 0;
      for(let i = len; i >= 1; i--)
      {
        let d = (n % 10);
  
        // Doubling every alternate digit
        if (i % 2 == 0)
            d = 2 * d;
  
        // Finding sum of the digits
        sum += sumDig(d);
        n = parseInt((n / 10).toString(), 10);
      }
  
      return (sum % 10 == 0);
    }else{
      return false;
    }
}

const sumDig = (n) =>
{
    let a = 0;
    while (n > 0)
    {
        a = a + n % 10;
        n = parseInt((n / 10).toString(), 10);
    }
    return a;
}

export const scrollToTop = () =>{
  window.scrollTo(0,0);
}

export const getDayName = (dayOfWeek:number) => {
  if(dayOfWeek === 1){
    return 'Sunday';
  }else if(dayOfWeek === 2){
    return 'Monday'
  }else if(dayOfWeek === 3){
    return 'Tuesday'
  }else if(dayOfWeek === 4){
    return 'Wednesday'
  }else if(dayOfWeek === 5){
    return 'Thursday'
  }else if(dayOfWeek === 6){
    return 'Friday'
  }else if(dayOfWeek === 7){
    return 'Saturday'
  }
}