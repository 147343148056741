import * as React from 'react';
import { TabControl } from 'src/components/common/TabControl';
import { CreditReceiveHistory } from './CreditReceiveHistory';
import { ReceiveCredit } from './ReceiveCredit';
import { generateUUID } from 'src/utility';
import { OrderBookerWiseCredit } from 'src/components/credit/OrderBookerWiseCredit';
import { TableContainer } from '@mui/material';
import useWindowDimensions from 'src/components/common/useWindowDimentions';
import { DateWiseCredit } from './DateWiseCredit';
import { IApplicationState } from 'src/redux';
import { connect } from 'react-redux';
import { IUserRouteDetail } from 'src/interfaces/routes/user-route-detail.interface';
import { getUrlFromComponentKey } from 'src/utility/navigation.util';
import { Keys } from 'src/component.keys';
import { CreditHistory } from './CreditHistory';

interface IProps{
    userRoutes:IUserRouteDetail;
}

const CreditNew = (props:IProps) => {
    const tabKeys = {
        ReceiveCredit: 'ReceiveCredit',
        CreditReceiveHistory: 'CreditReceiveHistory',
        OrderBookerWise:'OrderBookerWise',
        NewVsRecovered:'NewVsRecovered',
        CreditHistory:'CreditHistory'
    };
    const [tabs,setTabs] = React.useState<any[]>([{
        title: 'Credit',
        keyName: tabKeys.ReceiveCredit,
        order: 1
    },
    {
        title: 'Order Booker Credit',
        keyName: tabKeys.OrderBookerWise,
        order: 2
    }]);

    const creditNewKey = 'CreditNewParentComponentKey';

    const [dateWiseCreditPageUrl, setDateWiseCreditPageUrl] = React.useState<string>('');
    const [creditReceiveHistoryPageUrl, setCreditReceiveHistoryPageUrl] = React.useState<string>('');

    const [selectedTab, setSelectedTab] = React.useState<string>(tabKeys.ReceiveCredit);

    const handleChange = (newValue: string) => {
        setSelectedTab(newValue);
    };

    const dimensions = useWindowDimensions();

    React.useEffect(() =>{
        let editedTabs = [...tabs.map(a => a)];
        if(!!props.userRoutes && !!props.userRoutes.Routes && props.userRoutes.Routes.length > 0){
            let url = getUrlFromComponentKey(props.userRoutes,Keys.CreditReceiveHistory);
            if(!!url && url.trim() != ''){
                editedTabs.push({
                    title: 'Receive History',
                    keyName: tabKeys.CreditReceiveHistory,
                    order: 3
                })
            }

            url = getUrlFromComponentKey(props.userRoutes,Keys.NewCreditVsRecovered);

            if(!!url && url.trim() != ''){
                editedTabs.push({
                    title: 'Credit Report',
                    keyName: tabKeys.NewVsRecovered,
                    order: 5
                });
            }

            url = getUrlFromComponentKey(props.userRoutes,Keys.CreditHistory);

            if(!!url && url.trim() != ''){
                editedTabs.push({
                    title: 'History',
                    keyName: tabKeys.CreditHistory,
                    order: 4
                });
            }
        }

        setTabs([...editedTabs.map(a => a)]);
    },[props.userRoutes]);

    const TableContainerRef = React.useRef(null);
    const scrollToTop = () => {
        TableContainerRef.current.scrollTo(0,0);
    }

    return (
        <React.Fragment key={`${creditNewKey}_1`}>
            <TabControl key={`${creditNewKey}_2`} tabs={tabs} selected={selectedTab} onChange={handleChange} />
            <TableContainer key={`${creditNewKey}_3`} ref={TableContainerRef} style={{maxHeight:dimensions.height - 190}}>
                {selectedTab == tabKeys.ReceiveCredit && <ReceiveCredit scrollToTop={scrollToTop} key={`${creditNewKey}_4`} />}
                {selectedTab == tabKeys.CreditReceiveHistory && <CreditReceiveHistory key={`${creditNewKey}_5`} showBackButton={false} />}
                {selectedTab == tabKeys.OrderBookerWise && <OrderBookerWiseCredit key={`${creditNewKey}_6`} />}
                {selectedTab == tabKeys.NewVsRecovered && <DateWiseCredit key={`${creditNewKey}_7`} showBackButton={false} />}
                {selectedTab == tabKeys.CreditHistory && <CreditHistory showBackButton={false} key={`${creditNewKey}_8`} />}
            </TableContainer>
        </React.Fragment>
    );
}

const mapStateToProps = (state: IApplicationState) => {
    return {
        userRoutes:state.userRoutes
    };
}
const connectedCreditNew = connect(mapStateToProps)(CreditNew);
export { connectedCreditNew as CreditNew };
