import React from 'react';
import { LoadingButton } from '@mui/lab';
import { confirm } from "react-confirm-box";
import { store } from 'src/redux';
import { setAppConfirmationModalAction } from 'src/redux/actions/app-confirmation.action';

const scrollToTop = () =>{
  window.scrollTo(0,0);
}

// export const confirmAction = async (title?: string, message?: string, okText?:string, cancelText?:string): Promise<boolean> => {
//   scrollToTop();
//   const renderContent = (message, onConfirm, onCancel) => <div style={{ borderRadius: 5, backgroundColor: 'white', width: 400, minHeight: 100, position:'absolute',marginLeft:'auto',marginRight:'auto',top:'50%',left:'50%' }}>
//     <h3 style={{borderTopLeftRadius:5,borderTopRightRadius:5, backgroundColor: 'rgba(34, 154, 22, 0.72)', color: 'white', padding: 10, }}> {title ?? 'Confirmation'} </h3>
//     <h5 style={{ padding: 10, }}> {message ?? 'Are you sure?'} </h5>
//     <div style={{ textAlign: 'right', paddingBottom: 5, paddingRight: 5 }}><LoadingButton
//       size="small"
//       type="button"
//       variant="contained"
//       loading={false}
//       style={{ margin: 3, minWidth: 100 }}
//       onClick={onConfirm}
//     ><strong>{okText ?? 'Yes'}</strong></LoadingButton>
//       <LoadingButton
//         size="small"
//         type="button"
//         variant="contained"
//         loading={false}
//         style={{ margin: 3, width: 100, backgroundColor: 'red' }}
//         onClick={onCancel}
//       ><strong>{cancelText ?? 'No'}</strong></LoadingButton></div>
//   </div>

//   const opt = {
//     labels: {
//       confirmable: 'Yes',
//       cancellable: 'No'
//     },
//     render: renderContent as any,
//   }

//   const result = await confirm(message ?? 'Are you sure?', opt);

//   return result == true ? true : false;
// }


export const confirmAction = async (title?: string, message?: string, yesButtonText?:string, noButtonText?:string, yesButtonColor?:string, noButtonColor?:string, titleColor?:string, messageColor?:string, hideYesButton?:boolean, backdropColor?:string): Promise<boolean> => {
  let myPromise = new Promise<boolean>(function(resolve, reject) {
      store.dispatch(setAppConfirmationModalAction({
        title:title ?? '',
        message:message ?? '',
        yesButtonText:yesButtonText,
        noButtonText:noButtonText,
        yesButtonColor:yesButtonColor,
        noButtonColor:noButtonColor,
        titleColor:titleColor,
        messageColor:messageColor,
        backdropColor:backdropColor,
        hideYesButton:hideYesButton,
        onOk:(isOk:boolean) => {
          store.dispatch(setAppConfirmationModalAction(null));
          resolve(isOk);
        }
      }));
    });

    
  return myPromise;
}