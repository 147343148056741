import {IAction} from './action.interface';
import {NewOrderReportPrintSetting} from './action.constant';
import { INewOrderReportSetting } from 'src/interfaces/new-order-report-setting.interface';

export const setNewOrderReportPrintSettingAction = (
  payload: INewOrderReportSetting,
): IAction<INewOrderReportSetting> => {
  return {
    type: NewOrderReportPrintSetting.SET_NEW_ORDER_REPORT_PRINT_SETTING,
    payload,
  };
};
