import { Container, Typography, Chip } from '@mui/material';
import React from 'react';
import Page from '../Page';

interface IProps{
    pageTitle:string;
}
export const NoUnitSelectedComponent = (props:IProps) =>{
    return <Page title={props.pageTitle}>
    <Container style={{height:'100%', display:'flex',flex:1,flexDirection:'row',justifyContent:'center'}}>
            <Typography style={{alignSelf:'center',backgroundColor:'orange',opacity:0.5,color:'white',padding:20,marginTop:20,borderRadius:10}} variant='h2'>Please Select Unit From Left Panel</Typography>
    </Container>
  </Page>;
}