import {NewRouteSetupConstant} from '../../actions/action.constant';
import {IAction} from '../../actions/action.interface';
import {initialState} from '../../initial-state';

export const isLoadingRoutePoliesReducer = (
  state: boolean = initialState.isLoadingRoutePolies,
  action: IAction<boolean>,
) => {
  switch (action.type) {
    case NewRouteSetupConstant.SET_IS_LOADING_ROUTE_POLIES: {;
      return action.payload;
    }
    default:
      return state;
  }
};
