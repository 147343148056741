import {createStore, applyMiddleware} from 'redux';
import rootReducer from './reducers/root.reducer';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';
import {initialState} from './initial-state';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'root',
  storage:storage,
  whitelist: [
    'token',
    'loginUser',
    'userMenu',
    'userRoutes',
    'selectedUnitAccesses',
    'newOrderReportPrintSetting',
  ],
};

//need to remove this reduxImmutable state invariant before creating production build
//for app. Because this is just to check and ensure we are not mutating the state while using
//redux store.

const configureStore = (initialState: any) => {
  return createStore(
    persistReducer(persistConfig, rootReducer),
    initialState,
    applyMiddleware(thunk, reduxImmutableStateInvariant()),
  );
};

const store = configureStore(initialState);
const persistor = persistStore(store);
export {store, persistor};
