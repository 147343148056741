import React from 'react';
import { connect } from 'react-redux';
import { IUserDetail } from 'src/interfaces';
import { ISelectedUnitAccess } from 'src/interfaces/selected-unit-access.interface';
import { IApplicationState } from 'src/redux/application-state.interface';
import { OrderBookerOrdersComponent } from './order-booker-orders.component';
import { OrderBookerProductsComponent } from './order-booker-product-sale.component';
import { UnitOrdersComponent } from './unit-orders.component';

interface IViewPort {
    UnitWiseOrders: boolean;
    OrderBookerWiseOrders: boolean;
    OrderBookerOrders: boolean;
}

interface IProps{
    loginUser: IUserDetail | null;
    selectedUnits: ISelectedUnitAccess;
}

const UnitOrdersMain = (props:IProps) => {
    const initialViewPort: IViewPort = {
        UnitWiseOrders: true,
        OrderBookerWiseOrders:false,
        OrderBookerOrders:false
    };

    const viewPortAllFalse: IViewPort = {
        UnitWiseOrders: false,
        OrderBookerWiseOrders:false,
        OrderBookerOrders:false
    };

    const [viewPort, setViewPort] = React.useState<IViewPort>({ ...initialViewPort });
    const [unitWiseFromDate, setUnitWiseFromDate] = React.useState<Date>(new Date());
    const [unitWiseToDate, setUnitWiseToDate] = React.useState<Date>(new Date());
    const [orderBookerFromDate, setOrderBookerFromDate] = React.useState<Date>(new Date());
    const [orderBookerToDate, setOrderBookerToDate] = React.useState<Date>(new Date());
    const [employeeId, setEmployeeId] = React.useState<number>();
    const [employeeName, setEmployeeName] = React.useState<string>();
    const [selectedUnitUniqueId, setSelectedUnitUniqueId] = React.useState<string>();
    const [selectedUnitTitle, setSelectedUnitTitle] = React.useState<string>();
    const [showBackButtonOnOrderBookerDetail, setShowBackButtonOnOrderBookerDetail] = React.useState<boolean>(true);
    const [showProductWiseDetail,setShowProductWiseDetail] = React.useState<boolean>(true);
    // set dates on click

    const pageKey = 'UnitWiseOrdersParentComponentKey';

    const onUnitClick = (unitUniqueId:string, unitTitle:string, fromDate:Date, toDate:Date) =>{
        setUnitWiseFromDate(fromDate);
        setUnitWiseToDate(toDate);
        setOrderBookerFromDate(fromDate);
        setOrderBookerToDate(toDate);
        setSelectedUnitUniqueId(unitUniqueId);
        setSelectedUnitTitle(unitTitle);
        setViewPort({...viewPortAllFalse, OrderBookerWiseOrders:true});
    }

    const onOrderBookersBackClick = () => {
        setViewPort({...viewPortAllFalse, UnitWiseOrders:true});
    }

    const onOrderBookerClick = (employeeId:number, employeeName:string, fromDate:Date, toDate:Date) =>{
        setOrderBookerFromDate(fromDate);
        setOrderBookerToDate(toDate);
        setEmployeeId(employeeId);
        setEmployeeName(employeeName);
        setViewPort({...viewPortAllFalse, OrderBookerOrders:true});
    }

    const onProductWiseDetailBackClick = () => {
        setViewPort({...viewPortAllFalse, OrderBookerWiseOrders:true});
    }

    React.useEffect(() => {
        if(!!props.selectedUnits && !!props.selectedUnits.UnitAccesses && props.selectedUnits.UnitAccesses.length == 1){
            setShowBackButtonOnOrderBookerDetail(false);
            //setShowProductWiseDetail(false);
            const unit = props.selectedUnits.UnitAccesses[0];
            onUnitClick(unit.UnitUniqueId,unit.UnitTitle,new Date(), new Date());
        }else{
            setViewPort({...initialViewPort});
        }
    },[props.selectedUnits.UnitAccesses]);

    return <>
        {viewPort.UnitWiseOrders == true && <UnitOrdersComponent selectedUnits={props.selectedUnits} loginUser={props.loginUser} fromDate={unitWiseFromDate} toDate={unitWiseToDate} onUnitClick={onUnitClick} showProductWiseDetail={showProductWiseDetail} key={`${pageKey}_1`} />}
        {viewPort.OrderBookerWiseOrders == true && <OrderBookerOrdersComponent fromDate={orderBookerFromDate} toDate={orderBookerToDate} loginUser={props.loginUser} unitUniqueId={selectedUnitUniqueId} unitTitle={selectedUnitTitle} onBackClick={onOrderBookersBackClick} onOrderBookerClick={onOrderBookerClick} showBackButton={showBackButtonOnOrderBookerDetail} showProductWiseDetail={showProductWiseDetail} key={`${pageKey}_2`} />}
        {viewPort.OrderBookerOrders == true && <OrderBookerProductsComponent fromDate={orderBookerFromDate} toDate={orderBookerToDate} loginUser={props.loginUser} employeeId={employeeId} employeeName={employeeName} unitUniqueId={selectedUnitUniqueId} unitTitle={selectedUnitTitle} onBackClick={onProductWiseDetailBackClick} showBackButton={true} key={`${pageKey}_3`} />}
    </>
}

const mapStateToProps = (state: IApplicationState) => {
    return {
        selectedUnits: state.selectedUnitAccesses,
        loginUser: state.loginUser
    };
}
const connectedUnitOrdersMain = connect(mapStateToProps)(UnitOrdersMain);
export { connectedUnitOrdersMain as UnitOrdersMain };