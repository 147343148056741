import { IShopUpdateRequest } from 'src/interfaces/shop-update-request.interface';
import { IAutoComplete } from '../interfaces/auto-complete.interface';
import { http } from './http-base';
import { concatParam } from 'src/utility';
import { IRouteShopDetail } from 'src/interfaces/route-shop-detail.interface';
import { UnAssignedOrderBookerId } from 'src/app.constant';
import { IShopRequest } from 'src/interfaces/shop-request.interface';
import { ITabletInfo } from 'src/interfaces/tablet-info.interface';

const getTabletInfo = async (
    iMEIOne:string,
    iMEITwo:string
): Promise<ITabletInfo[]> => {
  let imeiTwoParam = '';
  if(!!iMEITwo && iMEITwo.trim() !== ''){
    imeiTwoParam = `&iMEITwo=${iMEITwo}`;
  }
  
  let url: string = `api/Tablet/TabletInfo?iMEIOne=${encodeURIComponent(iMEIOne ?? '')}${imeiTwoParam}`;
  console.log(url)
  let result = await http
    .get<ITabletInfo[]>(url)
    .catch(error => Promise.reject(error));
  return result.data;
};


const getUnitTabletInfo = async (
    loginUserId:number,
    unitUniqueId:string
): Promise<ITabletInfo[]> => {
  
  let url: string = `api/Tablet/UnitTabletInfo?loginUserId=${loginUserId}&unitUniqueId=${unitUniqueId}`;
  let result = await http
    .get<ITabletInfo[]>(url)
    .catch(error => Promise.reject(error));
  return result.data;
};

const addNewTablet = async (
  tabletInfo:ITabletInfo,
  loginUserId:number,
): Promise<ITabletInfo> => {

let url: string = `api/Tablet/AddNewTablet?loginUserId=${loginUserId}`;
let result = await http
  .post<ITabletInfo>(url, tabletInfo)
  .catch(error => Promise.reject(error));
return result.data;
};

const changeAssignment = async (
  employeeId:number,
  tabletId:number,
): Promise<number> => {

let url: string = `api/Tablet/ChangeAssignment?employeeId=${employeeId}&tabletId=${tabletId}`;
let result = await http
  .post<number>(url, null)
  .catch(error => Promise.reject(error));
return result.data;
};

const updateTabStatus = async (
  tabletId:number,
  newTabStatusId:number,
  comments:string,
  loginUserId:number
): Promise<number> => {

let url: string = `api/Tablet/UpdateTabStatus?tabletId=${tabletId}&newTabStatusId=${newTabStatusId}&comments=${encodeURIComponent(comments)}&loginUserId=${loginUserId}`;
let result = await http
  .post<number>(url, null)
  .catch(error => Promise.reject(error));
return result.data;
};

const unAssignTablet = async (
  tabletId:number,
  loginUserId:number
): Promise<number> => {

let url: string = `api/Tablet/UnAssignTablet?tabletId=${tabletId}&loginUserId=${loginUserId}`;
let result = await http
  .post<number>(url, null)
  .catch(error => Promise.reject(error));
return result.data;
};

const transfer = async (
  tabletId:number,
  toUnitUniqueId:string,
  loginUserId:number
): Promise<number> => {

let url: string = `api/Tablet/Transfer?tabletId=${tabletId}&toUnitUniqueId=${toUnitUniqueId}&loginUserId=${loginUserId}`;
let result = await http
  .post<number>(url, null)
  .catch(error => Promise.reject(error));
return result.data;
};

const deactivate = async (
  tabletId:number,
  loginUserId:number
): Promise<number> => {

let url: string = `api/Tablet/Delete?tabletId=${tabletId}&loginUserId=${loginUserId}`;
let result = await http
  .post<number>(url, null)
  .catch(error => Promise.reject(error));
return result.data;
};

const isTabletAlreadyExists = async (
  iMEIOne:string,
  iMEITwo:string
): Promise<boolean> => {
let imeiTwoParam = '';
if(!!iMEITwo && iMEITwo.trim() !== ''){
  imeiTwoParam = `&iMEITwo=${iMEITwo}`;
}

let url: string = `api/Tablet/IsTabletAlreadyExists?iMEIOne=${encodeURIComponent(iMEIOne ?? '')}${imeiTwoParam}`;
console.log(url)
let result = await http
  .get<boolean>(url)
  .catch(error => Promise.reject(error));
return result.data;
};

const tabletApi = {
    getTabletInfo,
    getUnitTabletInfo,
    addNewTablet,
    changeAssignment,
    updateTabStatus,
    unAssignTablet,
    transfer,
    deactivate,
    isTabletAlreadyExists
};

export { tabletApi };
