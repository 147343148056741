export const TokenActionConstants = {
  SET_TOKEN: 'SET_TOKEN',
};

export const LoginUserActionConstants = {
  SET_LOGIN_USER_DETAIL: 'SET_LOGIN_USER_DETAIL',
};

export const UserMenuActionConstants = {
  SET_USER_MENU:'SET_USER_MENU'
}

export const UserRoutesConstatns = {
  SET_USER_ROUTES:'SET_USER_ROUTES'
}

export const SelectedUnitAccessConstants = {
  SET_SELECTED_UNIT_ACCESS:'SET_SELECTED_UNIT_ACCESS'
}

export const OrderNewConstants = {
  SET_ORDER_NEW_DATA:'SET_ORDER_NEW_DATA'
};

export const NewOrderReportPrintSetting = {
  SET_NEW_ORDER_REPORT_PRINT_SETTING : "SET_NEW_ORDER_REPORT_PRINT_SETTING",
};

export const AppConfirmation = {
  SET_APP_CONFIRMATION_MODAL:"SET_APP_CONFIRMATION_MODAL"
}

export const OrderBookerInfoConstants = {
  SET_ORDER_BOOKER_INFO:'SET_ORDER_BOOKER_INFO',
  RESET_ORDER_BOOKER_INFO:'RESET_ORDER_BOOKER_INFO',
  SET_ORDER_BOOKER_INFO_LOADING:'SET_ORDER_BOOKER_INFO_LOADING',
  SET_ROUTE_ASSIGNMENT_SAVING:'SET_ROUTE_ASSIGNMENT_SAVING',
  SET_SELECTED_AREA_ID:'SET_SELECTED_AREA_ID'
}


export const NewRouteSetupConstant = {
  SET_SELECTED_ROUTE_TO_MODIFY:"SET_SELECTED_ROUTE_TO_MODIFY",
  SET_NEW_ROUTE_SETUP_PAGE_MESSAGE:"SET_NEW_ROUTE_SETUP_PAGE_MESSAGE",
  SET_CONFIRMATION_MESSAGE_POP_UP:"SET_CONFIRMATION_MESSAGE_POP_UP",
  SET_IS_SAVING_NEW_ROUTE:"SET_IS_SAVING_NEW_ROUTE",
  SET_SELECTED_ROUTE_ID_ON_MAP:'SET_SELECTED_ROUTE_ID_ON_MAP',
  SET_ROUTE_POLIES:'SET_ROUTE_POLIES',
  SET_IS_DELETING_ROUTE:'SET_IS_DELETING_ROUTE',
  SET_IS_LOADING_ROUTE_POLIES:'SET_IS_LOADING_ROUTE_POLIES',
  SET_IS_LOADING_EXISTING_SHOPS:'SET_IS_LOADING_EXISTING_SHOPS',
  SET_IS_LOADING_NEW_SHOPS:'SET_IS_LOADING_NEW_SHOPS',
  SET_IS_LOADIN_AREA_POLIES:'SET_IS_LOADING_AREA_POLIES',
  SET_AREA_EXISTING_SHOPS:'SET_AREA_EXISTING_SHOPS',
  SET_AREA_NEW_SHOPS:'SET_AREA_NEW_SHOPS',
  SET_AREA_POLIES:'SET_AREA_POLIES'
}