import React from 'react';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import refreshFill from '@iconify/icons-eva/refresh-fill';
// material
import {
    Card,
    Table,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    Grid,
    Checkbox,
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { UserListHead } from '../components/_dashboard/user';
//
import { LoadingButton } from '@mui/lab';
import { doCashApi, doCreditApi, employeeApi } from '../http';
import { defaultDateTimeTwelveHourFormat, formattedDate, generateUUID, getCommaSeparatedIds, getCommaSeparatedString, getCommaSeparatedUnitUniqueIdsExceptAll, getDateOnly } from 'src/utility';

import { useSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { IApplicationState } from 'src/redux';
import { Audio as AudoSpin } from 'react-loader-spinner';
import { confirmAction } from 'src/utility/confirm.util';
import { IMessage } from 'src/interfaces/message.interface';
import { MessageComponent } from 'src/components/messages.component';
import { IUserDetail } from 'src/interfaces';
import { ISelectedUnitAccess } from 'src/interfaces/selected-unit-access.interface';
import { AllOptionUniqueId, isRestrictedLogin, NumberOfPreviousMonth } from 'src/app.constant';
import { fCurrency } from 'src/utils/formatNumber';
import { NoUnitSelectedComponent } from 'src/components/common/no-unit-selected.component';
import { ICollectedCashDateWise } from 'src/interfaces/collected-cash-date-wise.interface';
import { ICashSubmissionHistory } from 'src/interfaces/cash-submission-history.interface';
import CustomDatePicker from 'src/components/common/CustomDatePicker';
import { ICashDetail } from 'src/interfaces/cash-detail.interface';
import Label from 'src/components/Label';
import { IDepositHistory } from 'src/interfaces/deposit-history.interface';
import { IDateWiseCredit } from 'src/interfaces/date-wise-credit.interface';
import { INewAndRecoveredCreditResult } from 'src/interfaces/new-and-recovered-credit-result.interface';
import { IAutoComplete } from 'src/interfaces/auto-complete.interface';
import { CustomAutoComplete } from 'src/components/common/CustomAutoComplete';
import { useNavigate } from "react-router-dom";
import { IUserRouteDetail } from 'src/interfaces/routes/user-route-detail.interface';
import { Keys } from 'src/component.keys';
import { getUrlFromComponentKey } from 'src/utility/navigation.util';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'unit', label: 'Unit', alignContent: 'center' },
    { id: 'dated', label: 'Date', alignContent: 'center' },
    { id: 'responsible_person', label: 'Responsible Person', alignContent: 'center' },
    { id: 't_new_credit', label: 'New Credit', alignContent: 'center' },
    { id: 'recovered_credit', label: 'Recovered Credit', alignContent: 'center' },
    { id: 'difference', label: 'Difference', alignContent: 'center' },
];

const TABLE_HEAD_DATE_WISE = [
    { id: 'unit', label: 'Unit', alignContent: 'center' },
    { id: 'dated', label: 'Date', alignContent: 'center' },
    { id: 't_new_credit', label: 'New Credit', alignContent: 'center' },
    { id: 'difference', label: 'Difference', alignContent: 'center' },
];

// ----------------------------------------------------------------------

interface IViewPort {
    ShowOverAllSummary: boolean;
    ShowUnitWiseSummary: boolean;
    ShowOrderBookerWiseDetail: boolean;
}

interface IProps {
    loginUser: IUserDetail | null;
    selectedUnits: ISelectedUnitAccess;
    showBackButton?:boolean;
}

const DateWiseCredit = (props: IProps) => {
    const navigate = useNavigate();
    const initialViewPort: IViewPort = {
        ShowOverAllSummary: false,
        ShowUnitWiseSummary: false,
        ShowOrderBookerWiseDetail: true
    };

    const initialViewPortAllFalse: IViewPort = {
        ShowOverAllSummary: false,
        ShowUnitWiseSummary: false,
        ShowOrderBookerWiseDetail: false
    };

    //#region state variables
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [orderBookers, setOrderBookers] = useState<IAutoComplete[]>([]);
    const [orderBookerId, setOrderBookerId] = useState<number | undefined>(undefined);
    const [creditDetails, setCreditDetails] = React.useState<INewAndRecoveredCreditResult>();

    const [pageMessages, setPageMessages] = React.useState<IMessage[]>([]);
    const [viewPort, setViewPort] = React.useState<IViewPort>(initialViewPort);
    const [fromDate, setFromDate] = React.useState<Date>(new Date());
    const [toDate, setToDate] = React.useState<Date>(new Date());
    const [isHistoryLoadedAfterLastChange, setIsHistoryLoadedAfterLastChange] = React.useState<boolean>(false);
    const tDated = new Date();
    //#endregion

    //#region Util Methods
    const showErrorMessage = (message: string) => addMessage(message, 'error');
    const showInfoMessage = (message: string) => addMessage(message, 'info');
    const showSuccessMessage = (message: string) => addMessage(message, 'success');
    const showWarningMessage = (message: string) => addMessage(message, 'warning');

    const addMessage = (messageText: string, variant: 'success' | 'error' | 'info' | 'warning') => {
        if (!!messageText && messageText.trim() != '') {
            let msgs = [];
            msgs.push({
                messageText,
                variant
            });

            setPageMessages([...msgs]);
        }
    }

    //#endregion

    //#region handlers
    const resetEverythingAndGetData = () => {
        resetEverything();
        getNewAndRecoveredCreditHistory(orderBookerId);
    }

    const resetEverything = () => {
        setCreditDetails(null);
    }

    const onRefresh = () => {
        setPageMessages([]);
        resetEverythingAndGetData();
    }

    const onViewOrderBookerWiseDetailScreen = () => {
        setPageMessages([]);
        setViewPort({ ...initialViewPortAllFalse, ShowOrderBookerWiseDetail: true });
    }

    const onViewOverallSummaryScreen = () => {
        setPageMessages([]);
        setViewPort({ ...initialViewPortAllFalse, ShowOverAllSummary: true });
    }

    const onViewUnitWiseSummaryScreen = () => {
        setPageMessages([]);
        setViewPort({ ...initialViewPortAllFalse, ShowUnitWiseSummary: true });
    }

    //#endregion

    //#region Apis Call

    const getNewAndRecoveredCreditHistory = (obId:number | null) => {

        if (fromDate === undefined || fromDate == null) {
            showErrorMessage('Please Select From Date');
            return;
        }

        if (toDate === undefined || toDate == null) {
            showErrorMessage('Please Select To Date');
            return;
        }

        if (getDateOnly(fromDate) > getDateOnly(toDate)) {
            showErrorMessage(`From Date Can't be Greater Than To Date`);
            return;
        }

        //put check on order booker here

        setIsLoading(true);
        resetEverything();
        setIsHistoryLoadedAfterLastChange(true);
        doCreditApi.getNewAndRecoveredCredit(props.loginUser.UserId, getCommaSeparatedUnitUniqueIdsExceptAll([...props.selectedUnits.UnitAccesses.map(a => a)]), fromDate, toDate, (!!obId && obId > 0 ? obId : null))
            .then(results => {
                setIsLoading(false);
                setCreditDetails({ ...results });
            })
            .catch(e => {
                console.log(e);
                setIsLoading(false);
            });
    }

    const getOrderBookers = () => {
        employeeApi.getOrderBookers(props.loginUser.UserId ?? 0, getCommaSeparatedUnitUniqueIdsExceptAll(props.selectedUnits?.UnitAccesses ?? []))
        .then(bookers => {
          setOrderBookers([...bookers]);
        })
        .catch(e => console.log(e));
      };

      const getUnitOrderBookers = (unitUniqueid:string) => {
        employeeApi.getUnitOrderBookers(unitUniqueid)
        .then(bookers => {
          setOrderBookers([...bookers]);
        })
        .catch(e => console.log(e));
      };


    const onFromDateChange = (e: Date) => {
        resetEverything();
        setIsHistoryLoadedAfterLastChange(false);
        setFromDate(e);
    }
    const onToDateChange = (e: Date) => {
        resetEverything();
        setIsHistoryLoadedAfterLastChange(false);
        setToDate(e);
    }

    const onOrderBookerChange = (e:IAutoComplete) =>{
        resetEverything();
        setIsHistoryLoadedAfterLastChange(false);

        if(!!e){
          setOrderBookerId(e.Value);
        }else{
          setOrderBookerId(undefined);
        }
      }

      const goBack = () =>{
        navigate(-1);
      }

    //#endregion

    //#region hooks
    React.useEffect(() => {

        if(!!props.selectedUnits && !!props.selectedUnits.UnitAccesses && props.selectedUnits.UnitAccesses.length > 0 && props.selectedUnits.UnitAccesses.filter(u => u.UnitUniqueId != AllOptionUniqueId).length > 0){

            if(props.selectedUnits.UnitAccesses.filter(m => m.UnitUniqueId != AllOptionUniqueId).length == 1){
                const unitUniqueId = props.selectedUnits.UnitAccesses.filter(m => m.UnitUniqueId != AllOptionUniqueId)[0].UnitUniqueId;
                getUnitOrderBookers(unitUniqueId);
            }else{
                getOrderBookers();
            }

            setOrderBookerId(null);
            
            if (fromDate !== undefined && fromDate != null && toDate !== undefined && toDate != null) {
                resetEverything();
                getNewAndRecoveredCreditHistory(null);
            }
        }else{
            resetEverything();
            setOrderBookerId(null);
            setOrderBookers([]);
        }

    }, [props.selectedUnits.UnitAccesses]);

    //#endregion

    const selectedBackgroundColor = 'blue';
    const normalBackgroundColor = 'rgba(34, 154, 22, 0.72)';
    const newVsRecoveredCreditkey = 'NewVsRecoveredCreditKey';

    const TopButtons = (buttonsProps: any) => {
        return <Grid key={`${newVsRecoveredCreditkey}_1`} item md={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            <LoadingButton
                size="small"
                type="button"
                variant="contained"
                loading={isLoading}
                style={{ alignSelf: 'flex-end', margin: 3, backgroundColor: viewPort.ShowOrderBookerWiseDetail == true ? selectedBackgroundColor : normalBackgroundColor, textAlign: 'right', height: 40 }}
                startIcon={<Icon icon={'fluent:apps-list-detail-24-regular'} />}
                onClick={onViewOrderBookerWiseDetailScreen}
                key={`${newVsRecoveredCreditkey}_2`}
            >
                Order Booker Wise
            </LoadingButton>
            <LoadingButton
                size="small"
                type="button"
                variant="contained"
                loading={isLoading}
                style={{ alignSelf: 'flex-end', margin: 3, backgroundColor: viewPort.ShowUnitWiseSummary == true ? selectedBackgroundColor : normalBackgroundColor, textAlign: 'right', height: 40 }}
                startIcon={<Icon icon={'ooui:text-summary-ltr'} />}
                onClick={onViewUnitWiseSummaryScreen}
                key={`${newVsRecoveredCreditkey}_3`}
            >
                Unit Wise
            </LoadingButton>
            {/* <LoadingButton
                size="small"
                type="button"
                variant="contained"
                loading={isLoading}
                style={{ margin: 3, backgroundColor: viewPort.ShowOverAllSummary == true ? selectedBackgroundColor : normalBackgroundColor, textAlign: 'right', alignSelf: 'flex-end', height: 40 }}
                startIcon={<Icon icon={'teenyicons:tick-circle-outline'} />}
                onClick={onViewOverallSummaryScreen}
            >
                Overall
            </LoadingButton> */}

            <LoadingButton
                size="small"
                type="button"
                variant="contained"
                loading={isLoading}
                style={{ alignSelf: 'flex-end', margin: 3, backgroundColor: normalBackgroundColor, textAlign: 'right', height: 40 }}
                startIcon={<Icon icon={refreshFill} />}
                onClick={buttonsProps.onRefresh}
                key={`${newVsRecoveredCreditkey}_4`}
            >
                Refresh
            </LoadingButton>
            {(props.showBackButton === undefined || props.showBackButton == true) && <LoadingButton
                size="small"
                type="button"
                variant="contained"
                loading={isLoading}
                style={{ alignSelf: 'flex-end', margin: 3, backgroundColor: 'black', textAlign: 'right', height: 40 }}
                startIcon={<Icon icon={'akar-icons:arrow-back'} />}
                onClick={goBack}
                key={`${newVsRecoveredCreditkey}_5`}
            >
                Back
            </LoadingButton>}
        </Grid>;
    }

    //#region Component Return
    return (
        (!!props.selectedUnits && !!props.selectedUnits.UnitAccesses && props.selectedUnits.UnitAccesses.filter(u => u.UnitUniqueId != AllOptionUniqueId).length > 0) ? <React.Fragment key={`${newVsRecoveredCreditkey}_7`}>
            <MessageComponent key={`${newVsRecoveredCreditkey}_6`} onRemove={(i: number) => {
                let msgs = [...pageMessages];
                msgs.splice(i, 1);
                setPageMessages([...msgs]);
            }} messages={pageMessages} />
            <Page key={`${newVsRecoveredCreditkey}_8`} title="Cash Detail | Raza Pak Care">

                <Container key={`${newVsRecoveredCreditkey}_9`} style={{ paddingTop: 20, paddingBottom: 10 }}>
                    <Grid key={`${newVsRecoveredCreditkey}_10`} container spacing={2}>
                    <Grid key={`${newVsRecoveredCreditkey}_11`} item md={4} sm={12}><CustomAutoComplete<IAutoComplete>
                Id={'Order_Booker_List'}
                Value={!!orderBookers && orderBookers.length > 0 && orderBookerId > 0 ? orderBookers.filter(m => m.Value == orderBookerId)[0] : null}
                Options={orderBookers}
                displayField={'DisplayText'}
                valueField={'Value'}
                Label={'Order Booker'}
                onChange={onOrderBookerChange}
                key={`${newVsRecoveredCreditkey}_12`}
                /></Grid>
                        <Grid key={`${newVsRecoveredCreditkey}_13`} item md={4} sm={12}>
                            <CustomDatePicker
                                label='From Date'
                                value={fromDate}
                                inputDisable={true}
                                readonly={isLoading}
                                onChange={onFromDateChange}
                                key={`${newVsRecoveredCreditkey}_14`}
                                minDate={isRestrictedLogin(props.loginUser.UserId) ? (new Date(tDated.getFullYear(), tDated.getMonth() - NumberOfPreviousMonth, 1)) : undefined}
                            />
                        </Grid>

                        <Grid key={`${newVsRecoveredCreditkey}_15`} item md={4} sm={12}>
                            <CustomDatePicker
                                label='To Date'
                                value={toDate}
                                inputDisable={true}
                                readonly={isLoading}
                                onChange={onToDateChange}
                                key={`${newVsRecoveredCreditkey}_16`}
                                minDate={isRestrictedLogin(props.loginUser.UserId) ? (new Date(tDated.getFullYear(), tDated.getMonth() - NumberOfPreviousMonth, 1)) : undefined}
                            />
                        </Grid>

                        <Grid key={`${newVsRecoveredCreditkey}_17`} item xs={12} style={{ justifyContent: 'center', display: 'flex' }}>
                            <LoadingButton
                                size="small"
                                type="button"
                                variant="contained"
                                loading={isLoading}
                                style={{ alignSelf: 'center', margin: 3, backgroundColor: normalBackgroundColor, textAlign: 'right', height: 40 }}
                                startIcon={<Icon icon={'bi:clock-history'} />}
                                onClick={resetEverythingAndGetData}
                                key={`${newVsRecoveredCreditkey}_18`}
                            >
                                Get Detail
                            </LoadingButton>
                        </Grid>

                    </Grid>
                </Container>

                {isHistoryLoadedAfterLastChange == true && <Container key={`${newVsRecoveredCreditkey}_19`} style={{ maxWidth: 1500, paddingLeft: 0, paddingRight: 0 }}><Grid key={`${newVsRecoveredCreditkey}_20`} container>
                        <Grid key={`${newVsRecoveredCreditkey}_21`} item md={3}>
                            <Typography key={`${newVsRecoveredCreditkey}_22`} style={{marginLeft:10,textDecoration:'underline', alignSelf:'center', color:'rgba(34, 154, 22, 0.72)'}} variant='subtitle2'>Credit {!!fromDate && !!toDate && isHistoryLoadedAfterLastChange == true ? `(${formattedDate(fromDate,'DD-MMM-YYYY')} to ${formattedDate(toDate,'DD-MMM-YYYY')})` : ''}</Typography>
                            <Typography key={`${newVsRecoveredCreditkey}_23`} style={{marginLeft:10,marginRight:10, alignSelf:'center', color:'red'}} variant='subtitle2'>New Credit: {!!fromDate && !!toDate && isHistoryLoadedAfterLastChange == true ? fCurrency(creditDetails?.NewAndRecoveredCreditSummary?.NewCredit ?? 0) : 'N/A'}</Typography>
                            <Typography key={`${newVsRecoveredCreditkey}_24`} style={{marginLeft:10,marginRight:10, alignSelf:'center', color:'rgba(34, 154, 22, 0.72)'}} variant='subtitle2'>Recovered Credit: {!!fromDate && !!toDate && isHistoryLoadedAfterLastChange == true ? fCurrency(creditDetails?.NewAndRecoveredCreditSummary?.RecoveredCredit ?? 0) : 'N/A'}</Typography>
                            <Typography key={`${newVsRecoveredCreditkey}_25`} style={{marginLeft:10,marginRight:10, alignSelf:'center', color:(creditDetails?.NewAndRecoveredCreditSummary?.RecoveredCredit ?? 0) - (creditDetails?.NewAndRecoveredCreditSummary?.NewCredit ?? 0) > 0 ? 'green' : 'red'}} variant='subtitle2'>Difference: {!!fromDate && !!toDate && isHistoryLoadedAfterLastChange == true ? fCurrency((creditDetails?.NewAndRecoveredCreditSummary?.RecoveredCredit ?? 0) - (creditDetails?.NewAndRecoveredCreditSummary?.NewCredit ?? 0)) : 'N/A'}</Typography>
                        </Grid>
                        <Grid key={`${newVsRecoveredCreditkey}_26`} item md={9} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                            <TopButtons key={`${newVsRecoveredCreditkey}_27`} onRefresh={onRefresh} />
                        </Grid>
                </Grid></Container>}

                {viewPort.ShowOrderBookerWiseDetail == true && <Container key={`${newVsRecoveredCreditkey}_28`} style={{ maxWidth: 1500, paddingLeft: 0, paddingRight: 0 }}>
                    {!!props.selectedUnits && !!props.selectedUnits.UnitAccesses && props.selectedUnits.UnitAccesses.filter(m => m.UnitUniqueId != AllOptionUniqueId).length > 0 && props.selectedUnits.UnitAccesses.filter(m => m.UnitUniqueId != AllOptionUniqueId).map((selectedUnit, unitIndex) => {
                        return <React.Fragment key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_1`}><Card key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_2`}>
                            {!!fromDate && !!toDate && isHistoryLoadedAfterLastChange == true && <Typography key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_3`} style={{ backgroundColor: selectedBackgroundColor, color: 'white', paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h4'}>{selectedUnit.UnitTitle} New Vs Recovered Credit Detail ({`${formattedDate(fromDate, 'DD-MMM-YYYY')} to ${formattedDate(toDate, 'DD-MMM-YYYY')}`})</Typography>}
                            {isLoading == true && <AudoSpin
                                height="80"
                                width="80"
                                color='green'
                                ariaLabel='three-dots-loading'
                                wrapperStyle={{ alignSelf: 'center', justifyContent: 'center' }}
                                key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_4`}
                            />}

                            {(!creditDetails || !creditDetails.UnitAndDateWiseCredits || creditDetails.UnitAndDateWiseCredits.length <= 0 || creditDetails.UnitAndDateWiseCredits.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length <= 0) && !!fromDate && !!toDate && isHistoryLoadedAfterLastChange == true && isLoading == false && <Typography key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_5`} style={{ paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h6'}>No Details For {selectedUnit.UnitTitle} ({`${formattedDate(fromDate, 'DD-MMM-YYYY')} to ${formattedDate(toDate, 'DD-MMM-YYYY')}`})</Typography>}

                            {!!creditDetails && !!creditDetails.UnitAndDateWiseCredits && creditDetails.UnitAndDateWiseCredits.length > 0 && creditDetails.UnitAndDateWiseCredits.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length > 0 && <Scrollbar key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_6`} sx={{}}>
                                <TableContainer key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_7`} sx={{ minWidth: 800 }}>
                                    <Table key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_8`} stickyHeader>
                                        <UserListHead
                                            order={'asc'}
                                            orderBy={''}
                                            headLabel={TABLE_HEAD}
                                            rowCount={0}
                                            numSelected={0}
                                            onRequestSort={undefined}
                                            onSelectAllClick={undefined}
                                            isAllSelected={false}
                                            showSelectAllCheckbox={false}
                                            key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_9`}
                                            customKey={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_10`}
                                        />
                                        <TableBody key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_11`}>
                                            {!!creditDetails && !!creditDetails.UnitAndDateWiseCredits && creditDetails.UnitAndDateWiseCredits.length > 0 && creditDetails.UnitAndDateWiseCredits.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length > 0 && creditDetails.UnitAndDateWiseCredits.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).map((datedRow, datedIndex) => {
                                                return (<React.Fragment key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_${formattedDate(datedRow.Dated,'MM_DD_YYYY')}`}>{!!datedRow.OrderBookerWiseNewAndRecoveredCredit && datedRow.OrderBookerWiseNewAndRecoveredCredit.length > 0 && datedRow.OrderBookerWiseNewAndRecoveredCredit.map((row, index) => {
                                                    return (
                                                        <TableRow
                                                            hover
                                                            key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_${formattedDate(datedRow.Dated,'MM_DD_YYYY')}_${row.ResponsiblePersonEmployeeId}_1`}
                                                            tabIndex={-1}
                                                            role="checkbox"
                                                            selected={false}
                                                            aria-checked={false}
                                                        >
                                                            <TableCell key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_${formattedDate(datedRow.Dated,'MM_DD_YYYY')}_${row.ResponsiblePersonEmployeeId}_2`} align='center'>{selectedUnit.UnitTitle}</TableCell>
                                                            <TableCell key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_${formattedDate(datedRow.Dated,'MM_DD_YYYY')}_${row.ResponsiblePersonEmployeeId}_3`} align="center" component="th" scope="row" padding="none">
                                                                {formattedDate(row.Dated, 'MMMM-DD-YYYY')}
                                                            </TableCell>
                                                            <TableCell key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_${formattedDate(datedRow.Dated,'MM_DD_YYYY')}_${row.ResponsiblePersonEmployeeId}_4`} align="center" component="th" scope="row" padding="none">
                                                                {row.ResponsiblePerson}
                                                            </TableCell>
                                                            <TableCell key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_${formattedDate(datedRow.Dated,'MM_DD_YYYY')}_${row.ResponsiblePersonEmployeeId}_5`} style={{color:'red'}} align="center" component="th" scope="row" padding="none">
                                                                {fCurrency(row.NewCredit)}
                                                            </TableCell>
                                                            <TableCell key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_${formattedDate(datedRow.Dated,'MM_DD_YYYY')}_${row.ResponsiblePersonEmployeeId}_6`} style={{color:'green'}} align="center" component="th" scope="row" padding="none">
                                                                {fCurrency(row.RecoveredCredit)}
                                                            </TableCell>
                                                            <TableCell key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_${formattedDate(datedRow.Dated,'MM_DD_YYYY')}_${row.ResponsiblePersonEmployeeId}_7`} style={{color:row.RecoveredCredit - row.NewCredit > 0 ? 'green' : 'red'}} align="center" component="th" scope="row" padding="none">
                                                                {fCurrency(row.RecoveredCredit - row.NewCredit)}
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}

                                                    <TableRow
                                                        hover
                                                        key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_${formattedDate(datedRow.Dated,'MM_DD_YYYY')}_30`}
                                                        tabIndex={-1}
                                                        role="checkbox"
                                                        selected={false}
                                                        aria-checked={false}
                                                    >
                                                        <TableCell key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_${formattedDate(datedRow.Dated,'MM_DD_YYYY')}_31`} style={{ borderBottom: 1, borderBottomColor: 'black', borderBottomStyle: 'solid' }} align="right" colSpan={3}><Typography key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_${formattedDate(datedRow.Dated,'MM_DD_YYYY')}_34`} variant='subtitle2'>Sub Total ({formattedDate(datedRow.Dated, 'MMMM-DD-YYYY')}):</Typography></TableCell>



                                                        <TableCell key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_${formattedDate(datedRow.Dated,'MM_DD_YYYY')}_32`} style={{ borderBottom: 1, borderBottomColor: 'black', borderBottomStyle: 'solid',color:'red' }} align="center" component="th" scope="row" padding="none">
                                                            <Typography key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_${formattedDate(datedRow.Dated,'MM_DD_YYYY')}_33`} variant="subtitle2">{fCurrency(datedRow.NewCredit)}</Typography>
                                                        </TableCell>

                                                        <TableCell key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_${formattedDate(datedRow.Dated,'MM_DD_YYYY')}_35`} style={{ borderBottom: 1, borderBottomColor: 'black', borderBottomStyle: 'solid',color:'green' }} align="center" component="th" scope="row" padding="none">
                                                            <Typography key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_${formattedDate(datedRow.Dated,'MM_DD_YYYY')}_36`} variant="subtitle2">{fCurrency(datedRow.RecoveredCredit)}</Typography>
                                                        </TableCell>
                                                        <TableCell key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_${formattedDate(datedRow.Dated,'MM_DD_YYYY')}_37`} style={{ borderBottom: 1, borderBottomColor: 'black', borderBottomStyle: 'solid' }} align="center" component="th" scope="row" padding="none">
                                                            <Typography key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_${formattedDate(datedRow.Dated,'MM_DD_YYYY')}_38`} style={{color:datedRow.RecoveredCredit - datedRow.NewCredit > 0 ? 'green' : 'red'}} variant="subtitle2">{fCurrency(datedRow.RecoveredCredit - datedRow.NewCredit)}</Typography>
                                                        </TableCell>
                                                    </TableRow>

                                                </React.Fragment>);
                                            })}
                                            <TableRow key={`${newVsRecoveredCreditkey}_40`}>
                                                <TableCell key={`${newVsRecoveredCreditkey}_41`} colSpan={3} align='right'>
                                                    <Typography key={`${newVsRecoveredCreditkey}_42`} variant='h6' style={{ textAlign: 'right' }}>Total:</Typography>
                                                </TableCell>
                                                <TableCell key={`${newVsRecoveredCreditkey}_43`} align='center'><Typography style={{ color: 'red' }} variant='h6'>{fCurrency(creditDetails?.UnitWiseNewAndRecoveredCreditSummaries?.find(m => m.UnitUniqueId == selectedUnit.UnitUniqueId)?.NewCredit ?? 0)}</Typography></TableCell>
                                                <TableCell key={`${newVsRecoveredCreditkey}_44`} align='center'><Typography style={{ color: 'green' }} variant='h6'>{fCurrency(creditDetails?.UnitWiseNewAndRecoveredCreditSummaries?.find(m => m.UnitUniqueId == selectedUnit.UnitUniqueId)?.RecoveredCredit ?? 0)}</Typography></TableCell>
                                                <TableCell key={`${newVsRecoveredCreditkey}_45`} align='center'><Typography style={{color: (creditDetails?.UnitWiseNewAndRecoveredCreditSummaries?.find(m => m.UnitUniqueId == selectedUnit.UnitUniqueId)?.RecoveredCredit ?? 0) - (creditDetails?.UnitWiseNewAndRecoveredCreditSummaries?.find(m => m.UnitUniqueId == selectedUnit.UnitUniqueId)?.NewCredit ?? 0) > 0 ? 'green' : 'red'}} variant='h6'>{fCurrency((creditDetails?.UnitWiseNewAndRecoveredCreditSummaries?.find(m => m.UnitUniqueId == selectedUnit.UnitUniqueId)?.RecoveredCredit ?? 0) - (creditDetails?.UnitWiseNewAndRecoveredCreditSummaries?.find(m => m.UnitUniqueId == selectedUnit.UnitUniqueId)?.NewCredit ?? 0))}</Typography></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Scrollbar>}
                        </Card><br /></React.Fragment>
                    })}
                </Container>}



                {viewPort.ShowUnitWiseSummary == true && <Container key={`${newVsRecoveredCreditkey}_46`} style={{ maxWidth: 1500, paddingLeft: 0, paddingRight: 0 }}>
                    {!!props.selectedUnits && !!props.selectedUnits.UnitAccesses && props.selectedUnits.UnitAccesses.filter(m => m.UnitUniqueId != AllOptionUniqueId).length > 0 && props.selectedUnits.UnitAccesses.filter(m => m.UnitUniqueId != AllOptionUniqueId).map((selectedUnit, unitIndex) => {
                        return <React.Fragment key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_50`}><Card key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_51`}>
                            {!!fromDate && !!toDate && isHistoryLoadedAfterLastChange == true && <Typography key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_52`} style={{ backgroundColor: selectedBackgroundColor, color: 'white', paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h4'}>{selectedUnit.UnitTitle} New Vs Recovered Credit Unit Wise Detail ({`${formattedDate(fromDate, 'DD-MMM-YYYY')} to ${formattedDate(toDate, 'DD-MMM-YYYY')}`})</Typography>}
                            {isLoading == true && <AudoSpin
                            key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_53`}
                                height="80"
                                width="80"
                                color='green'
                                ariaLabel='three-dots-loading'
                                wrapperStyle={{ alignSelf: 'center', justifyContent: 'center' }}
                            />}

                            {(!creditDetails || !creditDetails.UnitAndDateWiseCredits || creditDetails.UnitAndDateWiseCredits.length <= 0 || creditDetails.UnitAndDateWiseCredits.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length <= 0) && !!fromDate && !!toDate && isHistoryLoadedAfterLastChange == true && isLoading == false && <Typography key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_54`} style={{ paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h6'}>No Details For {selectedUnit.UnitTitle} ({`${formattedDate(fromDate, 'DD-MMM-YYYY')} to ${formattedDate(toDate, 'DD-MMM-YYYY')}`})</Typography>}

                            {!!creditDetails && !!creditDetails.UnitAndDateWiseCredits && creditDetails.UnitAndDateWiseCredits.length > 0 && creditDetails.UnitAndDateWiseCredits.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length > 0 && <Scrollbar key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_55`} sx={{}}>
                                <TableContainer key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_56`} sx={{ minWidth: 800 }}>
                                    <Table stickyHeader key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_57`}>
                                        <UserListHead
                                            order={'asc'}
                                            orderBy={''}
                                            headLabel={TABLE_HEAD_DATE_WISE}
                                            rowCount={0}
                                            numSelected={0}
                                            onRequestSort={undefined}
                                            onSelectAllClick={undefined}
                                            isAllSelected={false}
                                            showSelectAllCheckbox={false}
                                            key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_58`}
                                            customKey={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_59`}
                                        />
                                        <TableBody key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_60`}>
                                            {!!creditDetails && !!creditDetails.UnitAndDateWiseCredits && creditDetails.UnitAndDateWiseCredits.length > 0 && creditDetails.UnitAndDateWiseCredits.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length > 0 && creditDetails.UnitAndDateWiseCredits.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).map((datedRow, datedIndex) => {
                                                return (<React.Fragment key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_${formattedDate(datedRow.Dated,'DD_MMM_YYYY')}_610`}>
                                                    <TableRow
                                                        hover
                                                        key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_${formattedDate(datedRow.Dated,'DD_MMM_YYYY')}_61`}
                                                        tabIndex={-1}
                                                        role="checkbox"
                                                        selected={false}
                                                        aria-checked={false}
                                                    >
                                                        <TableCell key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_${formattedDate(datedRow.Dated,'DD_MMM_YYYY')}_62`} align='center'>{selectedUnit.UnitTitle}</TableCell>
                                                        <TableCell key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_${formattedDate(datedRow.Dated,'DD_MMM_YYYY')}_63`} align='center'>{formattedDate(datedRow.Dated,'MMMM-DD-YYYY')}</TableCell>
                                                        <TableCell key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_${formattedDate(datedRow.Dated,'DD_MMM_YYYY')}_64`} style={{color:'red'}} align="center">
                                                            {fCurrency(datedRow.NewCredit)}
                                                        </TableCell>

                                                        <TableCell key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_${formattedDate(datedRow.Dated,'DD_MMM_YYYY')}_65`} style={{ color:'green' }} align="center" component="th" scope="row" padding="none">
                                                            {fCurrency(datedRow.RecoveredCredit)}
                                                        </TableCell>
                                                        <TableCell key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_${formattedDate(datedRow.Dated,'DD_MMM_YYYY')}_66`} style={{ color: datedRow.RecoveredCredit - datedRow.NewCredit > 0 ? 'green' : 'red' }} align="center" component="th" scope="row" padding="none">
                                                            {fCurrency(datedRow.RecoveredCredit)}
                                                        </TableCell>
                                                    </TableRow>

                                                </React.Fragment>);
                                            })}
                                            <TableRow key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_70`}>
                                                <TableCell key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_71`} colSpan={2} align='right'>
                                                    <Typography key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_72`} variant='h6' style={{ textAlign: 'right' }}>Total:</Typography>
                                                </TableCell>
                                                <TableCell key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_73`} align='center'><Typography style={{ color: 'red' }} variant='h6'>{fCurrency(creditDetails?.UnitWiseNewAndRecoveredCreditSummaries?.find(m => m.UnitUniqueId == selectedUnit.UnitUniqueId)?.NewCredit ?? 0)}</Typography></TableCell>
                                                <TableCell key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_74`} align='center'><Typography style={{ color: 'rgba(34, 154, 22, 0.72)' }} variant='h6'>{fCurrency(creditDetails?.UnitWiseNewAndRecoveredCreditSummaries?.find(m => m.UnitUniqueId == selectedUnit.UnitUniqueId)?.RecoveredCredit ?? 0)}</Typography></TableCell>
                                                <TableCell key={`${newVsRecoveredCreditkey}_${selectedUnit.UnitUniqueId}_75`} align='center'><Typography style={{ color: (creditDetails?.UnitWiseNewAndRecoveredCreditSummaries?.find(m => m.UnitUniqueId == selectedUnit.UnitUniqueId)?.RecoveredCredit ?? 0) - (creditDetails?.UnitWiseNewAndRecoveredCreditSummaries?.find(m => m.UnitUniqueId == selectedUnit.UnitUniqueId)?.NewCredit ?? 0) > 0 ? 'green' : 'red' }} variant='h6'>{fCurrency((creditDetails?.UnitWiseNewAndRecoveredCreditSummaries?.find(m => m.UnitUniqueId == selectedUnit.UnitUniqueId)?.RecoveredCredit ?? 0) - (creditDetails?.UnitWiseNewAndRecoveredCreditSummaries?.find(m => m.UnitUniqueId == selectedUnit.UnitUniqueId)?.NewCredit ?? 0))}</Typography></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Scrollbar>}
                        </Card><br /></React.Fragment>
                    })}
                </Container>}



            </Page>
        </React.Fragment> : <NoUnitSelectedComponent key={`${newVsRecoveredCreditkey}_80`} pageTitle='Credit Report | Raza Pak Care' />
    );

    //#endregion
}

const mapStateToProps = (state: IApplicationState) => {
    return {
        selectedUnits: state.selectedUnitAccesses,
        loginUser: state.loginUser,
    };
}
const connectedDateWiseCredit = connect(mapStateToProps)(DateWiseCredit);
export { connectedDateWiseCredit as DateWiseCredit };
