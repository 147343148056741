import React from 'react';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import arrowBackFill from '@iconify/icons-eva/arrow-back-fill';
import refreshFill from '@iconify/icons-eva/refresh-fill';
import saveFill from '@iconify/icons-eva/save-fill';
import moneyCollectFill from '@iconify/icons-ant-design/money-collect-fill';
// material
import {
    Card,
    Table,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    Grid,
    TextField,
    InputAdornment,
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { UserListHead } from '../components/_dashboard/user';
//
import { LoadingButton } from '@mui/lab';
import { doCreditApi } from '../http';
import { connect } from 'react-redux';
import { IApplicationState } from 'src/redux';
import { Audio as AudoSpin } from 'react-loader-spinner'
import Label from 'src/components/Label';
import { sentenceCase } from 'change-case';
import { IDOCredit } from 'src/interfaces/do-credit.interface';
import { ICreditReceiveDetail } from 'src/interfaces/credit-receive-detail.interface';
import { confirmAction } from 'src/utility/confirm.util';
import { formattedDate, getCommaSeparatedUnitUniqueIdsExceptAll, getDaysDiff } from 'src/utility';
import { IUserDetail } from 'src/interfaces';
import { ISelectedUnitAccess } from 'src/interfaces/selected-unit-access.interface';
import { AllOptionUniqueId, AllowedNumberOfDaysForRed } from 'src/app.constant';
import { NoUnitSelectedComponent } from 'src/components/common/no-unit-selected.component';
import { Highlighted } from 'src/components/common/highlighted.component';
import { fCurrency } from 'src/utils/formatNumber';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { MessageComponent } from 'src/components/messages.component';
import { IMessage } from 'src/interfaces/message.interface';


// ----------------------------------------------------------------------

const TABLE_HEAD_DO_Credit = [
    { id: 'sr', label: 'Sr.', alignContent: 'center' },
    { id: 'order_id', label: 'Slip#', alignContent: 'center' },
    { id: 'shop_name', label: 'Shop Name', alignContent: 'center' },
    { id: 'shop_address', label: 'Shop Address', alignContent: 'center' },
    { id: 'owner_cell', label: 'Owner Cell', alignContent: 'center' },
    { id: 'responsible_person', label: 'Responsible Person', alignContent: 'center' },
    { id: 'credited_on', label: 'Credited On', alignContent: 'center' },
    { id: 'total_amount', label: 'Total Amount', alignContent: 'center' },
    { id: 'received_amount', label: 'Received Amount', alignContent: 'center' },
    { id: 'pending_amount', label: 'Pending Amount', alignContent: 'center' },
    { id: 'credit_status', label: 'Credit Status', alignContent: 'center' },
    { id: 'receive_credit', label: '', alignContent: 'center' },
];

//#region interfaces
interface IProps {
    loginUser: IUserDetail | null;
    selectedUnits: ISelectedUnitAccess;
    scrollToTop:() => void;
}

//#endregion

const ReceiveCredit = (props: IProps) => {
    //#region Initialization

    const initialCashDetail: ICreditReceiveDetail = {
        CoinOne: 0,
        CoinTwo: 0,
        CoinFive: 0,
        NoteTen: 0,
        NoteTwenty: 0,
        NoteFifty: 0,
        NoteHundred: 0,
        NoteFiveHundred: 0,
        NoteThousand: 0,
        NoteFiveThousand: 0,
        DOCreditId: 0,
        Id: 0,
        RecordedBy: props.loginUser.UserId,
        RecordedOn: new Date(),
        CreditReceiveStatusId: 1
    };

    //#endregion

    //#region state variables
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedCredit, setSelectedCredit] = React.useState<IDOCredit>(null);
    const [credits, setCredits] = React.useState<IDOCredit[]>([]);
    const [enteredCashAmount, setEnteredCashAmount] = React.useState<number>(0);
    const [orignalCredits,setOrignalCredits] = React.useState<IDOCredit[]>([]);
    const [searchText, setSearchText] = React.useState<string>('');
    const [cashDetail, setCashDetail] = React.useState<ICreditReceiveDetail>(initialCashDetail);
    const [isLatedOnly,setIsLatedOnly] = React.useState<boolean>(false);
    const [isReceiving, setIsReceiving] = React.useState<boolean>(false);

    const showErrorMessage = (message: string) => addMessage(message, 'error');
    const showInfoMessage = (message: string) => addMessage(message, 'info');
    const showSuccessMessage = (message: string) => addMessage(message, 'success');
    const showWarningMessage = (message: string) => addMessage(message, 'warning');
    const [pageMessages, setPageMessages] = React.useState<IMessage[]>([]);
    const addMessage = (messageText: string, variant: 'success' | 'error' | 'info' | 'warning') => {
        if (!!messageText && messageText.trim() != '') {
            let msgs = [];
            msgs.push({
                messageText,
                variant
            });

            setPageMessages([...msgs]);

            props.scrollToTop();
        }
    }

    const inputRefs:any[] = [
        React.useRef(null),
        React.useRef(null),
        React.useRef(null),
        React.useRef(null),
        React.useRef(null),
        React.useRef(null),
        React.useRef(null),
        React.useRef(null),
        React.useRef(null),
        React.useRef(null)
    ];

    //#endregion

    //#region util methods start

    const getCreditsCopy = () => {
        const creditsCopy = [...credits.map(a => a)];
        return creditsCopy;
    }

    const getOrignalCreditsCopy = () => {
        const creditsCopy = [...orignalCredits.map(a => a)];
        return creditsCopy;
    }

    const calculateTotalCashAmount = (detail: ICreditReceiveDetail) => {
        const tAmount = ((isNaN(detail.CoinOne) ? 0 : detail.CoinOne) * 1)
            +
            ((isNaN(detail.CoinTwo) ? 0 : detail.CoinTwo) * 2)
            +
            ((isNaN(detail.CoinFive) ? 0 : detail.CoinFive) * 5)
            +
            ((isNaN(detail.NoteTen) ? 0 : detail.NoteTen) * 10)
            +
            ((isNaN(detail.NoteTwenty) ? 0 : detail.NoteTwenty) * 20)
            +
            ((isNaN(detail.NoteFifty) ? 0 : detail.NoteFifty) * 50)
            +
            ((isNaN(detail.NoteHundred) ? 0 : detail.NoteHundred) * 100)
            +
            ((isNaN(detail.NoteFiveHundred) ? 0 : detail.NoteFiveHundred) * 500)
            +
            ((isNaN(detail.NoteThousand) ? 0 : detail.NoteThousand) * 1000)
            +
            ((isNaN(detail.NoteFiveThousand) ? 0 : detail.NoteFiveThousand) * 5000);

        setEnteredCashAmount(tAmount);
    }

    const getColor = (creditedOn:Date) =>{
        let color = 'black';

        if(!!creditedOn){
            const numberOfDays = getDaysDiff(new Date(), creditedOn);
            if(numberOfDays >= AllowedNumberOfDaysForRed){
                color = 'red'
            }
        }

        return color;
    }

    const isCreditLated = (creditedOn:Date) =>{
        let isYes = false;

        if(!!creditedOn){
            const numberOfDays = getDaysDiff(new Date(), creditedOn);
            if(numberOfDays >= AllowedNumberOfDaysForRed){
                isYes = true
            }
        }

        return isYes;
    }

    const getFilteredCreditsTotals = (unitUniqueId:string) => {
        let totals = {
            totalAmount:0,
            pendingAmount:0,
            receivedAmount:0
        };


        if(!!credits && credits.length > 0 && credits.filter(d => d.UnitUniqueId == unitUniqueId).length > 0){
            credits.filter(d => d.UnitUniqueId == unitUniqueId).forEach(fc =>{
                totals.totalAmount += fc.TotalAmount;
                totals.receivedAmount += fc.ReceivedAmount;
                totals.pendingAmount += fc.PendingAmount;
            })
        }

        return totals;
    }
    //#endregion util methods end

    //#region handlers start

    const saveCashDetail = () => {
        const totalCashAmount = ((isNaN(cashDetail.CoinOne) ? 0 : cashDetail.CoinOne) * 1)
            +
            ((isNaN(cashDetail.CoinTwo) ? 0 : cashDetail.CoinTwo) * 2)
            +
            ((isNaN(cashDetail.CoinFive) ? 0 : cashDetail.CoinFive) * 5)
            +
            ((isNaN(cashDetail.NoteTen) ? 0 : cashDetail.NoteTen) * 10)
            +
            ((isNaN(cashDetail.NoteTwenty) ? 0 : cashDetail.NoteTwenty) * 20)
            +
            ((isNaN(cashDetail.NoteFifty) ? 0 : cashDetail.NoteFifty) * 50)
            +
            ((isNaN(cashDetail.NoteHundred) ? 0 : cashDetail.NoteHundred) * 100)
            +
            ((isNaN(cashDetail.NoteFiveHundred) ? 0 : cashDetail.NoteFiveHundred) * 500)
            +
            ((isNaN(cashDetail.NoteThousand) ? 0 : cashDetail.NoteThousand) * 1000)
            +
            ((isNaN(cashDetail.NoteFiveThousand) ? 0 : cashDetail.NoteFiveThousand) * 5000);

        if (totalCashAmount > selectedCredit.PendingAmount) {
            showErrorMessage("Total Cash Amount Can't be greater than Amount To Receive");
        } else {
            if (totalCashAmount < selectedCredit.PendingAmount) {
                confirmAction("Partailly Receive Credit", "Total Cash Amount is less than Total Pending Amount", "Receive Partially")
                    .then(res => {
                        if (res == true) {
                            saveDetailsToDataBase();
                        }
                    })
            } else {
                saveDetailsToDataBase();
            }
        }
    }

    const resetEverything = () => {
        setIsReceiving(false);
        setSearchText('');
        setIsLatedOnly(false);
        setCredits([]);
        setOrignalCredits([]);
        setSelectedCredit(null);
        setCashDetail(initialCashDetail);
        setEnteredCashAmount(0);
        getCredits();
        setPageMessages([]);
    }

    const handleReceiveCash = (creditId: number) => {
        setPageMessages([]);
        const filteredCredit = credits.find(m => m.Id == creditId);

        if (!!filteredCredit && filteredCredit.PendingAmount > 0) {
            setIsReceiving(true);
            setSelectedCredit({ ...filteredCredit });
            setCashDetail(initialCashDetail);
        } else {
            showErrorMessage('No Details Found');
        }
    }

    const onCashDetailChange = (value: number, name: keyof ICreditReceiveDetail) => {
        setPageMessages([]);
        let copy = { ...cashDetail };
        (copy as any)[name] = value;
        calculateTotalCashAmount({ ...copy });
        setCashDetail({ ...copy });
    }

    const onSearchChange = (text:string, isLated:boolean) =>{
        setPageMessages([]);
        if(!!text){
            setSearchText(text);
            if(text.trim() != ''){
                let copy = getOrignalCreditsCopy();
                copy = copy.filter(c => 
                    (c.OrderId !== undefined && c.OrderId != null && c.OrderId.toString().trim().indexOf(text) >= 0)
                    ||
                    (!!c.ShopName && c.ShopName.toLowerCase().indexOf(text.toLowerCase()) >= 0)
                    ||
                    (!!c.ShopAddress && c.ShopAddress.toLowerCase().indexOf(text.toLowerCase()) >= 0 )
                    ||
                    (!!c.ResponsibleEmployeeName && c.ResponsibleEmployeeName.toLowerCase().indexOf(text.toLowerCase()) >= 0)
                    ||
                    (!!c.OwnerCellNumber && c.OwnerCellNumber.toLowerCase().indexOf(text.toLowerCase()) >= 0)
                    ||
                    (c.TotalAmount !== undefined && c.TotalAmount != null && c.TotalAmount.toString().indexOf(text) >= 0)
                    ||
                    (c.ReceivedAmount !== undefined && c.ReceivedAmount != null && c.ReceivedAmount.toString().indexOf(text) >= 0)
                    ||
                    (c.PendingAmount !== undefined && c.PendingAmount != null && c.PendingAmount.toString().indexOf(text) >= 0)
                    ||
                    (!!c.CreditStatus && c.CreditStatus.toLowerCase().indexOf(text.toLowerCase()) >= 0)
                    );

                    setCredits([...copy.filter(m => isLated == false || (isCreditLated(m.CreditedOn) == true))]);
            }else{
                const orignalCopy = getOrignalCreditsCopy();
                setCredits([...orignalCopy.filter(m => isLated == false || (isCreditLated(m.CreditedOn) == true))]);
            }
        }else{
            setSearchText('');
            if(!!orignalCredits){
                const orignalCopy = getOrignalCreditsCopy();
                setCredits([...orignalCopy.filter(m => isLated == false || (isCreditLated(m.CreditedOn) == true))]);
            }
        }
    }

    const onChangeLated = () =>{
        setPageMessages([]);
        onSearchChange(searchText,!isLatedOnly);
        setIsLatedOnly(!isLatedOnly);
    }

    //#endregion handlers end

    //#region apis call start
    const saveDetailsToDataBase = () => {
        setIsLoading(true);
        const modelToSave = { ...cashDetail, Id: 0, DOCreditId: selectedCredit.Id, RecordedOn: new Date(), RecordedBy: props.loginUser.UserId, CreditReceiveStatusId: 1 };
        doCreditApi.saveCashReceiveDetails(props.loginUser.UserId, modelToSave)
            .then(res => {
                setIsLoading(false);

                if (res.IsOk == true) {
                    showSuccessMessage(res.StatusMessage);
                    resetEverything();
                } else {
                    showErrorMessage(res.StatusMessage);
                }
            })
            .catch(e => {
                setIsLoading(false);
                console.log(e);
            });
    }

    const getCredits = () => {
        setIsLoading(true);
        doCreditApi.getDoCredits(props.loginUser.UserId, getCommaSeparatedUnitUniqueIdsExceptAll(props.selectedUnits.UnitAccesses),null,null,null)
            .then(results => {
                setIsLoading(false);
                if (!!results && results.length > 0) {
                    setCredits([...results]);
                    setOrignalCredits([...results]);
                }
                else {
                    setOrignalCredits([]);
                    setCredits([]);
                }
            })
            .catch(e => {
                console.log(e);
                setIsLoading(false);
            });
    }

    //#endregion apis calls end

    //#region hooks start
    React.useEffect(() => {
        getCredits();
    }, [props.selectedUnits.UnitAccesses]);

    React.useEffect(() =>{
        if(isReceiving){
            const handleWheel = e => e.preventDefault();
            inputRefs.forEach(r => {
                r.current.removeEventListener("wheel", handleWheel);
                r.current.addEventListener("wheel", handleWheel);
            });

            return () => {
                inputRefs.forEach(r => {
                    if(!!r && !!r.current){
                        r.current.removeEventListener("wheel", handleWheel);
                    }
                });
            };
        }
    },[isReceiving]);
    //#endregion hooks end

    return (
        (!!props.selectedUnits && !!props.selectedUnits.UnitAccesses && props.selectedUnits.UnitAccesses.filter(u => u.UnitUniqueId != AllOptionUniqueId).length > 0) ? <Page title="Receive Credit | Raza Pak Care">
            <MessageComponent onRemove={(i: number) => {
            let msgs = [...pageMessages];
            msgs.splice(i, 1);
            setPageMessages([...msgs]);
        }} messages={pageMessages} />
            {isReceiving == false && <Container style={{maxWidth: 1500, paddingLeft: 0, paddingRight: 0 }}>
                <Grid container style={{ flexDirection: 'row'}}>
                    <Grid xs={12} sm={4} item style={{flexDirection:'row',display:'flex',justifyContent:'flex-start'}}>
                <TextField
                style={{margin:5}}
                        autoComplete="search_text"
                        fullWidth
                        variant='outlined'
                        type={'text'}
                        label=""
                        value={searchText}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                            onSearchChange(event.target.value, isLatedOnly);
                        }}

                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon icon={'akar-icons:search'} />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                !!searchText ? <InputAdornment position="end">
                                    <Icon onClick={() => {onSearchChange('', isLatedOnly)}} style={{cursor:'pointer'}} icon={'ic:twotone-clear'} />
                                </InputAdornment> : undefined
                            )
                        }}
                    />
                    </Grid>
                    <Grid xs={12} sm={4} item style={{display:'flex',flexDirection:'column', justifyContent:'center'}}>
                    <FormGroup>
                        <FormControlLabel style={{marginLeft:5}} control={<Switch checked={isLatedOnly} onChange={() => {onChangeLated()}} />} label="Lated Only" />
                    </FormGroup>
                    </Grid>
                    <Grid xs={12} sm={4} style={{flexDirection:'row',display:'flex',justifyContent:'flex-end'}} item>
                    
                    <LoadingButton
                        size="small"
                        type="button"
                        variant="contained"
                        loading={isLoading}
                        style={{alignSelf:'center', backgroundColor: 'rgba(34, 154, 22, 0.72)', textAlign: 'right', height: 40 }}
                        startIcon={<Icon icon={refreshFill} />}
                        onClick={() => {
                            resetEverything();
                        }}
                    >
                        Refresh
                    </LoadingButton>
                    </Grid>
                </Grid>

                {!!props.selectedUnits && !!props.selectedUnits.UnitAccesses && props.selectedUnits.UnitAccesses.filter(m => m.UnitUniqueId != AllOptionUniqueId).length > 0 && props.selectedUnits.UnitAccesses.filter(m => m.UnitUniqueId != AllOptionUniqueId).map((selectedUnit, unitIndex) => {
                    return <React.Fragment key={`Fragment_${selectedUnit.UnitUniqueId}`}><Card style={{width:'100%'}} key={`Card_${selectedUnit.UnitUniqueId}`}>
                        <Typography style={{color: 'white',backgroundColor:'rgba(34, 154, 22, 0.72)', paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h6'}>Credits For {selectedUnit.UnitTitle}</Typography>
                        {isLoading == true && <AudoSpin
                            height="80"
                            width="80"
                            color='green'
                            ariaLabel='three-dots-loading'
                            wrapperStyle={{ alignSelf: 'center', justifyContent: 'center' }}
                        />}

                        {(!credits || credits.length <= 0 || credits.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length <= 0) && isLoading == false && <Typography style={{ paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h6'}>{`${!!searchText && searchText.trim() != '' ? `No Credits For Search Keywords '${searchText}' in ${selectedUnit.UnitTitle}` : `No Credits For ${selectedUnit.UnitTitle}`}`}</Typography>}

                        {!!credits && credits.length > 0 && credits.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length > 0 && <Scrollbar key={`Scroll_Bar_${selectedUnit.UnitUniqueId}`} sx={{}}>
                            <TableContainer key={`Table_Container_${selectedUnit.UnitUniqueId}`} sx={{ minWidth: 800, width:'100%' }}>
                                <Table style={{fontSize:'10'}} size='small' key={`Credit_Table_${selectedUnit.UnitUniqueId}`}>
                                    <UserListHead
                                        order={'asc'}
                                        orderBy={''}
                                        headLabel={TABLE_HEAD_DO_Credit}
                                        rowCount={0}
                                        numSelected={0}
                                        onRequestSort={undefined}
                                        onSelectAllClick={undefined}
                                        customKey={`Table_Head_Row_Credit_${selectedUnit.UnitUniqueId}`}
                                    />
                                    <TableBody key={`Table_Body_${selectedUnit.UnitUniqueId}`}>
                                        {!!credits && credits.length > 0 && credits.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length > 0 && credits.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).map((row, index) => {

                                            return (
                                                <TableRow
                                                    hover
                                                    key={`Data_Row_${selectedUnit.UnitUniqueId}_${row.OrderId}`}
                                                    tabIndex={-1}
                                                    role="checkbox"
                                                    selected={false}
                                                    aria-checked={false}
                                                >
                                                    <TableCell key={`SR_${selectedUnit.UnitUniqueId}_${row.OrderId}`} style={{color:getColor(row.CreditedOn)}} align="center">{index + 1}</TableCell>
                                                    <TableCell key={`Slip_${selectedUnit.UnitUniqueId}_${row.OrderId}`} style={{color:getColor(row.CreditedOn)}} align="center"><Highlighted key={`Slip_Hightlight_${selectedUnit.UnitUniqueId}_${row.OrderId}`} text={row.OrderId.toString()} highlight={searchText}  /></TableCell>
                                                    <TableCell key={`Shop_Title_${selectedUnit.UnitUniqueId}_${row.OrderId}`} style={{color:getColor(row.CreditedOn)}} align="center"><Highlighted key={`Shop_Name_hightlight_${selectedUnit.UnitUniqueId}_${row.OrderId}`} text={row.ShopName ?? ''} highlight={searchText}  /></TableCell>
                                                    <TableCell key={`Shop_Address_${selectedUnit.UnitUniqueId}_${row.OrderId}`} style={{color:getColor(row.CreditedOn)}} align="center"><Highlighted key={`Shop_Address_Hightlight_${selectedUnit.UnitUniqueId}_${row.OrderId}`} text={row.ShopAddress ?? ''} highlight={searchText}  /></TableCell>
                                                    <TableCell key={`Cell_Number_${selectedUnit.UnitUniqueId}_${row.OrderId}`} style={{color:getColor(row.CreditedOn)}} align="center" component="th" scope="row" padding="none">
                                                        <Highlighted key={`Cell_number_hightlight_${selectedUnit.UnitUniqueId}_${row.OrderId}`} text={`${!!row.OwnerCellNumber && row.OwnerCellNumber.trim() != '' ? `${row.OwnerCellNumber}` : 'N/A'}`} highlight={searchText}  />
                                                    </TableCell>
                                                    <TableCell key={`Responsible_Person_${selectedUnit.UnitUniqueId}_${row.OrderId}`} style={{color:getColor(row.CreditedOn)}} align="center"><Highlighted text={row.ResponsibleEmployeeName} highlight={searchText}  /></TableCell>

                                                    <TableCell key={`Credited_On_${selectedUnit.UnitUniqueId}_${row.OrderId}`} style={{color:getColor(row.CreditedOn)}} align="center" component="th" scope="row" padding="none">
                                                        {formattedDate(row.CreditedOn, 'ddd DD-MM-YYYY')}
                                                    </TableCell>

                                                    <TableCell key={`Total_Amount_${selectedUnit.UnitUniqueId}_${row.OrderId}`} align="center" component="th" scope="row" padding="none">
                                                        <strong><Highlighted key={`Total_Amount_Hightlight_${selectedUnit.UnitUniqueId}_${row.OrderId}`} text={`Rs.${row.TotalAmount}/-`} highlight={searchText}  /></strong>
                                                    </TableCell>

                                                    <TableCell key={`Received_Amount_${selectedUnit.UnitUniqueId}_${row.OrderId}`} align="center" component="th" scope="row" padding="none" style={{ color: 'green' }}>
                                                        <strong><Highlighted key={`Received_Amount_Hightlight_${selectedUnit.UnitUniqueId}_${row.OrderId}`} text={`Rs.${row.ReceivedAmount}/-`} highlight={searchText}  /></strong>
                                                    </TableCell>

                                                    <TableCell key={`Pending_Amount_${selectedUnit.UnitUniqueId}_${row.OrderId}`} align="center" component="th" scope="row" padding="none" style={{ color: 'red' }}>
                                                        <Typography key={`Pending_Amount_typography_${selectedUnit.UnitUniqueId}_${row.OrderId}`} variant='h6'><Highlighted key={`Pending_Amount_Highlight_${selectedUnit.UnitUniqueId}_${row.OrderId}`} text={`Rs.${row.PendingAmount}/-`} highlight={searchText}  /></Typography>
                                                    </TableCell>

                                                    <TableCell key={`Credit_Status_${selectedUnit.UnitUniqueId}_${row.OrderId}`} align="center">
                                                        <Label
                                                            key={`Credit_Status_Label_${selectedUnit.UnitUniqueId}_${row.OrderId}`}
                                                            variant="ghost"
                                                            color={row.CreditStatusId == 1 ? 'error' : (row.CreditStatusId == 2 ? 'info' : 'success')}
                                                        >
                                                            <Highlighted key={`Credit_Status_Hightlight_${selectedUnit.UnitUniqueId}_${row.OrderId}`} text={sentenceCase(row.CreditStatus ?? '')} highlight={searchText}  />
                                                        </Label>
                                                    </TableCell>
                                                    <TableCell key={`Receive_cash_action_${selectedUnit.UnitUniqueId}_${row.OrderId}`} width={180} align="center">
                                                        {(row.CreditStatusId == 1 || row.CreditStatusId == 2) && <LoadingButton
                                                        key={`Receive_Cash_Button_${selectedUnit.UnitUniqueId}_${row.OrderId}`}
                                                            size="small"
                                                            type="button"
                                                            variant="contained"
                                                            loading={isLoading}
                                                            style={{ margin: 10, backgroundColor: 'blue' }}
                                                            startIcon={<Icon icon={moneyCollectFill} />}
                                                            onClick={() => { handleReceiveCash(row.Id); }}
                                                        >
                                                            Receive Cash
                                                        </LoadingButton>}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                        <TableRow key={`Total_Row_${selectedUnit.UnitUniqueId}`}>
                                            <TableCell key={`Total_Label_Cell_${selectedUnit.UnitUniqueId}`} colSpan={7} align='right'><h3>Total:</h3></TableCell>
                                            <TableCell key={`Total_Credti_Amount_Cell_${selectedUnit.UnitUniqueId}`} align='center'><h3>{fCurrency(getFilteredCreditsTotals(selectedUnit.UnitUniqueId).totalAmount)}</h3></TableCell>
                                            <TableCell key={`Total_Received_Amount_Cell_${selectedUnit.UnitUniqueId}`} align='center' style={{color:'green'}}><h3>{fCurrency(getFilteredCreditsTotals(selectedUnit.UnitUniqueId).receivedAmount)}</h3></TableCell>
                                            <TableCell key={`Total_Pending_Amount_Cell_${selectedUnit.UnitUniqueId}`} align='center' style={{color:'red'}}><h3>{fCurrency(getFilteredCreditsTotals(selectedUnit.UnitUniqueId).pendingAmount)}</h3></TableCell>
                                            <TableCell key={`Total_Row_Placeholder_${selectedUnit.UnitUniqueId}`} colSpan={2}>&nbsp;</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Scrollbar>}
                    </Card><br /></React.Fragment>
                })}
            </Container>}


            {isReceiving == true && <Container key={`ReceivingCashContainer`} style={{ maxWidth: 1500, paddingLeft: 0, paddingRight: 0 }}>
                <Grid key={`ReceivingCashTopButtonsGridContainer`} container style={{ flexDirection: 'row', justifyContent: 'flex-end' }}><LoadingButton
                    size="small"
                    type="button"
                    variant="contained"
                    loading={isLoading}
                    style={{ margin: 3, backgroundColor: 'rgba(34, 154, 22, 0.72)', textAlign: 'right', height: 40 }}
                    startIcon={<Icon icon={arrowBackFill} />}
                    onClick={() => {
                        resetEverything();
                    }}
                    key={`Receiving_Cash_Back_Button`}
                >
                    Back
                </LoadingButton></Grid>
                <Card key={`ReceivingCashCard`}>
                    <Typography key={`ReceivingCashTitleTypography`} style={{ backgroundColor: 'rgba(34, 154, 22, 0.72)', color: 'white', paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h4'}>Receive Cash (Credit) For {selectedCredit.UnitTitle}</Typography>

                    {isLoading == true && <AudoSpin
                        key={`ReceivingCashAudoSpin`}
                        height="80"
                        width="80"
                        color='green'
                        ariaLabel='three-dots-loading'
                        wrapperStyle={{ alignSelf: 'center', justifyContent: 'center' }}
                    />}
                    <Container key={`ReceivingCashTopInfoContainer`} style={{ marginTop: 15, paddingBottom: 10 }}>
                        <Grid key={`InfoContainerGrid`} container spacing={2}>
                            <Grid key={`Info_Slip_Number`} item md={4}>
                                <Typography key={`Info_Slip_Number_Typography`}><strong>Slip#:{selectedCredit.OrderId}</strong></Typography>
                            </Grid>
                            <Grid key={`Top_Info_Responsible_Person`} item md={4}>
                                <Typography key={`Top_Info_Responsible_Person_Typography`}><strong>Responsible Person:</strong> {selectedCredit.ResponsibleEmployeeName}</Typography>
                            </Grid>
                            <Grid key={`Top_Info_Responsible_Person_Cell`} item md={4}>
                                <Typography key={`Top_Info_Responsible_Person_Cell_Typography`}><strong>Responsible Person Cell:</strong> {selectedCredit.ResponsibleEmployeeCellNumber === undefined || selectedCredit.ResponsibleEmployeeCellNumber == null || selectedCredit.ResponsibleEmployeeCellNumber.trim() == '' ? 'N/A' : selectedCredit.ResponsibleEmployeeCellNumber}</Typography>
                            </Grid>
                            <Grid key={`Top_Info_Shop_Name`} item md={4}>
                                <Typography key={`Top_Info_Shop_Name_Typography`}><strong>Shop Name:</strong> {selectedCredit.ShopName}</Typography>
                            </Grid>
                            <Grid key={`Top_info_Shop_Address`} item md={4}>
                                <Typography key={`Top_info_Shop_Address_Typography`}><strong>Shop Address:</strong> {selectedCredit.ShopAddress}</Typography>
                            </Grid>
                            <Grid key={`Top_Info_Shop_Number`} item md={4}>
                                <Typography key={`Top_Info_Shop_Number_Typography`}><strong>Shop#:</strong> {selectedCredit.ShopId}</Typography>
                            </Grid>

                            <Grid key={`Top_Info_Owner_Name`} item md={4}>
                                <Typography key={`Top_Info_Owner_Name_Typography`}><strong>Owner Name:</strong> {selectedCredit.OwnerName}</Typography>
                            </Grid>
                            <Grid key={`Top_Info_Owner_Cell_Number`} item md={4}>
                                <Typography key={`Top_Info_Owner_Cell_Number_Typography`}><strong>Owner Cell#:</strong> {`${!!selectedCredit.OwnerCellNumber && selectedCredit.OwnerCellNumber.trim() != '' ? `${selectedCredit.OwnerCellNumber}` : 'N/A'}`}</Typography>
                            </Grid>
                            <Grid key={`Top_Info_Credited_On`} item md={4}>
                                <Typography key={`Top_Info_Credited_On_Typography`}><strong>Credited On:</strong> {formattedDate(selectedCredit.CreditedOn,'DD-MMM-YYYY')}</Typography>
                            </Grid>
                        </Grid>
                    </Container>

                    <hr style={{ height: 3, backgroundColor: 'green' }} />

                    <Scrollbar key={`Receiving_Cash_Form_Scrollbar`} sx={{}}>
                        <TableContainer sx={{ minWidth: 800 }} key={'Receiving_Cash_Form_Table_Container'}>
                            <Typography key={`Receiving_Cash_Form_Top_Title_Cash_Summary`} style={{ padding: 10, textAlign: 'center' }} variant='h4'>Cash Summary</Typography>
                            {<Table key={`Receiving_Cash_Form_Table`} size='small'>
                                <TableBody key={`Receiving_Cash_Form_Table_Body`}>
                                    <TableRow
                                        key={`Receving_Cash_Form_Info_Row`}
                                        tabIndex={-1}
                                        role="checkbox"
                                        selected={false}
                                        aria-checked={false}
                                    >
                                        <TableCell key={`Receiving_Cash_Form_Total_Credit_Amount`} align="center">
                                            <strong>Total Credit Amount:&nbsp;&nbsp;&nbsp;&nbsp;</strong><Typography display='inline' variant='h6'>{fCurrency(selectedCredit.TotalAmount)}</Typography>
                                        </TableCell>

                                        <TableCell key={`Receiving_Cash_Form_Already_Received_Amount`} align="center" style={{ color: 'green' }}>
                                            <strong>Already Received Amount:&nbsp;&nbsp;&nbsp;&nbsp;</strong><Typography display={'inline'} variant='h6'>{fCurrency(selectedCredit.ReceivedAmount)}</Typography>
                                        </TableCell>
                                        <TableCell key={`Receiving_Cash_Form_Pending_Amount_To_Receive`} align="center" style={{ color: 'red' }}>
                                            <strong>Pending Amount To Receive:&nbsp;&nbsp;&nbsp;&nbsp;</strong><Typography display={'inline'} variant='h6'>{fCurrency(selectedCredit.PendingAmount)}</Typography>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow
                                        hover
                                        key={`Receving_Cash_Form_Denomination_Row_1`}
                                        tabIndex={-1}
                                        role="checkbox"
                                        selected={false}
                                        aria-checked={false}
                                    >
                                        <TableCell key={`Receiving_Cash_Form_Denomination_Title_1`} style={{ paddingTop: 20 }} align="right"><strong>1X</strong></TableCell>
                                        <TableCell key={`Receiving_Cash_Form_Denomination_Entered_Amount_1`} style={{ paddingTop: 20 }} align="center">
                                            <TextField
                                                autoComplete="credit-amount"
                                                size='small'
                                                variant='outlined'
                                                type={'number'}
                                                label=""
                                                value={cashDetail.CoinOne > 0 ? cashDetail.CoinOne : ''}
                                                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                    const value = event.target.value;
                                                    let numberValue: number | null = null;

                                                    if (value !== undefined && value != null) {
                                                        numberValue = parseInt(value);
                                                    }

                                                    onCashDetailChange(numberValue, 'CoinOne');
                                                }}
                                                inputRef={inputRefs[0]}

                                                onKeyDown={(e: any) => {
                                                    if (e.keyCode === 38) {
                                                        e.preventDefault();
                                                        inputRefs[9].current.focus();
                                                      }else if(e.keyCode === 40){
                                                        e.preventDefault();
                                                        inputRefs[1].current.focus();
                                                      }
                                                }}
                                                key={`Receiving_Cash_Form_Denomination_Text_Field_1`}
                                            />
                                        </TableCell>
                                        <TableCell key={`Receiving_Cash_Form_Denomination_Amount_1`} style={{ paddingTop: 20 }} align='left'>
                                            <strong>{fCurrency(cashDetail.CoinOne)}</strong>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow
                                        hover
                                        key={`Receving_Cash_Form_Denomination_Row_2`}
                                        tabIndex={-1}
                                        role="checkbox"
                                        selected={false}
                                        aria-checked={false}
                                    >
                                        <TableCell key={`Receiving_Cash_Form_Denomination_Title_2`} align="right"><strong>2X</strong></TableCell>
                                        <TableCell key={`Receiving_Cash_Form_Denomination_EnteredAmount_2`} align="center">
                                            <TextField
                                                size='small'
                                                variant='outlined'
                                                autoComplete="credit-amount"
                                                type={'number'}
                                                label=""
                                                value={cashDetail.CoinTwo > 0 ? cashDetail.CoinTwo : ''}
                                                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                    const value = event.target.value;
                                                    let numberValue: number | null = null;

                                                    if (value !== undefined && value != null) {
                                                        numberValue = parseInt(value);
                                                    }

                                                    onCashDetailChange(numberValue, 'CoinTwo');
                                                }}
                                                inputRef={inputRefs[1]}
                                                onKeyDown={(e: any) => {
                                                    if (e.keyCode === 38) {
                                                        e.preventDefault();
                                                        inputRefs[0].current.focus();
                                                      }else if(e.keyCode === 40){
                                                        e.preventDefault();
                                                        inputRefs[2].current.focus();
                                                      }
                                                }}
                                                key={`Receiving_Cash_Form_Denomination_Text_Field_2`}
                                            />
                                        </TableCell>
                                        <TableCell key={`Receiving_Cash_Form_Denomination_Amount_2`} align='left'>
                                            <strong>{fCurrency(cashDetail.CoinTwo * 2)}</strong>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow
                                        hover
                                        key={`Receving_Cash_Form_Denomination_Row_3`}
                                        tabIndex={-1}
                                        role="checkbox"
                                        selected={false}
                                        aria-checked={false}
                                    >
                                        <TableCell key={`Receiving_Cash_Form_Denomination_Title_5`} align="right"><strong>5X</strong></TableCell>
                                        <TableCell key={`Receiving_Cash_Form_Denomination_Qty_5`} align="center">
                                            <TextField
                                                size='small'
                                                variant='outlined'
                                                autoComplete="credit-amount"
                                                type={'number'}
                                                label=""
                                                inputRef={inputRefs[2]}
                                                onKeyDown={(e: any) => {
                                                    if (e.keyCode === 38) {
                                                        e.preventDefault();
                                                        inputRefs[1].current.focus();
                                                      }else if(e.keyCode === 40){
                                                        e.preventDefault();
                                                        inputRefs[3].current.focus();
                                                      }
                                                }}
                                                value={cashDetail.CoinFive > 0 ? cashDetail.CoinFive : ''}
                                                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                    const value = event.target.value;
                                                    let numberValue: number | null = null;

                                                    if (value !== undefined && value != null) {
                                                        numberValue = parseInt(value);
                                                    }

                                                    onCashDetailChange(numberValue, 'CoinFive');
                                                }}
                                                key={`Receiving_Cash_Form_Denomination_TextField_5`}
                                            />
                                        </TableCell>
                                        <TableCell key={`Receiving_Cash_Form_Denomination_Amount_5`} align='left'>
                                            <strong>{fCurrency(cashDetail.CoinFive * 5)}</strong>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow
                                        hover
                                        key={`Receving_Cash_Form_Denomination_Row_4`}
                                        tabIndex={-1}
                                        role="checkbox"
                                        selected={false}
                                        aria-checked={false}
                                    >

                                        <TableCell key={`Receiving_Cash_Form_Denomination_Title_10`} align="right"><strong>10X</strong></TableCell>
                                        <TableCell key={`Receiving_Cash_Form_Denomination_Qty_10`} align="center">
                                            <TextField
                                                size='small'
                                                variant='outlined'
                                                autoComplete="credit-amount"
                                                type={'number'}
                                                label=""
                                                value={cashDetail.NoteTen > 0 ? cashDetail.NoteTen : ''}
                                                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                    const value = event.target.value;
                                                    let numberValue: number | null = null;

                                                    if (value !== undefined && value != null) {
                                                        numberValue = parseInt(value);
                                                    }

                                                    onCashDetailChange(numberValue, 'NoteTen');
                                                }}
                                                inputRef={inputRefs[3]}
                                                onKeyDown={(e: any) => {
                                                    if (e.keyCode === 38) {
                                                        e.preventDefault();
                                                        inputRefs[2].current.focus();
                                                      }else if(e.keyCode === 40){
                                                        e.preventDefault();
                                                        inputRefs[4].current.focus();
                                                      }
                                                }}
                                                key={`Receiving_Cash_Form_Denomination_TextField_10`}
                                            />
                                        </TableCell>
                                        <TableCell key={`Receiving_Cash_Form_Denomination_Amount_10`} align='left'>
                                            <strong>{fCurrency(cashDetail.NoteTen * 10)}</strong>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow
                                        hover
                                        key={`Receving_Cash_Form_Denomination_Row_5`}
                                        tabIndex={-1}
                                        role="checkbox"
                                        selected={false}
                                        aria-checked={false}
                                    >
                                        <TableCell key={`Receiving_Cash_Form_Denomination_Title_20`} align="right"><strong>20X</strong></TableCell>
                                        <TableCell key={`Receiving_Cash_Form_Denomination_Qty_20`} align="center">
                                            <TextField
                                                size='small'
                                                variant='outlined'
                                                autoComplete="credit-amount"
                                                type={'number'}
                                                label=""
                                                value={cashDetail.NoteTwenty > 0 ? cashDetail.NoteTwenty : ''}
                                                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                    const value = event.target.value;
                                                    let numberValue: number | null = null;

                                                    if (value !== undefined && value != null) {
                                                        numberValue = parseInt(value);
                                                    }

                                                    onCashDetailChange(numberValue, 'NoteTwenty');
                                                }}
                                                inputRef={inputRefs[4]}
                                                onKeyDown={(e: any) => {
                                                    if (e.keyCode === 38) {
                                                        e.preventDefault();
                                                        inputRefs[3].current.focus();
                                                      }else if(e.keyCode === 40){
                                                        e.preventDefault();
                                                        inputRefs[5].current.focus();
                                                      }
                                                }}
                                                key={`Receiving_Cash_Form_Denomination_TextField_20`}
                                            />
                                        </TableCell>
                                        <TableCell key={`Receiving_Cash_Form_Denomination_Amount_20`} align='left'>
                                            <strong>{fCurrency(cashDetail.NoteTwenty * 20)}</strong>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow
                                        hover
                                        key={`Receving_Cash_Form_Denomination_Row_6`}
                                        tabIndex={-1}
                                        role="checkbox"
                                        selected={false}
                                        aria-checked={false}
                                    >
                                        <TableCell key={`Receiving_Cash_Form_Denomination_Title_50`} align="right"><strong>50X</strong></TableCell>
                                        <TableCell key={`Receiving_Cash_Form_Denomination_Qty_50`} align="center">
                                            <TextField
                                                size='small'
                                                variant='outlined'
                                                autoComplete="credit-amount"
                                                type={'number'}
                                                label=""
                                                value={cashDetail.NoteFifty > 0 ? cashDetail.NoteFifty : ''}
                                                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                    const value = event.target.value;
                                                    let numberValue: number | null = null;

                                                    if (value !== undefined && value != null) {
                                                        numberValue = parseInt(value);
                                                    }

                                                    onCashDetailChange(numberValue, 'NoteFifty');
                                                }}
                                                inputRef={inputRefs[5]}
                                                onKeyDown={(e: any) => {
                                                    if (e.keyCode === 38) {
                                                        e.preventDefault();
                                                        inputRefs[4].current.focus();
                                                      }else if(e.keyCode === 40){
                                                        e.preventDefault();
                                                        inputRefs[6].current.focus();
                                                      }
                                                }}
                                                key={`Receiving_Cash_Form_Denomination_TextField_50`}
                                            />
                                        </TableCell>
                                        <TableCell key={`Receiving_Cash_Form_Denomination_Amount_50`} align='left'>
                                            <strong>{fCurrency(cashDetail.NoteFifty * 50)}</strong>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow
                                        hover
                                        key={`Receving_Cash_Form_Denomination_Row_7`}
                                        tabIndex={-1}
                                        role="checkbox"
                                        selected={false}
                                        aria-checked={false}
                                    >
                                        <TableCell key={`Receiving_Cash_Form_Denomination_Title_100`} align="right"><strong>100X</strong></TableCell>
                                        <TableCell key={`Receiving_Cash_Form_Denomination_Qty_100`} align="center">
                                            <TextField
                                                size='small'
                                                variant='outlined'
                                                autoComplete="credit-amount"
                                                type={'number'}
                                                label=""
                                                value={cashDetail.NoteHundred > 0 ? cashDetail.NoteHundred : ''}
                                                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                    const value = event.target.value;
                                                    let numberValue: number | null = null;

                                                    if (value !== undefined && value != null) {
                                                        numberValue = parseInt(value);
                                                    }

                                                    onCashDetailChange(numberValue, 'NoteHundred');
                                                }}
                                                inputRef={inputRefs[6]}
                                                onKeyDown={(e: any) => {
                                                    if (e.keyCode === 38) {
                                                        e.preventDefault();
                                                        inputRefs[5].current.focus();
                                                      }else if(e.keyCode === 40){
                                                        e.preventDefault();
                                                        inputRefs[7].current.focus();
                                                      }
                                                }}
                                                key={`Receiving_Cash_Form_Denomination_TextField_100`}
                                            />
                                        </TableCell>
                                        <TableCell key={`Receiving_Cash_Form_Denomination_Amount_100`} align='left'>
                                            <strong>{fCurrency(cashDetail.NoteHundred * 100)}</strong>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow
                                        hover
                                        key={`Receving_Cash_Form_Denomination_Row_8`}
                                        tabIndex={-1}
                                        role="checkbox"
                                        selected={false}
                                        aria-checked={false}
                                    >

                                        <TableCell key={`Receiving_Cash_Form_Denomination_Title_500`} align="right"><strong>500X</strong></TableCell>
                                        <TableCell key={`Receiving_Cash_Form_Denomination_Qty_500`} align="center">
                                            <TextField
                                                size='small'
                                                variant='outlined'
                                                autoComplete="credit-amount"
                                                type={'number'}
                                                label=""
                                                value={cashDetail.NoteFiveHundred > 0 ? cashDetail.NoteFiveHundred : ''}
                                                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                    const value = event.target.value;
                                                    let numberValue: number | null = null;

                                                    if (value !== undefined && value != null) {
                                                        numberValue = parseInt(value);
                                                    }

                                                    onCashDetailChange(numberValue, 'NoteFiveHundred');
                                                }}
                                                inputRef={inputRefs[7]}
                                                onKeyDown={(e: any) => {
                                                    if (e.keyCode === 38) {
                                                        e.preventDefault();
                                                        inputRefs[6].current.focus();
                                                      }else if(e.keyCode === 40){
                                                        e.preventDefault();
                                                        inputRefs[8].current.focus();
                                                      }
                                                }}

                                                key={`Receiving_Cash_Form_Denomination_TextField_500`}
                                            />
                                        </TableCell>
                                        <TableCell key={`Receiving_Cash_Form_Denomination_Amount_500`} align='left'>
                                            <strong>{fCurrency(cashDetail.NoteFiveHundred * 500)}</strong>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow
                                        hover
                                        key={`Receving_Cash_Form_Denomination_Row_9`}
                                        tabIndex={-1}
                                        role="checkbox"
                                        selected={false}
                                        aria-checked={false}
                                    >
                                        <TableCell key={`Receiving_Cash_Form_Denomination_Title_1000`} align="right"><strong>1000X</strong></TableCell>
                                        <TableCell key={`Receiving_Cash_Form_Denomination_Qty_1000`} align="center">
                                            <TextField
                                                size='small'
                                                variant='outlined'
                                                autoComplete="credit-amount"
                                                type={'number'}
                                                label=""
                                                value={cashDetail.NoteThousand > 0 ? cashDetail.NoteThousand : ''}
                                                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                    const value = event.target.value;
                                                    let numberValue: number | null = null;

                                                    if (value !== undefined && value != null) {
                                                        numberValue = parseInt(value);
                                                    }

                                                    onCashDetailChange(numberValue, 'NoteThousand');
                                                }}
                                                inputRef={inputRefs[8]}
                                                onKeyDown={(e: any) => {
                                                    if (e.keyCode === 38) {
                                                        e.preventDefault();
                                                        inputRefs[7].current.focus();
                                                      }else if(e.keyCode === 40){
                                                        e.preventDefault();
                                                        inputRefs[9].current.focus();
                                                      }
                                                }}
                                                key={`Receiving_Cash_Form_Denomination_TextField_1000`}
                                            />
                                        </TableCell>
                                        <TableCell key={`Receiving_Cash_Form_Denomination_Amount_1000`} align='left'>
                                            <strong>{fCurrency(cashDetail.NoteThousand * 1000)}</strong>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow
                                        hover
                                        key={`Receving_Cash_Form_Denomination_Row_10`}
                                        tabIndex={-1}
                                        role="checkbox"
                                        selected={false}
                                        aria-checked={false}
                                    >
                                        <TableCell key={`Receiving_Cash_Form_Denomination_Title_5000`} align="right"><strong>5000X</strong></TableCell>
                                        <TableCell key={`Receiving_Cash_Form_Denomination_Qty_5000`} align="center">
                                            <TextField
                                                size='small'
                                                variant='outlined'
                                                autoComplete="credit-amount"
                                                type={'number'}
                                                label=""
                                                value={cashDetail.NoteFiveThousand > 0 ? cashDetail.NoteFiveThousand : ''}
                                                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                    const value = event.target.value;
                                                    let numberValue: number | null = null;

                                                    if (value !== undefined && value != null) {
                                                        numberValue = parseInt(value);
                                                    }

                                                    onCashDetailChange(numberValue, 'NoteFiveThousand');
                                                }}
                                                inputRef={inputRefs[9]}
                                                onKeyDown={(e: any) => {
                                                    if (e.keyCode === 38) {
                                                        e.preventDefault();
                                                        inputRefs[8].current.focus();
                                                      }else if(e.keyCode === 40){
                                                        e.preventDefault();
                                                        inputRefs[0].current.focus();
                                                      }
                                                }}

                                                key={`Receiving_Cash_Form_Denomination_TextField_5000`}
                                            />
                                        </TableCell>
                                        <TableCell key={`Receiving_Cash_Form_Denomination_Amount_5000`} align='left'>
                                            <strong>{fCurrency(cashDetail.NoteFiveThousand * 5000)}</strong>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow
                                        hover
                                        key={`Receving_Cash_Form_Denomination_Row_11`}
                                        tabIndex={-1}
                                        role="checkbox"
                                        selected={false}
                                        aria-checked={false}
                                    >
                                        

                                        <TableCell key={`Receiving_Cash_Form_Total_1`} colSpan={3} align='right'>
                                            <Typography key={`Receiving_Cash_Form_Total_1_Typography`} style={{marginRight:10,color:'green'}} display={'inline'} variant='h6'>Amount To Receive: {fCurrency(selectedCredit.PendingAmount)}</Typography>
                                            <Typography key={`Receiving_Cash_Form_Total_2_Typography`} style={{color:selectedCredit.PendingAmount - enteredCashAmount == 0 ? 'green' : (selectedCredit.PendingAmount - enteredCashAmount < 0 ? 'red' : 'blue'),marginRight:10}} display={'inline'} variant='h6'>Remaining Cash: {fCurrency(selectedCredit.PendingAmount - enteredCashAmount)}</Typography>
                                            <Typography key={`Receiving_Cash_Form_Total_3_Typography`} style={{ color: enteredCashAmount == selectedCredit.PendingAmount ? 'green' : 'red' }} display={'inline'} variant='h6'>Entered Cash: {fCurrency(enteredCashAmount)}</Typography>{enteredCashAmount == (selectedCredit.PendingAmount) && <Icon icon="teenyicons:tick-circle-solid" style={{ marginLeft: 10 }} color='green' width={30} height={30} />}{enteredCashAmount != (selectedCredit.PendingAmount) && <Icon style={{ marginLeft: 10 }} color="red" height={30} width={30} icon={"ep:circle-close-filled"} />}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>}
                        </TableContainer>
                    </Scrollbar>




                </Card>

                <Container key={`ReceivingCashSaveButtonContainer`} style={{ paddingTop: 30, paddingBottom: 10 }}>
                    <Grid key={`ReceivingCashSaveButtonGridContainer`} container >
                        <Grid key={`ReceivingCashSaveButtonItemGrid`} item md={12} style={{ textAlign: 'center' }}>
                            <LoadingButton
                                size="small"
                                type="button"
                                variant="contained"
                                loading={isLoading}
                                style={{ margin: 3, backgroundColor: 'blue', width: 180, height: 50 }}
                                startIcon={<Icon icon={saveFill} />}
                                onClick={saveCashDetail}
                                key={`ReceivingCashSaveButton`}
                            >
                                Save
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Container>

            </Container>}
        </Page> : <NoUnitSelectedComponent key={`No_Unit_Selected_Receive_Credit`} pageTitle='Receive Credit | Raza Pak Care' />
    );
}

const mapStateToProps = (state: IApplicationState) => {
    return {
        selectedUnits: state.selectedUnitAccesses,
        loginUser: state.loginUser,
    };
}
const connectedReceiveCredit = connect(mapStateToProps)(ReceiveCredit);
export { connectedReceiveCredit as ReceiveCredit };
