import React from 'react';
import { fCurrency } from 'src/utils/formatNumber';

interface IProps{
    orderBooker:string;
    totalShops:number;
    totalWholeSaleShops:number;
    totalRetailShops:number;
    totalCoveredShops:number;
    wholeSaleCoveredShops:number;
    retailCoveredShops:number;
    pendingCredit:number;
    numberOfShopsWithCredit:number;
    outOfRoutePendingCredit:number;
    outOfRouteNumberOfShopsWithCredit:number;
}

export const RoutesSummaryComponent = (props:IProps) =>{
    return <table border={1} cellSpacing={0} cellPadding={5} style={{ width: '100%', fontSize: 11, marginBottom: 10, marginTop:10 }}>
    <tbody>
    <tr>
      <th style={{padding:7}} colSpan={5} align={'center'}>Overall Summary</th>
    </tr>
    <tr>
      <td rowSpan={3} style={{padding:7}}>Pending Credit:</td>
    </tr>
    <tr>
        <th colSpan={2}>Total</th>
        <th>In Routes</th>
        <th>Out Of Route</th>
    </tr>
    <tr>
        <td colSpan={2} align={'center'} style={{padding:7,color:'red'}}>{fCurrency(props.pendingCredit)}</td>
        <td align={'center'} style={{padding:7,color:'red'}}>{fCurrency(props.pendingCredit - props.outOfRoutePendingCredit)}</td>
        <td align={'center'} style={{padding:7,color:'red'}}>{fCurrency(props.outOfRoutePendingCredit)}</td>
    </tr>
    <tr>
      <td rowSpan={3} style={{padding:7}}>Pending Credit Shops:</td>
    </tr>
    <tr>
        <th colSpan={2}>Total</th>
        <th>In Routes</th>
        <th>Out Of Route</th>
    </tr>
    <tr>
        <td colSpan={2} align={'center'} style={{padding:7,color:'red'}}>{props.numberOfShopsWithCredit ?? 0}</td>
        <td align={'center'} style={{padding:7,color:'red'}}>{(props.numberOfShopsWithCredit ?? 0) - (props.outOfRouteNumberOfShopsWithCredit ?? 0)}</td>
        <td align={'center'} style={{padding:7,color:'red'}}>{props.outOfRouteNumberOfShopsWithCredit ?? 0}</td>
    </tr>
    <tr>
      <th style={{padding:7}}>Detail</th>
      <th style={{padding:7}}>Total</th>
      <th style={{padding:7, background: 'rgba(51, 170, 51, .4)'}}>Covered</th>
      <th style={{padding:7, background:'rgba(255, 0, 0, .4)'}}>Never Covered</th>
      <th style={{padding:7, background: 'rgba(51, 170, 51, .4)'}}>Percentage</th>
    </tr>
    <tr>
      <td style={{padding:7}}>Total Shops</td>
      <td style={{padding:7}} align='center'>{props.totalShops}</td>
      <td style={{padding:7, background: 'rgba(51, 170, 51, .4)'}} align='center'>{props.totalCoveredShops}</td>
      <td style={{padding:7, background:'rgba(255, 0, 0, .4)'}} align='center'>{props.totalShops - props.totalCoveredShops}</td>
      <td style={{padding:7, background: 'rgba(51, 170, 51, .4)'}} align='center'>{`${parseFloat(((props.totalCoveredShops / props.totalShops) * 100).toString()).toFixed(2)} %`}</td>
    </tr>
    <tr>
      <td style={{padding:7}}>Whole Sale Shops</td>
      <td style={{padding:7}} align='center'>{props.totalWholeSaleShops}</td>
      <td style={{padding:7, background: 'rgba(51, 170, 51, .4)'}} align='center'>{props.wholeSaleCoveredShops}</td>
      <td style={{padding:7, background:'rgba(255, 0, 0, .4)'}} align='center'>{props.totalWholeSaleShops - props.wholeSaleCoveredShops}</td>
      <td style={{padding:7, background: 'rgba(51, 170, 51, .4)'}} align='center'>{`${parseFloat(((props.wholeSaleCoveredShops / props.totalWholeSaleShops) * 100).toString()).toFixed(2)} %`}</td>
    </tr>
    <tr>
      <td style={{padding:7}}>Retail Shops</td>
      <td style={{padding:7}} align='center'>{props.totalRetailShops}</td>
      <td style={{padding:7, background: 'rgba(51, 170, 51, .4)'}} align='center'>{props.retailCoveredShops}</td>
      <td style={{padding:7, background:'rgba(255, 0, 0, .4)'}} align='center'>{props.totalRetailShops - props.retailCoveredShops}</td>
      <td style={{padding:7, background: 'rgba(51, 170, 51, .4)'}} align='center'>{`${parseFloat(((props.retailCoveredShops / props.totalRetailShops) * 100).toString()).toFixed(2)} %`}</td>
    </tr>
    
    </tbody>
  </table>
}