import { IUserRouteDetail } from "src/interfaces/routes/user-route-detail.interface";

export const getUrlFromComponentKey = (userRoutes:IUserRouteDetail, componentKey:string) =>{
    let url:string = '';

    if(!!userRoutes && !!userRoutes.Routes && userRoutes.Routes.length > 0){
        let filteredRoutes = userRoutes.Routes.filter(r => (!!r.Children && r.Children.findIndex(rc => rc.ComponentKey == componentKey) >= 0));

        if(!!filteredRoutes && filteredRoutes.length > 0){
            let routeLayout = filteredRoutes[0];
            url = `${routeLayout.RoutePath}/${routeLayout.Children.filter(rc => rc.ComponentKey == componentKey)[0].RoutePath}`;
        }
    }

    return url;
}