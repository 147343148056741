import React from 'react';
import { connect } from 'react-redux';
import { AllOptionUniqueId } from 'src/app.constant';
import { IUserDetail } from 'src/interfaces';
import { ISelectedUnitAccess } from 'src/interfaces/selected-unit-access.interface';
import { IUnitAccess } from 'src/interfaces/unit-access.interface';
import { IApplicationState } from 'src/redux';
import { setSelectedUnitAccessesAction } from 'src/redux/actions';
import { IAction } from 'src/redux/actions/action.interface';
import { CustomMultiSelect } from './CustomMultiSelect';

interface IProps{
    loginUser:IUserDetail | null;
    selectedUnitAccesses:ISelectedUnitAccess;
    setSelectedUnitAccesses:(payload:ISelectedUnitAccess | null) => IAction<ISelectedUnitAccess | null>;
}

const UnitAccessMultiSelect = (props:IProps) =>{

    const onUnitChange = (options:IUnitAccess[]) =>{
        let isAllSelected = false;
        let selectedUnitAccesses:IUnitAccess[] = [];

        if(!!options && options.length > 0){
            if(!!props.selectedUnitAccesses && !!props.selectedUnitAccesses.UnitAccesses && props.selectedUnitAccesses.UnitAccesses.length > 0){
                if(props.selectedUnitAccesses.UnitAccesses.length > options.length){
                    //removed
                    if(options.length == 1 && options.findIndex(o => o.UnitUniqueId == AllOptionUniqueId)){
                        isAllSelected = false;
                        selectedUnitAccesses = [];
                    }else{
                        let removedUnitUniqueId = '';
                        props.selectedUnitAccesses.UnitAccesses.forEach(su => {
                            if(options.findIndex(m => m.UnitUniqueId == su.UnitUniqueId) < 0){
                                removedUnitUniqueId = su.UnitUniqueId;
                            }
                        });

                        if(removedUnitUniqueId == AllOptionUniqueId){
                            isAllSelected = false;
                            selectedUnitAccesses = [];
                        }else{
                            isAllSelected = false;
                            selectedUnitAccesses = [...options.filter(m => m.UnitUniqueId != AllOptionUniqueId)];
                        }
                    }
                }else{
                    //added
                    if(options.findIndex(o => o.UnitUniqueId == AllOptionUniqueId) >= 0 || (options.length == props.loginUser.UnitAccesses.filter(u => u.UnitUniqueId != AllOptionUniqueId).length)){
                        isAllSelected = true;
                        selectedUnitAccesses = [...props.loginUser.UnitAccesses.map(a => a)];
                    }else{
                        isAllSelected = false;
                        selectedUnitAccesses = [...options.map(a => a)];
                    }
                }
            }else{
                if(options.findIndex(o => o.UnitUniqueId == AllOptionUniqueId) >= 0){
                    selectedUnitAccesses = [...props.loginUser.UnitAccesses.map(a => a)];
                    isAllSelected = true;
                }else{
                    selectedUnitAccesses = [...options];
                    isAllSelected = false;
                }
            }
        }

        props.setSelectedUnitAccesses({IsAllSelected:isAllSelected,UnitAccesses:[...selectedUnitAccesses]});
    }

    const [options,setOptions] = React.useState<IUnitAccess[]>([]);

    React.useEffect(() => {
        setOptions([...props.loginUser.UnitAccesses.map(a => a)]);
    },[props.loginUser.UnitAccesses])

    return <CustomMultiSelect<IUnitAccess>
        Id='UnitAccesses'
        Label='Unit'
        Options={options}
        Value={!!props.selectedUnitAccesses && !!props.selectedUnitAccesses.UnitAccesses && props.selectedUnitAccesses.UnitAccesses.length > 0 ? [...options.filter(o => props.selectedUnitAccesses.UnitAccesses.findIndex(m => m.UnitUniqueId == o.UnitUniqueId) >= 0)] : []}
        displayField="UnitTitle"
        placeholder={!!props.selectedUnitAccesses && !!props.selectedUnitAccesses.UnitAccesses && props.selectedUnitAccesses.UnitAccesses.length > 0 ? '' : 'Unit'}
        disableCloseOnSelect={true}
        disabled={false}
        key="UnitAccessMultiSelect"
        onChange={onUnitChange}
        label="Unit"
        limitTags={1}
    />
}

const mapStateToProps = (state:IApplicationState) =>{
    return {
        loginUser:state.loginUser,
        selectedUnitAccesses:state.selectedUnitAccesses
    };
}

const mapDispatchToProps = {
    setSelectedUnitAccesses:setSelectedUnitAccessesAction
};

const connectedDropDown = connect(mapStateToProps,mapDispatchToProps)(UnitAccessMultiSelect);

export {connectedDropDown as UnitAccessMultiSelect};