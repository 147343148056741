import React from 'react';
import { useSelector } from 'react-redux';
import { MessageComponent } from 'src/components/messages.component';
import { areaApi, employeeApi, masterDataApi, routeApi } from 'src/http';
import { IMessage } from 'src/interfaces/message.interface';
import { IApplicationState } from 'src/redux';
import { confirmAction } from 'src/utility/confirm.util';
import { fCommaSeparated, fCurrency } from 'src/utils/formatNumber';
import { Audio as AudoSpin } from 'react-loader-spinner'
import Page from 'src/components/Page';
import { Container, Grid, TextField } from '@mui/material';
import { CustomAutoComplete } from 'src/components/common/CustomAutoComplete';
import { IAutoComplete } from 'src/interfaces/auto-complete.interface';
import '../page-styles/route-detail.css';
import { IRouteShopDetail } from 'src/interfaces/route-shop-detail.interface';
import { ShopsComponent } from 'src/components/ShopsComponent';
import '../extension-methods/extension-methods';
import { useLocation } from 'react-router-dom';
import { RouteDetailParam, UnAssignedOrderBookerId } from 'src/app.constant';
import { decodeBase64String, getCopy, isNumber } from 'src/utility';
import { LoadingButton } from '@mui/lab';
import { shopApi } from 'src/http/shop.http';

type Filters = 'RouteShops' | 'WithSale' | 'OtherShops' | 'TotalShops' | 'WholeSaleShops' | 'RetailShops' | 'CoveredShops' | 'NeverCoveredShops' | 'CreditShops' | 'RouteShopsInOtherRoutes';
interface ITotals {
    RouteShops: number;
    TotalSale: number;
    OtherShops: number;
    TotalShops: number;
    WholeSaleShops: number;
    RetailShops: number;
    CoveredShops: number;
    NeverCoveredShops: number;
    CreditShops: number;
    TotalCredit: number;
    RouteShopsInOtherRoutes: number;
}

export const ShopsContainer = () => {
    const initialTotals: ITotals = {
        CoveredShops: 0,
        CreditShops: 0,
        NeverCoveredShops: 0,
        OtherShops: 0,
        RetailShops: 0,
        RouteShops: 0,
        RouteShopsInOtherRoutes: 0,
        TotalCredit: 0,
        TotalSale: 0,
        TotalShops: 0,
        WholeSaleShops: 0
    };

    const search = useLocation().search;
    const queryOrderBookerId = new URLSearchParams(search).get(RouteDetailParam.OrderBookerId);
    const queryAreaId = new URLSearchParams(search).get(RouteDetailParam.AreaId);
    const queryRouteId = new URLSearchParams(search).get(RouteDetailParam.RouteId);

    const showErrorMessage = (message: string) => addMessage(message, 'error');
    const showInfoMessage = (message: string) => addMessage(message, 'info');
    const showSuccessMessage = (message: string) => addMessage(message, 'success');
    const showWarningMessage = (message: string) => addMessage(message, 'warning');
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isLoadingShops, setIsLoadingShops] = React.useState<boolean>(false);

    const [areasForAutoComplete, setAreasForAutoComplete] = React.useState<IAutoComplete[]>([]);
    const [isAreaAutoCompleteLoading, setIsAreaAutoCompleteLoading] = React.useState<boolean>(false);
    const [selectedAreaId, setSelectedAreaId] = React.useState<number>(0);
    const [orderBookersAutoComplete, setOrderBookersAutoComplete] = React.useState<IAutoComplete[]>([]);
    const [isOrderBookerAutoCompoleteLoading, setIsOrderBookerAutoCompleteLoading] = React.useState<boolean>(false);
    const [selectedOrderBookerId, setSelectedOrderBookerId] = React.useState<number>(0);
    const [routeAutoComplete, setRouteAutoComplete] = React.useState<IAutoComplete[]>([]);
    const [routeId, setRouteId] = React.useState<number>(0);
    const [isRouteAutoCompleteLoading, setIsRouteAutoCompleteLoading] = React.useState<boolean>(false);

    const [isShopTypesLoading, setIsShopTypesLoading] = React.useState<boolean>(false);
    const [shopTypes, setShopTypes] = React.useState<IAutoComplete[]>([]);
    const [selectedShopType, setSelectedShopType] = React.useState<IAutoComplete>(null);

    const [shopInfo, setShopInfo] = React.useState<IRouteShopDetail[]>([]);
    const loginUser = useSelector((state: IApplicationState) => state.loginUser);
    const [searchText, setSearchText] = React.useState<string>('');

    const [pageMessages, setPageMessages] = React.useState<IMessage[]>([]);
    const addMessage = (messageText: string, variant: 'success' | 'error' | 'info' | 'warning') => {
        if (!!messageText && messageText.trim() != '') {
            let msgs = [];
            msgs.push({
                messageText,
                variant
            });

            setPageMessages([...msgs]);
        }
    }

    const clearMessages = () => {
        setPageMessages([]);
    }

    const clearMessagesExceptSuccess = () => {
        setPageMessages((messages: IMessage[]) => [...messages.filter(pm => pm.variant == 'success')]);
    }

    const onAreaChange = (option: IAutoComplete) => {
        setSelectedAreaId(option?.Value ?? 0);
        processOnAreaChange(option?.Value ?? 0);
    }

    const processOnAreaChange = (newAreaId: number) => {
        setSelectedOrderBookerId(0);
        setOrderBookersAutoComplete([]);
        setRouteId(0);
        setRouteAutoComplete([]);
        setShopInfo([]);
        setPageMessages([]);

        if (newAreaId > 0) {
            loadOrderBookers(newAreaId, false);
        }
    }

    const onOrderBookerChange = (option: IAutoComplete) => {
        setSelectedOrderBookerId(option?.Value ?? 0);
        processOnOrderBookerChange(selectedAreaId, option?.Value ?? 0);
    }

    const processOnOrderBookerChange = (areaId: number, orderBookerId: number) => {
        setRouteId(0);
        setRouteAutoComplete([]);
        setShopInfo([]);
        setPageMessages([])

        if (orderBookerId > 0 || orderBookerId == UnAssignedOrderBookerId) {
            loadRoutes(areaId, orderBookerId, false);
        }

        if (orderBookerId === UnAssignedOrderBookerId) {
            setSelectedShopType(null);
        }
    }

    const onRouteChange = (option: IAutoComplete) => {
        setRouteId(option?.Value ?? 0);
        processOnRouteChange(selectedAreaId, selectedOrderBookerId, option?.Value ?? 0);
    }

    const processOnRouteChange = (areaId: number, orderBookerId: number, routeId: number) => {
        setShopInfo([]);
        setPageMessages([]);
        setShopInfo([]);
    }

    const onShopTypeChange = (option: IAutoComplete) => {
        setPageMessages([]);
        setShopInfo([]);
        setSelectedShopType(!!option ? getCopy(option) : null);
    }

    const onSearchTextChanged = (event: any) => {
        setPageMessages([]);
        setShopInfo([]);
        setSearchText(event.target.value);
    }

    const onSearch = () => {
        setPageMessages([]);
        setShopInfo([]);

        if (validate()) {
            loadData()
        }
    }

    const onResetFilters = () => {
        setPageMessages([])
        setSelectedOrderBookerId(0);
        setRouteId(0);
        setSelectedShopType(null);
        setRouteAutoComplete([]);
        setSearchText('');
        setShopInfo([]);
    }

    const validate = () => {
        if (!selectedAreaId || selectedAreaId <= 0) {
            showErrorMessage('Please Select Area');
            return false;
        }

        if (selectedOrderBookerId === UnAssignedOrderBookerId || selectedOrderBookerId > 0) {
            if ((!routeId || routeId <= 0) && (!selectedShopType || !selectedShopType.Value || selectedShopType.Value <= 0)) {
                showErrorMessage('Please select route or shop type');
                return false;
            }
        } else {
            if (!searchText || searchText.trim() === '') {
                showErrorMessage("Please Enter Search Text");
                return false;
            } else if (searchText.trim().length < 3 && isNumber(searchText.trim()) === true) {
                showErrorMessage("Search Number must be at least 3 characters long");
                return false;
            } else if (searchText.trim().length < 5 && isNumber(searchText.trim()) === false) {
                showErrorMessage('Search Text must be at least 5 characters long');
                return false;
            }
        }

        return true;
    }

    const getAndSetAreasAutoComplete = () => {
        setIsAreaAutoCompleteLoading(true);
        areaApi.getAreasAutoComplete(loginUser?.UserId ?? 0)
            .then(acomplete => {
                let items = [...acomplete];
                //items.unshift({ Value: 0, DisplayText: 'All' });
                setAreasForAutoComplete([...items]);

                if (!!queryAreaId && queryAreaId.trim() != '') {
                    const parsedAreaId = parseInt(decodeBase64String(queryAreaId));
                    setSelectedAreaId(parsedAreaId);
                    loadOrderBookers(parsedAreaId, true);
                } else {
                    if (!items || items.length > 1) {
                        setSelectedAreaId(0);
                    } else {
                        setSelectedAreaId(items[0].Value);
                        loadOrderBookers(items[0].Value, true);
                    }
                }
                setIsAreaAutoCompleteLoading(false);
            })
            .catch(e => {
                setAreasForAutoComplete([]);
                setIsAreaAutoCompleteLoading(false);
            });
    }

    const loadOrderBookers = (areaId: number, isFirstLoad: boolean) => {
        if (areaId > 0) {
            setIsOrderBookerAutoCompleteLoading(true);
            employeeApi.getOrderBookerAutoComplete(areaId)
                .then(res => {
                    if (!!res && res.length > 0) {
                        let items: IAutoComplete[] = [{ Value: UnAssignedOrderBookerId, DisplayText: 'Unassigned' }, ...res];
                        setOrderBookersAutoComplete([...items]);

                        if (isFirstLoad) {
                            if (!!queryOrderBookerId && queryOrderBookerId.trim() != '') {
                                const parsedOrderBookerId = parseInt(decodeBase64String(queryOrderBookerId));
                                setSelectedOrderBookerId(parsedOrderBookerId);
                                loadRoutes(areaId, parsedOrderBookerId, isFirstLoad);
                            } else {
                                if (!items || items.length > 1) {
                                    setSelectedOrderBookerId(0);
                                } else {
                                    setSelectedOrderBookerId(items[0].Value);
                                    loadRoutes(areaId, items[0].Value, isFirstLoad);
                                }
                            }
                        }
                    } else {
                        setOrderBookersAutoComplete([]);
                    }

                    setIsOrderBookerAutoCompleteLoading(false);
                })
                .catch(e => {
                    setIsOrderBookerAutoCompleteLoading(false);
                    setOrderBookersAutoComplete([]);
                });
        } else {
            setOrderBookersAutoComplete([]);
        }
    }

    const loadRoutes = (areaId: number, orderBookerId: number, isFirstLoad: boolean) => {
        if (areaId > 0) {
            setIsRouteAutoCompleteLoading(true);
            routeApi.getRouteAutoComplete(loginUser?.UserId ?? 0, areaId, orderBookerId == UnAssignedOrderBookerId ? null : orderBookerId)
                .then(res => {
                    if (!!res && res.length > 0) {
                        let items: IAutoComplete[] = [...res];
                        setRouteAutoComplete([...items]);

                        if (isFirstLoad) {
                            if (!!queryRouteId && queryRouteId.trim() != '') {
                                const parsedRouteId = parseInt(decodeBase64String(queryRouteId));
                                setRouteId(parsedRouteId);
                            } else {
                                if (!items || items.length > 1) {
                                    setRouteId(0);
                                } else {
                                    setRouteId(items[0].Value);
                                }
                            }
                        }

                    } else {
                        setRouteAutoComplete([]);
                    }
                    setIsRouteAutoCompleteLoading(false);
                })
                .catch(e => {
                    setIsRouteAutoCompleteLoading(false);
                    setRouteAutoComplete([]);
                });
        } else {
            setRouteAutoComplete([]);
        }
    }

    const loadShopTypes = () => {
        setIsShopTypesLoading(true);
        masterDataApi.getShopTypes()
            .then(res => {
                if (!!res && res.length > 0) {
                    setShopTypes(res.map(a => { return { DisplayText: a.TypeDescription, Value: a.Id } }))

                } else {
                    setShopTypes([]);
                }
                setIsShopTypesLoading(false);
            })
            .catch(e => {
                setIsShopTypesLoading(false);
                setShopTypes([]);
            });
    }

    const loadData = () => {
        setIsLoadingShops(true);
        shopApi.searchShops(selectedAreaId, selectedOrderBookerId, (selectedShopType?.Value ?? 0), (routeId ?? 0), searchText)
            .then(res => {
                if (!!res && res.length > 0) {
                    setShopInfo([...res]);
                } else {
                    resetShopDetails();
                }
                setIsLoadingShops(false)
                //setTimeout(() => {setIsLoadingShops(false)},1);
            })
            .catch(e => {
                setIsLoadingShops(false)
                resetShopDetails();
            });
    }

    const resetShopDetails = () => {
        setShopInfo([]);
    }

    const setShopsOnAction = (shops: IRouteShopDetail[]) => {
        setShopInfo(getCopy(shops));
    }

    React.useEffect(() => {
        getAndSetAreasAutoComplete();
        loadShopTypes();
    }, []);

    return <Page title="Shops | Raza Pak Care">
        <Container style={{ paddingBottom: 20, paddingTop: 10, maxWidth: '100%' }}><MessageComponent onRemove={(i: number) => {
            let msgs = [...pageMessages];
            msgs.splice(i, 1);
            setPageMessages([...msgs]);
        }} messages={pageMessages} />
        </Container>
        <Container style={{ paddingBottom: 10, maxWidth: '100%' }}>
            <Grid container spacing={2}>
                <Grid item lg={3} xl={3} md={3} xs={12}>
                    {<CustomAutoComplete<IAutoComplete>
                        Id={'Area_List_Auto_Complete'}
                        Value={!!areasForAutoComplete && areasForAutoComplete.length > 0 && selectedAreaId > 0 ? areasForAutoComplete.find(m => m.Value == selectedAreaId) : null}
                        Options={areasForAutoComplete}
                        displayField={'DisplayText'}
                        valueField={'Value'}
                        Label={'Areas'}
                        onChange={onAreaChange}
                        size='small'
                        isLoading={isAreaAutoCompleteLoading}
                        readOnly={isAreaAutoCompleteLoading || isOrderBookerAutoCompoleteLoading || isRouteAutoCompleteLoading || isLoadingShops}
                    />}
                </Grid>
                <Grid item lg={3} xl={3} md={3} xs={12}>
                    {<CustomAutoComplete<IAutoComplete>
                        Id={'Order_booker_AutoComplete'}
                        Value={!!orderBookersAutoComplete && orderBookersAutoComplete.length > 0 && selectedOrderBookerId != 0 ? orderBookersAutoComplete.find(m => m.Value == selectedOrderBookerId) : null}
                        Options={orderBookersAutoComplete ?? []}
                        displayField={'DisplayText'}
                        valueField={'Value'}
                        Label={'Order Booker'}
                        onChange={onOrderBookerChange}
                        size='small'
                        isLoading={isOrderBookerAutoCompoleteLoading}
                        readOnly={isAreaAutoCompleteLoading || isOrderBookerAutoCompoleteLoading || isRouteAutoCompleteLoading || isLoadingShops}
                    />}
                </Grid>
                <Grid item lg={3} xl={3} md={3} xs={12}>
                    {<CustomAutoComplete<IAutoComplete>
                        Id={'Route_List'}
                        Value={!!routeAutoComplete && routeAutoComplete.length > 0 && routeId > 0 ? routeAutoComplete.find(m => m.Value == routeId) : null}
                        Options={routeAutoComplete}
                        displayField={'DisplayText'}
                        valueField={'Value'}
                        Label={'Route'}
                        onChange={onRouteChange}
                        size='small'
                        isLoading={isRouteAutoCompleteLoading}
                        readOnly={isAreaAutoCompleteLoading || isOrderBookerAutoCompoleteLoading || isRouteAutoCompleteLoading || isLoadingShops}
                    />}
                </Grid>
                <Grid item lg={3} xl={3} md={3} xs={12}>
                    {<CustomAutoComplete<IAutoComplete>
                        Id={'shop_types_autocomplete'}
                        Value={selectedShopType}
                        Options={shopTypes}
                        displayField={'DisplayText'}
                        valueField={'Value'}
                        Label={'Shop Type'}
                        onChange={onShopTypeChange}
                        size='small'
                        readOnly={isLoadingShops}
                        disabled={selectedOrderBookerId === UnAssignedOrderBookerId}
                        isLoading={isShopTypesLoading}
                    />}
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: 5 }}>
                <Grid item lg={3} xl={3} md={3} xs={12}>
                    <TextField
                        fullWidth
                        autoComplete="search_text"
                        size='small'
                        variant='outlined'
                        type={'text'}
                        label="Search Here"
                        value={searchText}
                        disabled={isLoadingShops}
                        onChange={onSearchTextChanged}
                    />
                </Grid>
                <Grid item lg={3} xl={3} md={3} xs={12} style={{ justifyContent: 'flex-start', display: 'flex' }}>
                    <LoadingButton
                        size="small"
                        type="button"
                        variant="outlined"
                        loading={isLoading}
                        style={{ alignSelf: 'center', marginRight: 10, borderRadius: 0 }}
                        onClick={onSearch}
                    >
                        Search
                    </LoadingButton>

                    <LoadingButton
                        size="small"
                        type="button"
                        variant="outlined"
                        loading={isLoading}
                        disabled={false}
                        style={{ alignSelf: 'center', color: 'blue',borderColor:'blue', borderRadius: 0 }}
                        onClick={onResetFilters}
                    >
                        Reset
                    </LoadingButton>
                </Grid>
            </Grid>
        </Container>

        <ShopsComponent
            isLoading={isLoadingShops}
            shops={shopInfo}
            filter={null}
            areaId={selectedAreaId}
            loginUserId={loginUser?.UserId ?? 0}
            setFilter={() => { }}
            showErrorMessage={showErrorMessage}
            showInfoMessage={showInfoMessage}
            showSuccessMessage={showSuccessMessage}
            showWarningMessage={showWarningMessage}
            setShopsOnAction={setShopsOnAction}
            key={`ShopsComponentKey`}
            searchText={searchText}
            isRouteDetailPage={false}
            selectedRouteId={routeId}
        />
    </Page>;
}