import { INewOrderReportSetting } from 'src/interfaces/new-order-report-setting.interface';
import {NewOrderReportPrintSetting} from '../actions/action.constant';
import {IAction} from '../actions/action.interface';
import {initialState} from '../initial-state';

export const newOrderReportPrintSettingReducer = (
  state = initialState.newOrderReportPrintSetting,
  action: IAction<INewOrderReportSetting>,
) => {
  switch (action.type) {
    case NewOrderReportPrintSetting.SET_NEW_ORDER_REPORT_PRINT_SETTING:
      let newState: INewOrderReportSetting = {...action.payload};
      return newState;
    default:
      return state;
  }
};
