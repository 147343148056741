import {IAction} from './action.interface';
import {LoginUserActionConstants} from './action.constant';
import {IUserDetail} from '../../interfaces';

export const setLoginUserDetailAction = (
  payload: IUserDetail | null,
): IAction<IUserDetail | null> => {
  return {
    type: LoginUserActionConstants.SET_LOGIN_USER_DETAIL,
    payload,
  };
};
