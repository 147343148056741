import {OrderBookerInfoConstants} from '../actions/action.constant';
import {IAction} from '../actions/action.interface';
import {initialState} from '../initial-state';

export const selectedAreaIdReducer = (
  state: number = initialState.selectedAreaId,
  action: IAction<number>,
) => {
  switch (action.type) {
    case OrderBookerInfoConstants.SET_SELECTED_AREA_ID: {
      return action.payload;
    }
    default:
      return state;
  }
};
