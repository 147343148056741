import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { generateUUID } from 'src/utility';

interface ITab{
    title:string;
    keyName:string;
    order:number;
}

interface IProps {
  tabs:ITab[];
  selected:string;
  widthtPercent?:string;
  onChange(keyName:string);
}

export const TabControl = (props:IProps) => {
  const [value, setValue] = React.useState<string>(props.selected);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    props.onChange(newValue);
  };

  return (
    <Box sx={{ width: !!props.widthtPercent ? props.widthtPercent : '100%', fontSize:11 }}>
      <Box sx={{ borderBottom: 1, width:'100%', borderColor: 'divider', marginBottom:3 }}>
        <Tabs variant='fullWidth' value={value} onChange={handleChange}>
          {!!props.tabs && props.tabs.sort((a,b) => a.order - b.order).map((tab,i) => <Tab key={generateUUID()} label={tab.title} value={tab.keyName} />)}
        </Tabs>
      </Box>
    </Box>
  );
}
