import {TokenActionConstants} from '../actions/action.constant';
import {IAction} from '../actions/action.interface';
import {ITokenResult} from '../../interfaces';
import {initialState} from '../initial-state';

export const tokenReducer = (
  state = initialState.token,
  action: IAction<ITokenResult>,
) => {
  switch (action.type) {
    case TokenActionConstants.SET_TOKEN:
      let newState: ITokenResult = {...action.payload};
      return newState;
    default:
      return state;
  }
};
