import { Grid } from '@mui/material';
import React from 'react';
import { IOrderNew } from 'src/interfaces/reports/order-new.interface';
import { formattedDate } from 'src/utility';
import { fCurrency } from 'src/utils/formatNumber';

interface IProps {
    lastMonthTarget:IOrderNew;
    currentMonthTarget:IOrderNew;
    currentDayTarget:IOrderNew;
    dated:Date;
    lastMonthFromDate:Date;
    lastMonthToDate:Date;
    currentMonthFromDate:Date;
    currentMonthToDate:Date;
}

export const SaleInfoComponent = (props: IProps) => {
    const doAmountColor = 'orange';
    const returnAmountColor = 'red';
    const delieveredAmountColor = 'blue';
    const doCashAmountColor = 'green';
    const doCreditAmountColor = 'red';
    const recoveryAmountColor = 'green';
    const totalCashAmountColor = 'green';

    return <span>
        <Grid container>
            <Grid item md={12}>
                {<Grid container>
                    <table border={1} cellSpacing={0} cellPadding={5} style={{ width: '100%', fontSize: 11, marginBottom: 10, marginTop: 10 }}>
                        <tbody>
                            <tr>
                                <th style={{ padding: 7, color:'blue' }} colSpan={9} align={'center'}>{`DO Detail (${props.currentDayTarget?.FirstName ?? ''} ${props.currentDayTarget?.LastName ?? ''})`}</th>
                            </tr>
                            <tr>
                                <th style={{ padding: 7 }} colSpan={3} align={'center'}>Last Month DO Detail<br /> ({formattedDate(props.lastMonthFromDate,'DD-MMM-YYYY')} to {formattedDate(props.lastMonthToDate, 'DD-MMM-YYYY')})</th>
                                <th style={{ padding: 7 }} colSpan={3} align={'center'}>Current Month DO Detail<br /> ({formattedDate(props.currentMonthFromDate,'DD-MMM-YYYY')} to {formattedDate(props.currentMonthToDate, 'DD-MMM-YYYY')})</th>
                                <th style={{ padding: 7 }} colSpan={3} align={'center'}>Current Day DO Detail<br /> ({formattedDate(props.dated, 'DD-MMM-YYYY')})</th>
                            </tr>

                            <tr>
                                <td style={{ padding: 7 }}>DO Amount:</td>
                                <td colSpan={2} style={{ padding: 7, color:doAmountColor }} align='center'>{fCurrency(props.lastMonthTarget?.DoAmount ?? 0)}</td>
                                <td style={{ padding: 7 }}>DO Amount:</td>
                                <td colSpan={2} style={{ padding: 7, color:doAmountColor }} align='center'>{fCurrency(props.currentMonthTarget?.DoAmount ?? 0)}</td>
                                <td style={{ padding: 7 }}>DO Amount:</td>
                                <td colSpan={2} style={{ padding: 7, color:doAmountColor }} align='center'>{fCurrency(props.currentDayTarget?.DoAmount ?? 0)}</td>
                            </tr>

                            <tr>
                                <td style={{ padding: 7 }}>Return Amount:</td>
                                <td style={{ padding: 7, color:returnAmountColor }}>{fCurrency((props.lastMonthTarget?.DoAmount ?? 0) - (props.lastMonthTarget?.DoDeliveredAmount ?? 0))}</td>
                                <td style={{ padding: 7, color:returnAmountColor }}>{`${parseFloat(((((props.lastMonthTarget?.DoAmount ?? 0) - (props.lastMonthTarget?.DoDeliveredAmount ?? 0)) / ((props.lastMonthTarget?.DoAmount ?? 1) > 0 ? (props.lastMonthTarget?.DoAmount ?? 1) : 1)) * 100).toString()).toFixed(2)} %`}</td>
                                <td style={{ padding: 7 }}>Return Amount:</td>
                                <td style={{ padding: 7, color:returnAmountColor }}>{fCurrency((props.currentMonthTarget?.DoAmount ?? 0) - (props.currentMonthTarget?.DoDeliveredAmount ?? 0))}</td>
                                <td style={{ padding: 7, color:returnAmountColor }}>{`${parseFloat(((((props.currentMonthTarget?.DoAmount ?? 0) - (props.currentMonthTarget?.DoDeliveredAmount ?? 0)) / ((props.currentMonthTarget?.DoAmount ?? 1) > 0 ? (props.currentMonthTarget?.DoAmount ?? 1) : 1)) * 100).toString()).toFixed(2)} %`}</td>
                                <td style={{ padding: 7 }}>Return Amount:</td>
                                <td style={{ padding: 7, color:returnAmountColor }}>{fCurrency((props.currentDayTarget?.DoAmount ?? 0) - (props.currentDayTarget?.DoDeliveredAmount ?? 0))}</td>
                                <td style={{ padding: 7, color:returnAmountColor }}>{`${parseFloat(((((props.currentDayTarget?.DoAmount ?? 0) - (props.currentDayTarget?.DoDeliveredAmount ?? 0)) / ((props.currentDayTarget?.DoAmount ?? 1) > 0 ? (props.currentDayTarget?.DoAmount ?? 1) : 1)) * 100).toString()).toFixed(2)} %`}</td>
                            </tr>

                            <tr>
                                <td style={{ padding: 7 }}>Delievred Amount:</td>
                                <td style={{ padding: 7, color:delieveredAmountColor }}>{fCurrency(props.lastMonthTarget?.DoDeliveredAmount ?? 0)}</td>
                                <td style={{ padding: 7, color:delieveredAmountColor }}>{`${parseFloat((((props.lastMonthTarget?.DoDeliveredAmount ?? 0) / ((props.lastMonthTarget?.DoAmount ?? 1) > 0 ? (props.lastMonthTarget?.DoAmount ?? 1) : 1)) * 100).toString()).toFixed(2)} %`}</td>
                                <td style={{ padding: 7 }}>Delievred Amount:</td>
                                <td style={{ padding: 7, color:delieveredAmountColor }}>{fCurrency(props.currentMonthTarget?.DoDeliveredAmount ?? 0)}</td>
                                <td style={{ padding: 7, color:delieveredAmountColor }}>{`${parseFloat((((props.currentMonthTarget?.DoDeliveredAmount ?? 0) / ((props.currentMonthTarget?.DoAmount ?? 1) > 0 ? (props.currentMonthTarget?.DoAmount ?? 1) : 1)) * 100).toString()).toFixed(2)} %`}</td>
                                <td style={{ padding: 7 }}>Delievred Amount:</td>
                                <td style={{ padding: 7, color:delieveredAmountColor }}>{fCurrency(props.currentDayTarget?.DoDeliveredAmount ?? 0)}</td>
                                <td style={{ padding: 7, color:delieveredAmountColor }}>{`${parseFloat((((props.currentDayTarget?.DoDeliveredAmount ?? 0) / ((props.currentDayTarget?.DoAmount ?? 1) > 0 ? (props.currentDayTarget?.DoAmount ?? 1) : 1)) * 100).toString()).toFixed(2)} %`}</td>
                            </tr>

                            <tr>
                                <td style={{ padding: 7 }}>DO Cash:</td>
                                <td style={{ padding: 7, color:doCashAmountColor }}>{fCurrency((props.lastMonthTarget?.DoDeliveredAmount ?? 0) - (props.lastMonthTarget?.DoCreditAmount ?? 0))}</td>
                                <td style={{ padding: 7, color:doCashAmountColor }}>{`${parseFloat(((((props.lastMonthTarget?.DoDeliveredAmount ?? 0) - (props.lastMonthTarget?.DoCreditAmount ?? 0)) / ((props.lastMonthTarget?.DoDeliveredAmount ?? 1) > 0 ? (props.lastMonthTarget?.DoDeliveredAmount ?? 1) : 1)) * 100).toString()).toFixed(2)} %`}</td>
                                <td style={{ padding: 7 }}>DO Cash:</td>
                                <td style={{ padding: 7, color:doCashAmountColor }}>{fCurrency((props.currentMonthTarget?.DoDeliveredAmount ?? 0) - (props.currentMonthTarget?.DoCreditAmount ?? 0))}</td>
                                <td style={{ padding: 7, color:doCashAmountColor }}>{`${parseFloat(((((props.currentMonthTarget?.DoDeliveredAmount ?? 0) - (props.currentMonthTarget?.DoCreditAmount ?? 0)) / ((props.currentMonthTarget?.DoDeliveredAmount ?? 1) > 0 ? (props.currentMonthTarget?.DoDeliveredAmount ?? 1) : 1)) * 100).toString()).toFixed(2)} %`}</td>
                                <td style={{ padding: 7 }}>DO Cash:</td>
                                <td style={{ padding: 7, color:doCashAmountColor }}>{fCurrency((props.currentDayTarget?.DoDeliveredAmount ?? 0) - (props.currentDayTarget?.DoCreditAmount ?? 0))}</td>
                                <td style={{ padding: 7, color:doCashAmountColor }}>{`${parseFloat(((((props.currentDayTarget?.DoDeliveredAmount ?? 0) - (props.currentDayTarget?.DoCreditAmount ?? 0)) / ((props.currentDayTarget?.DoDeliveredAmount ?? 1) > 0 ? (props.currentDayTarget?.DoDeliveredAmount ?? 1) : 1)) * 100).toString()).toFixed(2)} %`}</td>
                            </tr>

                            <tr>
                                <td style={{ padding: 7 }}>DO Credit:</td>
                                <td style={{ padding: 7,color:doCreditAmountColor }}>{fCurrency(props.lastMonthTarget?.DoCreditAmount ?? 0)}</td>
                                <td style={{ padding: 7,color:doCreditAmountColor }}>{`${parseFloat((((props.lastMonthTarget?.DoCreditAmount ?? 0) / ((props.lastMonthTarget?.DoDeliveredAmount ?? 1) > 0 ? (props.lastMonthTarget?.DoDeliveredAmount ?? 1) : 1)) * 100).toString()).toFixed(2)} %`}</td>
                                <td style={{ padding: 7 }}>DO Credit:</td>
                                <td style={{ padding: 7,color:doCreditAmountColor }}>{fCurrency(props.currentMonthTarget?.DoCreditAmount ?? 0)}</td>
                                <td style={{ padding: 7,color:doCreditAmountColor }}>{`${parseFloat((((props.currentMonthTarget?.DoCreditAmount ?? 0) / ((props.currentMonthTarget?.DoDeliveredAmount ?? 1) > 0 ? (props.currentMonthTarget?.DoDeliveredAmount ?? 1) : 1)) * 100).toString()).toFixed(2)} %`}</td>
                                <td style={{ padding: 7 }}>DO Credit:</td>
                                <td style={{ padding: 7,color:doCreditAmountColor }}>{fCurrency(props.currentDayTarget?.DoCreditAmount ?? 0)}</td>
                                <td style={{ padding: 7,color:doCreditAmountColor }}>{`${parseFloat((((props.currentDayTarget?.DoCreditAmount ?? 0) / ((props.currentDayTarget?.DoDeliveredAmount ?? 1) > 0 ? (props.currentDayTarget?.DoDeliveredAmount ?? 1) : 1)) * 100).toString()).toFixed(2)} %`}</td>
                            </tr>

                            <tr>
                                <td style={{ padding: 7 }}>Recovery:</td>
                                <td colSpan={2} style={{ padding: 7, color:recoveryAmountColor }} align='center'>{fCurrency(props.lastMonthTarget?.EmployeeRecovery ?? 0)}</td>
                                <td style={{ padding: 7 }}>Recovery:</td>
                                <td colSpan={2} style={{ padding: 7, color:recoveryAmountColor }} align='center'>{fCurrency(props.currentMonthTarget?.EmployeeRecovery ?? 0)}</td>
                                <td style={{ padding: 7 }}>Recovery:</td>
                                <td colSpan={2} style={{ padding: 7, color:recoveryAmountColor }} align='center'>{fCurrency(props.currentDayTarget?.EmployeeRecovery ?? 0)}</td>
                            </tr>

                            <tr>
                                <td style={{ padding: 7 }}>Difference:</td>
                                <td colSpan={2} style={{ padding: 7, color:((props.lastMonthTarget?.EmployeeRecovery ?? 0) - (props.lastMonthTarget?.DoCreditAmount ?? 0)) > 0 ? 'green' : 'red' }} align='center'>{fCurrency((props.lastMonthTarget?.EmployeeRecovery ?? 0) - (props.lastMonthTarget?.DoCreditAmount ?? 0))}</td>
                                <td style={{ padding: 7 }}>Difference:</td>
                                <td colSpan={2} style={{ padding: 7, color:((props.currentMonthTarget?.EmployeeRecovery ?? 0) - (props.currentMonthTarget?.DoCreditAmount ?? 0)) > 0 ? 'green' : 'red' }} align='center'>{fCurrency((props.currentMonthTarget?.EmployeeRecovery ?? 0) - (props.currentMonthTarget?.DoCreditAmount ?? 0))}</td>
                                <td style={{ padding: 7 }}>Difference:</td>
                                <td colSpan={2} style={{ padding: 7, color:((props.currentDayTarget?.EmployeeRecovery ?? 0) - (props.currentDayTarget?.DoCreditAmount ?? 0)) > 0 ? 'green' : 'red' }} align='center'>{fCurrency((props.currentDayTarget?.EmployeeRecovery ?? 0) - (props.currentDayTarget?.DoCreditAmount ?? 0))}</td>
                            </tr>

                            <tr>
                                <td style={{ padding: 7 }}>Total Cash:</td>
                                <td colSpan={2} style={{ padding: 7, color:totalCashAmountColor }} align='center'>{fCurrency(((props.lastMonthTarget?.DoDeliveredAmount ?? 0) - (props.lastMonthTarget?.DoCreditAmount ?? 0)) + (props.lastMonthTarget?.EmployeeRecovery ?? 0))}</td>
                                <td style={{ padding: 7 }}>Total Cash:</td>
                                <td colSpan={2} style={{ padding: 7, color:totalCashAmountColor }} align='center'>{fCurrency(((props.currentMonthTarget?.DoDeliveredAmount ?? 0) - (props.currentMonthTarget?.DoCreditAmount ?? 0)) + (props.currentMonthTarget?.EmployeeRecovery ?? 0))}</td>
                                <td style={{ padding: 7 }}>Total Cash:</td>
                                <td colSpan={2} style={{ padding: 7, color:totalCashAmountColor }} align='center'>{fCurrency(((props.currentDayTarget?.DoDeliveredAmount ?? 0) - (props.currentDayTarget?.DoCreditAmount ?? 0)) + (props.currentDayTarget?.EmployeeRecovery ?? 0))}</td>
                            </tr>
                        </tbody>
                    </table>
                </Grid>}
            </Grid>
        </Grid>
    </span>;
}