import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IApplicationState } from 'src/redux';
import { setNewOrderReportPrintSettingAction } from 'src/redux/actions/new-order-report-print-setting.action';
import { connect } from 'react-redux';
import { INewOrderReportSetting } from 'src/interfaces/new-order-report-setting.interface';
import { IAction } from 'src/redux/actions/action.interface';
import { Checkbox, Container, FormControlLabel, Grid, TextField } from '@mui/material';
import { NewOrderReportPrintSetting, NewOrderReportPrintSettings, UnAssignedOrderBookerId } from 'src/app.constant';
import LoadingButton from '@mui/lab/LoadingButton';
import { IAutoComplete } from 'src/interfaces/auto-complete.interface';
import { CustomAutoComplete } from './common/CustomAutoComplete';
import { Icon } from '@iconify/react';
import { IShopRouteChangeParam } from 'src/interfaces/shop-route-change-param.interface';
import { employeeApi, routeApi } from 'src/http';
import { shopApi } from 'src/http/shop.http';
import { IMapPopUpConfirmation } from 'src/interfaces/map-pop-confirmation.interface';

interface IProps {
    params:IMapPopUpConfirmation;
    onClose: () => void;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width:500,
    bgcolor: 'background.paper',
    border: '3px solid #660000',
    boxShadow: 24,
    paddingTop: 2,
    paddingBottom: 2
};

export const MapConfirmationPopup = (props: IProps) => {
    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick") {
            return;
        }

        props.onClose();
    }

    return <Modal
        open={!!props.params}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disablePortal
    >
        <Box sx={style}>
            <Grid item md={12} style={{ justifyContent: 'center', display: 'flex' }}>
                <Typography style={{ alignSelf: 'center', color:!!props.params?.titleColor ? props.params?.titleColor : undefined }} id="modal-modal-title" variant="h6" component="h2">
                    {props.params?.title ?? ''}
                </Typography>
            </Grid>

            <Container>
                <Grid container spacing={2} style={{ marginTop: 5 }}>
                    <Grid item sm={12}>
                        <Typography style={{ color:!!props.params?.messageColor ? props.params?.messageColor : undefined }}>{props.params?.message ?? ''}</Typography>
                    </Grid>
                </Grid>
                <Grid item md={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                <LoadingButton
                        size="small"
                        type="button"
                        variant="outlined"
                        loading={false}
                        style={{ marginTop: 15, color: props.params?.yesButtonColor ?? 'green',borderColor: props.params?.yesButtonColor ?? 'green', alignSelf: 'center', borderRadius: 0, marginRight:10 }}
                        onClick={() =>{
                            props.params.onOk();
                        }}
                    >
                        {props.params?.yesButtonText ?? 'Yes'}
                    </LoadingButton>
                    <LoadingButton
                        size="small"
                        type="button"
                        variant="outlined"
                        loading={false}
                        disabled={false}
                        style={{ marginTop: 15, alignSelf: 'center', color: props.params?.noButtonColor ?? 'gray', borderRadius: 0, borderColor: props.params?.noButtonColor ?? 'gray' }}
                        onClick={props.onClose}
                    >
                        {props.params?.noButtonText ?? 'No'}
                    </LoadingButton>
                </Grid>
            </Container>
        </Box>
    </Modal>;
}