import * as React from 'react';
import { TabControl } from 'src/components/common/TabControl';
import { CreditReceiveHistory } from './CreditReceiveHistory';
import { ReceiveCredit } from './ReceiveCredit';
import { generateUUID } from 'src/utility';
import { OrderBookerWiseCredit } from 'src/components/credit/OrderBookerWiseCredit';
import { TableContainer } from '@mui/material';
import useWindowDimensions from 'src/components/common/useWindowDimentions';
import { DateWiseCredit } from './DateWiseCredit';
import { IApplicationState } from 'src/redux';
import { connect } from 'react-redux';
import { IUserRouteDetail } from 'src/interfaces/routes/user-route-detail.interface';
import { getUrlFromComponentKey } from 'src/utility/navigation.util';
import { Keys } from 'src/component.keys';
import { CreditHistory } from './CreditHistory';
import { OrdersOldComponent } from 'src/components/orders/orders-old.component';
import { UnitOrdersMain } from 'src/components/orders/unit-orders-main.component';
import { OrdersNewReport } from './OrdersNewReport';
import { RecoveryTargetNewContainer } from './RecoveryTargetNewContainer';

interface IProps{
    userRoutes:IUserRouteDetail;
}

interface IWindoDimension{
    width:number;
    height:number;
}

const Orders = (props:IProps) => {
    const tabKeys = {
        OrdersOld: 'OrdersOld',
        OrdersNew: 'OrdersNew',
        DailyReport:'DailyReport',
        RecoveryTarget:'RecoveryTarget'
    };
    const [tabs,setTabs] = React.useState<any[]>([
        {
            title: 'Daily & Monthly Performance Report',
            keyName: tabKeys.DailyReport,
            order: 1
        },
        {
            title: 'Recovery Target',
            keyName: tabKeys.RecoveryTarget,
            order: 2
        },
    {
        title: 'Orders',
        keyName: tabKeys.OrdersOld,
        order: 3
    },
    {
        title: 'Orders Report',
        keyName: tabKeys.OrdersNew,
        order: 4
    }]);

    const OrdersKey = 'OrdersNewParentComponentKey';

    const [dateWiseCreditPageUrl, setDateWiseCreditPageUrl] = React.useState<string>('');
    const [creditReceiveHistoryPageUrl, setCreditReceiveHistoryPageUrl] = React.useState<string>('');

    const [selectedTab, setSelectedTab] = React.useState<string>();

    const handleChange = (newValue: string) => {
        setSelectedTab(newValue);
    };

    //const [dimensions,setDimentions] = React.useState<IWindoDimension>(useWindowDimensions());
    const dimensions = useWindowDimensions();

    React.useEffect(() =>{
        let editedTabs = [...tabs.map(a => a)];
        let keysToShow = [];
        if(!!props.userRoutes && !!props.userRoutes.Routes && props.userRoutes.Routes.length > 0){
            let url = getUrlFromComponentKey(props.userRoutes,Keys.OrdersOldComponent);
            if(!!url && url.trim() != ''){
                keysToShow.push(tabKeys.OrdersOld);
            }

            url = getUrlFromComponentKey(props.userRoutes,Keys.UnitOrdersMain);

            if(!!url && url.trim() != ''){
                keysToShow.push(tabKeys.OrdersNew);
            }

            url = getUrlFromComponentKey(props.userRoutes,Keys.NewOrderReport);

            if(!!url && url.trim() != ''){
                keysToShow.push(tabKeys.DailyReport);
            }

            url = getUrlFromComponentKey(props.userRoutes,Keys.RecoveryTarget);

            if(!!url && url.trim() != ''){
                keysToShow.push(tabKeys.RecoveryTarget);
            }
        }

        let newTabs = [...editedTabs.filter(m => keysToShow.length > 0 && keysToShow.indexOf(m.keyName) >= 0).map(a => a)];
        setTabs([...newTabs]);

        if(newTabs !== undefined && newTabs != null && newTabs.length > 0){
            setSelectedTab(newTabs[0].keyName);
        }

    },[props.userRoutes]);

    const TableContainerRef = React.useRef(null);
    const scrollToTop = () => {
        TableContainerRef.current.scrollTo(0,0);
    }

    return (
        <React.Fragment key={`${OrdersKey}_1`}>
            {!!tabs && tabs.length > 0 && !!selectedTab && selectedTab.trim() != '' && <TabControl widthtPercent='80%' key={`${OrdersKey}_2`} tabs={tabs} selected={selectedTab} onChange={handleChange} />}
            <TableContainer key={`${OrdersKey}_3`} ref={TableContainerRef} style={{height:dimensions.height - 130, width:'100%'}}>
                {selectedTab == tabKeys.OrdersOld && <OrdersOldComponent key={`${OrdersKey}_4`} />}
                {selectedTab == tabKeys.OrdersNew && <UnitOrdersMain key={`${OrdersKey}_5`} />}
                {selectedTab == tabKeys.DailyReport && <OrdersNewReport key={`${OrdersKey}_7`} />}
                {selectedTab == tabKeys.RecoveryTarget && <RecoveryTargetNewContainer key={`${OrdersKey}_8`} />}
            </TableContainer>
        </React.Fragment>
    );
}

const mapStateToProps = (state: IApplicationState) => {
    return {
        userRoutes:state.userRoutes
    };
}
const connectedOrders = connect(mapStateToProps)(Orders);
export { connectedOrders as Orders };
