import React from 'react';
import { useSelector } from 'react-redux';
import { MessageComponent } from 'src/components/messages.component';
import {  employeeApi, orderApi } from 'src/http';
import { IMessage } from 'src/interfaces/message.interface';
import { IApplicationState } from 'src/redux';
import { Audio as AudoSpin } from 'react-loader-spinner'
import Page from 'src/components/Page';
import {  Checkbox, Container, Grid } from '@mui/material';
import { CustomAutoComplete } from 'src/components/common/CustomAutoComplete';
import { IAutoComplete } from 'src/interfaces/auto-complete.interface';
import { IMapShop } from 'src/interfaces/map-shop.interface';
import CustomDatePicker from 'src/components/common/CustomDatePicker';
import { ITrackingResult } from 'src/interfaces/tracking/tracking-result.interface';
import { ITrackingRouteDetail } from 'src/interfaces/tracking/tracking-route-detail.interface';
import { LoadingButton } from '@mui/lab';
import { ITrackingEmployeeLocation } from 'src/interfaces/tracking/tracking-employee-location.interface';
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { ITrackingShopDetail } from 'src/interfaces/tracking/tracking-shop-detail.interface';
import { decodeBase64String, formattedDate, generateUUID, getCommaSeparatedUnitUniqueIdsExceptAll, getDistanceInMeters } from 'src/utility';
import Slider from '@mui/material/Slider';
import {useLocation} from "react-router-dom";
import { IOrderNew } from 'src/interfaces/reports/order-new.interface';
import { fCommaSeparatedWithDecial, fCurrency } from 'src/utils/formatNumber';
import { TargetInfoComponent } from 'src/components/target-info.component';
import { SaleInfoComponent } from 'src/components/sale-info.component';
import { TargetAndSaleInfoComponent } from 'src/components/target-sale-info.component';
import { UserRoutesInfoComponent } from 'src/components/user-routes-info.component';

interface IRoutePolyOnMap {
  routeId: number;
  polygon: google.maps.Polygon
}

interface IShopAnalytics{
  TotalShops:number;
  NoOrderShops:number;
  NeverOrderShops:number;
  TodayFirstOrderShops:number;
  OrderShops:number;
  RemaingShops:number;
  VisitedShops:number;
  ConveredShops:number;
}

export const TrackingMapComponent = ({
  center,
  zoom
}: {
  center: google.maps.LatLngLiteral;
  zoom: number;
}) => {
  const ref = React.useRef();
  const state = useSelector((s: IApplicationState) => s);
  const [trackingResults, _setTrackingResults] = React.useState<ITrackingResult>();
  const trackingResultsRef = React.useRef(trackingResults);

  const setTrackingResults = (result:ITrackingResult) =>{
    trackingResultsRef.current = result;
    _setTrackingResults(result);
  }

  const search = useLocation().search;
  const queryOBId = new URLSearchParams(search).get('orderBooker');


  const [shopsMarker, _setShopsMarker] = React.useState<google.maps.Marker[]>([]);
  const shopsMarkerRef = React.useRef(shopsMarker);
  const [routePoliesOnMap, _setRoutePoliesOnMap] = React.useState<IRoutePolyOnMap[]>([]);
  const routePoliesOnMapRef = React.useRef(routePoliesOnMap);

  const [trackingMarker, _setTrackingMarker] = React.useState<google.maps.Marker[]>([]);
  const trackingMarkerRef = React.useRef(trackingMarker);
  const [shopsAnalytics, setShopsAnalytics] = React.useState<IShopAnalytics>({
    NeverOrderShops:0,
    NoOrderShops:0,
    OrderShops:0,
    RemaingShops:0,
    TodayFirstOrderShops:0,
    TotalShops:0,
    VisitedShops:0,
    ConveredShops:0,
  });

  const setTrackingMarker = (tMarkers: google.maps.Marker[]) => {
    trackingMarkerRef.current = tMarkers;
    _setTrackingMarker(tMarkers);
  }

  const [onClickMarker, _setOnClickMarker] = React.useState<google.maps.Marker[]>([]);
  const onClickMarkerRef = React.useRef(onClickMarker);

  const setOnClickMarker = (tMarkers: google.maps.Marker[]) => {
    onClickMarkerRef.current = tMarkers;
    _setOnClickMarker(tMarkers);
  }

  const setRoutePoliesOnMap = (data: IRoutePolyOnMap[]) => {
    routePoliesOnMapRef.current = data;
    _setRoutePoliesOnMap(data);
  }

  const setShopsMarker = (shopMarkers: google.maps.Marker[]) => {
    shopsMarkerRef.current = shopMarkers;
    _setShopsMarker(shopMarkers);
  }

  const [markerClusterer, setMarkerClusterer] = React.useState<MarkerClusterer>();

  const getTrackingResults = (map: google.maps.Map, orderBookerId: number) => {
    setIsLoading(true);
    refreshOrderReport(orderBookerId);
    orderApi.getTrackingResult(state.loginUser?.UserId ?? 0, orderBookerId, fromDate)
      .then(result => {
        drawPolygons(map, result.RouteDetail);
        handleRedGreen(isGreenChecked, isRedChecked, result.ShopDetails);
        refreshShopsMarkers(map, result.ShopDetails);
        //addTrackingMarkers(map, result.EmployeeTrackings);
        clearMessagesExceptSuccess();
        setIsLoading(false);
        setTrackingResults(result);
        updateAnalytics(result);
      })
      .catch(e => {
        setIsLoading(false);
        console.log(e)
        showErrorMessage('Unable To Fetch Details. Please Check Your Internet And Refresh Page Again.');
      });
  }

  const [orders, setOrders] = React.useState<IOrderNew[]>([]);

  const refreshOrderReport = (obId:number) => {
    setTargetRefreshToken(generateUUID());
    setRouteRefreshToken(generateUUID());
    // const todayDated: Date = new Date();
    // orderApi.getNewOrdersReport(loginUser.UserId, getCommaSeparatedUnitUniqueIdsExceptAll(unitAccesses.UnitAccesses), todayDated, todayDated, obId)
    //         .then(results => {
    //             if (!!results) {
    //                 setOrders([...results]);
    //             }
    //             else {
    //                 setOrders([]);
    //             }
    //         })
    //         .catch(e => {
    //             setOrders([]);
    //         });
  }

  const clearShopMarkers = () =>{
    if (!!shopsMarkerRef.current && shopsMarkerRef.current.length > 0) {
        shopsMarkerRef.current.forEach(sm => {
          sm.setMap(null);
        });
      }

      setShopsMarker([]);
  }

  const updateAnalytics = (results:ITrackingResult) =>{
    let shopsA:IShopAnalytics = {
      NeverOrderShops:0,
      NoOrderShops:0,
      OrderShops:0,
      RemaingShops:0,
      TodayFirstOrderShops:0,
      TotalShops:0,
      VisitedShops:0,
      ConveredShops:0
    };
    if(!!results && !!results.ShopDetails && results.ShopDetails.length > 0){
      shopsA.TotalShops = results.ShopDetails.length;
      const visitedShops = results.ShopDetails.filter(m => m.IsVisited == true);
      
      if(!!visitedShops && visitedShops.length > 0){
        shopsA.VisitedShops = visitedShops.length;
        shopsA.RemaingShops = shopsA.TotalShops - (visitedShops.length);

        const noOrderShops = visitedShops.filter(n => n.HaveOrderToday == undefined || n.HaveOrderToday == null || n.HaveOrderToday == false);

        if(!!noOrderShops && noOrderShops.length > 0){
          shopsA.NoOrderShops = noOrderShops.length;
        }

        const orderShops = visitedShops.filter(t => t.HaveOrderToday == true);
        
        if(!!orderShops && orderShops.length > 0){
          shopsA.OrderShops = (orderShops.length);

          const todayFirstOrderShops = orderShops.filter(f => f.HaveOrderPrevious == undefined || f.HaveOrderPrevious == null || f.HaveOrderPrevious == false );

          if(!!todayFirstOrderShops && todayFirstOrderShops.length > 0){
            shopsA.TodayFirstOrderShops = todayFirstOrderShops.length;
          }
        }
      }

      const coveredShops = results.ShopDetails.filter(c => c.HaveOrderToday == true || c.HaveOrderPrevious == true);

      if(!!coveredShops && coveredShops.length > 0){
        shopsA.ConveredShops = coveredShops.length;
      }

      const neverOrderShops = results.ShopDetails.filter(nt => (nt.HaveOrderPrevious === undefined || nt.HaveOrderPrevious == null || nt.HaveOrderPrevious == false) && (nt.HaveOrderToday === undefined || nt.HaveOrderToday == null || nt.HaveOrderToday == false));

          if(!!neverOrderShops && neverOrderShops.length > 0){
            shopsA.NeverOrderShops = neverOrderShops.length;
          }

      setShopsAnalytics({...shopsA});
      
    }
  }

  const svgMarker = {
    path: "M12,2 C8.13,2 5,5.13 5,9 C5,13.17 9.42,18.92 11.24,21.11 C11.64,21.59 12.37,21.59 12.77,21.11 C14.58,18.92 19,13.17 19,9 C19,5.13 15.87,2 12,2 Z M12,11.5 C10.62,11.5 9.5,10.38 9.5,9 C9.5,7.62 10.62,6.5 12,6.5 C13.38,6.5 14.5,7.62 14.5,9 C14.5,10.38 13.38,11.5 12,11.5 Z",
    fillColor: "blue",
    fillOpacity: 0.6,
    strokeWeight: 0,
    rotation: 0,
    scale: 2,
    anchor: new google.maps.Point(0, 20),
  };

  const refreshShopsMarkers = (map: google.maps.Map, shops: ITrackingShopDetail[]) => {
    clearShopMarkers();
    if (!!shops && shops.length > 0) {
      let markers: google.maps.Marker[] = [];
      shops.forEach(s => {
        let infowindow = new google.maps.InfoWindow({
            content: `<div style="width:300px;">
                        <div style="width:300px;text-align:center;">
                          <h3>
                          ${s.ShopName}
                          </h3>
                        </div>
                        <table width="300px" style="margin-top:5px;">
                        <tr>
                          <td style="padding-bottom:2px;width:100px;">
                            <strong>Contact Person:</strong>
                          </td>
                          <td style="padding-left:10px;padding-bottom:2px;">
                            <strong>${s.OwnerName ?? 0}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td style="padding-bottom:2px;">
                            <strong>Cell#:</strong>
                          </td>
                          <td style="padding-left:10px;padding-bottom:2px;">
                            <strong>${s.CellOne !== undefined && s.CellOne != null ? `${s.CellOne}` : ''} ${s.CellTwo !== undefined && s.CellTwo != null && s.CellTwo.trim() != '' ? (`${s.CellOne !== undefined && s.CellOne != null && s.CellOne.trim() != '' ? ', ' : ''}${s.CellTwo}`) : ''}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td style="padding-bottom:2px;">
                            <strong>Distance:</strong>
                          </td>
                          <td style="padding-left:10px;padding-bottom:2px;">
                            <strong>${parseFloat((s.Distance / 1000).toString()).toFixed(2)} KM</strong>
                          </td>
                        </tr>
                        <tr>
                          <td style="padding-bottom:2px;">
                            <strong>Address:</strong>
                          </td>
                          <td style="padding-left:10px;padding-bottom:2px;">
                            <strong>${s.ShopAddress ?? 0}</strong>
                          </td>
                        </tr>
                        </table>
                        </div>`,
          });

        const fillColor = s.HaveOrderToday ? 'green' : (s.IsVisited ? 'yellow' : 'blue');
        const marker = new google.maps.Marker({
          position: { lat: s.Latitude, lng: s.Longitude },
          label:{text:s.ShopName,color:'blue', fontSize:'5pt'},
          map: map,
          icon:{
            labelOrigin: new google.maps.Point(-8,0),
            path: "M12,2 C8.13,2 5,5.13 5,9 C5,13.17 9.42,18.92 11.24,21.11 C11.64,21.59 12.37,21.59 12.77,21.11 C14.58,18.92 19,13.17 19,9 C19,5.13 15.87,2 12,2 Z M12,11.5 C10.62,11.5 9.5,10.38 9.5,9 C9.5,7.62 10.62,6.5 12,6.5 C13.38,6.5 14.5,7.62 14.5,9 C14.5,10.38 13.38,11.5 12,11.5 Z"
            ,fillColor: fillColor
            ,fillOpacity: 0.6
            ,anchor: new google.maps.Point(0, 20)
          }
        });

        marker.addListener("click",() =>{
            infowindow.open(currentMapRef.current, marker);
        });

        markers.push(marker);
      });

      setShopsMarker([...markers]);
    }
  }

  const addTrackingMarkers = (map: google.maps.Map, empTracking: ITrackingEmployeeLocation[]) => {
    if (!!empTracking && empTracking.length > 0) {
      let markers: google.maps.Marker[] = [];

      empTracking.forEach(et => {
        const marker = new google.maps.Marker({
          position: { lat: et.Latitude, lng: et.Longitude },
          icon:{
            path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            scale: 2,
            fillColor: 'yellow',
            fillOpacity: 0.8,
            strokeWeight: 0.5,
            rotation: et.DirectionDegreeRelativeToNorth,
        } 
        });

        markers.push(marker);
      });

      const markerCluster = new MarkerClusterer({ map, markers });
      setMarkerClusterer(markerCluster);

      setTrackingMarker([...markers]);
    }
  }

  const drawPolygons = (map: google.maps.Map, routeDetail: ITrackingRouteDetail) => {
    if (!!routeDetail) {
      let routesPolyOnMap: IRoutePolyOnMap[] = [];

      let polyCoordinates = [];

      let points = routeDetail.PolygonData.split('((')[1]
      let p = points.split('))')[0];
      let p2 = p.split(',');
    let firstLat:number;
    let firstLong:number;
      for(var i = 0; i<p2.length; i++){
        let p3 = p2[i].split(' ');
            polyCoordinates.push({ lat: parseFloat(p3[1].trim()), lng: parseFloat(p3[0].trim()) });
            if(i == 0){
                firstLat = parseFloat(p3[1].trim())
                firstLong = parseFloat(p3[0].trim());
            }
      }

      setCenterPoint(firstLat, firstLong);

      const color = getRandomColor();
      const routePolyOnMap = new google.maps.Polygon({
        paths: polyCoordinates,
        strokeColor: color,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: color,
        editable: false,
        fillOpacity: 0.1,
        clickable:false,
      });

      routesPolyOnMap.push({ routeId: routeDetail.RouteId, polygon: routePolyOnMap });
      routePolyOnMap.setMap(map);

      setRoutePoliesOnMap([...routesPolyOnMap]);
      
      const polyBounds = getArrayBounds(routePolyOnMap);
      currentMapRef.current.fitBounds(polyBounds);
    }
  }

  const setCenterPoint = (latitude:number, longitude:number) =>{

    if(latitude > 0 && longitude > 0){
        currentMapRef.current.setCenter({
            lat:latitude,
            lng:longitude
        });

    }
  }

  const getRandomColor = () => {
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += Math.floor(Math.random() * 10);
    }
    return color;
  }

  const [currentMap, _setCurrentMap] = React.useState<google.maps.Map>();
  const currentMapRef = React.useRef(currentMap);

  const setCurrentMap = (cMap: google.maps.Map) => {
    currentMapRef.current = cMap;
    _setCurrentMap(cMap);
  }

  const scrollToTop = () =>{
    window.scrollTo(0,0);
  }

  const clearMap = () => {

    if (!!routePoliesOnMapRef.current && routePoliesOnMapRef.current.length > 0) {
      routePoliesOnMapRef.current.forEach(p => {
        p.polygon.setMap(null);
      });
    }

    if (!!shopsMarkerRef.current && shopsMarkerRef.current.length > 0) {
      shopsMarkerRef.current.forEach(sm => {
        sm.setMap(null);
      });
    }

    if (!!trackingMarkerRef.current && trackingMarkerRef.current.length > 0) {
        trackingMarkerRef.current.forEach(sm => {
          sm.setMap(null);
        });
      }

      if (!!onClickMarkerRef.current && onClickMarkerRef.current.length > 0) {
        onClickMarkerRef.current.forEach(sm => {
          sm.setMap(null);
        });
      }

      if(!!polylineRef.current){
        polylineRef.current.setMap(null);
      }

      setTrackingResults(null);
      setOrders([]);

    //currentMapRef.current.setZoom(8);

    if(!!markerClusterer){
        markerClusterer.clearMarkers();
    }

    setShopsMarker([]);
    setTrackingMarker([]);
    setRoutePoliesOnMap([]);
    setOnClickMarker([]);
    setPolyline(null);
    setMarkerClusterer(null);
    setShopsAnalytics(null);
    setIsRedChecked(false);
    setIsGreenChecked(false);
  }


  const clearTrackingMarkers = () =>{
    if (!!trackingMarkerRef.current && trackingMarkerRef.current.length > 0) {
      trackingMarkerRef.current.forEach(sm => {
          sm.setMap(null);
        });
      }
  }

  const [isPlaying, _setIsPlaying] = React.useState<boolean>(false);

  const isPlayingRef = React.useRef(isPlaying);

  const setIsPlaying = (v:boolean) => {
    isPlayingRef.current = v;
    _setIsPlaying(v);
  }

  const handlePlayButton = () =>{

    if(!!trackingResultsRef.current && !!trackingResultsRef.current.EmployeeTrackings && trackingResultsRef.current.EmployeeTrackings.length > 0){
      let empTracking = [...trackingResultsRef.current.EmployeeTrackings.filter(m => m.IsPlayed != true).map(a => a)];

      if(!!empTracking && empTracking.length > 0){
        setIsPlaying(true);
        empTracking.sort((a,b) => a.TimeStamp - b.TimeStamp);
        for(var i = 0; i < empTracking.length; i++){
            if(i == 0){
                let res = empTracking[0];
                let dated = formattedDate(res.RecordedOn,'DD-MMM-yyyy HH:mm A');
                let player = setTimeout(() => {addMarker(res, dated, true, false)},playSpeedRef.current);
                res.player = player;
            }else{
                let res1 = empTracking[i];
                let dated = formattedDate(res1.RecordedOn,'DD-MMM-yyyy hh:mm A');
                let isLast = i == (empTracking.length - 1);

                let player = setTimeout(() => {addMarker(res1, dated,false , isLast)},playSpeedRef.current * i);
                res1.player = player;
            }
        }

        setTrackingResults({...trackingResultsRef.current,EmployeeTrackings:empTracking});
      }
    }
  }

  const handleReplay = () =>{
    if(!!polylineRef.current){
      polylineRef.current.setMap(null);
    }
    clearTrackingMarkers();

    if(!!onClickMarkerRef.current && onClickMarkerRef.current.length > 0){
      onClickMarkerRef.current.forEach(oc => {
        oc.setMap(null);
      });

      setOnClickMarker([]);
    }

    if(!!trackingResultsRef.current && !! trackingResultsRef.current.EmployeeTrackings && trackingResultsRef.current.EmployeeTrackings.length > 0){
      let eTracking = trackingResultsRef.current.EmployeeTrackings.map(a => {
        if(!!a.player){
          clearTimeout(a.player);
          a.player = null;
        }

        a.IsPlayed = false;
        return a;
      });

      setPolyline(null);
      setTrackingResults({...trackingResultsRef.current, EmployeeTrackings:[...eTracking]});
      handlePlayButton();
      
    }
  }

  const handleStop = () =>{
    if(trackingResultsRef.current != null && trackingResultsRef.current.EmployeeTrackings != null){
      for(let i = 0; i < trackingResultsRef.current.EmployeeTrackings.length; i++){
        let res = trackingResultsRef.current.EmployeeTrackings[i];
        if(res.IsPlayed != true){
          clearTimeout(res.player);
        }
      }
    }

    setIsPlaying(false);
  }

  const addMarker = (et:ITrackingEmployeeLocation, dated:string, isFirst:boolean, isLast:boolean) =>{
    
    clearTrackingMarkers();

    const empTracking = [...trackingResultsRef.current.EmployeeTrackings.map(a => a)]
    let eTrack = empTracking.find(e => e.Id == et.Id);

    if(eTrack != null){
      eTrack.IsPlayed = true;
    }

    setTrackingResults({...trackingResultsRef.current,EmployeeTrackings:[...empTracking]});
    
    if(isLast == true){
      setIsPlaying(false);
    }

    const marker = new google.maps.Marker({
        position: { lat: et.Latitude, lng: et.Longitude },
        map:currentMapRef.current,
        icon:{
          path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
          scale: 5,
          fillColor: isFirst ? 'green' : 'yellow',
          fillOpacity: 0.8,
          strokeWeight: 0.5,
          rotation: et.DirectionDegreeRelativeToNorth,
      } 
      });

      let infowindow = new google.maps.InfoWindow({
        content: `<div style="width:300px;">
                    <div style="width:300px;text-align:center;">
                      <h3>
                        ${isFirst == true ? 'Started' : (isLast == true ? 'Ended' : 'Stopped')} At: ${dated}
                      </h3>
                    </div>
                    </div>`,
      });

      if(isFirst == true || isLast == true){
        infowindow.open(currentMapRef.current, marker);
      }
      

      marker.addListener('click', () => {
        infowindow.open(currentMapRef.current, marker);
      });

      setTrackingMarker([...trackingMarker,marker]);
      
      currentMapRef.current.setCenter({
        lat:et.Latitude,
        lng:et.Longitude
      });

      drawPolyLine(et.Latitude, et.Longitude);

  }

  const addMarkerOnClick = (et:ITrackingEmployeeLocation) =>{
    
    const marker = new google.maps.Marker({
        position: { lat: et.Latitude, lng: et.Longitude },
        map:currentMapRef.current,
        icon:{
          path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
          scale: 5,
          fillColor: 'blue',
          fillOpacity: 0.8,
          strokeWeight: 0.5,
          rotation: et.DirectionDegreeRelativeToNorth,
      } 
      });

      let dated = formattedDate(et.RecordedOn,'DD-MMM-yyyy hh:mm A');
      let infowindow = new google.maps.InfoWindow({
        content: `<div style="width:300px;">
                    <div style="width:300px;text-align:center;">
                      <h3>
                        Here At: ${dated} Speed: ${parseFloat((((et.SpeedInMetersPerSecond ?? 0) / 1000) * 60 * 60).toString()).toFixed(2)} kmh
                      </h3>
                    </div>
                    </div>`,
      });

      infowindow.open(currentMapRef.current, marker);
      

      marker.addListener('click', () => {
        infowindow.open(currentMapRef.current, marker);
      });

      setOnClickMarker([...onClickMarkerRef.current,marker]);
  }

  const [polyline, _setPolyline] = React.useState<google.maps.Polyline>();
  const polylineRef = React.useRef(polyline);
  const setPolyline = (poly:google.maps.Polyline) =>{
    polylineRef.current = poly;
    _setPolyline(poly);
  }

  const drawPolyLine = (lat:number, lng:number) =>{
    if(!polylineRef.current){
        let pLineCoordinates = [];
        pLineCoordinates.push({ lat: lat, lng: lng });
        var flightPath = new google.maps.Polyline({
            path: pLineCoordinates,
            geodesic: true,
            strokeColor: '#FF0000',
            strokeOpacity: 1.0,
            strokeWeight: 4
            });
    
        flightPath.setMap(currentMapRef.current);
        setPolyline(flightPath);
    }else{
      polylineRef.current.getPath().push(new google.maps.LatLng({lat:lat, lng:lng}));
    }
  }

  const  getArrayBounds = (polyArray) => {
    var bounds = new google.maps.LatLngBounds();
    var path, paths; 
    paths = polyArray.getPaths();       
        for (var i = 0; i < paths.getLength(); i++) {
            path = paths.getAt(i);
            for (var ii = 0; ii < path.getLength(); ii++) {
                bounds.extend(path.getAt(ii));
            }
        }

    return bounds;
}

  React.useEffect(() => {
    const map = new window.google.maps.Map(ref.current, {
      center,
      zoom,
      mapTypeId: window.google.maps.MapTypeId.ROADMAP,
      gestureHandling:'greedy'
    });

    map.addListener("click", (mapsMouseEvent) => {
      let nearest:any = null;
      if(!!trackingResultsRef.current && !!trackingResultsRef.current.EmployeeTrackings && trackingResultsRef.current.EmployeeTrackings.length > 0){
        const latLng = mapsMouseEvent.latLng;
        trackingResultsRef.current.EmployeeTrackings.forEach(ett => {
          if(nearest == null){
            let distance = getDistanceInMeters(latLng.lat(), latLng.lng(), ett.Latitude, ett.Longitude);
            nearest = {...ett, distance:distance};
          }else{
            let distance = getDistanceInMeters(latLng.lat(), latLng.lng(), ett.Latitude, ett.Longitude);
            if(distance < nearest.distance){
              nearest = {...ett, distance:distance};
            }
          }
        });

        if(nearest != null){
          addMarkerOnClick(nearest);
        }
      }
    });

    setCurrentMap(map);
    getOrderBookers();
    getTrackingResults(map,selectedOrderBookerId);
  }, []);

  const showErrorMessage = (message: string) => addMessage(message, 'error');
  const showInfoMessage = (message: string) => addMessage(message, 'info');
  const showSuccessMessage = (message: string) => addMessage(message, 'success');
  const showWarningMessage = (message: string) => addMessage(message, 'warning');
  const [isLoading, _setIsLoading] = React.useState<boolean>(false);
  const isLoadingRef = React.useRef(isLoading);
  const setIsLoading = (val: boolean) => {
    isLoadingRef.current = val;
    _setIsLoading(val);
  }
  const [pageMessages, setPageMessages] = React.useState<IMessage[]>([]);
  const addMessage = (messageText: string, variant: 'success' | 'error' | 'info' | 'warning') => {
    if (!!messageText && messageText.trim() != '') {
      let msgs = [];
      msgs.push({
        messageText,
        variant
      });

      setPageMessages([...msgs]);
    }
  }

  const clearMessages = () => {
    setPageMessages([]);
  }

  const clearMessagesExceptSuccess = () =>{
    setPageMessages((messages:IMessage[]) => [...messages.filter(pm => pm.variant == 'success')]);
  }

  const getOrderBookers = () => {
    employeeApi.getOrderBookers(state.loginUser?.UserId ?? 0, getCommaSeparatedUnitUniqueIdsExceptAll(state.selectedUnitAccesses?.UnitAccesses ?? []))
    .then(bookers => {
      setOrderBookers([...bookers]);
    })
    .catch(e => console.log(e));
  };

  const onOrderBookerChange = (option: IAutoComplete) => {
    clearMap();
    if (!!option) {
      setSelectedOrderBookerId(option.Value);
    } else {
      setSelectedOrderBookerId(0);
    }
  }

  const [orderBookers, _setOrderBookers] = React.useState<IAutoComplete[]>();
  const orderBookersRef = React.useRef(orderBookers);

  const setOrderBookers = (data: IAutoComplete[]) => {
    orderBookersRef.current = data;
    _setOrderBookers(data);
  }

  const handleGetDetailsClick = () =>{
    if(!selectedOrderBookerId || selectedOrderBookerId <= 0){
      showErrorMessage('Please Select Order Booker');
      return;
    }

    if(fromDate === undefined || fromDate == null){
      showErrorMessage('Please Select Date');
      return;
    }

    setIsPlaying(false);

    reloadTrackingResults(selectedOrderBookerId);
  }

  const [selectedOrderBookerId, _setSelectedOrderBookerId] = React.useState<number>(queryOBId === undefined || queryOBId == null || queryOBId.trim() == '' ? 0 : parseInt(decodeBase64String(queryOBId)));
  const [selectedAreaId, _setSelectedAreaId] = React.useState<number>(0);

  const selectedOrderBookerIdRef = React.useRef(selectedOrderBookerId);
  const selectedAreaIdRef = React.useRef(selectedAreaId);

  const setSelectedOrderBookerId = (id: number) => {
    selectedOrderBookerIdRef.current = id;
    _setSelectedOrderBookerId(id);
  }

  const reloadTrackingResults = (orderBookerId: number = null) => {
    clearMap();
    getTrackingResults(currentMapRef.current, orderBookerId);
  }

  const [fromDate, _setFromDate] = React.useState<Date | null>(new Date());

  const setFromDate = (dated:Date | null) => {
    _setFromDate(dated);
    clearMap();
  }

  const [targetRefreshToken, setTargetRefreshToken] = React.useState<string>('');
  const [routeRefreshToken, setRouteRefreshToken] = React.useState<string>('');

  const valueLabelFormat = (value: number) => {
    return `${value} ms`;
  }

  const [playSpeed, _setPlaySpeed] = React.useState<number>(10);
  const playSpeedRef = React.useRef(playSpeed);

  const setPlaySpeed = (v:number) =>{
    playSpeedRef.current = v;
    _setPlaySpeed(v);
  }

  const handleChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      handleStop();
      setPlaySpeed(newValue);
    }
  };

  const [isGreenChecked, setIsGreenChecked] = React.useState<boolean>(false);
  const [isRedChecked, setIsRedChecked] = React.useState<boolean>(false);

  const addBothRedGreenMarkers = (map: google.maps.Map, shops: ITrackingShopDetail[]) => {
    clearShopMarkers();
    if (!!shops && shops.length > 0) {
      let markers: google.maps.Marker[] = [];
      shops.forEach(s => {
        let infowindow = new google.maps.InfoWindow({
            content: `<div style="width:300px;">
                        <div style="width:300px;text-align:center;">
                          <h3>
                          ${s.ShopName}
                          </h3>
                        </div>
                        <table width="300px" style="margin-top:5px;">
                        <tr>
                          <td style="padding-bottom:2px;width:100px;">
                            <strong>Contact Person:</strong>
                          </td>
                          <td style="padding-left:10px;padding-bottom:2px;">
                            <strong>${s.OwnerName ?? 0}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td style="padding-bottom:2px;">
                            <strong>Cell#:</strong>
                          </td>
                          <td style="padding-left:10px;padding-bottom:2px;">
                            <strong>${s.CellOne !== undefined && s.CellOne != null ? `${s.CellOne}` : ''} ${s.CellTwo !== undefined && s.CellTwo != null && s.CellTwo.trim() != '' ? (`${s.CellOne !== undefined && s.CellOne != null && s.CellOne.trim() != '' ? ', ' : ''}${s.CellTwo}`) : ''}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td style="padding-bottom:2px;">
                            <strong>Distance:</strong>
                          </td>
                          <td style="padding-left:10px;padding-bottom:2px;">
                            <strong>${parseFloat((s.Distance / 1000).toString()).toFixed(2)} KM</strong>
                          </td>
                        </tr>
                        <tr>
                          <td style="padding-bottom:2px;">
                            <strong>Address:</strong>
                          </td>
                          <td style="padding-left:10px;padding-bottom:2px;">
                            <strong>${s.ShopAddress ?? 0}</strong>
                          </td>
                        </tr>
                        </table>
                        </div>`,
          });

        const fillColor = (s.HaveOrderToday == true || s.HaveOrderPrevious) ? 'green' : 'red';
        const marker = new google.maps.Marker({
          position: { lat: s.Latitude, lng: s.Longitude },
          label:{text:s.ShopName,color:'blue', fontSize:'5pt'},
          map: map,
          icon:{
            labelOrigin: new google.maps.Point(-8,0),
            path: "M12,2 C8.13,2 5,5.13 5,9 C5,13.17 9.42,18.92 11.24,21.11 C11.64,21.59 12.37,21.59 12.77,21.11 C14.58,18.92 19,13.17 19,9 C19,5.13 15.87,2 12,2 Z M12,11.5 C10.62,11.5 9.5,10.38 9.5,9 C9.5,7.62 10.62,6.5 12,6.5 C13.38,6.5 14.5,7.62 14.5,9 C14.5,10.38 13.38,11.5 12,11.5 Z"
            ,fillColor: fillColor
            ,fillOpacity: 0.6
            ,anchor: new google.maps.Point(0, 20)
          }
        });

        marker.addListener("click",() =>{
            infowindow.open(currentMapRef.current, marker);
        });

        markers.push(marker);
      });

      setShopsMarker([...markers]);
    }
  }

  const addGreenMarkers = (map: google.maps.Map, shops: ITrackingShopDetail[]) => {
    clearShopMarkers();
    if (!!shops && shops.length > 0) {
      let markers: google.maps.Marker[] = [];
      shops = shops.filter(st => (st.HaveOrderToday == true || st.HaveOrderPrevious == true));
      if(!!shops && shops.length > 0){
        shops.forEach(s => {
          let infowindow = new google.maps.InfoWindow({
              content: `<div style="width:300px;">
                          <div style="width:300px;text-align:center;">
                            <h3>
                            ${s.ShopName}
                            </h3>
                          </div>
                          <table width="300px" style="margin-top:5px;">
                          <tr>
                            <td style="padding-bottom:2px;width:100px;">
                              <strong>Contact Person:</strong>
                            </td>
                            <td style="padding-left:10px;padding-bottom:2px;">
                              <strong>${s.OwnerName ?? 0}</strong>
                            </td>
                          </tr>
                          <tr>
                            <td style="padding-bottom:2px;">
                              <strong>Cell#:</strong>
                            </td>
                            <td style="padding-left:10px;padding-bottom:2px;">
                              <strong>${s.CellOne !== undefined && s.CellOne != null ? `${s.CellOne}` : ''} ${s.CellTwo !== undefined && s.CellTwo != null && s.CellTwo.trim() != '' ? (`${s.CellOne !== undefined && s.CellOne != null && s.CellOne.trim() != '' ? ', ' : ''}${s.CellTwo}`) : ''}</strong>
                            </td>
                          </tr>
                          <tr>
                            <td style="padding-bottom:2px;">
                              <strong>Distance:</strong>
                            </td>
                            <td style="padding-left:10px;padding-bottom:2px;">
                              <strong>${parseFloat((s.Distance / 1000).toString()).toFixed(2)} KM</strong>
                            </td>
                          </tr>
                          <tr>
                            <td style="padding-bottom:2px;">
                              <strong>Address:</strong>
                            </td>
                            <td style="padding-left:10px;padding-bottom:2px;">
                              <strong>${s.ShopAddress ?? 0}</strong>
                            </td>
                          </tr>
                          </table>
                          </div>`,
            });
  
          const fillColor = 'green';
          const marker = new google.maps.Marker({
            position: { lat: s.Latitude, lng: s.Longitude },
            label:{text:s.ShopName,color:'blue', fontSize:'5pt'},
            map: map,
            icon:{
              labelOrigin: new google.maps.Point(-8,0),
              path: "M12,2 C8.13,2 5,5.13 5,9 C5,13.17 9.42,18.92 11.24,21.11 C11.64,21.59 12.37,21.59 12.77,21.11 C14.58,18.92 19,13.17 19,9 C19,5.13 15.87,2 12,2 Z M12,11.5 C10.62,11.5 9.5,10.38 9.5,9 C9.5,7.62 10.62,6.5 12,6.5 C13.38,6.5 14.5,7.62 14.5,9 C14.5,10.38 13.38,11.5 12,11.5 Z"
              ,fillColor: fillColor
              ,fillOpacity: 0.6
              ,anchor: new google.maps.Point(0, 20)
            }
          });
  
          marker.addListener("click",() =>{
              infowindow.open(currentMapRef.current, marker);
          });
  
          markers.push(marker);
        });
  
        setShopsMarker([...markers]);
      }
    }
  }

  const addRedMarkers = (map: google.maps.Map, shops: ITrackingShopDetail[]) => {
    clearShopMarkers();
    if (!!shops && shops.length > 0) {
      let markers: google.maps.Marker[] = [];
      shops = shops.filter(st => ((st.HaveOrderToday === undefined || st.HaveOrderToday == null || st.HaveOrderToday == false) && (st.HaveOrderPrevious === undefined || st.HaveOrderPrevious == null || st.HaveOrderPrevious == false)));
      if(!!shops && shops.length > 0){
        shops.forEach(s => {
          let infowindow = new google.maps.InfoWindow({
              content: `<div style="width:300px;">
                          <div style="width:300px;text-align:center;">
                            <h3>
                            ${s.ShopName}
                            </h3>
                          </div>
                          <table width="300px" style="margin-top:5px;">
                          <tr>
                            <td style="padding-bottom:2px;width:100px;">
                              <strong>Contact Person:</strong>
                            </td>
                            <td style="padding-left:10px;padding-bottom:2px;">
                              <strong>${s.OwnerName ?? 0}</strong>
                            </td>
                          </tr>
                          <tr>
                            <td style="padding-bottom:2px;">
                              <strong>Cell#:</strong>
                            </td>
                            <td style="padding-left:10px;padding-bottom:2px;">
                              <strong>${s.CellOne !== undefined && s.CellOne != null ? `${s.CellOne}` : ''} ${s.CellTwo !== undefined && s.CellTwo != null && s.CellTwo.trim() != '' ? (`${s.CellOne !== undefined && s.CellOne != null && s.CellOne.trim() != '' ? ', ' : ''}${s.CellTwo}`) : ''}</strong>
                            </td>
                          </tr>
                          <tr>
                            <td style="padding-bottom:2px;">
                              <strong>Distance:</strong>
                            </td>
                            <td style="padding-left:10px;padding-bottom:2px;">
                              <strong>${parseFloat((s.Distance / 1000).toString()).toFixed(2)} KM</strong>
                            </td>
                          </tr>
                          <tr>
                            <td style="padding-bottom:2px;">
                              <strong>Address:</strong>
                            </td>
                            <td style="padding-left:10px;padding-bottom:2px;">
                              <strong>${s.ShopAddress ?? 0}</strong>
                            </td>
                          </tr>
                          </table>
                          </div>`,
            });
  
          const fillColor = 'red';
          const marker = new google.maps.Marker({
            position: { lat: s.Latitude, lng: s.Longitude },
            label:{text:s.ShopName,color:'blue', fontSize:'5pt'},
            map: map,
            icon:{
              labelOrigin: new google.maps.Point(-8,0),
              path: "M12,2 C8.13,2 5,5.13 5,9 C5,13.17 9.42,18.92 11.24,21.11 C11.64,21.59 12.37,21.59 12.77,21.11 C14.58,18.92 19,13.17 19,9 C19,5.13 15.87,2 12,2 Z M12,11.5 C10.62,11.5 9.5,10.38 9.5,9 C9.5,7.62 10.62,6.5 12,6.5 C13.38,6.5 14.5,7.62 14.5,9 C14.5,10.38 13.38,11.5 12,11.5 Z"
              ,fillColor: fillColor
              ,fillOpacity: 0.6
              ,anchor: new google.maps.Point(0, 20)
            }
          });
  
          marker.addListener("click",() =>{
              infowindow.open(currentMapRef.current, marker);
          });
  
          markers.push(marker);
        });
  
        setShopsMarker([...markers]);
      }
    }
  }

  const handleGreenCheckedChange = (ev:any,chk:boolean) => {
    setIsGreenChecked(chk);
    handleRedGreen(chk,isRedChecked, trackingResultsRef.current.ShopDetails);
  }

  const handleRedCheckedChange = (ev:any,chk:boolean) => {
    setIsRedChecked(chk);
    handleRedGreen(isGreenChecked,chk, trackingResultsRef.current.ShopDetails);
  }

  const handleRedGreen = (isGChecked:boolean, isRChecked:boolean, shopDetails:ITrackingShopDetail[]) =>{
    if(isGChecked == false && isRChecked == false){
      clearShopMarkers();
      refreshShopsMarkers(currentMapRef.current, shopDetails);
    }else if(isGChecked == true && isRChecked == true){
      clearShopMarkers();
      addBothRedGreenMarkers(currentMapRef.current, shopDetails);
    }else if(isGChecked == true && isRChecked == false){
      clearShopMarkers();
      addGreenMarkers(currentMapRef.current, shopDetails);
    }else if(isGChecked == false && isRChecked == true){
      clearShopMarkers();
      addRedMarkers(currentMapRef.current, shopDetails);
    }
  }

  const blueColor:string = 'blue';
  const greenColor:string = 'green';
  const yellowColor:string = 'yellow';
  const redColor:string = 'red';
  const orangeColor:string = 'orange';
  const tableCellPadding = 2;

  return <><Page title="Tracking | Raza Pak Care">
    <Container style={{ paddingBottom: 20, maxWidth: '100%' }}><MessageComponent onRemove={(i: number) => {
      let msgs = [...pageMessages];
      msgs.splice(i, 1);
      setPageMessages([...msgs]);
    }} messages={pageMessages} />
    </Container>
    <Container style={{ paddingBottom: 10, maxWidth: '100%' }}>
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}><CustomAutoComplete<IAutoComplete>
          Id={'Order_Booker_List'}
          Value={!!orderBookers && orderBookers.length > 0 ? orderBookers.filter(m => m.Value == selectedOrderBookerId)[0] : null}
          Options={orderBookers}
          displayField={'DisplayText'}
          valueField={'Value'}
          Label={'Order Booker'}
          disabled={(!orderBookers || orderBookers.length <= 0)}
          onChange={onOrderBookerChange}
        /></Grid>
        <Grid item md={4} sm={12}>
                <CustomDatePicker
                  label='Date'
                  value={fromDate}
                  onChange={(v: Date) => {
                    setFromDate(v);
                  }}
                  inputDisable={true}
                />
              </Grid>
            
            <Grid item md={4} sm={12}>
            <LoadingButton
                  type="button"
                  size='small'
                  variant="contained"
                  loading={isLoading}
                  style={{ margin: 3, width: 100 }}
                  onClick={handleGetDetailsClick}
                >
                  Search
                </LoadingButton>
            </Grid>

            {!!trackingResultsRef.current && !!trackingResultsRef.current.EmployeeTrackings && trackingResultsRef.current.EmployeeTrackings.length > 0 &&
            <Grid item md={2} sm={12}>
              <Checkbox checked={isGreenChecked} onChange={handleGreenCheckedChange} style={{color:greenColor}} /><span style={{color:greenColor}}>G</span>
              <Checkbox checked={isRedChecked} onChange={handleRedCheckedChange} style={{color:redColor}} /><span style={{color:redColor}}>R</span>
            </Grid>}

            {!!trackingResultsRef.current && !!trackingResultsRef.current.EmployeeTrackings && trackingResultsRef.current.EmployeeTrackings.length > 0 && <Grid item md={4} sm={12}>
            <Slider
                      value={playSpeedRef.current}
                      min={10}
                      step={10}
                      max={3000}
                      getAriaValueText={valueLabelFormat}
                      valueLabelFormat={valueLabelFormat}
                      onChange={handleChange}
                      valueLabelDisplay="auto"
                      aria-labelledby="non-linear-slider"
            />
            </Grid>}

            {!!trackingResultsRef.current && !!trackingResultsRef.current.EmployeeTrackings && trackingResultsRef.current.EmployeeTrackings.length > 0 && <Grid item md={4} sm={12}>
            <LoadingButton
                  type="button"
                  size='small'
                  variant="contained"
                  loading={isLoading}
                  style={{ margin: 3, width: 100 }}
                  disabled={isPlayingRef.current}
                  onClick={handlePlayButton}
                >
                  Play
                </LoadingButton>

                <LoadingButton
                  type="button"
                  size='small'
                  variant="contained"
                  loading={isLoading}
                  disabled={!isPlayingRef.current}
                  style={{ margin: 3, width: 100, backgroundColor:'red' }}
                  onClick={handleStop}
                >
                  Stop
                </LoadingButton>

                <LoadingButton
                  type="button"
                  size='small'
                  variant="contained"
                  loading={isLoading}
                  style={{ margin: 3, width: 150 }}
                  onClick={handleReplay}
                  disabled={isPlayingRef.current}
                >
                  Play From Start
                </LoadingButton>
            </Grid>}
      </Grid>
    </Container>

    {isLoadingRef.current == true && <AudoSpin
      height="80"
      width="80"
      color='green'
      ariaLabel='three-dots-loading'
      wrapperStyle={{ alignSelf: 'center', justifyContent: 'center' }}
    />}


    <Grid container>
      <Grid item md={9}>
      <Container style={{maxWidth:'100%',width:'100%',height: 650}}>
      <div style={{ width: '100%', height: '100%' }} ref={ref} id="map" />  
    </Container>
      </Grid>
      <Grid item md={3}>
        {!!shopsAnalytics && shopsAnalytics.TotalShops > 0 && <Grid container>
          <table border={1} cellSpacing={0} cellPadding={5} style={{ width: '100%', fontSize: 11, marginBottom: 10, marginTop:10 }}>
            <tbody>
            <tr>
              <th style={{padding:7}} colSpan={3} align={'center'}>Analytics</th>
            </tr>
            <tr>
              <th style={{padding:7}} colSpan={3} align={'center'}>{trackingResults?.RouteDetail?.RouteName ?? ''}</th>
            </tr>
            <tr>
              <td style={{padding:7}}>Total Shops:</td>
              <td style={{padding:7}} colSpan={2} align={'center'}>{shopsAnalytics.TotalShops}</td>
            </tr>
            <tr>
              <td style={{padding:7}}>Remaining Shops:</td>
              <td style={{padding:7}}>{shopsAnalytics.RemaingShops}</td>
              <td style={{padding:7}}>{`${parseFloat((shopsAnalytics.RemaingShops / shopsAnalytics.TotalShops * 100).toString()).toFixed(2)} %`}</td>
            </tr>
            <tr>
              <td style={{padding:7}}>Visited Shops:</td>
              <td style={{padding:7}}>{shopsAnalytics.VisitedShops}</td>
              <td style={{padding:7}}>{`${parseFloat((shopsAnalytics.VisitedShops / shopsAnalytics.TotalShops * 100).toString()).toFixed(2)} %`}</td>
            </tr>
            <tr>
              <td style={{padding:7}}>Orders Shops:</td>
              <td style={{padding:7}}>{shopsAnalytics.OrderShops}</td>
              <td style={{padding:7}}>{`${parseFloat((shopsAnalytics.OrderShops / shopsAnalytics.TotalShops * 100).toString()).toFixed(2)} %`}</td>
            </tr>
            <tr>
              <td style={{padding:7, color:'red'}}>No Orders Shops:</td>
              <td style={{padding:7, color:'red'}}>{shopsAnalytics.NoOrderShops}</td>
              <td style={{padding:7, color:'red'}}>{`${parseFloat((shopsAnalytics.NoOrderShops / shopsAnalytics.TotalShops * 100).toString()).toFixed(2)} %`}</td>
            </tr>
            <tr>
              <td style={{padding:7}}>Repeated Order Shops:</td>
              <td style={{padding:7}}>{shopsAnalytics.OrderShops - shopsAnalytics.TodayFirstOrderShops}</td>
              <td style={{padding:7}}>{`${parseFloat(((shopsAnalytics.OrderShops - shopsAnalytics.TodayFirstOrderShops) / shopsAnalytics.TotalShops * 100).toString()).toFixed(2)} %`}</td>
            </tr>
            <tr>
              <td style={{padding:7, color:'green'}}>First Time Covered Shops:</td>
              <td style={{padding:7, color:'green'}}>{shopsAnalytics.TodayFirstOrderShops}</td>
              <td style={{padding:7, color:'green'}}>{`${parseFloat((shopsAnalytics.TodayFirstOrderShops / shopsAnalytics.TotalShops * 100).toString()).toFixed(2)} %`}</td>
            </tr>
            <tr>
              <td style={{padding:7,background: 'rgba(51, 170, 51, .4)'}}>Overall Covered Shops:</td>
              <td style={{padding:7,background: 'rgba(51, 170, 51, .4)'}}>{shopsAnalytics.ConveredShops}</td>
              <td style={{padding:7,background: 'rgba(51, 170, 51, .4)'}}>{`${parseFloat((shopsAnalytics.ConveredShops / shopsAnalytics.TotalShops * 100).toString()).toFixed(2)} %`}</td>
            </tr>
            <tr>
              <td style={{padding:7, background:'rgba(255, 0, 0, .4)'}}>Never Covered Shops:</td>
              <td style={{padding:7,background:'rgba(255, 0, 0, .4)'}}>{shopsAnalytics.NeverOrderShops}</td>
              <td style={{padding:7,background:'rgba(255, 0, 0, .4)'}}>{`${parseFloat((shopsAnalytics.NeverOrderShops / shopsAnalytics.TotalShops * 100).toString()).toFixed(2)} %`}</td>
            </tr>
            </tbody>
          </table>
        </Grid>}
      </Grid>
    </Grid>

    {!!orders && orders.length > 0 && <table border={1} cellSpacing={0} cellPadding={5} style={{ width: '100%', fontSize: 11, marginBottom: 10, marginTop:10 }}>
                                <tbody>
                                    {orders.map((od, i, { length }) => {
                                        return <React.Fragment key={`TabletRows_${od.EmployeeId}`}>
                                            <tr>
                                                <th style={{ padding: tableCellPadding, fontSize: 14, color:'blue' }} colSpan={9} align={'left'}>{`${od.FirstName} ${od.LastName}`}</th>
                                                <td align='center'>
                                                    <span style={{ color: 'blue', fontSize: 12, cursor: 'pointer', fontWeight: 'bold' }}>Details</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th style={{ padding: tableCellPadding, width: '6%' }}>Target (CTN)</th>
                                                <th style={{ padding: tableCellPadding, width: '6%' }}>Ach. Target (CTN)</th>
                                                <th style={{ padding: tableCellPadding, width: '6%' }}>Rem. Target (CTN)</th>
                                                <th style={{ padding: tableCellPadding, width: '6%' }}>Pnd. Crdt.</th>
                                                <th style={{ padding: tableCellPadding, width: '6%', background: 'rgba(51, 170, 51, .4)' }}>Ach. %age</th>
                                                <th style={{ padding: tableCellPadding, width: '6%', background: 'rgba(255, 0, 0, .4)' }}>Rem. %age</th>
                                                <th style={{ padding: tableCellPadding, width: '6%' }}>DO (CTN)</th>
                                                <th style={{ padding: tableCellPadding, width: '6%' }}>Return (CTN)</th>
                                                <th style={{ padding: tableCellPadding, width: '7%' }}>DO Delivered (CTN)</th>
                                            </tr>

                                            <tr>
                                                <td style={{ padding: tableCellPadding, width: '6%' }}>{fCommaSeparatedWithDecial(od.TargetCtn.toFixed(2))}</td>
                                                <td style={{ padding: tableCellPadding, width: '6%' }}>{fCommaSeparatedWithDecial(od.AchievedCtn.toFixed(2))}</td>
                                                <td style={{ padding: tableCellPadding, width: '6%' }}>{fCommaSeparatedWithDecial(od.RemainingCtn.toFixed(2))}</td>
                                                <td style={{ padding: tableCellPadding, width: '6%', color: 'red' }}>{fCommaSeparatedWithDecial(od.EmployeePendingCredit)}</td>
                                                <td style={{ padding: tableCellPadding, width: '6%', background: 'rgba(51, 170, 51, .4)' }}>{od?.AchievedPercentage ?? 0} %</td>
                                                <td style={{ padding: tableCellPadding, width: '6%', background: 'rgba(255, 0, 0, .4)' }}>{od.RemainingPercentage} %</td>
                                                <td style={{ padding: tableCellPadding, width: '6%', color:'orange' }}>{fCommaSeparatedWithDecial(od.DoCtn.toFixed(2))}</td>
                                                <td style={{ padding: tableCellPadding, width: '6%', color:'red' }}>{fCommaSeparatedWithDecial(od.DoReturnCtn.toFixed(2))}</td>
                                                <td style={{ padding: tableCellPadding, width: '7%', color:'blue' }}>{fCommaSeparatedWithDecial(od.DoDeliveredCtn.toFixed(2))}</td>
                                            </tr>
                                        </React.Fragment>;
                                    })}
                                </tbody>
                            </table>
                        }

                        <TargetAndSaleInfoComponent orderBookerId={selectedOrderBookerId} refreshToken={targetRefreshToken} refreshMe={setTargetRefreshToken} tillDate={fromDate} />

                        <UserRoutesInfoComponent orderBookerId={selectedOrderBookerId} refreshToken={routeRefreshToken} refreshMe={setRouteRefreshToken} />

  </Page>
    </>
    ;

}