import { ISelectedUnitAccess } from 'src/interfaces/selected-unit-access.interface';
import {SelectedUnitAccessConstants} from '../actions/action.constant';
import {IAction} from '../actions/action.interface';
import {initialState} from '../initial-state';

export const selectedUnitAccessReducer = (
  state = initialState.selectedUnitAccesses,
  action: IAction<ISelectedUnitAccess>,
) => {
  switch (action.type) {
    case SelectedUnitAccessConstants.SET_SELECTED_UNIT_ACCESS:
      let newState: ISelectedUnitAccess = {...action.payload,UnitAccesses:[...action.payload.UnitAccesses]};
      return newState;
    default:
      return state;
  }
};
