import { IRoute } from 'src/interfaces/route.interface';
import {NewRouteSetupConstant} from '../../actions/action.constant';
import {IAction} from '../../actions/action.interface';
import {initialState} from '../../initial-state';
import { getCopy } from 'src/utility';

export const selectedRouteToModifyReducer = (
  state: IRoute = initialState.selectedRouteToModify,
  action: IAction<IRoute>,
) => {
  switch (action.type) {
    case NewRouteSetupConstant.SET_SELECTED_ROUTE_TO_MODIFY: {;
      return getCopy(action.payload);
    }
    default:
      return state;
  }
};
