import {NewRouteSetupConstant} from '../../actions/action.constant';
import {IAction} from '../../actions/action.interface';
import {initialState} from '../../initial-state';

export const isLoadingAreaPoliesReducer = (
  state: boolean = initialState.isLoadingAreaPolies,
  action: IAction<boolean>,
) => {
  switch (action.type) {
    case NewRouteSetupConstant.SET_IS_LOADIN_AREA_POLIES: {;
      return action.payload;
    }
    default:
      return state;
  }
};
