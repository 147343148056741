import React from 'react';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import trashFill from '@iconify/icons-eva/trash-fill';
import downloadFill from '@iconify/icons-eva/download-fill';
import arrowBackFill from '@iconify/icons-eva/arrow-back-fill';
import printerFill from '@iconify/icons-eva/printer-fill';
import refreshFill from '@iconify/icons-eva/refresh-fill';
// material
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Grid,
  TextField,
  Checkbox,
  Stack,
  Avatar,
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import { UserListHead } from '../components/_dashboard/user';
//
import { CustomAutoComplete } from '../components/common/CustomAutoComplete';
import CustomDatePicker from 'src/components/common/CustomDatePicker';
import { LoadingButton } from '@mui/lab';
import { employeeApi, routeApi, deliveryOrderApi, orderApi } from '../http';
import { IAutoComplete } from 'src/interfaces/auto-complete.interface';
import { formattedDate, getCommaSeparatedString, getCommaSeparatedUnitUniqueIdsExceptAll, getCommaSeparatedValues, getSingleUnitUniqueId } from 'src/utility';
import { IDeliveryOrderResult } from 'src/interfaces/delivery-order/delivery-order-result.interface';
import { IDeliveryOrderView } from 'src/interfaces/delivery-order/delivery-order-view.interface';

import { useSnackbar } from 'notistack';
import { IApplicationState } from 'src/redux';
import { IOrderBookerForDO } from 'src/interfaces/delivery-order/order-booker-do.interface';
import { Audio } from 'react-loader-spinner'
import { IDODetail } from 'src/interfaces/delivery-order/do-detail.interface';
import { confirmAction } from 'src/utility/confirm.util';
import { sentenceCase, camelCase, capitalCase } from 'change-case';
import { base64ToBlob } from 'src/utility/base64.util';
import { downloadFile } from 'src/utility/file.util';
import { IOrderBooker } from 'src/interfaces/delivery-order/order-booker.interface';
import { IUserDetail } from 'src/interfaces';
import { connect } from 'react-redux';
import { AllOptionUniqueId, DoDetailFor } from 'src/app.constant';
import { ISelectedUnitAccess } from 'src/interfaces/selected-unit-access.interface';
import { IMessage } from 'src/interfaces/message.interface';
import { MessageComponent } from 'src/components/messages.component';
import { IDOResultWithDetail } from 'src/interfaces/delivery-order/do-result-with-detail.interface';
import { NoUnitSelectedComponent } from 'src/components/common/no-unit-selected.component';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: '', label: '', alignContent: 'center' },
  { id: 'sr', label: 'Sr.', alignContent: 'center' },
  { id: 'order_booker_Id', label: 'Order Booker', alignContent: 'center' },
  { id: 'route_title', label: 'Route Name', alignContent: 'center' },
  { id: 'total_order', label: 'Total Orders', alignContent: 'center' },
];

const TABLE_HEAD_DO = [
  { id: 'sr', label: 'Sr.', alignContent: 'center' },
  { id: 'delivery_boy', label: 'Delivery Boy', alignContent: 'center' },
  { id: 'order_bookers', label: 'Order Bookers', alignContent: 'center' },
  { id: 'total_order', label: 'Total Orders', alignContent: 'center' },
  { id: 'orders_date', label: 'Orders Date', alignContent: 'center' },
  { id: 'do_status', label: 'Status', alignContent: 'center' },
  { id: 'download_invoices', label: '', alignContent: 'center' },
  { id: 'delete_do', label: '', alignContent: 'center' },
];

const TABLE_HEAD_DO_Summary = [
  { id: 'sr', label: 'Sr.', alignContent: 'center' },
  { id: 'product_name', label: 'Product', alignContent: 'center' },
  { id: 'total_order_qty', label: 'Total Order Qty', alignContent: 'center' },
  { id: 'total_scheme_qty', label: 'Total Scheme Qty', alignContent: 'center' },
  { id: 'total_issue_qty', label: 'Total Issue Qty', alignContent: 'center' },
  { id: 'total_available_stock', label: 'Total Available Stock', alignContent: 'center' },
];

const TABLE_DETAIL_HEAD = [
  { id: 'sr', label: 'Sr.', alignContent: 'center' },
  { id: 'product_name', label: 'Product Name', alignContent: 'center' },
  { id: 'product_qty', label: 'Product Qty', alignContent: 'center' },
  { id: 'scheme_qty', label: 'Scheme Qty', alignContent: 'center' },
  { id: 'total_issue_qty', label: 'Total Issue Qty', alignContent: 'center' },
  { id: 'available_stock', label: 'Available Stock', alignContent: 'center' }
];

// ----------------------------------------------------------------------

interface IProps{
  loginUser:IUserDetail | null;
  selectedUnits:ISelectedUnitAccess;
}

const CreateDO = (props:IProps) => {
  const [orderBookersForDO, setOrderBookersForDO] = React.useState<IOrderBookerForDO[]>([]);
  const [selectedOrderBookerIds, setSelectedOrderBookerIds] = useState<number[]>([]);
  const [selectedRouteIds, setSelectedRouteIds] = useState<number[]>([]);
  const [deliveryBoyId, setDeliveryBoyId] = useState<number | undefined>(undefined);
  const [deliveryCost, setDeliveryCost] = useState<number>(0);
  const [scannedQty, setScannedQty] = useState<number>(0);
  const [dated, setDated] = useState<Date | null>(null);

  const [deliveryBoys, setDeliveryBoys] = useState<IAutoComplete[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const showErrorMessage = (message: string) => addMessage(message, 'error');
    const showInfoMessage = (message: string) => addMessage(message, 'info');
    const showSuccessMessage = (message: string) => addMessage(message, 'success');
    const showWarningMessage = (message: string) => addMessage(message, 'warning');
    const [pageMessages, setPageMessages] = React.useState<IMessage[]>([]);
    const addMessage = (messageText: string, variant: 'success' | 'error' | 'info' | 'warning') => {
        if (!!messageText && messageText.trim() != '') {
            let msgs = [];
            msgs.push({
                messageText,
                variant
            });

            setPageMessages([...msgs]);
        }
    }

  // const getOrderBookers = () => {
  //   employeeApi.getOrderBookers()
  //   .then(bookers => {
  //     setOrderBookers([...bookers]);
  //   })
  //   .catch(e => console.log(e));
  // };

  const getDeliveryBoyss = () => {
    employeeApi.getDeliveryBoysForDO(getSingleUnitUniqueId([...props.selectedUnits.UnitAccesses.map(a => a)]))
      .then(boys => {
        setDeliveryBoys([...boys]);
      })
      .catch(e => console.log(e));
  };

  // const onOrderBookerChange = (e:IAutoComplete) =>{
  //   if(!!e){
  //     setOrderBookerId(e.Value);
  //   }else{
  //     setOrderBookerId(undefined);
  //   }
  // }

  const onDeliveryBoyChange = (e: IAutoComplete) => {
    if (!!e) {
      setDeliveryBoyId(e.Value);
    } else {
      setDeliveryBoyId(undefined);
    }
  }

  const loadResults = (datedForDO: Date) => {

    if (datedForDO === undefined || datedForDO == null) {
      showErrorMessage('Invalid Date');
      return;
    }

    setIsLoading(true);
    setOrderBookersForDO([]);

    deliveryOrderApi.getOrderBookersForDO(datedForDO,getSingleUnitUniqueId([...props.selectedUnits.UnitAccesses.map(a => a)]))
      .then(results => {
        setIsLoading(false);
        if (!!results) {

          if (results.length <= 0) {
            showInfoMessage(`No Orders To Create DO For ${formattedDate(datedForDO)}`);
          }

          setOrderBookersForDO([...results]);
        } else {
          setOrderBookersForDO([]);
        }
      })
      .catch(e => {
        setIsLoading(false);
      })
  }

  const onDateChange = (e: Date) => {
    setDated(e);
    loadResults(e);
  }

  const handleOrderBookerSelectionChange = (orderBookerId: number, routeId: number) => {
    let existingOrderBookerIds = [...selectedOrderBookerIds];
    let existingRouteIds = [...selectedRouteIds];
    const inSelection = existingOrderBookerIds.filter(m => m == orderBookerId);
    const inSelectionRouteIds = existingRouteIds.filter(m => m == routeId);

    if (!!inSelection && inSelection.length > 0 && !!inSelectionRouteIds && inSelectionRouteIds.length > 0) {
      const removed = existingOrderBookerIds.filter(n => n != orderBookerId);
      const routeRemoved = existingRouteIds.filter(m => m != routeId);
      setSelectedOrderBookerIds([...removed]);
      setSelectedRouteIds([...routeRemoved]);
    } else {
      existingOrderBookerIds.push(orderBookerId);
      existingRouteIds.push(routeId);
      setSelectedRouteIds([...existingRouteIds]);
      setSelectedOrderBookerIds([...existingOrderBookerIds]);
    }
  }

  const createDO = () => {

    if (selectedOrderBookerIds === undefined || selectedOrderBookerIds == null || selectedOrderBookerIds.length <= 0) {
      showErrorMessage('Please Select At Least One Order Booker');
      return;
    }

    if (dated === undefined || dated == null) {
      showErrorMessage("Please Select Date");
      return;
    }

    if (deliveryBoyId === undefined || deliveryBoyId == null || deliveryBoyId <= 0) {
      showErrorMessage('Please Select Delivery Boy');
      return;
    }

    const selectedOrderBookersString = getCommaSeparatedString(selectedOrderBookerIds);
    const commaSeparatedRouteIds = getCommaSeparatedString(selectedRouteIds);
    setIsLoading(true);
    deliveryOrderApi.saveDeliveryOrder(props.loginUser?.UserId, dated, deliveryBoyId, deliveryCost, selectedOrderBookersString, commaSeparatedRouteIds, getSingleUnitUniqueId(props.selectedUnits.UnitAccesses))
      .then(res => {
        if (!!res && res.IsOk == true) {
          showSuccessMessage('DO Created Successfully');
          resetEverything();
        } else {
          showErrorMessage(res.ResponseMessage);
        }

        setIsLoading(false);
      })
      .catch(e => {
        showErrorMessage('Failed To Create DO. Please Contact System Admin.');
        setIsLoading(false);
        console.log(e);
      });
  }

  const downloadInvoicesFile = (doId:number, ordersDate:Date) => {
    setIsLoading(true);
    orderApi.downloadInvoicesDocument(doId)
    .then(result => {
      setIsLoading(false);
      const zipBlob = base64ToBlob(result);
      downloadFile(zipBlob,`Invoices-${formattedDate(ordersDate,'DD-MMM-YYYY')}.docx`);
      setInvoiceDownloadStatus(doId);
      
    })
    .catch(e => {
      setIsLoading(false);
      console.log(e);
    })
  }

  const getDoDetailCopy = () =>{
    let detail = {...doDetail,DeliveryOrders:[...doDetail.DeliveryOrders.map(a => a)],CreditWithProductWiseSummary:{...doDetail.CreditWithProductWiseSummary,ProductWiseSummaries:[...doDetail.CreditWithProductWiseSummary.ProductWiseSummaries.map(a => a)]},UnitWiseSummary:[...doDetail.UnitWiseSummary.map(a => a)]};
    return detail;
  }

  const setInvoiceDownloadStatus = (doId:number) =>{
    let detail = getDoDetailCopy();
    let doToSetStatus = detail.DeliveryOrders.find(m => m.DeliveryOrderId == doId);
    doToSetStatus.IsInvoicesDownloaded = true;
    setDODetails(detail);
  }

  const resetEverything = () => {
    setSelectedOrderBookerIds([]);
    setSelectedRouteIds([]);
    setOrderBookersForDO([]);
    setDated(null);
    setDeliveryBoyId(null);
    setDeliveryBoys([]);
    getDeliveryBoyss();
    setDODetails(null);
    setIsFormEnabled(false);
    getDeliveryOrders();
  }

  const onRefresh = () =>{
    setPageMessages([]);
    resetEverything();
  }

  const [isFormEnabled, setIsFormEnabled] = React.useState(false);

  React.useEffect(() => {
    getDeliveryOrders();
  }, [props.selectedUnits.UnitAccesses]);


  const [doDetail, setDODetails] = React.useState<IDOResultWithDetail>(null);

  const getDeliveryOrders = () => {
    setIsLoading(true);
    deliveryOrderApi.getDeliveryOrders(props.loginUser.UserId,getCommaSeparatedUnitUniqueIdsExceptAll([...props.selectedUnits.UnitAccesses.map(a => a)]),DoDetailFor.DOCreation,null,null,null,null)
      .then(doResults => {
        setIsLoading(false);
        if (!!doResults) {
          setDODetails({...doResults});
        }
        else {
          setDODetails(null);
        }
      })
      .catch(e => {
        console.log(e);
        setIsLoading(false);
      });
  }

  const deleteDO = (doId: number) => {
    confirmAction('DO Delete', 'Are You Sure To Delete DO?')
      .then(res => {
        if (res == true) {
          setIsLoading(true);
          deliveryOrderApi.deleteDOAndItsDetails(doId)
            .then(res => {
              setIsLoading(false);
              getDeliveryOrders();
            })
            .catch(e => {
              setIsLoading(false);
            });
        }
      });
  }

  const createNewDO = () => {
    setPageMessages([]);
    setIsFormEnabled(true);
    getDeliveryBoyss();
  }

const backToList = () => {
  if(!!orderBookersForDO && orderBookersForDO.length > 0){
    confirmAction("Confirmation","You havn't created DO. Are you sure you want to go back?")
    .then(res => {
      if(res == true){
        setPageMessages([]);
        resetEverything();
      }
    })
  }else{
    setPageMessages([]);
    resetEverything();
  }
}

  return (
    (!!props.selectedUnits && !!props.selectedUnits.UnitAccesses && props.selectedUnits.UnitAccesses.filter(u => u.UnitUniqueId != AllOptionUniqueId).length > 0) ? <>
    <MessageComponent onRemove={(i: number) => {
            let msgs = [...pageMessages];
            msgs.splice(i, 1);
            setPageMessages([...msgs]);
        }} messages={pageMessages} />
    <Page title="DO | Raza Pak Care">
      {isFormEnabled == true && <Container style={{ maxWidth: 1500, paddingLeft: 0, paddingRight: 0 }}>
        <Grid container style={{ flexDirection: 'row', justifyContent: 'flex-end' }}><LoadingButton
          size="small"
          type="button"
          variant="contained"
          loading={isLoading}
          style={{ margin: 3, backgroundColor: 'rgba(34, 154, 22, 0.72)', textAlign: 'right', height: 40 }}
          startIcon={<Icon icon={arrowBackFill} />}
          onClick={backToList}
        >
          Back To List
        </LoadingButton></Grid>
        <Card>
          <Typography style={{ backgroundColor: 'rgba(34, 154, 22, 0.72)', color: 'white', paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h4'}>Create DO ({props.selectedUnits.UnitAccesses[0].UnitTitle})</Typography>

          <Container style={{ paddingTop: 20, paddingBottom: 10 }}>
            <Grid container spacing={2}>
              <Grid item md={6} sm={12}><CustomAutoComplete<IAutoComplete>
                Id={'Delivery_Boys'}
                Value={!!deliveryBoys && deliveryBoys.length > 0 && deliveryBoyId > 0 ? deliveryBoys.filter(m => m.Value == deliveryBoyId)[0] : null}
                Options={deliveryBoys}
                displayField={'DisplayText'}
                valueField={'Value'}
                Label={'Delivery Boy'}
                onChange={onDeliveryBoyChange}
                disabled={isLoading}
              /></Grid>

              <Grid item md={6} sm={12}>
                <CustomDatePicker
                  label='Date'
                  value={dated}
                  inputDisable={true}
                  readonly={isLoading}
                  onChange={onDateChange}
                />
              </Grid>
            </Grid>
          </Container>

          {isLoading == true && <Audio
            height="80"
            width="80"
            color='green'
            ariaLabel='three-dots-loading'
            wrapperStyle={{ alignSelf: 'center', justifyContent: 'center' }}
          />}
          {!!orderBookersForDO && orderBookersForDO.length > 0 && <hr style={{ backgroundColor: 'whitesmoke' }} />}
          {!!orderBookersForDO && orderBookersForDO.length > 0 && <Scrollbar sx={{}}>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={'asc'}
                  orderBy={''}
                  headLabel={TABLE_HEAD}
                  rowCount={0}
                  numSelected={0}
                  onRequestSort={undefined}
                  onSelectAllClick={undefined}
                  customKey={`TableHeadRow_1`}
                />
                <TableBody>
                  {!!orderBookersForDO && orderBookersForDO.length > 0 && orderBookersForDO.map((row, index) => {
                    const selection = selectedOrderBookerIds.find(m => m == row.EmployeeId);
                    const routeSelection = selectedRouteIds.find(m => m == row.RouteId);
                    const isSelected = selection > 0 && routeSelection > 0;

                    return (
                      <TableRow
                        hover
                        key={row.EmployeeId}
                        tabIndex={-1}
                        role="checkbox"
                        selected={false}
                        aria-checked={false}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isSelected}
                            onChange={(event) => handleOrderBookerSelectionChange(row.EmployeeId, row.RouteId)}
                          />
                        </TableCell>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">{`${row.EmployeeName} [${row.EmployeeId}]`}</TableCell>
                        <TableCell align="center" component="th" scope="row" padding="none">
                          {row?.RouteTitle ?? ''}
                        </TableCell>
                        <TableCell align="center">
                          {row?.TotalOrder ?? ''}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>}
        </Card>

        {!!orderBookersForDO && orderBookersForDO.length > 0 && <Container style={{ paddingTop: 30, paddingBottom: 10 }}>
          <Grid container >
            <Grid item md={12} style={{ textAlign: 'center' }}>
              <LoadingButton
                size="small"
                type="button"
                variant="contained"
                loading={isLoading}
                style={{ margin: 3, backgroundColor: 'blue', width: 180, height: 50 }}
                startIcon={<Icon icon={printerFill} />}
                onClick={createDO}
                disabled={!(!!selectedOrderBookerIds && selectedOrderBookerIds.length > 0 && deliveryBoyId > 0)}
              >
                Create DO
              </LoadingButton>
            </Grid>
          </Grid>
        </Container>}

      </Container>}




      {isFormEnabled == false && <Container style={{ maxWidth: 1500, paddingLeft: 0, paddingRight: 0 }}>
        <Grid container style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>

          <LoadingButton
            size="small"
            type="button"
            variant="contained"
            loading={isLoading}
            style={{ margin: 3, backgroundColor: 'rgba(34, 154, 22, 0.72)', textAlign: 'right', height: 40 }}
            startIcon={<Icon icon={refreshFill} />}
            onClick={onRefresh}
          >
            Refresh
          </LoadingButton>

          {!!props.selectedUnits && !!props.selectedUnits.UnitAccesses && props.selectedUnits.UnitAccesses.length == 1 && <LoadingButton
            size="small"
            type="button"
            variant="contained"
            loading={isLoading}
            style={{ margin: 3, backgroundColor: 'blue', textAlign: 'right', height: 40 }}
            startIcon={<Icon icon={plusFill} />}
            onClick={createNewDO}
          >
            Create New
          </LoadingButton>}</Grid>
        {!!props.selectedUnits && !!props.selectedUnits.UnitAccesses && props.selectedUnits.UnitAccesses.filter(m => m.UnitUniqueId != AllOptionUniqueId).length > 0 && props.selectedUnits.UnitAccesses.filter(m => m.UnitUniqueId != AllOptionUniqueId).map((selectedUnit,unitIndex) => {
          return <><Card>
          <Typography style={{ backgroundColor: 'rgba(34, 154, 22, 0.72)', color: 'white', paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h4'}>DO For ({selectedUnit.UnitTitle})</Typography>
          {(!doDetail || !doDetail.DeliveryOrders || doDetail.DeliveryOrders.length <= 0 || doDetail.DeliveryOrders.filter(m => m.UnitUniqueId == selectedUnit.UnitUniqueId).length <= 0) && isLoading == false && <Typography style={{ paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h6'}>No DO created yet for '{selectedUnit.UnitTitle}'</Typography>}
          {isLoading == true && <Audio
            height="80"
            width="80"
            color='green'
            ariaLabel='three-dots-loading'
            wrapperStyle={{ alignSelf: 'center', justifyContent: 'center' }}
          />}

          {!!doDetail && !!doDetail.DeliveryOrders && doDetail.DeliveryOrders.length > 0 && doDetail.DeliveryOrders.filter(f => f.UnitUniqueId == selectedUnit.UnitUniqueId).length > 0 && <Scrollbar sx={{}}>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table size={'small'} key={`Table_${selectedUnit.UnitUniqueId}`}>
                <UserListHead
                  order={'asc'}
                  orderBy={''}
                  headLabel={TABLE_HEAD_DO}
                  rowCount={0}
                  numSelected={0}
                  onRequestSort={undefined}
                  onSelectAllClick={undefined}
                  customKey={`TableHeadRow_2_${selectedUnit.UnitUniqueId}`}
                />
                <TableBody key={`TableBody_${selectedUnit.UnitUniqueId}`}>
                  {!!doDetail && !!doDetail.DeliveryOrders && doDetail.DeliveryOrders.length > 0 && doDetail.DeliveryOrders.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length > 0 && doDetail.DeliveryOrders.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).map((row, index) => {
                    return (
                      <TableRow
                        hover
                        key={row.DeliveryOrderId}
                        tabIndex={-1}
                        role="checkbox"
                        selected={false}
                        aria-checked={false}
                      >
                        <TableCell width={10} align="center">{index + 1}</TableCell>
                        <TableCell width={300} align="center" component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" justifyContent={'center'} spacing={2}>
                            <Avatar alt={row?.DeliveryBoy} src={row?.DeliveryBoyImageUrl} />
                            <Typography>{row?.DeliveryBoy ?? ''}</Typography>
                          </Stack>
                        </TableCell>

                        <TableCell width={200} align="center" component="th" scope="row" padding="none">
                          {getCommaSeparatedValues<IOrderBooker>(row.OrderBookers, 'OrderBookerName')}
                        </TableCell>

                        <TableCell align="center" component="th" scope="row" padding="none">
                          {row.TotalOrders}
                        </TableCell>

                        <TableCell align="center" component="th" scope="row" padding="none">
                          {formattedDate(row.OrdersDate,'ddd DD-MM-YYYY')}
                        </TableCell>

                        <TableCell align="center">
                          <Label
                            variant="ghost"
                            color={row.DOStatusId == 1 ? 'info' : 'success'}
                          >
                            {sentenceCase(row?.DOStatus ?? '')}
                          </Label>
                        </TableCell>

                        <TableCell align="right">
                          {row.DOStatusId == 1 && <LoadingButton
                            size="small"
                            type="button"
                            variant="contained"
                            loading={isLoading}
                            style={{ margin: 10, backgroundColor: 'red' }}
                            startIcon={<Icon icon={trashFill} />}
                            onClick={() => { deleteDO(row.DeliveryOrderId); }}
                          >
                            Delete
                          </LoadingButton>}
                        </TableCell>

                        <TableCell align="center">
                          {row.IsInvoicesDownloaded == false && <LoadingButton
                            size="small"
                            type="button"
                            variant="contained"
                            loading={isLoading}
                            style={{ margin: 10, backgroundColor: 'blue' }}
                            startIcon={<Icon icon={downloadFill} />}
                            onClick={() => { downloadInvoicesFile(row.DeliveryOrderId, row.OrdersDate); }}
                          >
                            Download Invoices
                          </LoadingButton>}

                          {(row.IsInvoicesDownloaded == true) && <LoadingButton
                            size="small"
                            type="button"
                            variant="contained"
                            loading={isLoading}
                            style={{ margin: 10, backgroundColor: 'rgba(34, 154, 22, 0.72)' }}
                            startIcon={<Icon icon={'teenyicons:tick-circle-solid'} color="white" />}
                            onClick={() => { downloadInvoicesFile(row.DeliveryOrderId, row.OrdersDate); }}
                          >
                            Invoices Downloaded
                          </LoadingButton>}
                        </TableCell>

                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>}
        </Card><br /></>})}

        {!!orderBookersForDO && orderBookersForDO.length > 0 && <Container style={{ paddingTop: 30, paddingBottom: 10 }}>
          <Grid container >
            <Grid item md={12} style={{ textAlign: 'center' }}>
              <LoadingButton
                size="small"
                type="button"
                variant="contained"
                loading={isLoading}
                style={{ margin: 3, backgroundColor: 'blue', width: 180, height: 50 }}
                startIcon={<Icon icon={printerFill} />}
                onClick={createDO}
                disabled={!(!!selectedOrderBookerIds && selectedOrderBookerIds.length > 0 && deliveryBoyId > 0)}
              >
                Create DO
              </LoadingButton>
            </Grid>
          </Grid>
        </Container>}

      </Container>}
    </Page></> : <NoUnitSelectedComponent pageTitle='DO | Raza Pak Care' />
  );
}

const mapStateToProps = (state:IApplicationState) =>{
  return {
    selectedUnits:state.selectedUnitAccesses,
    loginUser:state.loginUser
  };
}
const connectedCreateDO = connect(mapStateToProps)(CreateDO);
export {connectedCreateDO as CreateDO};
