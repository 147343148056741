import {IAction} from './action.interface';
import {TokenActionConstants} from './action.constant';
import {ITokenResult} from '../../interfaces';

export const setTokenAction = (
  payload: ITokenResult | null,
): IAction<ITokenResult> => {
  return {
    type: TokenActionConstants.SET_TOKEN,
    payload,
  };
};
