import { NewOrderReportPrintSettings } from 'src/app.constant';
import {IApplicationState} from './application-state.interface';


const initialState: IApplicationState = {
  token: null,
  loginUser: null,
  userMenu:[],
  userRoutes:null,
  selectedUnitAccesses:null,
  orderNewData:null,
  confirmation:null,
  newOrderReportPrintSetting:{columnsToPrint: [...NewOrderReportPrintSettings.map(a => a)]},
  orderBookerRouteInfo:[],
  isLoadingOrderBookerRouteInfo:false,
  isSavingRouteAssignment:false,
  selectedAreaId:0,
  newRouteSetupPageMessage:null,
  mapConfirmationPopup:null,
  isSavingNewRoute:false,
  selectedRouteIdOnMap:{selectedRouteId:0},
  routePolies:[],
  areaPolies:[],
  existingShops:[],
  newShops:[],
  isLoadingAreaExistingShops:false,
  isLoadingAreaNewShops:false,
  isLoadingAreaPolies:false,
  isLoadingRoutePolies:false,
  isDeletingRoute:false,
  selectedRouteToModify:{
    AddedShopIds: [],
    AreaId: 0,
    BoundaryExistingShops: 0,
    BoundaryNewShops: 0,
    CenterPointLatitude: 0,
    CenterPointLongitude: 0,
    CoveredShops: 0,
    CreatedById: 0,
    CreatedOn: new Date(),
    EmployeeId: 0,
    EmployeeName: '',
    ExistingShopIds: [],
    Id: 0,
    IsActive: true,
    IsNew: 1,
    NeverCoveredShops: 0,
    PendingCredit: 0,
    PolygonData: '',
    RouteDay: 0,
    RouteDayName: '',
    RoutePoly: [],
    RouteTitle: '',
    TotalAddedShops: 0,
    TotalExistingShops: 0,
    TotalShops: 0,
    UpdatedById: 0,
    UpdatedOn: new Date(),
    OrderBookerToAssignRoute: 0,
    RouteDayToAssign: 0,
  }
};

export {initialState};
