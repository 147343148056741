import { IAppliedScheme } from "src/interfaces/applied-scheme.interface"
import { IProductScheme } from "src/interfaces/product-scheme.interface"
import { IAppliedSchemeTotal } from "./applied-scheme-total.interface";
import { getDateOnly } from "./date.util";

const getAppliedSchemeDetails = (productId: number, qty: number, orderedOn: Date, productSchemes: IProductScheme[]) => {
    let appliedScheme: IAppliedScheme = {
        SchemeId: 0,
        SchemeQty: 0,
        IsApplied: false,
        IsSchemeFound: false,
        ProductId: productId,
        AppliedSchemeMinQty: 0,
        LeastSchemeMinQty: 0
    };

    if (!!productSchemes && productSchemes.length > 0) {
        const schemeMayApply = productSchemes.filter(m => getDateOnly(orderedOn) >= getDateOnly(m.ValidFrom) && getDateOnly(orderedOn) <= getDateOnly(m.ValidTo));

        if (!!schemeMayApply && schemeMayApply.length > 0) {
            appliedScheme.IsSchemeFound = true;
            appliedScheme.LeastSchemeMinQty = schemeMayApply.sort((a, b) => a.MinQty - b.MinQty)[0].MinQty;

            const appliedSchemeOnQty = schemeMayApply.filter(m => qty >= m.MinQty);

            if (!!appliedSchemeOnQty && appliedSchemeOnQty.length > 0) {
                appliedScheme.IsApplied = true;

                const actualAppliedScheme = appliedSchemeOnQty.sort((a, b) => { return a.MinQty - b.MinQty }).reverse()[0];

                appliedScheme.SchemeId = actualAppliedScheme.Id;
                appliedScheme.SchemeQty = actualAppliedScheme.SchemeQty;
                appliedScheme.AppliedSchemeMinQty = actualAppliedScheme.MinQty;
            }
        }
    }

    return appliedScheme;
}

export const getTotalAppliedSchemeQty = (productId: number, qty: number, orderedOn: Date, productSchemes: IProductScheme[]) => {
    let totalScheme: IAppliedSchemeTotal = {
        IsSchemeFound: false,
        IsApplied: false,
        ProductId: productId,
        TotalSchemeQty: 0
    };
    const schemeDetail = getAppliedSchemeDetails(productId, qty, orderedOn, productSchemes);
    totalScheme.IsSchemeFound = schemeDetail.IsSchemeFound;
    totalScheme.IsApplied = schemeDetail.IsApplied;
    totalScheme.TotalSchemeQty = schemeDetail.SchemeQty;

    if (schemeDetail.IsApplied == true) {
        let remainingQty = schemeDetail.IsApplied == true ? qty - schemeDetail.AppliedSchemeMinQty : 0;
        const leastSchemeMinQty = schemeDetail.LeastSchemeMinQty;

        while (remainingQty >= leastSchemeMinQty) {
            let remainingQtySchemeDetail = getAppliedSchemeDetails(productId, remainingQty, orderedOn, productSchemes);

            if (remainingQtySchemeDetail.IsApplied == true) {
                totalScheme.TotalSchemeQty += remainingQtySchemeDetail.SchemeQty;
                remainingQty = remainingQty - remainingQtySchemeDetail.AppliedSchemeMinQty;
            } else {
                remainingQty = 0;
            }
        }
    }

    return totalScheme;
}

export const getTotalOrderAndSchemeReturnSeg = (productId: number, totalDeliveredQty: number, orderedOn: Date, productSchemes: IProductScheme[]) => {
    let detail = {
        OrderQty: 0,
        SchemeQty: 0
    };

    const schemeDetail = getAppliedSchemeDetails(productId, totalDeliveredQty, orderedOn, productSchemes);

    detail.SchemeQty = schemeDetail.SchemeQty;

    if (schemeDetail.IsApplied == true) {
        let remainingQty = schemeDetail.IsApplied == true ? totalDeliveredQty - detail.SchemeQty - schemeDetail.AppliedSchemeMinQty : 0;
        const leastSchemeMinQty = schemeDetail.LeastSchemeMinQty;

        while (remainingQty >= leastSchemeMinQty) {
            let remainingQtySchemeDetail = getAppliedSchemeDetails(productId, remainingQty, orderedOn, productSchemes);

            if (remainingQtySchemeDetail.IsApplied == true) {
                detail.SchemeQty += remainingQtySchemeDetail.SchemeQty;
                remainingQty = remainingQty - remainingQtySchemeDetail.SchemeQty - remainingQtySchemeDetail.AppliedSchemeMinQty;
            } else {
                remainingQty = 0;
            }
        }
    }

    detail.OrderQty = totalDeliveredQty - detail.SchemeQty;

    console.log('Calc Details:', detail)
    return detail;
}