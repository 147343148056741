import {http} from './http-base';
import {IEnum} from '../interfaces';
import { IExtraQtyReason } from 'src/interfaces/extra-qty-reason';
import { IAutoComplete } from 'src/interfaces/auto-complete.interface';
import { IEnumOld } from 'src/interfaces/enum-old.interface';


export const getSalaryDuration = async (): Promise<IEnum[]> => {
  const requestUrl = 'api/MasterData/SalaryDuration';

  const result = await http
    .get<IEnum[]>(requestUrl)
    .catch((error:any) => Promise.reject(error));
  return result.data;
};

export const getExtraQtyReasons = async (): Promise<IExtraQtyReason[]> => {
  const requestUrl = 'api/MasterData/ExtraQtyReasons';

  const result = await http
    .get<IExtraQtyReason[]>(requestUrl)
    .catch((error:any) => Promise.reject(error));
  return result.data;
};

const getShopCreditStatuses = async (): Promise<IAutoComplete[]> => {
    let url: string = `api/MasterData/ShopCreditStatuses`;
    let result = await http
      .get<IAutoComplete[]>(url)
      .catch(error => Promise.reject(error));
    return result.data;
  };

  const getShopTypes = async (): Promise<IEnum[]> => {
    let url: string = `api/MasterData/ShopType`;
    let result = await http
      .get<IEnum[]>(url)
      .catch(error => Promise.reject(error));
    return result.data;
  };

  const getShopIn = async (): Promise<IAutoComplete[]> => {
    let url: string = `api/MasterData/ShopIn`;
    let result = await http
      .get<IAutoComplete[]>(url)
      .catch(error => Promise.reject(error));
    return result.data;
  };

  const getShopSize = async (): Promise<IAutoComplete[]> => {
    let url: string = `api/MasterData/ShopSize`;
    let result = await http
      .get<IAutoComplete[]>(url)
      .catch(error => Promise.reject(error));
    return result.data;
  };

  const getTabStatuses = async (): Promise<IEnumOld[]> => {
    let url: string = `api/MasterData/TabStatuses`;
    let result = await http
      .get<IEnumOld[]>(url)
      .catch(error => Promise.reject(error));
    return result.data;
  };

export const masterDataApi = {
  getExtraQtyReasons,
  getShopCreditStatuses,
  getShopTypes,
  getShopIn,
  getShopSize,
  getTabStatuses
};