import React from 'react';
import { useSelector } from 'react-redux';
import { MessageComponent } from 'src/components/messages.component';
import { areaApi, employeeApi, routeApi } from 'src/http';
import { IMessage } from 'src/interfaces/message.interface';
import { IApplicationState } from 'src/redux';
import { formattedDate, generateUUID } from 'src/utility';
import { confirmAction } from 'src/utility/confirm.util';
import { centroid } from 'src/utility/geo.util';
import { fCurrency } from 'src/utils/formatNumber';
import { Audio as AudoSpin } from 'react-loader-spinner'
import { IRouteOnMap } from 'src/interfaces/route-on-map.interface';
import { IRoute } from 'src/interfaces/route.interface';
import { IRoutePoly } from 'src/interfaces/route-poly.interface';
import { IArea } from 'src/interfaces/area.interface';
import Page from 'src/components/Page';
import { Card, Container, Grid, Hidden, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from '@mui/material';
import { CustomAutoComplete } from 'src/components/common/CustomAutoComplete';
import { IAutoComplete } from 'src/interfaces/auto-complete.interface';
import { LoadingButton } from '@mui/lab';
import { Icon } from '@iconify/react';
import { IAvailableOrderBooker } from 'src/interfaces/available-order-booker.interface';
import { IOrderBookerAvailableDay } from 'src/interfaces/order-booker-available-day.interface';
import { UserListHead } from 'src/components/_dashboard/user';
import { IRouteAnalyticsHistory } from 'src/interfaces/route-analytics-history.interface';
import { IMapShop } from 'src/interfaces/map-shop.interface';

interface IRoutePolyOnMap {
  routeId: string;
  polygon: google.maps.Polygon
}

interface IAreaPolyOnMap {
  areaId: string;
  polygon: google.maps.Polygon
}

interface IRouteInfoWindow {
  routeId: string;
  infoWindow: google.maps.InfoWindow;
  marker: google.maps.Marker
}

interface IRouteCenterPointMarker {
  routeId: string;
  marker: google.maps.Marker
}

interface IAreaInfoWindow {
  areaId: string;
  infoWindow: google.maps.InfoWindow;
  marker: google.maps.Marker
}

interface IAreaCenterPointMarker {
  areaId: string;
  marker: google.maps.Marker
}

const TABLE_HEAD = [
  { id: 'sr', label: 'Sr.', alignContent: 'center' },
  { id: 'route_title', label: 'Route Name', alignContent: 'center' },
  { id: 'area_name', label: 'Area Name', alignContent: 'center' },
  { id: 'order_booker', label: 'Order Booker', alignContent: 'center' },
  { id: 'day', label: 'Day', alignContent: 'center' },
  { id: 'total_shops', label: 'Shops', alignContent: 'center' },
  { id: 'g_zone_shops', label: 'G-Shops', alignContent: 'center' },
  { id: 'r_shops', label: 'R-Shops', alignContent: 'center' },
  { id: 'converted_shops', label: 'Converted', alignContent: 'center' },
  { id: 'action_button', label: 'Action', alignContent: 'center' },
];

export const RouteMapComponent = ({
  center,
  zoom,
  isNew
}: {
  center: google.maps.LatLngLiteral;
  zoom: number;
  isNew:number;
}) => {
  // const ref = React.useRef();
  // const loginUser = useSelector((state: IApplicationState) => state.loginUser);
  // const [routesOnMap, _setRoutesOnMap] = React.useState<IRouteOnMap>();
  // const routesOnMapRef = React.useRef(routesOnMap);


  // const [shopsMarker, _setShopsMarker] = React.useState<google.maps.Marker[]>([]);
  // const shopsMarkerRef = React.useRef(shopsMarker);
  // const [routeCenterPointMarkers, _setRouteCenterPointMarkers] = React.useState<IRouteCenterPointMarker[]>([]);
  // const routeCenterPointMarkersRef = React.useRef(routeCenterPointMarkers);
  // const [routeInfoWindows, _setRouteInfoWindows] = React.useState<IRouteInfoWindow[]>([]);
  // const routeInfoWindowsRef = React.useRef(routeInfoWindows);
  // const [routePoliesOnMap, _setRoutePoliesOnMap] = React.useState<IRoutePolyOnMap[]>([]);
  // const routePoliesOnMapRef = React.useRef(routePoliesOnMap);

  // const [areaInfoWindows, _setAreaInfoWindows] = React.useState<IAreaInfoWindow[]>([]);
  // const areaInfoWindowsRef = React.useRef(areaInfoWindows);

  // const [areaCenterPointMarkers, _setAreaCenterPointMarkers] = React.useState<IAreaCenterPointMarker[]>([]);
  // const areaCenterPointMarkersRef = React.useRef(areaCenterPointMarkers);

  // const setAreaCenterPointMarkers = (data: IAreaCenterPointMarker[]) => {
  //   areaCenterPointMarkersRef.current = data;
  //   _setAreaCenterPointMarkers(data);
  // }
  // const setAreaInfoWindows = (data: IAreaInfoWindow[]) => {
  //   areaInfoWindowsRef.current = data;
  //   _setAreaInfoWindows(data);
  // }

  // const setRoutePoliesOnMap = (data: IRoutePolyOnMap[]) => {
  //   routePoliesOnMapRef.current = data;
  //   _setRoutePoliesOnMap(data);
  // }

  // const [areaPoliesOnMap, _setAreaPoliesOnMap] = React.useState<IAreaPolyOnMap[]>([]);
  // const areaPoliesOnMapRef = React.useRef(areaPoliesOnMap);
  // const setAreaPoliesOnMap = (data: IAreaPolyOnMap[]) => {
  //   areaPoliesOnMapRef.current = data;
  //   _setAreaPoliesOnMap(data);
  // }

  // const setRouteInfoWindows = (data: IRouteInfoWindow[]) => {
  //   routeInfoWindowsRef.current = data;
  //   _setRouteInfoWindows(data);
  // }
  // const setRouteCenterPointMarkers = (data: IRouteCenterPointMarker[]) => {
  //   routeCenterPointMarkersRef.current = data;
  //   _setRouteCenterPointMarkers(data);
  // }

  // const setShopsMarker = (shopMarkers: google.maps.Marker[]) => {
  //   shopsMarkerRef.current = shopMarkers;
  //   _setShopsMarker(shopMarkers);
  // }
  // const setRoutesOnMap = (rMaps: IRouteOnMap) => {
  //   routesOnMapRef.current = rMaps;
  //   _setRoutesOnMap(rMaps);
  // }

  // const getRoutesOnMap = (map: google.maps.Map, areaId: number = null, orderBookerId: number = null, routeAssignment: number = null) => {
  //   setIsLoading(true);
  //   setRoutesOnMapForList([]);
  //   routeApi.getRoutesOnMap(isNew, areaId, orderBookerId, routeAssignment)
  //     .then(result => {

  //       if (!!result && !!result.Routes && result.Routes.length > 0) {
  //         result.Routes.forEach(r => {
  //           r.RouteUniqueId = generateUUID();
  //         });
  //         setRoutesOnMapForList([...result.Routes]);
  //         result.Areas.forEach(a => {
  //           a.AreaUniqueId = generateUUID();
  //         })
  //       }

  //       setRoutesOnMap({ ...result });
  //       if(isNew == 0){
  //         addShopsMarkers(map, [...result.ExistingShops]);
  //       }else{
  //         addShopsMarkers(map, [...result.NewShops]);
  //       }
        
  //       drawAreasPolygons(map, [...result.Areas]);
  //       addAreaCenterPointMarker(map, [...result.Areas]);
  //       drawPolygons(map, [...result.Routes]);
  //       addCenterPointMarker(map, [...result.Routes]);

  //       clearMessagesExceptSuccess();
        
  //       setIsLoading(false);
  //     })
  //     .catch(e => {
  //       setIsLoading(false);
  //       showErrorMessage('Unable To Fetch Routes. Please Check Your Internet And Refresh Page Again.');
  //     });
  // }

  // const addShopsMarkers = (map: google.maps.Map, shops: IMapShop[]) => {
  //   if (!!shops && shops.length > 0) {
  //     let markers: google.maps.Marker[] = [];

  //     shops.forEach(s => {
  //       const marker = new google.maps.Marker({
  //         position: { lat: s.Latitude, lng: s.Longitude },
  //         map: map,
  //         icon: '/static/green.png'
  //       });

  //       markers.push(marker);
  //     });

  //     setShopsMarker([...markers]);
  //   }
  // }

  // const addCenterPointMarker = (map: google.maps.Map, routes: IRoute[]) => {
  //   let centerPointMarkers: IRouteCenterPointMarker[] = [];
  //   let routeInfoWindowsTemp: IRouteInfoWindow[] = [];
  //   if (!!routes && routes.length > 0) {
  //     routes.forEach(route => {
  //       const marker = new google.maps.Marker({
  //         position: { lat: route.CenterPointLatitude, lng: route.CenterPointLongitude },
  //         map: map,
  //       });

  //       var infowindow = new google.maps.InfoWindow({
  //         content: `<div style="width:100px;height:100px;">
  //                     <div style="width:100px;text-align:center;">
  //                       <h3>
  //                         ${route.RouteTitle}
  //                       </h3>
  //                     </div>
  //                     <table width="100px" style="margin-top:5px;">
  //                       <tr style="padding-bottom:10px;">
  //                         <td colspan="4" align="center" style="padding-bottom:5px;">
  //                         <h4>${`Current Week Analytics (${formattedDate(route?.LastWeekStartDate, 'DD-MMM-yyyy')} to ${formattedDate(route?.LastWeekEndDate, 'DD-MMM-yyyy')})`}</h4>
  //                         </td>
  //                       </tr>
  //                       ${isNew == 1 ? `<tr>
  //                       <td align="right" colspan="2" style="padding-bottom:7px;">
  //                         <strong>New Shops:</strong>
  //                       </td>
  //                       <td conspan="2" style="padding-left:10px;padding-bottom:7px;">
  //                         <strong>${route?.TotalAddedShops ?? 0}</strong>
  //                       </td>
  //                     </tr>` : `<tr>
  //                     <td align="right" style="padding-bottom:7px;">
  //                       <strong>Existing Shops:</strong>
  //                     </td>
  //                     <td style="padding-left:10px;padding-bottom:7px;">
  //                       <strong>${route?.TotalExistingShops ?? 0}</strong>
  //                     </td>
  //                     <td align="right" style="padding-bottom:7px;">
  //                       <strong>Added Shops:</strong>
  //                     </td>
  //                     <td style="padding-left:10px;padding-bottom:7px;">
  //                       <strong>${route?.TotalAddedShops ?? 0}</strong>
  //                     </td>
  //                   </tr>`}
  //                       <tr>
  //                       <td align="right" style="padding-bottom:7px;">
  //                           <strong>Assigned To:</strong>
  //                       </td>
  //                       <td style="padding-left:10px;padding-bottom:7px;">
  //                           <strong style="color:green">${route?.AssignToFirstName ?? ''} ${route?.AssignToLastName ?? ''}</strong>
  //                       </td>
  //                       <td align="right" style="padding-bottom:7px;">
  //                           <strong>Route Day:</strong>
  //                       </td>
  //                       <td style="padding-left:10px;padding-bottom:7px;color:green">
  //                           <strong>${route.RouteDayName}</strong>
  //                       </td>
  //                       </tr>
  //                       <tr>
  //                         <td align="right" style="padding-bottom:7px;">
  //                           <strong>Total Orders:</strong>
  //                         </td>
  //                         <td style="padding-left:10px;padding-bottom:7px;">
  //                           <strong>${route?.RouteOrderAnalytics?.TotalOrders ?? 0}</strong>
  //                         </td>
  //                         <td align="right" style="padding-bottom:7px;">
  //                           <strong style="color:green">Repeated Orders:</strong>
  //                         </td>
  //                         <td style="color:green;padding-left:10px;padding-bottom:7px;">
  //                           <strong>${route?.RouteOrderAnalytics?.TotalRepeatedOrders ?? 0}</strong>
  //                         </td>
  //                       </tr>
  //                       <tr>
  //                         <td align="right" style="padding-bottom:7px;">
  //                           <strong style="color:red">No Orders:</strong>
  //                         </td>
  //                         <td style="color:red;padding-left:10px;padding-bottom:7px;">
  //                           <strong>${route?.RouteOrderAnalytics?.TotalNoOrders ?? 0}</strong>
  //                         </td>
  //                         <td align="right" style="padding-bottom:7px;">
  //                           <strong style="color:red">Repeated No Orders:</strong>
  //                         </td>
  //                         <td style="color:red;padding-left:10px;padding-bottom:7px;">
  //                           <strong>${route?.RouteOrderAnalytics?.TotalRepeatedNoOrders ?? 0}</strong>
  //                         </td>
  //                       </tr>
  //                       <tr>
  //                         <td align="right" style="padding-bottom:7px;">
  //                           <strong style="color:red">Discount:</strong>
  //                         </td>
  //                         <td style="color:red;padding-left:10px;padding-bottom:7px;">
  //                           <strong>${fCurrency(route?.RouteSaleAnalytics?.TotalDiscountCost ?? 0)}</strong>
  //                         </td>
  //                         <td align="right" style="padding-bottom:7px;">
  //                           <strong style="color:red">Scheme Cost:</strong>
  //                         </td>
  //                         <td style="color:red;padding-left:10px;padding-bottom:7px;">
  //                           <strong>${fCurrency(route?.RouteSaleAnalytics?.TotalSchemeCost ?? 0)}</strong>
  //                         </td>
  //                       </tr>
  //                       <tr>
  //                         <td align="right" style="padding-bottom:7px;">
  //                           <strong style="color:green">Retail Sale:</strong>
  //                         </td>
  //                         <td style="color:green;padding-left:10px;padding-bottom:7px;">
  //                           <strong>${fCurrency(route?.RouteSaleAnalytics?.TotalRetailSale ?? 0)}</strong>
  //                         </td>
  //                         <td align="right" style="padding-bottom:7px;">
  //                           <strong style="color:red">Retail Sale Returned:</strong>
  //                         </td>
  //                         <td style="color:red;padding-left:10px;padding-bottom:7px;">
  //                           <strong>${fCurrency(route?.RouteSaleAnalytics?.TotalRetailSaleReturned ?? 0)}</strong>
  //                         </td>
  //                       </tr>
  //                       <tr>
  //                         <td align="right" style="padding-bottom:7px;">
  //                           <strong style="color:green">Whole Sale:</strong>
  //                         </td>
  //                         <td style="color:green;padding-left:10px;padding-bottom:7px;">
  //                           <strong>${fCurrency(route?.RouteSaleAnalytics?.TotalWholeSale ?? 0)}</strong>
  //                         </td>
  //                         <td align="right" style="padding-bottom:7px;">
  //                           <strong style="color:red">Whole Sale Returned:</strong>
  //                         </td>
  //                         <td style="color:red;padding-left:10px;padding-bottom:7px;">
  //                           <strong>${fCurrency(route?.RouteSaleAnalytics?.TotalWholeSaleReturned ?? 0)}</strong>
  //                         </td>
  //                       </tr>

  //                       <tr>
  //                         <td align="right" colspan="2" style="padding-bottom:7px;">
  //                           <h3 style="color:green">Net Sale:</h3>
  //                         </td>
  //                         <td colspan="2" style="color:green;padding-left:10px;padding-bottom:7px;">
  //                           <h3>${fCurrency(route?.RouteSaleAnalytics?.TotalSale ?? 0)}</h3>
  //                         </td>
  //                       </tr>

  //                     </table>
  //                     </div>`,
  //       });

  //       routeInfoWindowsTemp.push({ routeId: route.RouteUniqueId, infoWindow: infowindow, marker: marker });

  //       google.maps.event.addListener(marker, 'click', function () {
  //         infowindow.open(map, marker);
  //       });

  //       //infowindow.open(map, marker);
  //       centerPointMarkers.push({ routeId: route.RouteUniqueId, marker: marker });
  //     });

  //     setRouteCenterPointMarkers([...centerPointMarkers]);
  //     setRouteInfoWindows([...routeInfoWindowsTemp]);
  //   }
  // }


  // const addAreaCenterPointMarker = (map: google.maps.Map, areas: IArea[]) => {
  //   let centerPointMarkers: IAreaCenterPointMarker[] = [];
  //   let areaInfoWindowsTemp: IAreaInfoWindow[] = [];
  //   if (!!areas && areas.length > 0) {
  //     areas.forEach(area => {
  //       const marker = new google.maps.Marker({
  //         position: { lat: area.CenterPointLatitude, lng: area.CenterPointLongitude },
  //         map: map,
  //       });

  //       var infowindow = new google.maps.InfoWindow({
  //         content: `<div>
  //                       <div text-align:center;">
  //                         <h3>
  //                           Area Name: ${area.AreaTitle}
  //                         </h3>
  //                       </div>
  //                       </div>`,
  //       });

  //       areaInfoWindowsTemp.push({ areaId: area.AreaUniqueId, infoWindow: infowindow, marker: marker });

  //       google.maps.event.addListener(marker, 'click', function () {
  //         infowindow.open(map, marker);
  //       });

  //       //infowindow.open(map, marker);
  //       centerPointMarkers.push({ areaId: area.AreaUniqueId, marker: marker });
  //     });

  //     setAreaCenterPointMarkers([...centerPointMarkers]);
  //     setAreaInfoWindows([...areaInfoWindowsTemp]);
  //   }
  // }

  // const handleAssignRouteClick = (routeId:number, routeUniqueId:string) => {
  //   const polyOnMap = {...routePoliesOnMapRef.current.find(m => m.routeId == routeUniqueId)};
  //   const route = routesOnMapRef.current.Routes.find(m => m.RouteUniqueId == routeUniqueId);

  //   if(!!polyOnMap){
  //     onRoutePolyClick(polyOnMap.polygon,[...routesOnMapRef.current.Routes],routeId,routeUniqueId);
  //     if(!!route && route.CenterPointLatitude > 0 && route.CenterPointLongitude > 0){
  //       currentMapRef.current.setCenter({lat:route.CenterPointLatitude,lng:route.CenterPointLongitude});
  //       currentMapRef.current.setZoom(13);
  //     }
  //     scrollToTop();
  //   }else{
  //     showErrorMessage("Can't find route");
  //   }

  // }

  // const onRoutePolyClick = (routePolyOnMap:google.maps.Polygon,routes:IRoute[],routeId:number, routeUniqueId:string) => {
  //   if (!routePolyOnMap.getEditable()) {
  //     let routePoliesOnMapToSetEdit = [...routePoliesOnMapRef.current];
  //     let routeIdsToRemove: string[] = [];
  //     if (!!routePoliesOnMapToSetEdit && routePoliesOnMapToSetEdit.length > 0) {
  //       routePoliesOnMapToSetEdit.forEach(ap => {
  //         if (!!routesOnMapRef.current.Routes.find(m => m.RouteUniqueId == ap.routeId) && routesOnMapRef.current.Routes.find(m => m.RouteUniqueId == ap.routeId).Id <= 0) {
  //           ap.polygon.setMap(null);
  //           const centerPoint = routeCenterPointMarkersRef.current.find(n => n.routeId == ap.routeId);
  //           centerPoint.marker.setMap(null);
  //           routeIdsToRemove.push(ap.routeId);
  //         } else {
  //           ap.polygon.setEditable(ap.routeId == routeUniqueId);
  //           addOrRefreshInfoWindowContent(ap.routeId, { ...routesOnMapRef.current.Routes.find(m => m.RouteUniqueId == ap.routeId) }, false)
  //         }
  //       });

  //       setRoutePoliesOnMap([...routePoliesOnMapToSetEdit.filter(m => routeIdsToRemove.findIndex(t => m.routeId == t) < 0)]);
  //       setRouteCenterPointMarkers([...routeCenterPointMarkersRef.current.filter(m => routeIdsToRemove.findIndex(t => m.routeId == t) < 0)]);
  //       setRouteInfoWindows([...routeInfoWindowsRef.current.filter(m => routeIdsToRemove.findIndex(t => m.routeId == t) < 0)]);
  //     }

  //     routePolyOnMap.setEditable(true);
  //     let selectedRoute = { ...routes.find(m => m.RouteUniqueId == routeUniqueId) };
  //     setCurrentRouteOrignal({ ...selectedRoute, RoutePoly: [...selectedRoute.RoutePoly] });
  //     addCurrentRouteAnalytics({...selectedRoute});
  //     onUpdateRoutePoly(routeId, routeUniqueId, routePolyOnMap);
  //   }
  // }

  // const drawPolygons = (map: google.maps.Map, routes: IRoute[]) => {
  //   if (!!routes && routes.length > 0) {
  //     let routesPolyOnMap: IRoutePolyOnMap[] = [];
  //     routes.forEach(r => {
  //       if (!!r && !!r.RoutePoly && r.RoutePoly.length > 0) {
  //         const poly = r.RoutePoly.sort((a, b) => a.PointOrder - b.PointOrder);
  //         let polyCoordinates = [];

  //         poly.forEach(p => {
  //           polyCoordinates.push({ lat: p.PointLatitude, lng: p.PointLongitude });
  //         });

  //         const color = getRandomColor();
  //         const routePolyOnMap = new google.maps.Polygon({
  //           paths: polyCoordinates,
  //           strokeColor: color,
  //           strokeOpacity: 0.8,
  //           strokeWeight: 2,
  //           fillColor: color,
  //           editable: false,
  //           fillOpacity: 0.1,
  //         });

  //         google.maps.event.addListener(routePolyOnMap.getPath(), 'insert_at', function (index, obj) {
  //           onUpdateRoutePoly(r.Id, r.RouteUniqueId, routePolyOnMap);
  //         });
  //         google.maps.event.addListener(routePolyOnMap.getPath(), 'set_at', function (index, obj) {
  //           onUpdateRoutePoly(r.Id, r.RouteUniqueId, routePolyOnMap);
  //         });

  //         google.maps.event.addListener(routePolyOnMap, 'click', function (event) {
  //           onRoutePolyClick(routePolyOnMap,[...routes],r.Id,r.RouteUniqueId);
  //         });

  //         routesPolyOnMap.push({ routeId: r.RouteUniqueId, polygon: routePolyOnMap });
  //         routePolyOnMap.setMap(map);
  //       }
  //     });

  //     setRoutePoliesOnMap([...routesPolyOnMap]);
  //   }
  // }

  // const drawAreasPolygons = (map: google.maps.Map, areas: IArea[]) => {
  //   if (!!areas && areas.length > 0) {
  //     let areasPolyOnMap: IAreaPolyOnMap[] = [];
  //     areas.forEach(a => {
  //       if (!!a && !!a.AreaPoly && a.AreaPoly.length > 0) {
  //         const poly = a.AreaPoly.sort((a, b) => a.PointOrder - b.PointOrder);
  //         let polyCoordinates = [];

  //         poly.forEach(p => {
  //           polyCoordinates.push({ lat: p.PointLatitude, lng: p.PointLongitude });
  //         });

  //         const color = getRandomColor();
  //         const areaPolyOnMap = new google.maps.Polygon({
  //           paths: polyCoordinates,
  //           strokeColor: color,
  //           strokeOpacity: 0.8,
  //           strokeWeight: 2,
  //           fillColor: color,
  //           editable: false,
  //           fillOpacity: 0.05,
  //         });

  //         areasPolyOnMap.push({ areaId: a.AreaUniqueId, polygon: areaPolyOnMap });
  //         areaPolyOnMap.setMap(map);
  //       }
  //     });

  //     setAreaPoliesOnMap([...areasPolyOnMap]);
  //   }
  // }

  // const getRandomColor = () => {
  //   var color = '#';
  //   for (var i = 0; i < 6; i++) {
  //     color += Math.floor(Math.random() * 10);
  //   }
  //   return color;
  // }

  // const [currentMap, _setCurrentMap] = React.useState<google.maps.Map>();
  // const currentMapRef = React.useRef(currentMap);

  // const setCurrentMap = (cMap: google.maps.Map) => {
  //   currentMapRef.current = cMap;
  //   _setCurrentMap(cMap);
  // }
  // const [currentRoute, _setCurrentRoute] = React.useState<IRoute>();
  // const currentRouteRef = React.useRef(currentRoute);

  // const setCurrentRoute = (r: IRoute) => {
  //   currentRouteRef.current = r;
  //   _setCurrentRoute(r);
  // }

  // const scrollToTop = () =>{
  //   window.scrollTo(0,0);
  // }

  // const setOrderBookersToAssignRouteOnCurrentRouteChange = (areaId:number) =>{
  //   if (areaId > 0) {
  //     let rArea = {...routesOnMapRef.current.Areas.find(m => m.Id == areaId)};
  //     if (!!rArea && !!rArea.AvailableOrderBookers && rArea.AvailableOrderBookers.length > 0) {
  //       let bookers = [...rArea.AvailableOrderBookers];
  //       bookers.forEach(b => {
  //         b.DisplayText = `${b.FirstName} ${b.LastName} [${b.EmployeeTagId}]`;
  //       })
  //       let availableBookers: IAvailableOrderBooker[] = [{ AreaId: rArea.Id, EmployeeId: 0, FirstName: 'UnAssigned', DisplayText: 'UnAssigned', LastName: '',EmployeeTagId:'', OrderBookerAvailableDays: [] }, ...rArea.AvailableOrderBookers];
  //       setOrderBookersToAssignRoute([...availableBookers]);
  //     } else {
  //       setOrderBookersToAssignRoute([]);
  //     }
  //   } else {
  //     setOrderBookersToAssignRoute([]);
  //   }
  // }

  // const [orderBookersToAssignRoute, _setOrderBookersToAssignRoute] = React.useState<IAvailableOrderBooker[]>();
  // const orderBookersToAssignRouteRef = React.useRef(orderBookersToAssignRoute);
  // const setOrderBookersToAssignRoute = (data: IAvailableOrderBooker[]) => {
  //   orderBookersToAssignRouteRef.current = data;
  //   _setOrderBookersToAssignRoute(data);
  // }

  // const [routeDaysToAssign, _setRouteDaysToAssign] = React.useState<IOrderBookerAvailableDay[]>();
  // const routeDaysToAssignRef = React.useRef(routeDaysToAssign);
  // const setRouteDaysToAssign = (data: IOrderBookerAvailableDay[]) => {
  //   routeDaysToAssignRef.current = data;
  //   _setRouteDaysToAssign(data);
  // }

  // const handleOrderBookerToAssignRouteChange = (data: IAvailableOrderBooker) => {
  //   clearMessagesExceptSuccess();
  //   if (!!data && !!data.OrderBookerAvailableDays && data.OrderBookerAvailableDays.length > 0) {
  //     setRouteDaysToAssign([...data.OrderBookerAvailableDays]);
  //     let routeToAssign = { ...currentRouteRef.current };
  //     if (!!routeToAssign) {
  //       routeToAssign.OrderBookerToAssignRoute = data.EmployeeId;
  //       routeToAssign.RouteDayToAssign = null;
  //       routeToAssign.RouteDateToAssign = null;
  //       setCurrentRoute({ ...routeToAssign });
  //     }
  //   } else {
  //     setRouteDaysToAssign([]);
  //     let routeToAssign = { ...currentRouteRef.current };

  //     if (!!routeToAssign) {
  //       routeToAssign.OrderBookerToAssignRoute = 0;
  //       routeToAssign.RouteDayToAssign = null;
  //       routeToAssign.RouteDateToAssign = null;
  //       setCurrentRoute({ ...routeToAssign });
  //     }
  //   }
  // }

  // const handleRouteDayToAssignChange = (data: IOrderBookerAvailableDay) => {
  //   clearMessagesExceptSuccess();
  //   if (!!data) {
  //     let routeToAssign = { ...currentRouteRef.current };
  //     routeToAssign.RouteDayToAssign = data.AvailableDW;
  //     routeToAssign.RouteDateToAssign = data.AvailableDate;
  //     setCurrentRoute({ ...routeToAssign });
  //   } else {
  //     let routeToAssign = { ...currentRouteRef.current };
  //     routeToAssign.RouteDayToAssign = null;
  //     routeToAssign.RouteDateToAssign = null;
  //     setCurrentRoute({ ...routeToAssign });
  //   }
  // }

  // const handleRouteTitleChange = (value:string) =>{
  //   clearMessagesExceptSuccess();
  //   let val = !!value ? value : '';
  //   let routeToAssign = { ...currentRouteRef.current };
  //     if (!!routeToAssign) {
  //       routeToAssign.RouteTitle = val;
  //       setCurrentRoute({ ...routeToAssign });
  //     }
  // }

  // const [currentRouteOrignal, _setCurrentRouteOrignal] = React.useState<IRoute>();
  // const currentRouteOrignalRef = React.useRef(currentRouteOrignal);
  // const setCurrentRouteOrignal = (data: IRoute) => {
  //   currentRouteOrignalRef.current = data;
  //   _setCurrentRouteOrignal(data);
  // }

  // const onUpdateRoutePoly = (routeId: number, routeUniqueId: string, overlay: google.maps.Polygon) => {
  //   let routePolies: IRoutePoly[] = [];
  //   let arr = overlay.getPath().getArray();
  //   let pointOrder = 0;
  //   let points = [];
  //   arr.forEach(element => {
  //     pointOrder += 1;
  //     points.push({ x: element.lat(), y: element.lng() })
  //     routePolies.push({
  //       RouteId: routeId,
  //       Id: 0,
  //       IsActive: true,
  //       PointLatitude: element.lat(),
  //       PointLongitude: element.lng(),
  //       PointOrder: pointOrder,
  //       RouteUniqueId: routeUniqueId,
  //     });
  //   });


  //   let existingShopsInside = 0;
  //   let existingShopIds: number[] = [];
  //   if (!!routesOnMapRef.current && !!routesOnMapRef.current.ExistingShops && routesOnMapRef.current.ExistingShops.length > 0) {
  //     routesOnMapRef.current.ExistingShops.forEach(sh => {
  //       if (google.maps.geometry.poly.containsLocation({ lat: sh.Latitude, lng: sh.Longitude }, overlay)) {
  //         existingShopsInside = existingShopsInside + 1;
  //         existingShopIds.push(sh.Id);
  //       }
  //     });
  //   }

  //   let newShopsInside = 0;
  //   let newShopIdsInside: number[] = [];
  //   if (!!routesOnMapRef.current && !!routesOnMapRef.current.NewShops && routesOnMapRef.current.NewShops.length > 0) {
  //     routesOnMapRef.current.NewShops.forEach(sh => {
  //       if (google.maps.geometry.poly.containsLocation({ lat: sh.Latitude, lng: sh.Longitude }, overlay)) {
  //         newShopsInside = newShopsInside + 1;
  //         newShopIdsInside.push(sh.Id);
  //       }
  //     });
  //   }

  //   const centerPoint = centroid(points);
  //   if (routeUniqueId != '' && !!routesOnMapRef.current && !!routesOnMapRef.current.Routes && routesOnMapRef.current.Routes.length > 0 && routesOnMapRef.current.Routes.findIndex(m => m.RouteUniqueId == routeUniqueId) >= 0) {
  //     let routes = [...routesOnMapRef.current.Routes];
  //     let selectedRoute = { ...routes.find(m => m.RouteUniqueId == routeUniqueId) };
  //     let index = routes.findIndex(n => n.RouteUniqueId == routeUniqueId);
  //     selectedRoute.TotalExistingShops = existingShopsInside;
  //     selectedRoute.TotalAddedShops = newShopsInside;
  //     selectedRoute.RoutePoly = [...routePolies];
  //     selectedRoute.ExistingShopIds = [...existingShopIds];
  //     selectedRoute.AddedShopIds = [...newShopIdsInside];

  //     if(!!currentRouteRef.current && !!currentRouteRef.current.RouteTitle && currentRouteRef.current.RouteTitle.trim() != ''){
  //       selectedRoute.RouteTitle = currentRouteRef.current.RouteTitle ?? '';
  //     }
      
  //     routes[index] = { ...selectedRoute };
  //     setRoutesOnMap({ ...routesOnMapRef.current, Routes: [...routes] });
  //     setCurrentRoute({ ...selectedRoute, RoutePoly: [...routePolies] });
  //     setOrderBookersToAssignRouteOnCurrentRouteChange(selectedRoute.AreaId);
  //     addOrRefreshInfoWindowContent(routeUniqueId, { ...selectedRoute });
  //   } else {
  //     const routeAreaId = getAreaId([...routePolies]);
  //     const routeArea = routesOnMapRef.current?.Areas?.find(m => m.Id == routeAreaId);
  //     let selectedRoute: IRoute = {
  //       RouteOrderAnalytics: null,
  //       RoutePoly: [...routePolies],
  //       RouteSaleAnalytics: null,
  //       RouteTitle: '',
  //       CenterPointLatitude: centerPoint.lat,
  //       CenterPointLongitude: centerPoint.lng,
  //       CreatedById: loginUser.UserId ?? 0,
  //       CreatedOn: new Date(),
  //       Id: 0,
  //       IsActive: true,
  //       PolygonData: '',
  //       TotalExistingShops: existingShopsInside,
  //       TotalAddedShops: newShopsInside,
  //       UpdatedById: loginUser.UserId ?? 0,
  //       UpdatedOn: new Date(),
  //       RouteUniqueId: routeUniqueId,
  //       ExistingShopIds: [...existingShopIds],
  //       AddedShopIds: [...newShopIdsInside],
  //       AreaId: routeAreaId,
  //       AssignedOn: null,
  //       AssignToEmployeeId: null,
  //       AssignToFirstName: '',
  //       AssignToLastName: '',
  //       AssignToTabletId: null,
  //       RouteAssignId: null,
  //       RouteDate: null,
  //       RouteDay: null,
  //       RouteDayName: '',
  //       LastWeekStartDate: null,
  //       LastWeekEndDate: null,
  //       AreaTitle: !!routeArea ? routeArea.AreaTitle : '',
  //       IsNew:isNew
  //     };

  //     if(!!currentRouteRef.current && !!currentRouteRef.current.RouteTitle && currentRouteRef.current.RouteTitle.trim() != ''){
  //       selectedRoute.RouteTitle = currentRouteRef.current.RouteTitle ?? '';
  //     }

  //     let routesOnM = [...routesOnMapRef.current.Routes, { ...selectedRoute }]
  //     setRoutesOnMap({ ...routesOnMapRef.current, Routes: [...routesOnM] });
  //     setCurrentRoute({ ...selectedRoute, RoutePoly: [...routePolies] });
  //     setOrderBookersToAssignRouteOnCurrentRouteChange(routeAreaId);
  //     addOrRefreshInfoWindowContent(routeUniqueId, { ...selectedRoute });
  //     setRoutePoliesOnMap([...routePoliesOnMapRef.current, { routeId: routeUniqueId, polygon: overlay }]);
  //   }
  // }

  // const addOrRefreshInfoWindowContent = (routeId: string, route: IRoute, isEditable: boolean = false) => {
  //   let iWindow = routeInfoWindowsRef.current.find(m => m.routeId == routeId);
  //   if (!!iWindow) {
  //     if (route.Id <= 0) {
  //       let infoContent = `<div style="width:100px;height:100px">
  //         <div style="width:100px;text-align:center;">
  //           <h3>
  //           ${isEditable == true ? `<input style="padding:10px;width:350px;" type="text" name="txtRouteTitle" id="txtRouteTitle" value="${route.RouteTitle}" />` : `<h3>${route.RouteTitle}</h3>`}
  //           </h3>
  //         </div>
  //         <table width="100px" style="margin-top:5px;">
  //           ${isNew == 1 ? `<tr>
  //           <td colspan="2" align="right" style="padding-bottom:7px;">
  //             <strong>New Shops:</strong>
  //           </td>
  //           <td colspan="2" style="padding-left:10px;padding-bottom:7px;">
  //             <strong>${route?.TotalAddedShops ?? 0}</strong>
  //           </td>
  //         </tr>` : `<tr>
  //           <td align="right" style="padding-bottom:7px;">
  //             <strong>Existing Shops:</strong>
  //           </td>
  //           <td style="padding-left:10px;padding-bottom:7px;">
  //             <strong>${route?.TotalExistingShops ?? 0}</strong>
  //           </td>
  //           <td align="right" style="padding-bottom:7px;">
  //             <strong>Added Shops:</strong>
  //           </td>
  //           <td style="padding-left:10px;padding-bottom:7px;">
  //             <strong>${route?.TotalAddedShops ?? 0}</strong>
  //           </td>
  //         </tr>`}
  //           ${isEditable == true ? `<tr>
  //             <td colspan="4" align="center" style="padding-bottom:5px;">
  //               <button onclick="window.saveRouteDetails()" style="padding:10px;cursor:pointer;">Save Changes</button>
  //               <button onclick="window.resetEverything()" style="padding:10px;cursor:pointer;">Reset</button>
  //             </td>
  //           </tr>` : ''}
  //         </table>
  //         </div>`;
  //       iWindow.infoWindow.setContent(infoContent);
  //     } else {
  //       let infoContent = `<div style="width:100px;height:100px;">
  //         <div style="width:100px;text-align:center;">
  //         ${isEditable == true ? `<input style="padding:10px;width:350px;" type="text" name="txtRouteTitle" id="txtRouteTitle" value="${route.RouteTitle}" />` : `<h3>${route.RouteTitle}</h3>`}
  //         </div>
  //         <table width="100px" style="margin-top:5px;">
  //           <tr style="padding-bottom:10px;">
  //             <td colspan="4" align="center" style="padding-bottom:5px;">
  //               <h4>${`Current Week Analytics (${formattedDate(route?.LastWeekStartDate, 'DD-MMM-yyyy')} to ${formattedDate(route?.LastWeekEndDate, 'DD-MMM-yyyy')})`}</h4>
  //             </td>
  //           </tr>
  //           ${isNew == 1 ? `<tr>
  //           <td colspan="2" align="right" style="padding-bottom:7px;">
  //             <strong>New Shops:</strong>
  //           </td>
  //           <td colspan="2" style="padding-left:10px;padding-bottom:7px;">
  //             <strong>${route?.TotalAddedShops ?? 0}</strong>
  //           </td>
  //         </tr>` : `<tr>
  //         <td align="right" style="padding-bottom:7px;">
  //           <strong>Existing Shops:</strong>
  //         </td>
  //         <td style="padding-left:10px;padding-bottom:7px;">
  //           <strong>${route?.TotalExistingShops ?? 0}</strong>
  //         </td>
  //         <td align="right" style="padding-bottom:7px;">
  //           <strong>Added Shops:</strong>
  //         </td>
  //         <td style="padding-left:10px;padding-bottom:7px;">
  //           <strong>${route?.TotalAddedShops ?? 0}</strong>
  //         </td>
  //       </tr>`}
  //           <tr>
  //             <td align="right" style="padding-bottom:7px;">
  //               <strong>Assigned To:</strong>
  //             </td>
  //             <td style="padding-left:10px;padding-bottom:7px;">
  //               <strong style="color:green">${route?.AssignToFirstName ?? ''} ${route?.AssignToLastName ?? ''}</strong>
  //             </td>
  //             <td align="right" style="padding-bottom:7px;">
  //               <strong>Route Day:</strong>
  //             </td>
  //             <td style="padding-left:10px;padding-bottom:7px;">
  //               <strong style="color:green">${route.RouteDayName}</strong>
  //             </td>
  //           </tr>
  //           <tr>
  //             <td align="right" style="padding-bottom:7px;">
  //               <strong>Total Orders:</strong>
  //             </td>
  //             <td style="padding-left:10px;padding-bottom:7px;">
  //               <strong>${route?.RouteOrderAnalytics?.TotalOrders ?? 0}</strong>
  //             </td>
  //             <td align="right" style="padding-bottom:7px;">
  //               <strong style="color:green">Repeated Orders:</strong>
  //             </td>
  //             <td style="color:green;padding-left:10px;padding-bottom:7px;">
  //               <strong>${route?.RouteOrderAnalytics?.TotalRepeatedOrders ?? 0}</strong>
  //             </td>
  //           </tr>
  //           <tr>
  //             <td align="right" style="padding-bottom:7px;">
  //               <strong style="color:red">No Orders:</strong>
  //             </td>
  //             <td style="color:red;padding-left:10px;padding-bottom:7px;">
  //               <strong>${route?.RouteOrderAnalytics?.TotalNoOrders ?? 0}</strong>
  //             </td>
  //             <td align="right" style="padding-bottom:7px;">
  //               <strong style="color:red">Repeated No Orders:</strong>
  //             </td>
  //             <td style="color:red;padding-left:10px;padding-bottom:7px;">
  //               <strong>${route?.RouteOrderAnalytics?.TotalRepeatedNoOrders ?? 0}</strong>
  //             </td>
  //           </tr>
  //           <tr>
  //             <td align="right" style="padding-bottom:7px;">
  //               <strong style="color:red">Discount:</strong>
  //             </td>
  //             <td style="color:red;padding-left:10px;padding-bottom:7px;">
  //               <strong>${fCurrency(route?.RouteSaleAnalytics?.TotalDiscountCost ?? 0)}</strong>
  //             </td>
  //             <td align="right" style="padding-bottom:7px;">
  //               <strong style="color:red">Scheme Cost:</strong>
  //             </td>
  //             <td style="color:red;padding-left:10px;padding-bottom:7px;">
  //               <strong>${fCurrency(route?.RouteSaleAnalytics?.TotalSchemeCost ?? 0)}</strong>
  //             </td>
  //           </tr>
  //           <tr>
  //             <td align="right" style="padding-bottom:7px;">
  //               <strong style="color:green">Retail Sale:</strong>
  //             </td>
  //             <td style="color:green;padding-left:10px;padding-bottom:7px;">
  //               <strong>${fCurrency(route?.RouteSaleAnalytics?.TotalRetailSale ?? 0)}</strong>
  //             </td>
  //             <td align="right" style="padding-bottom:7px;">
  //               <strong style="color:red">Retail Sale Returned:</strong>
  //             </td>
  //             <td style="color:red;padding-left:10px;padding-bottom:7px;">
  //               <strong>${fCurrency(route?.RouteSaleAnalytics?.TotalRetailSaleReturned ?? 0)}</strong>
  //             </td>
  //           </tr>
  //           <tr>
  //             <td align="right" style="padding-bottom:7px;">
  //               <strong style="color:green">Whole Sale:</strong>
  //             </td>
  //             <td style="color:green;padding-left:10px;padding-bottom:7px;">
  //               <strong>${fCurrency(route?.RouteSaleAnalytics?.TotalWholeSale ?? 0)}</strong>
  //             </td>
  //             <td align="right" style="padding-bottom:7px;">
  //               <strong style="color:red">Whole Sale Returned:</strong>
  //             </td>
  //             <td style="color:red;padding-left:10px;padding-bottom:7px;">
  //               <strong>${fCurrency(route?.RouteSaleAnalytics?.TotalWholeSaleReturned ?? 0)}</strong>
  //             </td>
  //           </tr>
  
  //           <tr>
  //             <td align="right" colspan="2" style="padding-bottom:7px;">
  //               <h3 style="color:green">Net Sale:</h3>
  //             </td>
  //             <td colspan="2" style="color:green;padding-left:10px;padding-bottom:7px;">
  //               <h3>${fCurrency(route?.RouteSaleAnalytics?.TotalSale ?? 0)}</h3>
  //             </td>
  //           </tr>
  
  //           ${isEditable == true ? `<tr>
  //             <td colspan="4" align="center" style="padding-bottom:5px;">
  //               <button onclick="window.saveRouteDetails()" style="padding:10px;cursor:pointer;">Save Changes</button>
  //               <button onclick="window.resetEverything()" style="padding:10px;cursor:pointer;">Reset</button>
  //             </td>
  //           </tr>` : ''}
  
  //         </table>
  //         </div>`;

  //       iWindow.infoWindow.setContent(infoContent);
  //     }

  //     //iWindow.infoWindow.open(currentMapVar,iWindow.marker);
  //   } else {
  //     const marker = new google.maps.Marker({
  //       position: { lat: route.CenterPointLatitude, lng: route.CenterPointLongitude },
  //       map: currentMapRef.current,
  //     });

  //     let infowindow = new google.maps.InfoWindow({
  //       content: `<div style="width:100px;height:100px;">
  //                   <div style="width:100px;text-align:center;">
  //                     <h3>
  //                     ${route.RouteTitle}
  //                     </h3>
  //                   </div>
  //                   <table width="100px" style="margin-top:5px;">
  //                     ${isNew == 1 ? `<tr>
  //                     <td colspan="2" align="right" style="padding-bottom:7px;">
  //                       <strong>New Shops:</strong>
  //                     </td>
  //                     <td colspan="2" style="padding-left:10px;padding-bottom:7px;">
  //                       <strong>${route?.TotalAddedShops ?? 0}</strong>
  //                     </td>
  //                   </tr>` : `<tr>
  //                     <td align="right" style="padding-bottom:7px;">
  //                       <strong>Existing Shops:</strong>
  //                     </td>
  //                     <td style="padding-left:10px;padding-bottom:7px;">
  //                       <strong>${route?.TotalExistingShops ?? 0}</strong>
  //                     </td>
  //                     <td align="right" style="padding-bottom:7px;">
  //                       <strong>Added Shops:</strong>
  //                     </td>
  //                     <td style="padding-left:10px;padding-bottom:7px;">
  //                       <strong>${route?.TotalAddedShops ?? 0}</strong>
  //                     </td>
  //                   </tr>`}
  //                   </table>
  //                   </div>`,
  //     });

  //     google.maps.event.addListener(marker, 'click', function () {
  //       infowindow.open(currentMapRef.current, marker);
  //     });

  //     //infowindow.open(currentMapRef.current, marker);

  //     setRouteCenterPointMarkers([...routeCenterPointMarkersRef.current, { routeId: route.RouteUniqueId, marker: marker }]);
  //     setRouteInfoWindows([...routeInfoWindowsRef.current, { routeId: routeId, infoWindow: infowindow, marker: marker }]);
  //   }
  // }

  // const resetEverythingExceptShopMarkers = () => {
  //   clearMessagesExceptSuccess();
  //   if (!!routeCenterPointMarkersRef.current && routeCenterPointMarkersRef.current.length > 0) {
  //     routeCenterPointMarkersRef.current.forEach(m => {
  //       m.marker.setMap(null);
  //     });
  //   }

  //   setRouteCenterPointMarkers([]);

  //   if (!!routePoliesOnMapRef.current && routePoliesOnMapRef.current.length > 0) {
  //     routePoliesOnMapRef.current.forEach(p => {
  //       p.polygon.setMap(null);
  //     });
  //   }

  //   setRoutePoliesOnMap([]);

  //   if (!!currentRouteOrignalRef.current && !!routesOnMapRef.current && !!routesOnMapRef.current.Routes && routesOnMapRef.current.Routes.findIndex(t => t.RouteUniqueId == currentRouteOrignalRef.current.RouteUniqueId) >= 0) {
  //     let rOnMaps = [...routesOnMapRef.current.Routes];
  //     let index = rOnMaps.findIndex(m => m.RouteUniqueId == currentRouteOrignalRef.current.RouteUniqueId);
  //     rOnMaps[index] = { ...currentRouteOrignalRef.current };
  //     rOnMaps = rOnMaps.filter(m => m.Id != 0);
  //     setRoutesOnMap({ ...routesOnMapRef.current, Routes: [...rOnMaps] });
  //     drawPolygons(currentMapRef.current, [...rOnMaps]);
  //     addCenterPointMarker(currentMapRef.current, rOnMaps);
  //   } else {
  //     setRoutesOnMap({ ...routesOnMapRef.current, Routes: routesOnMapRef.current.Routes.filter(m => m.Id != 0) });
  //     drawPolygons(currentMapRef.current, [...routesOnMapRef.current.Routes.filter(m => m.Id != 0)]);
  //     addCenterPointMarker(currentMapRef.current, [...routesOnMapRef.current.Routes.filter(m => m.Id != 0)]);
  //   }

  //   setCurrentRoute(null);
  //   setCurrentRouteOrignal(null);
  //   setCurrentRouteAnalytics([]);
  //   setOrderBookersToAssignRouteOnCurrentRouteChange(null);
  //   setRouteDaysToAssign([]);
  // }

  // (window as any).resetEverything = resetEverythingExceptShopMarkers;

  // const clearMap = () => {
  //   if (!!routeCenterPointMarkersRef.current && routeCenterPointMarkersRef.current.length > 0) {
  //     routeCenterPointMarkersRef.current.forEach(m => {
  //       m.marker.setMap(null);
  //     });
  //   }

  //   setRouteCenterPointMarkers([]);

  //   if (!!routePoliesOnMapRef.current && routePoliesOnMapRef.current.length > 0) {
  //     routePoliesOnMapRef.current.forEach(p => {
  //       p.polygon.setMap(null);
  //     });
  //   }

  //   if (!!areaCenterPointMarkersRef.current && areaCenterPointMarkersRef.current.length > 0) {
  //     areaCenterPointMarkersRef.current.forEach(ac => {
  //       ac.marker.setMap(null);
  //     });
  //   }

  //   setAreaCenterPointMarkers([]);

  //   if (!!areaPoliesOnMapRef.current && areaPoliesOnMapRef.current.length > 0) {
  //     areaPoliesOnMapRef.current.forEach(ap => {
  //       ap.polygon.setMap(null);
  //     });
  //   }

  //   setAreaPoliesOnMap([]);

  //   if (!!shopsMarkerRef.current && shopsMarkerRef.current.length > 0) {
  //     shopsMarkerRef.current.forEach(sm => {
  //       sm.setMap(null);
  //     });
  //   }

  //   currentMapRef.current.setZoom(8);

  //   setShopsMarker([]);

  //   setRoutePoliesOnMap([]);
  //   setCurrentRoute(null);
  //   setCurrentRouteOrignal(null);
  //   setCurrentRouteAnalytics([]);
  //   setOrderBookersToAssignRouteOnCurrentRouteChange(null);
  //   setRouteDaysToAssign([]);
  // }

  // const saveRouteDetails = () => {
  //   if (validate()) {
  //     let routeToSave = { ...currentRouteRef.current };

  //     if(!!routeToSave.OrderBookerToAssignRoute && (!routeToSave.RouteDayToAssign || routeToSave.RouteDayToAssign <= 0)){
  //       routeToSave.OrderBookerToAssignRoute = null;
  //       routeToSave.RouteDayToAssign = null;
  //       routeToSave.RouteDateToAssign = null;
  //     }
  //     setIsLoading(true);
  //     routeToSave.IsNew = isNew;
  //     routeApi.saveRouteDetails(loginUser.UserId, routeToSave)
  //       .then(res => {
  //         resetAndReloadEverything();
  //         showSuccessMessage(`Changes To Route '${routeToSave.RouteTitle}' Saved Successfully`);
  //         setIsLoading(false);
  //       })
  //       .catch(e => {
  //         showErrorMessage('Failed To Save Route');
  //         setIsLoading(false);
  //       })
  //   }
  // }

  // const validate = () => {
  //   clearMessagesExceptSuccess();

  //   if (!currentRouteRef.current || !currentRouteRef.current.RoutePoly || currentRouteRef.current.RoutePoly.length <= 0) {
  //     showErrorMessage('Invalid Route Selection');
  //     return false;
  //   }

  //   if (!currentRouteRef.current.RouteTitle || currentRouteRef.current.RouteTitle.trim() == '') {
  //     showErrorMessage('Please Enter Route Title');
  //     return;
  //   }

  //   const areaId = getAreaId(currentRouteRef.current.RoutePoly);

  //   if (!areaId || areaId <= 0) {
  //     showErrorMessage('Invalid Route. Route Must be under an Area');
  //     return false;
  //   }

  //   if (currentRouteRef.current.OrderBookerToAssignRoute > 0 && (!currentRouteRef.current.RouteDayToAssign || currentRouteRef.current.RouteDayToAssign <= 0)) {
  //     showErrorMessage('Please Select Route Day To Assign Route');
  //     return false;
  //   }

  //   const isOverlapping = isRouteOverlapping(currentRouteRef.current.RoutePoly,currentRouteRef.current.RouteUniqueId);

  //   if(isOverlapping == true){
  //     showErrorMessage('Route Overlaps with other routes');
  //     return false;
  //   }

  //   return true;
  // }

  // const getAreaId = (routePoly: IRoutePoly[]) => {
  //   let areaIdOfPoly = 0;

  //   if (!!routePoly && routePoly.length > 0 && !!routesOnMapRef.current.Areas && routesOnMapRef.current.Areas.length > 0) {
  //     let areaUnqieId = '';
  //     const areaPolies = [...areaPoliesOnMapRef.current];
  //     for (let i = 0; i < areaPolies.length; i++) {
  //       const ap = areaPolies[i];
  //       let anyPointOutside = routePoly.filter(m => !google.maps.geometry.poly.containsLocation({ lat: m.PointLatitude, lng: m.PointLongitude }, ap.polygon))

  //       if (!anyPointOutside || anyPointOutside.length <= 0) {
  //         areaUnqieId = ap.areaId;
  //         break;
  //       }
  //     }

  //     let areaOfRoutePoly = routesOnMapRef.current.Areas.find(m => m.AreaUniqueId == areaUnqieId);

  //     if (!!areaOfRoutePoly) {
  //       areaIdOfPoly = areaOfRoutePoly.Id;
  //     }
  //   }

  //   return areaIdOfPoly;
  // }

  // const isRouteOverlapping = (routePoly: IRoutePoly[], routeId:string) => {
  //   let isOverlapping = false;

  //   if (!!routePoly && routePoly.length > 0 && !!routePoliesOnMapRef.current && routePoliesOnMapRef.current.length > 0 && !!routePoliesOnMapRef.current.filter(m => m.routeId != routeId) && routePoliesOnMapRef.current.filter(m => m.routeId != routeId).length > 0) {
      
  //     const otherRoutePolies = [...routePoliesOnMapRef.current.filter(n => n.routeId != routeId)];
  //     for (let i = 0; i < otherRoutePolies.length; i++) {
  //       const ap = otherRoutePolies[i];
  //       let anyPointInside = routePoly.filter(m => google.maps.geometry.poly.containsLocation({ lat: m.PointLatitude, lng: m.PointLongitude }, ap.polygon))

  //       if (!!anyPointInside && anyPointInside.length > 0) {
  //         isOverlapping = true;
  //         break;
  //       }
  //     }
  //   }

  //   return isOverlapping;
  // }

  // (window as any).saveRouteDetails = saveRouteDetails;

  // React.useEffect(() => {
  //   const map = new window.google.maps.Map(ref.current, {
  //     center,
  //     zoom,
  //     mapTypeId: window.google.maps.MapTypeId.ROADMAP,
  //     gestureHandling:'greedy'
  //   });


  //   let drawingManager = new google.maps.drawing.DrawingManager({
  //     drawingMode: google.maps.drawing.OverlayType.POLYGON,
  //     drawingControl: true,
  //     drawingControlOptions: {
  //       position: google.maps.ControlPosition.TOP_CENTER,
  //       drawingModes: [google.maps.drawing.OverlayType.POLYGON]
  //     },
  //     polygonOptions: {
  //       editable: true
  //     }
  //   });

  //   google.maps.event.addListener(drawingManager, "overlaycomplete", function (event) {
  //     setCurrentRouteOrignal(null);
  //     setCurrentRouteAnalytics([]);
  //     setCurrentRoute(null);
  //     setOrderBookersToAssignRouteOnCurrentRouteChange(null);
  //     setRouteDaysToAssign([]);

  //     let routePoliesOnMapToCheckRemove = [...routePoliesOnMapRef.current];
  //     let routeIdsToRemove: string[] = [];
  //     if (!!routePoliesOnMapToCheckRemove && routePoliesOnMapToCheckRemove.length > 0) {
  //       routePoliesOnMapToCheckRemove.forEach(ap => {
  //         if (!!routesOnMapRef.current.Routes.find(m => m.RouteUniqueId == ap.routeId) && routesOnMapRef.current.Routes.find(m => m.RouteUniqueId == ap.routeId).Id <= 0) {
  //           ap.polygon.setMap(null);
  //           const centerPoint = routeCenterPointMarkersRef.current.find(n => n.routeId == ap.routeId);
  //           centerPoint.marker.setMap(null);
  //           routeIdsToRemove.push(ap.routeId);
  //         }
  //       });

  //       setRoutePoliesOnMap([...routePoliesOnMapToCheckRemove.filter(m => routeIdsToRemove.findIndex(t => m.routeId == t) < 0)]);
  //       setRouteCenterPointMarkers([...routeCenterPointMarkersRef.current.filter(m => routeIdsToRemove.findIndex(t => m.routeId == t) < 0)]);
  //       setRouteInfoWindows([...routeInfoWindowsRef.current.filter(m => routeIdsToRemove.findIndex(t => m.routeId == t) < 0)]);
  //     }

  //     const uniqueId = generateUUID();
  //     onUpdateRoutePoly(0, uniqueId, event.overlay);

  //     google.maps.event.addListener(event.overlay.getPath(), 'insert_at', function (index, obj) {
  //       onUpdateRoutePoly(0, uniqueId, event.overlay);
  //     });
  //     google.maps.event.addListener(event.overlay.getPath(), 'set_at', function (index, obj) {
  //       onUpdateRoutePoly(0, uniqueId, event.overlay);
  //     });
  //   });

  //   drawingManager.setMap(map);
  //   getRoutesOnMap(map);
  //   setCurrentMap(map);
  //   getAndSetAreasAutoComplete();
  // }, []);

  // const showErrorMessage = (message: string) => addMessage(message, 'error');
  // const showInfoMessage = (message: string) => addMessage(message, 'info');
  // const showSuccessMessage = (message: string) => addMessage(message, 'success');
  // const showWarningMessage = (message: string) => addMessage(message, 'warning');
  // const [isLoading, _setIsLoading] = React.useState<boolean>(false);
  // const isLoadingRef = React.useRef(isLoading);
  // const setIsLoading = (val: boolean) => {
  //   isLoadingRef.current = val;
  //   _setIsLoading(val);
  // }
  // const [pageMessages, setPageMessages] = React.useState<IMessage[]>([]);
  // const addMessage = (messageText: string, variant: 'success' | 'error' | 'info' | 'warning') => {
  //   if (!!messageText && messageText.trim() != '') {
  //     let msgs = [];
  //     msgs.push({
  //       messageText,
  //       variant
  //     });

  //     setPageMessages([...msgs]);
  //   }
  // }

  // const [isListViewOn,setIsListViewOn] = React.useState<boolean>(false);
  // const [routesOnMapForList,setRoutesOnMapForList] = React.useState<IRoute[]>([]);

  // const clearMessages = () => {
  //   setPageMessages([]);
  // }

  // const clearMessagesExceptSuccess = () =>{
  //   setPageMessages((messages:IMessage[]) => [...messages.filter(pm => pm.variant == 'success')]);
  // }

  // const getAndSetAreasAutoComplete = () => {
  //   areaApi.getAreasAutoComplete(loginUser?.UserId ?? 0)
  //     .then(acomplete => {
  //       let items = [...acomplete];
  //       items.unshift({ Value: 0, DisplayText: 'All' });
  //       setAreasForAutoComplete([...items]);
  //       setSelectedAreaId(0);
  //     })
  //     .catch(e => console.log(e));
  // }

  // const getAndSetOrderBookersAutoComplete = (areaId: number) => {
  //   setOrderBookers([]);
  //   setIsLoading(true);
  //   employeeApi.getOrderBookerAutoComplete(areaId)
  //     .then(res => {
  //       if (!!res && res.length > 0) {
  //         let items: IAutoComplete[] = [{ Value: 0, DisplayText: 'All' }, ...res];
  //         setOrderBookers([...items]);
  //       }

  //       setIsLoading(false);
  //     })
  //     .catch(e => {
  //       setIsLoading(false);
  //       console.log(e);
  //     });
  // }

  // const onOrderBookerChange = (option: IAutoComplete) => {
  //   if (!!option) {
  //     setSelectedOrderBookerId(option.Value);
  //     reloadRoutesOnMap(selectedAreaIdRef.current, option.Value, null);
  //   } else {
  //     setSelectedOrderBookerId(0);
  //     reloadRoutesOnMap(selectedAreaIdRef.current, null, null);
  //   }
  // }

  // const onAreaChange = (option: IAutoComplete) => {

  //   if (!!option) {
  //     setSelectedAreaId(option.Value);
  //     setSelectedOrderBookerId(0);

  //     if (option.Value > 0) {
  //       getAndSetOrderBookersAutoComplete(option.Value);
  //     } else {
  //       setOrderBookers([]);
  //     }

  //     reloadRoutesOnMap(option.Value, null, selectedRouteAssignmentIdRef.current);
  //   } else {
  //     setSelectedAreaId(0);
  //     setSelectedOrderBookerId(null);
  //     reloadRoutesOnMap(null, null, selectedRouteAssignmentIdRef.current);
  //   }
  // }

  // const handleDeleteRoute = (routeId:number) =>{
  //   const route = routesOnMapForList.find(m => m.Id == routeId);
  //   scrollToTop();
  //   confirmAction('Route Delete',`${route.CanBeDeleted == false ? 'Route Have Sale On It. ' : ''}Are you sure you want to delete '${route.RouteTitle}'?`,'Yes','No')
  //   .then(ares =>{
  //     if(ares == true){
  //       setIsLoading(true);
  //       routeApi.deleteRoute(routeId, (loginUser?.UserId ?? 0))
  //       .then(isDeleted => {
  //         setIsLoading(false);

  //         if(isDeleted == true){
  //           showSuccessMessage(`Route '${route.RouteTitle}' Deleted Succesfully`);
  //           resetAndReloadEverything();
  //         }else{
  //           showErrorMessage('Route Not Deleted. Something Went Wrong');  
  //         }
  //       })
  //       .catch(e => {
  //         setIsLoading(false);
  //         showErrorMessage('Route Not Deleted. Something Went Wrong');
  //       });
  //     }
  //   });
  // }

  // const handleDeAssignRoute = (routeId:number) =>{
  //   const route = routesOnMapForList.find(m => m.Id == routeId);
  //   scrollToTop();
  //   confirmAction('Route UnAssign',`Route '${route.RouteTitle}' Currently Assigned To ${route.AssignToFirstName} ${route.AssignToLastName} For Every ${route.RouteDayName}.Are you sure you want to UnAssign?`,'Yes','No')
  //   .then(ares =>{
  //     if(ares == true){
  //       setIsLoading(true);
  //       routeApi.deAssignRoute(routeId,loginUser.UserId ?? 0)
  //       .then(isDeAssigned => {
  //         setIsLoading(false);

  //         if(isDeAssigned == true){
  //           showSuccessMessage(`Route '${route.RouteTitle}' UnAssigned Succesfully`);
  //           resetAndReloadEverything();
  //         }else{
  //           showErrorMessage('Unable To UnAssign Route. Something Went Wrong');  
  //         }
  //       })
  //       .catch(e => {
  //         setIsLoading(false);
  //         showErrorMessage('Unable To UnAssign Route. Something Went Wrong');
  //       });
  //     }
  //   });
  // }

  // const [isOrderBookerDisabled, setIsOrderBookerDisabled] = React.useState<boolean>(false);

  // const onRouteAssignmentChange = (option: IAutoComplete) => {
  //   if (!!option) {
  //     setSelectedRouteAssignmentId(option.Value);

  //     if (option.Value == 2) {
  //       setSelectedOrderBookerId(0);
  //       setIsOrderBookerDisabled(true);
  //     } else {
  //       setIsOrderBookerDisabled(false);
  //       setSelectedOrderBookerId(0);
  //     }

  //     reloadRoutesOnMap(selectedAreaIdRef.current, null, option.Value);
  //   } else {
  //     setSelectedRouteAssignmentId(0);
  //     setSelectedOrderBookerId(0);
  //     setIsOrderBookerDisabled(false);
  //     reloadRoutesOnMap(selectedAreaIdRef.current, null, null);
  //   }
  // }

  // const addCurrentRouteAnalytics = (route:IRoute) =>{
  //   console.clear();
  //   console.log('Called Again')
  //   if(!!route && route.Id > 0){
  //     setCurrentRouteAnalytics([{
  //       RouteId:route.Id,
  //       RouteUniqueId:route.RouteUniqueId,
  //       AssignedToEmployeeId:route.AssignToEmployeeId,
  //       AssignedToFirstName:route.AssignToFirstName,
  //       AssignedToLastName:route.AssignToLastName,
  //       GreenZoneNoOrders:route?.RouteOrderAnalytics?.GreenZoneNoOrders ?? 0,
  //       GreenZoneShops:route?.GreenZoneShops ?? 0,
  //       NetSale:route?.RouteSaleAnalytics?.TotalSale ?? 0,
  //       RepeatedNoOrders:route?.RouteOrderAnalytics?.TotalRepeatedNoOrders ?? 0,
  //       RepeatedOrders:route?.RouteOrderAnalytics?.TotalRepeatedOrders ?? 0,
  //       RetailSale:route?.RouteSaleAnalytics?.TotalRetailSale ?? 0,
  //       RetailSaleReturned:route?.RouteSaleAnalytics?.TotalRetailSaleReturned ?? 0,
  //       SchemeCost:route?.RouteSaleAnalytics?.TotalSchemeCost ?? 0,
  //       TotalDiscount:route?.RouteSaleAnalytics?.TotalDiscountCost ?? 0,
  //       TotalNoOrder:route?.RouteOrderAnalytics?.TotalNoOrders ?? 0,
  //       TotalOrder:route?.RouteOrderAnalytics?.TotalOrders ?? 0,
  //       TotalSaleReturned:route?.RouteSaleAnalytics?.TotalSaleReturned ?? 0,
  //       TotalShops:route?.TotalAddedShops ?? 0,
  //       WeekEndDate:route?.LastWeekEndDate,
  //       WeekStartDate:route?.LastWeekStartDate,
  //       WholeSale:route?.RouteSaleAnalytics?.TotalWholeSale ?? 0,
  //       WholeSaleReturned:route?.RouteSaleAnalytics?.TotalWholeSaleReturned ?? 0
  //     }]);
  //   }else{
  //     setCurrentRouteAnalytics([]);
  //   }
  // }

  // const [currentRouteAnalytics, setCurrentRouteAnalytics] = React.useState<IRouteAnalyticsHistory[]>([]);

  // const [orderBookers, _setOrderBookers] = React.useState<IAutoComplete[]>();
  // const orderBookersRef = React.useRef(orderBookers);

  // const setOrderBookers = (data: IAutoComplete[]) => {
  //   orderBookersRef.current = data;
  //   _setOrderBookers(data);
  // }

  // const [areasForAutoComplete, _setAreasForAutoComplete] = React.useState<IAutoComplete[]>();
  // const areasForAutoCompleteRef = React.useRef(areasForAutoComplete);

  // const setAreasForAutoComplete = (data: IAutoComplete[]) => {
  //   areasForAutoCompleteRef.current = data;
  //   _setAreasForAutoComplete(data);
  // }

  // const [selectedOrderBookerId, _setSelectedOrderBookerId] = React.useState<number>(0);
  // const [selectedAreaId, _setSelectedAreaId] = React.useState<number>(0);

  // const selectedOrderBookerIdRef = React.useRef(selectedOrderBookerId);
  // const selectedAreaIdRef = React.useRef(selectedAreaId);

  // const setSelectedOrderBookerId = (id: number) => {
  //   selectedOrderBookerIdRef.current = id;
  //   _setSelectedOrderBookerId(id);
  // }

  // const setSelectedAreaId = (areaid: number) => {
  //   selectedAreaIdRef.current = areaid;
  //   _setSelectedAreaId(areaid);
  // }

  // const [selectedRouteAssignmentId, _setSelectedRouteAssignmentId] = React.useState<number>(0);
  // const selectedRouteAssignmentIdRef = React.useRef(selectedRouteAssignmentId);

  // const setSelectedRouteAssignmentId = (data: number) => {
  //   selectedRouteAssignmentIdRef.current = data;
  //   _setSelectedRouteAssignmentId(data);
  // }

  // const routeAssignments: IAutoComplete[] = [{ DisplayText: 'All', Value: 0 }, { DisplayText: 'Assigned Only', Value: 1 }, { DisplayText: 'Not Assigned Only', Value: 2 }];

  // const reloadRoutesOnMap = (areaId: number = null, orderBookerId: number = null, routeAssigmentId: number = null) => {
  //   clearMap();
  //   getRoutesOnMap(currentMapRef.current, areaId, orderBookerId, routeAssigmentId);
  // }

  // const resetAndReloadEverything = () => {
  //   reloadRoutesOnMap(selectedAreaIdRef.current, selectedOrderBookerIdRef.current, selectedRouteAssignmentIdRef.current);
  // }

  // const [showSaleInfo,setShowSaleInfo] = React.useState<boolean>(false);

  // const addHistoryAnalyticHistory = () =>{
  //   if(!!currentRouteAnalytics && currentRouteAnalytics.length > 0){
  //     const lastAnalytics:IRouteAnalyticsHistory = {...currentRouteAnalytics[0]};
  //     const routeId = lastAnalytics.RouteId;
  //     const uniqueId = lastAnalytics.RouteUniqueId;
  //     const startDate = lastAnalytics.WeekStartDate;
      
  //     setIsLoading(true);
  //     routeApi.getRouteAnalyticHistory(routeId,startDate)
  //     .then(history => {
  //       if(!!history && !!history.RouteId &&history.RouteId > 0){
  //         setCurrentRouteAnalytics((ca) => {
  //           return [history,...ca];
  //         });
  //       }else{
  //         showInfoMessage("History Not Found");
  //       }

  //       setIsLoading(false);
  //     })
  //     .catch(e => {
  //       setIsLoading(false);
  //       showErrorMessage("Something went wrong while loading history");
  //     });
  //   }
  // }

  return <><Page title="Orders | Raza Pak Care">
    {/* <Container style={{ paddingBottom: 20, maxWidth: '100%' }}><MessageComponent onRemove={(i: number) => {
      let msgs = [...pageMessages];
      msgs.splice(i, 1);
      setPageMessages([...msgs]);
    }} messages={pageMessages} />
    </Container>
    <Container style={{ paddingBottom: 10, maxWidth: '100%' }}>
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <CustomAutoComplete<IAutoComplete>
            Id={'Area_List'}
            Value={!!areasForAutoComplete && areasForAutoComplete.length > 0 ? areasForAutoComplete.filter(m => m.Value == selectedAreaId)[0] : null}
            Options={areasForAutoComplete}
            displayField={'DisplayText'}
            valueField={'Value'}
            Label={'Area'}
            onChange={onAreaChange}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <CustomAutoComplete<IAutoComplete>
            Id={'Route_Assignment_List'}
            Value={!!routeAssignments && routeAssignments.length > 0 ? routeAssignments.filter(m => m.Value == selectedRouteAssignmentId)[0] : null}
            Options={routeAssignments}
            displayField={'DisplayText'}
            valueField={'Value'}
            Label={'Route Assignment'}
            onChange={onRouteAssignmentChange}
          />
        </Grid>
        <Grid item md={4} xs={12}><CustomAutoComplete<IAutoComplete>
          Id={'Order_Booker_List'}
          Value={!!orderBookers && orderBookers.length > 0 ? orderBookers.filter(m => m.Value == selectedOrderBookerId)[0] : null}
          Options={orderBookers}
          displayField={'DisplayText'}
          valueField={'Value'}
          Label={'Order Booker'}
          disabled={(!orderBookers || orderBookers.length <= 0) || isOrderBookerDisabled == true}
          onChange={onOrderBookerChange}
        /></Grid>
      </Grid>
    </Container>

    {isLoadingRef.current == true && <AudoSpin
      height="80"
      width="80"
      color='green'
      ariaLabel='three-dots-loading'
      wrapperStyle={{ alignSelf: 'center', justifyContent: 'center' }}
    />}

    {(!currentRouteRef.current) && !!routesOnMapForList && routesOnMapForList.length > 0 && <Container style={{ paddingBottom: 10, maxWidth: '100%' }}>
                <Card>
                    <Typography style={{ backgroundColor: 'rgba(34, 154, 22, 0.72)', color: 'white', paddingBottom: 5, paddingTop: 5, textAlign: 'center',borderRadius:1 }} variant={'h4'}>
                      <span style={{position:'absolute',top:10,left:12,bottom:0}}>
                        {isListViewOn == true && <Icon color='white' onClick={() => { setIsListViewOn(!isListViewOn)}} style={{ cursor: 'pointer' }} width={25} height={25} icon={'akar-icons:arrow-up'} />}
                        {(!isListViewOn == true) && <Icon color='white' onClick={() => { setIsListViewOn(!isListViewOn) }} style={{ cursor: 'pointer' }} width={25} height={25} icon={'akar-icons:arrow-down'} />}
                      </span>
                      Routes List (With Current Week Analytics)
                    </Typography>

                    {isListViewOn == true && !!routesOnMapForList && routesOnMapForList.length > 0 &&
                        <TableContainer sx={{ minWidth: 800, maxHeight:700 }}>
                            <Table stickyHeader>
                                <UserListHead
                                    order={'asc'}
                                    orderBy={''}
                                    headLabel={TABLE_HEAD}
                                    rowCount={0}
                                    numSelected={0}
                                    onRequestSort={undefined}
                                    onSelectAllClick={undefined}
                                    customKey={`TableHeadRow_2`}
                                />
                                <TableBody>
                                    {!!routesOnMapForList && routesOnMapForList.length > 0 && routesOnMapForList.map((row, index) => {
                                        const drawBottomBorder = (routesOnMapForList.length == (index + 1)) || (routesOnMapForList[index + 1].AssignToEmployeeId != row.AssignToEmployeeId);
                                        const changeFirstOrderBookerStyle = index == 0 || (routesOnMapForList[index - 1].AssignToEmployeeId != row.AssignToEmployeeId);
                                        return (
                                            <><TableRow
                                                hover
                                                key={row.Id}
                                                tabIndex={-1}
                                                role="checkbox"
                                                selected={false}
                                                aria-checked={false}
                                            >
                                                <TableCell style={drawBottomBorder == true ? {borderBottomColor:'black', borderBottom:1,borderBottomStyle:'solid'} : {}} width={10} align="center">
                                                    {index + 1}
                                                </TableCell>
                                                <TableCell style={drawBottomBorder == true ? {borderBottomColor:'black', borderBottom:1,borderBottomStyle:'solid'} : {}} align="center">
                                                    {row.RouteTitle}
                                                </TableCell>
                                                <TableCell style={drawBottomBorder == true ? {borderBottomColor:'black', borderBottom:1,borderBottomStyle:'solid'} : {}} align="center">
                                                    {row.AreaTitle}
                                                </TableCell>
                                                <TableCell style={drawBottomBorder == true ? {borderBottomColor:'black', borderBottom:1,borderBottomStyle:'solid'} : {}} align="center" component="th" scope="row" padding="none">
                                                    {changeFirstOrderBookerStyle == true && <h3 style={{color:'green'}}>{!!row.RouteAssignId && row.RouteAssignId > 0 ? `${row.AssignToFirstName} ${row.AssignToLastName}` : 'N/A'}</h3>}
                                                    {changeFirstOrderBookerStyle == false && (!!row.RouteAssignId && row.RouteAssignId > 0 ? `${row.AssignToFirstName} ${row.AssignToLastName}` : 'N/A')}
                                                </TableCell>
                                                <TableCell style={drawBottomBorder == true ? {borderBottomColor:'black', borderBottom:1,borderBottomStyle:'solid'} : {}} align="center">
                                                {!!row.RouteAssignId && row.RouteAssignId > 0 ? `${row.RouteDayName}` : 'N/A'}
                                                </TableCell>
                                                <TableCell style={drawBottomBorder == true ? {borderBottomColor:'black', borderBottom:1,borderBottomStyle:'solid',color:'blue'} : {color:'blue'}} align="center">
                                                    {row?.TotalAddedShops ?? 0}
                                                </TableCell>
                                                <TableCell style={drawBottomBorder == true ? {borderBottomColor:'black', borderBottom:1,borderBottomStyle:'solid',color:'green'} : {color:'green'}} align="center">
                                                    {row?.GreenZoneShops ?? 0}
                                                </TableCell>

                                                <TableCell style={drawBottomBorder == true ? {borderBottomColor:'black', borderBottom:1,borderBottomStyle:'solid',color:'red'} : {color:'red'}} align="center">
                                                    {(row?.TotalAddedShops ?? 0) - (row?.GreenZoneShops ?? 0)}
                                                </TableCell>
                                                <TableCell style={drawBottomBorder == true ? {borderBottomColor:'black', borderBottom:1,borderBottomStyle:'solid',color:'green'} : {color:'green'}} align="center">
                                                    {(row?.RouteOrderAnalytics?.TotalOrders ?? 0) - (row?.RouteOrderAnalytics?.TotalRepeatedOrders ?? 0)}
                                                </TableCell>
                                                <TableCell style={drawBottomBorder == true ? {borderBottomColor:'black', borderBottom:1,borderBottomStyle:'solid',minWidth:90} : {minWidth:90}} align="center">
                                                <Icon style={{margin:5,cursor:'pointer'}} color='green' fontSize={21} onClick={() => { handleAssignRouteClick(row.Id,row.RouteUniqueId) }} icon='bxs:map-pin' />
                                                  {!!row.RouteAssignId && row.RouteAssignId > 0 && <Icon style={{cursor:'pointer', margin:5,}} onClick={() => { handleDeAssignRoute(row.Id) }} color='red' fontSize={25} icon='bxs:user-minus' />}
                                                  {(!row.RouteAssignId || row.RouteAssignId <= 0) && <Icon style={{cursor:'pointer', margin:5}} onClick={() => { handleAssignRouteClick(row.Id,row.RouteUniqueId); }} color='blue' fontSize={25} icon='bxs:user-plus' />}
                                                  <Icon style={{margin:5,cursor:'pointer'}} color='red' fontSize={21} onClick={() => { handleDeleteRoute(row.Id) }} icon='fa6-solid:trash' />
                                                </TableCell>
                                            </TableRow>
                                            </>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>}
                </Card>
            </Container>}

    {!!currentRouteRef.current && <Container style={{ marginBottom: 10, maxWidth: '100%', border: 1, borderStyle: 'solid' }}>
      <Typography style={{ textAlign: 'center', paddingBottom: 5, paddingTop: 5 }}><strong style={{ textDecoration: 'underline' }}>Selected Route Details</strong></Typography>
      {!!currentRouteAnalytics && currentRouteAnalytics.length > 0 && showSaleInfo == false && <Icon style={{cursor:'pointer'}} onClick={() => {setShowSaleInfo(!showSaleInfo)}} color='green' icon='charm:eye' fontSize={25} />}
      {!!currentRouteAnalytics && currentRouteAnalytics.length > 0 && showSaleInfo == true &&  <Icon style={{cursor:'pointer'}} onClick={() => {setShowSaleInfo(!showSaleInfo)}} color='red' icon='charm:eye-slash' fontSize={25} />}
      {!!currentRouteAnalytics && currentRouteAnalytics.length > 0 && <Icon style={{cursor:'pointer',marginLeft:5}} onClick={() => {addHistoryAnalyticHistory()}} color='blue' icon='carbon:add-filled' fontSize={25} />}

{!!currentRouteAnalytics && currentRouteAnalytics.length > 0 && currentRouteAnalytics.map((row,index) => {
  return (
    <>
    <Typography style={{ textAlign: 'center', paddingBottom: 2, paddingTop: 5 }}><strong>--------------------({formattedDate(row.WeekStartDate,'DD-MMM-yyyy')} to {formattedDate(row.WeekEndDate,'DD-MMM-yyyy')})--------------------</strong></Typography>
    <Grid container spacing={2} style={{ paddingTop: 15, paddingBottom: 15 }}>
      
        <Grid style={{display: 'flex', flexDirection: 'row',justifyContent:'center' }} item md={2} xs={12}>
          <Card sx={{height:180,display:'flex',paddingTop:2,paddingBottom:2,alignSelf:'center',flexDirection:'column',width:'100%'}}>
            <h2 style={{ textAlign: 'center',paddingBottom:5,color:'#676767' }}><strong>Zone Information</strong></h2>
            <div style={{display:'flex',flexDirection:'column',textAlign:'center'}}><strong style={{color:'blue'}}>Total Shops: {row.TotalShops ?? 0}</strong>
            <strong style={{color:'green'}}>Green Zone: {row.GreenZoneShops ?? 0}</strong>
            <strong style={{color:'red'}}>Red Zone: {(row.TotalShops ?? 0) - (row.GreenZoneShops ?? 0)}</strong>
            <strong style={{color:'darkgreen'}}>Converted (This Week): {(row.TotalOrder ?? 0) - (row.RepeatedOrders ?? 0)}</strong></div>
          </Card>
        </Grid>

        <Grid style={{display: 'flex', flexDirection: 'row',justifyContent:'center' }} item md={2} xs={12}>
          <Card sx={{height:180,display:'flex',paddingTop:2,paddingBottom:2,alignSelf:'center',flexDirection:'column',width:'100%'}}>
            <h2 style={{ textAlign: 'center',paddingBottom:5,color:'#676767' }}><strong>No Order Information</strong></h2>
            <div style={{display:'flex',flexDirection:'column',textAlign:'center'}}>
              <strong style={{color:'red'}}>Total No Order: {row.TotalNoOrder ?? 0}</strong>
              <strong style={{color:'red'}}>G-Zone No Orders: {row.GreenZoneNoOrders ?? 0}</strong>
              <strong style={{color:'red'}}>Rptd No Orders: {row.RepeatedNoOrders ?? 0}</strong>
            </div>
          </Card>
        </Grid>

        <Grid style={{display: 'flex', flexDirection: 'row',justifyContent:'center' }} item md={2} xs={12}>
          <Card sx={{height:180,display:'flex',paddingTop:2,paddingBottom:2,alignSelf:'center',flexDirection:'column',width:'100%'}}>
            <h2 style={{ textAlign: 'center',paddingBottom:5,color:'#676767' }}><strong>Return Information</strong></h2>
            <div style={{display:'flex',flexDirection:'column',textAlign:'center'}}>
              <strong style={{color:'red'}}>Retail Sale Returned: {fCurrency(row.RetailSaleReturned ?? 0)}</strong>
              <strong style={{color:'red'}}>Whole Sale Returned: {fCurrency(row.WholeSaleReturned ?? 0)}</strong>
              <strong style={{color:'red'}}>Total Sale Returned: {fCurrency(row.TotalSaleReturned ?? 0)}</strong>
            </div>
          </Card>
        </Grid>

        <Grid style={{display: 'flex', flexDirection: 'row',justifyContent:'center' }} item md={2} xs={12}>
          <Card sx={{height:180,display:'flex',paddingTop:2,paddingBottom:2,alignSelf:'center',flexDirection:'column',width:'100%'}}>
            <h2 style={{ textAlign: 'center',paddingBottom:5,color:'#676767' }}><strong>Order Information</strong></h2>
            <div style={{display:'flex',flexDirection:'column',textAlign:'center'}}>
              <strong style={{color:'green'}}>Total Order: {row.TotalOrder ?? 0}</strong>
              <strong style={{color:'green'}}>New Orders: {(row.TotalOrder ?? 0) - (row.RepeatedOrders ?? 0)}</strong>
              <strong style={{color:'darkgreen'}}>Rptd Orders: {row.RepeatedOrders ?? 0}</strong>
            </div>
          </Card>
        </Grid>

        {showSaleInfo == true && <Grid style={{display: 'flex', flexDirection: 'row',justifyContent:'center' }} item md={2} xs={12}>
          <Card sx={{height:180,display:'flex',paddingTop:2,paddingBottom:2,alignSelf:'center',flexDirection:'column',width:'100%'}}>
            <h2 style={{ textAlign: 'center',paddingBottom:5,color:'#676767' }}><strong>Sale Information</strong></h2>
            <div style={{display:'flex',flexDirection:'column',textAlign:'center'}}>
              <strong style={{color:'green'}}>Retail Sale: {fCurrency(row.RetailSale ?? 0)}</strong>
              <strong style={{color:'green'}}>Whole Sale: {fCurrency(row.WholeSale ?? 0)}</strong>
              <strong style={{color:'darkgreen'}}>Net Sale: {fCurrency(row.NetSale ?? 0)}</strong>
            </div>
          </Card>
        </Grid>}

        {showSaleInfo == true && <Grid style={{display: 'flex', flexDirection: 'row',justifyContent:'center' }} item md={2} xs={12}>
          <Card sx={{height:180,display:'flex',paddingTop:2,paddingBottom:2,alignSelf:'center',flexDirection:'column',width:'100%'}}>
            <h2 style={{ textAlign: 'center',paddingBottom:5,color:'#676767' }}><strong>Cost Information</strong></h2>
            <div style={{display:'flex',flexDirection:'column',textAlign:'center'}}>
              <strong style={{color:'red'}}>Total Scheme: {fCurrency(row.SchemeCost ?? 0)}</strong>
              <strong style={{color:'red'}}>Total Discount: {fCurrency(row.TotalDiscount ?? 0)}</strong>
            </div>
          </Card>
        </Grid>}
      </Grid></>
  );
})}

      {!!currentRouteAnalytics && currentRouteAnalytics.length > 0 && <hr />}
      <Grid container spacing={2} style={{display:'flex',flexDirection:'row',paddingTop:10,justifyContent:'center'}}>
      <Grid style={{display: 'flex', flexDirection: 'row',justifyContent:'center' }} item md={4} xs={12}>
          <Card sx={{height:180,display:'flex',paddingTop:2,paddingBottom:1,paddingLeft:5,paddingRight:5,alignSelf:'center',flexDirection:'column',width:'100%'}}>
            <h2 style={{ textAlign: 'center',paddingBottom:5,color:'#676767' }}><strong>Current Information</strong></h2>
            <div style={{display:'flex',flexDirection:'column',textAlign:'center'}}>
              <strong>Assigned To: <span style={{color:'green',paddingLeft:5}}>{!!currentRouteRef.current.AssignToEmployeeId && currentRouteRef.current.AssignToEmployeeId > 0 ? `${currentRouteRef.current.AssignToFirstName} ${currentRouteRef.current.AssignToLastName}` : 'N/A'}</span></strong>
              <strong>Assigned To: <span style={{color:'green',paddingLeft:5}}>{!!currentRouteRef.current.AssignToEmployeeId && currentRouteRef.current.AssignToEmployeeId > 0 ? `${currentRouteRef.current.RouteDayName}` : 'N/A'}</span></strong>
              {isNew == 0 && <strong>Existing Shops: <span style={{color:'green',paddingLeft:5}}>{currentRouteRef.current.TotalExistingShops ?? 0}</span></strong>}
              <strong>Added Shops: <span style={{color:'green',paddingLeft:5}}>{currentRouteRef.current.TotalAddedShops ?? 0}</span></strong>
            </div>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ paddingTop: 20 }}>
        <Grid item md={12} xs={12} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
          <TextField
            style={{ width: 400 }}
            autoComplete="route-name"
            type={'text'}
            label="Roue Name"
            value={!!currentRouteRef.current ? currentRouteRef.current.RouteTitle : ''}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              const value = event.target.value;
              handleRouteTitleChange(value);
            }}
          />
          {!!currentRouteRef.current && (!currentRouteRef.current.AssignToEmployeeId || currentRouteRef.current.AssignToEmployeeId <= 0) && !!orderBookersToAssignRouteRef.current && orderBookersToAssignRouteRef.current.length > 0 && <CustomAutoComplete<IAvailableOrderBooker>
            style={{ width: 400, marginLeft: 20, marginRight: 20 }}
            Id={'Order_Booker_List_ToAssign'}
            Value={!!orderBookersToAssignRouteRef.current && orderBookersToAssignRouteRef.current.length > 0 && currentRouteRef.current.OrderBookerToAssignRoute !== undefined && currentRouteRef.current.OrderBookerToAssignRoute != null ? orderBookersToAssignRouteRef.current.filter(m => m.EmployeeId == currentRouteRef.current.OrderBookerToAssignRoute)[0] : null}
            Options={orderBookersToAssignRouteRef.current}
            displayField={'DisplayText'}
            valueField={'EmployeeId'}
            Label={'Assign To'}
            disabled={(!orderBookersToAssignRouteRef.current || orderBookersToAssignRouteRef.current.length <= 0)}
            onChange={handleOrderBookerToAssignRouteChange}
          />}
          {!!currentRouteRef.current && (!currentRouteRef.current.AssignToEmployeeId || currentRouteRef.current.AssignToEmployeeId <= 0) && !!routeDaysToAssignRef.current && routeDaysToAssignRef.current.length > 0 && <CustomAutoComplete<IOrderBookerAvailableDay>
            style={{ width: 400 }}
            Id={'Order_Booker_Available_Day_List'}
            Value={!!routeDaysToAssignRef.current && routeDaysToAssignRef.current.length > 0 && !!currentRouteRef.current?.RouteDayToAssign ? routeDaysToAssignRef.current.filter(m => m.AvailableDW == currentRouteRef.current.RouteDayToAssign)[0] : null}
            Options={routeDaysToAssignRef.current}
            displayField={'AvailableDay'}
            valueField={'AvailableDW'}
            Label={'Route Day'}
            disabled={(!routeDaysToAssignRef.current || routeDaysToAssignRef.current.length <= 0)}
            onChange={handleRouteDayToAssignChange}
          />}
        </Grid>
      </Grid>

      <Grid container style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', paddingTop: 10, paddingBottom: 10 }}>
        <LoadingButton
          type="button"
          variant="contained"
          loading={isLoading}
          style={{ margin: 3, width: 160 }}
          startIcon={<Icon icon={'fluent:save-24-filled'} />}
          onClick={saveRouteDetails}
        >
          Save Changes
        </LoadingButton>
        <LoadingButton
          type="button"
          variant="contained"
          loading={isLoading}
          style={{ margin: 3, width: 160, backgroundColor: 'blue' }}
          startIcon={<Icon icon={'bx:reset'} />}
          onClick={resetAndReloadEverything}
        >
          Reset
        </LoadingButton>
        {<LoadingButton
          type="button"
          variant="contained"
          loading={isLoading}
          style={{ margin: 3, width: 160, backgroundColor: 'red' }}
          startIcon={<Icon icon={'fa6-solid:trash'} />}
          onClick={() => {
            handleDeleteRoute(currentRouteRef.current.Id);
          }}
        >
          Delete
        </LoadingButton>}
        {!!currentRouteRef.current.RouteAssignId && currentRouteRef.current.RouteAssignId > 0 && <LoadingButton
          type="button"
          variant="contained"
          loading={isLoading}
          style={{ margin: 3, width: 160, backgroundColor: '#ffcc00' }}
          startIcon={<Icon icon={'bxs:user-minus'} />}
          onClick={() => {
            handleDeAssignRoute(currentRouteRef.current.Id);
          }}
        >
          UnAssign
        </LoadingButton>}

      </Grid>
    </Container>}
    <Container style={{maxWidth:'100%',width:'100%',height: 750}}>
      <div style={{ width: '100%', height: '100%' }} ref={ref} id="map" />  
    </Container> */}
  </Page>
    </>
    ;

}