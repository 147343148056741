import {OrderBookerInfoConstants} from '../actions/action.constant';
import {IAction} from '../actions/action.interface';
import {initialState} from '../initial-state';

export const isSavingRouteAssignmentReducer = (
  state: boolean = initialState.isSavingRouteAssignment,
  action: IAction<boolean>,
) => {
  switch (action.type) {
    case OrderBookerInfoConstants.SET_ROUTE_ASSIGNMENT_SAVING: {
      return (action.payload ?? state);
    }
    default:
      return state;
  }
};
