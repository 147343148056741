import * as React from 'react';
import LinearProgress, { LinearProgressProps, linearProgressClasses } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 15,
    [`&.${linearProgressClasses.colorPrimary}`]: {
    },
    [`& .${linearProgressClasses.bar}`]: {
    },
  }));

export const LinearProgressComponent = (props: LinearProgressProps & { value: number }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <BorderLinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="green">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}