import React from 'react';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import refreshFill from '@iconify/icons-eva/refresh-fill';
// material
import {
    Card,
    Table,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    Grid,
} from '@mui/material';
// components
import Page from '../Page';
import Scrollbar from '../Scrollbar';
import { UserListHead } from '../_dashboard/user';
//
import { LoadingButton } from '@mui/lab';
import { doCreditApi } from '../../http';
import { connect } from 'react-redux';
import { IApplicationState } from 'src/redux';
import { Audio as AudoSpin } from 'react-loader-spinner'
import { generateUUID, getCommaSeparatedUnitUniqueIdsExceptAll } from 'src/utility';
import { IUserDetail } from 'src/interfaces';
import { ISelectedUnitAccess } from 'src/interfaces/selected-unit-access.interface';
import { AllOptionUniqueId } from 'src/app.constant';
import { NoUnitSelectedComponent } from 'src/components/common/no-unit-selected.component';
import { fCurrency } from 'src/utils/formatNumber';
import { IEmployeeCredit } from 'src/interfaces/reports/employee-credit.interface';


// ----------------------------------------------------------------------


const TABLE_HEAD_CREDIT_ORDER_BOOKER_WISE = [
    { id: 'sr', label: 'Sr.', alignContent: 'center' },
    { id: 'unit_name', label: 'Unit', alignContent: 'center' },
    { id: 'responsible_person', label: 'Responsible Person', alignContent: 'center' },
    { id: 'cell_no', label: 'Cell#', alignContent: 'center' },
    {id: 'pending_credit', label:'Pending Credit', alignContent:'center'},
    {id: 'action', label:'', alignContent:'center'}
];

//#region interfaces
interface IProps {
    loginUser: IUserDetail | null;
    selectedUnits: ISelectedUnitAccess;
    onClick:(unitUniqueId:string,unitTitle:string, employeeId:number,employeeName:string) => void;
}

//#endregion

const OrderBookerWiseCreditDetail = (props: IProps) => {
    //#region state variables
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [employeeCredits, setEmployeeCredits] = React.useState<IEmployeeCredit[]>([]);
    const orderBookerWiseCreditDetailKey = 'OrderBookerWiseCreditDetailKey';
    //#endregion

    //#region util methods start

    const getTotalCredit = (unitUniqueId:string) =>{
        let totalC = 0;

        if(!!employeeCredits && employeeCredits.length > 0){
            employeeCredits.filter(u => u.UnitUniqueId == unitUniqueId).forEach(ec => {
                totalC += ec.TotalPendingCredit;
            });
        }

        return totalC;
    }

    const getTotalCreditOverAll = () =>{
        let totalC = 0;

        if(!!employeeCredits && employeeCredits.length > 0){
            employeeCredits.forEach(ec => {
                totalC += ec.TotalPendingCredit;
            });
        }

        return totalC;
    }
    //#endregion util methods end

    //#region handlers start

    const resetEverything = () => {
        setEmployeeCredits([]);
    }

    const onRefresh = () =>{
        resetEverything();
        getCredits();
    }

    //#endregion handlers end

    //#region apis call start

    const getCredits = () => {
        setIsLoading(true);
        doCreditApi.getEmployeeCredit(getCommaSeparatedUnitUniqueIdsExceptAll(props.selectedUnits.UnitAccesses), props.loginUser.UserId)
            .then(results => {
                setIsLoading(false);
                if (!!results) {
                    setEmployeeCredits([...results]);
                }
                else {
                    resetEverything();
                }
            })
            .catch(e => {
                console.log(e);
                setIsLoading(false);
            });
    }

    //#endregion apis calls end

    //#region hooks start
    React.useEffect(() => {
        getCredits();
    }, [props.selectedUnits.UnitAccesses]);
    //#endregion hooks end

    return (
        (!!props.selectedUnits && !!props.selectedUnits.UnitAccesses && props.selectedUnits.UnitAccesses.filter(u => u.UnitUniqueId != AllOptionUniqueId).length > 0) ? <Page key={`${orderBookerWiseCreditDetailKey}_1`} title="Credit | Raza Pak Care">

            {<Container key={`${orderBookerWiseCreditDetailKey}_2`} style={{ maxWidth: 1500, paddingLeft: 0, paddingRight: 0 }}>
                <Grid key={`${orderBookerWiseCreditDetailKey}_3`} container style={{display:'flex',flexDirection:'row', paddingBottom:3}}>
                <Grid key={`${orderBookerWiseCreditDetailKey}_4`} item md={3} style={{display:'flex', flexDirection:'column', justifyContent:'flex-end'}}>
                <Typography key={`${orderBookerWiseCreditDetailKey}_5`} style={{marginLeft:10,textDecoration:'underline', alignSelf:'flex-start', color:'red'}} variant='body1'>{fCurrency(getTotalCreditOverAll())}</Typography>
                        </Grid>
                        <Grid key={`${orderBookerWiseCreditDetailKey}_6`} item md={9} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                        <LoadingButton
                            size="small"
                            type="button"
                            variant="contained"
                            loading={isLoading}
                            style={{alignSelf:'center', backgroundColor: 'rgba(34, 154, 22, 0.72)', textAlign: 'right', height: 40 }}
                            startIcon={<Icon icon={refreshFill} />}
                            onClick={() => {
                                onRefresh();
                            }}
                            key={`${orderBookerWiseCreditDetailKey}_7`}
                        >
                            Refresh
                        </LoadingButton>
                        </Grid>
                </Grid>


                {!!props.selectedUnits && !!props.selectedUnits.UnitAccesses && props.selectedUnits.UnitAccesses.filter(m => m.UnitUniqueId != AllOptionUniqueId).length > 0 && props.selectedUnits.UnitAccesses.filter(m => m.UnitUniqueId != AllOptionUniqueId).map((selectedUnit, unitIndex) => {
                    return <React.Fragment key={`${orderBookerWiseCreditDetailKey}_${selectedUnit.UnitUniqueId}_8`}><Card key={`${orderBookerWiseCreditDetailKey}_${selectedUnit.UnitUniqueId}_9`}>
                        <Typography key={`${orderBookerWiseCreditDetailKey}_${selectedUnit.UnitUniqueId}_10`} style={{ backgroundColor: 'rgba(34, 154, 22, 0.72)', color: 'white', paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h4'}>{selectedUnit.UnitTitle}</Typography>
                        {isLoading == true && <AudoSpin
                            height="80"
                            width="80"
                            color='green'
                            ariaLabel='three-dots-loading'
                            wrapperStyle={{ alignSelf: 'center', justifyContent: 'center' }}
                            key={`${orderBookerWiseCreditDetailKey}_${selectedUnit.UnitUniqueId}_11`}
                        />}

                        {(!employeeCredits || employeeCredits.length <= 0 || employeeCredits.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length <= 0) && isLoading == false && <Typography key={`${orderBookerWiseCreditDetailKey}_${selectedUnit.UnitUniqueId}_12`} style={{ paddingBottom: 5, paddingTop: 5, textAlign: 'center' }} variant={'h6'}>{`No Credits Order Booker Wise`}</Typography>}

                        {!!employeeCredits && employeeCredits.length > 0 && employeeCredits.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length > 0 && <Scrollbar key={`${orderBookerWiseCreditDetailKey}_${selectedUnit.UnitUniqueId}_13`} sx={{}}>
                            <TableContainer key={`${orderBookerWiseCreditDetailKey}_${selectedUnit.UnitUniqueId}_14`} sx={{ minWidth: 800 }}>
                                <Table size='small' key={`${orderBookerWiseCreditDetailKey}_${selectedUnit.UnitUniqueId}_15`}>
                                    <UserListHead
                                        order={'asc'}
                                        orderBy={''}
                                        headLabel={TABLE_HEAD_CREDIT_ORDER_BOOKER_WISE}
                                        rowCount={0}
                                        numSelected={0}
                                        onRequestSort={undefined}
                                        onSelectAllClick={undefined}
                                        customKey={`${orderBookerWiseCreditDetailKey}_${selectedUnit.UnitUniqueId}_17`}
                                        key={`${orderBookerWiseCreditDetailKey}_${selectedUnit.UnitUniqueId}_16`}
                                    />
                                    <TableBody key={`${orderBookerWiseCreditDetailKey}_${selectedUnit.UnitUniqueId}_18`}>
                                        {!!employeeCredits && employeeCredits.length > 0 && employeeCredits.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).length > 0 && employeeCredits.filter(d => d.UnitUniqueId == selectedUnit.UnitUniqueId).map((row, index) => {

                                            return (
                                                <TableRow
                                        hover
                                        key={`${orderBookerWiseCreditDetailKey}_${selectedUnit.UnitUniqueId}_${row.EmployeeId}`}
                                        tabIndex={-1}
                                        role="checkbox"
                                        selected={false}
                                        aria-checked={false}
                                        style={{cursor:'pointer'}}
                                        onClick={() =>{
                                            props.onClick(row.UnitUniqueId,row.UnitTitle,row.EmployeeId,`${row.FirstName} ${row.LastName}${!!row.EmployeeTagId && row.EmployeeTagId.trim() != '' ? ` [${row.EmployeeTagId}]` : ''}`);
                                        }}
                                    >
                                        <TableCell key={`${orderBookerWiseCreditDetailKey}_${selectedUnit.UnitUniqueId}_${row.EmployeeId}_1`} align="center">{index + 1}</TableCell>
                                        <TableCell key={`${orderBookerWiseCreditDetailKey}_${selectedUnit.UnitUniqueId}_${row.EmployeeId}_2`} align="center">{row.UnitTitle}</TableCell>
                                        <TableCell key={`${orderBookerWiseCreditDetailKey}_${selectedUnit.UnitUniqueId}_${row.EmployeeId}_3`} align="center" style={{textDecoration:'underline',color:'blue'}}>
                                            {`${row.FirstName} ${row.LastName}${!!row.EmployeeTagId && row.EmployeeTagId.trim() != '' ? ` [${row.EmployeeTagId}]` : ''}`}
                                        </TableCell>
                                        <TableCell key={`${orderBookerWiseCreditDetailKey}_${selectedUnit.UnitUniqueId}_${row.EmployeeId}_4`} align="center">{`${!!row.CellNumberOne && row.CellNumberOne.trim() != '' ? row.CellNumberOne : (!!row.CellNumberTwo && row.CellNumberTwo.trim() != '' ? `${row.CellNumberTwo}` : 'N/A')}`}</TableCell>
                                        <TableCell key={`${orderBookerWiseCreditDetailKey}_${selectedUnit.UnitUniqueId}_${row.EmployeeId}_5`} align="center" style={{color:row.TotalPendingCredit > 0 ? 'red' : 'green'}}><h3>{fCurrency(row.TotalPendingCredit)}</h3></TableCell>
                                        <TableCell key={`${orderBookerWiseCreditDetailKey}_${selectedUnit.UnitUniqueId}_${row.EmployeeId}_6`} align='center'></TableCell>
                                    </TableRow>
                                            );
                                        })}

                                    <TableRow key={`${orderBookerWiseCreditDetailKey}_30`}>
                                        <TableCell key={`${orderBookerWiseCreditDetailKey}_31`} colSpan={4} align='right'><h3>Total:</h3></TableCell>
                                        <TableCell key={`${orderBookerWiseCreditDetailKey}_32`}  align='center' style={{color:'red'}}><h2>{fCurrency(getTotalCredit(selectedUnit.UnitUniqueId))}</h2></TableCell>
                                        <TableCell key={`${orderBookerWiseCreditDetailKey}_33`}></TableCell>
                                    </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Scrollbar>}
                    </Card><br /></React.Fragment>
                })}


            </Container>}
        </Page> : <NoUnitSelectedComponent key={`${orderBookerWiseCreditDetailKey}_34`} pageTitle='Credit | Raza Pak Care' />
    );
}

const mapStateToProps = (state: IApplicationState) => {
    return {
        selectedUnits: state.selectedUnitAccesses,
        loginUser: state.loginUser,
    };
}
const connectedOrderBookerWiseCreditDetail = connect(mapStateToProps)(OrderBookerWiseCreditDetail);
export { connectedOrderBookerWiseCreditDetail as OrderBookerWiseCreditDetail };
